
<div class="tw-mx-4">
  <div id="doctor-panel" class="tw-bg-white tw-px-2 tw-py-5 tw-rounded-3xl tw-shadow-z1" (click)="handleClick()" >
    <div class="tw-w-full tw-flex tw-justify-between tw-items-start">
      <div class="tw-flex tw-flex-col tw-gap-1.5 tw-text-gray-900">
        <h1 class="tw-font-light tw-text-700 tw-leading-none tw-font-body" i18n="@@meetCureskinsExperts">Meet cureskin's <br /> <span class="tw-font-semibold">panel of experts</span></h1>
      </div>
      <div class="tw-min-w-10 tw-flex tw-justify-end tw-items-center">
        <cs-icon class="tw-w-8 tw-h-8" [iconName]="'click-icon'"></cs-icon>
      </div>
    </div>
    <div class="tw-w-full tw-mt-6">
      <img src="/assets/images/doctors.gif" class="tw-w-full">
    </div>
  </div>
</div>
