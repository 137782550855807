import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { ButtonModule } from '@components/button';
import { ToolbarModule } from '@components/toolbar';
import { DirectivesModule } from '@directives/directives.module';
import { LoadingModule } from '@components/loading';
import { UserCheckupModule } from 'client/app/user-checkup/user-checkup.module';
import { UserAssistantComponent } from './user-assistant.component';

@NgModule({ imports: [
  FormsModule,
  CommonModule,
  ToolbarTabsModule,
  ButtonModule,
  LoadingModule,
  ToolbarModule,
  DirectivesModule,
  UserCheckupModule,
],
declarations: [UserAssistantComponent],
exports: [UserAssistantComponent] })
export class UserAssistantModule {}
