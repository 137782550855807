import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { ArticleComponent } from './article.component';

@NgModule({
  imports: [CommonModule, ToolbarModule, LoadingModule, ButtonModule, CsIconModule],
  declarations: [ArticleComponent],
  exports: [],
})
export class ArticleModule {}
