<div class="tw-block tw-border-spacing-0 tw-w-full tw-flex-[1] tw-overflow-x-hidden tw-h-screen tw-relative tw-font-body">
  <div class="tw-flex tw-flex-col tw-flex-[1]">
    <div class="tw-w-full tw-flex-initial">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="menuAction()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@faqs">FAQs</span>
      </div>
    </div>
    <div class="tw-w-full tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto" #container="">
      <loading class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" *ngIf="loading"></loading>
      <div class="tw-flex tw-items-center tw-p-4" *ngIf="categories.length > 1" style="flex-flow: wrap;">
        <div class="tw-flex tw-justify-center tw-items-center tw-min-w-[100px] tw-py-0 tw-px-4 tw-h-11 tw-text-300 tw-bg-white tw-border tw-border-gray-300 tw-my-1.5 tw-mx-2.5 tw-rounded-[50px] tw-capitalize tw-font-medium" *ngFor="let item of categories" [ngClass]="{ 'tw-border-blue-500 tw-text-blue-500': selectedId === item.id, 'tw-text-gray-500': selectedId !== item.id }" (click)="filter(item)">
          {{item.get('title')}}
        </div>
      </div>
      <div class="tw-mb-5 tw-mx-auto tw-w-11/12" *ngIf="!loading">
        <input class="tw-w-full tw-rounded-[30px] tw-py-4 tw-px-5 tw-m-auto tw-drop-shadow-none" style="box-shadow: none; border: 1px solid rgb(196, 196, 196);" type="text" placeholder="Type your question" i18n-placeholder="@@typeYourQn" [(ngModel)]="searchText" (keyup)="search($event)" (blur)="logSearch()" />
      </div>
      <div class="tw-bg-blue-100 tw-mb-4" *ngFor="let i = index; let qna of filteredQA" [id]='"faq-"+i'>
        <div class="tw-p-4 tw-flex tw-items-center" style="flex-flow: wrap;" (click)="expandCollapse(qna, i, container)">
          <div class="tw-w-[90%] tw-text-blue-500 tw-text-300" [innerHTML]='qna.get("question")'></div>
          <div class="tw-w-[10%] tw-text-blue-500 tw-text-right">
            <em class="cs-icon-arrow tw-align-middle" style="transform: rotate(90deg)"></em>
          </div>
        </div>
        <div class="tw-hidden tw-border-t tw-border-t-gray-200 tw-p-4 tw-text-300" [innerHTML]='qna.get("answerHTML")'></div>
      </div>
      <div class="tw-my-8 tw-flex tw-justify-center tw-items-center tw-w-full tw-text-200" *ngIf="!loading && !filteredQA.length">
        <a class="tw-inline-flex tw-bg-gray-100 tw-text-gray-400 tw-p-4" style="white-space: normal" href="mailto:hello@cureskin.com?Subject=Enquiry, {{user.get('MobileNumber')}}, {{user.get('PatientName')}}">
          <div class="tw-inline-block" i18n="@@contactUsForMore">
            Contact us for more queries
          </div>
          <span class="tw-pl-3 tw-text-blue-400">hello&#64;cureskin.com</span>
        </a>
      </div>
    </div>
  </div>
</div>
