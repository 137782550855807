import { ConnectionService } from '@services/connection-service';
import { inject, Injectable } from '@angular/core';
import { Onboarding } from './onboarding';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private connectionService: Record<string, any> = inject(ConnectionService);

  getService(): Onboarding {
    const user = this.connectionService.getActingUser();
    const isUserWithoutMainConcern = this.connectionService.userWithoutPrivateMainConcern();

    return new Onboarding(JSON.parse(JSON.stringify(user)), isUserWithoutMainConcern);
  }
}
