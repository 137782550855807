import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { ConcernAccordionComponent } from './concern-accordion.component';

@NgModule({
  imports: [
    CommonModule,
    CsIconModule,
  ],
  declarations: [ConcernAccordionComponent],
  exports: [ConcernAccordionComponent],
})
export class ConcernAccordionModule {}
