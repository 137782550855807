import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { BroadcastService } from '@services/broadcast-service';
import { WindowRefService } from '@services/window-ref-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'onboarding-checkup-detections',
  templateUrl: './onboarding-checkup-detections.html',
  styleUrls: ['../onboarding.scss'],
})
export class OnboardingCheckupDetectionsComponent {
  ui: { loading: boolean, isDetectionFetched: boolean } = { loading: false, isDetectionFetched: false };
  user: any;
  isDetectionFoundInPhotos: boolean;
  detectedIssues: string[] = [];
  pastSkinTreatment: string;
  consultationSession: any;
  popUp: { open?: boolean } = { open: false };
  mainConcerns: any[] = [];
  selectedConcernIndices: number[] = [];

  constructor(private conn: ConnectionService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    public appConfig: AppConfig,
    private broadcastService: BroadcastService,
    private windowService: WindowRefService,
    private eventLoggerService: EventLoggerService) {
  }

  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    const { queryParams }: any = this.route.snapshot;
    const checkups: any[] = await this.conn.fetchInstantCheckup({
      userId: this.user.get('username'),
      id: queryParams.checkupIds.split(','),
    });
    this.conn.updateExperimentLogsInUser({ onboarding_detection_page_visited: true });
    this.eventLoggerService.trackEvent('onboarding_detection_page_visited', {});
    await this.fetchMainConcerns();
    this.findMainConcernToDetectionMapping(checkups);
    this.ui.isDetectionFetched = true;
    this.consultationSession = await this.conn.findConsultationSession(this.appConfig.Shared.Regimen.Class.FACE);
  }

  findMainConcernToDetectionMapping(checkups: any[]): any {
    checkups.forEach((checkup_: any): void => {
      checkup_.aiResponse.result.forEach((each: any): void => {
        if (each.Condition === 'Detected') {
          const mainConcernOfDetectionIndex = this.mainConcerns
            .findIndex((eachConcern: any): boolean => eachConcern.aiClassName && eachConcern.aiClassName.includes(each.class));
          const mainConcernOfDetection = this.mainConcerns[mainConcernOfDetectionIndex];
          if (mainConcernOfDetection && !this.detectedIssues.includes(mainConcernOfDetection.key)) {
            this.selectedConcernIndices.push(mainConcernOfDetectionIndex);
            this.detectedIssues.push(mainConcernOfDetection.key);
          }
        }
      });
    });
    this.detectedIssues = this.detectedIssues.splice(0, 3);
    this.selectedConcernIndices = this.selectedConcernIndices.splice(0, 3);
    this.isDetectionFoundInPhotos = !!this.detectedIssues.length;
  }

  async fetchMainConcerns(): Promise<void> {
    let mainConcerns: any[] = await this.conn.findMainConcernsOptions({ class: this.appConfig.Shared.Regimen.Class.FACE,
      section: 'TREATMENT',
      visibleToUser: { $ne: false } });
    mainConcerns = mainConcerns.sort((item1: any, item2: any): number => {
      const rankOfItem1 = item1.get('rank') || 999999;
      const rankOfItem2 = item2.get('rank') || 999999;
      return rankOfItem1 - rankOfItem2;
    });
    mainConcerns.forEach((concern: any): void => {
      concern.get('keywords').forEach((item: any): void => {
        this.mainConcerns.push({
          value: concern.get('value'),
          key: item.key,
          keyInEnglish: item.keyInEnglish || '',
          class: concern.get('class'),
          aiClassName: concern.get('aiClassName'),
          rank: concern.get('rank'),
          description: (item.description || concern.get('description')),
          section: concern.get('section'),
        });
      });
    });
  }

  async saveNotesAndRedirectToChat(): Promise<void> {
    this.ui.loading = true;
    const concerns = this.mainConcerns
      .filter((each: any, index: number): boolean => this.selectedConcernIndices.includes(index))
      .sort((value1: any, value2: any): number => value1.rank - value2.rank)
      .map((each: any): void => each.value);
    try {
      await this.conn.saveUserMainConcern(concerns);
      if (this.pastSkinTreatment) {
        this.consultationSession.set('PastSkinTreatment', this.pastSkinTreatment);
        await this.consultationSession.save();
        this.conn.updateExperimentLogsInUser({ onboarding_detection_main_concern_selected: true });
        this.eventLoggerService.trackEvent('onboarding_detection_main_concern_selected', { concerns });
      }
    } catch (error) {
      this.broadcastService.broadcast('NOTIFY', { message: error.toString() });
    }
    this.ui.loading = false;
    await this.conn.navigateToURL('/user?tab=chat', false, true);
  }

  async selectConcern(index: number): Promise<void> {
    const concernIndex = this.selectedConcernIndices.findIndex((each: number): boolean => each === index);
    if (concernIndex >= 0) {
      this.selectedConcernIndices.splice(concernIndex, 1);
      return;
    }
    if (this.selectedConcernIndices.length === 3) {
      this.broadcastService.broadcast('NOTIFY', { message: 'Only 3 important concerns are allowed. Unselect others and try again.' });
      return;
    }
    this.selectedConcernIndices.push(index);
  }

  showPopup(): void {
    this.eventLoggerService.trackEvent('onboarding_detection_main_concern_selection_popup', {});
    this.popUp.open = true;
    this.windowService.replaceHash('./#popup', (): void => {
      this.popUp.open = false;
      this.updateSelectedMainConcerns();
    });
  }

  updateSelectedMainConcerns(): void {
    this.detectedIssues = this.selectedConcernIndices.map((each: number): string => this.mainConcerns[each].key);
  }

  back(): void {
    this.broadcastService.broadcast('NAVIGATION_BACK');
  }
}
