import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { ProductCarouselModule } from '@shared/product-carousel/product-carousel.module';
import { BoostYourRegimenComponent } from './boost-your-regimen.component';

@NgModule({
  imports: [CommonModule, ButtonModule, ProductCarouselModule],
  declarations: [BoostYourRegimenComponent],
  exports: [BoostYourRegimenComponent],
})
export class BoostYourRegimenModule {}
