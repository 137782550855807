import * as appActions from './app.actions';
import * as checkoutActions from './checkout.actions';
import * as supportActions from './support.actions';

const fromActions = {
  ...appActions,
  ...checkoutActions,
  ...supportActions,
};

export { fromActions };
