import { Injectable } from '@angular/core';

@Injectable()
export class WindowRefService {
  replaceHash: Function;

  cookieJSONToString(cookies: object, skip: Array<string> = []): string {
    if (!Object.keys(cookies).length) return '';
    return `${Object.keys(cookies)
      .filter((key: string): boolean => !skip.includes(key))
      .map((key: string): string => `${key}=${cookies[key]}`).join(';')};`;
  }

  getWindow(): any {
    return window;
  }

  get nativeWindow(): any {
    return this.getWindow();
  }

  isMobileBrowser(): boolean {
    if (this.nativeWindow.navigator.userAgent.match(/Android/i)
      || this.nativeWindow.navigator.userAgent.match(/webOS/i)
      || this.nativeWindow.navigator.userAgent.match(/iPhone/i)
      || this.nativeWindow.navigator.userAgent.match(/iPad/i)
      || this.nativeWindow.navigator.userAgent.match(/iPod/i)
      || this.nativeWindow.navigator.userAgent.match(/BlackBerry/i)
      || this.nativeWindow.navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    }
    return false;
  }

  isSafariBrowser(): boolean {
    if (this.nativeWindow.navigator.vendor.toLowerCase().match(/apple/i)
      || this.nativeWindow.navigator.userAgent.match(/iPhone/i)
      || this.nativeWindow.navigator.userAgent.match(/iPad/i)
      || this.nativeWindow.navigator.userAgent.match(/iPod/i)
    ) {
      return true;
    }
    return false;
  }

  getUserAgent(): string {
    return this.nativeWindow.navigator.userAgent;
  }
}
