import { Component, OnDestroy, OnInit } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { fromSelectors } from '@store/selectors';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { fromActions } from '@store/actions';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventLoggerService } from '@services/event-logger-service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { NotificationPermissionSheetComponent } from
  '@shared/bottom-sheet-layouts/notification-permission-sheet/notification-permission-sheet.component';
import { Table } from 'api-client';
import { AppConfig } from '../app.config';

@Component({
  selector: 'replacement',
  templateUrl: './support.html',
  styleUrls: ['./support.scss'],
})
export class SupportComponent implements OnInit, OnDestroy {
  ViewTypes: {
    FirstPage: 'FirstPage';
    NextPage: 'NextPage';
    SingleView: 'SingleView';
  } = { FirstPage: 'FirstPage', NextPage: 'NextPage', SingleView: 'SingleView' };
  subscriptions: Array<Subscription> = [];
  user: any;
  question: any;
  isHelpfull: boolean;
  showChatSupportPrompt: boolean;
  showCallPopup: boolean;
  ticketInProcess: boolean;
  exploreSectionExperiment: boolean = false;
  questions: Array<string>;
  viewType: string = this.ViewTypes.FirstPage;
  inProcess: boolean = true;
  queriesCount: number;
  getSupportQuestionFetch: Observable<Array<any>> = this.store.select(fromSelectors.selectSupportQuestions);
  recentSupportTicketList$: Observable<Record<string, any>[]> = this.store.select(fromSelectors.selectSupportOpenTicketList);
  userStatus: any = { PAID: false };
  selectedIndex: number = -1;
  festivalDelayAlertEnabled: boolean;
  returnedFrom: string;
  isNotificationPermissionEnabled: any;

  constructor(
    private eventLogger: EventLoggerService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private router: Router,
    public appConfig: AppConfig,
    private broadcastService: BroadcastService,
    private connectionService: ConnectionService,
    private bottomSheet: MatBottomSheet,
    private appBridge: AppWebBridgeService,
  ) {
    this.subscribe();
  }

  back(): void {
    this.router.navigate(['/home'], {});
  }

  async ngOnInit(): Promise<void> {
    this.user = this.connectionService.getActingUser();
    this.returnedFrom = this.activatedRoute.snapshot.queryParams?.returnedFrom;
    this.findUserStatus();
    if (!this.question.objectId) {
      this.updateQueryCount();
    }
    const experiments = await this.connectionService.findUserActiveExperiments();
    this.festivalDelayAlertEnabled = !!experiments.find((experiment: any): any => experiment.key === 'festival_delay_banner_enabled');
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'support' }));
    const notificationExperiment = experiments.find((item:any):any => item.key === 'notification_permission_popup');
    this.exploreSectionExperiment = experiments.find((experiment: any): any => experiment.key === 'explore_tab');
    if (notificationExperiment) {
      setTimeout((): void => this.openBottomSheet(), 1000);
    }
  }

  openBottomSheet(): void {
    // call function to check permission statustrue or false
    // if true then perimssionn already given
    this.isNotificationPermissionEnabled = this.appBridge.requestNotificationStatus();
    if (!this.isNotificationPermissionEnabled && this.returnedFrom === 'supportTicket') {
      this.bottomSheet.open(NotificationPermissionSheetComponent, {
        data: {
          content: 'support-ticket',
          callback: (): void => {
            this.openNativePermissionPopup();
          },
        },
      });
    }
  }

  openNativePermissionPopup(): void {
    this.appBridge.requestNotificationPermission();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((each: Subscription): void => each.unsubscribe());
    this.subscriptions = [];
  }

  async viewTicket(query: { [key: string]: any }): Promise<void> {
    await this.router.navigate([`/support/ticket/${query.objectId}`], { queryParams: { username: this.user?.get('username') } });
  }

  private subscribe(): void {
    this.subscriptions.push(this.activatedRoute.params.subscribe((params: Params): void => {
      this.isHelpfull = undefined;
      if (params.id) {
        this.question = { objectId: params.id };
        this.viewType = this.ViewTypes.NextPage;
        this.store.dispatch(fromActions.GetSupportQuestionFromStore({
          query: { where: { objectId: params.id, active: true } },
          context: { inputs: { ...this.activatedRoute.snapshot.queryParams } },
        }));
      } else {
        this.question = { outputComponent: this.appConfig.Shared.Support.component.SELECT_SUB_QUERY };
        this.viewType = this.ViewTypes.FirstPage;
        this.store.dispatch(fromActions.GetSupportQuestionFromStore({
          query: { where: { start: true, active: true }, descending: 'rank' },
        }));
        this.store.dispatch(fromActions.SupportRecentTicketFetchBegin({
          status: [this.appConfig.Shared.Ticket.status.Pending, this.appConfig.Shared.Ticket.status.RequestCloser],
        }));
      }
    }));
    this.subscriptions.push(this.store.select(fromSelectors.selectSupportQuestions).subscribe((questions: Array<any>): void => {
      if (!questions) {
        return;
      }
      if (questions.length === 1 && questions[0].objectId === this.question.objectId) {
        this.question = questions[0];
        const supportQuestion = new Table.SupportQuestion();
        supportQuestion.id = this.question.objectId;
        this.store.dispatch(fromActions.GetSupportQuestionFromStore({
          query: { where: { parentSupportQuestions: supportQuestion, active: true } },
        }));
        return;
      }
      this.inProcess = false;
      this.user = this.connectionService.getActingUser();
      const userLanguage = this.user?.get('languagePreference') || this.appConfig.Shared.Languages.EN;
      if (this.user?.isPaid()) {
        this.questions = questions
          .filter((question: any): any => question?.objectId === '8L9eKnSUPe')
          .map((question: any): any => ({ ...question, question: this.getNewQueryString(userLanguage), listViewType: 'Next' }));
      } else {
        this.questions = questions;
      }
    }));
  }

  getNewQueryString(userLanguage: any): string {
    switch (userLanguage) {
      case (this.appConfig.Shared.Languages.HI): return 'नया सवाल';
      case (this.appConfig.Shared.Languages.KN): return 'ಹೊಸ ಪ್ರಶ್ನೆ';
      case (this.appConfig.Shared.Languages.TE): return 'కొత్త ప్రశ్న';
      case (this.appConfig.Shared.Languages.TA): return 'புதிய கேள்வி';
      case (this.appConfig.Shared.Languages.MR): return 'नवीन प्रश्न';
      case (this.appConfig.Shared.Languages.BN): return 'নতুন প্রশ্ন';
      case (this.appConfig.Shared.Languages.ML): return 'പുതിയ ചോദ്യം';
      case (this.appConfig.Shared.Languages.EN): return 'New query';
      default: return 'New query';
    }
  }

  /**
   * Logs the helpfull status of that question (like / dislke) and shows a popup for chat support when ppl click on dislike.
   *
   * Don't show popup and chat support if the support section is `place a new order`
   * ie `outputComponent !== 'PLACE_NEW_ORDER'`
   * Don't show popup and chat support if the support section is `issue with my order`
   * ie `objectId !== 'GTjJHupZWB'`
   *
   * @param {boolean} isHelpfull
   */
  async updateFeedback(isHelpfull: boolean): Promise<void> {
    this.isHelpfull = isHelpfull;
    this.eventLogger.trackEvent('SUPPORT_HELPFULL_FEEDBACK', { questionId: this.question.objectId, isHelpfull });

    if (
      isHelpfull === false
      && this.question.treeNode
      && this.question.outputComponent !== this.appConfig.Shared.Support.component.PLACE_NEW_ORDER
      && this.question.objectId !== 'GTjJHupZWB'
    ) {
      this.showChatSupportPrompt = true;
    }
  }

  onCallNeeded(): void {
    this.eventLogger.trackEvent('SUPPORT_CALL_REQUESTED', { questionId: this.question.objectId, callPressed: true });
  }

  /**
   * Creates support ticket for the question and routes to ticket view.
   */
  async createTicket(): Promise<void> {
    if (this.ticketInProcess) return;
    this.ticketInProcess = true;
    try {
      const { supportTicketId }: { supportTicketId: string } = await this.connectionService.createTicket(this.question.objectId);
      this.eventLogger.trackEvent('SUPPORT_TICKET_CREATED_ON_DISLIKE', { questionId: this.question.objectId });
      await this.router.navigate([`/support/ticket/${supportTicketId}`], { replaceUrl: true });
    } finally {
      this.ticketInProcess = false;
    }
  }

  async viewAllQuery(): Promise<void> {
    await this.connectionService
      .navigateToURL(`/support/tickets?username=${this.activatedRoute.snapshot.queryParams.username || this.user?.get('username')}`);
  }

  private async updateQueryCount(): Promise<void> {
    this.queriesCount = await this.connectionService.fetchSupportTicketCount(this.user);
  }

  cameraClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'help-camera' }));
    this.connectionService.navigateToURL('/user/instantCheckup');
  }

  cameraClickHome(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-camera' }));
    this.connectionService.navigateToURL('/user/instantCheckup');
  }

  truncateString(str: string, maxLength: number): string {
    if (str?.length > maxLength) {
      return `${str.slice(0, maxLength)}...`;
    }
    return str || '';
  }

  shopClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'home-shop' }));
  }
  exploreClick(): void {
    this.eventLogger.cleverTapEvent('click-explore', JSON.stringify({ pageName: 'explore' }));
  }

  onTabChange(index: number): any {
    this.findUserStatus();
    if (index === this.selectedIndex) return 0;
    let queryParams: any;
    switch (index) {
      case 3: {
        queryParams = { tab: 'explore' };
        break;
      }
      case 4: {
        queryParams = { tab: 'shop' };
        break;
      }
      case 2: {
        queryParams = { tab: 'regimen' };
        break;
      }
      case 0:
      default:
        queryParams = { tab: 'home' };
    }
    return this.router.navigate(['/user'], { queryParams });
  }

  async findUserStatus(): Promise<any> {
    if (this.user?.isPaid() && this.user.get('orderState') === 'DELIVERED') this.userStatus.PAID = true;
  }
}
