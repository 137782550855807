import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipeService } from './date.pipe';
import { FilterPipeService } from './filter.pipe';
import { DateSuffixService } from './date-suffix-pipe';
import { TruncateWordsPipe } from './truncate.pipe';

@NgModule({ imports: [CommonModule],
  declarations: [FilterPipeService, DateSuffixService, TruncateWordsPipe],
  exports: [FilterPipeService, DateSuffixService, TruncateWordsPipe] })
export class CustomPipeModule {}
