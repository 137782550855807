<div class="hbox bg-white pos-fix of-auto z-ind-2 pos-top-left" *ngIf="!loading && chat && chat.length">
  <div class="flex-box">
  <div class="flex-heading">
    <toolbar (onAction)="back()" i18n="@@photos">Photos</toolbar>
  </div>
  <div class="flex-content pTB25 max-mobile-width">
    <div class="w100 of-hdn mB25 pB25 bdr-btm" *ngFor="let image of chat">
    <image-card class="w100" [data]="[image]" [inMultiView]="true" (close)="closePopUp($event)"></image-card>
    </div>
  </div>
  </div>
</div>