import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { ToolbarTabsComponent } from './toolbar-tabs.component';
import { Tab1Directive } from './toolbar-tab-1.directive';
import { Tab2Directive } from './toolbar-tab-2.directive';
import { Tab3Directive } from './toolbar-tab-3.directive';
import { Tab4Directive } from './toolbar-tab-4.directive';

@NgModule({
  imports: [CommonModule, DirectivesModule],
  declarations: [ToolbarTabsComponent, Tab1Directive, Tab2Directive, Tab3Directive, Tab4Directive],
  exports: [ToolbarTabsComponent, Tab1Directive, Tab2Directive, Tab3Directive, Tab4Directive],
})
export class ToolbarTabsModule {}
