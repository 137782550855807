import { Component, EventEmitter, Output } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from 'client/app/app.config';
import { Router } from '@angular/router';

@Component({
  selector: 'hair-fall-male-banner',
  templateUrl: './hair-fall-male.component.html',
})
export class HairFallMaleRegimenBannerComponentComponent {
  user: any;
  showBanner: boolean = false;
  regimens: any;
  activeFaceRegimen: any;
  activeHairRegimen: any;
  constructor(
    private eventLogger: EventLoggerService,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    if (this.user.get('Gender') === this.appConfig.Shared.Gender.FEMALE) return;
    const regimens = await this.conn.fetchRegimens(null, true);
    this.regimens = regimens.filter((regimen: any): any => !regimen.expired && regimen.active);
    this.activeHairRegimen = this.regimens.find((regimen: any):any => regimen.delivered && regimen.class
    === this.appConfig.Shared.Regimen.Class.HAIR);
    this.activeFaceRegimen = this.regimens.find((regimen: any):any => regimen.delivered && regimen.class
    === this.appConfig.Shared.Regimen.Class.FACE);
    if (this.activeFaceRegimen && !this.activeHairRegimen) {
      this.showBanner = true;
    }
  }

  handleClick(): void {
    this.navigateTo('/user?tab=regimen&class=HAIR');
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'homepage-hair-fall-cross-sell-male-banner' }));
  }

  navigateTo(url: string = ''): void {
    if (!url) return;
    const queryParams: Record<string, any> = {};
    const [URL, qParamString]: string[] = url.split('?');
    if (qParamString) {
      qParamString.split('&').forEach((each: string): void => {
        const [key, value]: string[] = each.split('=');
        queryParams[key] = value;
      });
    }
    this.router.navigate([URL], { queryParams });
  }
}
