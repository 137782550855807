import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../app.config';
import { LatestReportIds } from '../../app-constant-types';
import { ExtendedFollowUpReport } from '../../server-api.constants';

@Component({ selector: 'report-card', templateUrl: './report-card.html', styleUrls: ['./report-card.scss'] })
export class ReportCardComponent {
  loading: boolean = true;
  @Output() action: EventEmitter<any> = new EventEmitter();
  @Input('data')
  set followUpReport(report: any) {
    this.report = report;
    this.isNew = (new Date().getTime() - report.get('createdAt').getTime()) <= 604800000;
  }
  @Input() index!: number;
  @Input() latestReportIds!: LatestReportIds;
  @Input() userAllReportsWithCheckUpNumber!: ExtendedFollowUpReport[];
  report: any;
  isNew: any;
  user: any;
  newFollowUpUIExperiment: boolean = false;

  constructor(private dom: DomSanitizer,
    private conn: ConnectionService,
    private router: Router,
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((exp: any): void => {
      if (exp.key === 'new_follow_up_flow') {
        this.newFollowUpUIExperiment = true;
      }
    });
    this.loading = false;
  }

  openReport(): void {
    const reportId = this.newFollowUpUIExperiment ? this.report?.objectId : this.report.id;
    const eventName = this.newFollowUpUIExperiment ? 'view-report-from-new-follow-up-page-banner' : 'view-report';

    this.router.navigate([`/user/report/${reportId}`]);
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: eventName }));
  }

  // Check if the previous index has the same date as the current index
  checkIfPreviousIndexHasSameDate(currentIndex: number, currentDate: string): boolean {
    const previousIndex = currentIndex - 1;
    if (previousIndex >= 0) {
      const previousReport = this.userAllReportsWithCheckUpNumber[previousIndex];
      return previousReport.createdAt === currentDate;
    }
    return false;
  }

  // Check if current report is part of a group and if it contains any unread report
  // else if it is not a part of group check if it is an unread report
  tealDotLogic(currentIndex: number): boolean {
    const currentReport = this.userAllReportsWithCheckUpNumber[currentIndex];

    // Check if currentReport is a group anchor
    if (currentReport.groupAnchor) {
      if (currentReport.isGroup) {
        // Check for unread reports in the same date group
        return this.userAllReportsWithCheckUpNumber.some((report: ExtendedFollowUpReport):
          boolean => report.createdAt === currentReport.createdAt && !report.read,
        );
      }
      // Check if it's a non-group anchor and is unread
      return !currentReport.read;
    }

    return false; // Return false if not a group anchor
  }

  // Check if current report is part of a group and if it all are read reports
  // else if it is not a part of group check if it is a read report
  grayDotLogic(currentIndex: number): boolean {
    const currentReport = this.userAllReportsWithCheckUpNumber[currentIndex];
    const previousReport = this.userAllReportsWithCheckUpNumber[currentIndex - 1];
    const nextReport = this.userAllReportsWithCheckUpNumber[currentIndex + 1];

    const isPreviousSameDate = previousReport && currentReport.createdAt === previousReport.createdAt;
    const isNextSameDate = nextReport && currentReport.createdAt === nextReport.createdAt;

    // Check if current index is part of a date group
    if (isPreviousSameDate || isNextSameDate) {
      const allRead = [currentReport, isPreviousSameDate ? previousReport : null, isNextSameDate ? nextReport : null]
        .every((report: ExtendedFollowUpReport): boolean => report === null || report.read);

      // Check if current index is the largest in the group
      const isCurrentLargestInGroup = (!isNextSameDate && !isPreviousSameDate)
        || (isPreviousSameDate && currentIndex > currentIndex - 1)
        || (isNextSameDate && currentIndex > currentIndex + 1);

      // If all reports are read and current index is largest, return false
      return !(allRead && isCurrentLargestInGroup) && allRead;
    }

    // If not part of a group, just check if the current index is read
    return currentReport.read;
  }

  // Check if current report is part of a group and if it contains any unread report
  // else if it is not a part of group check if it is an unread report
  // Also checks if it contains the latest report id either face or hair report
  blinkingDotLogic(currentIndex: number, latestReportIds: LatestReportIds): boolean {
    const currentReport = this.userAllReportsWithCheckUpNumber[currentIndex];
    const previousReport = this.userAllReportsWithCheckUpNumber[currentIndex - 1];
    const nextReport = this.userAllReportsWithCheckUpNumber[currentIndex + 1];

    const isPreviousSameDate = previousReport && currentReport.createdAt === previousReport.createdAt;
    const isNextSameDate = nextReport && currentReport.createdAt === nextReport.createdAt;

    // Check if current index is part of a date group
    if (isPreviousSameDate || isNextSameDate) {
      const hasUnread = this.tealDotLogic(currentIndex); // Check for unread reports in the group

      const hasLatestReport = (isPreviousSameDate
          && [latestReportIds.latestFaceReportId, latestReportIds.latestHairReportId].includes(previousReport.objectId))
        || (isNextSameDate
          && [latestReportIds.latestFaceReportId, latestReportIds.latestHairReportId].includes(nextReport.objectId));

      // Return true if there's any unread report and at least one is the latest report
      return hasUnread && hasLatestReport;
    }

    // Check if the current report is unread and is a latest report
    return !currentReport.read
      && [latestReportIds.latestFaceReportId, latestReportIds.latestHairReportId].includes(currentReport.objectId);
  }
}
