<div class="font-noto tw-flex tw-items-center tw-relative tw-px-7 tw-py-5 tw-bg-white">
  <div class="tw-absolute tw-p-1 tw-rounded-full tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-[10px] tw-right-[10px] close-sheet" style="width: 35px; height: 35px" (click)="closeBottomSheet()">
    <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
  </div>
  <div>
    <img class="tw-rounded-full tw-absolute tw-w-[120px] tw-z-[100] tw-top-[-60px]" src="/assets/images/dr-jisha.png" (click)="closeBottomSheet()" />
    <h2 class="tw-text-lg tw-text-700 tw-pt-12" *ngIf="popUpType=='HOW_REGIMEN'">
      <span class="tw-font-bold" i18n="@@howIsTreatmentPrepared">How your treatment kit is prepared?</span>
      <p class="tw-mt-4 tw-text-400 tw-text-gray-500 tw-font-bold" i18n="@@treatmentPrep-1">
        The regimen is prescribed by our expert dermatologist as per the answers
        you've responded and on the basis of your photographs.
      </p>
      <p class="tw-mt-4 tw-text-400 tw-text-gray-500" i18n="@@treatmentPrep-2">
        All the products in your regimen are 100% safe, paraben free &
        clinically tested.
      </p>
      <p class="tw-mt-4 tw-text-400 tw-text-gray-500" i18n="@@treatmentPrep-3">
        All the CureSkin products has been formulated after doing in-depth
        research and tested thoroughly.
      </p>
    </h2>
    <h2 class="tw-text-lg tw-text-700 tw-pt-10" *ngIf="popUpType=='WHY_PHOTO'">
      <span class="tw-font-bold tw-pr-2" i18n="@@whyPhoto">Why we ask for your photo?</span>
      <p class="tw-my-2 tw-text-400 tw-text-gray-500 tw-font-bold" i18n="@@whyAskPhoto-1">
        We agree you know your skin. However, your skin issues can be better
        identified clinically by a dermatologist.
      </p>
      <p class="tw-my-2 tw-text-400 tw-text-gray-500" i18n="@@whyAskPhoto-2">
        Your photos help your dermatologist to know how severe your issue is and
        plan your treatment better. So, more photos mean better diagnosis!
      </p>
      <p class="tw-my-2 tw-text-400 tw-text-gray-500" i18n="@@whyAskPhoto-3">
        We respect your privacy and never share your details or photos with
        anyone, except your dermatologist.
      </p>
    </h2>
    <cs-button class="tw-mt-2 tw-mb-5" [class]="'w100 primary btn btn-padding tw-px-6 btn-float'" (click)="closeBottomSheet()"><cs-button-text><span class="tw-font-body tw-tracking-normal" i18n="@@continueChat">Continue</span><span>&nbsp; ></span></cs-button-text></cs-button>
  </div>
</div>