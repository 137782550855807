<div class="tw-block tw-w-full tw-h-full tw-border-spacing-0 tw-overflow-hidden tw-font-body tw-font-semibold">
  <div class="tw-flex tw-flex-col tw-flex-nowrap tw-h-full">
    <div class="tw-w-full tw-flex-initial">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
             (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50"
                   [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white"
              i18n="@@Select Photo Type">Select photo type</span>
      </div>
    </div>
    <div
         class="tw-w-full tw-flex tw-flex-col tw-flex-nowrap tw-items-center tw-justify-evenly tw-p-[10px] tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto">
      <div class="tw-w-full tw-max-w-[400px] tw-flex tw-flex-wrap tw-items-center tw-justify-evenly">
        <div class="tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
             rippleEffect="rippleEffect"
             rippleClass="ripple-black"
             (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.FRONT_FACE)">
          <div class="tw-py-3 tw-px-[7px] tw-text-[15px]">
            <translate-string [key]="appConfig.Shared.InstantCheckup.Type.FRONT_FACE"></translate-string>
          </div>
          <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
               *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
               src="https://cdn.cureskin.com/app/img/take-photo-front-face.jpg" />
          <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
               *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
               src="https://storage.googleapis.com/heallocontent/app/img/take-photo-front-face_male.jpg" />
        </div>
        <div class="imgHolder tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
             rippleEffect="rippleEffect"
             rippleClass="ripple-black"
             (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.SIDE_FACE)">
          <div class="tw-py-3 tw-px-[7px] tw-text-[15px]">
            <translate-string [key]="appConfig.Shared.InstantCheckup.Type.SIDE_FACE"></translate-string>
          </div>
          <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
               *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
               src="https://cdn.cureskin.com/app/img/take-photo-side-face.jpg" />
          <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
               *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
               src="https://storage.googleapis.com/heallocontent/app/img/take-photo-side-face_male.jpg" />
        </div>
      </div>
      <div class="tw-w-full tw-max-w-[400px] tw-flex tw-flex-wrap tw-items-center tw-justify-evenly">
        <div class="tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
             rippleEffect="rippleEffect"
             rippleClass="ripple-black"
             (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.HAIR_FRONT)">
          <div class="tw-py-3 tw-px-[7px] tw-text-[15px]">
            <translate-string [key]="appConfig.Shared.InstantCheckup.Type.HAIR_FRONT"></translate-string>
          </div>
          <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
               *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
               src="https://cdn.cureskin.com/app/img/take-photo-front-face.jpg" />
          <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
               *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
               src="https://storage.googleapis.com/heallocontent/app/img/take-photo-front-face_male.jpg" />
        </div>
        <div class="tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
             rippleEffect="rippleEffect"
             rippleClass="ripple-black"
             (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.HAIR_TOP)">
          <div class="tw-py-3 tw-px-[7px] tw-text-[15px]">
            <translate-string [key]="appConfig.Shared.InstantCheckup.Type.HAIR_TOP"></translate-string>
          </div>
          <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
               *ngIf="user?.get('Gender') !== appConfig.Shared.Gender.MALE"
               src="https://cdn.cureskin.com/app/img/take-photo-hair-top.jpg" />
          <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
               *ngIf="user?.get('Gender') === appConfig.Shared.Gender.MALE"
               src="https://storage.googleapis.com/heallocontent/app/img/take-photo-hair-top_male.jpg" />
        </div>
      </div>
      <div class="tw-w-full tw-max-w-[400px] tw-flex tw-flex-wrap tw-items-center tw-justify-evenly">
        <div class="tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
             *ngIf="hasBodyRegimen"
             rippleEffect="rippleEffect"
             rippleClass="ripple-black"
             (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.BODY)">
          <div class="tw-py-3 tw-px-[7px] tw-text-[15px]">
            <translate-string [key]="appConfig.Shared.InstantCheckup.Type.BODY"></translate-string>
            <span class="pL5"
                  i18n="@@photo">Photo</span>
          </div>
          <img class="tw-w-[92px] tw-inline-block tw-my-0 tw-mx-auto"
               src="https://cdn.cureskin.com/app/img/take-photo-body.jpg" />
        </div>
        <div class="tw-w-[145px] tw-h-[150px] tw-overflow-hidden tw-border tw-border-solid tw-border-gray-200 tw-text-center tw-rounded-1 tw-flex tw-flex-col tw-flex-nowrap tw-justify-between "
             rippleEffect="rippleEffect"
             rippleClass="ripple-black"
             (callback)="showPreview(appConfig.Shared.InstantCheckup.Type.REPORT)">
          <div class="tw-py-3 tw-px-[7px] tw-text-[15px]"
               i18n="@@uploadReports">Other Photos or Reports</div>
          <img class="tw-w-[55px] tw-inline-block tw-my-0 tw-mx-auto"
               src="https://cdn.cureskin.com/app/img/upload-report.svg" />
        </div>
      </div>
    </div>
    <div class="tw-w-full tw-flex-[0_1_0px] ">
      <div class="tw-text-center tw-uppercase tw-text-green-400 tw-text-200 tw-pt-1 tw-px-0 tw-pb-6 tw-tracking-[1px] tw-font-semibold"
           (click)="viewUploadedPhotos()"
           routerLink="/user/instantCheckup/list"
           i18n="@@viewUploadedPhotos"> View Uploaded Photos </div>
    </div>
  </div>
</div>
<file-picker (onFileChange)="onFileChange($event)"
             accept="image/jpeg"></file-picker>
<file-picker (onFileChange)="uploadImageFromNativeCamera($event)"
             accept="image/jpeg"></file-picker>