import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-diet-plan',
  templateUrl: './diet-plan.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class DietPlanComponent implements OnChanges {
  @Input('userStatus')userStatus:any;
  @Output('openDiet')openDiet:EventEmitter<void> = new EventEmitter();
  constructor() { }
  ngOnChanges(): void {}
  handleClick():void {
    this.openDiet.emit();
  }
}
