<div class="font-noto tw-flex tw-items-center tw-relative tw-px-2 tw-py-4">
    <div class="tw-absolute tw-p-1 tw-rounded-full tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-[15px] tw-right-[10px] close-sheet" style="width: 35px; height: 35px" (click)="closedPopup()" id="closedPopupBtn">
        <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
      </div>

    <div class="tw-w-full tw-bg-white rounded-lg tw-p-2 tw-flex tw-flex-col tw-pt-10">
        <div  class="tw-flex tw-justify-center">
            <div class="tw-p-2 tw-justify-center" >
            <cs-icon class="tw-h-20 tw-w-20 tw-font-bold tw-text-600 tw-text-green-500 " [iconName]="'delete_bin'"></cs-icon>
        </div>
        </div>

          <!--  -->
          <div class="bold">
            <div class="tw-p-2 tw-text-center">
                <div class="font-noto tw-font-bold tw-text-900 tw-text-[25px]" i18n="@@deletePhotoWarning" id="deletePhotoWarning">
                    <h2 class="tw-text-lg tw-font-semibold tw-text-700 tw-m-auto tw-text-center">
                        Are you sure, you want to delete your photo?
                    </h2>
                </div>
               
            </div>
        </div>
        <!--  -->

        <div>
            <div class="tw-p-2 tw-text-center">
                <div class="tw-px-4 text-base font-noto tw-text-600"
                [ngClass]="{'tw-text-[14px]': ['ta', 'te', 'ml', 'kn'].includes(user?.get('languagePreference'))}"
                >
                    {{content}}
                </div>
            </div>
        </div>

        <div class="tw-mt-4 tw-flex tw-flex-col tw-justify-between  tw-px-[10px]">
            <div>
                <cs-button class="tw-my-2" [class]="'w100 primary btn btn-padding tw-px-6 btn-float'" (click)="takeNewPhoto()" id="takeNewPhotoBtn"><cs-button-text><span class="tw-font-body tw-tracking-normal" i18n="@@takeNewPhoto">Take New Photo</span><span>&nbsp; ></span></cs-button-text></cs-button>
            </div>
            <div class="tw-flex tw-items-center tw-justify-center tw-pl-4 tw-pr-3 tw-h-10 tw-font-body tw-font-bold tw-text-300 tw-text-black-400 tw-rounded-full"
            (click)="deleteCurrentPhoto()" id="deleteCurrentPhotoBtn">
                <span class="tw-lg"
                i18n="@@deleteCurrentPhoto">Delete Current Photo</span>
            </div>
        </div>

    </div> 
</div>