<div class="font-noto tw-flex tw-items-center tw-flex-col tw-relative tw-px-7 tw-py-5 tw-bg-white tw-w-full tw-bprder tw-rounded-tr-[25px] tw-rounded-tl-[25px]">
    <div class="tw-absolute tw-p-1 tw-rounded-full tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-[10px] tw-right-[10px] close-sheet" style="width: 35px; height: 35px" (click)="closeBottomSheet()">
        <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
    </div>
    <div class="tw-w-full tw-mb-2">
        <h2 class="tw-text-600 tw-text-bold tw-pt-12 tw-font-body tw-text-black tw-mb-2" i18n="@@sorryToHear">We're sorry to hear that</h2>
        <p class="tw-text-300 tw-font-body tw-text-black" i18n="@@shareConcern">Please share your concern to request a callback from our support team</p>
    </div>
    <div class="tw-w-full">
        <div class="tw-mb-5 tw-mx-auto tw-w-full">
            <textarea class="tw-w-full tw-rounded-[10px] tw-py-4 tw-px-5 tw-bg-gray-100 tw-h-[165px]"
                style="box-shadow: none; border: 1px solid rgb(196, 196, 196);"
                placeholder="Write your query"
                i18n-placeholder="@@writeQuery"
                [(ngModel)]="feedbackValue">
            </textarea>
        </div>
    </div>
    <cs-button
        class="tw-w-full primary tw-bg-teal-600 tw-rounded-[24px]"
        [ngClass]="{'tw-opacity-50 tw-pointer-events-none': !feedbackValue}"
        (callback)="updateFeedbackAndCreatePendingCall(true)">
        <cs-button-text class="tw-normal-case tw-text-500 tw-font-bold tw-text-gray-50">
            <span class="tw-font-body tw-tracking-normal" i18n="@@requestCall">Request a callback</span>
            <span>&nbsp; ></span>
        </cs-button-text>
    </cs-button>
    <div class="tw-mb-3 tw-mt-2" [ngClass]="{'tw-opacity-50 tw-pointer-events-none': !feedbackValue}" (click)="updateFeedbackAndCreatePendingCall(false)">
        <p class="tw-text-300 tw-font-body tw-text-black tw-font-bold tw-cursor-pointer" i18n="@@noRequestCall">No, i don’t want a callback</p>
    </div>
</div>
