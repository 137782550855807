import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button/index';
import { LoadingModule } from '@components/loading/index';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { DirectivesModule } from '@directives/directives.module';
import { PopUpModule } from '@components/pop-up/index';
import { FeedbackModule } from '../../feedback/feedback.module';
import { ConfirmationComponent } from './confirmation.component';

@NgModule({ imports: [CommonModule,
  ButtonModule,
  LoadingModule,
  FeedbackModule,
  DirectivesModule,
  CustomPipeModule,
  PopUpModule,
],
declarations: [ConfirmationComponent],
exports: [ConfirmationComponent] })
export class ConfirmationModule {}
