import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-voice-note-banner',
  templateUrl: './voice-note-banner.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class VoiceNoteBannerComponent implements OnChanges {
@Input('docImg') docImg:string;
@Input('voiceUrl') voiceUrl:string;
@Input('trackEventAudio') trackEventAudio:any;
@Input('OrderApproval') OrderApproval:any;
@Output('trackVoiceEvent') trackVoiceEvent:EventEmitter<any> = new EventEmitter();
@Input('swiperCardsBanner') swiperCardsBanner:any;
ngOnChanges(): void {}
handleClick(event:any):void {
  this.trackVoiceEvent.emit(event);
}
}
