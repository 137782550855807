/* eslint-disable arrow-body-style */
import { createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers';
import * as appSelectors from './app.selectors';
import * as Types from '../modals';

// ********** ADDRESS BOOK ************ //

export const selectAddressBookState = createSelector(
  appSelectors.selectApp,
  (appState: fromReducer.AppStateI): Types.AddressBookStateI => appState.addressBook,
);
export const selectAddressBookList = createSelector(
  selectAddressBookState,
  (addressBook: Types.AddressBookStateI): Types.AddressBookI[] => addressBook.addressList,
);
export const selectDefaultAddress = createSelector(
  selectAddressBookState,
  (addressBook: Types.AddressBookStateI): Types.AddressBookI => addressBook.defaultAddress,
);
export const selectIsFetchingAddress = createSelector(
  selectAddressBookState,
  (addressBook: Types.AddressBookStateI): boolean => addressBook.isFetchInProgress,
);
export const selectAddressSaveInProcess = createSelector(
  selectAddressBookState,
  (addressBook: Types.AddressBookStateI): boolean => addressBook.saveInProcess,
);

// ********** CHECKOUT ************ //

export const selectCheckout = createSelector(
  appSelectors.selectApp,
  (appState: fromReducer.AppStateI): Types.CheckoutI => appState.checkout);
export const selectCheckoutProgress = createSelector(
  selectCheckout,
  (addressBook: Types.CheckoutI): boolean => {
    return addressBook.checkoutInProgress;
  },
);

// ********** ORDER STATE ************ //

export const selectOrderState = createSelector(
  appSelectors.selectApp,
  (appState: fromReducer.AppStateI): Types.OrderSateI => appState.orderState,
);

export const selectOrderPayment = createSelector(
  selectOrderState,
  (orderState: Types.OrderSateI): object => orderState.payment);

export const selectOrderRetryInProcess = createSelector(
  selectOrderState,
  (orderState: Types.OrderSateI): boolean => orderState.inProcess);

export const selectOrderSurpriseGiftClaimPending = createSelector(
  selectOrderState,
  (orderState: Types.OrderSateI): boolean => orderState.surpriseGiftClaimPending);

export const selectOrders = createSelector(
  selectOrderState,
  (orderState: Types.OrderSateI): any[] => orderState.orders);
