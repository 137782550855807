<div class="tw-mx-4 tw-mb-8 tw-p-4 tw-bg-white tw-overflow-hidden wrapper">
  <div class="tw-flex tw-items-center tw-gap-2 tw-pb-4 section-top">
    <img class="tw-w-16 tw-h-16 tw-shrink-0 tw-object-contain tw-rounded-[50%] tw-bg-gray-300" [src]="doctorDisplayImage" alt="Doctor">
    <div>
      <div class="tw-uppercase tw-text-300 tw-font-bold tw-text-black">Your Skin</div>
      <div class="tw-uppercase tw-text-300 tw-font-bold tw-text-black">Improvement Journey</div>
      <p class="tw-text-200 tw-font-normal tw-text-gray-700">based on your skin cycle, & top 3 concerns.</p>
    </div>
  </div>
  <ul class="tw-mt-4">
    <ng-container
      *ngFor="let step of (showFirstJourney ? journeySteps.firstJourney : journeySteps.notFirstJourney); let i = index; last as isLast">
      <li class="tw-flex tw-gap-2">
        <div class="tw-flex tw-flex-col tw-items-center">
          <div class="tw-flex tw-justify-center tw-items-center tw-w-40 tw-h-8 tw-text-200 tw-font-medium" [ngClass]="{
              'tw-text-gray-75': i === 0 || i === 1,
              'tw-text-black': i === 2 || i === 3,
              'tw-bg-teal-500': i === 0,
              'tw-bg-teal-400': i === 1,
              'tw-bg-teal-200': i === 2,
              'tw-bg-teal-100': i === 3
            }">
            {{step.name}}
          </div>
          <cs-icon *ngIf="!isLast" class="tw-justify-center tw-h-6" [iconName]="'arrow_down'"></cs-icon>
        </div>
        <div class="tw-flex tw-items-center tw-h-8 tw-text-200 tw-font-normal tw-text-gray-500">{{step.duration}}</div>
      </li>
    </ng-container>
  </ul>
</div>