import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CsIconModule } from '@components/icon';
import { HttpClientModule } from '@angular/common/http';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';

import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';
import { ReAcquisitionTakePhotoComponent } from './re-acquisition-take-photo/re-acquisition-take-photo.component';
import { ReAcquisitionUserQueryComponent } from './re-acquisition-user-query/re-acquisition-user-query.component';
import { ReAcquisitionUserWaitingForCallComponent } from './re-acquisition-waiting-for-call/re-acquisition-waiting-for-call.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CsIconModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoadingModule,
    ButtonModule,
    RouterModule.forChild([{
      path: 're-acquisition',
      canActivate: [authenticationGuard, onboardingGuard],
      children: [
        {
          path: 'take-photo',
          component: ReAcquisitionTakePhotoComponent,
        },
        {
          path: 'user-query',
          component: ReAcquisitionUserQueryComponent,
        },
        {
          path: 'waiting-for-call',
          component: ReAcquisitionUserWaitingForCallComponent,
        },
      ],
    }]),
  ],
  declarations: [
    ReAcquisitionTakePhotoComponent,
    ReAcquisitionUserQueryComponent,
    ReAcquisitionUserWaitingForCallComponent,
  ],
})
export class ReAcquisitionModule { }
