import { Component, Output, Input, EventEmitter, OnChanges } from '@angular/core';
import { SwiperOptions } from 'swiper';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'app-week2-part-two',
  templateUrl: './week2-part-two.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class Week2PartTwoComponent implements OnChanges {
  carouselConfigWeeklyJourney: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
  };
@Input('user')user:any;
@Input('followUp')followUp:any;
@Input('userDrImage')userDrImage:string;
@Input('defaultDoctorImage')defaultDoctorImage:string;
@Output('openStaticArticle1')openStaticArticle1:EventEmitter<string> = new EventEmitter();
@Output('openStaticArticle2')openStaticArticle2:EventEmitter<string> = new EventEmitter();
@Output('openCheckup')openCheckup:EventEmitter<string> = new EventEmitter();
constructor(private conn: ConnectionService) {
}
ngOnChanges(): void {
}
handleArticle1(card:string):void {
  this.openStaticArticle1.emit(card);
}
handleArticle2(card:string):void {
  this.openStaticArticle2.emit(card);
}
handleCheckups(card:string): void {
  this.openCheckup.emit(card);
}
handleCTA(): void {
  this.conn.navigateToURL('https://app.cureskin.com/user/checkup');
}
}
