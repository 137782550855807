import { Component } from '@angular/core';

@Component({
  selector: 'app-regimen-offer-tag',
  templateUrl: './regimen-offer-tag.component.html',
  styles: ':host { }',
})
export class RegimenOfferTagComponent {

}
