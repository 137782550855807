import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { ProductListModule } from '@shared/product-list/product-list.module';
import { UserOrderBanner } from './user-order-banner.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ButtonModule,
    ProductListModule,
  ],
  declarations: [UserOrderBanner],
  exports: [UserOrderBanner],
})
export class UserOrderBannerModule {}
