<div class="hbox bg-white font-noto" (click)="getStarted()">
  <div class="flex-box" [ngClass]="language">
    <div class="tw-flex tw-flex-col tw-flex-nowrap tw-justify-end tw-h-full tw-overflow-hidden tw-mb-5">
      <div class="tw-relative tw-flex tw-items-start tw-justify-center tw-flex-1 tw-overflow-visible">
        <div class="tw-relative tw-overflow-hidden tw-w-full tw-h-[140%]">
          <img class="tw-absolute tw-top-0 tw-w-full tw-h-auto tw-block" src="/assets/images/onboarding-new.png" />
          <h1 class="tw-absolute tw-text-700 tw-text-black tw-font-bold tw-w-full tw-text-center tw-capitalize tw-mt-2 tw-my-0 tw-mb-4">
            <span i18n="@@introText1">Welcome to</span>
            <br />
            <span i18n="@@introText2">Cureskin!</span>
          </h1>
        </div>
      </div>
      <div class="tw-relative tw-flex-[0_0_40%] tw-mx-6 tw-my-0 tw-bg-white tw-z-10 tw-rounded-5 tw-mb-[10px] tw-flex-col tw-p-2 tw-mt-4" style="box-shadow: unset">
        <ul class="tw-pt-6">
          <li class="tw-flex tw-font-body tw-p-2">
            <img class="tw-ml-0 tw-mr-2" src="/assets/images/tick.svg" />
            <span>
              <strong class="tw-font-bold tw-text-600 tw-ml-1" i18n="@@caption1">Free analysis</strong>&nbsp; <span class="tw-text-600" i18n="@@caption1B">of skin & hair</span>
            </span>
          </li>
          <li class="tw-flex tw-font-body tw-p-2">
            <img class="tw-ml-0 tw-mr-2" src="/assets/images/tick.svg" />
            <span>
              <span class="tw-text-600 tw-ml-1" i18n="@@caption2">Doctor given</span>&nbsp; <strong class="tw-font-bold tw-text-600" i18n="@@caption2B">treatment kit</strong>
            </span>
          </li>
          <li class="tw-flex tw-font-body tw-mb-1 tw-p-2">
            <img class="tw-ml-0 tw-mr-2" src="/assets/images/tick.svg" />
            <span>
              <span class="tw-text-600 tw-ml-1" i18n="@@caption3">Free monthly</span>&nbsp; <strong class="tw-font-bold tw-text-600" i18n="@@caption3B">doctor checkup</strong>
            </span>
          </li>
        </ul>
        <footer class="tw-flex tw-justify-center tw-items-center tw-flex-row tw-flex-nowrap tw-p-6 tw-relative tw-z-100 tw-h-6 tw-mt-10">
          <cs-button class="tw-w-80" id="skip" style="margin-bottom: 50px" [class]="'w100 primary'" (click)="$event.stopPropagation()" (callback)="getStarted()">
            <cs-button-text i18n="@@getStarted">Get Started</cs-button-text>
          </cs-button>
        </footer>
        <div class="tw-flex swiper-container tw-mt-2">
          <swiper class="swiper-wrapper" [config]="carouselConfig">
            <div class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-flex-nowrap tw-h-full tw-overflow-hidden" *swiperSlide>
              <p class="slide-text" i18n="@@ticker1"> Trusted by 17 lakh+ Indians </p>
            </div>
            <div class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-flex-nowrap tw-h-full tw-overflow-hidden" *swiperSlide>
              <p class="slide-text" i18n="@@ticker2"> Team of 40+ best doctors </p>
            </div>
            <div class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-flex-nowrap tw-h-full tw-overflow-hidden" *swiperSlide>
              <p class="slide-text" i18n="@@ticker3"> 30 million+ faces got free analysis </p>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</div>
