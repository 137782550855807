import { Component, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { BroadcastService } from '@services/broadcast-service/index';
import { ConnectionService } from '@services/connection-service/index';
import { WindowRefService } from '@services/window-ref-service';
import { EventLoggerService } from '@services/event-logger-service';
import { DataStoreService } from '@services/data-store-service';

@Component({ selector: 'faqs', templateUrl: './faqs.html' })
export class FaqsComponent implements OnDestroy {
  items: any;
  loading: boolean = true;
  searchText: any = '';
  selectedId: any;
  user: any;
  filteredQA: Array<any> = [];
  selectedQA: Array<any> = [];
  faqs: Array<any> = [];
  categories: Array<any> = [];
  searchHash: any = {};

  constructor(private route: ActivatedRoute,
    private broadcast: BroadcastService,
    private conn: ConnectionService,
    private router: Router,
    private window: WindowRefService,
    private logger: EventLoggerService,
    private dataStore: DataStoreService,
    private dom: DomSanitizer) { }

  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    this.logger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'faq' }));
    await this.loadFaqs();
    this.window.nativeWindow.openUrl = this.openUrl();
  }

  async loadFaqs(): Promise<any> {
    let data = this.dataStore.get('FAQS');
    const faqId: any = {};
    if (!data.categories) data = await this.conn.fetchFAQ();
    if (data.categories.length > 1) {
      this.categories = data.categories;
      faqId.id = this.route.snapshot.params.id || this.categories[0].id;
    }
    this.faqs = data.faqs;
    this.filter(faqId);
    this.loading = false;
    this.dataStore.set('FAQS', data);
    this.faqs.forEach((each: any): void => {
      each.set('answerHTML', this.dom.bypassSecurityTrustHtml(each.get('answer')));
    });
  }

  openUrl(): (url: string) => void {
    return (url: string): void => {
      this.conn.navigateToURL(url);
    };
  }

  expandCollapse(item: any, index: any, container: any): any {
    const parent = container.querySelector(`#faq-${index}`);
    const arrow = parent.querySelector('.cs-icon-arrow');
    if (arrow.style.transform === 'rotate(270deg)') {
      this.logger.trackEvent('faq_clicked', { question: item.get('question') });
      arrow.style.transform = 'rotate(90deg)';
    } else {
      arrow.style.transform = 'rotate(270deg)';
    }
    arrow.parentElement.parentElement.nextElementSibling.classList.toggle('tw-hidden');
  }

  menuAction(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  filter(item: any): void {
    this.logSearch();
    this.searchText = '';
    if (item.id !== this.selectedId) {
      this.selectedId = item.id;
      this.selectedQA = this.faqs.filter((each: any): void => each.get('FAQId').id.includes(this.selectedId));
      this.filteredQA = this.selectedQA;
    }
  }

  search(event?: any): any {
    const search = this.searchText.toLowerCase();
    this.filteredQA = this.faqs.filter((it: any): boolean => (JSON.stringify(it.attributes).toLowerCase().includes(search)));
    if ([13].includes(event.keyCode)) this.logSearch();
  }

  logSearch(): any {
    if (!this.filteredQA.length && this.searchText && !this.searchHash[this.searchText]) {
      this.searchHash[this.searchText] = 1;
      this.logger.trackInElasticSearch({
        event: 'FAQ_SEARCH',
        type: 'APP_SEARCH_LOG',
        key: this.searchText,
        user: this.user.get('username'),
      });
    }
  }

  ngOnDestroy(): void {
    this.logSearch();
  }
}
