import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { ToolbarModule } from '@components/toolbar';
import { RangeSliderModule } from '@components/range-slider/range-slider.module';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { FeedbackComponent } from './feedback.component';

@NgModule({
  imports: [CommonModule,
    ButtonModule,
    ToolbarModule,
    RangeSliderModule,
    LoadingModule,
    CsIconModule,
  ],
  declarations: [FeedbackComponent],
  exports: [FeedbackComponent],
})
export class FeedbackModule {}
