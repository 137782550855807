import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputModule } from '@components/inputs/index';
import { DirectivesModule } from '@directives/directives.module';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { ButtonModule } from '@components/button';
import { AddressFormComponent } from './address-form.component';

@NgModule({ imports: [
  CommonModule,
  FormsModule,
  LoadingModule,
  ReactiveFormsModule,
  InputModule,
  DirectivesModule,
  CsIconModule,
  ButtonModule,
],
declarations: [AddressFormComponent],
exports: [AddressFormComponent] })
export class AddressFormModule {}
