<div class="question-holder trans-y-hide slideFromBottom">
  <div class="question bg-fff" *ngFor="let order of orders$ | async" (click)="viewOrder(order, $event)">
    <div class="order">
      <div class="content">
        <img *ngIf="order.type === appConfig.Shared.Order.Type.REGIMEN" [src]='order.regimen?.regimenCoverImage || "https://storage.googleapis.com/heallocontent/app/img/Skin-Box.png"' /><img *ngIf="order.type === appConfig.Shared.Order.Type.PRODUCT" [src]='order.productInfo[0]?.image || "https://storage.googleapis.com/heallocontent/app/img/Skin-Box.png"' />
        <div class="desc">
          <div class="stage" [ngClass]='{ "active": !appConfig.Shared.Order.Stage.CompletedStages.includes(order.stage) }'>
            {{order.stage.toLowerCase().split('_').join(' ')}}
          </div>
          <div class="bullet">
            <div class="dib" i18n="@@orderNo">Order no:</div>
            <div class="dib pL5">{{'#'+order.orderNumber}}</div>
          </div>
          <div class="bullet">₹{{order.amount}}/-</div>
        </div>
      </div>
      <div class="buttons">
        <div class="btn" *ngIf="appConfig.Shared.Order.Stage.AfterShipment.includes(order?.stage)" (click)="editOrder(order, $event)">
          Edit
        </div>
        <div class="btn" *ngIf="appConfig.Shared.Order.Stage.CompletedStages.includes(order?.stage)" (click)="reorder(order, $event)">
          Reorder
        </div>
        <div class="btn" *ngIf="appConfig.Shared.Order.Stage.AfterShipment.includes(order?.stage)" (click)="trackOrder(order, $event)">
          Track
        </div>
        <div class="btn w100" *ngIf="appConfig.Shared.Order.Stage.BeforeShipment.includes(order?.stage) || appConfig.Shared.Order.Stage.CompletedStages.includes(order?.stage)" (click)="viewOrder(order, $event)">
          View
        </div>
      </div>
    </div>
  </div>
</div>