<div class="tw-block tw-border-spacing-0 tw-w-full tw-h-full tw-flex-1 tw-overflow-y-scroll tw-font-body">
  <div class="tw-flex tw-flex-col tw-flex-1">
    <div class="tw-flex tw-flex-col tw-pt-24 tw-h-1/5">
      <div
        class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
        (click)="back()">
        <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
      </div>
      <div class="tw-p-4 tw-relative tw-flex tw-flex-col tw-pt-0 tw-items-start tw-justify-end tw-w-8/12">
        <div class="tw-text-600 tw-font-bold tw-float-left tw-text-gray-800" i18n="@@personalInfo">
          Personal Info
        </div>
      </div>
    </div>
    <div class="tw-w-full tw-flex-auto tw-overflow-y-auto tw-overflow-x-hidden" *ngIf="!!user">
      <address-form class="tw-block tw-px-6" [(data)]="formDetails" [editable]="editFlag" (valid)="valid = $event"
        [isCheckout]="false"></address-form>
      <div class="tw-pt-0 tw-pb-6 tw-px-5">
        <h2 class="tw-text-500 tw-mx-0 tw-mt-0 tw-mb-4">
          notifications settings
        </h2>
        <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start">
          <div class="tw-font-body tw-text-300" (click)="toggleNotificationSetting($event, 'whatsappOptInStatus')">
            <label class="tw-flex tw-items-center tw-m-0">
              <input class="tw-peer tw-h-0" id="whatsapp" type="checkbox"
                [checked]="notificationReminders?.whatsappOptInStatus" />
              <label class="tw-cursor-pointer tw-w-9 tw-h-5 tw-bg-gray-500 tw-rounded-full tw-relative tw-my-0 tw-ml-0 tw-mr-4 peer-checked:tw-bg-blue-500
              after:tw-block after:tw-contents-[''] after:tw-absolute after:tw-top-[2px] after:tw-left-[2px] after:tw-h-4 after:tw-w-4 after:tw-rounded-full after:tw-bg-white after:tw-transition-all
              peer-checked:after:tw-left-[calc(100%-2px)] peer-checked:after:-tw-translate-x-full" for="whatsapp"></label>
              <span>whatsapp reminders</span>
            </label>
          </div>
          <div class="tw-font-body tw-text-300" (click)="toggleNotificationSetting($event, 'usageReminderOptInStatus')">
            <label class="tw-flex tw-items-center tw-m-0">
              <input class="tw-peer tw-h-0" id="product" type="checkbox"
                [checked]="notificationReminders?.usageReminderOptInStatus" />
              <label class="tw-cursor-pointer tw-w-9 tw-h-5 tw-bg-gray-500 tw-rounded-full tw-relative tw-my-0 tw-ml-0 tw-mr-4 peer-checked:tw-bg-blue-500
              after:tw-block after:tw-contents-[''] after:tw-absolute after:tw-top-[2px] after:tw-left-[2px] after:tw-h-4 after:tw-w-4 after:tw-rounded-full after:tw-bg-white after:tw-transition-all
              peer-checked:after:tw-left-[calc(100%-2px)] peer-checked:after:-tw-translate-x-full" for="product"></label>
              <span>product application reminders</span>
            </label>
          </div>
          <div class="tw-font-body tw-text-300" (click)="toggleNotificationSetting($event, 'waterReminderOptInStatus')">
            <label class="tw-flex tw-items-center tw-m-0">
              <input class="tw-peer tw-h-0" id="water" type="checkbox"
                [checked]="notificationReminders?.waterReminderOptInStatus" />
              <label class="tw-cursor-pointer tw-w-9 tw-h-5 tw-bg-gray-500 tw-rounded-full tw-relative tw-my-0 tw-ml-0 tw-mr-4 peer-checked:tw-bg-blue-500
                after:tw-block after:tw-contents-[''] after:tw-absolute after:tw-top-[2px] after:tw-left-[2px] after:tw-h-4 after:tw-w-4 after:tw-rounded-full after:tw-bg-white after:tw-transition-all
                peer-checked:after:tw-left-[calc(100%-2px)] peer-checked:after:-tw-translate-x-full" for="water"></label>
              <span>water reminders</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    
    <div class="tw-absolute tw-bottom-4 tw-right-4 tw-flex tw-items-center tw-justify-center tw-bg-green-400 tw-h-14 tw-w-14 tw-rounded-full tw-shadow-z1" id="update">
      <em class="tw-text-white cs-icon-edit-1" *ngIf="!saveInProcess && !editFlag" (click)="enableEdit()"></em>
      <em class="tw-text-white cs-icon-tick" *ngIf="!saveInProcess && editFlag" (click)="saveForm()"></em>
      <loading *ngIf="saveInProcess" [stroke]='"#fff"'></loading>
    </div>
  </div>
</div>
<pop-up-modal *ngIf="popUpModal?.open" [show]="popUpModal?.open" [config]="popUpModal"
  (close)="popUpClosed($event)"></pop-up-modal>