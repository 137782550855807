<div class="tw-w-full tw-h-full tw-block tw-border-spacing-0 tw-overflow-hidden tw-relative tw-font-body">
  <loading class="tw-inline-flex tw-z-10 tw-fixed tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" *ngIf="!html"></loading>
  <div class="tw-flex tw-h-full tw-flex-col tw-flex-nowrap">
    <div class="tw-w-full tw-flex-initial">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white tw-normal-case" *ngIf="diet_1500_experiment" i18n="@@dietConsultation">Diet consultation</span>
      </div>
    </div>
    <div class="tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto tw-w-full" [ngClass]="{ 'overrideBootstrap': article && article.get('cssLink') }">
      <link *ngIf="cssLink" itemprop="url" rel="stylesheet" [href]="cssLink" />
      <div *ngIf="html" [innerHTML]="html"></div>
    </div>
    <div class="tw-w-full tw-relative tw-flex-[0_1_0] tw-text-center tw-shadow-[0_-1px_7px_rgba(0,0,0,0.13)]" *ngIf="price">
      <div class="tw-p-3" *ngIf="!diet_1500_experiment">
        <div class="tw-font-semibold" i18n="@@getACustomizedPlan"> Get a customized diet plan </div>
        <span class="tw-font-bold">&#64;&nbsp;</span>
        <s>₹799</s>
        <span class="tw-font-semibold">₹{{price}}/Session</span>
      </div>
      <div *ngIf="diet_1500_experiment" class="tw-font-body tw-mt-4">
        <h5 class="tw-text-gray-900 tw-font-bold tw-text-left tw-px-6" i18n="@@chooseYourDietPlan">Choose your diet plan</h5>
        <div class="tw-flex tw-gap-2 tw-overflow-x-auto tw-px-4 tw-pb-4">
          <div class="tw-rounded-xl tw-mx-1 tw-my-2 tw-border tw-border-gray-200 tw-overflow-hidden tw-flex-1 tw-shadow-z1 tw-min-w-40 tw-max-h-36"
               [ngClass]="{'tw-bg-purple-100': selectedPlan === appConfig.Shared.Notice.dietPlans.BASIC, 'tw-bg-gray-100': selectedPlan !== appConfig.Shared.Notice.dietPlans.BASIC}"
               (click)="selectPlan(appConfig.Shared.Notice.dietPlans.BASIC)">
            <div class="tw-flex tw-items-center tw-justify-center tw-h-[30px]" [ngClass]="{'tw-bg-purple-200': selectedPlan === appConfig.Shared.Notice.dietPlans.BASIC, 'tw-bg-gray-200': selectedPlan !== appConfig.Shared.Notice.dietPlans.BASIC}">
              <img class="tw-mx-1 tw-w-[15px] tw-h-[15px]" *ngIf="selectedPlan === appConfig.Shared.Notice.dietPlans.BASIC" src="/assets/images/correct.png" />
              <span i18n="@@dietCall" class="tw-font-bold">1 Diet Call &#64; ₹{{price}}</span>
            </div>
            <div class="tw-p-2" >
              <ul class="tw-px-3 tw-text-left">
                <li class="tw-list-disc" i18n="@@consultationCall">1 Consultation Call</li>
                <li class="tw-list-disc" i18n="@@detailedMealPlan">Detailed Meal Plan + Guidance Call</li>
              </ul>
            </div>
          </div>
          <div class="tw-rounded-xl tw-mx-1 tw-my-2 tw-border tw-border-gray-200 tw-overflow-hidden tw-flex-1 tw-shadow-z1 tw-min-w-40 tw-max-h-36"
               [ngClass]="{'tw-bg-orange-100': selectedPlan === appConfig.Shared.Notice.dietPlans.PRO, 'tw-bg-gray-100': selectedPlan !== appConfig.Shared.Notice.dietPlans.PRO}"
               (click)="selectPlan(appConfig.Shared.Notice.dietPlans.PRO)">
            <div class="tw-flex tw-items-center tw-justify-center tw-h-[30px]" [ngClass]="{'tw-bg-orange-200': selectedPlan === appConfig.Shared.Notice.dietPlans.PRO, 'tw-bg-gray-200': selectedPlan !== appConfig.Shared.Notice.dietPlans.PRO}">
              <img class="tw-mx-1 tw-w-[15px] tw-h-[15px]" *ngIf="selectedPlan === appConfig.Shared.Notice.dietPlans.PRO" src="/assets/images/correct.png" />
              <span i18n="@@dietCalls" class="tw-font-bold">3 Diet Call &#64; ₹1500</span>
            </div>
            <div class="tw-p-2" >
              <ul class="tw-px-3 tw-text-left">
                <li class="tw-list-disc" i18n="@@consultationCall">1 Consultation Call</li>
                <li class="tw-list-disc" i18n="@@freeProducts">2 Free Product</li>
                <li class="tw-list-disc" i18n="@@detailedMealPlanAndMonthlyFollowup">Detailed Meal Plan + 3 Monthly Follow-up calls</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-mb-3 tw-px-2">
        <cs-button id="buyButton" [class]='"tw-w-full primary"' (click)="buy()">
          <cs-button-text i18n="@@buyNow">Buy Now</cs-button-text>
          <span *ngIf="diet_1500_experiment">&nbsp;&#64;&nbsp;₹{{ selectedPlan === appConfig.Shared.Notice.dietPlans.PRO
            ? proPlanPrice : price}}
          </span>
        </cs-button>
      </div>
    </div>
  </div>
</div>
