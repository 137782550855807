<div class="tw-bg-white tw-w-full tw-mb-5 tw-px-5 tw-py-7 tw-font-body" #container>
  <h1 class="tw-text-700 tw-font-bold tw-mb-4" i18n="@@faqsFullForm">Frequently Asked Questions</h1>
  <div class="tw-mb-5">
    <div class="tw-w-full tw-flex tw-justify-center tw-items-center">
      <loading class="" *ngIf="loading"></loading>
    </div>
    <div class="tw-border-b tw-border-b-gray-100" *ngFor="let i = index; let qna of faqs" [id]='"faq-"+i'>
      <div class="tw-py-3 tw-flex tw-items-center" style="flex-flow: wrap;" (click)="expandCollapse(i, container)">
        <div class="tw-w-[92%] tw-text-300 te-text-black" [innerHTML]='qna.get("question")'></div>
        <div class="tw-min-w-[8%] tw-text-right">
          <em class="cs-icon-arrow tw-align-middle tw-text-teal-600" style="transform: rotate(90deg)"></em>
        </div>
      </div>
      <div class="tw-hidden tw-border-t tw-border-t-gray-100 tw-py-2 tw-text-300 tw-text-gray-400" [innerHTML]='qna.get("answer")'></div>
    </div>
  </div>
  <h1 class="text-300 tw-font-semibold tw-text-teal-600 tw-underline" i18n="@@viewMore" (click)="viewAllFaqs()">View More</h1>
</div>
