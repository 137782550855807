import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref-service';
import { ConnectionService } from '../connection-service';
import { AppWebBridgeService } from '../app-web-bridge-service';

declare const Razorpay: any;

@Injectable()
export class RazorpayService {
  constructor(private windowRef: WindowRefService,
    private conn: ConnectionService,
    private appWebBridgeService: AppWebBridgeService) {
  }

  isRazorpayLoaded(): boolean {
    return typeof Razorpay !== 'undefined';
  }

  initialize(): void {
    if (this.isRazorpayLoaded()) return;
    this.conn.addScript('https://checkout.razorpay.com/v1/checkout.js');
    const interval = setInterval((): void => {
      if (!this.isRazorpayLoaded()) return;
      this.windowRef.nativeWindow.Razorpay = Razorpay;
      clearInterval(interval);
      this.appWebBridgeService.requestRazorpayLoaded();
    }, 100);
  }
}
