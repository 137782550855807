<div class="tw-w-full tw-h-full tw-block tw-border-spacing-0 tw-overflow-hidden tw-font-semibold tw-font-body">
  <div class="tw-flex tw-h-full tw-flex-col tw-flex-nowrap">
    <div class="tw-flex-initial tw-w-full">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@checkup">Check-up</span>
      </div>
    </div>
    <div class="tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto tw-w-full tw-p-6">
      <ng-container *ngIf="selectedConcern === 'face'">
        <div class="tw-mt-20 tw-text-center tw-text-sm tw-font-medium" i18n="@@doctorNeedYourFrontAndSideFacePhotoForCheckup"> Doctor needs your front and side face photo for checkup </div>
        <div class="tw-text-500 tw-font-bold tw-text-center tw-mt-2" i18n="@@uploadYourPhoto"> Upload your photo </div>
        <div class="tw-flex tw-mt-4 tw-justify-between tw-mx-4">
          <div class="tw-h-52 tw-border tw-border-1 tw-border-gray-200 tw-rounded-3 tw-w-[48%] tw-flex tw-flex-col tw-shadow-z1" (click)="openInstantCheckup(appConfig.Shared.InstantCheckup.Type.FRONT_FACE)">
            <div class="tw-w-full tw-h-2/5 tw-text-center tw-leading-[6]">
              <translate-string [key]="appConfig.Shared.InstantCheckup.Type.FRONT_FACE"></translate-string>
            </div>
            <img class="tw-w-full tw-h-3/5" *ngIf="currentUser?.get('Gender') === appConfig.Shared.Gender.FEMALE" src="https://cdn.cureskin.com/app/img/take-photo-front-face.jpg" />
            <img class="tw-w-full tw-h-3/5" *ngIf="currentUser?.get('Gender') === appConfig.Shared.Gender.MALE" src="https://storage.googleapis.com/heallocontent/app/img/take-photo-front-face_male.jpg" />
          </div>
          <div class="tw-h-52 tw-border tw-border-1 tw-border-gray-200 tw-rounded-3 tw-w-[48%] tw-flex tw-flex-col  tw-shadow-z1" (click)="openInstantCheckup(appConfig.Shared.InstantCheckup.Type.SIDE_FACE)">
            <div class="tw-w-full tw-h-2/5 tw-text-center tw-leading-[6]">
              <translate-string [key]="appConfig.Shared.InstantCheckup.Type.SIDE_FACE"></translate-string>
            </div>
            <img class="tw-w-full tw-h-3/5" *ngIf="currentUser?.get('Gender') === appConfig.Shared.Gender.FEMALE" src="https://cdn.cureskin.com/app/img/take-photo-side-face.jpg" />
            <img class="tw-w-full tw-h-3/5" *ngIf="currentUser?.get('Gender') === appConfig.Shared.Gender.MALE" src="https://storage.googleapis.com/heallocontent/app/img/take-photo-side-face_male.jpg" />
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedConcern === 'hair'">
        <div class="tw-mt-20 tw-text-center tw-text-sm tw-font-medium" i18n="@@doctorNeedYourFrontAndTopHairPhotoForCheckup"> Doctor needs your front and top hair photo for checkup </div>
        <div class="tw-text-500 tw-font-bold tw-text-center tw-mt-2" i18n="@@uploadYourPhoto"> Upload your photo </div>
        <div class="tw-flex tw-mt-4 tw-justify-between tw-mx-4">
          <div class="tw-h-52 tw-border tw-border-1 tw-border-gray-200 tw-rounded-3 tw-w-[48%] tw-flex tw-flex-col tw-shadow-z1" (click)="openInstantCheckup(appConfig.Shared.InstantCheckup.Type.HAIR_FRONT)">
            <div class="tw-w-full tw-h-2/5 tw-text-center tw-leading-[6]">
              <translate-string [key]="appConfig.Shared.InstantCheckup.Type.HAIR_FRONT"></translate-string>
            </div>
            <img class="tw-w-full tw-h-3/5" *ngIf="currentUser?.get('Gender') === appConfig.Shared.Gender.FEMALE" src="https://cdn.cureskin.com/app/img/take-photo-front-face.jpg" />
            <img class="tw-w-full tw-h-3/5" *ngIf="currentUser?.get('Gender') === appConfig.Shared.Gender.MALE" src="https://storage.googleapis.com/heallocontent/app/img/take-photo-front-face_male.jpg" />
          </div>
          <div class="tw-h-52 tw-border tw-border-1 tw-border-gray-200 tw-rounded-3 tw-w-[48%] tw-flex tw-flex-col tw-shadow-z1" (click)="openInstantCheckup(appConfig.Shared.InstantCheckup.Type.HAIR_TOP)">
            <div class="tw-w-full tw-h-2/5 tw-text-center tw-leading-[6]">
              <translate-string [key]="appConfig.Shared.InstantCheckup.Type.HAIR_TOP"></translate-string>
            </div>
            <img class="tw-w-full tw-h-3/5" *ngIf="currentUser.get('Gender') === appConfig.Shared.Gender.FEMALE" src="https://cdn.cureskin.com/app/img/take-photo-hair-top.jpg" />
            <img class="tw-w-full tw-h-3/5" *ngIf="currentUser?.get('Gender') === appConfig.Shared.Gender.MALE" src="https://storage.googleapis.com/heallocontent/app/img/take-photo-hair-top_male.jpg" />
          </div>
        </div>
      </ng-container>
      <cs-button class="tw-mt-18" [class]="'primary tw-w-full'" (callback)="submit()">
        <cs-button-text i18n="@@submit">Submit</cs-button-text>
      </cs-button>
    </div>
  </div>
</div>
