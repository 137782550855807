<loading class="pos-abs vh-center" *ngIf="inProcess"></loading><ng-container *ngIf="question && !inProcess">
  <section class="plain-html" *ngIf="question.outputComponent === appConfig.Shared.Support.component.HTML" [innerHTML]="domSanitizer.bypassSecurityTrustHtml(question.responseHtml)"></section>
  <product-list *ngIf="question.outputComponent === appConfig.Shared.Support.component.REGIMEN_PRODUCT_LIST" [regimen]="question.response?.regimen"></product-list><regimen-instruction *ngIf="question.outputComponent === appConfig.Shared.Support.component.REGIMEN_INSTRUCTION" [regimen]="question.response?.regimen" [experiments]="experiments$ | async" [user]="user"></regimen-instruction><order-update *ngIf="question.outputComponent === appConfig.Shared.Support.component.ORDER_UPDATE" [order]="question.response?.order"></order-update><shop-by-category *ngIf="question.outputComponent === appConfig.Shared.Support.component.PLACE_NEW_ORDER"></shop-by-category><get-support-query *ngIf="question.outputComponent === appConfig.Shared.Support.component.GET_QUERY" [message]="question.responseHtml" [question]="question"></get-support-query><doctor-profile *ngIf="question.outputComponent === appConfig.Shared.Support.component.ABOUT_MY_DOCTOR"></doctor-profile><support-regimen-class *ngIf="question.outputComponent === appConfig.Shared.Support.component.REGIMEN_CLASS_SELECTION"></support-regimen-class><support-view-edit-order *ngIf="question.outputComponent === appConfig.Shared.Support.component.VIEW_EDIT_ORDER"></support-view-edit-order>
  <section *ngIf="question.outputComponent === appConfig.Shared.Support.component.SELECT_SUB_QUERY">
    <ng-container *ngFor="let question of questions; let i = index">
      <div class="header" (click)="openQuestion(question, i)" [ngClass]='{ "mB0": questionMap[i]?.expanded }'>
        <em class="icon" [ngClass]='question.iconClassName || "cs-icon-info"'></em>
        <div class="title">{{question.listView || question.question}}</div>
        <em class="cs-icon-arrow" [ngClass]="{ 'cs-icon-plus': question.listViewType === 'Expand', 'cs-icon-arrow': question.listViewType === 'Next' }"></em>
      </div>
      <div class="of-x-auto" [ngClass]='{ "bg-white mB10": questionMap[i]?.expanded }'>
        <ng-container *ngIf="questionMap[i] && questionMap[i].expanded"><support-component-selection [loading]="questionMap[i].inProcess" [question]="questionMap[i].question" [questions]="questionMap[i].questions"></support-component-selection></ng-container>
      </div>
    </ng-container><ng-container *ngIf="user?.isPaid()">
      <div class="header" (click)="viewAllQuery()">
        <em class="icon" [ngClass]='"cs-icon-prescription"'></em>
        <div class="title" i18n="@@allQueries">All Queries</div>
        <em class="cs-icon-arrow"></em>
      </div>
    </ng-container>
  </section>
</ng-container>