import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-get-feedback',
  templateUrl: './get-feedback-sheet.html',
})

export class GetFeedbackSheetComponent implements OnInit {
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  private bottomSheetRef: MatBottomSheetRef) {}
  popUpType:string = '';
  feedbackValue: string = '';

  ngOnInit(): void {
    this.popUpType = this.data?.popUpType;
  }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }

  async updateFeedbackAndCreatePendingCall(createCall: boolean): Promise<void> {
    await this.data.updateFeedbackAndCreatePendingCall(createCall, this.feedbackValue);
    this.bottomSheetRef.dismiss();
  }
}
