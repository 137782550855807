<div class="hbox">
  <loading class="pos-fix vh-center z-ind-5" *ngIf="!ui.isDetectionFetched"></loading>
  <div class="flex-box concern-detection">
  <div class="flex-content">
    <div class="heading">
    <div *ngIf="ui.isDetectionFetched">
      <div class="fontL4 clr-white" i18n="@@asPerSkinAnalysis" *ngIf="isDetectionFoundInPhotos">
      As per your skin analysis, we have identified some major concerns.
      </div>
      <div class="fontL4 clr-white" i18n="@@asPerSkinAnalysisWeHaveNot" *ngIf="!isDetectionFoundInPhotos">
      We will help you get better and healthier skin.
      </div>
      <div class="detected-concerns" *ngIf="detectedIssues?.length" (click)="showPopup()">
      <span *ngFor="let concern of detectedIssues; let i = index">{{concern}}</span>
      </div>
      <div class="add-more" (click)="showPopup()">
      <em class="cs-icon-edit pR5"></em><span i18n="@@ChangeYourConcern">Change your concern</span>
      </div>
    </div>
    </div>
    <div class="content">
    <ng-container *ngIf="ui.isDetectionFetched">
      <section>
      <div i18n="@@isThereAnythingToInformUs">
        Is there anything that you want to inform your skin doctor about?
      </div>
      <textarea class="w100 mT15" [(ngModel)]="pastSkinTreatment" i18n-placeholder="@@tellUsMore" placeholder="Tell us more about your skin .."></textarea>
      </section>
    </ng-container>
    </div>
  </div>
  <div class="flex-footer" *ngIf="ui.isDetectionFetched">
    <cs-button [class]="'w100 primary'" (callback)="saveNotesAndRedirectToChat()" [loading]="ui.loading"><cs-button-text i18n="@@continue">Continue</cs-button-text></cs-button>
  </div>
  </div>
</div>
<div class="main-concern-selection hbox" *ngIf="popUp.open">
  <div class="flex-box">
  <div class="flex-heading">
    <div class="closeIcon" (click)="back()">
    <em class="cs-icon-close"></em>
    </div>
  </div>
  <div class="flex-content scroll-content">
    <div class="bold fontL2" i18n="@@pleaseSelectMultiConcern">
    Please select any 3 important concerns.
    </div>
    <div class="separator"></div>
    <div class="concern-container">
    <div class="concern" *ngFor="let item of mainConcerns; let i = index" (click)="selectConcern(i)" [ngClass]="{ 'selected-concern': selectedConcernIndices.includes(i) }">
      {{item.key}}
    </div>
    </div>
  </div>
  <div class="flex-footer shadow">
    <cs-button [class]="'primary w100'" (callback)="back()"><cs-button-text i18n="@@proceed">Proceed</cs-button-text></cs-button>
  </div>
  </div>
</div>