import { Component } from '@angular/core';

@Component({
  selector: 'app-regimen-offer-product-banner',
  templateUrl: './regimen-offer-product-banner.component.html',
  styles: ':host { @apply tw-flex tw-relative tw-border tw-border-teal-400 tw-border-dashed tw-rounded-2 tw-bg-white tw-overflow-hidden;}',
})
export class RegimenOfferProductBannerComponent {

}
