import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { LoadingModule } from '@components/loading';
import { DoctorProfileModule } from '@shared/doctor-profile/doctor-profile.module';
import { CsIconModule } from '@components/icon';
import { RouterModule } from '@angular/router';
import { DoctorViewComponent } from './doctor-view/doctor-view.component';
import { DoctorListComponent } from './doctor-list/doctor-list.component';
import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: 'doctor/:id', canActivate: [authenticationGuard, onboardingGuard], component: DoctorViewComponent,
    },
    {
      path: 'doctors', canActivate: [authenticationGuard, onboardingGuard], component: DoctorListComponent,
    }]),
    ToolbarModule,
    ButtonModule,
    LoadingModule,
    DoctorProfileModule,
    CsIconModule,
  ],
  declarations: [DoctorViewComponent, DoctorListComponent],
})
export class DoctorModule { }
