<div class="hbox">
    <div class="flex-box">
        <div class="flex-heading">
            <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
                <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="menuAction()">
                    <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
                </div>
                <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white text-cap" *ngIf="title">{{title}}</span>
            </div>
        </div>
        <div class="flex-content df">
            <iframe class="w100 flex-grow" [src]="src" (load)="loaded()"></iframe>
            <loading class="pos-fix vh-center" *ngIf="loading"></loading>
        </div>
    </div>
</div>