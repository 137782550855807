<div class="card tw-flex tw-flex-row tw-items-center tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 bg-light-yellow">
  <img class="tw-flex-none tw-h-20 tw-w-20 tw-rounded-full bg-black tw-bg-yellow-200" src="/assets/images/sand-watch-loader.gif" />
  <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-2">
    <div>
      <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@yourCheckupIsInProcess">
        Your checkup is in progress
      </p>
      <p class="tw-font-body tw-text-200 tw-text-black tw-text-xs" i18n="@@drMahimaWillCallYouWithin2-3hr">
        Dr. Mahima will call your within 2-3hrs
      </p>
    </div>
  </div>
</div>