<div class="w100 fontS2 p15 bg-white">
    <div class="w100 flex pTB10 bdr-btm" *ngFor="let product of products">
        <div class="w70 dib">
            {{product.name}}&nbsp;
            <div class="dib clr-secondary" *ngIf="product.quantity > 1">
                ({{product.quantity}})
            </div>
        </div>
        <div class="w5 dib">:</div>
        <div class="w25 dib text-right">
            <span>&nbsp; ₹ {{product.MRP * product.quantity}}/-</span>
        </div>
    </div>
    <div class="w100 flex bdr-btm pTB10" *ngFor="let service of services">
        <div class="w70 dib">{{service.get('title')}} &nbsp;service</div>
        <div class="w5 dib">:</div>
        <div class="w25 dib text-right">&nbsp; ₹ {{service.get('amount')}}/-</div>
    </div>
    <div class="bdr-btm pTB10" *ngIf="totalMRP !== totalSP">
        <div class="w100 flex">
            <div class="w70 dib">Bill amount</div>
            <div class="w5 dib">:</div>
            <div class="w25 dib text-right">₹ {{totalMRP}}/-</div>
        </div>
        <div class="w100 flex pT10">
            <div class="w70 dib">Discount</div>
            <div class="w5 dib">:</div>
            <div class="w25 dib text-right">₹ {{- totalDiscount}}/-</div>
        </div>
    </div>
    <div class="w100 flex pTB10">
        <div class="w70 dib">Total</div>
        <div class="w5 dib">:</div>
        <div class="w25 dib text-right">₹ {{totalSP}}/-</div>
    </div>
    <div class="w50 mx-auto mT10">
        <cs-button (callback)="closeDialog()" [class]="'w100 primary'"><cs-button-text i18n="@@gotIt">Got it</cs-button-text></cs-button>
    </div>
</div>