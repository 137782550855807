<div class="tw-block tw-border-spacing-0 tw-w-full tw-h-full tw-flex-1 tw-overflow-scroll tw-font-body">
  <div class="tw-flex tw-flex-1 tw-bg-gray-100 tw-flex-col">
    <div class="tw-w-full tw-flex-auto tw-overflow-y-auto tw-overflow-x-hidden tw-relative">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-fixed tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10 tw-z-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-gray-50">testimonial</span>
      </div>
      <div class="tw-my-4">
        <video-feed [language]='user?.get("languagePreference")'></video-feed>
      </div>
      <div class="tw-my-4">
        <testimonials-widget [concernClass]="concernClass" [hideReadMore]="true" [language]='user?.get("languagePreference")'></testimonials-widget>
      </div>
      <div class="tw-my-4">
        <testimonial-case-studies [language]='user?.get("languagePreference")'></testimonial-case-studies>
      </div>
      <div class="tw-my-4">
        <testimonial-wall></testimonial-wall>
      </div>
    </div>
  </div>
</div>