// eslint-disable-next-line max-classes-per-file
import { NgModule, Provider, ErrorHandler, APP_INITIALIZER, Injectable } from '@angular/core';
import {
  BrowserModule,
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { SwiperModule } from 'swiper/angular';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { ElasticService } from '@services/elastic-search';
import { WindowRefService } from '@services/window-ref-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { RazorpayService } from '@services/razorpay-service';
import { BroadcastService } from '@services/broadcast-service';
import { LocalStorageService } from '@services/local-storage-service';
import { CookieService } from '@services/cookie-service';
import { AwsService } from '@services/aws-service';
import { CurrentComponentService } from '@services/current-component';
import { TimeService } from '@services/time-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { NotificationModule } from '@components/notification';
import { LoadingModule } from '@components/loading';
import { DataStoreService } from '@services/data-store-service';
import { PopUpModalModule } from '@components/pop-up-modal';
import { ButtonModule } from '@components/button';
import { PopUpModule } from '@components/pop-up';
import { CsIconModule } from '@components/icon';
import { ChoosePlanInfoModule } from '@shared/choose-plan-info/choose-plan-info.module';
import { WebWorker } from '@services/web-worker';
import { SentryErrorHandler } from '@services/sentry-logger';
import { ImageService } from '@services/image-service';
import { LoginService } from '@services/login-service/login.service';
import { ConfirmationModule } from '@shared/confirmation/confirmation.module';
import * as Hammer from 'hammerjs';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { AppConfig } from './app.config';
import { AppRoutingModule } from './app-routing.module';
import { appReducer } from './store/reducers';
import { Effects } from './store/effects/index';
import { Shared } from './app.constants';
import { AppComponent } from './app.component';
import { ReminderFullPageComponent } from './reminder';
import { ProductModule } from './product';

export function playerFactory(): LottiePlayer {
  return player;
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides: any = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}
const SharedConfig = new Shared();

const providers: Provider[] = [
  AwsService,
  AppWebBridgeService,
  BroadcastService,
  CookieService,
  ConnectionService,
  CommonUtilityHelper,
  CurrentComponentService,
  DataStoreService,
  ElasticService,
  EventLoggerService,
  ImageService,
  LocalStorageService,
  RazorpayService,
  TimeService,
  WebWorker,
  WindowRefService,
  LoginService,
  { provide: AppConfig, useValue: AppConfig },
  { provide: ErrorHandler, useClass: SentryErrorHandler },
  { provide: Sentry.TraceService, deps: [Router] },
  {
    provide: APP_INITIALIZER,
    useFactory: (
      windowRefService: WindowRefService,
      connectionService: ConnectionService,
      eventLoggerService: EventLoggerService,
    ): any => async (): Promise<void> => {
      connectionService.initialize();
      eventLoggerService?.trackEvent('web_app_loaded');
      if (connectionService?.getActingUser()) {
        eventLoggerService?.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'web_app_loaded' }));
      }
      const queryParams = (windowRefService.nativeWindow.location.href.split('?')[1] || '').split('&')
        .reduce((result_: Record<string, string>, keyValue: string): Record<string, string> => {
          const result = result_;
          const [key, value]: Array<string> = keyValue.split('=');
          result[key] = value;
          return result;
        }, {});
      await connectionService.updateActingUser(queryParams);
    },
    deps: [WindowRefService, ConnectionService, EventLoggerService, Sentry.TraceService],
    multi: true,
  },
];

const imports = [
  BrowserModule.withServerTransition({ appId: 'cureskin-app' }),
  BrowserAnimationsModule,
  ProductModule,
  HttpClientModule,
  NotificationModule,
  LoadingModule,
  PopUpModalModule,
  [LottieModule.forRoot({ player: playerFactory })],
  ButtonModule,
  ConfirmationModule,
  AppRoutingModule,
  StoreModule.forRoot({ app: appReducer, router: routerReducer }),
  StoreRouterConnectingModule.forRoot(),
  EffectsModule.forRoot(Effects),
  SwiperModule,
  HammerModule,
  PopUpModule,
  ChoosePlanInfoModule,
  CsIconModule.forRoot({ 'icon-path': './assets/icons' }),
];

if (!SharedConfig.Server.production) {
  imports.push(StoreDevtoolsModule.instrument({
    maxAge: 25,
    autoPause: true,
  }));
}

@NgModule({
  providers,
  imports,
  declarations: [AppComponent, ReminderFullPageComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private windowRefService: WindowRefService,
    private appConfig: AppConfig,
    private sentryService: SentryErrorHandler) {
    this.initSentry();
  }

  initSentry(): void {
    let environment = 'localhost';

    if (this.windowRefService.nativeWindow.location.hostname.startsWith('app.cureskin.com')) {
      environment = 'production';
    }
    if (this.windowRefService.nativeWindow.location.hostname.startsWith('test-app.cureskin.com')) {
      environment = 'development';
    }
    if (this.windowRefService.nativeWindow.location.hostname.startsWith('staging-app.cureskin.com')) {
      environment = 'staging';
    }
    if (this.windowRefService.nativeWindow.location.hostname.startsWith('rc-app.cureskin.com')) {
      environment = 'release-candidate';
    }
    if (this.windowRefService.nativeWindow.location.hostname.startsWith('canary-app.cureskin.com')) {
      environment = 'canary';
    }
    if (environment) {
      this.sentryService.initialize(environment);
    }
  }
}
