import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { FormsModule } from '@angular/forms';
import { SupportViewEditOrderComponent } from './support-view-edit-order.component';

@NgModule({
  imports: [CommonModule, DirectivesModule, FormsModule],
  declarations: [SupportViewEditOrderComponent],
  exports: [SupportViewEditOrderComponent],
})
export class SupportViewEditOrderModule {}
