<div class="hbox">
  <loading class="pos-fix vh-center" *ngIf="showRegimenChat === undefined"></loading>
  <div class="flex-box">
  <div class="flex-heading" *ngIf="!showRegimenChat">
    <toolbar (onAction)="home()" [icons]="toolbarIcons"><img class="db m-auto" src="/assets/images/logo_white.svg" width="160px" /></toolbar><toolbar-tabs (tabSelected)="tabChange($event)" [data]="tabData">
    <tab1 i18n="@@chat" [ngClass]='{ "notify": tabUpdates.CHAT }'>Chat</tab1>
    <tab2 i18n="@@checkups" [ngClass]='{ "notify": tabUpdates.FOLLOWUP }'>Check-ups</tab2>
    </toolbar-tabs>
  </div>
  <div class="flex-content pos-rel" *ngIf="showRegimenChat">
    <user-regimen-chat class="pos-abs hbox"></user-regimen-chat>
  </div>
  <div class="flex-content pos-rel" *ngIf="showRegimenChat === false" swipe="swipe" (swipeCallback)="tabSwitch($event)">
    <user-chat class="pos-abs hbox" [ngClass]="{ 'trans-xy-0': selectedIndex === 0, 'trans-x-hide': selectedIndex !== 0 }" [loadChat]="selectedIndex === 0"></user-chat><user-followup class="pos-abs hbox" *ngIf="selectedIndex === 1" [ngClass]="{ 'trans-xy-0': selectedIndex === 1, 'trans-x-hide': selectedIndex !== 1 }" [hideTitle]="true"></user-followup>
  </div>
  </div>
</div>
