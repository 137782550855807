import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'checkup-missed',
  templateUrl: './checkup-missed.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class CheckupMissedComponent implements OnInit {
  @Output('startFollowUp') startFollowUp: EventEmitter<any> = new EventEmitter();
  constructor(
    public appConfig: AppConfig,
  ) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.startFollowUp.emit('LAST_FOLLOWUP_MISSED_BANNER');
  }
}
