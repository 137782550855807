import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';

interface VariantCosts {
  introductory: Number;
  advance: Number;
  assist: Number;
  pro: Number;
}
@Component({
  selector: 'choose-plan',
  templateUrl: './choose-plan.html',
  styles: [':host {@apply tw-block tw-border-t tw-border-t-gray-200;}'],
})

export class ChoosePlanComponent implements OnChanges {
  @ViewChild('scroll') horizontalScroll: ElementRef;
  @Input('regimen') regimen: any;
  @Output('onSelectPlan') onSelectPlan: EventEmitter<string> = new EventEmitter<string>();
  @Output('onReadMore') onReadMore: EventEmitter<void> = new EventEmitter();
  @Output('hideVariants') hideVariants: EventEmitter<void> = new EventEmitter();
  @Input('isRebrandingV1') isRebrandingV1: boolean = false;
  selectedPlan: string = 'advance';
  user: any;
  showIntroductoryKit: boolean = false;
  isStandAloneVariant: boolean = false; // flag to know whether only 999 variant is available
  showAdvanceTag: boolean = false;
  variantCosts: VariantCosts = {
    introductory: 999,
    advance: 1500,
    pro: 2000,
    assist: 3000,
  };

  constructor(private conn: ConnectionService,
    private eventLogger: EventLoggerService) {
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((each: any): void => {
      if (each.key === 'advance_tag') this.showAdvanceTag = true;
    });
  }

  ngOnChanges(): void {
    this.showIntroductoryKit = false;
    this.checkForIntroductoryKit();
    this.getPreselectedPlanInfo();
  }

  checkForIntroductoryKit(): void {
    if (!this.regimen.variants) return;
    this.sortVariantsArray();
    this.updateVariantCostsBasedOnLength();
    this.handleTwoVariantScenario();
    this.emitHideVariantsIfNeeded();
  }

  assignVariantCosts(index: number, price: number, length: number = 0): void {
    if (length === 2) {
      if (index === 0) this.variantCosts.introductory = price;
      if (index === 1) this.variantCosts.advance = price;
    } else if (length === 3) {
      if (index === 0) this.variantCosts.advance = price;
      if (index === 1) this.variantCosts.pro = price;
      if (index === 2) this.variantCosts.assist = price;
    } else if (length === 4) {
      if (index === 0) this.variantCosts.introductory = price;
      if (index === 1) this.variantCosts.advance = price;
      if (index === 2) this.variantCosts.pro = price;
      if (index === 3) this.variantCosts.assist = price;
    }
  }

  handleTwoVariantScenario(): void {
    if (this.regimen.variants.length === 2) {
      this.isStandAloneVariant = true;
      this.processIntroductoryKit();
    }
  }

  emitHideVariantsIfNeeded(): void {
    if (!this.showIntroductoryKit && this.regimen.variants.length === 2) {
      this.hideVariants.emit();
    }
  }

  updateVariantCostsBasedOnLength(): void {
    if (this.regimen.variants.length > 3) {
      this.processIntroductoryKit();
    }
    if (this.regimen?.variants.length) {
      const variants = this.regimen?.variants;
      const variantsLength = variants.length;
      for (let index = 0; index < variantsLength; index += 1) {
        this.assignVariantCosts(index, variants[index]?.fixedPrice, variantsLength);
      }
    }
  }

  sortVariantsArray(): void {
    this.regimen.variants.sort((variant1: any, variant2: any): Number => variant1.fixedPrice - variant2.fixedPrice);
  }

  processIntroductoryKit(): void {
    // eslint-disable-next-line prefer-destructuring
    const createdAt = this.regimen.createdAt;
    // eslint-disable-next-line prefer-destructuring
    const showAfterDays = 5;
    const dateStr1 = new Date(createdAt);
    const dateStr2 = new Date();

    const daysDifference = this.getDaysDifference(dateStr1, dateStr2);
    if (daysDifference >= showAfterDays) {
      this.showIntroductoryKit = true;
      // If user has selected variants other than 999, for this session, we shouldn't reset the selection
      if (!this.conn.getRegimenVariantSelection()) this.selectPlan('introductory', true);
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: '999-variant' }));
    }
  }

  getDaysDifference(dateStr1: any, dateStr2: any): number {
    const timeDifference = dateStr2.getTime() - dateStr1.getTime();
    const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
    const daysDifference = Math.ceil(timeDifference / (MILLISECONDS_PER_DAY));
    return daysDifference;
  }

  getPreselectedPlanInfo(): void {
    const regimenPrice = this.regimen.fixedPrice;
    if (regimenPrice === this.variantCosts.introductory) this.selectedPlan = 'introductory';
    if (regimenPrice === this.variantCosts.advance) this.selectedPlan = 'advance';
    if (regimenPrice === this.variantCosts.pro) this.selectedPlan = 'pro';
    if (regimenPrice === this.variantCosts.assist) this.selectedPlan = 'assist';
    setTimeout((): void => {
      if (this.horizontalScroll?.nativeElement) {
        this.scrollToSelectedPlan();
      }
    }, 500);
  }

  selectPlan(plan: string, isAutoSelect: boolean = false): void {
    if (this.selectedPlan === plan) return;
    // Paid user shouldn't be allowed to select the plans.
    const isInternalUser = this.conn.isInternalUser();
    if (this.regimen?.orderPlaced || isInternalUser) return;
    if (!isAutoSelect && !this.conn.getRegimenVariantSelection()) this.conn.setRegimenVariantSelection();
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'upsell_plan_change', variant: plan }));
    this.selectedPlan = plan;
    this.scrollToSelectedPlan();
    this.onSelectPlan.emit(plan);
  }

  scrollToSelectedPlan(): void {
    let scrollLeft = 0;
    switch (this.selectedPlan) {
      case 'introductory': {
        scrollLeft = 0;
        break;
      }
      case 'advance': {
        scrollLeft = this.showIntroductoryKit ? 100 : 0;
        break;
      }
      case 'pro': {
        scrollLeft = this.showIntroductoryKit ? 300 : 200;
        break;
      }
      default: {
        scrollLeft = this.showIntroductoryKit ? 400 : 200;
        break;
      }
    }
    this.horizontalScroll?.nativeElement?.scrollTo(
      {
        left: scrollLeft,
        behavior: 'smooth',
      },
    );
  }

  readMore(): void {
    const isInternalUser = this.conn.isInternalUser();
    if (isInternalUser) return;
    this.onReadMore.emit();
  }
}
