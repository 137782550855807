import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'out-for-delivery',
  templateUrl: './out-for-delivery.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class OutForDeliveryComponent implements OnInit {
  @Input('isRebrandingBanner') isRebrandingBanner: boolean;
  @Input('order') order: any;
  @Output('addEvents') addEvents: EventEmitter<any> = new EventEmitter();
  constructor(
    public appConfig: AppConfig,
  ) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.addEvents.emit();
  }
}
