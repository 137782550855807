import { Injectable } from '@angular/core';
import { BroadcastService } from '../broadcast-service';

declare const global;

@Injectable()
export class LocalStorageService {
  static parameters: any = [BroadcastService];

  constructor(private broadcast: BroadcastService) {
    if (typeof localStorage === 'undefined') {
      global.localStorage = this.serverSideRenderingLocalStorage;
    }
  }

  get serverSideRenderingLocalStorage(): any {
    return {
      getItem: (): void => {},
      setItem: (): void => {},
      setValue: (): void => {},
      getValue: (): void => {},
      setJsonValue: (): void => {},
      getJsonValue: (): void => {},
      clear: (): void => {},
      removeKey: (): void => {},
    };
  }

  setJsonValue(field: string, value: any): void {
    localStorage.setItem(field, JSON.stringify(value || {}));
  }

  getJsonValue(field: string, defaultValue: string = '{}'): any {
    return JSON.parse(localStorage.getItem(field) || defaultValue);
  }

  setValue(field: string, value: any): void {
    localStorage.setItem(field, value);
  }

  getValue(field: string, defaultValue?: string): any {
    return localStorage.getItem(field) || defaultValue;
  }

  getBooleanValue(field: string, defaultValue?: boolean): boolean {
    return this.getValue(field, `${defaultValue}`) === 'true';
  }

  clear(): void {
    localStorage.clear();
  }

  delete(field: string): void {
    localStorage.removeItem(field);
  }

  setRegimens(jsonRegimens: Array<object>, currentUser: any): void {
    if (!currentUser || currentUser.get('type')) return;
    this.setJsonValue('CureSkin/regimens', jsonRegimens);
  }

  getRegimens(currentUser: any): Array<Table.Regimen['json']> {
    if (!currentUser || currentUser.get('type')) return [];
    return this.getJsonValue('CureSkin/regimens', '[]');
  }

  setExperiments(jsonExperiment: any, currentUser: any): void {
    if (!currentUser || currentUser.get('type')) return;
    this.setJsonValue('CureSkin/activeExperiments', jsonExperiment);
  }

  getExperiments(currentUser: any): Array<any> {
    if (!currentUser || currentUser.get('type')) return [];
    return this.getJsonValue('CureSkin/activeExperiments', '[]');
  }

  addCheckupCount(id?: any): any {
    if (id) {
      const instantCheckups = this.getJsonValue('CureSkin/instantCheckupTaken', '[]');
      const duplicate = instantCheckups.find((each: any): boolean => each === id);
      if (!duplicate) instantCheckups.push(id);
      this.setJsonValue('CureSkin/instantCheckupTaken', instantCheckups);
    }
    const count = this.getJsonValue('CureSkin/instantCheckupTaken', '[]').length;
    if (count && count % 5 === 0) {
      setTimeout((): void => this.broadcast.broadcast('INSTANT_CHECKUP_RATING'), 1000);
    }
  }
}
