import { Injectable } from '@angular/core';
import { ConnectionService } from '../connection-service';

@Injectable()
export class ImageService {
  languageCode: string;
  baseImageCdnUrl: string = 'https://cdn.cureskin.com/app/img/';

  static parameters: any = [ConnectionService];

  constructor(private conn: ConnectionService) {
    this.languageCode = `${this.conn.currentWebSiteLanguage}/`;
  }

  getURL(imageUrl: string): string {
    let fileName = imageUrl;
    if (imageUrl.startsWith(this.baseImageCdnUrl)) {
      fileName = imageUrl.split(this.baseImageCdnUrl)[1];
    } else if (imageUrl.startsWith('http')) {
      return imageUrl;
    } else if (imageUrl.startsWith('/')) fileName = imageUrl.split('/')[1];
    return `${this.baseImageCdnUrl}${this.languageCode}${fileName}`;
  }
}
