import { Component, EventEmitter, Output } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from 'client/app/app.config';
import { Router } from '@angular/router';

@Component({
  selector: 'hair-fall-banner',
  templateUrl: './hair-fall-banner.component.html',
})
export class HairFallBannerComponentComponent {
  user: any;
  regimens: any;
  activeHairRegimen: any;
  showBanner: boolean = false;
  activeFaceRegimen: any;
  constructor(
    private eventLogger: EventLoggerService,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    const { ACNE, ACNE_OR_PIMPLES, INFLAMMATORY_ACNE, COMEDONES }: { ACNE: string;
       ACNE_OR_PIMPLES: string, INFLAMMATORY_ACNE: string, COMEDONES: string } = this.appConfig.acneConcernType;
    const acneConcern = [ACNE, ACNE_OR_PIMPLES, INFLAMMATORY_ACNE, COMEDONES];
    const newMom = ['PREGNANT', 'NEW_MOM'];
    // eslint-disable-next-line operator-linebreak
    const isExcluded = acneConcern.some((concern: any):any => this.user.get('PrivateMainConcern') === concern)
      || this.user.get('Gender') === 'Male'
      || newMom.some((tag: string):any => this.user.get('marketingTags').includes(tag));
    if (isExcluded) return;

    const regimens = await this.conn.fetchRegimens(null, true);
    this.regimens = regimens.filter((regimen: any):any => !regimen.expired && regimen.active);
    this.activeFaceRegimen = this.regimens.find((regimen: any):any => regimen.delivered && regimen.class
    === this.appConfig.Shared.Regimen.Class.FACE);
    this.activeHairRegimen = this.regimens.find((regimen: any):any => regimen.delivered && regimen.class
    === this.appConfig.Shared.Regimen.Class.HAIR);
    if (!this.activeHairRegimen && this.activeFaceRegimen) {
      this.showBanner = true;
    }
  }

  handleClick(): void {
    this.navigateTo('/user?tab=regimen&class=HAIR');
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'homepage-hair-fall-cross-sell-banner' }));
  }

  navigateTo(url: string = ''): void {
    if (!url) return;
    const queryParams: Record<string, any> = {};
    const [URL, qParamString]: string[] = url.split('?');
    if (qParamString) {
      qParamString.split('&').forEach((each: string): void => {
        const [key, value]: string[] = each.split('=');
        queryParams[key] = value;
      });
    }
    this.router.navigate([URL], { queryParams });
  }
}
