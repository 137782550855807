/* eslint-disable angular/log */
import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { AppConfig } from '../../app/app.config';

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements ErrorHandler {
  static parameters: Array<any> = [AppConfig];
  constructor(private appConfig: AppConfig) { }

  initialize(environment: string): void {
    Sentry.init({
      environment,
      release: `app@${this.appConfig.Shared.AppVersion}`,
      dsn: 'https://829772dcf77547d1acf6927f64ea4081@sentry.cureskin.com/3',
      tracePropagationTargets: [
        'https://staging-api.cureskin.com',
        'https://test-api.cureskin.com',
        'https://api.cureskin.com',
        'https://rc-api.cureskin.com',
        'https://canary-api.cureskin.com',
      ],
      integrations: [],
    });
  }

  setUser(user?: any): void {
    if (!this.appConfig.Shared.Server.production) return;
    const scope = Sentry.getCurrentScope();
    if (scope) scope.setUser({ username: user ? user.get('username') : '' });
  }

  handleError(error: any): void {
    if (!this.appConfig.Shared.Server.production) {
      // eslint-disable-next-line no-console
      console.error(error);
      return;
    }
    if (this.rejectError(error)) return;
    Sentry.captureException(error.originalError || error);
  }

  rejectError(err: any): boolean {
    if (typeof err.originalError === 'string' && err.originalError.toLowerCase().includes('play()')) return true;
    return typeof err === 'string' && err.toLowerCase().includes('play()');
  }
}
