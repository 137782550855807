import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegimenOfferProductBannerComponent } from './regimen-offer-product-banner/regimen-offer-product-banner.component';
import { RegimenOfferTagComponent } from './regimen-offer-tag/regimen-offer-tag.component';
import { RegimenOfferProductCaptionDirective } from './regimen-offer-product-caption/regimen-offer-product-caption.directive';
import { RegimenOfferProductTitleDirective } from './regimen-offer-product-title/regimen-offer-product-title.directive';

@NgModule({
  declarations: [
    RegimenOfferProductBannerComponent,
    RegimenOfferProductTitleDirective,
    RegimenOfferTagComponent,
    RegimenOfferProductCaptionDirective,
  ],
  exports: [
    RegimenOfferProductBannerComponent,
    RegimenOfferProductTitleDirective,
    RegimenOfferTagComponent,
    RegimenOfferProductCaptionDirective,
  ],
  imports: [
    CommonModule,
  ],
})
export class RegimenOfferProductBannerModule { }
