import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { FormsModule } from '@angular/forms';
import { SupportRegimenClassComponent } from './support-regimen-class.component';

@NgModule({
  imports: [CommonModule, DirectivesModule, FormsModule],
  declarations: [SupportRegimenClassComponent],
  exports: [SupportRegimenClassComponent],
})
export class SupportRegimenClassModule {}
