import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CallUsComponent } from './call-us.component';
import { ButtonModule } from '../button';
import { TranslateStringsModule } from '../translate-string';

@NgModule({
  imports: [CommonModule, RouterModule, ButtonModule, TranslateStringsModule],
  declarations: [CallUsComponent],
  exports: [CallUsComponent],
})
export class CallUsModule {}
