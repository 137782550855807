import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { CureskinCashSheetComponent } from './cureskin-cash-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
  ],
  declarations: [CureskinCashSheetComponent],
  exports: [CureskinCashSheetComponent],
})
export class CureskinCashSheetModule { }
