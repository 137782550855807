import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { OnboardingService } from '@services/onboarding/onboarding.service';
import { AppConfig } from '../app.config';

const onboardingPages: string[] = ['/login', '/logout', '/language', '/onboarding/number', '/onboarding/otp'];
const exceptionUrls: string[] = ['/user/instantcheckup', '/logout', '/empty', 'force', 'iframe'];

/**
 * If user onboarding is not complete then redirect user to page where they left the app.
 *
 * @param route
 * @param state
 * @returns
 */
export const onboardingGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {
  const isExceptionUrl: any = exceptionUrls.includes(route.url.toString());
  const onboardingSerivce = inject(OnboardingService).getService();
  const user = inject(ConnectionService).getActingUser();
  const appConfig = inject(AppConfig);

  if (user.get('orderState') === appConfig.Shared.User.OrderState.DELIVERED) return true;
  if (isExceptionUrl) return true;

  const validateOnboardingUrl: OnboardingPageUrl | null = onboardingSerivce.validateOnboarding();
  return validateOnboardingUrl ? createUrlTreeFromSnapshot(route, [validateOnboardingUrl]) : true;
};
