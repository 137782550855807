<div class="flex-box font-noto tw-bg-gray-100">
  <div class="flex-heading">
  <div class="tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
    <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
    </div>
  </div>
  <div class="tw-absolute tw-right-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-xl tw-h-10 tw-w-10 tw-shadow-z1">
    <cs-icon class="tw-h-6 tw-w-6" [iconName]="'reload'" (click)="reset()"></cs-icon>
  </div>
  </div>
  <!-- todo mic color-->
  <div class="flex-content" [beforeBottomView]="loadMore">
  <div class="button-holder">
    <button class="mR10" (click)="changeNotification(false)" [ngClass]='{"active-button": !showDoctorMessage, "unactive-button": showDoctorMessage}' i18n="@@allMessages">
    All Messages</button><button class="mL0" (click)="changeNotification(true)" [ngClass]='{"active-button": showDoctorMessage, "unactive-button": !showDoctorMessage}'>
    <cs-icon class="icon tw-h-6 tw-w-6 tw-text-black" iconName="mic"></cs-icon><span i18n="@@doctorRecordings">Doctor recordings</span>
    </button>
  </div>
  <ng-container *ngFor="let notification of notifications"><notification-card [notification]="notification"></notification-card></ng-container>
  <div class="mT60 text-center" *ngIf="!ui.loading && !ui.hasMore && !notifications.length" i18n="@@noNewNotifications">
    No new notifications.
  </div>
  </div>
</div>