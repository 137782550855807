import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[swipe]' })
export class SwipeListenerDirective {
  xDown: any = null;
  yDown: any = null;
  xDiff: any = null;
  yDiff: any = null;
  timeDown: any = null;
  startEl: any = null;

  @Output('swipeCallback') swipeCallback: EventEmitter<string> = new EventEmitter();
  @Output('coordinates') coordinates: EventEmitter<string> = new EventEmitter();

  @HostListener('touchmove', ['$event.touches[0]?.clientX', '$event.touches[0]?.clientY'])
  handleTouchMove(clientX: any, clientY: any): void {
    const xUp = clientX;
    const yUp = clientY;
    if (!this.xDown || !this.yDown) return;
    this.xDiff = this.xDown - xUp;
    this.yDiff = this.yDown - yUp;
  }

  @HostListener('touchstart', ['$event.touches[0]?.clientX', '$event.touches[0]?.clientY', '$event.target'])
  handleTouchStart(clientX: any, clientY: any, target: any): void {
    this.startEl = target;
    this.timeDown = Date.now();
    this.xDown = clientX;
    this.yDown = clientY;
    this.xDiff = 0;
    this.yDiff = 0;
  }

  @HostListener('touchend', ['$event.touches[0]?.clientX', '$event.touches[0]?.clientY', '$event.target', '$event'])
  handleTouchEnd(clientX: any, clientY: any, target: any, event: any): void {
    event.stopPropagation();
    const swipeThreshold = 100;
    const swipeTimeout = 700;
    const timeDiff = Date.now() - this.timeDown;
    let eventType: string = '';
    if (this.startEl !== target) return;
    if (Math.abs(this.xDiff) > Math.abs(this.yDiff)) {
      if (Math.abs(this.xDiff) > swipeThreshold && timeDiff < swipeTimeout) {
        if (this.xDiff > 0) {
          eventType = 'LEFT';
        } else {
          eventType = 'RIGHT';
        }
      }
    }
    if (eventType && this.coordinates.length) {
      this.coordinates.emit(JSON.stringify({ direction: eventType, startXPos: this.xDown }));
    } else if (eventType) {
      this.swipeCallback.emit(eventType);
    }
    this.xDown = null;
    this.yDown = null;
    this.timeDown = null;
  }
}
