import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { ToolbarModule } from '@components/toolbar';
import { AddressFormModule } from '@shared/address-form/address-form.module';
import { LoadingModule } from '@components/loading';
import { InputModule } from '@components/inputs';
import { PopUpModalModule } from '@components/pop-up-modal';
import { PopUpModule } from '@components/pop-up';
import { CsIconModule } from '@components/icon';
import { RouterModule } from '@angular/router';
import { UserAddressListComponent } from './user-address-list/user-address-list.component';
import { UserAddressEditComponent } from './user-address-edit/user-address-edit.component';
import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'address',
        canActivate: [authenticationGuard, onboardingGuard],
        children: [
          { path: '', component: UserAddressListComponent },
          { path: ':id/edit', component: UserAddressEditComponent },
          { path: 'new', component: UserAddressEditComponent },
        ],
      },
    ]),
    CsIconModule,
    ButtonModule,
    ToolbarModule,
    AddressFormModule,
    LoadingModule,
    InputModule,
    PopUpModalModule,
    PopUpModule,
  ],
  declarations: [
    UserAddressListComponent,
    UserAddressEditComponent,
  ],
})
export class UserAddressModule { }
