import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotificationModule } from '@components/notification';
import { AppRoutes, declarations } from './app-routes';
import { UserModule } from './user/user.module';
import { AboutModule } from './about/about.module';
import { ArticleModule } from './article/article.module';
import { ProductDescriptionModule } from './product-description/product-description.module';
import { EmptyModule } from './empty/empty.module';
import { FaqsModule } from './faqs/faqs.module';
import { FeedbackModule } from './feedback/feedback.module';
import { HelpModule } from './help/help.module';
import { IntroductionModule } from './introduction/introduction.module';
import { LoginDoctorModule } from './login/doctor/login-doctor.module';
import { LogoutModule } from './logout/logout.module';
import { MainConcernModule } from './main-concern/main-concern.module';
import { OnboardingModule } from './onboarding/onboarding.module';
import { IframeModule } from './iframe/iframe.module';
import { NoticeModule } from './notice/notice.module';
import { VideoModule } from './video/video.module';
import { DoctorModule } from './doctor/doctor.module';
import { SupportModule } from './support/support.module';
import { ChatV2Module } from './chatV2/chatV2.module';
import { UserCheckupModule } from './user-checkup/user-checkup.module';
import { ProductCartModule } from './cart/cart.module';
import { OnboardingLanguageModule } from './onboarding-language/onboarding-language.module';
import { QuizGameModule } from './quiz-game/quiz-game.module';
import { ReportModule } from './report/report.module';
import { InstantCheckupModule } from './instant-checkup/instant-checkup.module';
import { ReAcquisitionModule } from './re-acquisition-flow/re-acquisition.module';
import { UserRegimenModule } from './user-regimen/user-regimen.module';
import { TestimonialModule } from './testimonial/testimonial.module';
import { UserCheckoutModule } from './user-checkout/user-checkout.module';
import { UserAddressModule } from './user-address/user-address.module';
import { UserFollowupReportModule } from './user-followup-report/user-followup-report.module';
import { UserOrderModule } from './user-order/user-order.module';
import { UserReferModule } from './user-refer/user-refer.module';
import { UserWalletModule } from './user-wallet/user-wallet.module';
import { UserProfileModule } from './user-profile/user-profile.module';
import { UserPrescriptionViewModule } from './user-prescription/user-prescription-view.module';
import { UserNotificationModule } from './user-notification/user-notification.module';

@NgModule({
  providers: [],
  imports: [
    IntroductionModule,
    OnboardingModule,
    ReAcquisitionModule,
    AboutModule,
    SupportModule,
    DoctorModule,
    FaqsModule,
    ChatV2Module,
    InstantCheckupModule,
    UserRegimenModule,
    TestimonialModule,
    UserCheckoutModule,
    NotificationModule,
    UserAddressModule,
    UserFollowupReportModule,
    UserOrderModule,
    UserReferModule,
    UserWalletModule,
    UserProfileModule,
    UserCheckupModule,
    UserPrescriptionViewModule,
    UserNotificationModule,
    RouterModule.forRoot(AppRoutes),
    ArticleModule,
    EmptyModule,
    FeedbackModule,
    HelpModule,
    IframeModule,
    LogoutModule,
    LoginDoctorModule,
    MainConcernModule,
    NoticeModule,
    UserModule,
    VideoModule,
    UserCheckupModule,
    ProductDescriptionModule,
    QuizGameModule,
    ProductCartModule,
    OnboardingLanguageModule,
    ReportModule,
  ],
  declarations,
  exports: [RouterModule],
})
export class AppRoutingModule { }
