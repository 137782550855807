<section class="tw-flex tw-bg-white tw-border-y tw-border-gray-200">
  <div class="tw-flex-none tw-flex tw-pt-6 tw-px-3 tw-pb-4 tw-w-[100px] tw-h-[200px]">
    <img (click)="onImageClick($event, itemIndex)" class="tw-block tw-object-contain tw-mx-auto" [src]="product?.rebrandedImageWithoutBackground && product.rebrandedImageWithoutBackground?.length ? product?.rebrandedImageWithoutBackground[product?.rebrandedImageWithoutBackground.length - 1] : false || product?.productUnboxedImage[product.productUnboxedImage?.length - 1]" />
  </div>
  <div class="tw-flex-1 tw-px-5 tw-py-6 tw-border-l tw-border-gray-200 tw-min-h-[200px]">
    <div class="tw-font-body tw-font-bold tw-text-500 tw-mb-3">
      {{product.title}}
    </div>
    <div class="tw-font-body tw-text-200 tw-text-gray-500 tw-mb-5" *ngIf="!showProductDescription">
      {{product.purposeDescription}}
    </div>
    <div class="tw-font-body tw-text-200 tw-text-gray-500 tw-mb-5" *ngIf="showProductDescription">
      {{product.description || product.purposeDescription}}
    </div>
    <div class="tw-w-full tw-mb-5" *ngIf="ingredients">
      <h1 class="tw-font-body tw-text-200 tw-text-gray-500">
        <span class="tw-font-bold">Key Ingredients:</span> {{ ingredients }}
      </h1>
    </div>
    <div class="tw-flex tw-items-center tw-gap-2 tw-border tw-border-gray-400 tw-rounded-full tw-p-1 tw-pl-3 tw-w-fit" *ngIf="user?.isPaid() && instructionSet?.paidInstructionVideo && instructionSet?.paidInstructionVideo[userLanguage]" (click)="playVideo.emit(instructionSet)">
      <div class="tw-font-body tw-font-bold tw-text-300 tw-text-green-400" i18n="@@playVideo">
        Play video
      </div>
      <img src="/assets/icons/video_play.svg" />
    </div>
    <div class="tw-flex tw-items-center tw-gap-2 tw-border tw-border-gray-400 tw-rounded-full tw-p-1 tw-pl-3 tw-w-fit" *ngIf="!user?.isPaid() && instructionSet?.unpaidInstructionVideo && instructionSet?.unpaidInstructionVideo[userLanguage]" (click)="playVideo.emit(instructionSet)">
      <div class="tw-font-body tw-font-bold tw-text-300 tw-text-green-400" i18n="@@playVideo">
        Play video
      </div>
      <img src="/assets/icons/video_play.svg" />
    </div>
  </div>
</section>
<div class="tw-bg-teal-100 tw-px-6 tw-py-5" *ngIf="active">
  <h2 class="tw-font-body tw-font-bold text-300 tw-text-teal-500 tw-mb-3">
    Product application
  </h2>
  <ul class="tw-list-disc tw-pl-5 tw-mb-4">
    <li class="tw-mb-2" *ngIf="instructionSet.quantity">
      <span class="tw-font-body tw-text-300 tw-mr-3" i18n="@@howMuchToApply">How much to apply -</span><span class="tw-font-body tw-font-bold tw-text-300">{{instructionSet.quantity}} {{instructionSet.quantityUnit}}</span>
    </li>
    <li class="tw-mb-2" *ngIf="instructionSet.areaOfApplication">
      <span class="tw-font-body tw-text-300 tw-mr-3" i18n="@@whereToApply">Where to apply -</span><span class="tw-font-body tw-font-bold tw-text-300">{{instructionSet.areaOfApplication}}</span>
    </li>
    <li class="tw-mb-2" *ngIf="instructionSet.frequencyOfApplication">
      <span class="tw-font-body tw-text-300 tw-mr-3" i18n="@@whenToApply">When to apply -</span><span class="tw-font-body tw-font-bold tw-text-300">{{instructionSet.frequencyOfApplication}}</span>
    </li>
    <li class="tw-mb-2" *ngIf="instructionSet.durationOfApplication">
      <span class="tw-font-body tw-text-300 tw-mr-3" i18n="@@durationOfApplication">Duration of application -</span><span class="tw-font-body tw-font-bold tw-text-300">{{instructionSet.durationOfApplication}}</span>
    </li>
  </ul>
  <div class="tw-flex tw-flex-col tw-mb-3 tw-bg-white tw-border tw-border-gray-200 tw-shadow-z1 tw-rounded tw-mb-2" *ngIf="instructionSet.notes">
    <div class="tw-flex tw-items-center tw-justify-between tw-px-5 tw-py-2" (click)="toggleExpansion('application')">
      <p class="tw-font-body tw-font-bold tw-text-200 tw-text-teal-500" i18n="@@detailApplicationGuide">
        Detail application Guide
      </p>
      <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-700" [iconName]="'chevron_right'" [ngClass]="{'tw-rotate-90': !expansionPanel['application'], 'tw-rotate-[-90deg]': expansionPanel['application']}"></cs-icon>
    </div>
    <div class="tw-overflow-hidden tw-transition-all" [ngClass]="{'tw-max-h-0': !expansionPanel['application'], 'tw-max-h-[2000px]': expansionPanel['application']}">
      <ul class="tw-list-disc tw-pl-9 tw-py-2">
        <li class="tw-font-body tw-text-200 tw-mb-4" *ngFor="let note of instructionSet.notes">
          {{note}}
        </li>
      </ul>
    </div>
  </div>
  <div class="tw-flex tw-flex-col tw-mb-3 tw-bg-white tw-border tw-border-gray-200 tw-shadow-z1 tw-rounded" *ngIf="instructionSet.warnings && instructionSet?.warnings.length">
    <div class="tw-flex tw-items-center tw-justify-between tw-px-5 tw-py-2" (click)="toggleExpansion('advice')">
      <p class="tw-font-body tw-font-bold tw-text-200 tw-text-teal-500" i18n="@@expertAdivce">
        Expert advice
      </p>
      <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-700" [iconName]="'chevron_right'" [ngClass]="{'tw-rotate-90': !expansionPanel['advice'], 'tw-rotate-[-90deg]': expansionPanel['advice']}"></cs-icon>
    </div>
    <div class="tw-overflow-hidden tw-transition-all" [ngClass]="{'tw-max-h-0': !expansionPanel['advice'], 'tw-max-h-[2000px]': expansionPanel['advice']}">
      <ul class="tw-list-disc tw-pl-9 tw-py-2">
        <li class="tw-font-body tw-text-200 tw-mb-4" *ngFor="let warning of instructionSet.warnings">
          {{warning}}
        </li>
      </ul>
    </div>
  </div>
</div>
