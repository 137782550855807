import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref-service';

declare const Worker: any;

@Injectable()
export class WebWorker {
  worker: any;
  callback: any;
  constructor(private window: WindowRefService) {}

  initialize(): any {
    if (!this.window.nativeWindow.Worker) return;
    this.worker = new Worker('/assets/js/worker.js');
  }

  message(data: any, callback?: Function): void {
    this.callback = callback;
    this.worker.postMessage(data);
  }

  terminate(): any {
    this.worker.terminate();
  }
}
