<div class="card tw-flex tw-flex-row tw-items-center tw-bg-white tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 tw-bg-yellow-100" (click)="handleClick()">
  <img class="tw-flex-none tw-h-20 tw-w-20 tw-rounded-full bg-black tw-bg-green-300" src="/assets/images/sand-watch-loader.gif" />
  <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-2">
    <div>
      <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@reportIsInProcess">
        Report is in process
      </p>
      <p class="tw-font-body tw-text-300 tw-text-black" i18n="@@yourReportWillBeReadyWithin48Hours">
        Your report will be ready within 48 hours
      </p>
    </div>
  </div>
</div>