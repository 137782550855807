<div class="sticky-bar-drawer tw-border-0 tw-bottom-[80px]" *ngIf="showStickyBars$ && userStatus.PAID">
  <swiper class="pB0" #swiperWrapper="" [config]="carouselConfig" (slideChange)="handleSlide($event)"><ng-container *ngFor="let banner of bannerContainer">
      <div class="w100 z-ind-1" *swiperSlide>
        <div class="blockOne tw-flex tw-flex-row tw-p-3 tw-gap-2" *ngIf="banner === 'doctorOrder'">
          <img class="tw-flex-none" [src]="kitUrl" />
          <div class="tw-flex-1">
            <p class="mainOne tw-font-body tw-text-100" i18n="@@orderByDoctorNew">
              Based on your recent checkup, your doctor has recommended
              following products.
            </p>
            <p class="mainTwo">
              <s>₹{{ order?.get("actualPrice") }}</s><span>&nbsp;₹{{ order?.get("amount") }}</span>
            </p>
          </div>
          <cs-button class="tw-flex-none" [class]="'primary btn-xxxs btn-xxxs-padding'" (callback)="handleOrder()"><cs-button-text i18n="@@orderNow" style="padding: 0 10px">Order Now</cs-button-text></cs-button>
          <div class="tw-h-8 tw-w-8 tw-border-gray-200 tw-border tw-rounded-full tw-flex tw-items-center tw-justify-center tw-box-border" (click)="handleCancel($event)">
            <img class="tw-w-4 tw-h-4 tw-m-0" *ngIf="showStickyBars$" src="/assets/images/cancel-cross.png" />
          </div>
        </div>
        <div class="blockOne tw-flex tw-flex-row tw-p-3 tw-gap-2" *ngIf="banner === 'followUpReport'">
          <img class="tw-flex-none doctor-image" *ngIf="
        followUp?.get('allocatedDoctor')?.get('DoctorImageThumbnail')
      " [src]="
        followUp?.get('allocatedDoctor')?.get('DoctorImageThumbnail')
      " /><img class="tw-flex-none doctor-image" *ngIf="!followUp?.get('allocatedDoctor')" [src]="defaultDoctorImage" />
          <div class="tw-flex-1">
            <p class="mainOne tw-font-body tw-text-100">Your Checkup is Due</p>
            <p class="mainTwo tw-font-body tw-text-100 tw-text-gray-500">
              Dr
              {{
        followUp
          ?.get("allocatedDoctor")
          ?.get("DoctorDisplayName")
          ?.split(" ")[1]
        }}
              is Waiting For Your Photo
            </p>
          </div>
          <cs-button class="tw-flex-none" [class]="'primary btn-xxxs btn-xxxs-padding'" (callback)="handleFollowUp()"><cs-button-text i18n="@@startNow" style="padding: 0 10px">Start Now</cs-button-text></cs-button>
          <div class="tw-h-8 tw-w-8 tw-border-gray-200 tw-border tw-rounded-full tw-flex tw-items-center tw-justify-center tw-box-border" (click)="handleCancel($event)">
            <img class="tw-w-4 tw-h-4 tw-m-0" *ngIf="showStickyBars$" src="/assets/images/cancel-cross.png" />
          </div>
        </div>
        <div class="blockOne tw-flex tw-flex-row tw-p-3 tw-gap-2" *ngIf="banner === 'cartItems'">
          <img class="tw-flex-none" src="/assets/images/cart.gif" style="width: 30px; height: 30px" />
          <div class="tw-flex-1">
            <p class="mainOne tw-font-body" *ngIf="toolbarIcons[1].cartValue === 1" i18n="@@oneProductInCart" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn' , 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
              1 Product in your cart
            </p>
            <p class="mainOne tw-font-body" *ngIf="toolbarIcons[1].cartValue > 1" i18n="@@multipleProductsInCart" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn', 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
              {{ toolbarIcons[1].cartValue }} Products in your cart
            </p>
            <p class="mainTwo tw-font-body tw-text-gray-500" i18n="@@placeOrderNow" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn', 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
              Place Order Now
            </p>
          </div>
          <cs-button class="tw-flex-none" [class]="'primary btn-xxxs btn-xxxs-padding'" (callback)="handleNav()" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn', 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}"><cs-button-text i18n="@@buyNow" style="padding: 0 10px">Buy Now</cs-button-text></cs-button>
          <div class="tw-h-8 tw-w-8 tw-border-gray-200 tw-border tw-rounded-full tw-flex tw-items-center tw-justify-center tw-box-border" (click)="handleCancel($event)">
            <img class="tw-w-4 tw-h-4 tw-m-0" *ngIf="showStickyBars$" src="/assets/images/cancel-cross.png" />
          </div>
        </div>
      </div>
    </ng-container></swiper>
</div>