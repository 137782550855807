import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { LoadingModule } from '@components/loading';
import { ToolbarModule } from '@components/toolbar';
import { SwiperModule } from 'swiper/angular';

import { IntroductionComponent } from './introduction.component';

@NgModule({
  imports: [
    RouterModule.forChild([{
      path: 'introduction',
      component: IntroductionComponent,
    }]),
    CommonModule,
    ButtonModule,
    SwiperModule,
    LoadingModule,
    ToolbarModule],
  declarations: [IntroductionComponent],
})
export class IntroductionModule { }
