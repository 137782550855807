<div class="w100" *ngIf="offerTimer.endTime > 0 && (!regimen.active && !regimen.orderPlaced)">
  <discount-timer [endTime]="offerTimer.endTime" [text]="offerTimer.title"></discount-timer>
</div>
<button class="buy-now-btn shine" id="buyNowButton" (click)="buyNow.emit()" [disabled]="isInternalUser">
  <div class="svg-container">
    <svg class="anim-image orange-spin" xmlns="http://www.w3.org/2000/svg" width="64.443" height="67.447" viewBox="0 0 64.443 67.447" [ngStyle]="{'fill': discountSpinnerFillColor}">
      <path id="Path_23051" data-name="Path 23051" d="M87.025,208.059,79.5,203.146l-2.347-8.676a3.039,3.039,0,0,0-3.088-2.243l-8.976.449-7-5.639a3.042,3.042,0,0,0-3.817,0l-7,5.639-8.975-.449a3.031,3.031,0,0,0-3.088,2.243l-2.347,8.676-7.525,4.912a3.042,3.042,0,0,0-1.179,3.63l3.2,8.4-3.2,8.4a3.042,3.042,0,0,0,1.179,3.63l7.525,4.913,2.347,8.675a3.034,3.034,0,0,0,3.088,2.243l8.975-.449,7,5.639a3.041,3.041,0,0,0,3.817,0l7-5.639,8.976.449a3.036,3.036,0,0,0,3.088-2.243l2.347-8.675,7.525-4.913a3.042,3.042,0,0,0,1.179-3.63l-3.2-8.4,3.2-8.4A3.042,3.042,0,0,0,87.025,208.059Z" transform="translate(-23.961 -186.363)"></path>
    </svg><span class="off">{{regimen.discount}}%<br />off</span>
  </div>
  <span class="btn-txt">
    <span [ngStyle]="{ 'font-size': user.get('languagePreference') === 'ta' || user.get('languagePreference') === 'ml' ? '12px' : '18px' }" i18n="@@buyNow">Buy Now</span><span>&#64;</span>
    <span class="original">₹{{regimen.totalMRP}}</span>
    <span>₹{{regimen.totalSP}}</span>
  </span>
</button>