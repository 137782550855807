<div class="tw-flex tw-flex-col tw-gap-4 tw-mb-5 tw-pt-5 tw-pb-6 tw-bg-white">
    <div class="tw-flex tw-justify-between tw-px-5">
        <div class="tw-normal-case tw-h-6 tw-w-36 tw-rounded shimmer"></div>
        <div class="tw-h-5 tw-w-18 tw-rounded shimmer"></div>
    </div>
    <div class="tw-flex tw-gap-5 tw-px-5">
        <div class="tw-w-36 tw-flex tw-flex-col" *ngFor="let i of [1,2,3,4,5]">
            <div class="tw-w-36 tw-mb-3 tw-rounded-t-4 shimmer tw-h-[180px]"></div>
            <div class="tw-mx-3 tw-h-6 tw-mb-2 shimmer"></div>
            <div class="tw-mx-7 tw-h-4 tw-mb-3 shimmer"></div>
            <div class="tw-mx-4 tw-rounded-full tw-h-8 shimmer"></div>
        </div>
    </div>
</div>