import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { LoadingModule } from '@components/loading';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { ToolbarModule } from '@components/toolbar';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { DirectivesModule } from '@directives/directives.module';
import { PopUpModule } from '@components/pop-up';
import { CsIconModule } from '@components/icon';
import { RouterModule } from '@angular/router';
import { UserWalletViewComponent } from './user-wallet-view/user-wallet-view.component';
import { UserWalletTransactionsComponent } from './user-wallet-transactions/user-wallet-transactions.component';
import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: 'wallet',
      canActivate: [authenticationGuard, onboardingGuard],
      children: [
        { path: '', component: UserWalletViewComponent },
        { path: 'transactions', component: UserWalletTransactionsComponent },
      ],
    }]),
    ButtonModule,
    LoadingModule,
    ToolbarTabsModule,
    ToolbarModule,
    DirectivesModule,
    CustomPipeModule,
    PopUpModule,
    CsIconModule,
  ],
  declarations: [UserWalletViewComponent, UserWalletTransactionsComponent],
})

export class UserWalletModule { }
