<ng-container *ngIf="isRebrandingV1; then rebrandedTemplateV1 else originalTemplate"></ng-container>

<ng-template #originalTemplate>
  <!-- Original template -->
  <div class="tw-relative">
    <div class="tw-text-100 tw-uppercase tw-absolute tw-rotate-90 tw-font-bold tw-left-0">
    Low
    </div>
  </div>
  <div class="tw-flex tw-bg-gray-200 tw-mt-20 tw-mx-5 tw-border-black tw-relative tw-h-[15px]">
    <div class="tw-grow" *ngFor="let _ of [].constructor(10); let i=index" [ngClass]="checkForBackground(i+1)"></div>
    <div class="tw-absolute tw-border-black tw-border tw-border-t tw-border-l tw-border-r-0 tw-w-[10px] tw-h-[30px]" style="top: -50%" [style.left]="(leftIdealPercentage === 0 ? '0' : leftIdealPercentage - 10) + '%'"></div>
    <div class="tw-absolute tw-border-black tw-border tw-border-t tw-border-l-0 tw-border-r tw-w-[10px] tw-h-[30px]" style="top: -50%" [style.left]="'calc(' + rightIdealPercentage + '% - 10px)'"></div>
    <div class="tw-flex tw-flex-col tw-justify-center tw-absolute tw-w-[20px]" style="top: -39px" [style.left]="patientScore === 0 ? 'calc(' + patientScore * 10 + '%)' : 'calc(' + (patientScore - 1) * 10 + '%)'">
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center" [ngClass]="{'tw-text-green-500': isIdeal, 'tw-text-orange-500': !isIdeal}">
        <div class="tw-text-200 tw-m-0 tw-ml-[-4px]">You</div>
        <span class="tw-m-0 tw-ml-[-4px]">&darr;</span>
      </div>
      <div class="tw-relative tw-h-[15px] tw-w-[15.6px] tw-mt-[-1px]" [ngClass]="{'tw-bg-green-500': isIdeal, 'tw-bg-orange-500': !isIdeal}"></div>
    </div>
    <div class="tw-text-100 tw-uppercase tw-absolute tw-font-bold tw-rotate-90" style="right: -20px !important"> High </div>
  </div>
  <div class="tw-mx-4">
    <div class="tw-flex tw-mt-2 tw-justify-center tw-items-center tw-relative" [style.width]="'calc(' + (rightIdealPercentage - leftIdealPercentage) + '%' + ')'" [style.left]="leftIdealPercentage + '%'">
      <div class="tw-text-100 tw-uppercase">Ideal</div>
    </div>
  </div>
</ng-template>

<ng-template #rebrandedTemplateV1>
  <!-- Rebranded template v1 -->
  <cs-icon *ngIf="!isIdeal" class="tw-absolute tw-mt-[-252px] tw-ml-4 tw-w-6 tw-h-6 tw-rounded-[50%] tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)]" [iconName]="'/rebranding/v1/exclamation_icon'"></cs-icon>
  <div class="tw-relative">
    <div class="tw-text-100 tw-text-gray-800 tw-uppercase tw-absolute tw-rotate-[-90deg] tw-font-bold tw-left-0 tw-top-[5px]">
    Low
    </div>
  </div>
  <div class="tw-flex tw-bg-gray-300 tw-mt-20 tw-mx-5 tw-border-gray-800 tw-relative tw-h-6">
    <div class="tw-grow" *ngFor="let _ of [].constructor(10); let i=index" [ngClass]="checkForBackground(i+1)"></div>
    <div class="tw-absolute tw-border-gray-800 tw-border tw-border-t tw-border-l tw-border-r-0 tw-w-[5px] tw-h-[28px] tw-z-10" style="top: -10%" [style.left]="(leftIdealPercentage === 0 ? '0' : leftIdealPercentage - 10) + '%'"></div>
    <div class="tw-absolute tw-border-gray-800 tw-border tw-border-t tw-border-l-0 tw-border-r tw-w-[5px] tw-h-[28px] tw-z-10" style="top: -10%" [style.left]="'calc(' + rightIdealPercentage + '% - 5px)'"></div>
    <div class="tw-flex tw-flex-col tw-justify-center tw-absolute tw-w-[20px]" style="top: -39px" [style.left]="patientScore === 0 ? 'calc(' + patientScore * 10 + '%)' : 'calc(' + (patientScore - 1) * 10 + '%)'">
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center" [ngClass]="{'tw-text-green-400': isIdeal, 'tw-text-orange-400': !isIdeal}">
        <div class="tw-text-200 tw-m-0 tw-ml-[-4px]">You</div>
        <span class="tw-m-0 tw-ml-[-4px]">&darr;</span>
      </div>
      <div class="tw-relative tw-h-6 tw-w-[17.6px] tw-mt-[-0.5px]" [ngClass]="{'tw-bg-green-400': isIdeal, 'tw-bg-orange-400': !isIdeal}"></div>
    </div>
    <div class="tw-text-100 tw-text-gray-800 tw-uppercase tw-absolute tw-font-bold tw-rotate-[-90deg] tw-top-[5px]" style="right: -20px !important"> High </div>
  </div>
  <div class="tw-mx-4">
    <div class="tw-flex tw-mt-2 tw-justify-center tw-items-center tw-relative" [style.width]="'calc(' + (rightIdealPercentage - leftIdealPercentage) + '%' + ')'" [style.left]="leftIdealPercentage + '%'">
      <div class="tw-text-100 tw-uppercase">Ideal</div>
    </div>
  </div>
</ng-template>