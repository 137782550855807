import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { CsIconModule } from '@components/icon';
import { RouterModule } from '@angular/router';
import { FaqsComponent } from './faqs.component';
import { onboardingGuard } from '../guards/onboarding.guard';
import { authenticationGuard } from '../guards/authentication.guard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: 'faq',
      canActivate: [authenticationGuard, onboardingGuard],
      children: [
        { path: '', component: FaqsComponent },
        { path: ':id', component: FaqsComponent },
      ],
    }]),
    FormsModule,
    ToolbarModule,
    LoadingModule,
    CustomPipeModule,
    CsIconModule,
  ],
  declarations: [FaqsComponent],
  exports: [],
})
export class FaqsModule { }
