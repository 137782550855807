import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppStateI } from '@store/reducers';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { fromActions } from '@store/actions';

@Component({
  selector: 'followup-chat-sheet',
  templateUrl: './followup-chat-sheet.html',
  styleUrls: [],
})
export class FollowUpChatSheetComponent implements OnInit {
  allocatedDoctor: any;
  /**
     * The constructor injects the data passed to the bottom sheet using Angular's dependency injection.
     * The 'new-cap' linting rule is disabled for this line because it doesn't apply in this case,
     * as the uppercase letter is part of the Angular-specific token MAT_BOTTOM_SHEET_DATA.
   */
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  private conn: ConnectionService,
  private router: Router,
  private eventLogger: EventLoggerService,
  private store: Store<AppStateI>,
  private bottomSheetRef: MatBottomSheetRef) {}

  async ngOnInit(): Promise<void> {
    this.allocatedDoctor = await this.conn?.findUserByObjectId(this.data.user?.get('allocatedDoctor')?.id);
  }

  async handleClick(userResponse: boolean): Promise<void> {
    if (userResponse) {
      let reAcquisitionResponse = this.data.user.get('reAcquisitionResponse');
      if (!reAcquisitionResponse) {
        const res = { reAcquisitionResponseYes: true };
        reAcquisitionResponse = res;
      }
      if (!reAcquisitionResponse.reAcquisitionResponseYes) {
        reAcquisitionResponse.reAcquisitionResponseYes = true;
      }
      reAcquisitionResponse.lastReAcquisititionResponseTime = new Date().toISOString();
      this.data.user.set('reAcquisitionResponse', reAcquisitionResponse);
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 're-acq-folloup-banner-yes' }));
      this.router.navigate(['re-acquisition/user-query']);
    } else {
      let reAcquisitionResponse = this.data.user.get('reAcquisitionResponse');
      if (!reAcquisitionResponse) {
        const res = { totalReAcquisitionResponse: 0 };
        reAcquisitionResponse = res;
      }
      reAcquisitionResponse.totalReAcquisitionResponse += 1;
      this.data.user.set('reAcquisitionResponse', reAcquisitionResponse);
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 're-acq-followup-banner-no' }));
    }
    this.closeBottomSheet();
    this.store.dispatch(fromActions.HomePageUpdate({ showFollowUpBanner: false }));
    this.data.user.save();
  }

  closeBottomSheet(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 're-acq-folloup-banner-cancel' }));
    this.store.dispatch(fromActions.HomePageUpdate({ showFollowUpBanner: false }));
    this.bottomSheetRef.dismiss();
  }
}
