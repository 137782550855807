import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { GetSupportQueryComponent } from './get-support-query.component';

@NgModule({
  imports: [CommonModule, ButtonModule, FormsModule],
  declarations: [GetSupportQueryComponent],
  exports: [GetSupportQueryComponent],
})
export class GetSupportQueryModule {}
