import { Component, EventEmitter, Output } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'app-doctor-panel',
  templateUrl: './doctor-panel.component.html',
})
export class DoctorPanelComponent {
  @Output('openDoctorList') openDoctorList: EventEmitter<any> = new EventEmitter();
  constructor(
    private eventLogger: EventLoggerService) {
  }

  handleClick(): void {
    this.openDoctorList.emit();
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'homepage-expert-panel' }));
  }
}
