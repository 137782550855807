import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AppConfig } from 'client/app/app.config';
import { ConnectionService } from '@services/connection-service';
import { DataStoreService } from '@services/data-store-service';
import { EventLoggerService } from '@services/event-logger-service';
import { ActivatedRoute } from '@angular/router';
import { WindowRefService } from '@services/window-ref-service';

@Component({
  selector: 'app-faq-section',
  templateUrl: './faq-section.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqSectionComponent implements OnInit {
  user: any;
  isPaidUser: boolean;
  faqs: any[];
  loading: boolean = false;
  pageRoute: string = this.route.snapshot.queryParams?.tab;

  constructor(
    public appConfig: AppConfig,
    public conn: ConnectionService,
    private dataStore: DataStoreService,
    private changeDetector: ChangeDetectorRef,
    private logger: EventLoggerService,
    private route: ActivatedRoute,
    private windowRef: WindowRefService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.user = await this.conn?.getActingUser();
    this.isPaidUser = this.user.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED;
    await this.loadFaqs();
    this.changeDetector.detectChanges();
  }

  async loadFaqs(): Promise<void> {
    try {
      this.loading = true;
      const data = await this.conn.fetchFAQ();
      const faqIds: string[] = this.getFaqIdsForUser();
      this.faqs = this.filterFaqsById(data.faqs, faqIds);
      this.dataStore.set('FAQS', data);
    } catch (error) {
      this.faqs = [];
    } finally {
      this.loading = false;
    }
  }

  private getFaqIdsForUser(): string[] {
    return this.isPaidUser
      ? this.appConfig.Shared.Faq.paidUserFaqIds
      : this.appConfig.Shared.Faq.unpaidUserFaqIds;
  }

  private filterFaqsById(allFaqs: any[], faqIds: string[]): any[] {
    return faqIds.map((id: string): any => allFaqs.find((faq: any): boolean => faq.id === id));
  }

  expandCollapse(index: any, container: any): any {
    const parent = container.querySelector(`#faq-${index}`);
    const arrow = parent.querySelector('.cs-icon-arrow');
    if (arrow.style.transform === 'rotate(270deg)') {
      arrow.style.transform = 'rotate(90deg)';
    } else {
      arrow.style.transform = 'rotate(270deg)';
    }
    arrow.parentElement.parentElement.nextElementSibling.classList.toggle('tw-hidden');
    this.logger.cleverTapEvent('click', JSON.stringify({ pageName: `FAQ_clicked_${this.pageRoute}page` }));
  }

  viewAllFaqs(): void {
    this.conn.navigateToURL('/faq');
    this.logger.cleverTapEvent('click', JSON.stringify({ pageName: `ViewMore_FAQ_${this.pageRoute}page` }));
  }
}
