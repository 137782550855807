import { NgModule } from '@angular/core';
import { ButtonModule } from '@components/button';
import { PopUpModule } from '@components/pop-up';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingModule } from '@components/loading';
import { HttpClientModule } from '@angular/common/http';
import { RegimenConcernSelectionModule } from '@shared/regimen-concern-selection/regimen-concern-selection.module';

import { OnboardingLocationComponent } from './onboarding-location/onboarding-location.component';
import { OnboardingGenderComponent } from './onboarding-gender/onboarding-gender.component';
import { OnboardingMobileNumberComponent } from './onboarding-number/onboarding-mobile-number.component';
import { OnboardingMobileOtpComponent } from './onboarding-otp/onboarding-mobile-otp.component';
import { OnboardingConcernComponent } from './onboarding-concern/onboarding-concern.component';
import { OnboardingTakePhotoComponent } from './onboarding-take-photo/onboarding-take-photo.component';
import { OnboardingCheckupDetectionsComponent } from './onboarding-checkup-detections/onboarding-checkup-detections.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'onboarding',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/',
          },
          {
            path: 'gender',
            component: OnboardingGenderComponent,
          },
          {
            path: 'number',
            component: OnboardingMobileNumberComponent,
          },
          {
            path: 'otp',
            component: OnboardingMobileOtpComponent,
          },
          {
            path: 'concern',
            component: OnboardingConcernComponent,
          },
          {
            path: 'take-photo',
            component: OnboardingTakePhotoComponent,
          },
          {
            path: 'checkup-detections',
            component: OnboardingCheckupDetectionsComponent,
          },
          {
            path: 'location',
            component: OnboardingLocationComponent,
          },
        ],
      },
    ]),
    ButtonModule,
    CsIconModule,
    PopUpModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoadingModule,
    ButtonModule,
    RegimenConcernSelectionModule,
    PopUpModule,
  ],
  declarations: [
    OnboardingLocationComponent,
    OnboardingMobileNumberComponent,
    OnboardingMobileOtpComponent,
    OnboardingGenderComponent,
    OnboardingConcernComponent,
    OnboardingCheckupDetectionsComponent,
    OnboardingTakePhotoComponent,
  ],
  exports: [OnboardingConcernComponent],
})
export class OnboardingModule { }
