/* eslint-disable arrow-body-style */
import { createReducer, on } from '@ngrx/store';
import { RequestQueryPayload } from 'api-client';
import { fromActions } from '../actions';
import * as Types from '../modals';

export interface AppStateI {
  currentUser: Types.User;
  config: Types.ConfigI;
  experiments: any[];
  homePage: Types.HomePageDataI;
  addressBook: Types.AddressBookStateI;
  checkout: Types.CheckoutI;
  orderState: Types.OrderSateI;
  support: Types.SupportI;
  recentSupportTickets: Types.RecentSupportTicketI;
  cart: Types.CartI;
}

export const initialState: AppStateI = {
  currentUser: null,
  config: {},
  experiments: [],
  homePage: { blogs: [],
    addonProducts: [],
    reorderProducts: [],
    isAddonProductsLoading: false,
    isReorderProductsLoading: false,
    showConsultationBanner: true,
    showAnytimeFollowupPopup: true,
    isSessionOn: true,
    showFollowUpBanner: true },
  addressBook: { error: '', addressList: [], defaultAddress: null, isFetchInProgress: false, saveInProcess: false },
  checkout: { checkoutInProgress: false },
  orderState: { isLoading: true, inProcess: false, orders: [] },
  support: { category: [], inProcess: false, questions: [], questionsByCategory: {}, queries: {}, error: undefined },
  recentSupportTickets: { inProcess: false, closedTickets: [], openTickets: [], reFetch: true },
  cart: { products: {}, inProcess: false },
};

export const appReducer = createReducer(
  initialState,
  on(fromActions.ConfigUpdate,
    (state: AppStateI, config: Types.ConfigI): AppStateI => {
      return { ...state, config: { ...state.config, ...config } };
    }),
  on(fromActions.ExperimentsUpdate,
    (state: AppStateI, { experiments }: { experiments: any[] }): AppStateI => {
      return { ...state, experiments };
    }),
  on(fromActions.HomePageUpdate,
    (state: AppStateI, props: Partial<Types.HomePageDataI>): AppStateI => {
      return { ...state, homePage: { ...state.homePage, ...props } };
    }),
  on(fromActions.AddressBookUpdate,
    (state: AppStateI, props: { payload: Partial<Types.AddressBookStateI> }): AppStateI => {
      return ({ ...state, addressBook: { ...state.addressBook, ...props.payload } });
    }),
  on(fromActions.CheckoutOrderUpdate,
    (state: AppStateI, { checkout }: { checkout: Types.CheckoutI }): AppStateI => {
      return ({ ...state, checkout: { ...state.checkout, ...checkout } });
    }),
  on(fromActions.OrderStateUpdate,
    (state: AppStateI, payload: Partial<Types.OrderSateI>): AppStateI => {
      return ({ ...state, orderState: { ...state.orderState, ...payload } });
    }),
  on(fromActions.SupportStateUpdate,
    (state: AppStateI, payload: Partial<Types.SupportI>): AppStateI => {
      return ({ ...state, support: { ...state.support, ...payload } });
    }),
  on(fromActions.SupportRecentTicketUpdate,
    (state: AppStateI, payload: Partial<Types.RecentSupportTicketI>): AppStateI => {
      return ({ ...state, recentSupportTickets: { ...state.recentSupportTickets, ...payload } });
    }),
  on(fromActions.CartUpdateProductsBegin,
    (state: AppStateI, payload: Partial<Types.CartI>): AppStateI => {
      return ({ ...state, cart: { ...state.cart, ...payload } });
    }),
  on(fromActions.SupportQuestionsFetchUpdate, (
    state: AppStateI,
    payload: Partial<{ query: RequestQueryPayload<Table.SupportQuestion>; questions: Array<unknown>; error: string; }>): AppStateI => {
    const support = { ...state.support, queries: { ...state.support.queries } };
    support.error = payload.error;
    if (payload.questions) {
      support.queries[JSON.stringify(payload.query)] = { questions: payload.questions };
      support.questions = payload.questions;
    }
    return { ...state, support };
  }),
  on(fromActions.GetSupportQuestionFromStore, (state: AppStateI,
    payload: Partial<{ query: RequestQueryPayload<Table.SupportQuestion>;}>): AppStateI => {
    const queryString = JSON.stringify(payload.query);
    const support = { ...state.support };
    if (state.support.queries[queryString]) {
      support.questions = state.support.queries[queryString].questions;
    } else {
      support.questions = undefined;
    }
    return { ...state, support };
  }),
);
