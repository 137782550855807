import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConnectionService } from '../connection-service';

@Injectable()
export class AwsService {
  constructor(private conn: ConnectionService, private http: HttpClient) {}

  dataURLtoFile(dataUrl: string): any {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const binaryString = Buffer.from(arr[1], 'base64').toString('binary');
    let { length }: any = binaryString;
    const u8arr = new Uint8Array(length);
    while (length) {
      length -= 1;
      u8arr[length] = binaryString.charCodeAt(length);
    }
    const blob = new Blob([u8arr], { type: mime });
    return new File([blob], null, { type: mime });
  }

  saveImageToS3(canvas: any, source: string): Promise<any> {
    const user = this.conn.getCurrentUser();
    return this.conn.createUserImageUploadUrl('jpg', user.get('username'), source)
      .then((payload: any): Promise<unknown> => {
        const formData = new FormData();
        Object.keys(payload.fields).forEach((field: string): void => formData.append(field, payload.fields[field]));
        formData.append('file', this.dataURLtoFile(canvas.nativeElement.toDataURL('image/jpeg')));
        const headers = new HttpHeaders();
        return new Promise((resolve: (item: string) => void): void => {
          this.http.post(payload.signedURL[0], formData, { headers, responseType: 'text' })
            .subscribe((data: string): void => resolve(data));
        });
      });
  }
}
