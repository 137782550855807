<div id="stopPropagation" class="tw-font-body tw-block tw-border-spacing-0 tw-w-full tw-flex-1 tw-overflow-hidden tw-text-300 tw-h-screen" (click)="stopPropagation($event)">
  <div class="tw-flex tw-flex-1 tw-h-full tw-flex-col">
    <div class="tw-w-full tw-flex-initial tw-bg-orange-200 tw-text-gray-900 tw-font-body tw-text-300 tw-leading-300 tw-pt-0 tw-px-6 tw-pb-4" *ngIf="!isRebrandingV1Burger">
      <div class="tw-flex tw-flex-col tw-pt-24 new-header">
        <div id="backBtn" class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-white" iconName="arrow_back"></cs-icon>
        </div>
      </div>
      <div id="navBtn1" class="tw-text-gray-900 tw-flex tw-justify-between tw-items-end tw-text-tw-pb-4 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis" *ngIf="user" (click)="navigateTo('/user/profile/edit')">
        <div>
          <div class="tw-capitalize tw-font-bold tw-text-400 tw-leading-none tw-mb-1">{{ user?.get('PatientName') ? user?.get('PatientName')?.trim().split(' ')[0] : "Hello" }}</div>
          <div class="tw-block tw-text-200 tw-leading-none tw-mb-1" *ngIf="user?.get('MobileNumber')">+91 {{user?.get('MobileNumber')}}</div>
          <div class="tw-block tw-text-200 tw-leading-none" *ngIf="user?.get('contactDetails')?.userEmail"> {{user?.get('contactDetails')?.userEmail}}</div>
        </div>
        <div class="tw-flex tw-items-center tw-gap-2 tw-h-8 tw-border tw-border-gray-900 tw-rounded-4 tw-px-4 tw-text-200 tw-font-bold" style="min-height: 32px;">
          <span i18n="@@editProfile">Edit</span>
          <em class="cs-icon-arrow tw-text-100 tw-text-gray-900"></em>
        </div>
      </div>
    </div>
    <div class="tw-w-full tw-flex-initial tw-bg-teal-200 tw-text-gray-900 tw-font-body tw-text-300 tw-leading-300 tw-pt-0 tw-px-6 tw-pb-4" *ngIf="isRebrandingV1Burger">
      <div class="tw-flex tw-flex-col tw-pt-24 new-header">
        <div id="backBtn" class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-white" iconName="arrow_back"></cs-icon>
        </div>
      </div>
      <div id="navBtn1" class="tw-text-gray-900 tw-flex tw-justify-between tw-items-end tw-text-tw-pb-4 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis" *ngIf="user" (click)="navigateTo('/user/profile/edit')">
        <div>
          <div class="tw-capitalize tw-font-body tw-font-bold tw-text-400 tw-leading-none tw-mb-1">{{ user?.get('PatientName') ? user?.get('PatientName')?.trim().split(' ')[0] : "Hello" }}</div>
          <div class="tw-block tw-text-200 tw-leading-none tw-mb-1" *ngIf="user?.get('MobileNumber')">+91 {{user?.get('MobileNumber')}}</div>
          <div class="tw-block tw-text-200 tw-leading-none" *ngIf="user?.get('contactDetails')?.userEmail"> {{user?.get('contactDetails')?.userEmail}}</div>
        </div>
        <div class="tw-flex tw-items-center tw-gap-2 tw-h-8 tw-border tw-border-gray-900 tw-rounded-4 tw-px-4 tw-text-200 tw-font-bold" style="min-height: 32px;">
          <span  class="tw-lowercase" i18n="@@editProfile">Edit</span>
          <em class="cs-icon-arrow tw-text-100 tw-text-gray-900"></em>
        </div>
      </div>
    </div>
    <div class="tw-w-full tw-flex-auto tw-overflow-y-auto tw-overflow-x-hidden tw-bg-gray-100 tw-pb-[86px]">
      <ng-container *ngIf="!showRefundPolicyOptions && !isRebrandingV1Burger">
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/user/instantCheckup/list')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-5 tw-w-5" iconName="user_photos"></cs-icon>
          </div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span i18n="@@myPreviousPhotos">My Photos</span><em class="cs-icon-arrow tw-pr-3 tw-font-normal tw-text-200 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/user/wallet')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-5 tw-w-5" iconName="wallet"></cs-icon>
          </div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span i18n="@@myWallet">My Wallet</span><em class="cs-icon-arrow tw-pr-3 tw-font-normal tw-text-200 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/user/orders')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-5 tw-w-5" iconName="orders"></cs-icon>
          </div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span class="tw-capitalize" i18n="@@myOrders">My orders</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/user/refer')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-5 tw-w-5" iconName="refer_earn"></cs-icon>
          </div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span i18n="@@referAndEarn">Refer & Earn</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/user/prescription')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600"><em class="cs-icon-prescription tw-text-gray-900 tw-h-5 tw-w-5"></em></div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span i18n="@@myPrescription">My Prescription</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/language', { force: true })">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600"><em class="cs-icon-language-globe tw-text-gray-900 tw-h-5 tw-w-5"></em></div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span i18n="@@changeLanguage">Change Language</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/support')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600"><em class="cs-icon-help tw-text-gray-900 tw-h-5 tw-w-5"></em></div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span><span i18n="@@help">Help</span></span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="showRefundPolicyOptions = true">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600"><em class="cs-icon-help tw-text-gray-900 tw-h-5 tw-w-5"></em></div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span><span>Policies</span></span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
      </ng-container>
      <ng-container *ngIf="showRefundPolicyOptions">
        <div id="refundPolicyOpt" class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card1" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="redirectTo('/iframe?src=https://cdn.cureskin.com/refunds-and-cancellations.html')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-text-gray-900 tw-h-5 tw-w-5" iconName="user_photos"></cs-icon>
          </div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span>Refund and Cancellation</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card1" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="redirectTo('/iframe?src=https://cdn.cureskin.com/grievance-policy.html')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-text-gray-900 tw-h-5 tw-w-5" iconName="wallet"></cs-icon>
          </div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span>Grievance Policy</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card1" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="redirectTo('/iframe?src=https://cdn.cureskin.com/privacypolicy_new.html')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-text-gray-900 tw-h-5 tw-w-5" iconName="orders"></cs-icon>
          </div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span class="tw-capitalize">Privacy Policy</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card1" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="redirectTo('/iframe?src=https://cdn.cureskin.com/termsofservice_new.html')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-text-gray-900 tw-h-5 tw-w-5" iconName="refer_earn"></cs-icon>
          </div>
          <div class="tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300"><span>Terms of Use</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
      </ng-container>

       <ng-container *ngIf="!showRefundPolicyOptions && isRebrandingV1Burger">
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/user/instantCheckup/list')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-6 tw-w-6" iconName="new_user_photos"></cs-icon>
          </div>
          <div class="tw-text-sm tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300 tw-font-normal"><span i18n="@@myPreviousPhotos">My Photos</span><em class="cs-icon-arrow tw-pr-3 tw-font-normal tw-text-200 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/user/wallet')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-6 tw-w-6" iconName="new_wallet"></cs-icon>
          </div>
          <div class="tw-text-sm tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300 tw-font-normal"><span i18n="@@myWallet">My Wallet</span><em class="cs-icon-arrow tw-pr-3 tw-font-normal tw-text-200 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/user/orders')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-6 tw-w-6" iconName="new_orders"></cs-icon>
          </div>
          <div class="tw-text-sm tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300 tw-font-normal"><span class="tw-capitalize" i18n="@@myOrders">My orders</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/user/refer')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-6 tw-w-6" iconName="new_refer_earn"></cs-icon>
          </div>
          <div class="tw-text-sm tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300 tw-font-normal"><span i18n="@@referAndEarn">Refer & Earn</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/user/prescription')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600"><em class="cs-icon-prescription tw-text-gray-900 tw-h-5 tw-w-5"></em></div>
          <div class="tw-text-sm tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300 tw-font-normal"><span i18n="@@myPrescription">My Prescription</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/language', { force: true })">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-6 tw-w-6" iconName="language_globe"></cs-icon>
          </div>
          <div class="tw-text-sm tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300 tw-font-normal"><span i18n="@@changeLanguage">Change Language</span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="navigateTo('/support')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-6 tw-w-6" iconName="help_question"></cs-icon>
          </div>          
          <div class="tw-text-sm tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300 tw-font-normal"><span><span i18n="@@help">Help</span></span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (callback)="showRefundPolicyOptions = true">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600"><em class="cs-icon-help tw-text-gray-900 tw-h-5 tw-w-5"></em></div>
          <div class="tw-text-sm tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300 tw-font-normal"><span><span>Policies</span></span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
        <div id="feedbackBtn" class="tw-px-3 tw-py-2 tw-flex tw-justify-center tw-items-center tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-font-body tw-font-normal tw-h-16 user-card" style="flex-flow: wrap;" rippleEffect="rippleEffect" (click)="navigateTo('/feedback')">
          <div class="tw-w-10 tw-h-tw-w-10 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-600">
            <cs-icon class="tw-h-6 tw-w-6" iconName="new_orders"></cs-icon>
          </div>  
           <div class="tw-text-sm tw-flex-1 tw-pl-3 tw-flex tw-justify-between tw-font-body tw-text-300 tw-font-normal"><span><span i18n="@@shareYourFeedbackMenu">Share Your Feedback</span></span><em class="cs-icon-arrow tw-font-normal tw-text-200 tw-pr-3 tw-text-gray-900"></em></div>
        </div>
      </ng-container>
    </div>
    <div class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-w-full tw-flex tw-items-center tw-justify-between tw-p-6 tw-bg-gray-100 tw-font-body tw-text-300 tw-backdrop-blur-sm" style="background-color: #f5f5f566 !important;">
      <div id="logoutBtn" class="tw-bg-gray-900 tw-rounded-6 tw-text-gray-50 tw-py-2.5 tw-px-4 tw-text-300 tw-flex tw-gap-2.5" (click)="navigateTo('/logout')">
        <span i18n="@@logout">Logout</span>
        <em class="cs-icon-exit tw-text-300 tw-text-white"></em>
      </div>
      <span class="tw-text-100 tw-text-gray-400" *ngIf="appVersion"><span id="appVersion">{{appVersion}}</span> &nbsp; <span>{{androidVersion}}</span></span>
    </div>
  </div>
</div>
<pop-up-modal [config]="ui.popUpModal" (close)="closePopUp($event)"></pop-up-modal>
