<div class="tw-flex tw-flex-col tw-h-full">
  <div class="tw-flex-auto tw-relative tw-h-full">
  <user-home id="tab1" *ngIf="isTabActive.HOME" [refresh]="isTabActive.HOME" [animation]="tabAnimation.HOME" [tabUpdates]="isTabHasUpdates"></user-home>
  <user-assistant id="tab2" *ngIf="isTabActive.CHAT" [tabUpdates]="isTabHasUpdates"></user-assistant>
  <user-shop id="tab5" *ngIf="isTabActive.SHOP" [refresh]="isTabActive.SHOP" [animation]="tabAnimation.SHOP"></user-shop>
  <user-view-regimen id="tab3" *ngIf="isTabActive.REGIMEN" [refresh]="isTabActive.REGIMEN" [animation]="tabAnimation.REGIMEN" (showNewRegimen)="setNewRegimenFlag($event)"></user-view-regimen>
  <user-explore *ngIf="!exploreSectionExperiment && isTabActive.EXPLORE" id="tab4" [refresh]="isTabActive.EXPLORE" [animation]="tabAnimation.EXPLORE"></user-explore>
  </div>
  <div class="floating-nav tw-flex-none tw tw-h-20 tw-flex tw-flex-row" *ngIf="!isTabActive.HOME && !isTabActive.REGIMEN && !hideBottomNavBar">
  <div class="navHolder tw-h-20">
    <ng-container *ngTemplateOutlet="navBar"></ng-container>
  </div>
  </div>
  <div class="floating-nav tw-flex-none tw tw-h-20 tw-flex tw-flex-row tw-border-none" *ngIf="isTabActive.HOME && !hideBottomNavBar">
  <div class="navHolder tw-h-20">
    <ng-container *ngTemplateOutlet="navBar"></ng-container>
  </div>
  </div>
</div>
<ng-template #navBar="">
  <div class="tw-h-full tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" rippleEffect="rippleEffect" (callback)="onTabChange(0)">
  <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-black tw-rounded-br tw-rounded-bl" [ngClass]='{"tw-bg-purple-400": isTabActive.HOME, "tw-bg-white": !isTabActive.HOME}'></div>
  <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
    <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" *ngIf="!isTabActive.HOME" [iconName]='"home"'></cs-icon><cs-icon class="tw-h-8 tw-w-8 tw-text-purple-400" *ngIf="isTabActive.HOME" [iconName]='"home_solid"'></cs-icon>
    <div class="tw-font-body tw-text-200" i18n="@@home" [ngClass]="{ 'tw-text-gray-500': !isTabActive.HOME, 'tw-text-purple-400': isTabActive.HOME }">
    Home
    </div>
  </div>
  </div>
  <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" rippleEffect="rippleEffect" (click)="needHelpClick()">
  <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-black tw-rounded-br tw-rounded-bl" [ngClass]='{"tw-bg-purple-400": isTabActive.CHAT, "tw-bg-white": !isTabActive.CHAT}'></div>
  <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
    <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" *ngIf="!isTabActive.CHAT" [iconName]='"help"'></cs-icon><cs-icon class="tw-h-8 tw-w-8 tw-text-purple-400" *ngIf="isTabActive.CHAT" [iconName]='"help_solid"'></cs-icon>
    <div class="tw-font-body tw-text-200" i18n="@@help" [ngClass]="{ 'tw-text-gray-500': !isTabActive.CHAT, 'tw-text-purple-400': isTabActive.CHAT }">Help</div>
  </div>
  </div>
  <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" *ngIf="userStatus.PAID" rippleEffect="rippleEffect" (callback)="onTabChange(4);shopClick()">
  <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-white" [ngClass]='{"tw-bg-purple-400": isTabActive.SHOP, "tw-bg-white": !isTabActive.SHOP}'></div>
  <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
    <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" *ngIf="!isTabActive.SHOP" [iconName]='"storefront"'></cs-icon><cs-icon class="tw-h-8 tw-w-8 tw-text-purple-400" *ngIf="isTabActive.SHOP" [iconName]='"storefront_solid"'></cs-icon>
    <div class="tw-font-body tw-text-200" i18n="@@shop" [ngClass]="{ 'tw-text-gray-500': !isTabActive.SHOP, 'tw-text-purple-400': isTabActive.SHOP }">
    Shop
    </div>
  </div>
  </div>
  <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" *ngIf="!userStatus.PAID" rippleEffect="rippleEffect" (callback)="cameraClick()">
  <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-white"></div>
  <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
    <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" [iconName]='"photo_camera"'></cs-icon>
    <div class="tw-font-body tw-text-200 tw-text-gray-500" i18n="@@photo">Photo</div>
  </div>
  </div>
  <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" rippleEffect="rippleEffect" (callback)="onTabChange(2)">
  <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-black tw-rounded-br tw-rounded-bl" [ngClass]='{"tw-bg-purple-400": isTabActive.REGIMEN, "tw-bg-white": !isTabActive.REGIMEN}'></div>
  <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
    <cs-icon class="tw-h-8 tw-w-8" [iconName]='"sanitizer"' [ngClass]="{ 'tw-text-gray-500': !isTabActive.REGIMEN, 'tw-text-black': isTabActive.REGIMEN }"></cs-icon>
    <div class="tw-font-body tw-text-200" i18n="@@treatment" [ngClass]="{ 'tw-text-gray-500': !isTabActive.REGIMEN, 'tw-text-black': isTabActive.REGIMEN }">
    Treatment
    </div>
  </div>
  </div>
  <div *ngIf="!exploreSectionExperiment" class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" rippleEffect="rippleEffect" (callback)="onTabChange(3);exploreClick()">
  <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-black tw-rounded-br tw-rounded-bl" [ngClass]='{"tw-bg-purple-400": isTabActive.EXPLORE, "tw-bg-white": !isTabActive.EXPLORE}'></div>
  <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
    <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" *ngIf="!isTabActive.EXPLORE" [iconName]='"manage_search"'></cs-icon><cs-icon class="tw-h-8 tw-w-8 tw-text-purple-400" *ngIf="isTabActive.EXPLORE" [iconName]='"manage_search_solid"'></cs-icon>
    <div class="tw-font-body tw-text-200" i18n="@@explore" [ngClass]="{ 'tw-text-gray-500': !isTabActive.EXPLORE, 'tw-text-purple-400': isTabActive.EXPLORE }">Explore</div>
  </div>
  </div>
</ng-template>
