import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { DeletePhotoSheetComponent } from './delete-photo-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
  ],
  declarations: [DeletePhotoSheetComponent],
  exports: [DeletePhotoSheetComponent],
})
export class DeletePhotoSheetModule { }
