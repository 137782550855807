import { Component, ViewChild, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { DOCUMENT } from '@angular/common';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { WindowRefService } from '@services/window-ref-service';
import { AppConfig } from 'client/app/app.config';
import { TestimonialVideoTitles } from 'e2e/src/shared/constants';

@Component({
  selector: 'video-feed',
  templateUrl: './video-feed.html',
  styleUrls: [],
})

export class VideoFeedComponent {
  @ViewChild('videoPlayer', { static: false }) videoPlayer: ElementRef<HTMLVideoElement>;
  videoUrls: string[] = [
    'https://storage.googleapis.com/heallocontent/app/img/testimonials/csjourney_2.mp4',
    'https://storage.googleapis.com/heallocontent/app/img/testimonials/csjourney_3.mp4',
    'https://storage.googleapis.com/heallocontent/app/img/testimonials/csjourney_4.mp4',
    'https://storage.googleapis.com/heallocontent/app/img/testimonials/csjourney_5.mp4',
    'https://storage.googleapis.com/heallocontent/app/img/testimonials/csjourney_6.mp4',
    'https://storage.googleapis.com/heallocontent/app/img/testimonials/csjourney_7.mp4',

  ];
  user: any;
  isPlaying: boolean = false;
  isiOSDevice: boolean = false;
  videoTitles: string[];
  @Input('language') language: string = this.appConfig.Shared.Languages.EN;

  constructor(
    // eslint-disable-next-line new-cap
    @Inject(DOCUMENT) private document: Document,
    private conn: ConnectionService,
    private appWebBridge: AppWebBridgeService,
    private windowRef: WindowRefService,
    private appConfig: AppConfig) {
  }

  @HostListener('document:fullscreenchange')
  onFullscreenChange(): void {
    if (!this.document.fullscreenElement) {
      this.closeVideo();
    }
  }

  ngOnInit(): void {
    this.user = this.conn.getActingUser();
    this.isiOSDevice = this.appWebBridge.requestOSInformation() === 'iOS' || this.windowRef.isSafariBrowser();
    if (this.language === this.appConfig.Shared.Languages.ML || this.language === this.appConfig.Shared.Languages.BN) {
      this.language = this.appConfig.Shared.Languages.EN;
    }
    this.videoTitles = TestimonialVideoTitles[this.language];
  }

  onPlayButtonClick(videoUrl: string): void {
    if (this.videoPlayer && this.videoPlayer.nativeElement) {
      const videoPlayer = this.videoPlayer.nativeElement;
      videoPlayer.parentElement.classList.remove('tw-hidden');
      videoPlayer.src = videoUrl;
      const videoPlay = videoPlayer.play();
      if (videoPlay && !this.isPlaying) {
        videoPlay.then((): void => {
          this.isPlaying = true;
        });
      }
    }
  }

  videoEnd(): void {
    this.videoPlayer.nativeElement.pause();
    this.videoPlayer.nativeElement.currentTime = 0;
    this.videoPlayer.nativeElement.parentElement.classList.add('tw-hidden');
    this.isPlaying = false;
  }

  closeVideo(): void {
    if (!this.isPlaying) return;
    this.videoPlayer.nativeElement.pause();
    this.videoPlayer.nativeElement.currentTime = 0;
    this.videoPlayer.nativeElement.parentElement.classList.add('tw-hidden');
    this.isPlaying = false;
  }
}
