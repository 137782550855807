<div class="font-noto tw-items-center tw-relative tw-px-7 tw-py-5">
  <div class="top-line"></div>
  <div class="tw-absolute tw-p-1 tw-rounded-full tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-[15px] tw-right-[10px] close-sheet" style="width: 35px; height: 35px" (click)="closeBottomSheet()">
    <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
  </div>
  <header class="bg-white">
    <div class="title tw-font-body" i18n="@@codProcessingFee">
      Pay COD Processing Fees Now
    </div>
  </header>
  <div class="content bg-white">
    <div class="tw-font-body" i18n="@@toConfirmYourCod">
      To confirm your order for COD (Cash On Delivery), pay ₹{{codFee}}/- online
      and give the remaining amount on delivery
    </div>
    <div class="price-breakdown">
      <div class="bold tw-text-400">
        <div class="dib clr-green-new tw-font-body" i18n="@@onlinePaymentNow">
          Online payment now
        </div>
        <div class="clr-green-new tw-font-body">₹{{codFee}}/-</div>
      </div>
      <div class="mT20 tw-text-400" *ngIf="isCCODChargeApplicable">
        <div class="tw-font-body" i18n="@@cashOnDelivery">
          Pay cash on delivery
        </div>
        <div class="tw-font-body">₹{{orderTotal - codCharge}}/-</div>
      </div>
      <div class="mT20 tw-text-400" *ngIf="!isCCODChargeApplicable">
        <div class="dib tw-font-body" i18n='@@codPaymentOnDelivery'>
          Pay on delivery
        </div>
        <div class="tw-font-body">₹{{orderTotal - codFee}}/-</div>
      </div>
      <div class="mT20 tw-text-400 tw-font-bold" *ngIf="isCCODChargeApplicable">
        <div class="dib tw-font-body" i18n="@@totalOrderAmount">
          Total order amount
        </div>
        <div class="tw-font-body">₹{{orderTotal + codCharge + discount}}/-</div>
      </div>
      <div class="mT20 tw-text-400 tw-font-bold" *ngIf="!isCCODChargeApplicable">
        <div class="dib tw-font-body" i18n="@@totalOrderAmount">
          Total order amount
        </div>
        <div class="tw-font-body">₹{{orderTotal}}/-</div>
      </div>
    </div>
    <cs-button id="payButton" [class]="'primary w100 new-bg'" (callback)="handleClick()"><cs-button-text class="tw-font-body" i18n="@@payFeeOnline">Pay ₹{{codFee}} Online</cs-button-text></cs-button>
    <div class="mT20 fontS3 text-center tw-font-body tw-mb-3" style="color: #d66736" i18n="@@orderPlacedAndNonRefundable">
      Your order will be placed as soon as ₹{{codFee}}/- is paid online. This
      processing fees is non-refundable.
    </div>
  </div>
</div>