<div class="tw-px-4 tw-pb-6" *ngIf="showBanner">
  <div class="tw-h-[192px] tw-flex tw-flex-col tw-w-full tw-bg-white tw-px-4 tw-pt-4 tw-pb-2 tw-rounded-3xl tw-shadow-z1" style="background-image: url('/assets/images/white_cross_bg.png'); background-size: 50% 100%; background-position: right; background-repeat: no-repeat;">
    <div class="tw-flex tw-flex-row">
        <!-- Left half -->
        <div class="tw-flex-1" >
          <div class="tw-flex tw-flex-col tw-top-0">
            <div class="-tw-mt-4">
              <div i18n="@@200Off" class="tw-text-center tw-w-[95px] tw-p1 tw-rounded-br-[1px] tw-rounded-bl-[1px] tw-border-x-[1px] tw-border-b-[1px] tw-border-dashed tw-border-teal-400  tw-bg-teal-200 tw-p-1 tw-text-teal-600">
                ₹200 OFF!
            </div>
            </div>
            <div class="tw-pt-2">
              <h1 class="tw-font-bold tw-text-[20px] tw-font-bold" i18n="@@hairThinningTitle">Hair thinning</h1>
              <p class="tw-text-gray-600" i18n="@@hairThinningSubTitle">is common during pregnancy.</p>
            </div>
          </div>
        </div>
        <!-- Right half content, if any -->
        <div class="tw-flex-2 tw-p-2 tw-mr-auto" >
          <img class="tw-justify-end tw-w-[116px] tw-h-[95px]" src="https://storage.googleapis.com/heallo-test/article_image/1723618764_17ef841018c64d0c8d51547a6bb0c130.png"/>
        </div>
      </div>
      <div (click)="handleClick()" class="tw-mt-auto tw-flex tw-items-end tw-justify-end">
        <button i18n="@@takeHairConsultationNow" class="tw-h-10 tw-bottom-0 tw-w-full tw-rounded-3 tw-bg-teal-600 tw-p-4 tw-text-white tw-text-300">Take hair consultation now</button>
       </div>
    </div>
</div>