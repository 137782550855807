<div class="bg-f4 pB5">
  <div class="bg-regimen-grad">
  <div class="intro">
    <div class="fontL4" *ngIf="patientName">
    <span class="pR5" i18n="@@hello">Hello</span><span>{{patientName}}!</span>
    </div>
    <div class="regimen-hint" *ngIf="!alternateRegimen">
    <div class="bold mB10" i18n="@@yourSkinWilGetTheCare">
      Your {{regimenType}} will get the care and attention from our
      dermatologist.
    </div>
    <ul>
      <li i18n="@@placeYourOrderOfKit">Place your order for the kit.</li>
      <li i18n="@@AfterThatDoctorWillCall">
      After that, our doctor will call you to know about your
      {{regimenType}} and prescribe right products
      </li>
      <li i18n="@@ProductsWillBePrescribed">
      Products will be prescribed and home-delivered at no additional
      charges.
      </li>
    </ul>
    </div>
    <div class="p10" *ngIf="alternateRegimen" i18n="@@weHavePreparedTreatmentAlt">
    We’ve prepared a treatment kit for you, however your {{regimenType}}
    concerns needs special attention of our dermatologist. Once you pay for
    the {{regimenType}} treatment kit, our doctor will review your details
    and photos to prepare the treatment kit and deliver the treatment at
    your doorstep post their approval.
    </div>
  </div>
  <div class="regimenImage" *ngIf="regimen.regimenCoverImage">
    <img class="w80" [src]="regimen.regimenCoverImage" />
  </div>
  <div class="content">
    <div class="serviceCard">
    <div class="flex pTB5">
      <div class="dib dot"></div>
      <div class="dib pL15 w90">
      <span *ngIf="!alternateRegimen" i18n="@@allProductsByDoctorBasedOnPhoto">All products given by doctors based on photos</span><span *ngIf="alternateRegimen" i18n="@@allProductsByDoctor">All products recommended by dermatologist</span>
      </div>
    </div>
    <div class="flex pTB5">
      <div class="dib dot"></div>
      <div class="dib pL15 w90">
      <span *ngIf="!alternateRegimen" i18n="@@ReviewCallWithDermo">Review call with dermatologist and check-ups while you are using
        those products.</span><span *ngIf="alternateRegimen" i18n="@@ReviewByDermo">Review of your skin from our doctor to prepare the kit and
        check-ups while you are using those products.</span>
      </div>
    </div>
    <div class="flex pTB5">
      <div class="dib dot"></div>
      <div class="dib pL15 w90">
      <span i18n="@@chatSupportForHelp">Chat support through out your treatment for instant help</span>
      </div>
    </div>
    </div>
    <div class="serviceCard" #serviceCardTemplate="" (click)="expandServices(serviceCardTemplate)">
    <div class="heading fontL3">
      <em class="cs-icon-tick"></em><span class="pL15" i18n="@@treatmentService">Treatment Services Included</span>
    </div>
    <div class="line"></div>
    <div class="flex pTB5">
      <div class="dib dot"></div>
      <div class="dib pL15 w90">
      <span i18n="@@dermatologistVerificationAndApprovalParaTreatment">Doctor check-up of photos and details with treatment kit
        prescription</span>
      </div>
    </div>
    <div class="flex pTB5">
      <div class="dib dot"></div>
      <div class="dib pL15 w90">
      <span i18n="@@threeCheckupsByOurDermatologist">Monthly check-up with improvement tracking</span>
      </div>
    </div>
    <div class="flex pTB5">
      <div class="dib dot"></div>
      <div class="dib pL15 w90">
      <span i18n="@@chatInAppAnytimeForYourQuestions.">Detailed instructions, guidance and 24x7 chat support</span>
      </div>
    </div>
    <div class="flex pTB5">
      <div class="dib dot"></div>
      <div class="dib pL15 w90">
      <span i18n="@@dietAndLifestyleGuidanceForClearerSkin">Diet and Lifestyle guidance</span>
      </div>
    </div>
    <div class="flex pTB5">
      <div class="dib dot"></div>
      <div class="dib pL15 w90">
      <span i18n="@@improvementGuarantee">The CureSkin Improvement Guarantee</span>
      </div>
    </div>
    <div class="line"></div>
    <div class="readMore" *ngIf="!showServiceDetail" i18n="@@readMore">Read More</div>
    <div class="serviceDetail" *ngIf="showServiceDetail">
      <div class="service-head" i18n="@@service-2">
      1. Doctor's approval within 24 hours
      </div>
      <div class="service-info" i18n="@@service-2-detail">
      Your doctor will personally review your photos and case details.
      </div>
      <div class="service-head" i18n="@@service-3">
      2. Regular follow ups for 12-weeks
      </div>
      <div class="service-info" i18n="@@service-3-detail">
      Your doctor will review your case on regular intervals to monitor
      your improvement. These check-ups are absolutely FREE!
      </div>
      <div class="service-head" *ngIf="regimen.class !== appConfig.Shared.Regimen.Class.HAIR" i18n="@@service-5-skin">
      3. 24*7 chat with your personal advisor
      </div>
      <div class="service-info" *ngIf="regimen.class !== appConfig.Shared.Regimen.Class.HAIR" i18n="@@service-5-detail-skin">
      Advice on taking care of your skin in the sun or doubts on how to
      apply the products? We are always available to help.
      </div>
      <div class="service-head" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@service-5-hair">
      3. 24*7 chat with your personal advisor
      </div>
      <div class="service-info" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@service-5-detail-hair">
      Worried about increased hair fall or doubts on how to apply? We are
      always available to help.
      </div>
      <div class="service-head" *ngIf="regimen.class !== appConfig.Shared.Regimen.Class.HAIR" i18n="@@service-4-skin">
      4. Diet and lifestyle guidance
      </div>
      <div class="service-info" *ngIf="regimen.class !== appConfig.Shared.Regimen.Class.HAIR" i18n="@@service-4-detail-skin">
      In addition to the right products, it is also important to make some
      diet and lifestyle changes.
      </div>
      <div class="service-head" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@service-4-hair">
      4. Diet and lifestyle guidance
      </div>
      <div class="service-info" *ngIf="regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@service-4-detail-hair">
      In addition to the right products, it is also important to make some
      diet and lifestyle changes.
      </div>
      <div class="service-head" i18n="@@service-6">
      5. CureSkin Improvement Guarantee
      </div>
      <div class="service-info pB5" i18n="@@service-6-detailTreatment">
      If there is no improvement after using the treatment kit (includes
      completing all check-ups) correctly for 3 months, you get your money
      back!
      </div>
    </div>
    <div class="line" *ngIf="showServiceDetail"></div>
    <div class="readMore" *ngIf="showServiceDetail" i18n="@@readLess">Read Less</div>
    </div>
  </div>
  </div>
  <div *ngIf="regimen && !regimen.active">
  <div class="doctorCard card" (click)="openDoctorProfile()">
    <div class="text">
    <div class="bold fontL2" *ngIf="allocatedDoctor">
      {{allocatedDoctor.get('DoctorDisplayName')}}
    </div>
    <div class="bold fontL2" *ngIf="!allocatedDoctor">Dr. Charu Sharma</div>
    <div class="fontS1 mT5" i18n="@@dermatologist">Dermatologist</div>
    <div class="readMore" *ngIf="allocatedDoctor" i18n="@@readMore">Read More</div>
    </div>
    <div class="image">
    <img *ngIf="allocatedDoctor" [src]='allocatedDoctor.get("DoctorImageThumbnail")' /><img *ngIf="!allocatedDoctor" src="https://cdn.cureskin.com/app/img/doctor-charu.png" />
    </div>
  </div>
  <img class="db w100" *ngFor="let banner of banners" [src]="banner.image" [ngClass]='{ "mB10": banners.length > 1 }' />
  <div class="testimonialCard card" (click)="openTestimonial()">
    <div class="image">
    <img src="https://cdn.cureskin.com/other/testimonial-four.jpg" />
    </div>
    <div class="text">
    <img src="https://cdn.cureskin.com/app/img/quotes.png" />
    <div class="fontL1 bold" i18n="@@whatUserSays">
      What Our Customers Say
    </div>
    <div class="readMore" i18n="@@seeTestimonials">See Testimonials</div>
    </div>
  </div>
  <div class="socialProofCard card" #socialProofCard="" *ngIf="socialProof.length">
    <div class="fontS2" [innerHTML]="socialProofContent"></div>
  </div>
  <div class="reanswer-tree" *ngIf="!regimen.orderPlaced" (click)="startAgain()">
    <div class="fontS2" i18n="@@forgotToShare">
    Forgot to share some important information?
    </div>
    <div class="fontS2" i18n="@@neverLate">
    It's never too late! Simply re-answer the questions.
    </div>
    <hr />
    <div class="fontS2 bolder" i18n="@@changeAnswers">
    + Click Here to Change the answers
    </div>
  </div>
  </div>
</div>