import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'app-product-controller',
  templateUrl: './product-controller.component.html',
})
export class ProductControllerComponent implements OnInit {
  user: any;
  experiments: any[];
  product: any;
  article: any;
  cart: any;
  numberOfProductsInCart: number;
  productInCart: number;
  loading: boolean = true;
  isAddRemovePending: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public appConfig: AppConfig,
    private conn: ConnectionService,
    private broadcast: BroadcastService,
    private eventLogger: EventLoggerService,
  ) { }

  async ngOnInit(): Promise<void> {
    const productId = this.route.snapshot.params.id;
    this.user = this.conn.getActingUser();
    if (!this.user) return;
    this.experiments = await this.conn.findUserActiveExperiments(this.user.get('username'));
    const [product]: any[] = await this.conn.findCatalogWithKey(productId);
    const articleExperiment = this.experiments.find((item:any):any => item.name === 'complex_article');
    if (product?.get('articleWithTag') && articleExperiment?.variant.productIds?.includes(productId)) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({
        name: 'product-complex-article-page-visit',
        value: productId,
      }));
      const articleId = (<{ id: string }>Object.values(product.get('articleWithTag'))[0]).id;
      this.article = await this.conn.getArticleById(articleId, { translate: true });
    // } else if (product?.get('articleWithTag') &&
    // this.user?.get('marketingTags').includes(Object.keys(product.get('articleWithTag'))[0])) {
    // const articleId = (<{ id: string }>Object.values(product?.get('articleWithTag'))[0]).id;
    // this.article = await this.conn.getArticleById(articleId, { translate: true });
    // }
    } else if (product?.get('articleId') || (product?.get('article') && product.get('article')?.id)) {
      const articleId = product?.get('articleId') || product.get('article')?.id;
      this.article = await this.conn.getArticleById(articleId, { translate: true });
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'product-article-page-visit',
        value: productId }));
    }
    this.product = JSON.parse(JSON.stringify(product));

    // Remove article if it's not available for user langage.
    const isTranslationAvailalbeForArticle = this.article?.get('params')?.articleImagesLanguageString?.map(
      (languageString: any): string => languageString.get(this.user.get('languagePreference') || this.appConfig.Shared.Languages.EN))
      .every((str: string): boolean => !!str);
    if (!isTranslationAvailalbeForArticle) this.article = null;

    this.getCart();
    this.loading = false;
  }

  async getCart(): Promise<void> {
    this.numberOfProductsInCart = 0;
    this.cart = await this.conn.getCart();
    this.cart.get('lineItems')?.forEach((element: any): void => this.numberOfProductsInCart += element.quantity);
    this.productInCart = this.cart.get('lineItems').find((element: any): any => element.productId === this.product.objectId);
    this.isAddRemovePending = false;
  }

  async addToCart(productId: string): Promise<void> {
    const sectionName = this.route.snapshot.queryParams?.section;
    const productObjectInCart = this.cart?.get('lineItems')?.find((each: any): any => each.productId === productId);

    if (productObjectInCart && this.product?.prescriptionRequired && productObjectInCart.quantity >= 3) {
      this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 3 per product' });
      return;
    }

    if (productObjectInCart && !this.product?.prescriptionRequired && productObjectInCart.quantity >= 5) {
      this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 5 per product' });
      return;
    }

    this.isAddRemovePending = true;
    await this.conn.addProductToCart({ productId, quantity: 1, section: sectionName });
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'product-description-checkout' }));

    this.getCart();
  }

  async removeFromCart(productId: string): Promise<any> {
    this.isAddRemovePending = true;
    const quantity = this.cart.get('lineItems').find((product: any): any => product.productId === productId)?.quantity;
    if (!quantity || quantity <= 0) return;

    const params = {
      productId,
      quantity,
    };
    await this.conn.removeProductFromCart(params);
    this.getCart();
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
