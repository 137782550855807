import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { ProductListComponent } from './product-list.component';

@NgModule({ imports: [CommonModule,
  CsIconModule,
  ButtonModule,
],
declarations: [ProductListComponent],
exports: [ProductListComponent] })
export class ProductListModule {}
