import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2 } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'regimen-concern-selection',
  templateUrl: './regimen-concern-selection.html',
})

export class RegimenConcernSelectionComponent implements OnInit {
  @Input('class') set setConcernClass(concernClass: string) {
    this.selectedConcernClass = concernClass;
  }
  @Input('gender') gender: string = 'female';
  @Output('onSelect') onSelect: EventEmitter<string> = new EventEmitter<string>();
  selectedConcernClass: string;
  faceIconUrl: string = 'https://cdn.cureskin.com/app/img/female-face-new.png';
  bodyIconUrl: string = 'https://cdn.cureskin.com/app/img/female-body.png';
  hairIconUrl: string = 'https://cdn.cureskin.com/app/img/female-hair-new.png';

  constructor(public appConfig: AppConfig,
    private renderer: Renderer2) { }

  async ngOnInit(): Promise<void> {
    this.faceIconUrl = 'https://cdn.cureskin.com/app/img/skin_v1.png';
    this.hairIconUrl = 'https://cdn.cureskin.com/app/img/hair_v1.png';
    if (this.gender.toLocaleLowerCase() === 'male') {
      this.setIconUrls();
    }
  }

  onSelectConcern(concernClass: string): void {
    this.selectedConcernClass = concernClass;
    this.onSelect.emit(this.selectedConcernClass);
  }

  setIconUrls(): void {
    this.faceIconUrl = 'https://cdn.cureskin.com/app/img/male_skin_v1.png';
    this.hairIconUrl = 'https://cdn.cureskin.com/app/img/male_hair_v1.png';
  }
}
