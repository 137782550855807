import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoadingModule } from '@components/loading';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { DiscountTimerModule } from '@components/discount-timer/discount-timer.module';
import { AddressFormModule } from '@shared/address-form/address-form.module';
import { RouterModule } from '@angular/router';
import { UserCheckoutComponent } from './user-checkout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: 'checkout', component: UserCheckoutComponent },
    ]),
    CsIconModule,
    FormsModule,
    HttpClientModule,
    AddressFormModule,
    ButtonModule,
    ToolbarModule,
    CustomPipeModule,
    LoadingModule,
    DiscountTimerModule,
  ],
  declarations: [UserCheckoutComponent],
  exports: [],
})
export class UserCheckoutModule { }
