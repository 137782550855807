import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { TimeService } from '@services/time-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { AppConfig } from '../../app.config';

@Component({ selector: 'user-profile', templateUrl: './user-profile.html' })
export class UserProfileComponent {
  user: any;
  appVersion: string;
  androidVersion: string;
  isInternalUser: boolean;
  ui:any = { popUpModal: { open: false } };
  experiments: Array<any> = [];
  @Output('onPopupToggle') onPopupToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  showRefundPolicyOptions: boolean = false;
  isRebrandingV1Burger: boolean = false;

  constructor(private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private timeService: TimeService,
    private broadcast: BroadcastService,
    private appConfig: AppConfig,
    private eventLogger: EventLoggerService,
    private appWebBridge: AppWebBridgeService) {
  }

  async ngOnInit(): Promise<void> {
    this.isInternalUser = this.conn.isInternalUser();
    this.user = this.conn.getActingUser();
    this.appVersion = `V ${this.appConfig.Shared.AppVersion}`;
    this.experiments = await this.conn.findUserActiveExperiments();
    const experiment = this.experiments.find((experiment: any): any => experiment.key === 'rebranding_v1_burger');
    if (experiment) {
      this.isRebrandingV1Burger = true;
    }
    this.androidVersion = this.appWebBridge?.requestAppVersion() ? this.appWebBridge?.requestAppVersion()?.toString() : '';
  }

  navigateTo(url: string, params: any = {}): void {
    if (url === '/logout') {
      if (this.isInternalUser) return;
      this.onPopupToggle.emit(true);
      this.ui.popUpModal = { message: { type: this.appConfig.Shared.String.LOGOUT_CONFIRM },
        open: true,
        title: this.appConfig.Shared.String.LOGOUT,
        okText: this.appConfig.Shared.String.YES,
        cancelText: this.appConfig.Shared.String.NO,
        type: this.appConfig.Dialog.CONFIRMATION };
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'logout' }));
    } else this.router.navigate([url], { queryParams: params });
  }

  closePopUp(event: any): any {
    this.ui.popUpModal = { open: false };
    if (event.clickOnYes) {
      setTimeout((): Promise<any> => this.router.navigate(['/logout'], { replaceUrl: true }), 500);
    }
    this.onPopupToggle.emit(false);
  }

  back(): void {
    this.showRefundPolicyOptions = false;
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  stopPropagation(event: any): void {
    event.stopPropagation();
  }

  redirectTo(url: string): void {
    this.conn.navigateToURL(url);
  }
}
