<div class="card-holder dt anim-group font-noto newUI">
  <ng-container *ngFor="let item of products">
    <div class="card anim-fadeIn tw-bg-transparent" *ngIf="item.price !== 0" (click)="viewProduct(item)">
      <img class="tw-block tw-border tw-border-white tw-border-b-0" #img="" [src]="item?.rebrandedImageWithBackground?.[0] || item?.productUnboxedImage?.[0] || item?.images?.[0]" align="middle" />
      <div class="detail tw-bg-white tw-pt-3 tw-pb-4">
        <div class="name font12 tw-mt-0">{{item.title.substr(0, 25)}}</div>
        <del class="font14 fw-700 pR5">₹{{item.mrp}}</del>
        <pan class="clr-orange fw-700">₹{{item.price}}</pan>
        <div class="product-action" *ngIf="item?.quantityCart && item?.quantityCart !== 0">
          <em class="cs-icon-minus green" (click)='addOrRemoveFromCart(item, "REMOVE", $event, img)'></em>
          <div class="count green" *ngIf="!item?.prescriptionRequired">
            {{item?.quantityCart > nonRxLimit ? nonRxLimit :
      item?.quantityCart}}
          </div>
          <div class="count green" *ngIf="item?.prescriptionRequired">
            {{item?.quantityCart > rxLimit ? rxLimit : item?.quantityCart}}
          </div>
          <em class="cs-icon-plus green" (click)='addOrRemoveFromCart(item, "ADD", $event, img)'></em>
        </div>
        <div class="buy font12 tw-tracking-normal" *ngIf="(!item?.quantityCart || item?.quantityCart === 0 )" (click)='addOrRemoveFromCart(item, "ADD", $event, img)'>
          <span i18n="@@addToCart">Add to Cart</span><em class="cs-icon-arrow"></em>
        </div>
      </div>
    </div>
  </ng-container>
</div>