import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BeforeBottomViewDirective } from '@directives/before-bottom-view/before-bottom-view.directive';
import { DoubleClickDirective } from './double-click/double-click.directive';
import { SwipeListenerDirective } from './swipe-listener/swipe-listener.directive';
import { RippleEffectDirective } from './ripple-effect/ripple-effect.directive';
import { LoadingEffectDirective } from './loading-effect/loading-effect.directive';
import { PinchZoomDirective } from './imagePinchZoom/pinch-zoom.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    DoubleClickDirective,
    SwipeListenerDirective,
    RippleEffectDirective,
    LoadingEffectDirective,
    BeforeBottomViewDirective,
    PinchZoomDirective,
  ],
  exports: [
    DoubleClickDirective,
    SwipeListenerDirective,
    RippleEffectDirective,
    LoadingEffectDirective,
    BeforeBottomViewDirective,
    PinchZoomDirective,
  ],
})

export class DirectivesModule {}
