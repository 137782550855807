import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PopUpComponent } from './pop-up.component';
import { ButtonModule } from '../button';
import { TranslateStringsModule } from '../translate-string';

@NgModule({
  imports: [CommonModule, RouterModule, ButtonModule, TranslateStringsModule],
  declarations: [PopUpComponent],
  exports: [PopUpComponent],
})
export class PopUpModule {}
