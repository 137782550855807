<!-- New UI-->
<div class="tw-flex tw-flex-col tw-bg-gray-100 tw-h-full">
  <div class="tw-fixed tw-left-6 tw-top-3 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
  </div>
  <loading class="tw-flex-1 tw-self-center tw-justify-self-center" *ngIf="loading"></loading>
  <section class="tw-flex-1 tw-overflow-y-auto tw-pt-12 tw-bg-white" *ngIf="!loading">
    <doctor-profile class="tw-h-full" [doctor]="allocatedDoctor"></doctor-profile>
  </section>
  <footer class="tw-flex-none" *ngIf="!loading && !regimen.active && !regimen.orderPlaced">
    <buy-now-footer [regimen]="regimen" (buyNow)="buyRegimen()"></buy-now-footer>
  </footer>
</div>