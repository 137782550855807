import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'incomplete-chat-sheet',
  templateUrl: './incomplete-chat-sheet.html',
  styleUrls: [],
})
export class IncompleteChatSheetComponent implements OnInit {
  /**
     * The constructor injects the data passed to the bottom sheet using Angular's dependency injection.
     * The 'new-cap' linting rule is disabled for this line because it doesn't apply in this case,
     * as the uppercase letter is part of the Angular-specific token MAT_BOTTOM_SHEET_DATA.
   */
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  public appConfig: AppConfig,
  private bottomSheetRef: MatBottomSheetRef) {}
  regimenClass: string = '';
  ngOnInit(): void {
    this.regimenClass = this.data.regimenClass;
  }

  async handleClick(): Promise<void> {
    if (this.data?.callback) this.data.callback();
    this.closeBottomSheet();
  }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }
}
