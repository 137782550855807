import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { DirectivesModule } from '@directives/directives.module';
import { AudioComponent } from './audio.component';

@NgModule({
  imports: [CommonModule, LoadingModule, DirectivesModule, ButtonModule, CustomPipeModule],
  declarations: [AudioComponent],
  exports: [AudioComponent],
})
export class AudioModule {}
