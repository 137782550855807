import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { Router } from '@angular/router';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { RequestQueryPayload } from 'api-client';
import { LocalStorageService } from '@services/local-storage-service';
import { EventLoggerService } from '@services/event-logger-service';
import { TimeService } from '@services/time-service';
import { AppConfig } from '../../../../app.config';
import { IncompleteInformationComponent } from '../incomplete-information-banner/incomplete-information.component';

@Component({
  selector: 'cross-regimen-banner',
  templateUrl: './cross-regimen-banner.html',
  styleUrls: ['./cross-regimen-banner.scss'],
})
export class CrossRegimenBannerComponent implements OnInit {
  @Input('incompleteChat') incompleteChat: boolean;
  @ViewChild(IncompleteInformationComponent) incompleteInformationComponent: IncompleteInformationComponent;
  user: any;
  imageUrl: any;
  hairRegimen: boolean = false;
  faceRegimen: boolean = false;
  regimenClass: any;
  userGender: any;
  regimenActive: boolean;
  crossSellRegimen: boolean = false;
  experiments: any = [];
  regimenData: any;
  regimentMRP: any;
  bannerStyle: any = {};
  faceRegimenActive: boolean = false;
  hairRegimenActive: boolean = false;
  latestUnBoughtRegimen: any = {};
  isRegimenBeingPrepared: boolean;
  showUnBoughtRegimen: boolean = false;
  regimens: any;
  consultationSession: any;
  userStatus: any = { PAID: false, GOT_REGIMEN: false, NO_PHOTO: false };
  validTillDate: any;
  userActiveExperiments: any = [];
  isHairThinningCrossSellBanner: boolean = false;
  isHairFallCrossSellBanner: boolean = false;
  isHairFallCrossSellMaleBanner: boolean = false;
  isHairCrossSellBanner: boolean = false;
  isHairDandruffCrossSellBanner: boolean = false;
  isNewCrossRegimenBannerActive: boolean = false;

  constructor(
    public appConfig: AppConfig,
    private connectionService: ConnectionService,
    private conn: ConnectionService,
    private commonUtil: CommonUtilityHelper,
    private localStorage: LocalStorageService,
    private eventLogger: EventLoggerService,
    private timeService: TimeService,
    private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    this.user = this.connectionService.getActingUser();
    await this.fetchUserActiveExperiments();
    this.userGender = this.user.get('Gender');
    this.findUserStatus();
    this.fetchCrossSellBanner();

    const experiment = await this.connectionService.fetchExperimentByKey('cross_sell_regimen');
    this.experiments = JSON.parse(JSON.stringify(experiment));
    this.regimenData = JSON.parse(this.experiments.variant);
  }

  private async fetchUserActiveExperiments(): Promise<void> {
    this.userActiveExperiments = await this.conn.findUserActiveExperiments();
    this.userActiveExperiments.forEach(async (experiment: any): Promise<void> => {
      switch (experiment.key) {
        case 'hair_regimen_cross_sell_banner':
          this.isHairCrossSellBanner = true;
          this.isNewCrossRegimenBannerActive = true;
          break;
        case 'hair_thinning_cross_sell_banner':
          this.isHairThinningCrossSellBanner = true;
          this.isNewCrossRegimenBannerActive = true;
          break;
        case 'hair_fall_cross_sell_banner':
          this.isHairFallCrossSellBanner = true;
          this.isNewCrossRegimenBannerActive = true;
          break;
        case 'hair_fall_male_cross_sell_banner':
          this.isHairFallCrossSellMaleBanner = true;
          this.isNewCrossRegimenBannerActive = true;
          break;
        case 'hair_dandruff_cross_sell_banner':
          this.isHairDandruffCrossSellBanner = true;
          this.isNewCrossRegimenBannerActive = true;
          break;
        default:
          break;
      }
    });
  }

  async fetchCrossSellBanner(): Promise<void> {
    const experiment = await this.conn.fetchExperimentByKey('cross_sell_regimen');
    this.experiments = JSON.parse(JSON.stringify(experiment));
    const startEndDay = JSON.parse(this.experiments.variant);
    const where: any = { active: true, forUser: this.user };
    const lessThan = this.timeService.getEndOfDay();
    const lessThanDate = this.timeService.removeDays(lessThan, startEndDay.startDay);
    const greatThan = this.timeService.getEndOfDay();
    const greatThanDate = this.timeService.removeDays(greatThan, startEndDay.endDay);
    where.activeFromDate = {
      $lt: lessThanDate,
      $gt: greatThanDate,
    };
    const latestActiveRegimenForUser = await this.conn.findRegimenByDate({ where });
    if (latestActiveRegimenForUser) {
      const activeDate = latestActiveRegimenForUser.get('activeFromDate');
      const startDate = this.timeService.addDays(activeDate, 1);
      this.validTillDate = this.timeService.addDays(startDate, startEndDay.endDay - 1);
      this.localStorage.setValue('validTillDate', this.validTillDate);
      const expired: boolean = true;
      this.regimens = await this.conn.fetchRegimens(null, null, null, expired);
      this.findUserStatus();
      // Filter out the BODY class from regimen, since it is not needed
      this.regimens = this.regimens.filter((regimen: any): boolean => regimen.class !== this.appConfig.Shared.Regimen.Class.BODY);
      this.regimens.forEach((element: any):void => {
        if (element.class === this.appConfig.Shared.Regimen.Class.FACE && element.active && element.delivered) {
          this.faceRegimenActive = true;
        }
        if (element.class === this.appConfig.Shared.Regimen.Class.HAIR && element.active && element.delivered) {
          this.hairRegimenActive = true;
        }
      });
      let bothRegimen: any;
      let noRegimen: any;
      let oneRegimen: any;
      let activeRegimen: any;
      let regimenOrderPlaced: boolean;
      if (this.regimens.length > 1) {
        bothRegimen = this.regimens?.every((x: any): any => (x.active && x.delivered));
        noRegimen = this.regimens?.every((x: any): any => !(x.active && x.delivered));
        regimenOrderPlaced = (this.regimens.findIndex((each: any): any => each.orderPlaced) >= 0);
      }
      if (this.regimens.length === 1 && this.regimens[0].active && this.regimens[0].active) {
        oneRegimen = true;
        activeRegimen = this.regimens[0];
      }
      if (this.latestUnBoughtRegimen && this.userStatus?.GOT_REGIMEN
        && this.latestUnBoughtRegimen?.fixedPrice !== startEndDay.discountedSP) {
        return;
      }
      if ((this.hairRegimenActive || this.faceRegimenActive) && (!(bothRegimen && noRegimen) || oneRegimen)) {
        if (this.experiments && !regimenOrderPlaced && !bothRegimen) {
          const today = new Date();
          const date = new Date(this.experiments.endDate.iso);
          if (today.getTime() <= date.getTime()) {
            this.crossSellRegimen = true;
            if (this.crossSellRegimen && this.regimens.length) {
              this.validTillDate = this.localStorage.getValue('validTillDate');
              this.regimens.forEach((element: any): void => {
                if (element.class === this.appConfig.Shared.Regimen.Class.FACE && element.active && element.delivered) {
                  this.faceRegimenActive = true;
                }
                if (element.class === this.appConfig.Shared.Regimen.Class.HAIR && element.active && element.delivered) {
                  this.hairRegimenActive = true;
                }
              });
              this.regimens.forEach((element: any): void => {
                switch (element.class) {
                  case this.appConfig.Shared.Regimen.Class.FACE:
                    this.regimentMRP = element.fixedPrice;
                    if (!element.active && !this.hairRegimenActive) {
                      this.faceRegimen = true;
                      this.regimenClass = element.class;
                    }
                    if (element.active && !this.hairRegimenActive) {
                      this.hairRegimen = true;
                      this.regimenClass = this.appConfig.Shared.Regimen.Class.HAIR;
                    }
                    break;
                  case this.appConfig.Shared.Regimen.Class.HAIR:
                    this.regimentMRP = element.fixedPrice;
                    if (!element.active && !this.faceRegimenActive) {
                      this.hairRegimen = true;
                      this.regimenClass = element.class;
                    }
                    if (element.active && !this.faceRegimenActive) {
                      this.faceRegimen = true;
                      this.regimenClass = this.appConfig.Shared.Regimen.Class.FACE;
                    }
                    break;
                  default:
                    break;
                }
              });
              this.getImage();
            }
          }
        }
      }
    } else {
      this.localStorage.delete('validTillDate');
    }
  }

  findUserStatus(): any {
    this.userStatus = { PAID: false, GOT_REGIMEN: false, NO_PHOTO: false };
    if (this.regimens && this.regimens.length) {
      this.latestUnBoughtRegimen = this.regimens.find((regimen: any): boolean => !(regimen.orderPlaced || regimen.delivered));
    }
    const regimenOrderPlaced: boolean = (this.regimens?.findIndex((each: any): any => each.orderPlaced || each.delivered) >= 0);
    if (this.latestUnBoughtRegimen) {
      this.isRegimenBeingPrepared = this.commonUtil.isRegimenBeingPrepared(this.latestUnBoughtRegimen);
    }
    if (this.user.isPaid() || regimenOrderPlaced) this.userStatus.PAID = true;
    if (this.latestUnBoughtRegimen && !this.isRegimenBeingPrepared) {
      this.showUnBoughtRegimen = true;
      this.userStatus.GOT_REGIMEN = true;
    }
    if (this.latestUnBoughtRegimen) {
      const isEmpty = Object.keys(this.latestUnBoughtRegimen).length === 0;
      if (!isEmpty && !this.isRegimenBeingPrepared) this.showUnBoughtRegimen = true;
    }
  }

  getImage(): void {
    switch (true) {
      case this.hairRegimen && this.userGender === 'Male':
        this.bannerStyle.backgroundImage = 'https://cdn.cureskin.com/article_image/1679303555_0c467ca64cf248c6bea0d2501cf10696.gif';
        break;
      case this.hairRegimen && this.userGender === 'Female':
        this.bannerStyle.backgroundImage = 'https://cdn.cureskin.com/article_image/1679303432_db8c53591e9f4c6c946dd02d180849b5.gif';
        break;
      case this.faceRegimen && this.userGender === 'Male':
        this.bannerStyle.backgroundImage = 'https://cdn.cureskin.com/article_image/1679303689_82517d31bda34a10a4e10d1d62af6200.gif';
        break;
      case this.faceRegimen && this.userGender === 'Female':
        this.bannerStyle.backgroundImage = 'https://cdn.cureskin.com/article_image/1679303645_652017894e674fb3a3dbcba4760b8dfe.gif';
        break;
      default:
        break;
    }
  }

  navigateTo(url: string = ''): void {
    if (!url) return;
    const queryParams: Record<string, any> = {};
    const [URL, qParamString]: string[] = url.split('?');
    if (qParamString) {
      qParamString.split('&').forEach((each: string): void => {
        const [key, value]: string[] = each.split('=');
        queryParams[key] = value;
      });
    }
    this.router.navigate([URL], { queryParams });
  }

  async openConsultation(): Promise<void> {
    await this.incompleteInformationComponent.openConsultation();
  }

  async openBanner(): Promise<void> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'cross-sell-banner' }));
    const regimensClass = (await this.connectionService.fetchRegimens())
      .filter((each: any): boolean => !each.expired)
      .map((each: any): string => each.class);
    const payload: RequestQueryPayload<Table.ConsultationSession> = {
      where: {
        user: this.user,
        PrivateMainConcern: { $exists: true },
        PrivateMainConcernClass: { $nin: regimensClass },
        archive: false,
        regimen: { $exists: false },
      },
      descending: 'updatedAt',
    };
    const [consultationSession]: any = await this.connectionService.fetchConsultationSessions(payload);
    this.consultationSession = consultationSession;
    if (consultationSession || ((!this.isRegimenBeingPrepared && this.showUnBoughtRegimen))) {
      const route = `/user?tab=regimen&class=${this.regimenClass}`;
      if ((this.router.url === route || !consultationSession) && !this.showUnBoughtRegimen) {
        this.router.navigate(['/mainConcern'], { queryParams: { class: this.regimenClass, redirectOnBack: '/home' } });
      } else {
        this.navigateTo(`/user?tab=regimen&class=${this.regimenClass}`);
      }
    } else if (this.isRegimenBeingPrepared && !this.showUnBoughtRegimen && !consultationSession) {
      this.navigateTo(`/user?tab=regimen&class=${this.regimenClass}`);
    } else {
      this.router.navigate(['/mainConcern'], { queryParams: { class: this.regimenClass, redirectOnBack: '/home' } });
    }
  }
}
