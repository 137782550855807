<ng-container *ngIf="isRebrandingV1; then rebrandedTemplateV1 else originalTemplate"></ng-container>

<ng-template #originalTemplate>
  <!-- Original template -->
  <!--Contact us-->
  <section class="contact" id="contact" *ngIf="!userStatus.PAID">
    <div class="header">
      <div class="fw-700 font20" i18n="@@reachUsNew">Connect with us</div>
      <div class="desc"></div>
    </div>
    <div class="cardHolder">
      <div class="card"><a href="mailto:hello@cureskin.com?Subject={{user?.get(&quot;MobileNumber&quot;)}}, {{user?.get(&quot;PatientName&quot;)}}" (click)="handleSupport('mail')"><img class="mail svg-black" src="https://cdn.cureskin.com/app/img/mail.svg" />
          <div class="text">
            <div class="fontS3 clr-secondary" i18n="@@mailUsAtNew">Mail<span class="tw-lowercase"> us at</span></div>
            <div class="info">hello&#64;cureskin.com</div>
          </div>
        </a></div>
      <div class="card"><a href="tel:080-471-91820" (click)="handleSupport('phone')"><img class="call svg-black" src="https://cdn.cureskin.com/app/img/call.svg" />
          <div class="text">
            <div class="fontS3 clr-secondary" i18n="@@callUsAtNew">Call<span class="tw-lowercase"> us on</span></div>
            <div class="info">080-471-91820</div>
          </div>
        </a></div>
    </div>
  </section><!--Explore section-->
  <section *ngIf="!isExploreSectionExperimentOn" class="bdr-rad-15 explore" id="explore" (click)="handleExplore()">
    <div class="header pos-rel">
      <div class="title fw-700" i18n="@@explore">Explore</div><cs-icon class="pos-abs pos-btm-right w10 mR10 mB10" [iconName]="'click-icon'"></cs-icon>
      <div class="desc mB15 mT5 fw-500 clr-dark" i18n="@@skinHairVideos">See skin and hair related tips <br>& videos by experts</div>
    </div>
  </section>
</ng-template>

<ng-template #rebrandedTemplateV1>
  <!-- Rebranded template v1 -->
  <!--Explore section-->
  <div *ngIf="!isExploreSectionExperimentOn" class="tw-p-4 tw-mb-4 tw-bg-gray-50" id="explore" (click)="handleExplore()">
    <div class="header pos-rel">
      <div class="title fw-700" i18n="@@explore">Explore</div><cs-icon class="pos-abs pos-btm-right tw-w-8 tw-h-8"
        [iconName]="'click-icon'"></cs-icon>
      <div class="desc mB15 mT5 fw-500 clr-dark" i18n="@@skinHairVideos">See skin and hair related tips <br>& videos by
        experts</div>
    </div>
  </div>
  <!--Contact us-->
  <section class="contact tw-px-4 tw-pt-4 tw-pb-0 tw-m-0" id="contact" *ngIf="!userStatus.PAID">
    <div class="header">
      <div class="fw-700 font20" i18n="@@reachUsNew">Connect with us</div>
      <div class="desc"></div>
    </div>
    <div class="cardHolder tw-mt-4">
      <div class="card tw-p-4 tw-mr-2"><a
          href="mailto:hello@cureskin.com?Subject={{user?.get(&quot;MobileNumber&quot;)}}, {{user?.get(&quot;PatientName&quot;)}}"
          (click)="handleSupport('mail')"><img class="mail svg-black" src="https://cdn.cureskin.com/app/img/mail.svg" />
          <div class="text">
            <div class="fontS3 clr-secondary" i18n="@@mailUsAtNew">Mail<span class="tw-lowercase"> us at</span></div>
            <div class="info">hello&#64;cureskin.com</div>
          </div>
        </a></div>
      <div class="card tw-p-4"><a href="tel:080-471-91820" (click)="handleSupport('phone')"><img class="call svg-black"
            src="https://cdn.cureskin.com/app/img/call.svg" />
          <div class="text">
            <div class="fontS3 clr-secondary" i18n="@@callUsAtNew">Call<span class="tw-lowercase"> us on</span></div>
            <div class="info">080-471-91820</div>
          </div>
        </a></div>
    </div>
  </section>
</ng-template>