import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { DiscountTimerModule } from '@components/discount-timer/discount-timer.module';
import { VideoPlayerModule } from '@components/video-player/video-player.module';
import { TranslateStringsModule } from '@components/translate-string';
import { CsIconModule } from '@components/icon';
import { DoctorProfileModule } from '@shared/doctor-profile/doctor-profile.module';
import { SwiperModule } from 'swiper_angular';
import { ChoosePlanInfoComponent } from '@shared/choose-plan-info/choose-plan-info.component';
import { ChoosePlanInfoModule } from '@shared/choose-plan-info/choose-plan-info.module';
import { UserRegimenProductsViewComponent } from './user-regimen-products-view/user-regimen-products-view.component';
import { BuyNowFooterComponent } from './components/buy-now-footer/buy-now-footer.component';
import { UserRegimenDoctorComponent } from './user-regimen-doctor/user-regimen-doctor.component';
import { UserRegimenServicesComponent } from './user-regimen-services/user-regimen-services.component';
import { ProductItemComponent } from './components/product-item/product-item.component';

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild([
      {
        path: 'regimen',
        children: [
          { path: 'products', component: UserRegimenProductsViewComponent },
          { path: 'doctor', component: UserRegimenDoctorComponent },
          { path: 'services', component: UserRegimenServicesComponent },
          { path: 'plan-info', component: ChoosePlanInfoComponent },
        ],
      },
    ]),
    CommonModule,
    ChoosePlanInfoModule,
    CsIconModule,
    ToolbarModule,
    VideoPlayerModule,
    RouterModule,
    LoadingModule,
    ButtonModule,
    DiscountTimerModule,
    TranslateStringsModule,
    DoctorProfileModule,
    SwiperModule,
  ],
  declarations: [
    UserRegimenProductsViewComponent,
    UserRegimenDoctorComponent,
    UserRegimenServicesComponent,
    BuyNowFooterComponent,
    ProductItemComponent,
  ],
  exports: [BuyNowFooterComponent],
})

export class UserRegimenModule { }
