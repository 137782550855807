<!-- New ui-->
<div class="tw-block tw-bg-gray-100 tw-min-h-full" *ngIf="doctor">
  <section class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-px-6 tw-pb-5 tw-bg-gray-50 after:tw-hidden">
    <div class="tw-flex-1">
      <h1 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-900 tw-mb-1">
        {{doctor?.get('DoctorDisplayName')}}
      </h1>
      <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1">
        {{conn.getQualifyText(doctor)}}
      </h2>
      <p class="tw-font-body tw-font-bold tw-text-200 tw-text-gray-900" i18n="@@noOfPatientsTreated">
        Has treated {{doctor?.get('patientsTreated') || 0}}+<br />Cureskin
        patients
      </p>
    </div>
    <img class="tw-flex-none tw-block tw-h-28 tw-w-28 tw-bg-green-100 tw-rounded-full" *ngIf="doctor?.get('DoctorImageThumbnail')" [src]="doctor.get('DoctorImageThumbnail')" alt="Doctor's image" />
  </section>
  <ul class="tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-gap-4 tw-px-6 tw-pt-6 tw-pb-4" *ngIf="doctor.get('tags')?.length">
    <li class="tw-flex-none tw-flex tw-items-center tw-bg-orange-100 tw-rounded-lg tw-py-2 tw-px-2.5" *ngFor="let item of doctor.get('tags')">
      <span class="tw-font-body tw-font-bold tw-text-200 tw-text-orange-400">{{item}}</span>
    </li>
  </ul>
  <section class="tw-px-6 tw-bg-gray-100 tw-pt-1 after:tw-hidden">
    <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-900 tw-mb-1" i18n="@@aboutYourDoctor">
      Know your doctor
    </h2>
    <p class="tw-font-body tw-text-gray-500 tw-text-300 tw-mb-2">
      {{doctor.get('doctorProfessionalArea')}}
    </p>
  </section>
  <section class="tw-px-6 tw-pb-3 tw-bg-gray-100 after:tw-hidden">
    <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-900 tw-mb-1" i18n="@@qualification">
      Qualifications
    </h2>
    <p class="tw-font-body tw-text-gray-500 tw-text-300 tw-mb-1">
      {{doctor.get('doctorQualificationDetail')}}
    </p>
    <p class="tw-font-body tw-font-bold tw-text-200 tw-text-gray-800" *ngIf="doctor.get('mciNumber') && !doctor?.get('forDoctor')">
      Medical Council Reg Number - {{doctor.get('mciNumber')}}
    </p>
  </section>
</div>