/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service/index';
import { WindowRefService } from '@services/window-ref-service';
import { fromActions } from '@store/actions';
import { fromSelectors } from '@store/selectors';
import { AppConfig } from '../../app.config';

@Component({ selector: 'product-carousel', templateUrl: './product-carousel.html', styleUrls: ['./product-carousel.scss'] })
export class ProductCarouselComponent implements OnInit {
  loading: boolean = true;
  experiments: any[] = [];
  cart: any;
  nonRxLimit: number = 5;
  rxLimit: number = 3;
  cartItems: any = [];
  oldProduct: any;
  newProduct: any;
  user: any;
  aovExp: boolean = false;
  freeProductId: any;
  productsInCart: { [key: string]: number } = {};
  private _products: any[] = [];
  @Input('products') set products(list: any[]) {
    if (!list) return;
    this.getCart();
    this._products = list.filter((product: any): boolean => product?.price);
  }
  get products(): any[] { return this._products; }
  @Input('sectionName') sectionName: string;

  @Output() valueChange: any = new EventEmitter();
  @Output() showPopup: any = new EventEmitter<{oldProduct:any, newProduct:any}>();

  constructor(private conn: ConnectionService,
    private broadcast: BroadcastService,
    private store: Store,
    private router: Router,
    private appConfig: AppConfig,
    private windowRef: WindowRefService) {
  }

  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach((each: any): void => {
      if (each.key === 'aov_gamification' && this.user?.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED) {
        const aovData = each.variant;
        this.freeProductId = aovData.productID;
        this.aovExp = true;
      }
    });
    this.store.select(fromSelectors.selectCartProducts).subscribe((products: any): any => this.productsInCart = { ...products });
  }

  async getCart(): Promise<void> {
    this.cart = await this.conn.getCart();
    this.cartItems = this.cart.get('lineItems');
    this.products.forEach((element: any): any => {
      // eslint-disable-next-line no-param-reassign
      element.quantityCart = 0;
    });
    if (this.cartItems.length) {
      this.cartItems.forEach(async (each: any): Promise<void> => {
        this.productsInCart[each.productId] = each.quantity;
        if (this.aovExp && this.freeProductId) {
          if (this.cartItems.length === 1 && each.productId === this.freeProductId) {
            const params1 = {
              productId: this.freeProductId,
              quantity: 1,
            };
            await this.conn.removeProductFromCart(params1);
            this.getCart();
            this.valueChange.emit();
            this.store.dispatch(fromActions.CartUpdateProductsBegin({ products: this.productsInCart }));
          }
        }
      });
      this.products.forEach((e1: any): any => this.cartItems.forEach((e2: any): any => {
        if (e1.objectId === e2.productId) {
          // eslint-disable-next-line no-param-reassign
          e1.quantityCart = e2.quantity;
          if ((!e1.prescriptionRequired && e1.quantityCart > this.nonRxLimit)
          || (e1.prescriptionRequired && e1.quantityCart > this.rxLimit)) {
            this.addOrRemoveFromCart(e1, 'REMOVE');
          }
        }
      }),
      );
    }
  }

  // eslint-disable-next-line complexity
  async addOrRemoveFromCart(product: any, action: string, event?: any, img?: any): Promise<void> {
    if (event) event.stopPropagation();
    const quantity = this.productsInCart[product.objectId] || 0;
    this.oldProduct = product;
    if (action === 'ADD' && product.inventoryStatus === this.appConfig.Shared.Inventory.Type.DISCONTINUED) {
      const alternateProduct = await this.conn.findCatalogWithAlternateProduct(this.oldProduct.objectId);
      if (alternateProduct.get('alternateProduct')) {
        this.newProduct = alternateProduct.get('alternateProduct');
        this.showPopup.emit({ oldProduct: this.oldProduct, newProduct: this.newProduct });
        return;
      }
    }
    if (action === 'ADD' && !product.prescriptionRequired && quantity === this.nonRxLimit) {
      this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 5 per product' });
      return;
    }
    if (action === 'ADD' && product.prescriptionRequired && quantity === this.rxLimit) {
      this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 3 per product' });
      return;
    }
    if (action === 'ADD' && !product.prescriptionRequired && quantity < this.nonRxLimit) {
      this.animateAddToCart(img);
      this.productsInCart[product.objectId] = quantity + 1;
      const params = {
        productId: product.objectId,
        quantity: 1,
        ...(this.sectionName && { sectionName: this.sectionName }),
      };
      const res = await this.conn.addProductToCart(params);
      this.valueChange.emit();
      this.checkAddedQuantity(res, this.nonRxLimit, product);
    }

    if (action === 'ADD' && product.prescriptionRequired && quantity < this.rxLimit) {
      this.animateAddToCart(img);
      this.productsInCart[product.objectId] = quantity + 1;
      const params = {
        productId: product.objectId,
        quantity: 1,
        ...(this.sectionName && { sectionName: this.sectionName }),
      };
      const res = await this.conn.addProductToCart(params);
      this.valueChange.emit();
      this.checkAddedQuantity(res, this.rxLimit, product);
    }

    if (action === 'REMOVE' && quantity > 0) {
      this.productsInCart[product.objectId] = quantity - 1;
      const params = {
        productId: product.objectId,
        quantity: product.quantityCart - 1,
      };
      this.conn.removeProductFromCart(params)
        .then((): any => {
          setTimeout((): any => {
            this.getCart();
            this.valueChange.emit();
          });
        });
    }
    this.store.dispatch(fromActions.CartUpdateProductsBegin({ products: this.productsInCart }));
  }

  animateAddToCart(img?: HTMLImageElement): void {
    if (!img) return;
    const image = new Image();
    image.src = img.src;
    image.style.position = 'absolute';
    image.style.top = `${img.getBoundingClientRect().top}px`;
    image.style.right = `${this.windowRef.nativeWindow.innerWidth - img.getBoundingClientRect().right}px`;
    image.style.height = `${img.offsetHeight}px`;
    image.style.width = 'auto';
    image.style.zIndex = '1000';

    image.classList.add('fly-animation');
    image.onanimationend = ((event: any): void => {
      image.remove();
    });
    this.windowRef.nativeWindow.document.body.appendChild(image);
  }

  checkAddedQuantity(res: any, limit: number, product: any): void {
    if (res) {
      const cartItems = res.get('lineItems');
      cartItems.forEach((element: any): any => {
        if (element.productId === product.productId && element.quantity > 5) {
          const params = {
            productId: product.objectId,
          };
          this.conn.removeProductFromCart(params);
        }
      });
      this.getCart();
    }
  }

  viewProduct(product: any): void {
    this.router.navigate([`/product/${product.objectId}`],
      { queryParams: { ...(this.sectionName && { section: this.sectionName }) } });
  }

  buyNow(event: any, item: any): void {
    event.stopPropagation();
    const queryParams = { products: item.objectId, showAll: true };
    this.router.navigate(['user/order/reorder'], { queryParams });
  }
}
