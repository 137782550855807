import { Injectable } from '@angular/core';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { WindowRefService } from '@services/window-ref-service';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from 'client/app/app.config';

@Injectable({ providedIn: 'root' })
export class ExperimentService {
  appVersion: number = null;
  webAppVersion: string = null;
  private experimentAndroidVersionForParseInterceptor: number = 207000;
  private experimentForVideoInstructions: number = 207141;
  isInternalUser: boolean = false;
  appVersionFromConfigTable: { androidApp: string, webApp: string } = {
    androidApp: null, webApp: null,
  };
  isRebrandingV1Phase2: boolean = false;
  isMulticoncernUI: boolean = false;

  constructor(
    private appWebBridge: AppWebBridgeService,
    private windowRef: WindowRefService,
    private conn: ConnectionService,
    private appConfig: AppConfig,
  ) {
    try {
      this.appVersion = this.appWebBridge.requestAppVersion();
      this.webAppVersion = this.appConfig.Shared.AppVersion;
      this.isInternalUser = this.conn.isInternalUser();
    } catch (err) {
      // Handle error here
    }
  }

  get enableParseInterceptor(): boolean {
    const isiOSDevice = this.appWebBridge.requestOSInformation() === 'iOS' || this.windowRef.isSafariBrowser();
    if (isiOSDevice) return true;
    if (this.isInternalUser) return true;
    if (this.appVersion >= this.experimentAndroidVersionForParseInterceptor) return true;
    return false;
  }

  get showNewInstructionVideo(): boolean {
    return this.appVersion >= this.experimentForVideoInstructions;
  }
}
