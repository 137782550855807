import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@components/loading';
import { LogoutComponent } from './logout.component';

@NgModule({
  imports: [FormsModule, LoadingModule],
  declarations: [LogoutComponent],
  exports: [],
})
export class LogoutModule {}
