import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'app-home-pop-up',
  templateUrl: './home-page-bottom-sheet.html',
})

export class HomePageSheetComponent implements OnInit {
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  private conn: ConnectionService,
  private router: Router,
  private eventLogger: EventLoggerService,
  private bottomSheetRef: MatBottomSheetRef) {}
  popUpType:string = '';

  ngOnInit(): void {
    this.popUpType = this.data?.popUpType;
  }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }
}
