import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { SwiperModule } from 'swiper/angular';
import { ProductDescriptionComponent } from './product-description.component';

@NgModule({
  imports: [CommonModule, SwiperModule, ToolbarModule, LoadingModule, ButtonModule, CsIconModule],
  declarations: [ProductDescriptionComponent],
  exports: [],
})
export class ProductDescriptionModule { }
