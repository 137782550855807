import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, createUrlTreeFromSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { ConnectionService } from '@services/connection-service';

const exceptionUrls: string[] = ['/user/instantcheckup', '/logout', '/empty', 'force', 'iframe'];
const doctorExceptionUrls: string[] = ['/user/checkup', '/chatV2'];
const doctorExceptionQueryPram: string = 'loginType=token';

function checkDoctorExceptionUrl(urls: string[], url: string, token: string): boolean {
  return urls.some((exceptionUrl: string): boolean => url.includes(exceptionUrl) && url.includes(token));
}

/**
 * Check for user authentication.
 * If user is not authenticated then based on the environment(mobile/web app),
 * user is redirected to different entry points in the route.
 *
 * @param route
 * @param state
 * @returns
 */
export const authenticationGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {
  const { isUserLoggedIn }: any = inject(ConnectionService);
  const isMobileApp: any = inject(AppWebBridgeService).isAppWebBridgeLoaded();
  const isExceptionUrl = exceptionUrls.some((url: any): boolean => state.url.includes(url));
  const isDoctorExceptionUrl = checkDoctorExceptionUrl(doctorExceptionUrls, state.url, doctorExceptionQueryPram);

  if (isExceptionUrl) return true;
  if (isUserLoggedIn) return true;
  if (isDoctorExceptionUrl) return createUrlTreeFromSnapshot(route, ['/login/doctor']);

  const redirectUrl = isMobileApp ? ['onboarding/number'] : ['/language'];
  return createUrlTreeFromSnapshot(route, redirectUrl);
};
