<div class="hbox pos-rel">
  <div class="flex-box">
  <div class="flex-heading">
    <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
    <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
      <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
    </div>
    <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@transactionHistory">Transaction history</span>
    </div>
  </div>
  <div class="flex-content pos-rel">
    <loading class="pos-abs vh-center" *ngIf="loading"></loading>
    <div *ngIf="!loading">
    <div class="transaction" *ngFor="let item of transactions">
      <div class="w70" [ngSwitch]="item.reason">
      <div *ngSwitchCase='"REFERRER"'>
        <div *ngIf="item.referrerName" i18n="@@referedByReferrer">
        Referred to {{item.referrerName}}
              </div>
              <div *ngIf="!item.referrerName" i18n="@@thanksForReferring">
                Referred a friend
              </div>
            </div>
            <div *ngSwitchCase='"REFEREE"'>
              <div *ngIf="item.refereeName" i18n="@@referedToReferee">
                Referred by {{item.refereeName}}
              </div>
              <div *ngIf="!item.refereeName" i18n="@@thanksForSignUp">
                Referred by a friend
              </div>
            </div>
            <div *ngSwitchCase='"SURPRISE"' i18n="@@surpriseGift">
              Surprise Gift
            </div>
            <div *ngSwitchCase='"GAME"'>Game Reward</div>
            <div *ngSwitchCase='"REGIMEN_ORDER"' i18n="@@treatmentOrder">
              Treatment Order
            </div>
            <div *ngSwitchCase='"PRODUCT_ORDER"' i18n="@@productOrder">
              Product Order
            </div>
            <div class="text-cap" *ngSwitchDefault="">{{item.reason}}</div>
            <div class="date">{{item.createdAt | date: 'MMM d, yyy'}}</div>
          </div>
          <div class="w30 flex justify-end bold" [ngClass]="{ 'clr-green': item.type === 1, 'clr-warning': item.type === -1 }">
            <span class="pR2">{{item.type >= 0 ? '+' : '-'}}</span>
            <div class="pL10">₹</div>
            <span>{{item.amount}}</span>
          </div>
        </div>
        <div class="centerA empty-hint empty-icon" *ngIf="!transactions.length" i18n="@@noTransactions">
          No Transactions Available
        </div>
      </div>
    </div>
  </div>
</div>