import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import Swiper, { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-sticky-bar',
  templateUrl: './sticky-bar.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class StickyBarComponent implements OnInit {
@Input('showStickyBars$')showStickyBars$:any;
@Input('userStatus')userStatus:any;
@Input('onSlideChange')onSlideChange:EventEmitter<any> = new EventEmitter();
@Input('bannerContainer')bannerContainer:any;
@Input('kitUrl')kitUrl:string;
@Input('order')order:any;
@Output('checkoutOrder')checkoutOrder:EventEmitter<void> = new EventEmitter();
@Output('onCancelClick')onCancelClick:EventEmitter<void> = new EventEmitter();
@Input('followUp')followUp:any;
@Input('defaultDoctorImage')defaultDoctorImage:any;
@Output('startFollowUp')startFollowUp:EventEmitter<void> = new EventEmitter();
@Input('toolbarIcons')toolbarIcons:any;
@Input('user')user:any;
@Output('goToCartNav')goToCartNav:EventEmitter<void> = new EventEmitter();

carouselConfig: SwiperOptions = {
  slidesPerView: 1,
  navigation: { prevEl: 'nonext', nextEl: 'nonext' },
  pagination: true,
  autoplay: { delay: 6000, disableOnInteraction: false },
};
constructor() { }
ngOnInit(): void {
}
handleSlide():void {
  this.onSlideChange.emit();
}
handleOrder():void {
  this.checkoutOrder.emit();
}
handleCancel():void {
  this.onCancelClick.emit();
}
handleFollowUp():void {
  this.startFollowUp.emit();
}
handleNav():void {
  this.goToCartNav.emit();
}
}
