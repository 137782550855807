<div class="toolbar bold" #toolbarContainer="" [ngStyle]='{ "background-color": bg  }'>
  <div class="leftIcon" [ngStyle]='{ "color": leftIconColor }'>
  <em class="cs-icon-menu font25" *ngIf="enableMenu" rippleEffect="rippleEffect" (callback)="onAction.emit()" rippleClass="ripple-white"></em><em class="cs-icon-back-arrow" *ngIf="!enableMenu && !disableBack" rippleEffect="rippleEffect" (callback)="onAction.emit()" rippleClass="ripple-white"></em>
  </div>
  <div class="title ellipsis"><ng-content></ng-content></div>
  <div class="rightIcon" *ngIf="icons.length && icons[0].name === appConfig.Toolbar.ICONS.DELETE" [color]='"#fff"' loadingEffect="loadingEffect" [timeout]="3000" [bgClass]='"bg-primary"'>
  <div class="flex justify-center" *ngIf="icons.length" rippleEffect="rippleEffect" rippleClass="ripple-white">
    <em class="cs-icon-delete font22" *ngIf="icons[0].name === appConfig.Toolbar.ICONS.DELETE" (click)="onClick(icons[0])"></em>
  </div>
  </div>
  <div class="rightIcon" *ngIf="icons.length && icons[0].name !== appConfig.Toolbar.ICONS.DELETE" [attr.data-after]="icons[0].cartValue" [ngClass]='{ "notify": icons[0].notify && !icons[1]?.cartValue , "cartCart": icons[0].cartValue && icons[0].showCartUI, "cartHome": icons[1]?.homePage && icons[1]?.showCartUI && icons[1].cartValue, "notify2": icons[0].notify && icons[1]?.showCartUI && icons[1]?.cartValue }'>
  <div class="flex justify-center" *ngIf="icons.length" rippleEffect="rippleEffect" rippleClass="ripple-white">
    <em class="cs-icon-lock font22" *ngIf="icons[0].name === appConfig.Toolbar.ICONS.LOCK" (click)="onClick(icons[0])"></em><em class="cs-icon-edit font20" *ngIf="icons[0].name === appConfig.Toolbar.ICONS.EDIT" (click)="onClick(icons[0])"></em><em class="cs-icon-tick font20" *ngIf="icons[0].name === appConfig.Toolbar.ICONS.SAVE" (click)="onClick(icons[0])"></em><em class="cs-icon-phone-in-talk font20" *ngIf="icons[0].name === appConfig.Toolbar.ICONS.CALL" (click)="onClick(icons[0])"></em><em class="cs-icon-info font22" *ngIf="icons[0].name === appConfig.Toolbar.ICONS.INFO" (click)="onClick(icons[0])"></em>
    <div class="doubleIcon" [attr.data-after]="icons[1]?.cartValue" *ngIf="icons[1]?.name === appConfig.Toolbar.ICONS.CART && icons[1]?.showCartUI && icons[1]?.cartValue" (click)="onClick(icons[1])" [ngClass]='{ "checkData": icons[1]?.cartValue }'>
    <img class="cartIcon" *ngIf="icons[1]?.name === appConfig.Toolbar.ICONS.CART && icons[1]?.showCartUI && icons[1]?.homePage" src="/assets/images/cart.png" />
    </div>
    <em class="cs-icon-notification" *ngIf="icons[0].name === appConfig.Toolbar.ICONS.NOTIFICATION" (click)="onClick(icons[0])"></em><em class="cs-icon-refresh" *ngIf="icons[0].name === appConfig.Toolbar.ICONS.REFRESH" (click)="onClick(icons[0])"></em><img class="cartIcon" *ngIf="icons[0].name === appConfig.Toolbar.ICONS.CART && icons[0].showCartUI && icons[0].cartValue" src="/assets/images/cart.png" (click)="onClick(icons[0])" />
  </div>
  </div>
</div>