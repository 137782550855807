<ng-container *ngIf="isRebrandingV1; then rebrandedTemplateV1 else originalTemplate"></ng-container>

<ng-template #originalTemplate>
  <div class="font-noto tw-pb-7 tw-flex tw-justify-center tw-flex-col">
    <div [ngClass]="regimenImageBackgroundColor" class="tw-rounded-t-xl">
      <img class="tw-w-full tw-rounded-t-xl tw-object-contain" [src]="coverImage" />
    </div>
    <div class="tw-flex tw-items-center tw-text-center tw-flex-col">
      <img class="tw-bg-green-100 tw-rounded-full tw-w-[96px] tw-object-contain tw-h-[96px]" [src]="doctorImage || '/assets/images/dr-jisha.png'" style="border: 4px solid white; margin-top: -80px" />
      <p class="w70 tw-mt-2 tw-font-bold tw-text-500 tw-mb-4" *ngIf="class === appConfig.Shared.Regimen.Class.HAIR">
        <span class="tw-capitalize">{{userName}},&nbsp;</span><span i18n="@@yourHairKitIsReady">Your hair treatment kit is ready</span>
      </p>
      <p class="w70 tw-mt-2 tw-font-bold tw-text-500 tw-mb-4" *ngIf="class === appConfig.Shared.Regimen.Class.FACE ">
        <span class="tw-capitalize">{{userName}},&nbsp;</span><span i18n="@@yourSkinKitIsReady">Your skin treatment kit is ready</span>
      </p>
      <cs-button [class]="'tw-w-[260px] primary'"><cs-button-text i18n="@@buyNow">Buy Now</cs-button-text></cs-button>
    </div>
  </div>
</ng-template>

<ng-template #rebrandedTemplateV1>
  <!-- Rebranded template v1 -->
  <div class="font-noto tw-pb-8 tw-flex tw-justify-center tw-flex-col">
    <div [ngClass]="regimenImageBackgroundColor" class="tw-rounded-[15px]">
      <img class="tw-w-full tw-rounded-[15px] tw-object-contain" [src]="coverImage" />
    </div>
    <div class="tw-flex tw-items-center tw-text-center tw-flex-col">
      <img class="tw-bg-green-100 tw-rounded-full tw-w-[96px] tw-object-contain tw-h-[96px]" [src]="doctorImage || '/assets/images/dr-jisha.png'" style="border: 4px solid white; margin-top: -60px" />
      <p class="tw-mt-2 tw-font-bold tw-text-500 tw-text-gray-800 tw-mb-2 tw-px-8" *ngIf="class === appConfig.Shared.Regimen.Class.HAIR">
        <span class="tw-capitalize">{{userName}},&nbsp;</span><span i18n="@@yourHairKitIsReady">Your hair treatment kit is ready</span>
      </p>
      <p class="tw-mt-2 tw-font-bold tw-text-500 tw-text-gray-800 tw-mb-2 tw-px-8" *ngIf="class === appConfig.Shared.Regimen.Class.FACE ">
        <span class="tw-capitalize">{{userName}},&nbsp;</span><span i18n="@@yourSkinKitIsReady">Your skin treatment kit is ready</span>
      </p>
      <div class="tw-w-full">
        <cs-button [class]="'tw-w-full tw-h-10 primary tw-max-w-[216px] tw-box-border'"><cs-button-text [class]="'tw-text-300 tw-font-bold'" i18n="@@buyNow">Buy Now</cs-button-text></cs-button>
      </div>
    </div>
  </div>
</ng-template>