<div class="tw-flex tw-flex-col tw-h-full tw-bg-teal-200 tw-bg-repeat-x tw-bg-top">
  <div class="tw-flex-1 tw-p-6 tw-pb-7 tw-flex tw-flex-col">
    <div class="tw-font-body tw-font-bold tw-text-800 tw-text-gray-800 tw-pt-14 tw-mb-2 title" i18n="@@verifyYourMobileNumber"> Verify your mobile number </div>
    <img class="tw-block tw-w-[75px] tw-h-[25px]" src="https://storage.googleapis.com/heallocontent/app/img/cs-icon.svg" />
    <div class="tw-flex-1"></div>
    <div class="tw-font-body tw-text-300 tw-text-gray-800" i18n="@@weHaveSentA4DigitCode"> We have sent a 4-digit code </div>
    <div class="tw-font-body tw-text-300 tw-text-gray-800 tw-mb-1" i18n="@@weWillSendOtp"> to your mobile number </div>
    <div id="edit-number" class="tw-flex tw-items-center tw-font-body tw-text-300 tw-text-gray-800" (click)="editNumber()">
      <span class="tw-font-bold tw-mr-[14px]">+91 {{mobileNumber}}</span>
      <div class="tw-flex tw-items-baseline">
        <span class="tw-text-teal-600 tw-text-200 tw-font-bold tw-tracking-[0.3px]" i18n="@@edit">Edit</span>
        <cs-icon class="tw-h-2 tw-w-2 tw-ml-2" [iconName]="'/rebranding/v1/arrow_forward'"></cs-icon>
      </div>
    </div>
  </div>
  <div class="tw-flex-none tw-rounded-tr-5 tw-rounded-tl-5 tw-px-6 tw-pt-8 tw-pb-[38px] tw-bg-white">
    <div class="tw-relative tw-mb-2">
      <div class="tw-flex tw-items-center tw-gap-2">
        <ng-container *ngFor="let index of [0, 1, 2, 3]">
          <div class="tw-h-12 tw-w-12 tw-rounded-2.5 tw-bg-gray-80 tw-border tw-border-solid tw-border-gray-300"
            [ngClass]="{'tw-border-teal-400': otpInput?.value?.toString().length === 4}"></div>
        </ng-container>
      </div>
      <div class="tw-absolute tw-top-0 tw-w-full">
        <input class="tw-w-full tw-h-12 tw-flex tw-rounded-xl tw-border-0 tw-m-0 tw-font-body tw-text-300 tw-text-gray-800 tw-bg-transparent tw-tracking-[49px] tw-pl-[18px] placeholder:tw-tracking-[49px]" #otpInput="" id="otpInput" [(ngModel)]="verificationCode" autocomplete="off" type="number" pattern="[0-9]*" autofocus="autofocus" (keydown)="commonUtilityHelper.limitInputByMaxDigit($event, 4)" placeholder="xxxx" [ngClass]="{ 'tw-caret-transparent': otpInput?.value.toString().length === 4 }" />
      </div>
    </div>
    <div class="tw-mb-8 tw-font-body tw-text-300 tw-text-gray-800" *ngIf="ui.timeLefForOTPCall > 0" i18n="@@waitForCall"> Wait for a call in ( <div class="dib text-center" style="width: 20px">
      {{ui.timeLefForOTPCall}}
    </div>
    <span>) secs</span>
  </div>
    <cs-button class="tw-mb-6 proceed tw-relative" [disabled]="otpInput?.value.toString().length !== 4" (callback)="verifyOtp()" id="continueBtn" [class]="'w100 primary'" [loading]="ui.loading" [ngClass]="{ 'tw-mt-[60px]': !(ui.timeLefForOTPCall > 0) }">
      <cs-button-text i18n="@@proceed">Proceed</cs-button-text>
    </cs-button>
    <div class="tw-font-body tw-text-100 tw-text-gray-500 tw-font-normal" i18n="@@termsNew">
      <span>By proceeding, you consent to share your information with Cureskin and agree to Cureskin's</span>
      <span id="privacy-policy" class="tw-text-gray-500 tw-px-[5px] tw-underline tw-font-bold" (click)='navigateTo("/iframe?src=https://cdn.cureskin.com/privacypolicy_new.html")'>Privacy Policy</span>
      <span>and</span>
      <span id="terms-of-service" class="tw-text-gray-500 tw-px-[5px] tw-underline tw-font-bold" (click)='navigateTo("/iframe?src=https://cdn.cureskin.com/termsofservice_new.html")'>Terms of Service.</span>
    </div>
  </div>
</div>