import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShopCarouselComponent } from './shop-carousels.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ShopCarouselComponent],
  exports: [ShopCarouselComponent],
})
export class ShopCarouselsModule {}
