import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { IncompleteInformationComponent } from './incomplete-information.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ButtonModule,
  ],
  declarations: [IncompleteInformationComponent],
  exports: [IncompleteInformationComponent],
})
export class IncompleteInformationBannerModule {}
