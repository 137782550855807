import * as appSelector from './app.selectors';
import * as routerSelector from './router.selectors';
import * as checkoutSelector from './checkout.selectors';
import * as supportSelector from './support.selectors';

const fromSelectors = {
  ...appSelector,
  ...routerSelector,
  ...checkoutSelector,
  ...supportSelector,
};

export { fromSelectors };
