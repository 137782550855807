import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '@components/loading';
import { ImagePopUpModule } from '@components/image-pinch-zoom-popup';
import { AttachmentsComponent } from './attachments.component';

@NgModule({
  imports: [CommonModule, RouterModule, LoadingModule, ImagePopUpModule],
  declarations: [AttachmentsComponent],
  exports: [AttachmentsComponent],
})
export class AttachmentsModule {}
