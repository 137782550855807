import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConnectionService } from '../connection-service';
import { AppConfig } from '../../app/app.config';

@Injectable()
export class ElasticService {
  headers: HttpHeaders;
  url: string;

  constructor(private http: HttpClient, private conn: ConnectionService, private appConfig: AppConfig) {
    this.url = `${conn.analyticsUrl}/api/web/log`;
    this.headers = new HttpHeaders({ Authorization: `token ${this.conn.isTestEnvironment ? 'dev' : 'prod'}` });
  }

  setIdentity(interApplicationTrackingId: any): void {
    if (!this.conn.isUserLoggedIn && interApplicationTrackingId) {
      this.log({
        analytics: this.appConfig.Shared.Analytics.Mixpanel,
        track: 'identity',
        params: { uniqueId: interApplicationTrackingId },
      });
    } else {
      const user = this.conn.getCurrentUser();
      this.log({
        analytics: this.appConfig.Shared.Analytics.Mixpanel,
        track: 'identity',
        params: { uniqueId: user.get('username') },
      });
    }
  }

  log(d: any): void {
    if (this.conn.isDevelopmentEnvironment) return;
    const data = d;
    if (this.conn.isUserLoggedIn) {
      const currentUser = this.conn.getCurrentUser();
      data.username = currentUser?.get('username');
      data.mod20 = currentUser?.get('mod20');
      data.userId = currentUser?.id;
      data.userCreatedAt = currentUser?.get('createdAt')?.toISOString();
      data.logSource = 'WEB_APP';
      data.appVersion = this.appConfig.Shared.AppVersion;
    }
    this.http.post(this.url, data, { headers: this.headers }).subscribe({ error: ElasticService.handleError });
  }

  private static handleError(): void {}
}
