<div class="card tw-flex tw-flex-row tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 tw-bg-orange-100">
  <div class="tw-relative tw-top-[-10px]">
    <img class="tw-flex-none tw-h-20 tw-w-20 tw-rounded-full" src="../assets/images/voice_note.gif" /><img class="tw-flex-none tw-h-14 tw-w-14 tw-rounded-full tw-absolute tw-top-3 tw-left-3" [src]="docImg" />
    <div class="orangeDot tw-absolute tw-top-3 tw-right-4 tw-w-2 tw-h-2 tw-bg-orange-500 tw-rounded-full tw-content-none"></div>
  </div>
  <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-2">
    <div></div>
    <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@newVoiceMsg">
      1 new voice message
    </p>
    <p class="tw-font-body tw-text-200 tw-text-black tw-text-xs" i18n="@@drSharedMessage">
      Your doctor has shared a message regarding your treatment.
    </p>
    <c-audio class="c-audio" [src]="voiceUrl" [trackEvent]="trackEventAudio" eventPrefix="OrderApproval" (emitTrackEvent)="handleClick($event)"></c-audio>
  </div>
</div>