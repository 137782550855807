import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { SwiperModule } from 'swiper/angular';
import { PopUpModule } from '@components/pop-up';
import { BannerScrollbarComponent } from './banner-scrollbar.component';
import { FollowUpBannerModule } from '../follow-up-banner/follow-up-banner.module';
import { FollowUpReportBannerModule } from '../followup-report-banner/followup-report-banner.module';
import { IncompleteInformationBannerModule } from '../incomplete-information-banner/incomplete-information.module';
import { InstructionBannerModule } from '../instruction-banner/instruction-banner.module';
import { NPSBannerModule } from '../n-p-s-banner/n-p-s-banner.module';
import { CrossRegimenBannerModule } from '../cross-regimen-banner/cross-regimen-banner.module';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    SwiperModule,
    ButtonModule,
    FollowUpBannerModule,
    FollowUpReportBannerModule,
    IncompleteInformationBannerModule,
    NPSBannerModule,
    InstructionBannerModule,
    PopUpModule,
    CrossRegimenBannerModule,
  ],
  declarations: [BannerScrollbarComponent],
  exports: [BannerScrollbarComponent],
})
export class BannerScrollbarModule {}
