import { Directive, EventEmitter, HostListener, Input, Output, ViewContainerRef } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service/index';

@Directive({ selector: '[rippleEffect]' })
export class RippleEffectDirective {
  debounce: any;
  debouncePress: any;
  @Input('rippleClass') rippleClass: string;
  @Input('noDelay') noDelay: boolean;
  @Output('callback') callback: EventEmitter<any> = new EventEmitter();
  constructor(private window: WindowRefService, private viewContainer: ViewContainerRef) {}

  @HostListener('click', ['$event'])
  handleClick(event: any): void {
    clearInterval(this.debounce);
    clearInterval(this.debouncePress);
    const node = this.viewContainer.element.nativeElement;
    const cords = node.getBoundingClientRect();
    this.debounce = setTimeout((): void => {
      const rippleEffect: any = Array.from(node.querySelectorAll('.ripple'));
      if (rippleEffect.length) rippleEffect.forEach((each: any): void => each.remove());
    }, 1000);
    if (this.noDelay) this.callback.emit();
    const left = `${event.x - cords.x - (cords.width / 2)}px`;
    const top = `${event.y - cords.y - (cords.width / 2)}px`;
    const span = this.window.nativeWindow?.document.createElement('span');
    if (!span) return;
    span.classList.add('ripple');
    if (this.rippleClass) span.classList.add(this.rippleClass);
    span.style.left = left;
    span.style.top = top;
    span.style.height = `${cords.width}px`;
    span.style.width = `${cords.width}px`;
    node.appendChild(span);
    if (!this.noDelay) setTimeout((): void => this.callback.emit(), 300);
    event.preventDefault();
  }

  @HostListener('touchstart', ['$event'])
  handleTouchDown(event: any): void {
    clearInterval(this.debounce);
    clearInterval(this.debouncePress);
    this.debouncePress = setTimeout((): void => {
      const node = this.viewContainer.element.nativeElement;
      const cords = node.getBoundingClientRect();
      const left = `${event.touches[0].clientX - cords.x - (cords.width / 2)}px`;
      const top = `${event.touches[0].clientY - cords.y - (cords.width / 2)}px`;
      const span = this.window.nativeWindow.document.createElement('span');
      span.classList.add('ripple-press');
      if (this.rippleClass) span.classList.add(this.rippleClass);
      span.style.left = left;
      span.style.top = top;
      span.style.height = `${cords.width}px`;
      span.style.width = `${cords.width}px`;
      node.appendChild(span);
    }, 300);
  }

  @HostListener('touchend', ['$event'])
  handleTouchEnd(event: any): void {
    clearInterval(this.debouncePress);
    const node = event.target.closest('[rippleeffect]');
    const rippleEffect: any = Array.from(node.querySelectorAll('.ripple-press'));
    if (rippleEffect.length) rippleEffect.forEach((each: any): void => each.remove());
  }
}
