import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'privacy-policy-sheet',
  templateUrl: './privacy-policy-sheet.html',
  styleUrls: ['privacy-policy-sheet.scss'],
})
export class PrivacyPolicySheetComponent implements OnInit {
  user: any;
  isChecked: boolean = true;
  pastRegimensClass: Array<string> = ['HAIR'];
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  private conn: ConnectionService,
  private eventLogger: EventLoggerService,
  private bottomSheetRef: MatBottomSheetRef) {}

  ngOnInit(): void {
    this.user = this.conn.getActingUser();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'new-privacy-policy' }));
    this.checkForUserPastRegimens();
  }

  async checkForUserPastRegimens(): Promise<void> {
    const pastRegimens = await this.conn.getPastRegimens(this.user);
    if (!pastRegimens.length) return;
    this.pastRegimensClass = pastRegimens.map((regimen: any): any => regimen?.get('class'));
  }

  async handleClick(): Promise<void> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'new-privacy-policy-accepted' }));
    if (this.data?.callback) this.data.callback();
    this.closeBottomSheet();
  }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }

  navigateTo(url: string): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'new-privacy-policy-terms' }));
    this.closeBottomSheet();
    this.conn.navigateToURL(url);
  }
}
