import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ChatV2Component } from './chatV2.component';
import { ChatV2ViewModule } from '../chatV2-view/chatV2-view.module';
import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: 'chatV2/:id',
      canActivate: [authenticationGuard, onboardingGuard],
      component: ChatV2Component,
    }]),
    ChatV2ViewModule,
  ],
  exports: [ChatV2Component],
  declarations: [ChatV2Component],
})
export class ChatV2Module { }
