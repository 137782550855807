import { Injectable } from '@angular/core';
import { CookieService } from '../cookie-service';
import { AppWebBridgeService } from '../app-web-bridge-service';
import { LocalStorageService } from '../local-storage-service';
import { ConnectionService } from '../connection-service';
import { EventLoggerService } from '../event-logger-service';
import { AuthConfig } from '../../app/app-constant-types';
import { BroadcastService } from '../broadcast-service';
import { generateDigest } from '../utility';

@Injectable()
export class LoginService {
  constructor(private cookies: CookieService,
    private appWebBridge: AppWebBridgeService,
    private localStorageService: LocalStorageService,
    private conn: ConnectionService,
    private eventLogger: EventLoggerService,
    private broadcaster: BroadcastService) {
  }

  /**
   * Receive the auth data which include (source, mobilenumber, otp, ids, etc..) to make the login.
   * 1. We verify the OTP and get the session token.
   * 2. We login with the token and update user information.
   * 3. We check for user preferred language and redirect to the specific website of (same page || redirectUrl || '/')
   * @param {AuthConfig} authData
   * @param {boolean} enableOTPVerifiedLog
   * @returns {Promise<any>}
   */
  async authenticateUser(authData: AuthConfig, { enableOTPVerifiedLog }: { enableOTPVerifiedLog?: boolean } = {}): Promise<any> {
    try {
      const deviceId = this.cookies.get('deviceId');
      const aaid = this.appWebBridge.requestAndroidAdvertisingID();
      const referralCode = this.appWebBridge.requestReferralCode();
      const source = this.appWebBridge.requestOSInformation();
      const time = new Date().toISOString();
      const digest = await generateDigest(authData.mobileNumber, time);
      const payload: any = { aaid, deviceId, referral: referralCode, source, ...authData, time, digest };
      const { sessionToken }: any = await this.conn.verifyOTP(payload);
      if (enableOTPVerifiedLog) {
        this.eventLogger.trackEvent('otp_verified', { device_id: deviceId, mobileNumber: authData.mobileNumber });
      }
      await this.conn.loginWithTokenAndUpdateUserInfo(sessionToken, { loginSource: this.appWebBridge.requestOSInformation() });
      this.eventLogger.trackEvent(`${authData.loginMethod}_verified`, { device_id: deviceId, mobileNumber: authData.mobileNumber });
      await this.conn.checkUserLanguageAndRedirect(this.localStorageService.getValue('CureSkin/redirectUrl') || '/');
    } catch (error) {
      const message = error.toString();
      this.eventLogger.logError(message);
      return Promise.reject(message);
    }
    return Promise.resolve();
  }
}
