<div class="audio-card" *ngIf="chat">
  <div class="icon" *ngIf='chat.get("Owner") === appConfig.Shared.Assistant.Owner.DOCTOR'>
  <img *ngIf="doctor" [src]='doctor.get("doctorDisplayImage") || doctor.get("DoctorImageThumbnail") || "https://cdn.cureskin.com/app/img/dp-dr-charu.jpg"' />
  </div>
  <div class="content">
  <div class="fontS1">
    {{isDoctorCallExperiment ? '"Please listen to your audio message"' :
  chat.get('Message')}}
  </div>
  <audio class="mT10" controls="controls" controlslist="nodownload" *ngIf="chat.get('audioUrl')" (error)="audioError($event)" type="audio/mpeg" (play)="onAudioPlay($event, chat)" (ended)="onAudioPlayEnd($event, chat)">
    <source [src]="chat.get('audioUrl')" />
  </audio>
  <div class="fontS1 mT10" *ngIf="isInternalUser">
    <div class="bold">Internal Note:</div>
    <div class="mT5">{{chat.get('internalNote')}}</div>
  </div>
  <div class="timestamp">
    <em class="cs-icon-tick clr-green fontS2" *ngIf="chat.get('trackingDetails')?.audioPlayed && isInternalUser"></em><em class="cs-icon-tick clr-green fontS2" *ngIf="chat.get('trackingDetails')?.audioFinished && isInternalUser"></em><span>{{chat.get('createdAt') | date: (isInternalUser ? 'hh:mm a dd MM yy' :
  'hh:mm a') }} &nbsp; {{isInternalUser ? chat.get('OperatorName') ||
  chat.get('Owner') : ''}}</span>
  </div>
  </div>
</div>