import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'swiper/angular';
import { VideoPlayerModule } from '@components/video-player/video-player.module';
import { NPSBannerModule } from 'client/app/user/user-view/user-home/n-p-s-banner/n-p-s-banner.module';
import { CAudioModule } from '@shared/c-audio/c-audio.module';
import { LoadingModule } from '@components/loading';
import { WaitingForPhotoComponent } from './waiting-for-photo/waiting-for-photo.component';
import { OrderReceivedComponent } from './orderReceived/order-received.component';
import { KitDeliveredComponent } from './kit-delivered/kit-delivered.component';
import { WantDrCheckupComponent } from './want-dr-checkup/want-dr-checkup.component';
import { RequestDataComponent } from './request-data/request-data.component';
import { ReportReadyComponent } from './report-ready/report-ready.component';
import { ReportProcessComponent } from './report-process/report-process.component';
import { PhotoBlurComponent } from './photo-blur/photo-blur.component';
import { PackingOrderComponent } from './packing-order/packing-order.component';
import { OutForDeliveryComponent } from './out-for-delivery/out-for-delivery.component';
import { OrderShippedComponent } from './order-shipped/order-shipped.component';
import { MissedDoctorCallComponent } from './missed-doctor-call/missed-doctor-call.component';
import { IncompleteChatComponent } from './incomplete-chat/incomplete-chat.component';
import { FestiveBannerComponent } from './festive-banner/festive-banner.component';
import { DoctorReplyComponent } from './doctor-reply/doctor-reply.component';
import { DoctorCallComponent } from './doctor-call/doctor-call.component';
import { CheckupScheduledComponent } from './checkup-scheduled/checkup-scheduled.component';
import { CheckupMissedComponent } from './checkup-missed/checkup-missed.component';
import { CheckUpAnytimeComponent } from './checkup-anytime/checkup-anytime.component';
import { CompleteYourOrderComponent } from './complete-your-order/complete-your-order.component';
import { VoiceNoteScriptComponent } from './voice-note-script/voice-note-script.component';
import { CompleteYourMultipleOrderComponent } from './complete-your-multiple-order/complete-your-multiple-order.component';
import { VoiceNoteBannerComponent } from './voice-note-banner/voice-note-banner.component';
import { DoubtsSectionComponent } from './doubts-section/doubts-section.component';
import { RegimenKitReadyComponent } from './regimen-kit-ready/regimen-kit-ready.component';
import { WeekZeroJourneyComponent } from './week-zero-journey/week-zero-journey.component';
import { Week1PartOneComponent } from './week1-part-one/week1-part-one.component';
import { RegimenPrepComponent } from './regimen-prep/regimen-prep.component';
import { Week1PartTwoComponent } from './week1-part-two/week1-part-two.component';
import { Week2PartOneComponent } from './week2-part-one/week2-part-one.component';
import { Week2PartTwoComponent } from './week2-part-two/week2-part-two.component';
import { DietPlanComponent } from './diet-plan/diet-plan.component';
import { AnalysisBannerComponent } from './analysis-banner/analysis-banner.component';
import { TestimonialsBannerComponent } from './testimonials/testimonials.component';
import { ReferralBannerComponent } from './referral-banner/referral-banner.component';
import { QuizBannerComponent } from './quiz-banner/quiz-banner.component';
import { FaqSectionComponent } from './faq-section/faq-section.component';
import { ExploreContactBannersComponent } from './explore-contact-banners/explore-contact-banners.component';
import { StickyBarComponent } from './sticky-bar/sticky-bar.component';
import { Week3PartOneComponent } from './week3-part-one/week3-part-one.component';
import { Week4PartOneComponent } from './week4-part-one/week4-part-one.component';
import { SuggestedForYouComponent } from './suggested-for-you/suggested-for-you.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    RouterModule,
    CAudioModule,
    SwiperModule,
    NPSBannerModule,
    VideoPlayerModule,
    LoadingModule,
  ],
  declarations: [
    WaitingForPhotoComponent,
    OrderReceivedComponent,
    KitDeliveredComponent,
    WantDrCheckupComponent,
    RequestDataComponent,
    ReportReadyComponent,
    ReportProcessComponent,
    PhotoBlurComponent,
    PackingOrderComponent,
    OutForDeliveryComponent,
    OrderShippedComponent,
    MissedDoctorCallComponent,
    RegimenPrepComponent,
    IncompleteChatComponent,
    FestiveBannerComponent,
    DoctorReplyComponent,
    DoctorCallComponent,
    CheckupMissedComponent,
    CheckupScheduledComponent,
    CheckUpAnytimeComponent,
    CompleteYourOrderComponent,
    CompleteYourMultipleOrderComponent,
    TestimonialsBannerComponent,
    VoiceNoteBannerComponent,
    DoubtsSectionComponent,
    RegimenKitReadyComponent,
    WeekZeroJourneyComponent,
    Week1PartOneComponent,
    Week1PartTwoComponent,
    Week2PartOneComponent,
    Week2PartTwoComponent,
    Week3PartOneComponent,
    Week4PartOneComponent,
    DietPlanComponent,
    AnalysisBannerComponent,
    ReferralBannerComponent,
    QuizBannerComponent,
    FaqSectionComponent,
    ExploreContactBannersComponent,
    StickyBarComponent,
    VoiceNoteScriptComponent,
    SuggestedForYouComponent,
  ],
  exports: [
    WaitingForPhotoComponent,
    OrderReceivedComponent,
    KitDeliveredComponent,
    CompleteYourOrderComponent,
    VoiceNoteScriptComponent,
    WantDrCheckupComponent,
    RequestDataComponent,
    ReportReadyComponent,
    ReportProcessComponent,
    PhotoBlurComponent,
    PackingOrderComponent,
    OutForDeliveryComponent,
    CompleteYourMultipleOrderComponent,
    OrderShippedComponent,
    MissedDoctorCallComponent,
    IncompleteChatComponent,
    FestiveBannerComponent,
    DoctorReplyComponent,
    DoctorCallComponent,
    CheckupMissedComponent,
    CheckupScheduledComponent,
    CheckUpAnytimeComponent,
    Week1PartOneComponent,
    Week2PartTwoComponent,
    VoiceNoteBannerComponent,
    DoubtsSectionComponent,
    TestimonialsBannerComponent,
    RegimenKitReadyComponent,
    WeekZeroJourneyComponent,
    Week1PartTwoComponent,
    Week2PartOneComponent,
    Week3PartOneComponent,
    Week4PartOneComponent,
    DietPlanComponent,
    AnalysisBannerComponent,
    ReferralBannerComponent,
    QuizBannerComponent,
    RegimenPrepComponent,
    FaqSectionComponent,
    ExploreContactBannersComponent,
    StickyBarComponent,
    SuggestedForYouComponent,
  ],
})
export class HomeBannersModule {}
