import { Component, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { LocalStorageService } from '@services/local-storage-service';
import { WindowRefService } from '@services/window-ref-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'onboarding-location',
  templateUrl: './onboarding-location.component.html',
})
export class OnboardingLocationComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  user: any;
  showInfo: boolean = false;
  loading: boolean = false;

  constructor(
    private appWebBridge: AppWebBridgeService,
    private broadcast: BroadcastService,
    private conn: ConnectionService,
    private router: Router,
    private eventLogger: EventLoggerService,
    private window: WindowRefService,
    private localStorage: LocalStorageService,
    private zone: NgZone) {
  }

  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'onboarding-location' }));
  }

  async continue(): Promise<void> {
    await this.conn.updateUserData({ lastLocation: {} });
    if (this.showInfo) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'Onboarding-Location-Details-Popup' }));
    }
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'onboardingLocationAccessDenied' }));

    this.zone.run(async (): Promise<void> => {
      await this.router.navigate(['/onboarding/concern'], { queryParams: { redirectOnBack: '/home' } });
    });
  }

  async getLocation(): Promise<any> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'onboardingLocationAccessGranted' }));
    if (this.showInfo) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'Onboarding-Location-Details-Popup' }));
    }
    this.loading = true;
    // Native location
    if (this.appWebBridge.getLocationDetails()) {
      this.loading = false;
      await this.router.navigate(['/onboarding/concern'], { queryParams: { redirectOnBack: '/home' } });
      return;
    }
    // Browser location
    this.window.nativeWindow.navigator.geolocation.getCurrentPosition(async (location: any): Promise<void> => {
      this.loading = false;
      await this.setUserLocation({ latitude: location.coords.latitude, longitude: location.coords.longitude });
      await this.router.navigate(['/onboarding/concern'], { queryParams: { redirectOnBack: '/home' } });
    }, (): any => {
      this.loading = false;
      this.broadcast.broadcast('NOTIFY', { message: 'Location permission denied. Kindly enable permission in your browser settings.' });
    });
  }

  async setUserLocation(lastLocation: any): Promise<void> {
    await this.conn.updateUserData({ lastLocation: lastLocation || {} });
  }

  back():any {
    const queryParams = { fromLocationPage: true };
    this.router.navigate(['/onboarding/gender'], { queryParams });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: any): void => sub.unsubscribe());
  }
}
