import { Directive, ElementRef, Input } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';

@Directive({
  selector: '[appScrollToView]',
})
export class ScrollToViewDirective {
  @Input() set appScrollToView(isSelected: boolean) {
    if (!isSelected) return;
    this.scrollToVeiw();
  }

  constructor(private elementRef: ElementRef<HTMLDivElement>, private windowRef: WindowRefService) {
  }

  scrollToVeiw(): void {
    const scrollX = this.elementRef.nativeElement.offsetLeft
      + this.elementRef.nativeElement.offsetWidth / 2
      - this.windowRef.nativeWindow.innerWidth / 2;

    this.elementRef.nativeElement.parentElement.scroll(scrollX, 0);
  }
}
