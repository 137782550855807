<div class="hbox font-noto">
    <div class="flex-box">
        <div class="flex-content pos-rel">
            <ng-container *ngIf="!showAsCarousel">
                <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-relative tw-z-[100]">
                    <div class="tw-fixed tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10 tw-z-10" (click)="back()">
                        <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
                    </div>
                    <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@testimonials">Testimonials</span>
                </div>
            </ng-container><img class="tw-w-full tw-object-cover tw-mb-4" [src]="testimonial.img" />
            <div class="tw-p-6">
                <div class="tw-text-600 tw-font-bold">{{testimonial.testimonial}}</div>
                <div class="tw-text-400 tw-text-gray-500 tw-my-2">
                    {{testimonial.story}}
                </div>
                <div class="tw-my-2 tw-text-300 tw-text-black tw-mb-2 tw-font-bold">
                    {{testimonial['name'] + ', ' + testimonial['location']}}
                </div>
            </div>
        </div>
        <div class="flex-footer shadow">
            <div class="w100 p15" (click)="redirectToRegimenPage()">
                <cs-button [class]="'w100 primary'"><cs-button-text i18n="@@getYourTreatment">Get your treatment kit</cs-button-text></cs-button>
            </div>
        </div>
    </div>
</div>