import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConnectionService } from '@services/connection-service';

@Component({ selector: 'order-summary-popup',
  templateUrl: './order-summary-popup.html' })
export class OrderSummaryPopupComponent {
  products: Array<any> = [];
  totalSP: number = 0;
  totalDiscount: number;
  totalMRP: number = 0;
  services: Array<any>;
  hideMedicine: boolean = false;
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
  @Input('data') data: any = {};
  @Input('experiments') experiments: any[];
  static parameters: any = [ConnectionService];
  constructor(private conn: ConnectionService) {
  }

  ngOnInit(): void {
    this.load(this.data);
  }

  async load(data: any): Promise<any> {
    const Config: any = data;
    await this.isRepairHideActive();
    this.products = [];
    this.totalDiscount = 0;
    this.totalMRP = Config.totalMRP;
    this.totalSP = Config.totalSP;
    this.products = Config.products;
    this.totalMRP = Math.floor(this.totalMRP);
    this.totalSP = Math.floor(this.totalSP);
    this.totalDiscount = Math.floor(this.totalMRP - this.totalSP);
    this.services = Config.services;
  }

  async isRepairHideActive(): Promise<any> {
    this.experiments.forEach((experiment: any): void => {
      if (experiment.key === 'hide_prescription_medicines') this.hideMedicine = true;
    });
  }

  closeDialog(): void {
    this.close.emit();
  }
}
