<div class="tw-block tw-border-spacing-0 tw-w-full tw-h-full tw-bg-black"
     *ngIf="!ui.openCamera && (canvas || nativeCameraImage)">
    <div class="tw-left-1/2 tw-z-[4] tw-absolute tw-transform -tw-translate-x-1/2 tw-w-full tw-h-[100px] tw-bg-transparent tw-bg-[linear-gradient(180deg,_#FF2C2C00_0%,_#F52A2A_100%)] tw-animate-scan_v2 tw-opacity-50"
         *ngIf="!ui.uploadingDone && tag.includes(appConfig.Shared.Regimen.Class.FACE)"></div>
    <div class="tw-flex tw-flex-col tw-flex-wrap tw-h-full tw-relative tw-max-[500px] tw-m-auto">
        <div class="tw-w-full tw-flex-initial tw-relative tw-z-10">
            <div class="tw-w-full tw-h-15 tw-text-white tw-bg-transparent">
                <div class="tw-w-15 tw-inline-flex tw-justify-center tw-items-center tw-float-left">
                    <em class="tw-w-10 tw-h-10 tw-text-[24px] tw-rounded-[50%] cs-icon-back-arrow"
                        rippleEffect="rippleEffect"
                        (callback)="back()"
                        rippleClass="ripple-white"></em>
                </div>
            </div>
        </div>
        <div class="tw-w-full tw-overflow-x-hidden tw-overflow-y-auto tw-flex-auto">
            <div
                 class="tw-w-full tw-h-[35%] tw-absolute tw-bg-[linear-gradient(to_bottom,_rgba(0,0,0,0.65),_rgba(255,255,255,0))] tw-top-0 tw-z-[2]">
            </div>
            <div
                 class="tw-w-full tw-h-[35%] tw-absolute tw-bg-[linear-gradient(to_top,_rgba(0,0,0,0.65),_rgba(255,255,255,0))] tw-bottom-0 tw-z-[2]">
            </div>
            <img class="tw-absolute tw-top-0 tw-h-full tw-left-1/2 tw-transform -tw-translate-x-1/2"
                 [src]="imageSrc" />
        </div>
        <div
             class="tw-absolute tw-z-[5] tw-bottom-8 tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-w-[250px] tw-text-center">
            <div class="tw-text-white tw-font-medium tw-pb-2 "
                 i18n="@@uploading..."> Uploading ... </div>
            <div
                 class="tw-w-full tw-h-[6px] tw-rounded-[10px] tw-border tw-border-solid tw-border-gray-300 tw-overflow-hidden">
                <div class="tw-animate-run tw-h-[6px] tw-bg-white"></div>
            </div>
            <div
                 class="tw-pt-4 tw-uppercase tw-text-white tw-font-medium tw-tracking-[2px] tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis tw-text-300">
                {{scanningText}}
            </div>
        </div>
    </div>
</div>
<camera *ngIf="ui.openCamera && !enableNativeCameraThroughWeb"
        (resultantCanvas)="cameraResult($event)"
        (close)="closeCamera(true)"></camera>