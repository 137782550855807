import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { RouterModule } from '@angular/router';
import { CartDetailStripComponent } from './cart-detail-strip.component';

@NgModule({
  imports: [CommonModule, RouterModule, ButtonModule],
  declarations: [CartDetailStripComponent],
  exports: [CartDetailStripComponent],
})
export class CartDetailStripModule {}
