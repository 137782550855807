import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';

import { IconComponent, ICON_PATH } from './icon.component';

@NgModule({
  imports: [CommonModule, HttpClientModule, MatIconModule],
  declarations: [IconComponent],
  exports: [IconComponent],
})
export class CsIconModule {
  static forRoot(config: { 'icon-path': string }): ModuleWithProviders<CsIconModule> {
    return {
      ngModule: CsIconModule,
      providers: [{
        provide: ICON_PATH,
        useValue: config['icon-path'],
      }],
    };
  }
}
