<div class="hbox bg-white font-noto">
  <loading class="pos-fix vh-center" *ngIf="!pages.length"></loading>
  <div class="flex-box">
  <div class="flex-heading">
    <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
    <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
      <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
    </div>
    <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@shareYourFeedback">Share your feedback</span>
    </div>
  </div>
  <div class="progress-bar" #progressBar="">
    <div class="coloured-bar" #colouredBar=""></div>
  </div>
  <div class="flex-content pos-rel" *ngIf="currQuestionIndex >= 0 && currentPageIndex >= 0">
    <div class="anim-fadeIn" *ngFor="let row of pages[currentPageIndex];">
    <div class="question">{{row.question.question}}</div>
    <div class="rating vh-center" *ngIf='row.question.type === "SCORE"'>
      <div class="w100">
      <div class="flex justify-between w100 tw-px-3">
        <span class="rating-text" i18n="@@notLikely">Not likely</span><span class="rating-text" i18n="@@extremelyLikely">Extremely likely</span>
      </div>
      <div class="flex flex-no-wrap">
        <div class="rating-box" *ngFor="let rating of ratingArray; let i = index" [ngClass]='{ "first-rating-box": i==0 }' (click)="saveScore(i, row)">
        <cs-icon class="rating-icon" [iconName]="rating"></cs-icon>
        <div class="rating-number">{{i}}</div>
        </div>
      </div>
      </div>
    </div>
    <div class="optionHolder" *ngIf='["SINGLE_SELECT"].includes(row.question.type)'>
      <div class="options" *ngFor="let option of row.question.options; let i = index" [ngClass]="{ 'anim-click': row.answers.includes(option), 'txt-clr-light': (row.answers.length && !row.answers.includes(option)) }" (click)="selectOption(option, row, $event)">
      {{option}}
      </div>
    </div>
    <div class="optionHolder" *ngIf='["MULTI_SELECT"].includes(row.question.type)' [ngClass]="{ 'multiSelect-holder': row.question.type === 'MULTI_SELECT' }">
      <div class="options" *ngFor="let option of row.question.options" [ngClass]="{ 'anim-click': row.answers.includes(option), 'btn-green': row.answers.includes(option) && row.question.uniqueId === 'likedFeatureArray', 'btn-red': row.answers.includes(option) && row.question.uniqueId === 'needImprovementArray', 'multiSelect-option': row.question.type === 'MULTI_SELECT' }" (click)="selectOption(option, row)">
      {{option}}
      </div>
    </div>
    <div class="optionHolder" *ngIf='row.question.type === "TEXT"'>
      <textarea class="w100" placeholder="Write us here" (keyup)="textFeedback($event, row)" i18n-placeholder="@@writeUsHere"></textarea>
    </div>
    <div class="optionHolder" *ngIf='row?.question?.type === "MULTI_SELECT" && row?.question?.category === "NPD"' [ngClass]="{ 'multiSelect-holder': row?.question?.type === 'MULTI_SELECT' }">
      <div class="tw-flex tw-flex-col tw-gap-1">
      <div class="options tw-flex tw-items-center tw-gap-2 tw-border tw-border-gray-100 tw-p-2 tw-pr-4 tw-rounded-2xl tw-max-w-full tw-justify-start" *ngFor="let product of products" [ngClass]="{ 'anim-click': row.answers.includes(product.objectId), 'btn-green': row.answers.includes(product.objectId) && row.question.uniqueId === 'productLike', 'btn-red': row.answers.includes(product.objectId) && row.question.uniqueId === 'productDislike', 'multiSelect-option': row.question.type === 'MULTI_SELECT' }" (click)="selectOption(product.objectId, row)">
        <img class="tw-h-10 tw-w-auto tw-rounded-md" [src]="product?.rebrandedImageWithBackground?.[0]" />
        <p class="tw-font-body tw-text-300">{{product.title}}</p>
      </div>
      </div>
    </div>
    </div>
  </div>
  <div class="flex-footer p20">
    <div class="text-center"></div>
    <div *ngIf="isLastPage()">
    <cs-button [class]="'primary w100'" (callback)="submitFeedback()" [disabled]="disableNextButton"><cs-button-text i18n="@@next" *ngIf="isMultiSelectPage()">Next</cs-button-text><cs-button-text i18n="@@submit" *ngIf="currentPageIndex === pages?.length - 1">Submit</cs-button-text></cs-button>
    </div>
  </div>
  </div>
</div>