<div class="font-noto tw-flex tw-items-center tw-relative tw-px-2 tw-py-4">
  <img class="tw-rounded-full tw-absolute tw-mb-20 tw-w-[120px] tw-z-[100] tw-top-[-60px] tw-left-0 tw-right-0 tw-m-auto" [src]="doctorProfileImageURL" (click)="handleClick()" />
  <div class="tw-absolute tw-p-1 tw-rounded-full tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-[15px] tw-right-[10px] close-sheet" style="width: 35px; height: 35px" (click)="closeBottomSheet()">
    <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
  </div>
  <div class="tw-w-full tw-bg-white rounded-lg tw-p-2 tw-flex tw-flex-col tw-pt-10" (click)="handleClick()">
    <div>
      <h2 class="tw-text-lg tw-font-semibold tw-text-700 tw-mt-4 tw-w-4/5 tw-m-auto tw-text-center" i18n="@@getYourSkinCheckUpReportFromYourDoctor">
        Get your skin checkup report from your doctor
      </h2>
    </div>
    <cs-button class="tw-my-2 tw-m-auto" [class]="'tw-m-auto primary btn btn-padding tw-px-6 btn-float tw-h-10 btn-xxxs'">
      <cs-button-text>
        <span class="tw-font-body tw-tracking-normal tw-text-xs" i18n="@@takeCheckupNow">Take Checkup Now</span>
        <span class="text-xs">&nbsp; ></span>
      </cs-button-text>
    </cs-button>
  </div>
</div>
