import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { ChoosePlanComponent } from './choose-plan.component';

@NgModule({ imports: [CommonModule,
  CsIconModule,
  ButtonModule,
],
declarations: [ChoosePlanComponent],
exports: [ChoosePlanComponent] })
export class ChoosePlanModule {}
