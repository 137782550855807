import { createSelector } from '@ngrx/store';
import { AppStateI } from '@store/reducers';
import * as Types from '@store/modals';
import * as fromRouteSelectors from '@store/selectors/router.selectors';
import { selectApp } from '@store/selectors/app.selectors';

export const selectSupportState = createSelector(
  selectApp,
  (appState: AppStateI): Types.SupportI => appState.support,
);
export const selectSupportCategory = createSelector(
  selectSupportState,
  (supportState: Types.SupportI): any[] => supportState.category,
);
export const selectSupportFetchInProcess = createSelector(
  selectSupportState,
  (supportState: Types.SupportI): boolean => supportState.inProcess,
);
export const selectSupportQuestionMap = createSelector(
  selectSupportState,
  (supportState: Types.SupportI): object => supportState.questionsByCategory,
);
export const selectSupportQuestions = createSelector(
  selectSupportState,
  (supportState: Types.SupportI): Array<any> => supportState.questions,
);
export const selectSupportQuestionById = createSelector(
  selectSupportQuestions,
  fromRouteSelectors.selectRouteParams,
  (supportQuestions: any[], routeParams: any): object => supportQuestions.find((each: any): any => each.objectId === routeParams.id));

export const selectRecentTicketState = createSelector(
  selectApp,
  (appState: AppStateI): Types.RecentSupportTicketI => appState.recentSupportTickets,
);
export const selectRecentTicketFetchInProcess = createSelector(
  selectRecentTicketState,
  (supportState: Types.RecentSupportTicketI): boolean => supportState.inProcess,
);
export const selectRecentTicketRefetchFlag = createSelector(
  selectRecentTicketState,
  (supportState: Types.RecentSupportTicketI): boolean => supportState.reFetch,
);
export const selectSupportClosedTicketList = createSelector(
  selectRecentTicketState,
  (supportState: Types.RecentSupportTicketI): Record<string, any>[] => supportState.closedTickets,
);
export const selectSupportOpenTicketList = createSelector(
  selectRecentTicketState,
  (supportState: Types.RecentSupportTicketI): Record<string, any>[] => supportState.openTickets,
);
export const selectAllSupportTicket = createSelector(
  selectRecentTicketState,
  (supportState: Types.RecentSupportTicketI): Record<string, any>[] => [...supportState.openTickets,
    ...supportState.closedTickets]);
