<div class="tw-flex tw-flex-col tw-bg-white tw-h-full font-noto">
  <loading class="tw-flex-1 tw-justify-self-center tw-self-center" *ngIf="loading"></loading>
  <div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
  </div>
  <div class="tw-flex-1 tw-pt-24 tw-px-6 tw-overflow-y-auto" *ngIf="!loading">
    <div>
      <div class="tw-font-body tw-font-bold tw-text-black tw-text-600" i18n="@@addAddress" *ngIf="!isEdit">Add your delivery address</div>
      <div class="tw-font-body tw-font-bold tw-text-black tw-text-600" i18n="@@yourDeliveryAddress" *ngIf="isEdit">Edit your address</div>
    </div>
    <div class="tw-text-200 tw-text-gray-400 tw-mb-4" i18n="@@addressDisclaimer">Complete address help you get your order faster</div>
    <address-form #form="" [(data)]="addressForm" [locationLoading]="isLocatingAddress" (updateLocationInfo)="getLocationDetails()"></address-form>
  </div>
  <div class="tw-flex-none tw-bottom-0 tw-left-0 tw-right-0 tw-p-6 tw-bg-white" *ngIf="!loading">
    <cs-button [class]="'tw-w-full tw-bg-white tw-border tw-border-gray-900 tw-rounded-[50px]'" *ngIf="!hideLocation" (callback)="getLocationDetails()" [disabled]="!addressForm" [loading]="isLocatingAddress">
      <cs-button-text class="tw-flex tw-justify-center">
        <span class=" tw-text-green-500" i18n="@@useMyCurrentLocation">Use current location</span>
      </cs-button-text>
    </cs-button>
    <cs-button class="tw-mt-4" [class]="'tw-w-full primary tw-text-white disabled:bg-white'" (click)="saveAddress()" [disabled]="!addressForm" [loading]="(saveInProcess$ | async)">
      <cs-button-text *ngIf="!isEdit" i18n="@@continue">Continue</cs-button-text>
      <cs-button-text *ngIf="isEdit" i18n="@@save">Save</cs-button-text>
    </cs-button>
  </div>
</div>
<pop-up *ngIf="popUpModal?.open" [class]="'tw-rounded-2.5 tw-p-4'" [show]="popUpModal?.open" [triggerDismiss]="true" (close)="closePopup()">
  <div>
    <div class="tw-mb-4 tw-font-bold tw-text-500" i18n="@@invalidPinTitle">Invalid Pincode</div>
    <div class="tw-mb-4 tw-text-400" i18n="@@invalidPincode">
      Pincode looks wrong, Do you want to autofill the correct pincode?
    </div>
    <div class="tw-flex tw-items-center tw-justify-end tw-flex-wrap">
      <div class="tw-w-1/2">
        <cs-button (click)="onClickYes()" [class]="'primary tw-w-full btn-xs'">
          <cs-button-text i18n="@@ok">Ok</cs-button-text>
        </cs-button>
      </div>
      <div class="tw-w-1/2">
        <cs-button (click)="closePopup()" [class]="'secondary only-text btn-xs tw-pl-0'">
          <cs-button-text i18n="@@no">No</cs-button-text>
        </cs-button>
      </div>
    </div>
  </div>
</pop-up>