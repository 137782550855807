import { Component, Output, Input, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'order-received',
  templateUrl: './order-received.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class OrderReceivedComponent implements OnInit {
  @Input('order') order: any;
  @Input('isRebrandingBanner') isRebrandingBanner: boolean;
  @Output('addEvents') addEvents: EventEmitter<any> = new EventEmitter();
  constructor() { }
  ngOnInit(): void { }
  handleClick(): void {
    this.addEvents.emit();
  }
}
