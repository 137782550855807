<div class="hbox font-noto">
  <div class="flex-box">
    <div class="flex-content pos-rel">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-relative tw-z-[100]">
        <div class="tw-fixed tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10 tw-z-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@testimonials">Testimonials</span>
      </div>
      <div class="tw-text-800 tw-mt-8 tw-pl-4" i18n="@@treatedFor">
        Treated for:
      </div>
      <div class="tw-text-800 tw-font-bold tw-pl-4">
        {{ testimonialCaseStudy['concern'] }}
      </div>
      <div class="tw-text-300 tw-mt-3 tw-pl-4 tw-mb-2">
        <span i18n="@@name">Name</span><span>:</span><span>&nbsp;</span><span class="tw-font-bold">{{ testimonialCaseStudy['name'] }}</span><span>|</span><span>&nbsp;</span><span i18n="@@place">Place</span><span>:</span><span>&nbsp;</span><span class="tw-font-bold">{{ testimonialCaseStudy['place'] }}</span>
      </div>
      <img class="tw-w-full tw-object-cover" [src]="testimonialImageUrl" />
      <div class="tw-bg-teal-100 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-px-6 tw-pb-6 tw-pt-12">
        <cs-icon class="tw-mb-1 tw-w-[22px]" [iconName]="'comma'"></cs-icon>
        <div class="tw-text-600 tw-font-bold w70">
          {{ testimonialCaseStudy['testimonial'] }}
        </div>
        <div class="tw-text-400 tw-my-2">
          {{ testimonialCaseStudy['story'] }}
        </div>
        <cs-icon class="w40" [iconName]="'stars'"></cs-icon>
      </div>
      <div class="tw-px-4">
        <div class="tw-text-400 tw-my-4">
          <span class="tw-font-bold" i18n="@@treatmentDuration">Treatment duration</span><span>:</span><span>&nbsp;</span><span>{{ testimonialCaseStudy['duration'] }}</span><span>&nbsp;</span><span>months</span>
        </div>
        <div class="tw-text-400 tw-my-4">
          <span class="tw-font-bold" i18n="@@checkupTaken">Checkups taken</span><span>:</span><span>&nbsp;</span><span>{{ testimonialCaseStudy['noOfCheckups'] }}</span>
        </div>
        <div class="tw-text-400 tw-my-4">
          <span class="tw-font-bold" i18n="@@dietConsultation">Diet consultation</span><span>:</span><span>&nbsp;</span><span>{{ testimonialCaseStudy['diet'] }}</span>
        </div>
        <div class="tw-text-400 tw-my-4">
          <span class="tw-font-bold" i18n="@@Tablets given">Tablets given</span><span>:</span><span>&nbsp;</span><span>{{ testimonialCaseStudy['tabletsGiven'] }}</span>
        </div>
      </div>
    </div>
    <div class="flex-footer shadow" *ngIf="hideRegimenCTA">
      <div class="w100 p15" (click)="redirectToRegimenPage()">
        <cs-button [class]="'w100 primary'"><cs-button-text i18n="@@getYourTreatment">Get your treatment kit</cs-button-text></cs-button>
      </div>
    </div>
  </div>
</div>