<div class="card tw-flex tw-flex-row tw-items-center tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 tw-bg-orange-100" (click)="handleClick()">
  <img class="tw-flex-none tw-h-20 tw-w-20 tw-rounded-full bg-black tw-bg-yellow-200" src="https://storage.googleapis.com/heallo-other-user-files/doctor_api_mahima_aggarwal_12052022_1670250785248.jpg" />
  <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-2">
    <div>
      <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@missedDoctorCall">
        Missed Doctor Call
      </p>
      <p class="tw-font-body tw-text-200 tw-text-black tw-text-xs" i18n="@@iTriedCallingYouButCouldnotConnect">
        I tried calling you, but we couldn't connect.
      </p>
    </div>
    <cs-button [class]="'primary btn-xxxs btn-xxxs-padding tw-px-2 btn-float'"><cs-button-text i18n="@@takeHelp">Take help ></cs-button-text></cs-button>
  </div>
</div>