import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { ButtonModule } from '@components/button';
import { ToolbarModule } from '@components/toolbar';
import { DirectivesModule } from '@directives/directives.module';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { SwiperModule } from 'swiper/angular';
import { RouterModule } from '@angular/router';
import { ReportCardModule } from '@shared/report-card/report-card.module';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { UserCheckupComponent } from './user-checkup.component';
import { UserFollowupComponent } from './user-followup/user-followup.component';
import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild([
      {
        path: 'checkup',
        canActivate: [authenticationGuard, onboardingGuard],
        component: UserCheckupComponent,
      },
    ]),
    CommonModule,
    ToolbarTabsModule,
    ButtonModule,
    LoadingModule,
    ToolbarModule,
    DirectivesModule,
    CsIconModule,
    RouterModule,
    ReportCardModule,
    SwiperModule,
    CustomPipeModule,
  ],
  declarations: [UserCheckupComponent, UserFollowupComponent],
  exports: [UserCheckupComponent, UserFollowupComponent],
})
export class UserCheckupModule { }
