<div class="card tw-flex tw-flex-row tw-items-center tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 tw-bg-orange-200 tw-mt-3" (click)="handleClick()">
  <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-2">
    <div>
      <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@yourCheckupIsDue">
        Your Checkup is due
      </p>
      <p class="tw-font-body tw-text-300 tw-text-black" i18n="@@doctorIsWaitingForYourPhoto">
        Doctor is waiting for your photo
      </p>
    </div>
    <cs-button [class]="'tw-tracking-wide primary btn-xxxs btn-xxxs-padding tw-px-2 btn-float'"><cs-button-text i18n="@@takeCheckupNow">Take Checkup Now</cs-button-text></cs-button>
  </div>
  <img class="tw-absolute tw-right-8 tw-bottom-0 tw-h-[150px]" *ngIf='followUp.get("allocatedDoctor")?.get("DoctorImageThumbnail")' [src]='followUp.get("allocatedDoctor")?.get("DoctorImageThumbnail")' />
</div>