import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'report-process',
  templateUrl: './report-process.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class ReportProcessComponent implements OnInit {
  @Output('trackEvent') trackEvent: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.trackEvent.emit('WAITING_FOR_REPORT_BANNER_CLICKED');
  }
}
