import { Injectable } from '@angular/core';
import { BroadcastService } from '../broadcast-service';

@Injectable()
export class CurrentComponentService {
  component: { constructor?: any, handleBackPress?: Function } = {};

  constructor(private broadcastService: BroadcastService) {}

  /**
   * Should be called when you want to handle back press on component level. Ideally in constructor()
   * Receives the active component and just extracts 2 properties ['constructor', 'handleBackPress'] and save it locally.
   * Binds component to 'handleBackPress' function.
   * @param component - active component to be set
   */
  set(component: any): void {
    const keysToCopy = ['constructor', 'handleBackPress'];
    keysToCopy.forEach((key: any): void => {
      this.component[key] = component[key];
      if (component[key] instanceof Function) this.component[key] = component[key].bind(component);
    });
  }

  /**
   * Should be called when you don't want to handle back press on component level. Ideally in ngOnDestroy()
   * Note: If one component is set, then it has to be removed by itself. In order to avoid cross mapping bugs between components.
   *       So that no other component handles the set component's handleBackPress fucntion.
   * @param component - component
   */
  remove(component: any): void {
    if (component && this.component.constructor.name.includes(component.constructor.name)) this.component = {};
  }
}
