// tslint:disable:max-line-length
/* eslint-disable max-len */
export const problemsCopy: { [key: string]: { [key: string]: { name: string, info: string, } } } = {
  en: {
    'pimple-region': { name: 'Inflammatory Acne', info: 'Red, pus-filled inflamed pores, often from hormones, poor diet, and stress, mainly during puberty.' },
    'come-region': { name: 'Comedones', info: 'Small, skin-colored bumps from blocked pores, due to excess oil from sebaceous glands.' },
    'darkspot-region': { name: 'Dark Spots', info: 'Dark spots on the face from prior acne exposed to sunlight, darker than surrounding areas.' },
    DarkCircle: { name: 'Dark Circles', info: 'Under-eye darkening, influenced by lifestyle and genetic factors, often due to inadequate sleep.' },
    'ascar-region': { name: 'Acne Scars', info: 'Scars remaining after severe or prolonged acne healing; important to control acne first.' },
    'oscar-region': { name: 'Other Scars', info: 'Scars from some injuries or chickenpox; reduced with proper skincare.' },
    PuffyEyes: { name: 'Puffy Eyes', info: 'Under-eye bags or swelling, worsened by sleep deprivation, vitamin deficiency, or allergies.' },
    PerioralPigmentation: { name: 'Perioral Pigmentation', info: 'Irregular dark spots around the mouth from injury, acne, or hormonal changes; easily treatable.' },
    OpenPores: { name: 'Open Pores', info: 'Visible large pores due to high oil production, aging, or sun damage; treatable to appear smaller.' },
    Melasma: { name: 'Melasma', info: 'Brown to grey-brown patches on the face, triggered by sun or hormonal changes; common in women.' },
    Freckles: { name: 'Freckles', info: 'Flat, circular, tan to black spots from genetics and sun exposure; controllable with sun protection.' },
    Hirsutism: { name: 'Facial Hair', info: 'Excessive dark or coarse hair growth in a male-like pattern on women\'s face, chest, and back.' },
  },
  hi: {
    'pimple-region': { name: 'इंफ्लेमेटरी एक्ने', info: 'लाल, सूजे हुए, या पस से भरे मुंहासे अक्सर हार्मोन, खराब डाइट, तनाव के कारण होते हैं' },
    'come-region': { name: 'कॉमेडोन', info: 'सीबम ग्लैंड्स से ज़्यादा ऑयल के कारण ब्लॉक्ड छिद्रों से छोटे, स्किन के रंग के उभार।' },
    'darkspot-region': { name: 'डार्क स्पॉट्स', info: 'सन एक्सपोज़र में आने वाले मुंहासों की वजह से डार्क स्पॉट्स, बाकी की स्किन की तुलना में गहरे होते हैं।' },
    DarkCircle: { name: 'काले घेरे', info: 'आंखों के नीचे कालापन, लाइफस्टाइल और जेनेटिक वजह से होता है, पर्याप्त नींद लेने के कारण भी होता है।' },
    'ascar-region': { name: 'एक्ने के निशान', info: 'गंभीर या लंबे समय तक रहे मुहासें के ठीक होने के बाद के निशान; मुंहासों को कंट्रोल करना अहम है।' },
    'oscar-region': { name: 'अन्य निशान', info: 'कुछ चोटों या चिकनपॉक्स के निशान; उचित स्किन केयर से कम हो जाते हैं।' },
    PuffyEyes: { name: 'सूजी हुई आँखें', info: 'आंखों के नीचे बैग या सूजन, नींद की कमी, विटामिन की कमी या एलर्जी से समस्या और बढ़ जाती है।' },
    PerioralPigmentation: { name: 'पेरिओरल पिगमेंटेशन', info: 'चोट, मुंहासे या हार्मोनल बदलाव से मुंह के आसपास अनियमित डार्क स्पॉट्स; आसानी से ठीक किये जा सकते हैं' },
    OpenPores: { name: 'ओपन पोर्स', info: 'ज़्यादा ऑयल प्रोडक्शन, उम्र बढ़ने या सन डैमेज से दिखने वाले बड़े पोर्स;  इलाज से छोटा किया जा सकता है' },
    Melasma: { name: 'मेलास्मा', info: 'चेहरे पर भूरे से ग्रे-भूरे धब्बे, धुप, हार्मोनल बदलावों से ट्रिगर होते हैं; महिलाओं में आम समस्या है' },
    Freckles: { name: 'झाइयाँ', info: 'जेनेटिक्स और सन एक्सपोज़र से फ्लैट, गोलाकार, काले धब्बे; इसे सन प्रोटेक्शन से कंट्रोल किया जा सकता है' },
    Hirsutism: { name: 'चेहरे पर बाल', info: 'महिलाओं के चेहरे, छाती और पीठ पर पुरुषों की तरह बहुत ज़्यादा काले या मोटे बाल उगना।' },
  },
  te: {
    'pimple-region': { name: 'ఇన్ఫ్లమేటరీ యాక్నే', info: 'ఎరుపు,చీముతో నిండి ఎర్రబడిన రంధ్రాలు,తరచుగా హార్మోన్లు/యుక్తవయస్సులో;సరైన ఆహారం,ఒత్తిడి వల్ల కావచ్చు' },
    'come-region': { name: 'కామెడోన్స్', info: 'సేబాషియస్ గ్రంధుల నుండి అదనపు నూనె వలన ఏర్పడిన నిరోధించబడిన రంధ్రాల నుండి చిన్న,చర్మం రంగు దద్దుర్లు' },
    'darkspot-region': { name: 'డార్క్ స్పాట్స్', info: 'ఎండ వల్ల ఏర్పడే ముందు మొటిమలు ముఖంపై నల్లటి మచ్చలు కలిగిస్తాయి, చుట్టు ఉన్న చర్మం కంటే నల్లగా ఉంటుంది' },
    DarkCircle: { name: 'నల్లటి వలయాలు', info: 'కంటి కింద నల్లబడటం,జీవన శైలి, జన్యుపరమైన కారకాల ప్రభావం, తరచుగా తగినంత నిద్ర లేమి వల్ల సంభవిస్తాయి.' },
    'ascar-region': { name: 'మొటిమల మచ్చలు', info: 'తీవ్రమైన లేదా దీర్ఘకాలంగా నయం అయిన తర్వాత ఉన్న మచ్చలు; ముందు, ఈ మొటిమలను నియంత్రించడం చాలా ముఖ్యం.' },
    'oscar-region': { name: 'ఇతర మచ్చలు', info: 'కొన్ని గాయాలు లేదా చికెన్ పాక్స్ నుండి వచ్చిన మచ్చలు; సరైన స్కిన్ కేర్ తో తగ్గుతుంది.' },
    PuffyEyes: { name: 'ఉబ్బెత్తు కళ్ళు', info: 'కంటి కింద వలయాలు లేదా వాపు, నిద్ర లేమి, విటమిన్ లోపం లేదా అలెర్జీల వల్ల తీవ్రమవుతుంది.' },
    PerioralPigmentation: { name: 'పెరియోరల్ పిగ్మెంటేషన్', info: 'గాయం, మొటిమలు లేదా హార్మోన్ల మార్పుల వల్ల నోటి చుట్టూ అసమానమైన నల్ల మచ్చలు;సులభంగా చికిత్స చేయవచ్చు.' },
    OpenPores: { name: 'ఓపెన్ పోర్స్', info: 'అధిక చమురు, వృద్ధాప్యం, ఎండ వలన పెద్ద రంధ్రాలు కనిపిస్తాయి; చిన్నగా కనిపించడానికి చికిత్స చేయవచ్చు.' },
    Melasma: { name: 'మెలస్మా', info: 'ముఖంపై గోధుమ నుండి బూడిద-గోధుమ మచ్చలు,సూర్యుడు లేదా హార్మోన్ల వలన సంభవిస్తాయి;మహిళల్లో సాధారణ సమస్య.' },
    Freckles: { name: 'మచ్చలు', info: 'జెనెటిక్స్,ఎండ వల్ల చదునైన,వృత్తాకారంలో,టాన్ నుండి డార్క్ స్పాట్స్;సూర్య రక్షణతో ఇది నియంత్రించవచ్చు' },
    Hirsutism: { name: 'ముఖం పై వెంట్రుకలు', info: 'స్త్రీలు పురుషుల వలె ముఖం, ఛాతీ మరియు వీపుపై అధిక నలుపు లేదా మందపాటి జుట్టును పెంచుతారు.' },
  },
  kn: {
    'pimple-region': { name: 'ಇಂಪ್ಲಾಮೇಟರಿ ಏಕ್ನೆ', info: 'ಕೆಂಪು, ಪಸ್ ತುಂಬಿದ ಇನ್ಫ್ಲೇಮ್ಡ್ ಪೋರ್ಸ್, ಹಾರ್ಮೋನ್/ ಪ್ಯುಬರ್ಟಿ ಸಮಯದಲ್ಲಿ; ಕಳಪೆ ಆಹಾರ, ಒತ್ತಡದಿಂದ ಉಂಟಾಗಬಹುದು' },
    'come-region': { name: 'ಕಾಮೆಡೋನ್ಸ್', info: 'ಬ್ಲಾಕ್ಡ್ ಪೋರ್ಸ್ ಇಂದ ಸಣ್ಣ, ಚರ್ಮದ ಬಣ್ಣದ ಉಬ್ಬು. ಸೆಬಾಸಿಯಸ್ ಗ್ಲ್ಯಾಂಡ್ಸ್ ಇಂದ ಹೆಚ್ಚುವರಿ ಎಣ್ಣೆಯ ಕಾರಣದಿಂದಾಗಿ' },
    'darkspot-region': { name: 'ಡಾರ್ಕ್ಸ್ ಸ್ಪಾಟ್ಸ್', info: 'ಬಿಸಿಲಿಗೆ ಒಡ್ಡಿಕೊಂಡ ಮೊದಲು ಮೊಡವೆಗಳಿಂದ ಮುಖದ ಮೇಲೆ ಡಾರ್ಕ್ ಸ್ಪಾಟ್ಸ್, ಸುತ್ತಮುತ್ತಲಿನ ಜಾಗಗಳಿಗಿಂತ ಗಾಢವಾಗಿರುತ್ತವೆ.' },
    DarkCircle: { name: 'ಡಾರ್ಕ್ ಸರ್ಕಲ್ಸ್', info: 'ಕಣ್ಣಿನ ಅಡಿಯಲ್ಲಿ ಕಪ್ಪಾಗುವುದು ಸಾಮಾನ್ಯವಾಗಿ ಸರಿಯಿಲ್ಲದ ನಿದ್ದೆ   ಜೀವನಶೈಲಿ ಮತ್ತು ಜೆನೆಟಿಕ್ ಅಂಶಗಳಿಂದ ಆಗುತ್ತದೆ' },
    'ascar-region': { name: 'ಏಕ್ನೆ ಸ್ಕಾರ್ಸ್', info: 'ತೀವ್ರವಾದ ಅಥವಾ ದೀರ್ಘಕಾಲದ ಮೊಡವೆ ವಾಸಿಯಾದ ನಂತರ ಉಳಿದಿರುವ ಚರ್ಮ; ಮೊದಲು ಮೊಡವೆಗಳನ್ನು ನಿಯಂತ್ರಿಸುವುದು ಮುಖ್ಯ.' },
    'oscar-region': { name: 'ಇತರ ಗುರುತುಗಳು', info: 'ಕೆಲವು ಗಾಯಗಳು ಅಥವಾ ಚಿಕನ್ ಇಂದ ಚರ್ಮ; ಸರಿಯಾದ ಸ್ಕಿನ್ ಕೇರ್ ನಿಂದ ಕಡಿಮೆಯಾಗುವುದು.' },
    PuffyEyes: { name: 'ಪಫಿ ಐಸ್', info: 'ಕಣ್ಣಿನ ಕೆಳಗಿರುವ ಬಾಗ್ಸ್ ಅಥವಾ ಊತ, ನಿದ್ರೆಯ ಅಭಾವ, ವಿಟಮಿನ್ ಕೊರತೆ, ಅಥವಾ ಅಲರ್ಜಿಗಳಿಂದ ಹೆಚ್ಚಾಗುತ್ತದೆ.' },
    PerioralPigmentation: { name: 'ಪೆರಿಯೊರಲ್ ಪಿಗ್ಮೆಂಟೇಶನ್', info: 'ಗಾಯ, ಮೊಡವೆ ಅಥವಾ ಹಾರ್ಮೋನ್ ಬದಲಾವಣೆಯಿಂದ ಬಾಯಿಯ ಸುತ್ತ ಇರೆಗ್ಯುಲರ್ ಡಾರ್ಕ್ ಸ್ಪಾಟ್ಸ್; ಟ್ರೀಟ್ ಮಾಡಬಹುದು.' },
    OpenPores: { name: 'ಓಪನ್ ಪೋರ್ಸ್', info: 'ಹೆಚ್ಚಿನ ತೈಲ ಉತ್ಪಾದನೆ, ಏಜಿಂಗ್ ಅಥವಾ ಬಿಸಿಲಿನಿಂದಾಗಿ ಪೋರ್ಸ್ ಕಾಣಿಸುತ್ತವೆ; ಚಿಕ್ಕದಾಗಿ ಕಾಣಿಸಲು ಟ್ರೀಟ್ಮೆಂಟ್ ಇದೆ.' },
    Melasma: { name: 'ಮೆಲಸ್ಮಾ', info: 'ಮುಖದಲ್ಲಿ ಬ್ರೌನ್, ಗ್ರೇ ಬ್ರೌನ್ ಪ್ಯಾಚಸ್, ಬಿಸಿಲು, ಹಾರ್ಮೋನ್ ಬದಲಾವಣೆಯಿಂದ ಆಗುತ್ತದೆ ; ಮಹಿಳೆಯರಲ್ಲಿ ಸಾಮಾನ್ಯ.' },
    Freckles: { name: 'ನಸುಕಂದು ಮಚ್ಚೆಗಳು', info: 'ಜೆನೆಟಿಕ್ಸ್ , ಬಿಸಿಲಿನಿಂದ ಚಪ್ಪಟೆ, ವೃತ್ತಾಕಾರ, ಕಂದುಬಣ್ಣದ ಬ್ಲಾಕ್ ಸ್ಪಾಟ್ಸ್ ; ಸೂರ್ಯನ ರಕ್ಷಣೆಯಿಂದ ತಡೆಯಬಹುದು.' },
    Hirsutism: { name: 'ಮುಖದ ಕೂದಲು', info: 'ಮಹಿಳೆಯರ ಮುಖ, ಎದೆ ಮತ್ತು ಬೆನ್ನಿನ ಮೇಲೆ ಪುರುಷ ಮಾದರಿಯಲ್ಲಿ ಅತಿಯಾದ ಕಪ್ಪು ಅಥವಾ ಒರಟಾದ ಕೂದಲು ಬೆಳವಣಿಗೆ.' },
  },
  ta: {
    'pimple-region': { name: 'இன்ஃப்ளமேட்டரி அக்னே ', info: 'சிவப்பு, சீழுள்ள வீங்கிய போர்,  ஹார்மோனால்/பருவமடைதலால் ஏற்படும்; சத்தில்லா உணவு, மன அழுத்தம் காரணம்' },
    'come-region': { name: 'கொமெடோன்கள்', info: 'செபாசியஸ் சுரப்பிகளிலிருந்து அதிக எண்ணெய் உற்பத்தியினால், அடைபட்ட சரும போரிலிருந்து சிறிய புடைப்பு.' },
    'darkspot-region': { name: 'கரும்புள்ளிகள் ', info: 'சூரிய ஒளியில் படும் முகப்பருவினால் ஏற்படும் கரும்புள்ளி, சுற்றியுள்ள பகுதியைவிட கருப்பாக இருக்கும்.' },
    DarkCircle: { name: 'கரு வளையங்கள்', info: 'கண்ணுக்கு கீழ் கருவளையம், வாழ்க்கைமுறை, மரபணு, மற்றும் போதுமான தூக்கமின்மை காரணமாகவும் ஏற்படலாம்.' },
    'ascar-region': { name: 'முகப்பரு வடுக்கள்', info: 'தீவிர/நீடித்த முகப்பரு சிகிச்சைக்குப் பிறகுள்ள வடு; முதலில் முகப்பருவை கட்டுப்படுத்துவது முக்கியம்.' },
    'oscar-region': { name: 'பிற வடுக்கள்', info: 'சில காயங்கள் அல்லது அம்மை நோயினால் ஏற்பட்டுள்ள வடு; சரியான சரும பராமரிப்பு மூலம் குறைக்கப்படும்.' },
    PuffyEyes: { name: 'வீக்கமான கண்கள்', info: 'தூக்கமின்மை, வைட்டமின் குறைபாடு/ ஒவ்வாமை ஆகியவற்றால் கண்ணுக்கு கீழுள்ள கருவளையம்/வீக்கம் மோசமடையும்.' },
    PerioralPigmentation: { name: 'பீரியோரல் பிக்மென்டேஷன்', info: 'காயம், முகப்பரு/ஹார்மோன் மாற்றங்களால் வாயைச் சுற்றி கரும்புள்ளிகள்; எளிதில் குணமடையச் செய்யமுடியும்.' },
    OpenPores: { name: 'திறந்த சரும துளைகள்', info: 'எண்ணெய் உற்பத்தி, வயதாகுதல், சூரிய பாதிப்பினால் ஏற்படும் பெரிய சரும போர்; சிறியவற்றை குணமாக்கலாம்' },
    Melasma: { name: 'மெலஸ்மா', info: 'பிரவுன், க்ரே-பிரவுன் முகப் பேட்ச், சூரிய ஒளி வெளிப்பாடு/ஹார்மோன் காரணம்; பெண்களில் அதிகம் ஏற்படும்' },
    Freckles: { name: 'முகப்புள்ளிகள்', info: 'மரபியல் மற்றும் சூரிய ஒளியினால் ஏற்படும் ஃபிளாட், வட்ட, பிரவுன்/கரும்புள்ளி; சூரியஒளியை தவிர்க்கணும்' },
    Hirsutism: { name: 'முகத்தில் முடி வளர்ச்சி', info: 'பெண்களின் முகம், மார்பு மற்றும் முதுகில் ஆண் போல அதிக கருமுடி அல்லது முரட்டுத்தனமான முடி வளர்ச்சி.' },
  },
  ml: {
    'pimple-region': { name: 'വീങ്ങിയിരിക്കുന്ന മുഖക്കുരു', info: 'ചുവന്ന, പഴുപ്പുള്ള വീങ്ങിയ സുഷിരങ്ങൾ, ഹോർമോൺ / ആർത്തവാരംഭത്തിൽ; മോശം ഡയറ്റ്, സമ്മർദ്ദം കൊണ്ടാകാം' },
    'come-region': { name: 'കൊമെഡോണുകൾ / ചെറിയ മുഴകൾ അല്ലെങ്കിൽ വീക്കം', info: 'അടഞ്ഞ സുഷിരങ്ങളിൽ സെബേഷ്യസ് ഗ്രന്ഥികളിൽ നിന്നുള്ള അമിത എണ്ണ മൂലമുള്ള ചെറിയ, ചർമ്മ നിറത്തിലെ മുഴകൾ.' },
    'darkspot-region': { name: 'കറുത്ത പുള്ളികൾ', info: 'സൂര്യപ്രകാശം ഏൽക്കുന്ന മുൻപുള്ള മുഖക്കുരു, ചുറ്റുമുള്ളതിനേക്കാൾ നിറം കുറഞ്ഞ മുഖത്തെ കറുത്ത പാടുകൾ.' },
    DarkCircle: { name: 'കറുത്ത വളയങ്ങൾ', info: 'ജീവിത ശൈലി, പാരമ്പര്യ ഘടകങ്ങൾ മൂലം, മിക്കവാറും ഉറക്കക്കുറവ് കാരണമുള്ള കണ്ണിന് അടിയിലെ കറുപ്പ്.' },
    'ascar-region': { name: 'മുഖക്കുരുവിന്റെ പാടുകൾ', info: 'ഗുരുതരമോ നീണ്ട നാളത്തെയോ മുഖക്കുരു ഉണങ്ങി ബാക്കിയായ പാടുകൾ;മുഖക്കുരു നിയന്ത്രിക്കാൻ ഏറ്റവും പ്രധാനം.' },
    'oscar-region': { name: 'മറ്റ് പാടുകൾ', info: 'അപകടങ്ങൾ അല്ലെങ്കിൽ ചിക്കൻ പോക്സ് മൂലമുണ്ടായ പാടുകൾ; കൃത്യമായ ചർമ്മ സംരക്ഷണം കൊണ്ട് കുറയുന്നത്.' },
    PuffyEyes: { name: 'വീർത്ത കണ്ണുകൾ', info: 'കണ്ണിന് താഴെ വീക്കം അല്ലെങ്കിൽ വീർപ്പ്; ഉറക്കക്കുറവോ വിറ്റാമിൻ അഭാവമോ അലർജിയോ മൂലം ഗുരുതരമായത്.' },
    PerioralPigmentation: { name: 'മുഖത്തിന് ചുറ്റുമുള്ള ചർമ്മത്തിന്റെ നിറം മങ്ങൽ', info: 'അപകടമോ കുരുവോ ഹോർമോൺ മാറ്റങ്ങളോ മൂലം മുഖത്തിന് ചുറ്റും ക്രമരഹിത കറുത്ത പാടുകൾ; എളുപ്പം ചികിൽസിക്കാം.' },
    OpenPores: { name: 'തുറന്ന സുഷിരങ്ങൾ', info: 'അമിത എണ്ണ ഉത്പാദനമോ, പ്രായമോ സൂര്യാഘാതമോ കൊണ്ടുള്ള വലിയ സുഷിരങ്ങൾ; ചെറുതായി കാണപ്പെടാൻ ചികിൽസിക്കാം.' },
    Melasma: { name: 'കരിമാംഗല്യം', info: 'സൂര്യനോ ഹോർമോൺ മാറ്റങ്ങളോ കൊണ്ട്, മുഖത്തെ ബ്രൗൺ, ചാര-ബ്രൗൺ നിറം; സ്ത്രീകളിൽ സാധാരണ കാണപ്പെടുന്നത്.' },
    Freckles: { name: 'പുള്ളികൾ', info: 'പാരമ്പര്യവും സൂര്യാഘാതവും മൂലം പരന്ന, ഉരുണ്ട,ടാനോ കറുപ്പോ പാടുകൾ; വെളിച്ചമേൽക്കാതെ നിയന്ത്രിക്കാം.' },
    Hirsutism: { name: 'മുഖത്തിലെ രോമം', info: 'സ്ത്രീകളുടെ മുഖത്തോ, മാറിടത്തിലോ, പുറകിലോ പുരുഷന്മാരെ പോലെ വളരെ കറുത്തതോ പരുക്കാനോ ആയ മുടി വളർച്ച.' },
  },
  bn: {
    'pimple-region': { name: 'ফুলে ওঠা জ্বালাযুক্ত ব্রণ', info: 'লাল, পুঁজ, ব্যথাযুক্ত ছিদ্র, হরমোনের কারণে/বয়ঃসন্ধির সময় হয়; খারাপ ডায়েট, স্ট্রেসের ফলে হতে পারে' },
    'come-region': { name: 'কোমেডন / ছোট ছোট গোটা বা ফুলে যাওয়া', info: 'সিবাসিয়াস গ্ল্যান্ডের থেকে অতিরিক্ত তেলের কারণে ব্লক হওয়া লোমকূপের থেকে ছোট, ত্বকের রঙের ফুসকুড়ি।' },
    'darkspot-region': { name: 'কালো ছোপ / দাগ', info: 'আগে হওয়া ব্রণ যা সূর্যের সংস্পর্শে আসার কারণে মুখে কালো দাগ তৈরি করে, যা আশেপাশের ত্বকের চেয়ে কালো।' },
    DarkCircle: { name: 'ডার্ক সার্কেল', info: 'লাইফস্টাইল এবং জিনগত কারণে চোখের নিচে কালো দাগ, যা প্রায়ই পর্যাপ্ত ঘুম না হওয়ার কারণে হয়।' },
    'ascar-region': { name: 'ব্রণর দাগ', info: 'গুরুতর ব্রণ বা অনেক সময় ধরে থাকার পরে নিরাময় হওয়া ব্রণর দাগ; সবার আগে ব্রণকে নিয়ন্ত্রণ করা জরুরি।' },
    'oscar-region': { name: 'অন্য ক্ষতচিহ্ন', info: 'কোনো আঘাত বা চিকেনপক্সের দাগ; সঠিকভাবে ত্বকের যত্ন নিলে কমে যায়।' },
    PuffyEyes: { name: 'ফোলা চোখ', info: 'চোখের নিচে চামড়া ঝুলে বা ফুলে যাওয়া, ঘুমের অভাব, ভিটামিনের ঘাটতি বা অ্যালার্জির ফলে সমস্যা বাড়ে।' },
    PerioralPigmentation: { name: 'পেরিওরাল পিগমেন্টেশন', info: 'আঘাত, ব্রণ বা হরমোনের পরিবর্তনের কারণে মুখের চারপাশে অসমান কালো দাগ; একে সহজেই ঠিক করা যায়।' },
    OpenPores: { name: 'ওপেন পোর / খোলা লোমকূপ+', info: 'বেশি তেল তৈরি, বার্ধক্য, সূর্য থেকে ক্ষতির ফলে হওয়া বড় ছিদ্র; চিকিত্‍সা করলে ছিদ্র ছোট হতে পারে।' },
    Melasma: { name: 'মেলাসমা / মেচেতা', info: 'মুখে বাদামী বা ধূসর-বাদামী দাগ যা সূর্যের আলো, হরমোনের পরিবর্তনের ফলে হয়; মহিলাদের মধ্যে এটা সাধারণ।' },
    Freckles: { name: 'ফ্রেকলস', info: 'জিনগত, রোদের কারণে হওয়া চ্যাপ্টা, গোলাকার, ট্যান, কালো দাগ; সান প্রোটেকশন দিয়ে নিয়ন্ত্রণ করা যায়।' },
    Hirsutism: { name: 'মুখের লোম', info: 'মহিলাদের মুখে, বুকে এবং পিঠে পুরুষদের মতো অত্যধিক কালো বা ঘন চুল গজায়।' },
  },
  mr: {
    'pimple-region': { name: 'इंफ्लेमेटरी एक्ने', info: 'लाल, पू आणि दुखणारे छिद्र, हार्मोनल/ तरुण्या दरम्यान उद्भवते; हे अयोग्य आहार, तणाव यामुळे होते.' },
    'come-region': { name: 'कॉमेडोन', info: 'छोटे, सेबेशियस ग्रंथींच्या अतिरिक्त तेलामुळे, ब्लॉक केलेल्या छिद्रांमधून त्वचेच्या रंगाचे अडथळे.' },
    'darkspot-region': { name: 'डार्क स्पॉट्स', info: 'उन्हाच्या संपर्कात आल्याने जुन्या मुरुमांपासून चेहऱ्यावर काळे डाग, इतर भागापेक्षा जास्त गडद असतात.' },
    DarkCircle: { name: 'काळी वर्तुळे', info: 'लाईफस्टाइल आणि जेनेटिक घटकांमुळे डोळ्यांखालील काळे होते, अनेकदा अपुऱ्या झोपेमुळे सुद्धा हे होते.' },
    'ascar-region': { name: 'मुरुमांचे चट्टे', info: 'गंभीर किंवा जुने मुरुम बरे झाल्यावर त्यांचे चट्टे; म्हणून आधी मुरुमांवर कंट्रोल ठेवणे महत्त्वाचे आहे' },
    'oscar-region': { name: 'इतर डाग/ इतर चट्टे', info: 'काही जखमांमुळे किंवा कांजण्यांमुळे चट्टे; योग्य स्किनकेअरसह कमी होतात.' },
    PuffyEyes: { name: 'सुजलेले डोळे', info: 'डोळ्यांखाली सूज येणे, झोप न लागणे, जीवनसत्वाची कमतरता किंवा ऍलर्जीमुळे ही समस्या आणखी वाढली आहे.' },
    PerioralPigmentation: { name: 'पेरिओरल पिगमेंटेशन', info: 'दुखापत, पुरळ किंवा हार्मोनल बदलांमुळे तोंडाभोवती अनियमित गडद ठिपके; सहज बरे करता येते.' },
    OpenPores: { name: 'ओपन पोर्स', info: 'जास्त ऑइल प्रोडक्शन, वृद्धत्व किंवा अति उन्हामुळे दिसणारी मोठी  छिद्र योग्य उपचाराने लहान करता येतात' },
    Melasma: { name: 'मेलास्मा', info: 'चेहऱ्यावर तपकिरी ते राखाडी-तपकिरी ठिपके, ऊन किंवा हार्मोनल बदलांमुळे ट्रिगर होतात, ' },
    Freckles: { name: 'डाग', info: 'जेनेटिक घटक व उन्हामुळे सपाट, गोलाकार, गडद ठिपके होतात; हे सन प्रोटेक्शन द्वारे कंट्रोल  करता येतात.' },
    Hirsutism: { name: 'चेहऱ्यावरील केस ', info: 'महिलांच्या चेहऱ्यावर, छातीवर आणि पाठीवर पुरूषांप्रमाणेच जास्त काळसर किंवा जाड केसांची वाढ होते.' },
  },
};

export const lesionPlaceHolder: any = {
  'Inflammatory Acne': 'Acne is an inflammatory skin condition resulting from blocked hair follicles, often aggravated by hormonal fluctuations, affecting individuals across various life stages.',
  'Dark Spots': 'Dark spots, or hyperpigmentation, are patches of skin that appear darker than the surrounding area, often due to factors like sun exposure, aging, or hormonal changes.',
  'Open Pores': 'Open pores refer to visibly enlarged skin pores, typically caused by factors like excessive oil production, aging, and environmental damage.',
  'Acne Scars': 'Acne scars are skin blemishes that remain after acne breakouts, formed due to the skin\'s healing process and can vary in appearance based on their origin and individual skin factors.',
  Comedones: 'Comedones are skin blemishes formed by clogged hair follicles. They can be open (blackheads) or closed (whiteheads), and are universally experienced, especially in oil-prone skin.',
  'Dark Circles': 'Dark circles are discolorations under the eyes, commonly due to factors such as fatigue, aging, or genetics, and are universally experienced across age groups.',
  Melasma: 'Melasma is a skin condition where brown to gray-brown patches appear, often due to hormonal changes or sun exposure, affecting diverse groups, especially those with darker skin tones.',
};

export const concernSeverityTranslations: { [key: string]: { [key: string]: string } } = {
  en: {
    Mild: 'Mild',
    Moderate: 'Moderate',
    Severe: 'Severe',
  },
  hi: {
    Mild: 'हल्की गंभीरता',
    Moderate: 'मध्यम गंभीरता',
    Severe: 'गंभीर',
  },
  te: {
    Mild: 'తేలికపాటి తీవ్రత',
    Moderate: 'మితమైన తీవ్రత',
    Severe: 'తీవ్రమైన',
  },
  kn: {
    Mild: 'ಸೌಮ್ಯ ತೀವ್ರತೆ',
    Moderate: 'ಮಧ್ಯಮ ತೀವ್ರತೆ',
    Severe: 'ತೀವ್ರ',
  },
  ta: {
    Mild: 'லேசான தீவிரம்',
    Moderate: 'மிதமான தீவிரம்',
    Severe: 'கடுமையான',
  },
  ml: {
    Mild: 'Mild',
    Moderate: 'Moderate',
    Severe: 'Severe',
  },
  bn: {
    Mild: 'Mild',
    Moderate: 'Moderate',
    Severe: 'Severe',
  },
  mr: {
    Mild: 'सौम्य तीव्रता',
    Moderate: 'मध्यम तीव्रता',
    Severe: 'गंभीर',
  },
};
