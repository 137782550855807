import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ConnectionService } from '@services/connection-service';
import { Store } from '@ngrx/store';
import { mergeMap, Observable } from 'rxjs';
import { fromActions } from '@store/actions';
import { catchError, map } from 'rxjs/operators';
import { fromSelectors } from '@store/selectors';
import * as Types from '@store/modals';
import { RequestQueryPayload } from 'api-client';

@Injectable()
export class SupportEffects {
  constructor(
    private actions$: Actions,
    private connectionService: ConnectionService,
    private store: Store) {
  }

  getSupportQuestionFetch$: Observable<unknown> = createEffect((): Observable<any> => this.actions$.pipe(
    ofType(fromActions.SupportQuestionsFetchBegin),
    mergeMap(async (
      params: { query: RequestQueryPayload<Table.SupportQuestion>;
        type: '[Support Question] Support Question Fetch Begin';
        context: Record<string, unknown>; })
      : Promise<[RequestQueryPayload<Table.SupportQuestion>, Array<any>]> => {
      const questions = (await this.connectionService.fetchSupportQuestions(params.query, params.context))
        .map((each: any): any => each.toJSON());
      return [params.query, questions];
    }),
    map(([query, questions]: [RequestQueryPayload<Table.SupportQuestion>, Array<any>]): any => fromActions.SupportQuestionsFetchUpdate(
      { query, questions, error: undefined })),
    catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
      this.store.dispatch(fromActions.SupportQuestionsFetchUpdate({ query: undefined, questions: undefined, error }));
      return caught;
    }),
  ));

  getSupportQuestionFromStore$: Observable<unknown> = createEffect((): Observable<any> => this.actions$.pipe(
    ofType(fromActions.GetSupportQuestionFromStore),
    concatLatestFrom((): Array<Observable<unknown>> => [this.store.select(fromSelectors.selectSupportState)]),
    map(([params, supportState]: [
      { query: RequestQueryPayload<Table.SupportQuestion>; type: '[Support Question] Get Support Question From Store' },
      Types.SupportI,
    ]): void => {
      if (!supportState.questions) {
        this.store.dispatch(fromActions.SupportQuestionsFetchBegin(params));
      }
    }),
  ), { dispatch: false });
}
