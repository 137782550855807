import { createAction, props } from '@ngrx/store';
import * as Types from '../modals';

export const ConfigUpdate = createAction('[ConfigI] Update', props<Types.ConfigI>());
export const ExperimentsUpdate = createAction('[Experiments] Update', props<{ experiments: any[] }>());

export const HomePageUpdate = createAction('[Home Page] Update', props<Partial<Types.HomePageDataI>>());
export const HomePageAddonBegin = createAction('[Home Page] Addon Fetch Begin', props<{ payload: object }>());
export const HomePageBlogsBegin = createAction('[Home Page] Blogs Fetch Begin', props<{ payload: object }>());
export const HomePageReorderBegin = createAction('[Home Page] Reorder Fetch Begin', props<{ payload: object }>());

export const AddressBookUpdate = createAction('[Address Book] Update', props<{ payload: Partial<Types.AddressBookStateI> }>());
export const AddressBookListFetchBegin = createAction('[Address Book] List Fetch Begin', props<{ fromCache?: boolean }>());
export const AddressBookDefaultFetchBegin = createAction('[Address Book] Default Fetch Begin');
export const AddressBookDeleteBegin = createAction('[Address Book] Delete Begin', props<{ address: Types.AddressBookI }>());
export const AddressBookSetDeliveryAddress = createAction('[Address Book] Set Default', props<{ deliveryAddress: Types.AddressBookI }>());
export const AddressBookSaveBegin = createAction('[Address Book] Save Begin', props<{ addressId: string, address: Types.AddressBookI }>());
export const AddressBookUpdateOrderAddress = createAction('[Address Book] Update Order Address',
  props<{ orderId: string, deliveryAddress: Types.AddressBookI }>());

export const OrderStateUpdate = createAction('[Order State] Update', props<Partial<Types.OrderSateI>>());
export const OrderFetchPaymentBegin = createAction('[Order] Fetch Payment Begin');
export const OrderRetryInCodMode = createAction('[Order] Retry COD Begin');
export const OrderRetryOnlinePayment = createAction('[Order] Retry Online Payment Begin');
export const OrderSuccessBegin = createAction('[Order] Success Begin');
export const OrderFetchBegin = createAction('[Order] Fetch Begin');

export const SupportCategoryFetchBegin = createAction('[Support Category] Fetch Begin');
export const SupportStateUpdate = createAction('[Support State] Update', props<Partial<Types.SupportI>>());
export const SupportUpdateFeedback = createAction('[Support Feedback] Update', props<{ isHelpfull: boolean, questionId: string }>());

export const SupportRecentTicketFetchBegin = createAction('[Query] Fetch', props<{ status?: Array<string> }>());
export const SupportRecentTicketUpdate = createAction('[Query] Update', props<Partial<Types.RecentSupportTicketI>>());

export const CartUpdateProductsBegin = createAction('[Cart] Update Begin', props<Partial<Types.CartI>>());
