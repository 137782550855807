import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { CallUsModule } from '@components/call-us';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { PopUpModule } from '@components/pop-up';
import { SingleSelectorModule } from '@shared/single-selector/single-selector.module';
import { CsIconModule } from '@components/icon';
import { SupportComponentSelectionModule } from '@shared/support-component-selection/support-component-selection.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NotificationPermissionSheetModule } from
  '@shared/bottom-sheet-layouts/notification-permission-sheet/notification-permission.module';
import { SupportComponent } from './support.component';
import { SupportTicketListComponent } from './support-ticket-list/support-ticket-list.component';
import { ImageCardModule } from '../user/user-view/user-assistant/user-chat/components/image-card/image-card.module';
import { AudioModule } from '../user/user-view/user-assistant/user-chat/components/audio/audio.module';
import { MultiImagesModule } from '../user/user-view/user-assistant/user-chat/components/multi-images/multiImages.module';
import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';
import { ChatV2Component } from '../chatV2/chatV2.component';
import { ChatV2Module } from '../chatV2/chatV2.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: 'support',
      canActivate: [authenticationGuard, onboardingGuard],
      children: [
        {
          path: '',
          component: SupportComponent,
        },
        {
          path: 'ticket/:id',
          component: ChatV2Component,
        },
        {
          path: 'tickets',
          component: SupportTicketListComponent,
        },
        {
          path: ':id',
          component: SupportComponent,
        },
      ],
    }]),
    FormsModule,
    DirectivesModule,
    ToolbarModule,
    LoadingModule,
    PopUpModule,
    CallUsModule,
    ImageCardModule,
    ButtonModule,
    AudioModule,
    MultiImagesModule,
    SingleSelectorModule,
    SupportComponentSelectionModule,
    CsIconModule,
    MatBottomSheetModule,
    NotificationPermissionSheetModule,
    ChatV2Module,
  ],
  declarations: [SupportComponent, SupportTicketListComponent],
})
export class SupportModule { }
