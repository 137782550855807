import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromSelectors } from '@store/selectors';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ConnectionService } from '@services/connection-service';
import { Router } from '@angular/router';
import { fromActions } from '@store/actions';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'support-regimen-class',
  templateUrl: './support-regimen-class.html',
  styleUrls: ['./support-regimen-class.scss'],
})
export class SupportRegimenClassComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  regimenOrders: Array<any> = [];
  regimens: Array<any> = [];
  user: any;
  faceIconUrl: string = 'https://cdn.cureskin.com/app/img/female-face.png';
  hairIconUrl: string = 'https://cdn.cureskin.com/app/img/female-hair.png';
  constructor(
    public appConfig: AppConfig,
    private store: Store,
    private router: Router,
    private connectionService: ConnectionService) {
    this.subscribe();
    this.store.dispatch(fromActions.OrderFetchBegin());
  }

  /**
   * Process the orders into recentOrder & regimenOrders.
   * @param {any[]} orders
   */
  processOrders(orders: any[]): void {
    this.regimenOrders = orders.filter((order: any): boolean => {
      if (order.type === this.appConfig.Shared.Order.Type.REGIMEN
        && order.regimen
        && (!this.appConfig.Shared.Order.Stage.CompletedStages.includes(order.stage)
          || order.stage === this.appConfig.Shared.Order.Stage.DELIVERED)) return true;
      return false;
    });
  }

  /**
   * When any regimen class (FACE, HIAR, BODY) is clicked,
   * 1. we route to /mainconcern - if no regimen is found on the class.
   * 2. we route to /regimen - if regimen is found & not bought.
   * 3. we route to /support/REGIMEN_QUESTION - if the regimen order is placed/found for that class.
   *
   * We filter all question under NEED_HELP_WITH_RECENT_ORDER category to 'regimenSupportQuestions'
   * In that we filter the question with uniqueId - 'needHelpWithRegimen', which is the question that shows the options for regimen.
   *
   * Note: This 1st screen more over looks manually mapped/routed,
   * From second screen the recursive logic takes care of without manual mapping/routing.
   */
  async openRegimenQueries(regimenClass: string): Promise<void> {
    const regimenOrder = this.regimenOrders.find((order: any): boolean => order.regimen.class === regimenClass);
    const regimen = this.regimens.find((each: any): boolean => each.class === regimenClass);
    if (regimenOrder) {
      const queryParams = { orderId: regimenOrder.objectId, regimenId: regimen.objectId, regimenClass };
      await this.router.navigate(['/support', 'WFVdFMdhAR'], { queryParams });
    } else if (regimen) {
      await this.connectionService.navigateToURL(`/user?tab=regimen&class=${regimenClass}`);
    } else {
      await this.connectionService.navigateToURL(`/mainConcern?class=${regimenClass}`);
    }
  }

  setIconUrls(): void {
    this.faceIconUrl = 'https://storage.googleapis.com/heallocontent/app/img/Male%20Face_1.png';
    this.hairIconUrl = 'https://storage.googleapis.com/heallocontent/app/img/Male%20Hair_1.png';
  }

  private subscribe(): void {
    this.subscriptions.push(this.store.select(fromSelectors.selectOrders)
      .subscribe((orders: Array<any>): void => this.processOrders(orders)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((each: Subscription): void => each.unsubscribe());
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.fetchRegimens();
    if (this.user?.get('Gender') === 'Male') this.setIconUrls();
  }

  private async fetchRegimens(): Promise<void> {
    this.user = this.connectionService.getActingUser();
    this.regimens = await this.connectionService.fetchRegimens();
  }
}
