<loading class="pos-abs vh-center" *ngIf="loading"></loading>
<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="hbox rebranding-hbox font-noto" *ngIf="!loading">
  <div class="flex-box">
    <div class="flex-content tw-bg-blue-200 bg-grad pos-rel" #scrollContainer="">
      <div class="tw-bg-grid-layout tw-pt-24 tw-px-4 tw-mb-4">
        <div class="tw-font-body tw-font-bold tw-text-700" i18n="@@skinReport" *ngIf="[appConfig.Shared.Regimen.Class.FACE, appConfig.Shared.Regimen.Class.BODY].includes(report?.type)">
          Skin Report
        </div>
        <div class="tw-font-body tw-font-bold tw-text-700" i18n="@@hairReport" *ngIf="report?.type === appConfig.Shared.Regimen.Class.HAIR">
          Hair Report
        </div>
        <div class="tw-font-body tw-font-bold tw-text-300">
          {{report?.createdAt | date: 'd MMM yyyy'}}
        </div>
      </div>
      <div class="heading">
        <div class="fontS2 mT5" i18n="@@yourReportIsCreatedBy">
          Your check-up report is created by
          <div class="fw-700">{{report?.doctor?.DoctorDisplayName}}</div>
        </div>
        <section class="mT25 mB25 audio" *ngIf="report?.doctor && report?.audioURL">
          <div class="card">
            <c-audio class="c-audio" src="{{report.audioURL}}" imageUrl="{{report.doctor.DoctorImageThumbnail}}" eventPrefix="Check-Up" (emitTrackEvent)="trackEvent($event)"></c-audio>
          </div>
          <div class="audioNote card" *ngIf="isInternalUser && report.audioNote">
            <span class="bold">Audio Note:</span><span class="pL5 fontS1">{{report.audioNote}}</span>
          </div>
        </section>
      </div>
      <div class="content">
        <section>
          <div class="h1" i18n="@@yourTransformation">
            Your Transformation Journey
          </div>
          <comparison-slider *ngFor="let item of comparisonSliders; let i = index" [index]="i" [sliderLength]="comparisonSliders.length" [report]="item" (back)="back()" [user]="user"></comparison-slider>
        </section>
        <section class="note" *ngIf="report?.observation">
          <div class="h1" i18n="@@observation">Observation</div>
          <div [innerHTML]="report.observation"></div>
        </section>
        <section class="progress" #progressBar="" *ngIf="report?.treatmentProgress && report.treatmentProgress.length">
          <div class="h1" i18n="@@yourTreatmentProgress">
            Your Transformation Progress
          </div>
          <div class="progress-bar" *ngFor="let item of report.treatmentProgress; let i = index">
            <div class="title">
              {{item.issue.split('_').join(' ').toLowerCase()}}
            </div>
            <progress-bar [value]="item.score" [bgTemplate]="i%2" [stagesLanguageString]="progressBarStages" [animate]="animateProgressBar"></progress-bar>
          </div>
        </section>
        <section class="investigation tw-bg-gray-100 tw-text-black" *ngIf="report?.investigations?.name">
          <div class="bold fontL2" i18n="@@investigation">Investigation</div>
          <div class="mT10 fontS1" i18n="@@investigationHint">
            Kindly do the following tests to plan your treatment further and
            upload reports before next follow up.
          </div>
          <div class="tests">
            <div class="title bold" *ngIf="report?.investigations?.name">
              {{report?.investigations?.name}}
            </div>
            <div class="test" *ngFor="let item of report?.investigations.tests">
              {{item}}
            </div>
          </div>
        </section>
        <section class="regimenModification" *ngIf="report?.regimenModifications">
          <div class="card">
            <div class="title bold" i18n="@@yourTreatmentChanged">
              Your Treatment kit has been Changed
            </div>
            <li *ngFor="let item of report.regimenModifications">{{item}}</li>
            <cs-button [class]="'primary w100 mT20'" (callback)="viewRegimen()"><cs-button-text i18n="@@viewTreatment">View Treatment kit</cs-button-text></cs-button>
          </div>
        </section>
        <section class="tips" *ngIf="report?.tips">
          <div class="card">
            <div class="title">
              <img src="https://cdn.cureskin.com/app/img/heart.svg" /><span i18n="@@importantTip">Important tip from your doctor</span>
            </div>
            <div class="tip">{{report.tips}}</div>
          </div>
        </section>
        <section class="tips" *ngIf="report.consentFormAsk">
          <div class="card" (click)="openConsentForm()">
            <div class="tw-text-center" i18n="@@consentToPrescribeMedicines">
              Consent to prescribe medicines
            </div>
            <div class="consent-button" i18n="@@open">Open</div>
          </div>
        </section>
        <div class="feedback-question" *ngIf="showFeedbackQuestions && !afterSaveFeedback">
          <div class="question" i18n="@@areYouSatisfiedWithTheReport?">
            Are you satisfied with the Report?
          </div>
          <div class="answer-holder">
            <div class="btn" i18n="@@yes" (click)="saveFeedback('isReportSatisfied', true)" [ngClass]="{'btn-green': feedback?.isReportSatisfied == true}">Yes</div>
            <div class="btn mL5" i18n="@@no" (click)="saveFeedback('isReportSatisfied', false)" [ngClass]="{'btn-red': feedback?.isReportSatisfied == false}">No</div>
          </div>
          <div class="question mT20" i18n="@@wereYouAbleToUnderstandTheVoiceNote?">
            Were you able to understand the Voice note?
          </div>
          <div class="answer-holder">
            <div class="btn" i18n="@@yes" (click)="saveFeedback('isVoiceNoteUnderstandable', true)" [ngClass]="{'btn-green': feedback?.isVoiceNoteUnderstandable == true}">Yes</div>
            <div class="btn mL5" i18n="@@no" (click)="saveFeedback('isVoiceNoteUnderstandable', false)" [ngClass]="{'btn-red': feedback?.isVoiceNoteUnderstandable == false}">No</div>
          </div>
          <div class="question mT20" i18n="@@wereAllYourDoubts/queriesAnswered?">
            Were all your Doubts/queries answered?
          </div>
          <div class="answer-holder">
            <div class="btn answer" i18n="@@yes" (click)="saveFeedback('doubtsCleared', true)" [ngClass]="{'btn-green': feedback?.doubtsCleared == true}">Yes</div>
            <div class="btn mL5" i18n="@@no" (click)="saveFeedback('doubtsCleared', false)" [ngClass]="{'btn-red': feedback?.doubtsCleared == false}">No</div>
          </div>
        </div>
        <div class="feedback-section" #feedbackSection="">
          <div class="feedback card" *ngIf="afterSaveFeedback">
            <em class="cs-icon-circle-tick-plain icon"></em>
            <div class="flex-grow mL10" i18n="@@thankYouForYourFeedback">
              Thank You For Your Feedback
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
