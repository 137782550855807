import { Injectable } from '@angular/core';

interface Store {
  USERS: object;
  CHATS: Array<any>;
  ARTICLES: Array<any>;
  EXTERNAL_ARTICLES: Array<any>;
  FOLLOW_REPORTS: Array<any>;
  PRODUCTS: { ADD_ON: [], RE_ORDER: [] };
  INSTANT_CHECKUPS: Array<any>;
  MAIN_CONCERN: Array<any>;
  HOME: { BLOGS?: Array<any>, TIP_OF_DAY?: any };
  CONFIG?: any;
  FOLLOW_UP?: any;
  CHAT_CACHE?: any;
  PRODUCTS_IN_CART: Array<any>;
  FAQS: any;
  VIDEO_URL: string;
  IMAGE_FILE?: any;
}

const emptyDB: Store = {
  USERS: {},
  CHATS: [],
  ARTICLES: [],
  EXTERNAL_ARTICLES: [],
  FOLLOW_REPORTS: [],
  PRODUCTS: { ADD_ON: [], RE_ORDER: [] },
  INSTANT_CHECKUPS: [],
  MAIN_CONCERN: [],
  HOME: { BLOGS: [] },
  PRODUCTS_IN_CART: [],
  FAQS: {},
  VIDEO_URL: '',
};

let store: any;

@Injectable()
export class DataStoreService {
  constructor() {
    store = JSON.parse(JSON.stringify(emptyDB));
  }

  clearDataStore(): void {
    store = {};
    store = JSON.parse(JSON.stringify(emptyDB));
  }

  set(key: string, state: any): void {
    if (state instanceof Object && !state.id) { // Parse object should not go here.
      store[key] = Object.assign(store[key] || {}, state);
    } else store[key] = state;
  }

  get(key: string, defaultValue?: any): any {
    return store[key] || defaultValue;
  }
}
