import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProductControllerComponent } from './controllers/product-controller/product-controller.component';

const routes: Routes = [
  { path: 'product/:id', component: ProductControllerComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProductRoutingModule { }
