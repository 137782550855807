<div class="popup-bg" *ngIf="show" (click)="closePopUp()">
  <div class="content slideFromBottom trans-x-hide" (click)="stopPropagation($event)">
  <div class="heading flex">
    <div class="fw-500">
    <em class="cs-icon-phone-in-talk fontL2"></em><span class="pL10" i18n="@@callUs">Call Us</span>
    </div>
    <div class="closeIcon" (click)="closePopUp()">
    <em class="cs-icon-close"></em>
    </div>
  </div>
  <a href="tel:080-4680-9361" (click)="onCallNeeded.emit()">
    <section>
    <div i18n="@@ourAdvisorsAreAviailBtwn">
      <span class="pR5">Our Skincare advisors are available between</span>
      <div class="bold">10AM - 7PM</div>
    </div>
    <hr class="mTB15" />
    <div>
      <span class="pR5" i18n="@@tollFreeNo">Toll Free No</span><span class="fontL2 fw-600">080-4680-9361</span>
    </div>
    </section>
    <cs-button [class]="'primary w100 btn-xs mT15 shine'"><cs-button-text i18n="@@callNow">Call Now</cs-button-text></cs-button>
  </a>
  </div>
</div>