<div class="insta-strip mT20" *ngIf="isLive || isUpcoming && !isExpired" (click)="handleStripClick()">
  <div class="live-icon">
  <img src="https://cdn.cureskin.com/article_image/1661300877_69f4344b354a43718c13e2063d1c8502.png" />
  </div>
  <div class="dr-card">
  <div class="title">{{ instaLiveInfo?.get('shortDescription')}}</div>
  <div class="dr-name">{{ instaLiveInfo?.get('doctorName')}}</div>
  <div class="flex-box-1">
    <div class="line"></div>
  </div>
  <div class="watch-now" *ngIf="isLive">
    <div class="play"></div>
    <div class="info">Watch Now On Instagram</div>
  </div>
  <div class="read-more" *ngIf="isUpcoming">Read more</div>
  <div class="live" *ngIf="isLive">
    <div class="icon blinking"></div>
    <div class="txt">LIVE</div>
  </div>
  <div class="scheduled" *ngIf="isUpcoming">
    <img src="https://cdn.cureskin.com/article_image/1661322013_4b04649e54994fb78dde31bf0809685a.png" /><span>{{ liveTime }}</span>
  </div>
  </div>
</div>