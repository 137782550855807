import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { OnboardingLanguageComponent } from './onboarding-language.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    ToolbarModule,
    LoadingModule,
    CsIconModule,
  ],
  declarations: [OnboardingLanguageComponent],
  exports: [OnboardingLanguageComponent],
})
export class OnboardingLanguageModule {}
