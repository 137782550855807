import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ConnectionService } from '@services/connection-service';

@Component({ selector: 'skin-parameter', templateUrl: './skin-parameter.html', styleUrls: [] })

export class SkinParameterComponent {
  @Input('patientScore') patientScore: number = 0;
  @Input('expectedRange') expectedRange: string = '';
  isLow: boolean = false;
  isIdeal: boolean = false;
  isHigh: boolean = false;
  leftIdealPercentage: number = 30;
  rightIdealPercentage: number = 65;
  user: any;
  idealRange: any;
  isRebrandingV1: boolean = false;

  constructor(public conn: ConnectionService, private changeDetectionRef: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {
    this.extractTheExpectedRange();
    this.calculateTheRangeStatus();

    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((exp: any): void => {
      if (exp.key === 'rebranding_v1_phase_2') {
        this.isRebrandingV1 = true;
      }
    });
    this.changeDetectionRef.detectChanges();
  }

  /**
   * Determines the status of the concern (low, ideal, or high) based on the
   * patient's score relative to the defined range thresholds.
  */
  calculateTheRangeStatus(): void {
    const { leftThreshold, rightThreshold }: { leftThreshold: number; rightThreshold: number } = this.calculateThresholds();
    if (this.patientScore >= leftThreshold && this.patientScore <= rightThreshold) this.isIdeal = true;
    else if (this.patientScore > this.rightIdealPercentage) this.isHigh = true;
    else this.isLow = true;
  }

  /**
   * Extracts an expected range in the format '8-9' and calculates
   * the left and right ideal percentages (0-100%) based on this range.
   * If the input is not in the expected format, it sets both percentages to 0.
  */
  extractTheExpectedRange(): void {
    const rangeArray = this.expectedRange.split('-');

    if (rangeArray.length === 2) {
      const leftValue = Number(rangeArray[0]);
      const rightValue = Number(rangeArray[1]);

      if (!isNaN(leftValue) && !isNaN(rightValue)) {
        this.leftIdealPercentage = leftValue * 10;
        this.rightIdealPercentage = rightValue * 10;
        return;
      }
    }

    // Handle invalid input or unexpected format
    this.leftIdealPercentage = 0;
    this.rightIdealPercentage = 0;
  }

  /**
   * Determines the background color class for a loader cell based on the classIndex
   * and the current status (low, ideal, or high) relative to the defined range thresholds.
   *
   * @param classIndex The index of the loader cell.
   * @returns A string representing the CSS class for the background color.
  */
  checkForBackground(classIndex: number): string {
    const { leftThreshold, rightThreshold }: { leftThreshold: number; rightThreshold: number } = this.calculateThresholds();

    if (this.isIdeal) {
      // If the status is ideal, use green background for cells within the ideal range,
      // otherwise use gray background.
      if (classIndex >= leftThreshold && classIndex <= rightThreshold) {
        return this.isRebrandingV1 ? 'tw-bg-green-100' : 'tw-bg-green-200';
      }
      return this.isRebrandingV1 ? 'tw-bg-gray-300' : 'tw-bg-gray-200';
    }
    // If the status is not ideal, use gray background for cells within the range,
    // otherwise use orange background.
    if (classIndex >= leftThreshold && classIndex <= rightThreshold) {
      return this.isRebrandingV1 ? 'tw-bg-gray-300' : 'tw-bg-gray-200';
    }
    return this.isRebrandingV1 ? 'tw-bg-orange-100' : 'tw-bg-orange-200';
  }

  /**
   * Calculates the left and right values by dividing the leftIdealPercentage
   * and rightIdealPercentage by 10.
  */
  calculateThresholds(): { leftThreshold: number; rightThreshold: number } {
    const leftThreshold = this.leftIdealPercentage / 10;
    const rightThreshold = this.rightIdealPercentage / 10;
    return { leftThreshold, rightThreshold };
  }
}
