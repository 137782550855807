<div class="tw-block tw-border-spacing-0 tw-w-full tw-flex-[1] tw-overflow-hidden tw-h-screen tw-font-body">
  <loading id="loading" class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" *ngIf="isLoading$ | async"></loading>
  <div class="tw-flex tw-flex-col tw-flex-[1] tw-h-full">
    <div class="tw-w-full tw-flex-initial">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@selectAddress">Delivery address</span>
      </div>
    </div>
    <ng-container *ngIf="!(isLoading$ | async)">
      <div class="tw-w-full tw-flex-auto tw-overflow-y-auto tw-overflow-x-hidden tw-bg-gray-100 tw-pb-[100px]">
        <div class="tw-bg-gray-50 tw-p-5 tw-flex tw-items-center tw-border tw-border-gray-200 tw-rounded tw-justify-start tw-uppercase tw-mb-5 tw-text-300 tw-shadow-z1" id="addAddress" *ngIf="!(isLoading$ | async)" (click)="addNewAddressClick()">
          <em class="cs-icon-plus"></em>
          <span class="tw-pl-2 tw-font-body tw-normal-case" i18n="@@addNewAddress">Add new address</span>
        </div>
        <div class="tw-pt-0 tw-px-4 tw-pb-5 tw-font-body tw-uppercase tw-text-200 tw-tracking-wide" *ngIf="defaultAddress$ | async" i18n="@@defaultAddress"> Default address </div>
        <div class="tw-mt-0 tw-mx-4 tw-mb-5 tw-relative tw-rounded tw-overflow-hidden tw-bg-white tw-shadow-z1" *ngIf="defaultAddress$ | async as defaultAddress">
          <div class="tw-py-5 tw-px-4">
            <radio-btn cssClass="tw-shrink-0" contentCssClass="tw-flex tw-flex-col tw-overflow-auto" [checked]="deliverTo?.objectId === defaultAddress.objectId" (checkedChange)="deliverTo = defaultAddress">
              <div class="tw-capitalize tw-mb-1 tw-text-400 tw-leading-none tw-break-words">{{defaultAddress.tag}}</div>
              <div class="tw-capitalize tw-break-words" *ngIf="defaultAddress?.contactName">
                {{ defaultAddress?.contactName}},
              </div>
              <div class="tw-text-300 tw-break-words" *ngIf="defaultAddress?.userEmail">
                {{ defaultAddress?.userEmail }}
              </div>
              <div class="tw-text-300 tw-capitalize tw-break-words">
                {{ getDeliveryAddress(defaultAddress)}}
              </div>
              <div class="tw-text-300 tw-break-words"> +91-{{ defaultAddress.mobileNumber || defaultAddress.alternateNumber }}
              </div>
            </radio-btn>
          </div>
          <div class="tw-flex tw-w-full tw-bg-gray-100" *ngIf="1||deliverTo?.objectId === defaultAddress.objectId">
            <span id="editButton" class="tw-w-1/2 tw-text-center tw-text-200 tw-py-2.5 tw-px-0" i18n="@@edit" (click)="editAddress(defaultAddress)">Edit</span>
            <span id="deleteButton" class="tw-w-1/2 tw-text-center tw-text-200 tw-py-2.5 tw-px-0 tw-border-l tw-border-l-white" i18n="@@delete" (click)="deleteAddress(defaultAddress)">Delete</span>
          </div>
        </div>
        <div class="tw-pt-0 tw-px-4 tw-pb-5 tw-font-body tw-uppercase tw-text-200 tw-tracking-wide" *ngIf="(otherAddressList$ | async)?.length" i18n="@@otherAddress"> Other addresses </div>
        <div class="tw-mt-0 tw-mx-4 tw-mb-5 tw-relative tw-rounded tw-overflow-hidden tw-bg-white tw-shadow-z1" *ngFor="let address of otherAddressList$ | async">
          <div class="tw-py-5 tw-px-4">
            <radio-btn cssClass="tw-shrink-0" contentCssClass="tw-flex tw-flex-col tw-overflow-auto" [checked]="deliverTo?.objectId === address.objectId" (checkedChange)="deliverTo = address">
              <div class="tw-capitalize tw-mb-1 tw-text-400 tw-leading-none tw-break-words">{{address.tag}}</div>
              <div class="tw-capitalize tw-break-words">{{ address?.contactName }},</div>
              <div class="tw-text-300 tw-break-words" *ngIf="address?.userEmail">
                {{ address?.userEmail }}
              </div>
              <div class="tw-text-300 tw-break-words">
                {{ address.buildingDetails}}, {{ address.landmark}}
              </div>
              <div class="tw-text-300 tw-break-words">
                {{ address.city}}, {{ address.state}} - {{ address.zipCode}}
              </div>
              <div class="tw-text-300 tw-break-words"> +91-{{ address.mobileNumber || address.alternateNumber }}
              </div>
            </radio-btn>
          </div>
          <div class="tw-flex tw-w-full tw-bg-gray-100" *ngIf="deliverTo?.objectId === address.objectId">
            <span id="editButton" class="tw-w-1/2 tw-text-center tw-text-200 tw-py-2.5 tw-px-0" i18n="@@edit" (click)="editAddress(address)">Edit</span>
            <span id="deleteButton" class="tw-w-1/2 tw-text-center tw-text-200 tw-py-2.5 tw-px-0 tw-border-l tw-border-l-white" i18n="@@delete" (click)="deleteAddress(address)">Delete</span>
          </div>
        </div>
      </div>
      <div class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-flex-none tw-p-5 bg-white">
        <cs-button id="continueButton" [class]='"w100 primary"' [disabled]="!deliverTo?.objectId" (callback)="saveAsDeliveryAddress()" [loading]="inProcess"><cs-button-text i18n="@@continue">Continue</cs-button-text></cs-button>
      </div>
    </ng-container>
  </div>
</div>