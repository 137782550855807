<div class="weekStrip tw-bg-white tw-text-center tw-m-auto tw-mb-6 tw-font-body tw-text-200 tw-pt-2">
  <span i18n="@@yourTreatment1">Your Treatment :</span><span><span class="tw-font-bold tw-mr-1">&nbsp;WEEK 1</span><img class="tw-inline-flex tw-mb-[2px]" src="/assets/icons/teal-tick-circle.svg" /></span>
</div>
<div class="title tw-text-center tw-text-600 tw-font-body tw-font-bold tw-mb-1 tw-m-auto">
  <p class="tw-mx-4" i18n="@@hiUser">Hi {{ user?.get("PatientName")?.split(" ")[0] }},</p>
  <p class="tw-mx-4" i18n="@@willCallYouOn">Your skin advisor will call you on {{ pendingCheckInCall?.get("requestTime") | date: "dd MMM YYY" }}</p>
</div>
<div class="subTitle tw-text-center tw-mb-4 tw-font-body tw-text-300 tw-mx-4" i18n="@@dontMissCall">
  Please do not miss this call.
</div>
<swiper class="tw-pl-6 tw-pb-14 tw-pt-16" [config]="carouselConfigWeeklyJourney"><ng-container>
    <div class="bgClass firstCard tw-mr-3 tw-p-1 tw-shadow-z1 tw-h-full tw-box-border" *swiperSlide>
      <section class="cust-wid tw-flex tw-flex-col tw-items-start">
        <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="https://storage.googleapis.com/heallocontent/app/img/home_how_to_use.png" />
        <div class="header tw-mt-11 tw-mb-4">
          <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@getBestResults">
            Get the best results,
          </div>
          <div class="tw-font-body tw-text-300 carousel-wd tw-text-gray-500" i18n="@@keepUsingConsistently">
            keep using your treatment kit consistently.
          </div>
        </div>
        <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-7 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleInstructions('card3')">
          <span class="tw-lowercase" i18n="@@seeInstructions">See instructions</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
        </div>
      </section>
    </div>
    <section class="cust-wid tw-flex tw-flex-col tw-items-start tw-mr-3" *swiperSlide>
      <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="/assets/images/question_mark.png"/>
      <div class="header tw-mt-11 tw-mb-4">
        <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@resultSeen">
          When will I start to see changes in my skin?
        </div>
      </div>
      <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleArticle1('post-purchase-improv-card-3')">
        <span class="tw-lowercase" i18n="@@knowMore">Know More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
      </div>
    </section>
    <section class="cust-wid tw-flex tw-flex-col tw-items-start tw-mr-3" *swiperSlide>
      <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="/assets/images/cream_image.png" />
      <div class="header tw-mt-11 tw-mb-4">
        <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@proTip">
          Pro tip!
        </div>
        <div class="tw-font-body tw-text-300 carousel-wd tw-text-gray-500" i18n="@@lessIsMore">
          Less is more (don't overdo your repair cream)
        </div>
      </div>
      <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleArticle2('post-purchase-overdo-card-3')">
        <span class="tw-lowercase" i18n="@@readMore">Read More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
      </div>
    </section>
  </ng-container></swiper>