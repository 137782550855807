import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { HairTestimonialObject, RebrandedHairTestimonialObject, RebrandedTestimonialStoriesObject,
  TestimonialStoriesObject, TestimonialStory } from 'e2e/src/shared/constants';
import { Router } from '@angular/router';
import SwiperCore, { SwiperOptions, Navigation, Pagination } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { AppConfig } from 'client/app/app.config';
import { ConnectionService } from '@services/connection-service';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'testimonials-widget',
  templateUrl: './testimonials-widget.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TestimonialsWidgetComponent {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  @Input('concernClass') concernClass: string = 'FACE';
  @Input('language') language: string = 'en';
  @Input('gender') gender: string = 'Female';
  @Input('hideReadMore') hideReadMore: boolean = false;
  @Output('redirectToTestimonialPage') redirectToTestimonialPage: EventEmitter<void> = new EventEmitter();

  testimonials: TestimonialStory[];
  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    autoHeight: false,
    loop: true,
    loopedSlides: 4,
    speed: 600,
    pagination: false,
    navigation: true,
  };

  constructor(
    private router: Router,
    private appConfig: AppConfig,
    private conn: ConnectionService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.assignTestimonials();
    this.changeDetectorRef.detectChanges();
  }

  slideNext(): void {
    this.swiper.swiperRef.slideNext(100);
  }
  slidePrev(): void {
    this.swiper.swiperRef.slidePrev(100);
  }

  ngOnChanges(): void {
    this.assignTestimonials();
    this.changeDetectorRef.detectChanges();
  }

  assignTestimonials(): void {
    if (this.concernClass === this.appConfig.Shared.Regimen.Class.FACE) {
      this.testimonials = RebrandedTestimonialStoriesObject[this.gender][this.language];
    }
    if (this.concernClass === this.appConfig.Shared.Regimen.Class.HAIR) {
      this.testimonials = RebrandedHairTestimonialObject[this.gender][this.language];
    }
  }

  redirectTestimonial(): void {
    this.redirectToTestimonialPage.emit();
  }

  getCardBgClass(index: number): object {
    return {
      'tw-bg-purple-100': index % 3 === 0,
      'tw-bg-blue-100': index % 3 === 1,
      'tw-bg-teal-100': index % 3 === 2,
    };
  }
}
