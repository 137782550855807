import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentDimensionComponent } from './treatment-dimension.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [TreatmentDimensionComponent],
  exports: [TreatmentDimensionComponent],
})
export class TreatmentDimensionModule {}
