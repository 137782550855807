<ng-container *ngIf="isRebrandingV1; then rebrandedTemplateV1 else originalTemplate"></ng-container>

<ng-template #originalTemplate>
  <!-- Original template -->
  <div class="tw-flex tw-flex-col tw-h-full tw-overflow-y-auto tw-bg-gray-100" *ngIf="loading">
    <loading class="tw-flex-1 tw-self-center tw-justify-self-center"></loading>
  </div>
  <div *ngIf="!loading" class="tw-flex tw-flex-col tw-h-full tw-font-body" [ngClass]="{ 'tw-bg-purple-100': selectedPlan === 'advance', 'tw-bg-orange-100': selectedPlan === 'pro', 'tw-bg-blue-100': selectedPlan === 'assist', 'tw-bg-teal-100': selectedPlan === 'introductory'}">
    <div class="flex-content pos-rel" #regimenModuleContainer="">
      <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionHeader else originalHeader"></ng-container>
      <ng-template #regimenNewPropositionHeader>
        <!-- Regimen new proposition header -->
        <div class="tw-flex tw-items-center tw-px-6 tw-py-2 tw-bg-gray-800">
          <div class="tw-flex tw-shrink-0 tw-justify-center tw-items-center tw-h-10 tw-w-10 tw-bg-gray-800" (click)="back()">
            <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
          </div>
          <div class="tw-flex tw-justify-center tw-items-center tw-w-full">
            <span class="tw-mr-10 tw-font-body tw-text-400 tw-text-gray-75 tw-font-normal" i18n="@@chooseTreatmentPlan">Choose
              treatment plan</span>
          </div>
        </div>
      </ng-template>
      <ng-template #originalHeader>
      <!-- Original header -->
      <div class="tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-bg-white tw-z-[100]">
        <div class="tw-fixed tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
      </div>
      </ng-template>
      <ng-container *ngIf="tabData?.count === 2">
        <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionTabs else originalTabs"></ng-container>
        <ng-template #regimenNewPropositionTabs>
          <!-- Regimen new proposition tabs -->
          <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'"
            [isRegimenNewPropositionUI]="isRegimenNewPropositionUI">
            <tab1>
              <span>{{variantsInfo[0]?.fixedPrice}}</span>
            </tab1>
            <tab2>
              <span>{{variantsInfo[1]?.fixedPrice}}</span>
            </tab2>
          </toolbar-tabs>
        </ng-template>
        <ng-template #originalTabs>
        <!-- Original tabs -->
        <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'">
          <tab1 class="tw-uppercase tw-font-bold">
            <span>3&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[0]?.fixedPrice}}</span>
          </tab1>
          <tab2 class="tw-uppercase tw-font-bold">
            <span>4&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[1]?.fixedPrice}}</span>
          </tab2>
        </toolbar-tabs>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="tabData?.count === 4">
        <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionTabs else originalTabs"></ng-container>
        <ng-template #regimenNewPropositionTabs>
          <!-- Regimen new proposition tabs -->
          <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'"
            [isRegimenNewPropositionUI]="isRegimenNewPropositionUI">
            <tab1>
              <span>{{variantsInfo[0]?.fixedPrice}}</span>
            </tab1>
            <tab2>
              <span>{{variantsInfo[1]?.fixedPrice}}</span>
            </tab2>
            <tab3>
              <span>{{variantsInfo[2]?.fixedPrice}}</span>
            </tab3>
            <tab4>
              <span>{{variantsInfo[3]?.fixedPrice}}</span>
            </tab4>
          </toolbar-tabs>
        </ng-template>
        <ng-template #originalTabs>
        <!-- Original tabs -->
        <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'">
          <tab1 class="tw-uppercase tw-font-bold">
            <span>3&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[0]?.fixedPrice}}</span>
          </tab1>
          <tab2 class="tw-uppercase tw-font-bold">
            <span>4&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[1]?.fixedPrice}}</span>
          </tab2>
          <tab3 class="tw-uppercase tw-font-bold">
            <span>5&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[2]?.fixedPrice}}</span>
          </tab3>
          <tab4 class="tw-uppercase tw-font-bold" i18n="@@5Prod1to1">5 Products With 1:1 &#64; ₹{{variantsInfo[3]?.fixedPrice}}</tab4>
        </toolbar-tabs>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="tabData?.count === 3 && variantsInfo.length === 3">
        <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionTabs else originalTabs"></ng-container>
        <ng-template #regimenNewPropositionTabs>
          <!-- Regimen new proposition tabs -->
          <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'"
            [isRegimenNewPropositionUI]="isRegimenNewPropositionUI">
            <tab1>
              <span>{{variantsInfo[0]?.fixedPrice}}</span>
            </tab1>
            <tab2>
              <span>{{variantsInfo[1]?.fixedPrice}}</span>
            </tab2>
            <tab3>
              <span>{{variantsInfo[2]?.fixedPrice}}</span>
            </tab3>
          </toolbar-tabs>
        </ng-template>
        <ng-template #originalTabs>
        <!-- Original tabs -->
        <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'">
          <tab1 class="tw-uppercase tw-font-bold">
            <span>4&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[0]?.fixedPrice}}</span>
          </tab1>
          <tab2 class="tw-uppercase tw-font-bold">
            <span>5&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[1]?.fixedPrice}}</span>
          </tab2>
          <tab3 class="tw-uppercase tw-font-bold tw-px-3" i18n="@@5Prod1to1">5 Products With 1:1 &#64; ₹{{variantsInfo[2]?.fixedPrice}}</tab3>
        </toolbar-tabs>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="tabData?.count === 3 && variantsInfo.length === 4">
        <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionTabs else originalTabs"></ng-container>
        <ng-template #regimenNewPropositionTabs>
          <!-- Regimen new proposition tabs -->
          <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'"
            [isRegimenNewPropositionUI]="isRegimenNewPropositionUI">
            <tab1>
              <span>{{variantsInfo[1]?.fixedPrice}}</span>
            </tab1>
            <tab2>
              <span>{{variantsInfo[2]?.fixedPrice}}</span>
            </tab2>
            <tab3>
              <span>{{variantsInfo[3]?.fixedPrice}}</span>
            </tab3>
          </toolbar-tabs>
        </ng-template>
        <ng-template #originalTabs>
        <!-- Original tabs -->
        <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'">
          <tab1 class="tw-uppercase tw-font-bold">
            <span>4&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[1]?.fixedPrice}}</span>
          </tab1>
          <tab2 class="tw-uppercase tw-font-bold">
            <span>5&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[2]?.fixedPrice}}</span>
          </tab2>
          <tab3 class="tw-uppercase tw-font-bold tw-px-3" i18n="@@5Prod1to1">5 Products With 1:1 &#64; ₹{{variantsInfo[3]?.fixedPrice}}</tab3>
        </toolbar-tabs>
        </ng-template>
      </ng-container>
      <div class="tw-flex tw-flex-col">
        <ng-container
          *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionTabContent else originalTabContent"></ng-container>
        <ng-template #regimenNewPropositionTabContent>
          <!-- Regimen new proposition tab content -->
          <regimen-proposition [isRebrandingV1]="isRebrandingV1" [regimenId]="regimenNewPropositionData.regimenId"
            [regimenClass]="regimenClass" [variantId]="regimenNewPropositionData.variantId"
            [totalSellingPrice]="regimenNewPropositionData.totalSellingPrice"
            [productsCount]="regimenNewPropositionData.productsCount"
            [doctorImageUrl]="regimenNewPropositionData.doctorImageUrl" />
        </ng-template>
        <ng-template #originalTabContent>
        <!-- Original tab content -->
        <h3 class="tw-text-400 tw-px-2 tw-mx-3 tw-mt-4 tw-mb-2 tw-font-bold" *ngIf="regimenClass === appConfig.Shared.Regimen.Class.FACE" i18n="@@yourSkinTreatmentIncludes"> Your Skin Treatment kit includes </h3>
        <h5 class="tw-px-4 tw-text-gray-500 tw-mx-3 tw-mb-3 tw-capitalize" *ngIf="regimenClass === appConfig.Shared.Regimen.Class.HAIR"> Your Hair Treatment includes </h5>
        <div class="tw-flex-1 tw-flex tw-flex-col tw-overflow-y-auto" *ngIf="regimenClass === appConfig.Shared.Regimen.Class.FACE">
          <section class="tw-p-6 tw-pb-4 tw-bg-white tw-flex tw-flex-row tw-items-center tw-gap-6 tw-mx-3 tw-my-1 tw-shadow-z1 tw-rounded-xl" *ngFor="let service of variantServices[selectedPlan]; let i = index;">
            <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-bg-green-200 tw-rounded-full" *ngIf="i === 0" [src]="regimenCoverImage || service['img']" />
            <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-bg-green-200 tw-rounded-full" *ngIf="i !== 0" [src]="service['img']" />
            <div class="tw-flex-1">
              <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1">
                {{ service[userLanguage][0] }}
              </h2>
              <p class="tw-font-body tw-text-200 tw-text-gray-900">
                {{ service[userLanguage][1]}}
              </p>
            </div>
          </section>
        </div>
        <div class="tw-flex-1 tw-flex tw-flex-col tw-overflow-y-auto" *ngIf="regimenClass === appConfig.Shared.Regimen.Class.HAIR">
          <section class="tw-p-6 tw-pb-4 tw-bg-white tw-flex tw-flex-row tw-items-center tw-gap-6 tw-mx-3 tw-my-1 tw-shadow-z1 tw-rounded-xl" *ngFor="let service of hairVariants[selectedPlan]; let i = index;">
            <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-bg-green-200 tw-rounded-full" *ngIf="i === 0" [src]="regimenCoverImage || service['img']" />
            <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-bg-green-200 tw-rounded-full" *ngIf="i !== 0" [src]="service['img']" />
            <div class="tw-flex-1">
              <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1">
                {{ service[userLanguage][0] }}
              </h2>
              <p class="tw-font-body tw-text-200 tw-text-gray-900">
                {{ service[userLanguage][1]}}
              </p>
            </div>
          </section>
        </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rebrandedTemplateV1>
  <!-- Rebranded template v1 -->
  <div class="tw-flex tw-flex-col tw-h-full tw-overflow-y-auto tw-bg-gray-100" *ngIf="loading">
    <loading class="tw-flex-1 tw-self-center tw-justify-self-center"></loading>
  </div>
  <div *ngIf="!loading" class="tw-flex tw-flex-col tw-h-full tw-font-body" [ngClass]="{ 'tw-bg-teal-100': selectedPlan === 'advance' || selectedPlan === 'introductory', 'tw-bg-orange-100': selectedPlan === 'pro', 'tw-bg-blue-100': selectedPlan === 'assist'}">
    <div class="flex-content pos-rel" #regimenModuleContainer="">
      <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionHeader else originalHeader"></ng-container>
      <ng-template #regimenNewPropositionHeader>
        <!-- Regimen new proposition header -->
        <div class="tw-flex tw-items-center tw-px-6 tw-py-2 tw-bg-gray-800">
          <div class="tw-flex tw-shrink-0 tw-justify-center tw-items-center tw-h-10 tw-w-10 tw-bg-gray-800" (click)="back()">
            <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
          </div>
          <div class="tw-flex tw-justify-center tw-items-center tw-w-full">
            <span class="tw-mr-10 tw-font-body tw-text-400 tw-text-gray-75 tw-font-normal" i18n="@@chooseTreatmentPlan">Choose
              treatment plan</span>
          </div>
        </div>
      </ng-template>
      <ng-template #originalHeader>
      <!-- Original header -->
      <div class="tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-bg-white tw-z-[100]">
        <div class="tw-fixed tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
      </div>
      </ng-template>
      <ng-container *ngIf="tabData?.count === 2">
        <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionTabs else originalTabs"></ng-container>
        <ng-template #regimenNewPropositionTabs>
          <!-- Regimen new proposition tabs -->
          <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'"
            [isRebrandingV1]="isRebrandingV1" [isRegimenNewPropositionUI]="isRegimenNewPropositionUI">
            <tab1>
              <span>{{variantsInfo[0]?.fixedPrice}}</span>
            </tab1>
            <tab2>
              <span>{{variantsInfo[1]?.fixedPrice}}</span>
            </tab2>
          </toolbar-tabs>
        </ng-template>
        <ng-template #originalTabs>
        <!-- Original tabs -->
        <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'" [isRebrandingV1]="isRebrandingV1">
          <tab1 class="tw-uppercase tw-font-bold">
            <span>3&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[0]?.fixedPrice}}</span>
          </tab1>
          <tab2 class="tw-uppercase tw-font-bold">
            <span>4&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[1]?.fixedPrice}}</span>
          </tab2>
        </toolbar-tabs>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="tabData?.count === 4">
        <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionTabs else originalTabs"></ng-container>
        <ng-template #regimenNewPropositionTabs>
          <!-- Regimen new proposition tabs -->
          <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'"
            [isRebrandingV1]="isRebrandingV1" [isRegimenNewPropositionUI]="isRegimenNewPropositionUI">
            <tab1>
              <span>{{variantsInfo[0]?.fixedPrice}}</span>
            </tab1>
            <tab2>
              <span>{{variantsInfo[1]?.fixedPrice}}</span>
            </tab2>
            <tab3>
              <span>{{variantsInfo[2]?.fixedPrice}}</span>
            </tab3>
            <tab4>
              <span>{{variantsInfo[3]?.fixedPrice}}</span>
            </tab4>
          </toolbar-tabs>
        </ng-template>
        <ng-template #originalTabs>
        <!-- Original tabs -->
        <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'" [isRebrandingV1]="isRebrandingV1">
          <tab1 class="tw-uppercase tw-font-bold">
            <span>3&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[0]?.fixedPrice}}</span>
          </tab1>
          <tab2 class="tw-uppercase tw-font-bold">
            <span>4&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[1]?.fixedPrice}}</span>
          </tab2>
          <tab3 class="tw-uppercase tw-font-bold">
            <span>5&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[2]?.fixedPrice}}</span>
          </tab3>
          <tab4 class="tw-uppercase tw-font-bold" i18n="@@5Prod1to1">5 Products With 1:1 &#64; ₹{{variantsInfo[3]?.fixedPrice}}</tab4>
        </toolbar-tabs>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="tabData?.count === 3 && variantsInfo.length === 3">
        <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionTabs else originalTabs"></ng-container>
        <ng-template #regimenNewPropositionTabs>
          <!-- Regimen new proposition tabs -->
          <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'"
            [isRebrandingV1]="isRebrandingV1" [isRegimenNewPropositionUI]="isRegimenNewPropositionUI">
            <tab1>
              <span>{{variantsInfo[0]?.fixedPrice}}</span>
            </tab1>
            <tab2>
              <span>{{variantsInfo[1]?.fixedPrice}}</span>
            </tab2>
            <tab3>
              <span>{{variantsInfo[2]?.fixedPrice}}</span>
            </tab3>
          </toolbar-tabs>
        </ng-template>
        <ng-template #originalTabs>
        <!-- Original tabs -->
        <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'" [isRebrandingV1]="isRebrandingV1">
          <tab1 class="tw-uppercase tw-font-bold">
            <span>4&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[0]?.fixedPrice}}</span>
          </tab1>
          <tab2 class="tw-uppercase tw-font-bold">
            <span>5&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[1]?.fixedPrice}}</span>
          </tab2>
          <tab3 class="tw-uppercase tw-font-bold tw-px-3" i18n="@@5Prod1to1">5 Products With 1:1 &#64; ₹{{variantsInfo[2]?.fixedPrice}}</tab3>
        </toolbar-tabs>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="tabData?.count === 3 && variantsInfo.length === 4">
        <ng-container *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionTabs else originalTabs"></ng-container>
        <ng-template #regimenNewPropositionTabs>
          <!-- Regimen new proposition tabs -->
          <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'"
            [isRebrandingV1]="isRebrandingV1" [isRegimenNewPropositionUI]="isRegimenNewPropositionUI">
            <tab1>
              <span>{{variantsInfo[1]?.fixedPrice}}</span>
            </tab1>
            <tab2>
              <span>{{variantsInfo[2]?.fixedPrice}}</span>
            </tab2>
            <tab3>
              <span>{{variantsInfo[3]?.fixedPrice}}</span>
            </tab3>
          </toolbar-tabs>
        </ng-template>
        <ng-template #originalTabs>
        <!-- Original tabs -->
        <toolbar-tabs #toolbarTabs="" (tabSelected)="tabChange($event)" [data]="tabData" [class]="'planInfo'" [isRebrandingV1]="isRebrandingV1">
          <tab1 class="tw-uppercase tw-font-bold">
            <span>4&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[1]?.fixedPrice}}</span>
          </tab1>
          <tab2 class="tw-uppercase tw-font-bold">
            <span>5&nbsp;</span>
            <span i18n="@@products">Products</span>
            <br />
            <span>&#64; ₹{{variantsInfo[2]?.fixedPrice}}</span>
          </tab2>
          <tab3 class="tw-uppercase tw-font-bold tw-px-3" i18n="@@5Prod1to1">5 Products With 1:1 &#64; ₹{{variantsInfo[3]?.fixedPrice}}</tab3>
        </toolbar-tabs>
        </ng-template>
      </ng-container>
      <div class="tw-flex tw-flex-col">
        <ng-container
          *ngIf="isRegimenNewPropositionUI; then regimenNewPropositionTabContent else originalTabContent"></ng-container>
        <ng-template #regimenNewPropositionTabContent>
          <!-- Regimen new proposition tab content -->
          <regimen-proposition [isRebrandingV1]="isRebrandingV1" [regimenId]="regimenNewPropositionData.regimenId"
            [regimenClass]="regimenClass" [variantId]="regimenNewPropositionData.variantId"
            [totalSellingPrice]="regimenNewPropositionData.totalSellingPrice"
            [productsCount]="regimenNewPropositionData.productsCount"
            [doctorImageUrl]="regimenNewPropositionData.doctorImageUrl" />
        </ng-template>
        <ng-template #originalTabContent>
        <!-- Original tab content -->
        <h3 class="tw-text-400 tw-px-2 tw-mx-3 tw-mt-4 tw-mb-2 tw-font-bold" *ngIf="regimenClass === appConfig.Shared.Regimen.Class.FACE" i18n="@@yourSkinTreatmentIncludes"> Your Skin Treatment kit includes </h3>
        <h5 class="tw-px-4 tw-text-gray-500 tw-mx-3 tw-mb-3 tw-capitalize" *ngIf="regimenClass === appConfig.Shared.Regimen.Class.HAIR"> Your Hair Treatment includes </h5>
        <div class="tw-flex-1 tw-flex tw-flex-col tw-overflow-y-auto" *ngIf="regimenClass === appConfig.Shared.Regimen.Class.FACE">
          <section class="tw-p-6 tw-pb-4 tw-bg-white tw-flex tw-flex-row tw-items-center tw-gap-6 tw-mx-3 tw-my-1 tw-shadow-z1 tw-rounded-xl" *ngFor="let service of variantServices[selectedPlan]; let i = index;">
            <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-bg-green-200 tw-rounded-full" *ngIf="i === 0" [src]="regimenCoverImage || service['img']" />
            <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-bg-green-200 tw-rounded-full" *ngIf="i !== 0" [src]="service['img']" />
            <div class="tw-flex-1">
              <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1">
                {{ service[userLanguage][0] }}
              </h2>
              <p class="tw-font-body tw-text-200 tw-text-gray-900">
                {{ service[userLanguage][1]}}
              </p>
            </div>
          </section>
        </div>
        <div class="tw-flex-1 tw-flex tw-flex-col tw-overflow-y-auto" *ngIf="regimenClass === appConfig.Shared.Regimen.Class.HAIR">
          <section class="tw-p-6 tw-pb-4 tw-bg-white tw-flex tw-flex-row tw-items-center tw-gap-6 tw-mx-3 tw-my-1 tw-shadow-z1 tw-rounded-xl" *ngFor="let service of hairVariants[selectedPlan]; let i = index;">
            <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-bg-green-200 tw-rounded-full" *ngIf="i === 0" [src]="regimenCoverImage || service['img']" />
            <img class="tw-flex-none tw-block tw-h-24 tw-w-24 tw-bg-green-200 tw-rounded-full" *ngIf="i !== 0" [src]="service['img']" />
            <div class="tw-flex-1">
              <h2 class="tw-font-body tw-font-bold tw-text-400 tw-text-gray-900 tw-mb-1">
                {{ service[userLanguage][0] }}
              </h2>
              <p class="tw-font-body tw-text-200 tw-text-gray-900">
                {{ service[userLanguage][1]}}
              </p>
            </div>
          </section>
        </div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>