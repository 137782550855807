import { Component, Input } from '@angular/core';
import { TestimonialCaseStudiesInfo, TestimonialCaseStudy } from 'e2e/src/shared/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'testimonial-case-studies',
  templateUrl: './testimonial-case-studies.html',
  styleUrls: [],
})

export class TestimonialCaseStudiesComponent {
  @Input('language') language: string = 'en';
  testimonialCaseStudies: TestimonialCaseStudy[];

  constructor(
    private router: Router) { }

  ngOnInit(): void {
    this.testimonialCaseStudies = TestimonialCaseStudiesInfo[this.language];
  }

  redirectToCaseStudiesInfo(index: number): void {
    this.router.navigate([`/user/caseStudies/${index}`]);
  }

  getCardBgClass(index: number): object {
    return {
      'tw-bg-green-100': index % 3 === 0,
      'tw-bg-blue-100': index % 3 === 1,
      'tw-bg-purple-100': index % 3 === 2,
    };
  }
}
