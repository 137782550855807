/* eslint-disable quote-props */
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { ConcernType, FallbackSummaryMap, FallbackSummaries } from 'e2e/src/shared/constants';
import { problemsCopy } from '../instant-checkup/problem-details';
import { AppConfig } from '../app.config';

export interface SkinCareData {
  collagenImprovement: string;
  inflammationControl: string;
  skinRenewal: string;
  glycationReduction: string;
  sunProtection: string;
  antiOxidation: string;
}

interface SkinParameters {
  patientScore: string;
  expectedRange: string;
}

interface SkinData {
  sebumBalanceParameters: SkinParameters;
  inflammationParameters: SkinParameters;
  sensitivityParameters: SkinParameters;
  skinDamageParameters: SkinParameters;
  skinRenewalParameters: SkinParameters;
  skinRadianceParameters: SkinParameters;
}

export interface SkinConcernData {
  concern: string;
  lesionAnalysisForConcern: string;
}

export interface ConcernsWithCount {
  infAcne: number;
  comedone: number;
  openPores: number;
  ds: number;
  dc: number;
  melasma: number;
  acneScars: number;
  sensitiveSkin: number;
}
export interface Concerns {
  infAcne: string;
  comedone: string;
  openPores: string;
  ds: string;
  dc: string;
  melasma: string;
  acneScars: string;
  sensitiveSkin: string;
}

@Component({
  selector: 'report',
  templateUrl: './report.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ReportComponent {
  @Input('regimen') regimen: any;
  @Input('experiments') experiments: any[];
  @Input('isTimerPage') isTimerPage: boolean = false;
  @Input('showRegimenToast') showRegimenToast: boolean = false;
  @Output('hideReport') hideReport: EventEmitter<void> = new EventEmitter();
  @Output('showRegimenTimer') showRegimenTimer: EventEmitter<void> = new EventEmitter();
  user: any;
  detectedConcerns: Array<any> = [];
  previousInstantCheckup: Array<any> = [];
  allocatedDoctor: any;
  treatmentDimension: SkinCareData;
  skinParametersInfo: SkinData;
  lesionAnalysis: [SkinConcernData];
  mergedMap: Map<string, any> = new Map();
  mergedConcerns: Array<any> = [];
  createdAt: Date = new Date();
  problemsCopy: { [key: string]: { [key: string]: { name: string, info: string } } } = problemsCopy;
  updatedPayload: ConcernsWithCount = {
    infAcne: 0,
    comedone: 0,
    openPores: 0,
    ds: 0,
    dc: 0,
    melasma: 0,
    acneScars: 0,
    sensitiveSkin: 0,
  };
  concernNames: Concerns = {
    infAcne: 'Acne',
    comedone: 'Comedones',
    openPores: 'Open pores',
    ds: 'Dark spots',
    dc: 'Dark circles',
    melasma: 'Melasma',
    acneScars: 'Acne Scars',
    sensitiveSkin: 'Sensitive Skin',
  };
  timerInterval: any;
  currentTime: number;
  overallSummary: string;
  mainConcernDetectedByAI: string = 'infAcne';
  reportCode: string;
  patientID: string;
  loading: boolean = false;
  mainConcern: string = '';
  showFirstJourney: boolean = false;
  otherConcerns: string = '';
  showTimer: boolean = false;
  patientName: string = '';
  isRebrandingV1: boolean = false;
  severity: any;
  reportBasedOnRegimenExperment: boolean = false;

  constructor(public conn: ConnectionService,
    private router: Router,
    public appConfig: AppConfig,
    private changeDetection: ChangeDetectorRef,
    private eventLogger: EventLoggerService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.user = this.conn.getActingUser();
    const concern = this.user.get('PrivateMainConcernClass');
    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((exp: any): void => {
      if (exp.key === 'rebranding_v1_phase_2') {
        this.isRebrandingV1 = true;
      }
      if (exp.key === 'skin_report_regimen_and_overall_summary_mapping') {
        this.reportBasedOnRegimenExperment = true;
      }
    });

    this.detectedConcerns = this.user?.get('aiDetections');
    this.patientName = this.user?.get('PatientName');
    this.otherConcerns = this.detectedConcerns?.slice(1)?.join(', ');
    // Sometimes allocated doctor obj is not available and template is broken so fetching user details below
    if (!this.user?.get('allocatedDoctor')) await this.conn.getActingUser().fetch();
    this.allocatedDoctor = await this.conn.findUserByObjectId(this.user?.get('allocatedDoctor')?.id);
    this.previousInstantCheckup = await this.conn.fetchInstantCheckup({ userId: this.user.get('username') });
    if (this.previousInstantCheckup?.length) {
      await this.processCheckupInfo();
      await this.updateReportInfo();
      this.checkForJourney();
      this.loading = false;
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'skin-report-success' }));
    } else {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'skin-report-fail' }));
      this.hideReport.emit();
    }

    this.changeDetection.detectChanges();
  }

  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
  }

  async updateReportInfo(): Promise<void> {
    if (this.user?.get('report')) {
      const { reportCode, patientID, createdAt }: { reportCode: string, patientID: string, createdAt: Date} = this.user.get('report');
      this.reportCode = reportCode;
      this.patientID = patientID;
      this.createdAt = createdAt;
    } else {
      this.reportCode = `CS${this.generateRandomNumberWithDigits(7)}`;
      this.patientID = `CS${this.generateRandomNumberWithDigits(4)}`;
      await this.saveReportInfo();
    }
  }

  checkForJourney(): void {
    const firstJourney = ['Pigmentation', 'Acne Scars', 'Dark spots'];
    if (firstJourney.includes(this.mainConcern)) {
      this.showFirstJourney = true;
    }
  }

  async saveReportInfo(): Promise<void> {
    this.user.set('report', {
      reportCode: this.reportCode,
      patientID: this.patientID,
      createdAt: this.createdAt,
    });
    await this.user.save();
  }

  async processCheckupInfo(): Promise<void> {
    this.filterFrontAndSideFace();
    this.getCommonConcernsWithHighCount();
    await this.getSkinReport();
    if (!this.mainConcern && !this.detectedConcerns?.length) {
      if (this.mergedConcerns.length) {
        this.mainConcern = this.mergedConcerns[0]?.ProblemName;
      }
    }
  }

  filterFrontAndSideFace(): void {
    const filteredArray: Array<any> = [];
    const sideFace = this.previousInstantCheckup.find((checkup: any): boolean => checkup?.type?.includes('SIDE_FACE'));
    const frontFace = this.previousInstantCheckup.find((checkup: any): boolean => checkup?.type?.includes('FRONT_FACE'));

    if (frontFace) {
      filteredArray.push(frontFace);
    }

    if (sideFace) {
      filteredArray.push(sideFace);
    }

    this.previousInstantCheckup = filteredArray;
  }

  getCommonConcernsWithHighCount(): void {
    const frontFaceDetection = this.previousInstantCheckup.find(
      (item: any): boolean => item.type === this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE) || [];

    const sideFaceDetection = this.previousInstantCheckup.find(
      (item: any):boolean => item.type !== this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE) || [];

    const frontFaceResults = frontFaceDetection?.aiResponse?.result || [];
    const sideFaceResults = sideFaceDetection?.aiResponse?.result || [];
    const allObjects = [...frontFaceResults, ...sideFaceResults];

    // Iterating over each detected concern (checkup) and merge them based on 'ProblemName'.
    allObjects.forEach((checkup: any): void => {
      const problemName = this.getProblemName(checkup);
      const existingCheckup = this.mergedMap.get(problemName);

      // If no existing checkup is found, or if the current checkup has more BoundingBoxes,
      // update the mergedMap with the current checkup.
      if (!existingCheckup || (checkup.BoundingBoxes?.length || 0) > (existingCheckup.BoundingBoxes?.length || 0)) {
        this.mergedMap.set(problemName, checkup);
      }
    });

    this.mergedMap.forEach((item: any): void => {
      this.mergedConcerns.push(item);
    });
  }

  getProblemName(checkup: any): any {
    if (checkup?.ProblemName) {
      return checkup.ProblemName;
    }
    return this.problemsCopy.en[checkup.class]?.name;
  }

  async getSkinReport(): Promise<void> {
    const concernsWithCount = this.updatePayloadWithDetectedConcernCount();
    const skinReport = await this.conn.getSkinReportInfo(this.mainConcernDetectedByAI, concernsWithCount);
    this.showRegimenTimer.emit();
    this.treatmentDimension = skinReport?.treatmentDimensions;
    this.skinParametersInfo = skinReport?.skinParameters;
    this.lesionAnalysis = skinReport?.lesionAnalysis;

    if (this.reportBasedOnRegimenExperment && this.regimen?.skinReportParams?.overallSummary) {
      this.overallSummary = this.regimen.skinReportParams.overallSummary;
    } else if (skinReport?.overallSummary?.overallSummary) {
      this.overallSummary = skinReport.overallSummary.overallSummary;
    } else {
      this.assignFallbackSummary();
      this.logSkinReportParams(concernsWithCount);
    }

    this.severity = (this.reportBasedOnRegimenExperment && this.capitalizeFirstLetter(this.regimen?.displayConcern?.severity))
      || 'Moderate';

    if (this.reportBasedOnRegimenExperment && this.regimen?.displayConcern?.name) {
      this.mainConcern = this.capitalizeFirstLetter(this.regimen?.displayConcern?.name);
    }
  }

  assignFallbackSummary(): void {
    const fallbackSummaryMap: FallbackSummaryMap = {
      [ConcernType.ACNE]: FallbackSummaries.ACNE,
      [ConcernType.COMEDONES]: FallbackSummaries.COMEDONES,
      [ConcernType.OPEN_PORES]: FallbackSummaries.OPEN_PORES,
      [ConcernType.DARK_SPOTS]: FallbackSummaries.DARK_SPOT,
      [ConcernType.ACNE_SCARS]: FallbackSummaries.ACNE_SCARS,
      [ConcernType.MELASMA]: FallbackSummaries.MELESMA,
      [ConcernType.DARK_CIRCLES]: FallbackSummaries.UNDER_EYE_DARK_CIRCLES,
    };
    this.overallSummary = fallbackSummaryMap[this.mainConcern] || FallbackSummaries.GENERAL;
  }

  public logSkinReportParams(concernsWithCount: any): void {
    this.eventLogger.trackInElasticSearch({
      event: 'SKIN_REPORT_PARAM_EMPTY',
      type: 'ERROR_SKIN_REPORT_PARAM_EMPTY',
      user: this.user?.get('username'),
      data: JSON.stringify(concernsWithCount),
    });
  }

  capitalizeFirstLetter(str: string): string {
    if (!str) return '';
    return str.split(' ').map((item: string):string => item[0] + item.slice(1).toLowerCase()).join(' ');
  }
  updatePayloadWithDetectedConcernCount(): ConcernsWithCount {
    // Mapping the threshold values based on problem names
    this.mergedMap.forEach((obj: any): void => {
      this.processCheckup(obj);
    });
    return this.updatedPayload;
  }

  processCheckup(obj: any): void {
    switch (obj?.ProblemName) {
      case 'Inflammatory Acne':
        this.updateInflammatoryAcne(obj);
        break;
      case 'Comedones':
        this.updateComedones(obj);
        break;
      case 'Acne Scars':
        this.updateAcneScars(obj);
        break;
      case 'Dark Spots':
        this.updateDarkSpots(obj);
        break;
      case 'Open Pores':
        this.mainConcernDetectedByAI = 'openPores';
        if (obj?.Condition === 'Detected') {
          this.updatedPayload.openPores = 1;
          this.updateMainConcern('openPores');
        }
        break;
      case 'Melasma':
        this.mainConcernDetectedByAI = 'melasma';
        if (obj?.Condition === 'Detected') {
          this.updatedPayload.melasma = 1;
          this.updateMainConcern('melasma');
        }
        break;
      case 'Dark Circles':
        this.mainConcernDetectedByAI = 'dc';
        if (obj?.Condition === 'Detected') {
          this.updatedPayload.dc = 1;
          this.updateMainConcern('dc');
        }
        break;
      default:
        break;
    }
  }

  updateInflammatoryAcne(obj: any): void {
    if (obj?.Condition !== 'Detected') return;
    this.mainConcernDetectedByAI = 'infAcne';
    this.updateMainConcern('infAcne');
    const boundingBoxesCount = obj?.BoundingBoxes?.length ?? 0;
    this.updatedPayload.infAcne = boundingBoxesCount;
  }

  updateComedones(obj: any): void {
    if (obj?.Condition !== 'Detected') return;
    this.mainConcernDetectedByAI = 'comedone';
    this.updateMainConcern('comedone');
    const boundingBoxesCount = obj?.BoundingBoxes?.length ?? 0;
    this.updatedPayload.comedone = boundingBoxesCount;
  }

  updateAcneScars(obj: any): void {
    if (obj?.Condition !== 'Detected') return;
    this.mainConcernDetectedByAI = 'acneScars';
    this.updateMainConcern('acneScars');
    const boundingBoxesCount = obj?.BoundingBoxes?.length ?? 0;
    this.updatedPayload.acneScars = boundingBoxesCount;
  }

  updateDarkSpots(obj: any): void {
    if (obj?.Condition !== 'Detected') return;
    this.mainConcernDetectedByAI = 'ds';
    this.updateMainConcern('ds');
    const boundingBoxesCount = obj?.BoundingBoxes?.length ?? 0;
    this.updatedPayload.ds = boundingBoxesCount;
  }

  generateRandomNumberWithDigits(digits: number): string {
    const digitMultiplier = 10 ** (digits - 1);
    const randomNumber = Math.floor(Math.random() * 9 * digitMultiplier) + digitMultiplier;
    return randomNumber.toString();
  }

  back(): void {
    this.router.navigate(['/user'], { queryParams: { tab: 'home' } });
  }

  updateMainConcern(concern: string): void {
    if (!this.mainConcern) this.mainConcern = this.concernNames[concern];
  }

  skipConcern(checkup: any): boolean {
    const concernsThatShouldBeSkipped = ['Puffy Eyes', 'Facial Hair', 'Perioral Pigmentation', 'Other Scars'];
    const problemName = this.getProblemName(checkup);
    if (concernsThatShouldBeSkipped.includes(problemName)) return true;
    return false;
  }
}
