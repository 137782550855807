import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { SwiperModule } from 'swiper/angular';
import { RegimenPropositionComponent } from './regimen-proposition.component';

@NgModule({
  imports: [CommonModule, CsIconModule, SwiperModule],
  declarations: [RegimenPropositionComponent],
  exports: [RegimenPropositionComponent],
})
export class RegimenPropositionModule {}
