import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading';
import { ToolbarModule } from '@components/toolbar';
import { CsIconModule } from '@components/icon';
import { IframeComponent } from './iframe.component';

@NgModule({
  imports: [CommonModule, LoadingModule, ToolbarModule, CsIconModule],
  declarations: [IframeComponent],
  exports: [],
})
export class IframeModule {}
