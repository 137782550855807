<div class="tw-fixed tw-overflow-hidden tw-border-spacing-0 tw-w-full tw-font-body">
  <div class="tw-flex tw-w-full tw-flex-col tw-flex-1">
  <div class="tw-w-full">
    <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
    <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
      <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
    </div>
    <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@aboutUs">About Us</span>
    </div>
  </div>
  <div class="tw-w-full tw-overflow-x-hidden tw-overflow-y-auto tw-p-4 tw-flex-1">
    <div i18n="@@aboutUsPara1">
    At CureSkin, we are committed to giving you the highest quality care and
    treatment, that is accessible right from your mobile phone, anytime,
    anywhere.
    </div>
    <div class="tw-mt-4" i18n="@@aboutUsPara2">
    We partner with carefully selected, top dermatologists for your skin and
    hair care related concerns. All information regarding your consultation
    and treatment plan are confidential and secure.
    </div>
    <div class="tw-mt-4" i18n="@@aboutUsPara3">
    CureSkin app is always with you to continuously care for and help you
    get better. We are continuously improving our app and have some pretty
    exciting things coming up in the near future.
    </div>
    <div class="tw-mt-4" i18n="@@aboutUsPara4">
    We would be happy to hear about your experience and feedback. You can
    contact us (Please mention the mobile number you registered in the app
    for quicker response), by emailing to &nbsp;
    </div>
    <div class="tw-inline-block tw-pt-3">
    <a class="tw-color-blue-400" href="mailto:hello@cureskin.com?Subject={{user.get('MobileNumber')}}, {{user.get('PatientName')}}">hello&#64;cureskin.com</a>
    </div>
    <div class="tw-mt-4" i18n="@@thankYou">Thank you.</div>
    <div class="tw-mt-1 tw-font-bold tw-tracking-tight tw-text-500">Cureskin Team</div>
  </div>
  </div>
</div>