<ng-container *ngIf="isRebrandingBanner; then rebrandedTemplateV1 else originalTemplate"></ng-container>

<ng-template #originalTemplate>
  <section class="bdr-rad-15 tw-mx-4 tw-mb-4 explore tw-p-4 tw-shadow-z1" routerLink="/user/order/{{order?.id}}" (click)="handleClick()">
  <div class="header tw-flex tw-items-center">
    <img class="tw-flex-none tw-h-12 tw-w-12 tw-rounded-full tw-mr-3" src="/assets/images/order-placed.gif" />
    <div class="tw-font-body tw-font-bold tw-text-400 tw-mr-2 tw-items-center tw-flex w50 tw-min-h-12" i18n="@@packingKit" *ngIf='order?.get("type") === appConfig.Shared.Order.Type.REGIMEN'>
      We are packing your treatment kit
    </div>
    <div class="tw-font-body tw-font-bold tw-text-400 tw-mr-2 tw-items-center tw-flex w50 tw-min-h-12" i18n="@@packingOrder" *ngIf='order?.get("type") !== appConfig.Shared.Order.Type.REGIMEN'>
      We are packing your order
    </div>
    <div class="click-icon tw-border tw-border-gray-500 tw-rounded-full tw-w-8 tw-h-8 tw-absolute tw-right-2">
      <cs-icon class="tw-absolute" [iconName]="'chevron_right_green'"></cs-icon>
    </div>
  </div>
  </section>
</ng-template>

<ng-template #rebrandedTemplateV1>
  <section class="tw-rounded-3 tw-mx-4 tw-mb-4 tw-px-2 tw-py-3 tw-border-[2px] tw-border-solid tw-border-teal-200 tw-max-h-18 tw-box-border" 
  style="background: linear-gradient(95deg, #E4F4F0 -6.23%, #FFF 88%);"
  routerLink="/user/order/{{order?.id}}" (click)="handleClick()">
    <div class="header tw-flex tw-items-center">
      <div >
      <cs-icon class="tw-h-12 tw-w-12" [iconName]="'order_packing'"></cs-icon>
      </div>
      <div class="tw-ml-2 tw-flex tw-flex-col tw-min-h-12 tw-gap-1">
        <div class="tw-flex tw-items-center">
          <div class="tw-leading-4 tw-font-body tw-font-bold tw-text-[14px] tw-items-center tw-flex tw-pt-1" i18n="@@packingYourOrder"
          [ngClass]="{'tw-text-[10px]': ['ta', 'te', 'ml', 'kn'].includes(user?.get('languagePreference'))}">
        Packing Your Order
      </div>
      <cs-icon class="tw-pt-1 tw-h-4 tw-w-4 tw-pl-2" [iconName]="'chevron_right_teal'"
      [ngClass]="{'tw-h-5 tw-w-5': ['ta', 'te', 'ml', 'kn'].includes(user?.get('languagePreference'))}">
      ></cs-icon>
       </div>
      <div class="tw-leading-[18px] tw-font-body tw-text-gray-500 tw-font-medium tw-text-[12px] tw-items-center tw-flex" i18n="@@packingKit" *ngIf='order?.get("type") === appConfig.Shared.Order.Type.REGIMEN'> We are packing your treatment kit </div>
      <div class="tw-leading-[18px] tw-font-body tw-text-gray-500 tw-font-medium tw-text-[12px] tw-items-center tw-flex" i18n="@@packingOrder" *ngIf='order?.get("type") !== appConfig.Shared.Order.Type.REGIMEN'> We are packing your order </div>

    </div>
    </div>
  </section>
</ng-template>