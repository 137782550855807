<div class="hbox" [ngClass]='{ "copy": isInternalUser, "native": isNative }'>
  <div class="hbox pos-fix">
  <router-outlet></router-outlet>
  <div class="addToHome trans-y-hide slideFromBottom" *ngIf="showAddToHomePrompt" (click)="addToHomeScreen(true, $event)">
    <img src="/assets/images/logo-512.png" />
    <div>Add CureSkin to HomeScreen</div>
    <em class="cs-icon-close clr-white fontXL" (click)="addToHomeScreen(false, $event)"></em>
  </div>
  </div>
  <div class="shareBg" *ngIf="openShareOptions" (click)="hideMoreOption()"></div>
  <div class="moreOption trans-y-hide" [ngClass]='{ "slideFromBottom": openShareOptions, "slideToBottom": openShareOptions === false }' (click)="stopPropagation($event)">
  <div class="flex" (click)='shareApp("WHATSAPP")'>
    <div class="socailmedia whatsapp"></div>
    <span class="pL15">WhatsApp</span>
  </div>
  <div class="flex" (click)='shareApp("COPY")'>
    <div class="socailmedia copy"></div>
    <span class="pL15">Copy</span>
  </div>
  </div>
  <textarea id="clipboard"></textarea><app-notification [(message)]="notifyMessage"></app-notification>
</div>
<pop-up-modal *ngIf="popUpModal && popUpModal.open" [config]="popUpModal" (close)="popUpClosed($event)"></pop-up-modal>
<div class="popup-bg" *ngIf="showLoadingOverlay">
  <loading class="pos-abs vh-center z-ind-5" [stroke]="'#fff'"></loading>
</div>
<div class="linear-loading" *ngIf="showLinearLoading">
  <div class="line"></div>
</div>
<div class="popup-bg" *ngIf="uploadingProgressBar.show">
  <div class="uploading-progress">
  <div class="mB10 bold text-center" i18n="@@uploadInProgress">
    Upload in progress
  </div>
  <div class="percentage">{{uploadingProgressBar.percentageLoaded}}%</div>
  <div class="progress-holder">
    <div class="progress-bar" #progressBar="" [style.width]='uploadingProgressBar.percentageLoaded+"%"'></div>
  </div>
  <div class="cancel-btn text-upr" i18n="@@cancel" (click)="cancelUploadingFile()">Cancel</div>
  </div>
</div>
<pop-up *ngIf="popUp?.open && cashValue !== 0" [class]="'bdr-rad-10 p15'" [show]="popUp?.open" [triggerDismiss]="true" (close)="closePopup()">
  <div class="tw-bg-white tw-p-4">
  <header>
    <div class="title" i18n="@@congratulations">Congratulations!</div>
  </header>
  <div class="content">
    <div class="price-breakdown">
    <div class="bold">
      <div class="dib" i18n="@@gotFreeCash">
      You just won ₹{{cashValue}} CureSkin Cash as a surprise gift for
      visiting this page.
      </div>
      <div class="underline mB15" (click)="goToWallet()" i18n="@@goToWallet">
      Click here to see the wallet.
      </div>
    </div>
    </div>
  </div>
  <ng-lottie *ngIf="showConfettiAnimation" width="100%" [styles]="styles" [options]="options"></ng-lottie><cs-button id="payButton" [class]="'primary w100'" (callback)="closePopup()"><cs-button-text i18n="@@ok">Ok</cs-button-text></cs-button>
  </div>
</pop-up><router-outlet name="reminder"></router-outlet>