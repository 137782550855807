import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CsIconModule } from '@components/icon';
import { ToolbarModule } from '@components/toolbar';
import { TranslateStringsModule } from '@components/translate-string';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { FilePickerModule } from '@components/file-picker';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { VideoPlayerModule } from '@components/video-player/video-player.module';
import { LoadingModule } from '@components/loading';
import { CameraModule } from '@components/camera';
import { PopUpModalModule } from '@components/pop-up-modal';
import { SwiperModule } from 'swiper/angular';
import { WrongPhotoDetectedModule } from '@shared/bottom-sheet-layouts/wrong-photo-detected/wrong-photo-detected.module';
import { DeletePhotoSheetModule } from '@shared/bottom-sheet-layouts/delete-photo-sheet/delete-photo-sheet.module';
import {
  SelectCheckupTypeComponent,
  CheckupPhotoInstructionComponent,
  CheckupListComponent,
  CheckupCaptureComponent,
  CheckupViewComponent,
  CheckupViewContainerComponent,
  HairAiReportComponent,
  CheckupViewExperimentComponent,
} from './components';
import {
  HairAiCanvasDirective,
  DrawImageDirective,
  ScrollToViewDirective,
  DrawDetectionsDirective,
} from './directives';
import { onboardingGuard } from '../guards/onboarding.guard';
import { authenticationGuard } from '../guards/authentication.guard';

@NgModule({
  declarations: [
    SelectCheckupTypeComponent,
    CheckupPhotoInstructionComponent,
    CheckupListComponent,
    CheckupCaptureComponent,
    CheckupViewComponent,
    CheckupViewContainerComponent,
    HairAiCanvasDirective,
    HairAiReportComponent,
    CheckupViewExperimentComponent,
    DrawImageDirective,
    ScrollToViewDirective,
    DrawDetectionsDirective,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'instant-checkup',
        canActivate: [authenticationGuard, onboardingGuard],
        children: [
          { path: '', component: SelectCheckupTypeComponent },
          { path: 'preview', component: CheckupPhotoInstructionComponent },
          { path: 'capture', component: CheckupCaptureComponent },
          { path: 'list', component: CheckupListComponent },
          { path: ':id', component: CheckupViewContainerComponent },
        ],
      },
    ]),
    ToolbarModule,
    FormsModule,
    RouterModule,
    TranslateStringsModule,
    CustomPipeModule,
    DirectivesModule,
    FilePickerModule,
    CsIconModule,
    ButtonModule,
    VideoPlayerModule,
    LoadingModule,
    CameraModule,
    PopUpModalModule,
    SwiperModule,
    DeletePhotoSheetModule,
    WrongPhotoDetectedModule,
  ],
})
export class InstantCheckupModule { }
