import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { WrongPhotoDetectedComponent } from './wrong-photo-detected.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
  ],
  declarations: [WrongPhotoDetectedComponent],
  exports: [WrongPhotoDetectedComponent],
})
export class WrongPhotoDetectedModule { }
