import { Component } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { EventLoggerService } from '@services/event-logger-service';
import { DomSanitizer } from '@angular/platform-browser';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { fromActions } from '@store/actions';
import { AppConfig } from '../app.config';

@Component({
  selector: 'notice',
  templateUrl: './notice.html',
})
export class NoticeComponent {
  user: any;
  articleLinks: any;
  articleLink: any;
  price: string;
  cssLink: any;
  article: any;
  html: any;
  consultationService: any;
  selectedPlan: string;
  selectedConsultationServiceId: string;
  diet_1500_experiment: boolean = false;
  proPlanId: string;
  proPlanPrice: number;
  static parameters: any = [
    ConnectionService,
    BroadcastService,
    Router,
    DomSanitizer,
    AppConfig,
  ];
  constructor(
    private conn: ConnectionService,
    private broadcast: BroadcastService,
    private router: Router,
    private dom: DomSanitizer,
    protected appConfig: AppConfig,
    private store: Store,
    private eventLogger: EventLoggerService,
  ) {}
  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    this.articleLink = 'MMQlAYqq7c';
    // this.articleLinks = {
    //   en: 'MMQlAYqq7c',
    //   kn: 'ESHHWqMxVh',
    //   te: 'h2DnEcRPFO',
    //   hi: 'BSIDz7l0rJ',
    // };
    // const language = this.conn.currentWebSiteLanguage;
    this.selectedPlan = this.appConfig.Shared.Notice.dietPlans.BASIC;
    const article = await this.conn.getArticleById(this.articleLink, { translate: true });
    this.article = article;
    if (!article) {
      this.back();
      return;
    }
    if (article.get('cssLink')) {
      this.cssLink = this.dom.bypassSecurityTrustResourceUrl(article.get('cssLink'));
    }
    this.html = this.dom.bypassSecurityTrustHtml(article.get('html'));
    this.consultationService = await this.conn.fetchDietService();
    if (!this.consultationService) return;
    this.price = this.consultationService.get('amount');
    const experiment = await this.conn.fetchExperimentByKey('diet_1500');
    const dietExperiment = JSON.parse(JSON.stringify(experiment));
    if (dietExperiment.key) {
      const parsedVariant = JSON.parse(dietExperiment.variant);
      this.proPlanId = parsedVariant.proPlanId;
      this.proPlanPrice = parsedVariant.proPlanPrice;
      this.diet_1500_experiment = true;
    } else {
      this.diet_1500_experiment = false;
    }
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  buy(replaceUrl?: boolean): void {
    this.selectedConsultationServiceId = this.selectedPlan === this.appConfig.Shared.Notice.dietPlans.PRO
      ? this.proPlanId : this.consultationService.id;
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'buy-consultatioin' }));
    const payload: any = {
      userObjectId: this.user.id,
      paymentType: this.appConfig.Shared.Order.PaymentType.NOT_SELECTED,
      type: this.appConfig.Shared.Order.Type.CONSULTATION,
      uniqueId: uuid(),
      services: this.selectedConsultationServiceId,
    };
    this.store.dispatch(fromActions.CheckoutOrderBegin({ payload, replaceUrl }));
  }

  selectPlan(plan: string): void {
    if (this.selectedPlan === plan) {
      return;
    }
    this.selectedPlan = plan;
  }
}
