<div class="card tw-flex tw-flex-row tw-items-center tw-bg-white tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 tw-bg-yellow-100" (click)="handleClick()">
  <img class="tw-flex-none tw-h-20 tw-w-20 tw-rounded-full bg-black tw-bg-green-300" *ngIf='followUp.get("allocatedDoctor")?.get("DoctorImageThumbnail")' [src]='followUp.get("allocatedDoctor")?.get("DoctorImageThumbnail")' />
  <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-2">
    <div>
      <p class="tw-font-body tw-text-300 tw-text-black tw-font-bold" i18n="@@youCanTakeYourCheckupAnytime">
        You can take your checkup anytime
      </p>
    </div>
    <cs-button [class]="'tw-tracking-wide primary btn-xxxs btn-xxxs-padding tw-px-2 btn-float'"><cs-button-text i18n="@@takeCheckupNow">Take Checkup Now</cs-button-text></cs-button>
  </div>
</div>