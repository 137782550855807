import { Component } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeService } from '@services/time-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'acquisition-take-photo',
  templateUrl: './re-acquisition-take-photo.html',
})

export class ReAcquisitionTakePhotoComponent {
  currentUser: any;
  selectedConcern: 'face' | 'hair' = 'face';
  instantCheckup: Array<any>;
  constructor(
    private conn: ConnectionService,
    private storage: LocalStorageService,
    private broadcast: BroadcastService,
    private eventLogger: EventLoggerService,
    private timeService: TimeService,
    public appConfig: AppConfig,
    public localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    public commonUtilityHelper: CommonUtilityHelper) {
  }
  async ngOnInit(): Promise<void> {
    this.currentUser = this.conn.getActingUser();
    this.selectedConcern = this.route.snapshot.queryParams.selectedConcern || 'face';
    const userQuery = this.localStorageService.getValue('userQuery');
    this.instantCheckup = await this.conn.fetchInstantCheckup({
      userId: this.currentUser.id,
      limit: 1,
      dateRange: { startDate: this.timeService.removeDays(new Date(), 7), endDate: new Date() },
    });
    if (this.instantCheckup?.length) {
      await this.conn.createPendingCall({ type: this.appConfig.Shared.PendingCall.Type.RE_ACQUISITION_CALL, message: userQuery });
      await this.conn.navigateToURL('/re-acquisition/waiting-for-call');
      return;
    }
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 're-acquisition-take-photo' }));
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  submit(): void {
    if (!this.instantCheckup?.length) {
      this.broadcast.broadcast('NOTIFY', { message: 'No photos found' });
      return;
    }
    this.conn.navigateToURL('/re-acquisition/waiting-for-call');
  }

  openInstantCheckup(tag: string): void {
    const redirectUrl = this.router.url;
    this.localStorageService.setValue('CureSkin/redirectUrl', redirectUrl);
    this.router.navigate(['user/instantCheckup/preview'], { queryParams: { tag } });
  }
}
