<div class="tw-flex tw-flex-col tw-h-full tw-bg-teal-200 tw-bg-repeat-x tw-bg-top">
  <div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-gray-800 tw-rounded-[12px] tw-h-10 tw-w-10" (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
  </div>
  <div class="tw-flex-1 tw-p-6 tw-pb-7 tw-flex tw-flex-col">
    <div class="tw-font-body tw-font-bold tw-text-800 tw-text-gray-800 tw-pt-14 tw-mb-3" i18n="@@startJourney"> Start your healthy skin & hair journey </div>
    <img class="tw-block tw-w-[75px] tw-h-[25px]" src="https://storage.googleapis.com/heallocontent/app/img/cs-icon.svg" />
    <div class="tw-flex-1"></div>
    <div class="tw-font-body tw-text-300 tw-text-gray-800" i18n="@@enterYourMobileNumber"> Enter your mobile number to log in </div>
  </div>
  <div class="tw-flex-none tw-px-6 tw-pt-8 tw-pb-[38px] tw-bg-white tw-rounded-tr-5 tw-rounded-tl-5">
    <div class="tw-relative tw-mb-15">
      <p class="tw-absolute tw-h-12 tw-flex tw-justify-end tw-pl-4 tw-items-center tw-font-body tw-text-300 tw-text-gray-800"> +91 </p>
      <div class="tw-absolute tw-right-0 tw-h-12 tw-flex tw-justify-end tw-pr-4 tw-items-center">
        <cs-icon class="tw-h-5 tw-w-5" [iconName]="'/rebranding/v1/check'" [ngClass]="{'tw-animate-bounce_in_once tw-visible': mobileInput?.value.toString().length === 10, 'tw-invisible': mobileInput?.value.toString().length !== 10}"></cs-icon>
      </div>
      <input class="tw-h-12 tw-flex tw-bg-gray-80 tw-rounded-2.5 tw-m-0 tw-w-full tw-font-body tw-text-300 tw-text-gray-800 tw-pl-[42px] placeholder:tw-font-body tw-border tw-border-solid tw-border-gray-300" [ngClass]="{'tw-border-teal-400': mobileInput?.value.toString().length === 10}" #mobileInput="" id="phoneNumber" [(ngModel)]="phoneNumber" autocomplete="off" type="number" pattern="[0-9]*" autofocus="autofocus" (keydown)="commonUtilityHelper.limitInputByMaxDigit($event, 10)" placeholder="Mobile Number" i18n-placeholder="@@mobileNumber" />
    </div>
    <cs-button class="tw-mb-6 proceed tw-relative" [loading]="ui.loading" [class]="'tw-w-full primary'" [disabled]="mobileInput?.value.toString().length !== 10" (callback)="requestOTP()" id="continueBtn">
      <cs-button-text i18n="@@proceed">Proceed</cs-button-text>
    </cs-button>
    <div class="tw-font-body tw-text-100 tw-text-gray-500 tw-font-normal tw-mb-2" i18n="@@termsNew">
      <span>By proceeding, you consent to share your information with Cureskin and agree to Cureskin's</span>
      <span id="privacy-policy" class="tw-text-gray-500 pLR5 underline fw-700" (click)='navigateTo("/iframe?src=https://cdn.cureskin.com/privacypolicy_new.html")'>Privacy Policy</span>
      <span>and</span>
      <span id="terms-of-service" class="tw-text-gray-500 pLR5 underline fw-700" (click)='navigateTo("/iframe?src=https://cdn.cureskin.com/termsofservice_new.html")'>Terms of Service.</span>
    </div>
    <div class="tw-font-body tw-text-100 tw-text-gray-500 tw-font-normal"> This site is protected by reCAPTCHA Enterprise and the Google <a class="tw-text-gray-800" href="https://policies.google.com/privacy">Privacy Policy</a> and <a class="tw-text-gray-800" href="https://policies.google.com/terms">Terms of Service</a> apply. </div>
  </div>
</div>