<div class="feed-content" style="width: 100%; height: auto">
  <swiper [config]="carouselConfig"><ng-container *ngFor="let media of feed?.media"><ng-template swiperSlide="swiperSlide"><ng-container *ngIf="media?.type === appConfig.Shared.Feed.IMAGE"><feed-image id="{{index}}-feed" [feed]="media" (likeToggle)="updateIsLikedFromFeedImageComponent($event)" [isLiked]="isLiked"></feed-image></ng-container><ng-container *ngIf="media?.type === appConfig.Shared.Feed.VIDEO"><feed-video id="{{index}}-feed" [feed]="media" (likeToggle)="updateIsLikedFromFeedImageComponent($event)" [isLiked]="isLiked" [visible]="feed?.isVisible" (onVideoPlay)="onVideoPlay.emit($event)" [index]="index"></feed-video></ng-container></ng-template></ng-container></swiper>
</div>
<div class="feed-footer">
  <div class="caption">{{feed?.caption}}</div>
  <div class="feed-actions">
  <em class="cs-icon-unfavorite" *ngIf="!isLiked" (click)="onLikeClick()"></em><em class="cs-icon-favorite anim-fav" *ngIf="isLiked" (click)="onLikeClick()"></em>
  <div class="feed-btn" *ngIf="feed.ctaText && !isCtaDisabled" (click)="onCTAClick.emit(feed)">
    {{feed.ctaText}}
  </div>
  </div>
</div>