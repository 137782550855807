import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonTextDirective } from './button-text-directive';
import { ButtonComponent } from './button.component';
import { LoadingModule } from '../loading';

@NgModule({
  imports: [CommonModule, LoadingModule],
  declarations: [ButtonComponent, ButtonTextDirective],
  exports: [ButtonComponent, ButtonTextDirective],
})
export class ButtonModule {}
