import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'want-dr-checkup',
  templateUrl: './want-dr-checkup.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class WantDrCheckupComponent implements OnInit {
  @Output('openReAcquisitionPage') openReAcquisitionPage: EventEmitter<any> = new EventEmitter();
  constructor(
    public appConfig: AppConfig,
  ) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.openReAcquisitionPage.emit(true);
  }
}
