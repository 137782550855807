import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { NoticeComponent } from './notice.component';

@NgModule({
  imports: [
    CommonModule,
    ToolbarModule,
    ButtonModule,
    LoadingModule,
    CsIconModule,
  ],
  declarations: [NoticeComponent],
  exports: [],
})
export class NoticeModule {}
