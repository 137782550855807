<div class="discountTimer" *ngIf="!hideTimer">
  <div class="bold pR5 text-cap">{{text}}</div>
  <div class="dib flex">
    <div class="holder">
      <div>
        <div class="value">
          {{timer.days > 9 ? timer.days : '0'+timer.days}}
        </div>
        <div class="unit">days</div>
      </div>
      <div>:</div>
    </div>
    <div class="holder">
      <div>
        <div class="value">
          {{timer.hours > 9 ? timer.hours : '0'+timer.hours}}
        </div>
        <div class="unit">hrs</div>
      </div>
      <div>:</div>
    </div>
    <div class="holder">
      <div>
        <div class="value">
          {{timer.minutes > 9 ? timer.minutes : '0'+timer.minutes}}
        </div>
        <div class="unit">min</div>
      </div>
      <div>:</div>
    </div>
    <div class="holder">
      <div>
        <div class="value">
          {{timer.seconds > 9 ? timer.seconds : '0'+timer.seconds}}
        </div>
        <div class="unit">sec</div>
      </div>
    </div>
  </div>
</div>