import { ChangeDetectionStrategy, Component, Inject, InjectionToken, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const ICON_PATH = new InjectionToken<string>('icon-path');

@Component({
  selector: 'cs-icon',
  template: `
    <mat-icon style="height: unset; width: unset;"
          [svgIcon]="iconName"></mat-icon>
  `,
  styles: [':host {display: inline-flex;}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  private name: string = '';
  @Input() set iconName(icon: string) {
    if (!icon) return;
    this.name = icon;
    this.registerIcon(icon);
  }
  get iconName(): string {
    return this.name;
  }

  private iconPath: string = '';

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    // eslint-disable-next-line new-cap
    @Inject(ICON_PATH) iconPath: string,
  ) {
    this.iconPath = iconPath;
  }

  registerIcon(icon: string): void {
    const path = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.iconPath}/${icon}.svg`);
    this.iconRegistry.addSvgIcon(icon, path);
  }
}
