<div class="font-noto tw-flex tw-items-center tw-relative tw-px-2 tw-py-4" style="height: 300px">
  <img class="tw-rounded-full tw-absolute tw-mb-20 tw-left-0 tw-right-0 tw-m-auto tw-w-[120px] tw-z-[100] tw-top-[-60px]" src="https://storage.googleapis.com/heallo-other-user-files/doctor_api_mahima_aggarwal_12052022_1670250785248.jpg" />
  <div class="tw-absolute tw-p-1 tw-rounded-full tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-[15px] tw-right-[10px] close-sheet" style="width: 35px; height: 35px" (click)="closeBottomSheet()">
    <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
  </div>
  <div class="tw-w-full tw-bg-white rounded-lg tw-p-2 tw-flex tw-flex-col tw-pt-10">
    <div class="tw-text-center">
      <h2 class="tw-text-lg tw-font-semibold tw-text-700" i18n="@@welcomeBackFullName">Welcome back, {{data?.user?.get('PatientName')}}</h2>
      <p class="tw-my-2 tw-text-500 tw-text-gray-500" i18n="@@wantDoctorCheckup">
        Do you want to take a doctor checkup?
      </p>
    </div>
    <div class="tw-flex tw-justify-evenly">
      <cs-button class="tw-my-2" [class]="'w50 primary btn btn-padding tw-px-6 btn-float'" (click)="handleClick(true)"><cs-button-text><span class="tw-font-body" i18n="@@yes">Yes</span><span>&nbsp; ></span></cs-button-text></cs-button><cs-button class="tw-my-2" [class]="'w50 secondary btn btn-padding tw-px-6 btn-float'" (click)="handleClick(false)"><cs-button-text><span class="tw-font-body" i18n="@@no">No</span><span>&nbsp; ></span></cs-button-text></cs-button>
    </div>
  </div>
</div>