<div class="card tw-flex tw-flex-row tw-items-center tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 tw-bg-yellow-100" (click)="handleClick()">
  <img class="tw-flex-none tw-h-20 tw-w-20 tw-rounded-full bg-black tw-bg-green-300" *ngIf='followUp.get("allocatedDoctor")?.get("DoctorImageThumbnail")' [src]='followUp.get("allocatedDoctor")?.get("DoctorImageThumbnail")' />
  <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-2">
    <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@yourFollowUpScheduledDate">
      Your next checkup is scheduled on {{followUp.get('effectiveFollowUpDate')
    || followUp.get('nextFollowUpDate') | date: 'd'}}
      <sup>th</sup> {{followUp.get('effectiveFollowUpDate') ||
    followUp.get('nextFollowUpDate') | date: 'MMM'}} by
      {{followUp.get("allocatedDoctor")?.get("DoctorDisplayName")}}
    </p>
  </div>
</div>