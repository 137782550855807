import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, Renderer2 } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { ParseKeys, Table } from 'api-client';
import { TimeService } from '@services/time-service';
import { AppConfig } from '../app.config';

@Component({ selector: 'feedback', templateUrl: './feedback.html', styleUrls: ['./feedback.scss'] })
export class FeedbackComponent implements OnDestroy {
  @Input('disableBack') disableBack: boolean;
  @Output('close') close: EventEmitter<any> = new EventEmitter();
  @ViewChild('colouredBar', { static: false }) colouredBar: ElementRef;
  @ViewChild('progressBar', { static: false }) progressBar: ElementRef;
  subscriptions: Array<Subscription> = [];
  loading: boolean = false;
  user: any;
  fromUserConfirmation: boolean;
  products: any[] = [];

  feedback: any;
  pages: Array<Array<{ question: any; answers: Array<any> }>> = [];
  currentPageIndex: number = -1;
  currQuestionIndex: any = -1;
  disableNextButton: boolean = true;
  ratingArray: Array<string> = ['nps-rate-bad',
    'nps-rate-bad',
    'nps-rate-bad',
    'nps-rate-bad',
    'nps-rate-bad',
    'nps-rate-bad',
    'nps-rate-bad',
    'nps-rate-avg',
    'nps-rate-avg',
    'nps-rate-good',
    'nps-rate-good'];
  constructor(
    private appConfig: AppConfig,
    private appWebBridgeService: AppWebBridgeService,
    private activatedRoute: ActivatedRoute,
    private broadcast: BroadcastService,
    private conn: ConnectionService,
    private timeService: TimeService,
    private renderer: Renderer2,
    private eventLogger: EventLoggerService) { }

  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    this.fromUserConfirmation = this.close.length > 0;
    const { category }: any = this.activatedRoute.snapshot.params;
    if (category === 'NPD') {
      await this.loadQuestions(category);
      await this.fetchUserProducts();
    } else {
      await this.loadQuestions();
    }
    this.feedback = new Table.Feedback();

    this.feedback.set('type', this.appConfig.Shared.Feedback.Type.NPS);
    if (!this.activatedRoute.snapshot.queryParams.feedbackId) {
      await this.fetchLastFeedback();
    }
    this.subscribe();
    this.removeLikedProducts();
  }

  // Fetch last 3 months unique products ordered by user
  async fetchUserProducts(): Promise<void> {
    const products: any = {};
    const where: any = {
      user: this.user,
      stage: this.appConfig.Shared.Order.Stage.DELIVERED,
      deliveredOn: { $gte: this.timeService.removeDays(new Date(), 90) },
    };
    const select:Array<ParseKeys<Table.Order>> = ['orderNumber', 'products'];
    const orders = await this.conn.fetchOrders(where, select);
    orders.forEach((order: any): any => order.get('products').forEach((product: any): any => {
      products[product.id] = product;
    }));
    this.products = Object.values(JSON.parse(JSON.stringify(products)));
    this.products.push({ objectId: '000', title: 'None of the above' });
  }

  async selectOption(option: any, row_: { answers: Array<any>; question: any; }, event: any = undefined): Promise<void> {
    if (event) {
      this.changeColor(event, option);
    }
    const row = row_;
    const index = row.answers.findIndex((each: any): boolean => each === option);
    if (index >= 0) {
      row.answers.splice(index, 1);
    } else if (row.question.type === 'MULTI_SELECT') {
      row.answers.push(option);
    } else {
      row.answers = [option];
    }
    await this.updatePageAndQuestionSelection();
    if (row.question.type === 'MULTI_SELECT') return;
    await this.submitFeedback();
  }

  changeColor(event: any, option: string): void {
    const index = this.pages[this.currentPageIndex][0].question.options.indexOf(option);
    if (index === 2 || index === 3) {
      this.renderer.addClass(event.target, 'bg-yellow');
    }
    this.renderer.addClass(event.target, 'bg-green');
  }

  textFeedback(event: any, row_: { answers: Array<any> }): void {
    const row = row_;
    if (event.target.value) row.answers = [event.target.value];
    this.disableNextButton = !(event.target.value);
  }

  async saveScore(value: number, row_: { answers: Array<any> }): Promise<void> {
    const row = row_;
    row.answers = [value || 0];
    this.updatePageAndQuestionSelection();
    await this.submitFeedback();
    if (value >= 8) {
      this.appWebBridgeService.requestAppReview();
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'feedback-review' }));
    }
  }

  async submitFeedback(): Promise<void> {
    try {
      if (!this.feedback.has('user')) {
        this.feedback.set('user', this.user);
      }
      this.pages[this.currentPageIndex].forEach((row: any): void => {
        this.feedback.set(row.question.uniqueId, row.question.type === 'MULTI_SELECT' ? row.answers : row.answers[0]);
      });
      if (this.currentPageIndex === this.pages.length - 1) {
        this.feedback.set('stage', this.appConfig.Shared.Feedback.Stage.Finished);
      }
      this.loading = true;
      if (!this.conn.isInternalUser()) {
        await this.feedback.save();
      }
      this.updateProgressBar();
      if (this.currentPageIndex === this.pages.length - 1) {
        this.thankAndBack();
        return;
      }
      this.currentPageIndex += 1;
      this.currQuestionIndex = 1;
      this.removeLikedProducts();
      this.loading = false;
    } catch ({ message }) {
      this.loading = false;
      this.broadcast.broadcast('NOTIFY', { message });
      this.closeFeedback();
    }
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  thankAndBack(): void {
    if (this.feedback.get('score') >= 8) {
      const experienceQuestion = this.pages.reduce((result: any, page: Array<{ question: any }>): any => (result
        || page.find(({ question }: any): boolean => (question.uniqueId === 'experience'))?.question), undefined);
      const playStoreRequireExperience = experienceQuestion.options.slice(0, 2);
      if (playStoreRequireExperience.includes(this.feedback.get('experience'))) {
        this.appWebBridgeService.requestAppReview();
      }
    }
    this.currQuestionIndex = -1;
    this.broadcast.broadcast('NOTIFY', { message: 'Thanks for your valuable feedback' });
    this.closeFeedback();
  }

  sliderValue(value: any, row_: { answers: Array<any> }): any {
    const row = row_;
    row.answers = [value || 0];
    this.updatePageAndQuestionSelection();
  }

  async loadQuestions(category: string = 'NPS'): Promise<any> {
    const questions = (await this.conn.fetchFeedbackQuestions({ category })).map((each: any): any => each.toJSON());
    this.pages = questions.map((question: any): any => ([{ question, answers: [] }]));
    this.currentPageIndex = 0;
    this.currQuestionIndex = 0;
    this.updatePageAndQuestionSelection();
    this.updateFeedbackAnsweredQuestion();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((each: Subscription): void => each.unsubscribe());
    this.subscriptions = [];
  }

  private subscribe(): void {
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(async ({ feedbackId }: { feedbackId: string }): Promise<void> => {
      if (!feedbackId) {
        return;
      }
      [this.feedback] = await this.conn.fetchFeedback({
        where: {
          objectId: feedbackId,
          user: this.user,
          type: this.appConfig.Shared.Feedback.Type.NPS,
          stage: [this.appConfig.Shared.Feedback.Stage.Created, this.appConfig.Shared.Feedback.Stage.Partial],
        },
        limit: 1,
      });
      this.updateFeedbackAnsweredQuestion();
    }));
  }

  private updatePageAndQuestionSelection(): void {
    if (!this.feedback) {
      return;
    }
    if (this.currentPageIndex === this.pages.length) {
      this.thankAndBack();
      return;
    }
    const allAnswerAreStored = this.pages[this.currentPageIndex]
      .every((row: { question: any }): boolean => this.feedback.has(row.question.uniqueId));
    this.disableNextButton = this.pages[this.currentPageIndex].some((row: { answers: Array<any> }): boolean => !row.answers.length);
    if (!this.disableNextButton && allAnswerAreStored) {
      this.currentPageIndex += 1;
      this.currQuestionIndex = 0;
      this.updatePageAndQuestionSelection();
      return;
    }
    this.disableNextButton = this.disableNextButton && this.currentPageIndex < 1;
  }
  updateProgressBar(): void {
    const progressBarWidth = this.progressBar.nativeElement.getBoundingClientRect().width;
    const colouredBarWidth = (progressBarWidth / this.pages.length) * (this.currentPageIndex + 1);
    this.colouredBar.nativeElement.style.width = `${colouredBarWidth}px`;
  }

  private updateFeedbackAnsweredQuestion(): void {
    if (!this.feedback?.id) {
      return;
    }
    this.pages.forEach((rows: Array<{ answers: Array<any>, question: any; }>): void => {
      rows.forEach((row_: { answers: Array<any>, question: any; }): void => {
        const row = row_;
        if (this.feedback.has(row.question.uniqueId)) {
          row.answers = row.question.type === 'MULTI_SELECT'
            ? this.feedback.get(row.question.uniqueId)
            : [this.feedback.get(row.question.uniqueId)];
        }
      });
    });
    this.currentPageIndex = 0;
    this.currQuestionIndex = 0;
    this.updatePageAndQuestionSelection();
    this.updateProgressBar();
  }

  private closeFeedback(): void {
    if (!this.fromUserConfirmation) this.back();
    else this.close.emit();
  }

  private async fetchLastFeedback(): Promise<void> {
    const [feedback]: Array<any> = await this.conn.fetchFeedback({
      where: {
        user: this.user,
        type: this.appConfig.Shared.Feedback.Type.NPS,
        stage: [this.appConfig.Shared.Feedback.Stage.Created, this.appConfig.Shared.Feedback.Stage.Partial],
      },
      limit: 1,
    });
    if (!feedback) {
      return;
    }
    this.feedback = feedback;
    this.updateFeedbackAnsweredQuestion();
  }

  public isLastPage(): boolean {
    const currentPage = this.pages?.[this.currentPageIndex]?.[0];
    const isLastPage = this.currentPageIndex === ((this.pages?.length ?? 0) - 1);
    const isMultiSelect = currentPage?.question?.type === 'MULTI_SELECT';
    const hasAnswers = (currentPage?.answers?.length ?? 0) > 0;
    const isLoading = this.loading;

    return (isLastPage || (isMultiSelect && hasAnswers)) && !isLoading;
  }

  public isMultiSelectPage(): boolean {
    const currentPage = this.pages?.[this.currentPageIndex]?.[0];
    return currentPage?.question?.type === 'MULTI_SELECT' && this.pages?.[this.currentPageIndex]?.length > 0;
  }

  removeLikedProducts(): void {
    this.pages.forEach((row: Array<{ answers: Array<any>, question: any; }>): any => {
      row.forEach((r: { answers: Array<any>, question: any; }): any => {
        if (r?.question?.uniqueId === 'productLike' && r?.answers?.length) {
          this.products = this.products.filter((product: any): boolean => !r.answers.includes(product.objectId));
        }
      });
    });
    if (!this.products.some((product: any): boolean => product.objectId === '000')) {
      this.products.push({ objectId: '000', title: 'None of the above' });
    }
  }
}
