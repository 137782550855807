import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { RouterModule } from '@angular/router';
import { AboutComponent } from './about.component';
import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';

@NgModule({
  imports: [
    CommonModule,
    ToolbarModule,
    LoadingModule,
    CsIconModule,
    RouterModule.forChild([{
      path: 'about',
      canActivate: [authenticationGuard, onboardingGuard],
      children: [
        { path: '', component: AboutComponent },
        { path: 'cureskin', redirectTo: '/about', pathMatch: 'full' },
      ],
    }, {
      path: 'about-us', redirectTo: '/about', pathMatch: 'full',
    }]),
  ],
  declarations: [AboutComponent],
})
export class AboutModule { }
