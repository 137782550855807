<loading class="pos-abs vh-center" *ngIf="isLoading"></loading>
<!-- New innstruction page experiment-->
<div class="tw-bg-gray-100 tw-relative" *ngIf="!isLoading">
  <div class="tw-sticky tw-z-20 tw-top-[0px]" *ngIf="morningProducts.length || nightProducts.length">
    <div class="tw-flex tw-sticky tw-justify-around tw-bg-gray-200">
      <div class="tw-flex-1 tw-text-center tw-py-3" *ngFor="let day of weekArray" [ngClass]='{"tw-bg-white": +day?.date === todayDay, "tw-opacity-40": +day?.date !== todayDay }'>
        <p class="tw-font-body tw-text-100 tw-text-gray-900 tw-uppercase">
          {{ day?.dayValue }}
        </p>
        <p class="tw-font-body tw-text-600 tw-text-gray-900 tw-uppercase tw-font-bold">
          {{ day?.date }}
        </p>
        <p class="tw-font-body tw-text-100 tw-text-gray-900 tw-uppercase">
          {{ day?.month }}
        </p>
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-p-4 tw-sticky tw-top-12 tw-bg-white" *ngIf="!isDayTime">
      <div class="tw-flex-none tw-flex tw-flex-row tw-items-center tw-gap-1 tw-border tw-border-gray-100 tw-rounded-full tw-px-3 tw-py-2" *ngIf="morningProducts.length" (click)="onMorningClick()">
        <cs-icon class="tw-h-4 tw-w-4 tw-text-gray-400" [iconName]="'light_mode'"></cs-icon>
        <p class="tw-font-body tw-text-200 tw-text-gray-900 tw-uppercase" i18n="@@morning"> Morning </p>
      </div>
      <div class="tw-flex-none tw-flex tw-flex-row tw-items-center tw-gap-1 tw-border tw-border-black tw-rounded-full tw-bg-black tw-px-3 tw-py-2" *ngIf="nightProducts.length" (click)="onNightClick(nightElement)">
        <cs-icon class="tw-h-4 tw-w-4 tw-text-purple-200" [iconName]="'night_full'"></cs-icon>
        <p class="tw-font-body tw-text-200 tw-text-white tw-uppercase" i18n="@@night"> Night </p>
      </div>
    </div>
    <div class="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-p-4 tw-sticky tw-top-12 tw-bg-white" *ngIf="isDayTime">
      <div class="tw-flex-none tw-flex tw-flex-row tw-items-center tw-gap-1 tw-border tw-bg-blue-100 tw-rounded-full tw-px-3 tw-py-2" *ngIf="morningProducts.length" (click)="onMorningClick()">
        <cs-icon class="tw-h-4 tw-w-4 tw-text-yellow-400" [iconName]="'light_full'"></cs-icon>
        <p class="tw-font-body tw-text-200 tw-text-gray-900 tw-uppercase" i18n="@@morning"> Morning </p>
      </div>
      <div class="tw-flex-none tw-flex tw-flex-row tw-items-center tw-gap-1 tw-border tw-border-gray-200 tw-rounded-full tw-px-3 tw-py-2" *ngIf="nightProducts.length" (click)="onNightClick(nightElement)">
        <cs-icon class="tw-h-4 tw-w-4 tw-text-purple-200" [iconName]="'clear_night'"></cs-icon>
        <p class="tw-font-body tw-text-200 tw-text-gray-900 tw-uppercase" i18n="@@night"> Night </p>
      </div>
    </div>
  </div>
  <div *ngIf="!morningProducts.length" i18n="@@noMorningProductsTreatment">
    <p class="tw-px-3 tw-py-5 tw-font-body tw-text-300 tw-text-gray-900 tw-text-center"> No morning products in your treatment kit. </p>
  </div>
  <div *ngIf="!nightProducts.length" i18n="@@noNightProducts">
    <p class="tw-px-3 tw-py-5 tw-font-body tw-text-300 tw-text-gray-75 tw-text-center"> No night products in your treatment kit. </p>
  </div>
  <section class="tw-flex tw-flex-col tw-gap-1 tw-p-4 tw-bg-blue-100" *ngIf="morningProducts?.length">
    <div class="nightTab tw-absolute tw-top-[-120px]" id="morningBlock"></div>
    <ng-container *ngFor="let item of morningProducts; let i = index; let l = last;">
      <div class="tw-flex tw-gap-2">
        <cs-icon class="tw-h-4 tw-w-4 tw-text-yellow-400" [iconName]="'light_full'"></cs-icon>
        <div class="tw-font-body tw-text-200 tw-text-gray-900 tw-mb-1" [ngClass]='{"tw-line-through" : item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])}'>
          <span class="tw-font-bold tw-uppercase" i18n="@@step">step {{ i + 1 }} &nbsp;</span>
          <span>{{item.product.purpose ? item.product.purpose : ''}}</span>
        </div>
      </div>
      <div class="tw-border tw-border-gray-200 tw-relative tw-bg-white tw-rounded-md tw-mb-4">
        <div class="tw-flex">
          <div class="tw-flex-none tw-border-r tw-border-gray-200 tw-p-5 tw-pl-5 tw-w-24">
            <img class="tw-max-w-full tw-max-h-40 tw-object-contain"
            [src]="
              (item?.product?.rebrandedImageWithoutBackground?.length ?
              item.product.rebrandedImageWithoutBackground[item.product.rebrandedImageWithoutBackground.length - 1] :
              null)
              ||
              (item?.product?.productUnboxedImage?.length ?
              item.product.productUnboxedImage[item.product.productUnboxedImage.length - 1] :
              null)
            "
            *ngIf="item?.product"
          />
          </div>
          <div class="tw-flex-auto tw-p-5 tw-pr-4">
            <img class="tw-h-20 tw-w-20 tw-float-right" *ngIf="item.instructionSet.quantityImage?.length" [src]="item.instructionSet.quantityImage[0]" />
            <h2 class="tw-font-body tw-font-bold tw-text-300 tw-mb-4">
              {{item.product.title}}
            </h2>
            <p class="tw-font-body tw-text-100 tw-text-gray-500" *ngIf="item.instructionSet?.frequencyOfApplication?.includes(',')" i18n="@@alternateDays"> (Alternate Days) </p>
            <ul class="tw-list-disc tw-ml-4 tw-text-gray-900">
              <ng-container *ngIf="item.product?.reorderType === appConfig.Shared.Product.ReOrderType.LIMITED && changeInstructionForRepairProduct">
                <ng-container *ngIf="dayAfterDelivery === -1">
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint1">Instructions will be visible after product delivery.</span>
                  </li>
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint2">Please start using this 2 days after starting other products.</span>
                  </li>
                </ng-container>
                <ng-container *ngIf="dayAfterDelivery === 0">
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint1-D0">Instructions will be visible after 2 days.</span>
                  </li>
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint2-D0">Please continue using other products.</span>
                  </li>
                </ng-container>
                <ng-container *ngIf="dayAfterDelivery === 1">
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint1-D1">Instructions will be visible tomorrow.</span>
                  </li>
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint2-D0">Please continue using other products.</span>
                  </li>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="(item.product?.reorderType !== appConfig.Shared.Product.ReOrderType.LIMITED) || !changeInstructionForRepairProduct">
                <li *ngIf="item.instructionSet?.isCustomInstruction">
                  <span class="tw-break-all tw-font-body tw-text-200 tw-text-gray-900">{{item.instructionSet?.frequencyOfApplication}}</span>
                </li>
                <li *ngIf="item.instructionSet && item.instructionSet.quantityUnit?.length">
                  <span class="tw-font-body tw-text-200 tw-text-gray-900">{{item.instructionSet.quantity}}
                    {{item.instructionSet?.quantityUnit}}</span>
                </li>
                <li *ngIf="item.instructionSet?.areaOfApplication">
                  <span class="tw-font-body tw-text-200 tw-text-gray-900">{{item.instructionSet?.areaOfApplication}}</span>
                </li>
                <li *ngIf="item.instructionSet?.durationOfApplication">
                  <span class="tw-font-body tw-text-200 tw-text-gray-900">{{item.instructionSet?.durationOfApplication}}</span>
                </li>
              </ng-container>
            </ul>
            <ng-container *ngIf="user?.isPaid() && item.instructionSet?.paidInstructionVideo && (item.instructionSet?.paidInstructionVideo[userLanguage] || item.instructionSet?.paidInstructionVideo.en)">
              <div class="tw-flex tw-items-center tw-mt-1">
                <div class="tw-font-body tw-text-300 tw-text-green-400 tw-font-bold" i18n="@@playVideo" (click)="onPlayButtonClick(item)"> Play video </div>
                <img class="tw-ml-2" src="/assets/icons/video_play.svg" (click)="onPlayButtonClick(item)" />
              </div>
            </ng-container>
            <ng-container *ngIf="!user?.isPaid() && item.instructionSet?.unpaidInstructionVideo && (item.instructionSet?.unpaidInstructionVideo[userLanguage] || item.instructionSet?.unpaidInstructionVideo.en)">
              <div class="tw-flex tw-items-center tw-mt-1">
                <div class="tw-font-body tw-text-300 tw-text-green-400 tw-font-bold" i18n="@@playVideo" (click)="onPlayButtonClick(item)"> Play video </div>
                <img class="tw-ml-2" src="/assets/icons/video_play.svg" (click)="onPlayButtonClick(item)" />
              </div>
            </ng-container>
          </div>
        </div>
        <div class="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-items-center" id="overlay" [ngClass]='{"tw-bg-[#ffffff99] tw-flex" : item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])}' [ngStyle]='{ "display" : item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal]) ? "block" : "none" } '>
          <div class="tw-bg-orange-100 tw-flex-1 tw-backdrop-blur-sm tw-absolute tw-w-full tw-m-auto tw-text-center tw-p-3 tw-top-[50%] tw-left-[50%] tw-translate-x-[-50%] tw-translate-y-[-50%]" *ngIf="item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])">
            <p class="tw-text-200 tw-text-orange-400 tw-font-bold" i18n="@@notToUseToday"> Not to be used today! </p>
            <p class="tw-text-200 tw-text-gray-500" i18n="@@checkTomorrow"> Please check back tomorrow. </p>
          </div>
        </div>
      </div>
    </ng-container>
  </section>
  <!--night-->
  <section class="tw-flex tw-flex-col tw-gap-1 tw-p-4 tw-bg-black tw-relative" *ngIf="nightProducts?.length" #nightElementRelative="">
    <div class="nightTab tw-absolute tw-top-[-148px]" id="nightBlockScroll" #nightElement=""></div>
    <ng-container *ngFor="let item of nightProducts; let i = index; let l = last;">
      <div class="tw-flex tw-gap-2">
        <cs-icon class="tw-h-4 tw-w-4 tw-text-purple-400" [iconName]="'night_full'"></cs-icon>
        <div class="tw-font-body tw-text-200 tw-text-white tw-mb-1" [ngClass]='{"tw-line-through" : item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])}'>
          <span class="tw-font-bold tw-uppercase" i18n="@@step">step {{ i + 1 }} &nbsp;</span>
          <span>{{item.product.purpose ? item.product.purpose : ''}}</span>
        </div>
      </div>
      <div class="tw-border tw-border-gray-200 tw-relative tw-bg-white tw-rounded-md tw-mb-4">
        <div class="tw-flex">
          <div class="tw-flex-none tw-border-r tw-border-gray-200 tw-p-5 tw-pl-5 tw-w-24">
            <img class="tw-max-w-full tw-max-h-40 tw-object-contain"
            [src]="
              (item?.product?.rebrandedImageWithoutBackground?.length ?
              item.product.rebrandedImageWithoutBackground[item.product.rebrandedImageWithoutBackground.length - 1] :
              null)
              ||
              (item?.product?.productUnboxedImage?.length ?
              item.product.productUnboxedImage[item.product.productUnboxedImage.length - 1] :
              null)
            "
            *ngIf="item?.product">
          </div>
          <div class="tw-flex-auto tw-p-5 tw-pr-4">
            <img class="tw-h-20 tw-w-20 tw-float-right" *ngIf="item.instructionSet.quantityImage?.length" [src]="item.instructionSet.quantityImage[0]" />
            <h2 class="tw-font-body tw-font-bold tw-text-300 tw-mb-4">
              {{item.product.title}}
            </h2>
            <p class="tw-font-body tw-text-100 tw-text-gray-500" *ngIf="item.instructionSet?.frequencyOfApplication?.includes(',')" i18n="@@alternateDays"> (Alternate Days) </p>
            <ul class="tw-list-disc tw-ml-4 tw-text-gray-900">
              <ng-container *ngIf="item.product?.reorderType === appConfig.Shared.Product.ReOrderType.LIMITED && changeInstructionForRepairProduct">
                <ng-container *ngIf="dayAfterDelivery === -1">
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint1">Instructions will be visible after product delivery.</span>
                  </li>
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint2">Please start using this 2 days after starting other products.</span>
                  </li>
                </ng-container>
                <ng-container *ngIf="dayAfterDelivery === 0">
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint1-D0">Instructions will be visible after 2 days.</span>
                  </li>
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint2-D0">Please continue using other products.</span>
                  </li>
                </ng-container>
                <ng-container *ngIf="dayAfterDelivery === 1">
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint1-D1">Instructions will be visible tomorrow.</span>
                  </li>
                  <li>
                    <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint2-D0">Please continue using other products.</span>
                  </li>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="(item.product?.reorderType !== appConfig.Shared.Product.ReOrderType.LIMITED) || !changeInstructionForRepairProduct">
                <li *ngIf="item.instructionSet?.isCustomInstruction">
                  <span class="tw-break-all tw-font-body tw-text-200 tw-text-gray-900">{{item.instructionSet?.frequencyOfApplication}}</span>
                </li>
                <li *ngIf="item.instructionSet && item.instructionSet.quantityUnit?.length">
                  <span class="tw-font-body tw-text-200 tw-text-gray-900">{{item.instructionSet.quantity}}
                    {{item.instructionSet?.quantityUnit}}</span>
                </li>
                <li *ngIf="item.instructionSet?.areaOfApplication">
                  <span class="tw-font-body tw-text-200 tw-text-gray-900">{{item.instructionSet?.areaOfApplication}}</span>
                </li>
                <li *ngIf="item.instructionSet?.durationOfApplication">
                  <span class="tw-font-body tw-text-200 tw-text-gray-900">{{item.instructionSet?.durationOfApplication}}</span>
                </li>
              </ng-container>
            </ul>
            <ng-container *ngIf="user?.isPaid() && item.instructionSet?.paidInstructionVideo && (item.instructionSet?.paidInstructionVideo[userLanguage] || item.instructionSet?.paidInstructionVideo.en)">
              <div class="tw-flex tw-items-center tw-mt-1">
                <div class="tw-font-body tw-text-300 tw-text-green-400 tw-font-bold" i18n="@@playVideo" (click)="onPlayButtonClick(item)"> Play video </div>
                <img class="tw-ml-2" src="/assets/icons/video_play.svg" (click)="onPlayButtonClick(item)" />
              </div>
            </ng-container>
            <ng-container *ngIf="!user?.isPaid() && item.instructionSet?.unpaidInstructionVideo && (item.instructionSet?.unpaidInstructionVideo[userLanguage] || item.instructionSet?.unpaidInstructionVideo.en)">
              <div class="tw-flex tw-items-center tw-mt-1">
                <div class="tw-font-body tw-text-300 tw-text-green-400 tw-font-bold" i18n="@@playVideo" (click)="onPlayButtonClick(item)"> Play video </div>
                <img class="tw-ml-2" src="/assets/icons/video_play.svg" (click)="onPlayButtonClick(item)" />
              </div>
            </ng-container>
          </div>
        </div>
        <div class="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-items-center" id="overlay" [ngClass]='{"tw-bg-[#ffffff99] tw-flex" : item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])}' [ngStyle]='{ "display" : item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal]) ? "block" : "none" } '>
          <div class="tw-bg-orange-100 tw-flex-1 tw-backdrop-blur-sm tw-absolute tw-w-full tw-m-auto tw-text-center tw-p-3 tw-top-[50%] tw-left-[50%] tw-translate-x-[-50%] tw-translate-y-[-50%]" *ngIf="item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])">
            <p class="tw-text-200 tw-text-orange-400 tw-font-bold"> Not to be used today! </p>
            <p class="tw-text-200 tw-text-gray-500"> to be used on {{item.instructionSet.displayDays}}
            </p>
          </div>
        </div>
      </div>
    </ng-container>
  </section>
</div>
<!--thumbs up/down-->
<div class="card tw-flex tw-gap-2 tw-rounded-2xl tw-px-6 tw-pb-4 tw-mx-4 tw-mt-3 tw-bg-yellow-100 tw-relative tw-pt-7 tw-pr-8" *ngIf="thumbsUpDown">
  <cs-icon class="tw-h-6 tw-w-6 tw-absolute tw-right-2 tw-top-2 tw-opacity-50" [iconName]='"close"' (click)="thumbsUpDown = false"></cs-icon>
  <p class="tw-font-body tw-flex-1 tw-text-400 tw-text-black" i18n="@@areTheseHelpful"> Are these instructions helpful? </p>
  <div class="tw-flex-none">
    <cs-button class="tw-border tw-rounded-full tw-border-black tw-text-green-400 tw-min-w-0" [class]="'btn-xs'" (callback)="thumpsUpDownRes(true)">
      <cs-button-text i18n="@@yes">Yes</cs-button-text>
    </cs-button>
  </div>
  <div class="tw-flex-none">
    <cs-button class="tw-border tw-rounded-full tw-border-black tw-text-green-400 tw-min-w-0" [class]="'btn-xs'" (callback)="thumpsUpDownRes(false)">
      <cs-button-text i18n="@@no">No</cs-button-text>
    </cs-button>
  </div>
</div>
<!-- diet banner-->
<section class="tw-p-6 tw-pb-4 tw-bg-white" *ngIf="!isLoading && user?.isPaid()">
  <div class="tw-bg-green-100 tw-bg-grid-layout-doctor tw-bg-right tw-bg-repeat-y tw-rounded-lg pos-rel" (click)="myDietNew()">
    <div class="pos-abs tw-py-6 tw-px-5">
      <p class="tw-font-body tw-text-100 tw-text-gray-900 tw-tracking-wider tw-uppercase tw-mb-3" *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimen.class"> for healthy hair </p>
      <p class="tw-font-body tw-text-100 tw-text-gray-900 tw-tracking-wider tw-uppercase tw-mb-3" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimen.class"> for healthy skin </p>
      <h2 class="tw-font-body tw-font-bold tw-text-600 tw-text-gray-900"> Diet &amp; lifestyle guidance </h2>
      <p class="tw-font-body tw-text-300 tw-text-gray-900 tw-mb-8"> given by your doctor </p>
      <button class="tw-px-4 tw-py-2 tw-bg-green-500 tw-font-body tw-font-bold tw-text-gray-50 tw-text-200 tw-flex tw-rounded-full m0 mT20 tw-h-8 tw-bg-green-400">
        <span class="tw-tracking-normal">view here</span>
        <cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon>
      </button>
    </div>
    <img src="https://storage.googleapis.com/heallocontent/healthy-diet.gif" />
  </div>
</section>
<div class="video-container tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-hidden tw-z-50">
  <div class="tw-bg-black tw-absolute tw-inset-0"></div>
  <video class="tw-h-full tw-w-full tw-object-contain tw-max-w-full tw-max-h-full tw-relative tw-z-10" #videoPlayer controls="controls"></video>
  <button class="tw-absolute tw-top-2 tw-right-1 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-end tw-text-white tw-font-bold tw-text-xl tw-z-10 close-video-button" (click)="closeVideo()">
    <svg class="tw-h-6 tw-w-6" fill="currentColor" viewBox="0 0 24 24">
      <path d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
    </svg>
  </button>
</div>