import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { DirectivesModule } from '@directives/directives.module';
import { OrderUpdateComponent } from './order-update.component';

@NgModule({
  imports: [CommonModule, LoadingModule, DirectivesModule, CustomPipeModule],
  declarations: [OrderUpdateComponent],
  exports: [OrderUpdateComponent],
})
export class OrderUpdateModule {}
