import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '@components/loading';
import { DirectivesModule } from '@directives/directives.module';
import { ImageCardComponent } from './image-card.component';

@NgModule({
  imports: [CommonModule, RouterModule, LoadingModule, DirectivesModule],
  declarations: [ImageCardComponent],
  exports: [ImageCardComponent],
})
export class ImageCardModule {}
