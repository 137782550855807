import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { HairFallBannerComponentComponent } from './hair-fall-banner.component';

@NgModule({
  declarations: [HairFallBannerComponentComponent],
  imports: [
    CommonModule,
    CsIconModule,
  ],
  exports: [HairFallBannerComponentComponent],
})

export class HairFallBannerModule { }
