<div class="font-noto tw-flex tw-items-center tw-relative tw-px-2 tw-py-4">
  <div class="tw-rounded-full tw-aspect-square tw-absolute tw-mb-20 tw-z-[100] -tw-top-18 tw-left-4 tw-bg-white tw-p-1 tw-overflow-hidden">
  <img class="tw-w-28 tw-object-cover" src="https://cdn.cureskin.com/app/img/dr_charu_single.png" (click)="handleClick()" />
  </div>
  <div class="tw-absolute tw-p-1 tw-rounded-full tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-[15px] tw-right-[10px] close-sheet" style="width: 35px; height: 35px" (click)="closeBottomSheet()">
    <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
  </div>
  <div class="tw-w-full tw-bg-white rounded-lg tw-p-2 tw-flex tw-flex-col tw-pt-10" (click)="handleClick()">
    <div>
      <h2 class="tw-text-lg tw-font-semibold tw-text-700 tw-mt-2" *ngIf="appConfig.Shared.Regimen.Class.FACE === regimenClass" i18n="@@completeYourFaceCheckup">
        Complete your Face Checkup
      </h2>
      <h2 class="tw-text-lg tw-font-semibold tw-text-700 tw-mt-2" *ngIf="appConfig.Shared.Regimen.Class.HAIR === regimenClass" i18n="@@completeYourHairCheckup">
        Complete your Hair Checkup
      </h2>
      <p class="tw-my-2 tw-text-400 tw-text-gray-500" i18n="@@doctorWaitingForChat">
        Your doctor is waiting for some answers to create the right treatment
        kit for you.
      </p>
    </div>
    <cs-button class="tw-my-2" [class]="'w100 primary btn btn-padding tw-px-6 btn-float'"><cs-button-text><span class="tw-font-body tw-tracking-normal" i18n="@@continueChat">Continue</span><span>&nbsp; ></span></cs-button-text></cs-button>
  </div>
</div>
