<div class="tw-block tw-border-spacing-0 tw-w-full tw-flex-1 tw-h-screen tw-overflow-hidden tw-font-body">
  <div class="tw-flex tw-flex-col tw-flex-1">
    <div class="tw-w-full tw-flex-initial">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="menuAction()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@help">Help</span>
      </div>
    </div>
    <div class="tw-w-full tw-flex-auto tw-overflow-y-auto tw-overflow-x-hidden tw-pt-4">
      <div class="tw-p-4 tw-pl-1 tw-flex tw-items-center tw-justify-center">
        <div class="tw-flex tw-justify-center tw-items-center tw-w-14">
          <em class="cs-icon-mail tw-text-blue-500 tw-text-600"></em>
        </div>
        <div class="tw-flex-1 tw-text-300 tw-text-black" i18n="@@forSupportMail">
          For support mail to &nbsp;<a class="tw-text-blue-300 tw-text-200" [href]="mailLink">hello&#64;cureskin.com</a>
        </div>
      </div>
      <div class="tw-p-4 tw-pl-1 tw-flex tw-items-center tw-justify-center" *ngIf="user?.get('orderState') !== appConfig.Shared.User.OrderState.DELIVERED">
        <div class="tw-flex tw-justify-center tw-items-center tw-w-14">
          <em class="cs-icon-phone-in-talk tw-text-blue-500 tw-text-600"></em>
        </div>
        <div class="tw-flex-1">
          <div class="tw-text-300 tw-text-black tw-mb-1" i18n="@@contactHelp">
            For any queries call us on<a class="tw-text-blue-300 tw-text-200 tw-pl-1" href="tel:080-4680-9361">080-4680-9361</a>
          </div>
          <div class="tw-text-200" i18n="@@callTiming">* Timing 10AM - 7PM</div>
        </div>
      </div>
      <div class="tw-p-4 tw-pl-1 tw-flex tw-items-center tw-justify-center" rippleEffect="rippleEffect" (callback)='open("/faq")'>
        <div class="tw-flex tw-justify-center tw-items-center tw-w-14">
          <em class="cs-icon-help tw-text-blue-500 tw-text-600"></em>
        </div>
        <div class="tw-flex-1 tw-text-300 tw-text-black" i18n="@@frequentlyAsked">
          Frequently Asked Questions
        </div>
      </div>
      <div class="tw-p-4 tw-pl-1 tw-flex tw-items-center tw-justify-center" rippleEffect="rippleEffect" (callback)='open("/feedback")'>
        <div class="tw-flex tw-justify-center tw-items-center tw-w-14">
          <em class="cs-icon-feedback tw-text-blue-500 tw-text-600"></em>
        </div>
        <div class="tw-flex-1 tw-text-300 tw-text-black" i18n="@@feedback">Feedback</div>
      </div>
      <div class="tw-p-4 tw-pl-1 tw-flex tw-items-center tw-justify-center" rippleEffect="rippleEffect" (callback)='open("/doctors")'>
        <div class="tw-flex tw-justify-center tw-items-center tw-w-14">
          <em class="cs-icon-person tw-text-blue-500 tw-text-600"></em>
        </div>
        <div class="tw-flex-1 tw-text-300 tw-text-black" i18n="@@aboutDoctors">About Doctors</div>
      </div>
      <div class="tw-p-4 tw-pl-1 tw-flex tw-items-center tw-justify-center" rippleEffect="rippleEffect" (callback)='open("/about/cureskin")'>
        <div class="tw-flex tw-justify-center tw-items-center tw-w-14">
          <em class="cs-icon-info tw-text-blue-500 tw-text-600"></em>
        </div>
        <div class="tw-flex-1 tw-text-300 tw-text-black" i18n="@@aboutUs">About Us</div>
      </div>
      <div class="tw-p-4 tw-pl-1 tw-flex tw-items-center tw-justify-center anim-fadeIn" (callback)="startAgain()" rippleEffect="rippleEffect" *ngIf="showRestartButton">
        <div class="tw-flex tw-justify-center tw-items-center tw-w-14">
          <em class="cs-icon-refresh tw-text-blue-500 tw-text-600"></em>
        </div>
        <div class="tw-flex-1 tw-text-300 tw-text-black" i18n="@@iHaveEnteredDetailsWrong">
          I have entered the wrong details. Clear and restart (restart allowed
          only one time)
        </div>
      </div>
    </div>
  </div>
</div>