import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CameraComponent } from './camera.component';
import { ButtonModule } from '../button';

@NgModule({ imports: [
  CommonModule,
  ButtonModule,
],
declarations: [CameraComponent],
exports: [CameraComponent] })
export class CameraModule {}
