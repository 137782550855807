import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@components/loading';
import { SupportRegimenClassModule } from '@shared/support-regimen-class/support-regimen-class.module';
import { DoctorProfileModule } from '@shared/doctor-profile/doctor-profile.module';
import { ProductListModule } from '@shared/product-list/product-list.module';
import { RegimenInstructionModule } from '@shared/regimen-instruction/regimen-instruction.module';
import { GetSupportQueryModule } from '@shared/get-support-query/get-support-query.module';
import { ShopByCategoryModule } from '@shared/shop-by-category/shop-by-category.module';
import { OrderUpdateModule } from '@shared/order-update/order-update.module';
import { SupportViewEditOrderModule } from '@shared/support-view-edit-order/support-view-edit-order.module';
import { SupportComponentSelectionComponent } from './support-component-selection.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    FormsModule,
    SupportRegimenClassModule,
    DoctorProfileModule,
    LoadingModule,
    ProductListModule,
    RegimenInstructionModule,
    GetSupportQueryModule,
    ShopByCategoryModule,
    OrderUpdateModule,
    SupportViewEditOrderModule,
  ],
  declarations: [SupportComponentSelectionComponent],
  exports: [SupportComponentSelectionComponent],
})
export class SupportComponentSelectionModule {}
