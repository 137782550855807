import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class LoggingService {
  constructor() { }

  log(message: string): void {
    console.log(message);
  }

  error(message: string): void {
    console.error(message);
  }

  warn(message: string): void {
    console.warn(message);
  }

  info(message: string): void {
    console.info(message);
  }
}
