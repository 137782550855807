<div class="tw-bg-white tw-text-center tw-p-[30px]" (click)="stopPropagation($event)">
    <div class="tw-text-[1.35rem] tw-font-medium" i18n="@@thisProductCannotBeReordered">
        This product cannot be self-reordered.
    </div>
    <div class="tw-mt-[10px]" i18n="@@pleaseChatWithUsDuringCheckup">
        Please chat with us or discuss with doctor during the monthly check-up to
        reorder.
    </div>
    <div class="tw-w-[90%] tw-mx-auto tw-mt-[15px]">
        <cs-button (callback)="closeDialog()" [class]="'w100 primary'"><cs-button-text i18n="@@gotIt">Got it</cs-button-text></cs-button>
    </div>
</div>