<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="tw-flex tw-flex-col tw-h-full tw-overflow-hidden tw-bg-gray-100">
  <div class="tw-flex-none tw-bg-grid-layout tw-bg-yellow-100 tw-pt-20 tw-pb-[60px]">
    <div class="tw-font-body tw-font-bold tw-text-600 tw-tracking-normal tw-text-center" i18n="@@myOrders">My orders</div>
  </div>
  <div class="tw-flex-none tw-flex tw-px-6 tw-gap-2 tw-relative tw-z-10 tw-mt-[-20px]">
    <div class="tw-h-10 tw-flex tw-items-center tw-justify-center tw-px-4 tw-rounded-full" [ngClass]="{'tw-bg-white tw-shadow-z1': activeOrderType !== orderType.active, 'tw-bg-black tw-text-white': activeOrderType === orderType.active }" (click)="changeOrderType(orderType.active)">
      <span i18n="@@active">active</span>
    </div>
    <div class="tw-h-10 tw-flex tw-items-center tw-justify-center tw-px-4 tw-rounded-full" [ngClass]="{'tw-bg-white tw-shadow-z1': activeOrderType !== orderType.completed, 'tw-bg-black tw-text-white': activeOrderType === orderType.completed }" (click)="changeOrderType(orderType.completed)">
      <span i18n="@@completed">completed</span>
    </div>
    <div class="tw-h-10 tw-flex tw-items-center tw-justify-center tw-px-4 tw-rounded-full" [ngClass]="{'tw-bg-white tw-shadow-z1': activeOrderType !== orderType.cancelled, 'tw-bg-black tw-text-white': activeOrderType === orderType.cancelled }" (click)="changeOrderType(orderType.cancelled)">
      <span i18n="@@cancelled">cancelled</span>
    </div>
  </div>
  <div class="tw-flex-auto tw-relative tw-overflow-y-auto">
    <loading class="pos-abs vh-center" *ngIf="isLoading"></loading>
    <div class="tw-flex tw-flex-col tw-gap-3 tw-pt-5 tw-px-5 tw-mb-6" *ngIf="!isLoading">
      <div class="empty-hint centerA empty-icon" *ngIf="!orders?.length">
        <span class="tw-font-body tw-text-300" i18n="@@noOrdersAvailable">No orders available</span>
      </div>
      <div class="tw-rounded-2xl tw-p-4 tw-border tw-border-gray-200 tw-shadow-z1 tw-bg-white" *ngFor="let order of orders" routerLink="/user/order/{{order?.id}}">
        <div class="tw-flex tw-justify-between tw-mb-4">
          <div class="tw-flex tw-flex-col tw-items-start tw-gap-1">
            <ng-container [ngSwitch]="true">
              <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none" *ngSwitchCase="order.get('stage') === 'INITIAL'" i18n="@@orderNotPlacedYet">
                Order not placed yet
              </p>
              <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none" *ngSwitchDefault>
                <span i18n="@@orderNumber">Order number</span><span>: {{'#'+order.get('orderNumber')}}</span>
              </p>
            </ng-container><ng-container [ngSwitch]="true">
              <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))" i18n="@@paymentPending">
                Payment pending
              </p>
              <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!orderDeliveredStages.includes(order.get('stage')) && !!order.get('deliverOnDate')">
                <span i18n="@@expectedDelivery">Expected delivery</span><span>: {{order.get('deliverOnDate') || order.get('deliverOnDate')
          | date: 'dd MM'}}</span>
              </p>
              <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage')) && !!order.get('deliveredOn')">
                <span i18n="@@deliveredOn">Delivered on</span><span>: {{order.get('deliveredOn') || order.get('deliveredOn') |
          date: 'dd MM'}}</span>
              </p>
              <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderCanceledStages.includes(order.get('stage')) && !order.get('canceledByUser')" i18n="@@placeANewOrder">
                Place a new order
              </p>
              <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderCanceledStages.includes(order.get('stage')) && !!order.get('canceledByUser')" i18n="@@userCancelled">
                User cancelled
              </p>
              <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchDefault>
                -
              </p>
            </ng-container>
          </div>
          <ng-container [ngSwitch]="true">
            <div class="tw-rounded tw-border tw-border-yellow-400 tw-bg-yellow-100 tw-h-6 tw-flex tw-items-center tw-px-3" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))">
              <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@paymentPending">
                Payment pending
              </p>
            </div>
            <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-flex tw-items-center tw-px-3" *ngSwitchCase="orderPlacedStages.includes(order.get('stage'))">
              <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@orderPlaced">
                Order placed
              </p>
                  </div>
                        <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-px-3" *ngSwitchCase="orderPackedStages.includes(order.get('stage'))">
                            <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@packed">
                                Packed
                            </p>
                        </div>
                        <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-px-3" *ngSwitchCase="orderDispatchedStages.includes(order.get('stage'))">
                            <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@dispatched">
                                Dispatched
                            </p>
                        </div>
                        <div class="tw-rounded tw-border tw-border-red-400 tw-bg-red-100 tw-h-6 tw-flex tw-items-center tw-px-3" *ngSwitchCase="orderOutForDeliveryStages.includes(order.get('stage'))">
                            <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@outForDelivery">
                                Out For Delivery
                            </p>
                        </div>
                        <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-px-3" *ngSwitchCase="orderDeliveryFailedStages.includes(order.get('stage'))">
                            <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@deliveryAttemptFailed">
                                Delivery attempt failed
                            </p>
                        </div>
                        <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))">
                            <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@delivered">Delivered</p>
                            <div class="tw-rounded-full tw-bg-green-400 tw-h-4">
                                <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'done'"></cs-icon>
                            </div>
                        </div>
                        <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngSwitchCase="orderCanceledStages.includes(order.get('stage'))">
                            <p class="tw-font-body tw-text-200 tw-text-gray-500" i18n="@@canceled">
                                Canceled
                            </p>
                            <div class="tw-rounded-full tw-bg-red-400 tw-h-4">
                                <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'close'"></cs-icon>
                            </div>
                        </div>
                        <div class="tw-rounded tw-flex tw-items-center tw-gap-1 tw-bg-gray-100 tw-border tw-border-gray-300 tw-px-1" *ngSwitchDefault>
                            <p class="tw-font-body tw-text-200 tw-text-gray-500">
                                {{order.get('stage').replace('_', ' ') | titlecase}}
                            </p>
                        </div>
                    </ng-container>
                </div>
                <div class="tw-mb-4 tw-flex tw-flex-col tw-gap-2">
                    <div class="tw-flex tw-items-center tw-gap-2" *ngFor="let product of order.get('productInfo')">
                        <img class="tw-h-auto tw-max-h-10 tw-max-w-auto" [src]="product?.image" />
                        <p class="tw-font-body tw-text-300">{{product?.quantity}}</p>
                        <cs-icon class="tw-h-4 tw-w-4 tw-text-gray-800" [iconName]='"close"'></cs-icon>
                        <p class="tw-font-body tw-text-300">{{product?.name}}</p>
                    </div>
                </div>
                <ng-container [ngSwitch]="true">
                    <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))">
                        <button class="tw-flex-none tw-h-8 tw-flex tw-item-center tw-justify-center tw-bg-green-400 tw-rounded-full tw-m-0" (click)="makePaymentForOrder(order)">
                            <span class="tw-font-body tw-text-200 tw-text-white tw-font-bold tw-tracking-normal" i18n="@@makePayment">Make payment</span></button><cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                    </div>
                    <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="expectedDeliveryDateStages.includes(order.get('stage')) && order.get('paymentType') === 'COD'">
                        <div class="tw-flex tw-flex-col">
                            <p class="tw-font-body tw-text-300">
                                <span class="tw-text-black" i18n="@@amountToBePaid">Amount to be paid</span><span class="tw-font-body tw-font-bold tw-text-green-500">: ₹{{order.get('amount')}}</span>
                            </p>
                            <p class="tw-font-body tw-text-300" *ngIf="order.get('paymentType') === 'COD'">
                                (Cash on delivery)
                            </p>
                        </div>
                        <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                    </div>
                    <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="expectedDeliveryDateStages.includes(order.get('stage')) && order.get('paymentType') !== 'COD'">
                        <p class="tw-font-body tw-text-300">
                            <span class="tw-text-black" i18n="@@amountPaid">Amount Paid</span><span class="tw-font-body tw-font-bold tw-text-green-400">: ₹{{order.get('amount')}}</span>
                        </p>
                        <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                    </div>
                    <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))">
                        <p class="tw-font-body tw-text-300">
                            <span class="tw-text-black" i18n="@@amountPaid">Amount Paid</span><span class="tw-font-body tw-font-bold">: ₹{{order.get('amount')}}</span>
                        </p>
                        <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                    </div>
                    <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchDefault>
                        <p class="tw-font-body tw-text-300">
                            <span class="tw-text-black" i18n="@@orderAmount">Order amount</span><span class="tw-font-body tw-font-bold">: ₹{{order.get('amount')}}</span>
                        </p>
                        <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>