import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class TimeService {
  pipe: DatePipe;

  static parameters: any = [];
  constructor() {
    this.pipe = new DatePipe('en-US');
  }

  isAfter(date1: Date, date2: Date, granularity?: string): boolean {
    if (!date1 || !date2) return false;
    const compareDate1 = new Date(date1.getTime());
    const compareDate2 = new Date(date2.getTime());
    if (['day'].includes(granularity)) {
      compareDate1.setHours(0, 0, 0, 0);
      compareDate2.setHours(0, 0, 0, 0);
    }
    return compareDate1.getTime() > compareDate2.getTime();
  }

  addDays(date: Date, days: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setDate(date.getDate() + days);
    return newDate;
  }

  removeDays(date: Date, days: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setDate(date.getDate() - days);
    return newDate;
  }

  removeMonths(date: Date, months: number): Date {
    date.setMonth(date.getMonth() - months);
    return date;
  }

  getStartOfDay(): Date {
    const start = new Date();
    start.setUTCHours(0, 0, 0, 0);
    return start;
  }

  getEndOfDay(): Date {
    const end = new Date();
    end.setUTCHours(23, 59, 59, 999);
    return end;
  }

  addWeeks(date: Date, weeks: number): Date {
    return this.addDays(date, weeks * 7);
  }

  addYears(date: Date, years: number): Date {
    const newDate = new Date(date.getTime());
    newDate.setFullYear(date.getFullYear() + years);
    return newDate;
  }

  format(date: Date, dateFormat: string): string {
    return this.pipe.transform(date, dateFormat);
  }

  difference(date1: any, date2: any): Date {
    return new Date(Math.abs(date1 - date2));
  }

  differenceInDays(date1: any, date2: any): any {
    const d1: any = this.resetTime(date1);
    const d2: any = this.resetTime(date2);
    const diffTime = d1 - d2;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  resetTime(date: Date): Date {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  getDaysDifference(date1: any, date2: any): number {
    if (!(date1 instanceof Date)) return 0; // fallback, some users might have mm/dd/yyyy string instead of Date
    const timeDifference = date2.getTime() - date1.getTime();
    const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;
    const daysDifference = Math.ceil(timeDifference / (MILLISECONDS_PER_DAY));
    return daysDifference;
  }
}
