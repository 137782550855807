<section>
  <div class="header mB0">
    <span class="fontS1 fw-500" i18n="@@orderNo">Order no:</span><span class="pL5 fw-600">{{'#'+order?.orderNumber}}</span>
  </div>
  <div class="header" *ngIf="order?.trackingURL" (click)="trackOrder()">
    <em class="icon cs-icon-other-address"></em>
    <div class="title" i18n="@@trackOrder">Track Order</div>
    <em class="cs-icon-arrow"></em>
  </div>
  <div class="header" (click)="viewOrder()">
    <em class="icon cs-icon-orders"></em>
    <div class="title" i18n="@@viewOrder">View Order</div>
    <em class="cs-icon-arrow"></em>
  </div>
  <div class="header" *ngIf="appConfig.Shared.Order.Stage.BeforeShipment.includes(order?.stage)" (click)="editAddressBook()">
    <em class="icon cs-icon-edit-1"></em>
    <div class="title" i18n="@@editAddressPhoneNumber">
      Edit Address/Phone Number
    </div>
    <em class="cs-icon-arrow"></em>
  </div>
  <!--.header((click)='editProducts()')--><!--  em.icon.cs-icon-cart--><!--  .title(i18n='@@editProducts') Edit Products--><!--  em.cs-icon-arrow-->
  <div class="header" *ngIf="appConfig.Shared.Order.Stage.BeforeShipment.includes(order?.stage)" (click)="viewOrder()">
    <em class="icon cs-icon-close"></em>
    <div class="title" i18n="@@cancelOrder">Cancel Order</div>
    <em class="cs-icon-arrow"></em>
  </div>
</section>