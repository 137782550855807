import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscountTimerComponent } from './discount-timer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [DiscountTimerComponent],
  exports: [DiscountTimerComponent],
})
export class DiscountTimerModule {}
