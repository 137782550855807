import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { LocalStorageService } from '@services/local-storage-service';
import { EventLoggerService } from '@services/event-logger-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'onboarding-take-photo',
  templateUrl: './onboarding-take-photo.html',
  styleUrls: ['../onboarding.scss'],
})
export class OnboardingTakePhotoComponent {
  ui: { loading: boolean } = { loading: false };
  user: any;
  selectedPhotoType: string;
  concernList: string[];
  imageURLMap: { SIDE_FACE?: any, FULL_FACE?: any } = { SIDE_FACE: {}, FULL_FACE: {} };
  checkupIds: string[] = [];
  photosUploaded: boolean;
  consultationSession: any;
  meshImage: { FRONT: string, SIDE: string } = { FRONT: '/assets/images/female-front-face-mesh.png',
    SIDE: '/assets/images/female-side-face-mesh.png' };

  constructor(
    private conn: ConnectionService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private eventLogger: EventLoggerService,
    private broadcast: BroadcastService,
    public appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<any> {
    this.ui.loading = true;
    const user = this.conn.getActingUser();
    if (user.get('Gender') === this.appConfig.Shared.Gender.MALE) {
      this.meshImage.FRONT = '/assets/images/male-front-face-mesh.png';
      this.meshImage.SIDE = '/assets/images/male-side-face-mesh.png';
    }
    const type = [this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE,
      this.appConfig.Shared.InstantCheckup.Type.LEFT_SIDE_FACE,
      this.appConfig.Shared.InstantCheckup.Type.RIGHT_SIDE_FACE, this.appConfig.Shared.InstantCheckup.Type.FACE,
      this.appConfig.Shared.InstantCheckup.Type.FULL_FACE, this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE];
    this.consultationSession = await this.conn.findOrCreateConsultationSession(this.appConfig.Shared.Regimen.Class.FACE);
    await this.selectTheUnTakenPhotoType(type);
    this.ui.loading = false;
  }

  async selectTheUnTakenPhotoType(type: string[]): Promise<void> {
    const user = this.conn.getActingUser();
    const startDate: Date = this.consultationSession.createdAt;
    startDate.setUTCHours(0, 0, 0, 0);
    const checkups: any[] = await this.conn.fetchInstantCheckup(
      { userId: user.get('username'), limit: 5, type, dateRange: { startDate } });
    checkups.reverse();
    checkups.forEach((each: any): void => {
      if (each.type.includes(this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE)) {
        this.imageURLMap.SIDE_FACE = each;
        this.eventLogger.trackEvent('onboarding_side_face_photo_taken', {});
        this.conn.updateExperimentLogsInUser({ onboarding_side_face_photo_taken: true });
      } else if ([this.appConfig.Shared.InstantCheckup.Type.FULL_FACE,
        this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE].includes(each.type)) {
        this.imageURLMap.FULL_FACE = each;
        this.eventLogger.trackEvent('onboarding_front_face_photo_taken', {});
        this.conn.updateExperimentLogsInUser({ onboarding_front_face_photo_taken: true });
      }
    });
    const takenPhotoTypes: string[] = Object.keys(this.imageURLMap).filter((each: any): boolean => this.imageURLMap[each].objectId);
    if (takenPhotoTypes.length === 2) {
      this.photosUploaded = true;
      this.eventLogger.trackEvent('onboarding_both_photos_taken', {});
      this.conn.updateExperimentLogsInUser({ onboarding_both_photos_taken: true });
      await this.showDetections();
      return;
    }
    const frontFacePhotoFound: boolean = !!takenPhotoTypes.find((type_: string): boolean => [
      this.appConfig.Shared.InstantCheckup.Type.FULL_FACE,
      this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE].includes(type_));
    if (frontFacePhotoFound) this.selectedPhotoType = this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE;
    else this.selectedPhotoType = this.appConfig.Shared.InstantCheckup.Type.FULL_FACE;
  }

  selectType(type: string): void {
    if (this.imageURLMap[type].imagePath) {
      this.broadcast.broadcast('NOTIFY', { message: 'Photo uploaded. You can add more front photos later.' });
      return;
    }
    if (this.selectedPhotoType === type) this.takePhoto();
    else this.selectedPhotoType = type;
  }

  async showDetections(): Promise<void> {
    this.ui.loading = true;
    this.checkupIds = Object.values(this.imageURLMap).map((each: any): string => each.objectId);
    this.consultationSession.set('facePhotos', this.checkupIds);
    await this.consultationSession.save();
    await this.router.navigate(['/user'],
      { queryParams: { tab: 'home' }, replaceUrl: true });
    this.ui.loading = false;
  }

  takePhoto(): void {
    this.conn.navigateToURL(`/user/instantCheckup/preview?tag=${this.selectedPhotoType}`);
  }
}
