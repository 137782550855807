export const VariantServices = {
  advance: [
    {
      en: ['100% Effectiveness', 'Clinically-tested ingredients and 4 customised products to ensure you get visible results.'],
      hi: ['100% प्रभावशाली', 'क्लिनिकल परीक्षण से गुजरे इंग्रेडिएंट्स और 4 कस्टमाइज्ड प्रोडक्ट्स ताकि आप बढ़िया नतीजे पाएं।'],
      te: ['100% ప్రభావతమైనది', 'మీరు అనుభూతి చెందగల ఫలితాలను పొందడానికి వైద్యపరంగా - పరీక్షించబడిన పదార్థాలు మరియు 4 అనుకూలీకరించిన ఉత్పత్తులు'],
      kn: ['100% ಪರಿಣಾಮಕಾರಿ', 'ನೀವು ಕಾಣುವಂತಹ ಫಲಿತಾಂಶ ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಕ್ಲಿನಿಕಲಿ ಟೆಸ್ಟ್ ಮಾಡಿದ ಪದಾರ್ಥಗಳು ಮತ್ತು 4 ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಪ್ರಾಡಕ್ಟ್ ಗಳು.'],
      ta: ['100% தீவிரமானது', 'நீங்கள் உணரக்கூடிய முடிவுகளைப் பெறுவதற்கென மருத்துவ ரீதியாக பரிசோதிக்கப்பட்ட உட்கொருட்களை கொண்டது மற்றும் இதில் 4 தனிப்பயனாக்கப்பட்ட தயாரிப்புகள் அடங்கும்.'],
      ml: ['100% ഫലപ്രദം', 'നിങ്ങൾക്ക് ദൃശ്യമാകുന്ന ഫലങ്ങൾ ലഭിക്കുന്നു എന്നുറപ്പാക്കാൻ വൈദ്യശാസ്ത്ര-പരീക്ഷിത സാമഗ്രികളും 4 വ്യക്തിഗത ഉത്പന്നങ്ങളും.'],
      bn: ['100% কার্যকারিতা', 'আপনি যাতে দুর্দান্ত ফলাফল পান তা নিশ্চিত করতে ক্লিনিক্যালি-টেস্ট করা উপাদান এবং 4 কাস্টমাইজড প্রোডাক্ট।'],
      mr: ['100% परिणामकारकता (इफेक्टिव्हनेस)', 'तुम्हाला दृश्यमान परिणाम मिळतील याची खात्री करण्यासाठी क्लिनीकली-टेस्टेड घटक आणि 4 कस्टमाईझ्ड प्रॉडक्ट्स.'],
      img: 'https://storage.googleapis.com/heallocontent/kit.png',
    },
    {
      en: ['Monthly Doctor Checkup', 'Free routine checkups with a Cureskin doctor to measure your skin progress in detail.'],
      hi: ['मासिक डॉक्टर चेकअप', 'आपकी त्वचा की प्रगति को ट्रैक करने के लिए Cureskin डॉक्टर के साथ नि:शुल्क रूटीन चेकअप।'],
      te: ['నెలవారీ డాక్టర్ చెక్అప్', 'మీ స్కిన్ ప్రోగ్రెస్ ని వివరంగా కొలవడానికి Cureskin వైద్యునితో ఫ్రీ రెగ్యులర్ చెక్అప్.'],
      kn: ['ಪ್ರತಿ ತಿಂಗಳು ವೈದ್ಯರ ತಪಾಸಣೆ', 'ನಿಮ್ಮ ಚರ್ಮದ ಪ್ರಗತಿಯನ್ನು ವಿವರವಾಗಿ ಅಳೆಯಲು Cureskin  ವೈದ್ಯರೊಂದಿಗೆ ಉಚಿತ ದೈನಂದಿನ ತಪಾಸಣೆ ಮಾಡಿ.'],
      ta: ['மாதாந்திர மருத்துவ பரிசோதனை ',
        'உங்கள் சரும முன்னேற்றத்தை விரிவாக அளவிட கியூர் ஸ்கின் மருத்துவருடன் வழக்கமான இலவச பரிசோதனைகள்.'],
      ml: ['പ്രതിമാസ ഡോക്ടർ ചെക്കപ്പ്', 'നിങ്ങളുടെ ചർമ്മ പുരോഗതി വിശദമായി അളക്കാൻ സൗജന്യ പതിവ് ചെക്കപ്പുകൾ.'],
      bn: ['প্রতি মাসে ডাক্তারের চেকআপ', 'আপনার ত্বক কতটা উন্নত হয়েছে সেটা বিশদে দেখতে Cureskin-এর একজন ডাক্তারের কাছ থেকে বিনামূল্যে রুটিন চেকআপ করান।'],
      mr: ['डॉक्टरांकडून मोफत मासिक तपासणी', 'तुमच्या त्वचेची प्रगती तपशीलवार मोजण्यासाठी Cureskin डॉक्टरांमार्फत मोफत नियमित तपासणी.'],
      img: '/assets/images/dr-jisha.png',
    },
    {
      en: ['Skin-friendly Diet Plan',
        'Complimentary meal plans to make sure your daily diet contains food that boosts skin health. '],
      hi: ['त्वचा अनुकूल डाइट प्लान',
        'कम्प्लीमेंटरी मील प्लान, ताकि आपके दैनिक आहार में वो भोजन शामिल हो जो स्वस्थ त्वचा को बढ़ावा देता है।'],
      te: ['స్కిన్ - ఫ్రెండ్లీ డైట్ ప్లాన్', 'మీ రోజువారీ ఆహారంలో చర్మ ఆరోగ్యాన్ని మెరుగుపరిచే ఆహారాన్ని కలిగి ఉండేలా కాంప్లిమెంటరీ మీల్ ప్లాన్‌లు.'],
      kn: ['ಚರ್ಮ ಸರಿಹೊಂದುವಂತ ಡಯೆಟ್ ಪ್ಲಾನ್', 'ನಿಮ್ಮ ಪ್ರತಿದಿನದ ಆಹಾರದಲ್ಲಿ ಚರ್ಮದ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸುವ ಆಹಾರವಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಉಚಿತ  ಮೀಲ್ ಪ್ಲಾನ್.'],
      ta: ['ஸ்கின்-ஃபிரெண்ட்லி டையட் பிளான்', 'உங்கள் தினசரி உணவில் சரும ஆரோக்கியத்தை அதிகரிக்கும் உணவு இருத்தலை உறுதி செய்யும் இலவச உணவுத் திட்டங்கள். '],
      ml: ['ചർമ്മ-സൗഹൃദ ഡയറ്റ് പ്ലാൻ',
        'ചർമ്മത്തിന്റെ ആരോഗ്യം ഉത്തേജിപ്പിക്കാനുള്ള ഭക്ഷണം നിങ്ങളുടെ പ്രതിദിന ഡയറ്റിൽ ഉണ്ടെന്ന് ഉറപ്പിക്കാൻ സൗജന്യ ഭക്ഷണ പ്ലാനുകൾ'],
      bn: ['ত্বকের সাথে সামঞ্জস্যপূর্ণ ডায়েট প্ল্যান', 'আপনার প্রতিদিনের ডায়েটে এমন খাবার রয়েছে কিনা যেটা ত্বককে আরো বেশি স্বাস্থ্যকর করে তোলে সেটা নিশ্চিত করার জন্য কমপ্লিমেন্টারি খাবারের পরিকল্পনা।'],
      mr: ['स्किन-फ्रेंडली डाएट प्लॅन',
        'तुमच्या दैनंदिन आहारात त्वचेचे आरोग्य वाढवणारे अन्न आहे याची खात्री करण्यासाठी मोफत जेवणाचे प्लॅन.'],
      img: 'https://storage.googleapis.com/heallocontent/diet.png',
    },
    {
      en: ['Free home delivery', 'No shipping charges or hidden costs. Only an effective treatment kit delivered right to your doorstep.'],
      hi: ['ऑनलाइन भुगतान पर फ्री होम डिलीवरी', 'कोई शिपिंग शुल्क या छिपे क़ीमत नहीं। सिर्फ आपके दरवाजे तक पहुँचाया गया एक प्रभावी ट्रीटमेंट किट।'],
      te: ['ఆన్‌లైన్ చెల్లింపుపై ఫ్రీ హోమ్ డెలివరీ', 'షిప్పింగ్ ఛార్జీలు లేవు లేదా దాగి ఉన్న ఛార్జీలు లేవు. సమర్థవంతమైన ట్రీట్మెంట్ కిట్ మాత్రమే మీ ఇంటికి డెలివేరి చేయబడుతుంది.'],
      kn: ['ಆನ್‌ಲೈನ್ ಪೇಮೆಂಟ್ ಗೆ ಉಚಿತ ಹೋಮ್ ಡೆಲಿವರಿ', 'ಯಾವುದೇ ಶಿಪ್ಪಿಂಗ್ ಚಾರ್ಜ್ ಗಳು ಅಥವಾ ಯಾವುದೇ ಹಿಡನ್ ಚಾರ್ಜ್ ಗಳಿಲ್ಲ. ಪರಿಣಾಮಕಾರಿ ಚಿಕಿತ್ಸಾ ಕಿಟ್ ಅನ್ನು ಮಾತ್ರ ನಿಮ್ಮ ಮನೆ ಬಾಗಿಲಿಗೆ ತಲುಪಿಸಲಾಗುತ್ತದೆ.'],
      ta: ['ஆன்லைன் பேமெண்ட்களில் இலவச ஹோம் டெலிவரி', 'ஷிப்பிங் கட்டணங்கள் இல்லை அல்லது மறைமுக கட்டணங்கள் இல்லை. ஒரு நற்பயன் தரும்  சிகிச்சை கிட் மட்டுமே உங்கள் வீட்டிற்கு டெலிவரி செய்யப்படுகிறது. '],
      ml: ['ഓൺലൈൻ പേയ്‌മെന്റിന് സൗജന്യ ഹോം ഡെലിവറി', 'ഷിപ്പിംഗ് നിരക്കുകൾ അല്ലെങ്കിൽ മറയ്ക്കപ്പെട്ട നിരക്കുകൾ ഒന്നുമില്ല. ഫലപ്രദമായ ചികിത്സ കിറ്റ് നിങ്ങളുടെ വീട്ടുപടിക്കൽ ഡെലിവർ ചെയ്യുക മാത്രം.'],
      bn: ['অনলাইনে পেমেন্ট করলে বিনামূল্যে হোম ডেলিভারি পাবেন', 'কোনো শিপিং চার্জ নেই বা কোনো লুকানো খরচ নেই। শুধুমাত্র আপনার দরজায় ডেলিভার করা একটি কার্যকরী ট্রিটমেন্ট কিট।'],
      mr: ['ऑनलाईन पेमेंट करा, फ्री होम डिलिव्हरी मिळवा', 'कोणतेही शिपिंग चार्जेस किंवा छुपे खर्च नाहीत. तुमच्या दारापर्यंत फक्त एक प्रभावी ट्रिटमेंट किट डिलिव्हर केली जाते.'],
      img: 'https://cdn.cureskin.com/app/img/regimen-service-4.png',
    },
    {
      en: ['Personal Skin Advisor', 'A team of skin advisors made available to you via the app, to solve your doubts and support your healthy skin journey.'],
      hi: ['व्यक्तिगत स्किन एडवाइजर', 'स्किन एडवाइजर की एक टीम जो कि आपकी शंकाओं को हल करने और स्वस्थ त्वचा के सफर को समर्थन देने के लिए ऐप पर उपलब्ध रहती है।'],
      te: ['పర్సనల్ స్కిన్ అడ్వైసర్', 'మీ సందేహాలను పరిష్కరించడానికి ఇంకా మీ ఆరోగ్యకరమైన స్కిన్ జర్నీకి మద్దతు ఇవ్వడానికి యాప్ ద్వారా మీకు స్కిన్ అడ్వైసర్ టీమ్ మీకు అందుబాటులో ఉంటారు.'],
      kn: ['ಪರ್ಸನಲ್ ಸ್ಕಿನ್ ಅಡವೈಸರ್', 'ಸ್ಕಿನ್ ಅಡವೈಸರ್ ನ ತಂಡವು ನಿಮ್ಮ ಸಂದೇಹಗಳನ್ನು ಬಗೆಹರಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಆರೋಗ್ಯಕರ ಚರ್ಮದ ಪ್ರಯಾಣವನ್ನು ಬೆಂಬಲಿಸಲು ಆಪ್ ಅಲ್ಲಿ ಲಭ್ಯವಿರುತ್ತಾರೆ.'],
      ta: ['பர்சனல் ஸ்கின் அட்வைசர்', 'உங்கள் சந்தேகங்களைத் தீர்த்து, ஆரோக்கியமான சருமத்திற்கான உங்கள் பயணத்தில் உங்களுக்கு ஆதரவளிக்க, எங்கள் ஆப் மூலம் ஸ்கின் அட்வைசர்கள் உங்களுடன் இணைந்திருப்பார்கள்'],
      ml: ['വ്യക്തിഗത ചർമ്മ ഉപദേഷ്ടാവ്', 'ചർമ്മ ഉപദേഷ്ടാക്കളുടെ ഒരു ടീം ആപ്പ് വഴി നിങ്ങളുടെ സംശയങ്ങൾ പരിഹരിക്കാനും ആരോഗ്യപൂർണമായ ചർമ്മത്തിലേക്കുള്ള യാത്രയിൽ പിന്തുണയ്ക്കാനും ലഭ്യമാക്കിയിരിക്കുന്നു.'],
      bn: ['ত্বকের ব্যক্তিগত পরামর্শদাতা', 'আপনার সন্দেহ দূর করতে এবং আপনার স্বাস্থ্যকর ত্বকের যাত্রাকে সাপোর্ট করতে ত্বকের পরামর্শদাতাদের একটা টিম আপনার জন্য অ্যাপের মাধ্যমে উপলব্ধ আছেন।'],
      mr: ['पर्सनल स्किन ॲडव्हाईझर.', 'तुमच्या शंका दूर करण्यासाठी आणि तुमच्या निरोगी त्वचेच्या प्रवासाला सपोर्ट करण्यासाठी स्किन ॲडव्हाईझर्सची टीम ॲपद्वारे उपलब्ध करून दिली जाते.'],
      img: 'https://storage.googleapis.com/heallocontent/support.png',
    },
  ],
  pro: [
    {
      en: ['100% Effectiveness', 'Clinically-tested ingredients and 5 customised products to ensure you get visible results.'],
      hi: ['100% प्रभावशाली', 'क्लिनिकल परीक्षण से गुजरे इंग्रेडिएंट्स और 5 कस्टमाइज्ड प्रोडक्ट्स ताकि आप बढ़िया नतीजे पाएं।'],
      te: ['100% ప్రభావతమైనది', 'మీరు అనుభూతి చెందగల ఫలితాలను పొందడానికి వైద్యపరంగా - పరీక్షించబడిన పదార్థాలు మరియు 5 అనుకూలీకరించిన ఉత్పత్తులు'],
      kn: ['100% ಪರಿಣಾಮಕಾರಿ', 'ನೀವು ಕಾಣುವಂತಹ ಫಲಿತಾಂಶ ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಕ್ಲಿನಿಕಲಿ ಟೆಸ್ಟ್ ಮಾಡಿದ ಪದಾರ್ಥಗಳು ಮತ್ತು 5 ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಪ್ರಾಡಕ್ಟ್ ಗಳು.'],
      ta: ['100% தீவிரமானது', 'நீங்கள் உணரக்கூடிய முடிவுகளைப் பெறுவதற்கென மருத்துவ ரீதியாக பரிசோதிக்கப்பட்ட உட்கொருட்களை கொண்டது மற்றும் இதில் 5 தனிப்பயனாக்கப்பட்ட தயாரிப்புகள் அடங்கும்.'],
      ml: ['100% ഫലപ്രദം', 'നിങ്ങൾക്ക് ദൃശ്യമാകുന്ന ഫലങ്ങൾ ലഭിക്കുന്നു എന്നുറപ്പാക്കാൻ വൈദ്യശാസ്ത്ര-പരീക്ഷിത സാമഗ്രികളും 5 വ്യക്തിഗത ഉത്പന്നങ്ങളും.'],
      bn: ['100% কার্যকারিতা', 'আপনি যাতে দুর্দান্ত ফলাফল পান তা নিশ্চিত করতে ক্লিনিক্যালি-টেস্ট করা উপাদান এবং 5 কাস্টমাইজড প্রোডাক্ট।'],
      mr: ['100% परिणामकारकता (इफेक्टिव्हनेस)', 'तुम्हाला दृश्यमान परिणाम मिळतील याची खात्री करण्यासाठी क्लिनीकली-टेस्टेड घटक आणि 5 कस्टमाईझ्ड प्रॉडक्ट्स.'],
      img: 'https://storage.googleapis.com/heallocontent/kit.png',
    },
    {
      en: ['Diet Consultation Call with Nutritionist',
        'Complimentary consultation and diet tips given by a Cureskin nutritionist, to ensure your daily meal contains food that boosts skin health.'],
      hi: ['नूट्रिशनिस्ट के साथ डाइट परामर्श कॉल  ', 'Cureskin नूट्रिशनिस्ट के साथ परामर्श कॉल और उनकी दी गयी सलाह। ताकि आपके दैनिक आहार में वो भोजन शामिल हो जो स्वस्थ त्वचा को बढ़ावा देता है। '],
      te: ['న్యూట్రిషనిస్ట్‌తో డైట్ కన్సల్టేషన్ కాల్', 'మీ డెయిలీ మీల్ లో స్కిన్ హెల్త్ ని మెరుగుపరిచే ఆహారాన్ని కలిగి ఉండేలా చూసుకోవడానికి Cureskin న్యూట్రిషనిస్ట్‌ అందిస్తున్న కాంప్లిమెంటరీ కన్సల్టేషన్‌ ఇంకా డైట్ టిప్స్.'],
      kn: ['ನ್ಯೂಟ್ರಿಷನಿಸ್ಟ್ ನ ಜೊತೆ ಡಯೆಟ್ ಕನ್ಸಲ್ಟೇಶನ್ ಕಾಲ್ ', 'Cureskin ನ್ಯೂಟ್ರಿಷನಿಸ್ಟ್ ನೀಡಿದ ಕಾಂಪ್ಲಿಮೆಂಟರಿ ಕನ್ಸಲ್ಟೇಶನ್ ಮತ್ತು ಡಯೆಟ್ ಟಿಪ್ಸ್, ಇದರಿಂದ ನಿಮ್ಮ ಪ್ರತಿದಿನದ ಆಹಾರವು ಚರ್ಮದ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸುವ ಆಹಾರವನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.'],
      ta: ['ஊட்டச்சத்து நிபுணருடன் டயட் கன்சல்டேஷன் கால்', 'உங்கள் தினசரி உணவில் சரும ஆரோக்கியத்தை மேம்படுத்தும் உணவுகள் உள்ளதா என்பதை உறுதிப்படுத்த, Cureskin ஊட்டச்சத்து நிபுணர் மூலம் ஒரு காம்ப்ளிமென்ட்ரி கன்சல்டேஷன்ஸ் மற்றும் உணவுத் திட்டம் தயாரித்து வழங்கப்படும் '],
      ml: ['ന്യൂട്രീഷനിസ്റ്റുമായി ഡയറ്റ് കൺസൾട്ടേഷൻ കോൾ', 'ചർമ്മത്തിന്റെ ആരോഗ്യം ഉത്തേജിപ്പിക്കാനുള്ള ഭക്ഷണം നിങ്ങളുടെ പ്രതിദിന ആഹാരത്തിൽ ഉണ്ടെന്ന് ഉറപ്പിക്കാൻ സൗജന്യ കൺസൾട്ടേഷനും Cureskin ന്യൂട്രീഷനിസ്റ്റ് നൽകുന്ന ഡയറ്റ് ടിപ്‌സും.'],
      bn: ['নিউট্রিশনিস্টের সাথে ডায়েটের ব্যাপারে পরামর্শের জন্য কল', 'আপনার প্রতিদিনের খাবারে এমন খাদ্য রয়েছে কিনা যেটা ত্বককে আরো বেশি স্বাস্থ্যকর করে তোলে সেটা নিশ্চিত করার জন্য Cureskin-এর নিউট্রিশনিস্টের দেওয়া কমপ্লিমেন্টারি কন্সালটেশন এবং ডায়েট টিপস।'],
      mr: ['न्यूट्रिशनिस्ट सह डाएट कंसल्टेशन कॉल', 'तुमच्या दैनंदिन आहारात त्वचेचे आरोग्य वाढवणारे डाएट असल्याचे सुनिश्चित करण्यासाठी Cureskin  न्यूट्रिशनिस्ट द्वारे कॉम्प्लिमेंटरी कंसल्टेशन आणि डाएट टिप्स दिल्या जातात.'],
      img: 'https://storage.googleapis.com/heallocontent/diet.png',
    },
    {
      en: ['Monthly Doctor Checkup',
        'Free routine checkups with a Cureskin doctor to measure your skin progress in detail.'],
      hi: ['मासिक डॉक्टर चेकअप', 'आपकी त्वचा की प्रगति को ट्रैक करने के लिए Cureskin डॉक्टर के साथ नि:शुल्क रूटीन चेकअप।'],
      te: ['నెలవారీ డాక్టర్ చెక్అప్', 'మీ స్కిన్ ప్రోగ్రెస్ ని వివరంగా కొలవడానికి Cureskin వైద్యునితో ఫ్రీ రెగ్యులర్ చెక్అప్.'],
      kn: ['ಪ್ರತಿ ತಿಂಗಳು ವೈದ್ಯರ ತಪಾಸಣೆ', 'ನಿಮ್ಮ ಚರ್ಮದ ಪ್ರಗತಿಯನ್ನು ವಿವರವಾಗಿ ಅಳೆಯಲು Cureskin  ವೈದ್ಯರೊಂದಿಗೆ ಉಚಿತ ದೈನಂದಿನ ತಪಾಸಣೆ ಮಾಡಿ.'],
      ta: ['மாதாந்திர மருத்துவ பரிசோதனை ',
        'உங்கள் சரும முன்னேற்றத்தை விரிவாக அளவிட கியூர் ஸ்கின் மருத்துவருடன் வழக்கமான இலவச பரிசோதனைகள்.'],
      ml: ['പ്രതിമാസ ഡോക്ടർ ചെക്കപ്പ്', 'നിങ്ങളുടെ ചർമ്മ പുരോഗതി വിശദമായി അളക്കാൻ സൗജന്യ പതിവ് ചെക്കപ്പുകൾ.'],
      bn: ['প্রতি মাসে ডাক্তারের চেকআপ', 'আপনার ত্বক কতটা উন্নত হয়েছে সেটা বিশদে দেখতে Cureskin-এর একজন ডাক্তারের কাছ থেকে বিনামূল্যে রুটিন চেকআপ করান।'],
      mr: ['डॉक्टरांकडून मोफत मासिक तपासणी', 'तुमच्या त्वचेची प्रगती तपशीलवार मोजण्यासाठी Cureskin डॉक्टरांमार्फत मोफत नियमित तपासणी.'],
      img: '/assets/images/dr-jisha.png',
    },
    {
      en: ['Free home delivery', 'No shipping charges or hidden costs. Only an effective treatment kit delivered right to your doorstep.'],
      hi: ['ऑनलाइन भुगतान पर फ्री होम डिलीवरी', 'कोई शिपिंग शुल्क या छिपे क़ीमत नहीं। सिर्फ आपके दरवाजे तक पहुँचाया गया एक प्रभावी ट्रीटमेंट किट।'],
      te: ['ఆన్‌లైన్ చెల్లింపుపై ఫ్రీ హోమ్ డెలివరీ', 'షిప్పింగ్ ఛార్జీలు లేవు లేదా దాగి ఉన్న ఛార్జీలు లేవు. సమర్థవంతమైన ట్రీట్మెంట్ కిట్ మాత్రమే మీ ఇంటికి డెలివేరి చేయబడుతుంది.'],
      kn: ['ಆನ್‌ಲೈನ್ ಪೇಮೆಂಟ್ ಗೆ ಉಚಿತ ಹೋಮ್ ಡೆಲಿವರಿ', 'ಯಾವುದೇ ಶಿಪ್ಪಿಂಗ್ ಚಾರ್ಜ್ ಗಳು ಅಥವಾ ಯಾವುದೇ ಹಿಡನ್ ಚಾರ್ಜ್ ಗಳಿಲ್ಲ. ಪರಿಣಾಮಕಾರಿ ಚಿಕಿತ್ಸಾ ಕಿಟ್ ಅನ್ನು ಮಾತ್ರ ನಿಮ್ಮ ಮನೆ ಬಾಗಿಲಿಗೆ ತಲುಪಿಸಲಾಗುತ್ತದೆ.'],
      ta: ['ஆன்லைன் பேமெண்ட்களில் இலவச ஹோம் டெலிவரி', 'ஷிப்பிங் கட்டணங்கள் இல்லை அல்லது மறைமுக கட்டணங்கள் இல்லை. ஒரு நற்பயன் தரும்  சிகிச்சை கிட் மட்டுமே உங்கள் வீட்டிற்கு டெலிவரி செய்யப்படுகிறது. '],
      ml: ['ഓൺലൈൻ പേയ്‌മെന്റിന് സൗജന്യ ഹോം ഡെലിവറി', 'ഷിപ്പിംഗ് നിരക്കുകൾ അല്ലെങ്കിൽ മറയ്ക്കപ്പെട്ട നിരക്കുകൾ ഒന്നുമില്ല. ഫലപ്രദമായ ചികിത്സ കിറ്റ് നിങ്ങളുടെ വീട്ടുപടിക്കൽ ഡെലിവർ ചെയ്യുക മാത്രം.'],
      bn: ['অনলাইনে পেমেন্ট করলে বিনামূল্যে হোম ডেলিভারি পাবেন', 'কোনো শিপিং চার্জ নেই বা কোনো লুকানো খরচ নেই। শুধুমাত্র আপনার দরজায় ডেলিভার করা একটি কার্যকরী ট্রিটমেন্ট কিট।'],
      mr: ['ऑनलाईन पेमेंट करा, फ्री होम डिलिव्हरी मिळवा', 'कोणतेही शिपिंग चार्जेस किंवा छुपे खर्च नाहीत. तुमच्या दारापर्यंत फक्त एक प्रभावी ट्रिटमेंट किट डिलिव्हर केली जाते.'],
      img: 'https://cdn.cureskin.com/app/img/regimen-service-4.png',
    },
    {
      en: ['Personal Skin Advisor', 'A team of skin advisors made available to you via the app, to solve your doubts and support your healthy skin journey.'],
      hi: ['व्यक्तिगत स्किन एडवाइजर', 'स्किन एडवाइजर की एक टीम जो कि आपकी शंकाओं को हल करने और स्वस्थ त्वचा के सफर को समर्थन देने के लिए ऐप पर उपलब्ध रहती है।'],
      te: ['పర్సనల్ స్కిన్ అడ్వైసర్', 'మీ సందేహాలను పరిష్కరించడానికి ఇంకా మీ ఆరోగ్యకరమైన స్కిన్ జర్నీకి మద్దతు ఇవ్వడానికి యాప్ ద్వారా మీకు స్కిన్ అడ్వైసర్ టీమ్ మీకు అందుబాటులో ఉంటారు.'],
      kn: ['ಪರ್ಸನಲ್ ಸ್ಕಿನ್ ಅಡವೈಸರ್', 'ಸ್ಕಿನ್ ಅಡವೈಸರ್ ನ ತಂಡವು ನಿಮ್ಮ ಸಂದೇಹಗಳನ್ನು ಬಗೆಹರಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಆರೋಗ್ಯಕರ ಚರ್ಮದ ಪ್ರಯಾಣವನ್ನು ಬೆಂಬಲಿಸಲು ಆಪ್ ಅಲ್ಲಿ ಲಭ್ಯವಿರುತ್ತಾರೆ.'],
      ta: ['பர்சனல் ஸ்கின் அட்வைசர்', 'உங்கள் சந்தேகங்களைத் தீர்த்து, ஆரோக்கியமான சருமத்திற்கான உங்கள் பயணத்தில் உங்களுக்கு ஆதரவளிக்க, எங்கள் ஆப் மூலம் ஸ்கின் அட்வைசர்கள் உங்களுடன் இணைந்திருப்பார்கள்'],
      ml: ['വ്യക്തിഗത ചർമ്മ ഉപദേഷ്ടാവ്', 'ചർമ്മ ഉപദേഷ്ടാക്കളുടെ ഒരു ടീം ആപ്പ് വഴി നിങ്ങളുടെ സംശയങ്ങൾ പരിഹരിക്കാനും ആരോഗ്യപൂർണമായ ചർമ്മത്തിലേക്കുള്ള യാത്രയിൽ പിന്തുണയ്ക്കാനും ലഭ്യമാക്കിയിരിക്കുന്നു.'],
      bn: ['ত্বকের ব্যক্তিগত পরামর্শদাতা', 'আপনার সন্দেহ দূর করতে এবং আপনার স্বাস্থ্যকর ত্বকের যাত্রাকে সাপোর্ট করতে ত্বকের পরামর্শদাতাদের একটা টিম আপনার জন্য অ্যাপের মাধ্যমে উপলব্ধ আছেন।'],
      mr: ['पर्सनल स्किन ॲडव्हाईझर.', 'तुमच्या शंका दूर करण्यासाठी आणि तुमच्या निरोगी त्वचेच्या प्रवासाला सपोर्ट करण्यासाठी स्किन ॲडव्हाईझर्सची टीम ॲपद्वारे उपलब्ध करून दिली जाते.'],
      img: 'https://storage.googleapis.com/heallocontent/support.png',
    },
  ],
  assist: [
    {
      en: ['100% Effectiveness', 'Clinically-tested ingredients and 5 customised products to ensure you get visible results.'],
      hi: ['100% प्रभावशाली', 'क्लिनिकल परीक्षण से गुजरे इंग्रेडिएंट्स और 5 कस्टमाइज्ड प्रोडक्ट्स ताकि आप बढ़िया नतीजे पाएं।'],
      te: ['100% ప్రభావతమైనది', 'మీరు అనుభూతి చెందగల ఫలితాలను పొందడానికి వైద్యపరంగా - పరీక్షించబడిన పదార్థాలు మరియు 5 అనుకూలీకరించిన ఉత్పత్తులు'],
      kn: ['100% ಪರಿಣಾಮಕಾರಿ', 'ನೀವು ಕಾಣುವಂತಹ ಫಲಿತಾಂಶ ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಕ್ಲಿನಿಕಲಿ ಟೆಸ್ಟ್ ಮಾಡಿದ ಪದಾರ್ಥಗಳು ಮತ್ತು 5 ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಪ್ರಾಡಕ್ಟ್ ಗಳು.'],
      ta: ['100% தீவிரமானது', 'நீங்கள் உணரக்கூடிய முடிவுகளைப் பெறுவதற்கென மருத்துவ ரீதியாக பரிசோதிக்கப்பட்ட உட்கொருட்களை கொண்டது மற்றும் இதில் 5 தனிப்பயனாக்கப்பட்ட தயாரிப்புகள் அடங்கும்.'],
      ml: ['100% ഫലപ്രദം', 'നിങ്ങൾക്ക് ദൃശ്യമാകുന്ന ഫലങ്ങൾ ലഭിക്കുന്നു എന്നുറപ്പാക്കാൻ വൈദ്യശാസ്ത്ര-പരീക്ഷിത സാമഗ്രികളും 5 വ്യക്തിഗത ഉത്പന്നങ്ങളും.'],
      bn: ['100% কার্যকারিতা', 'আপনি যাতে দুর্দান্ত ফলাফল পান তা নিশ্চিত করতে ক্লিনিক্যালি-টেস্ট করা উপাদান এবং 5 কাস্টমাইজড প্রোডাক্ট।'],
      mr: ['100% परिणामकारकता (इफेक्टिव्हनेस)', 'तुम्हाला दृश्यमान परिणाम मिळतील याची खात्री करण्यासाठी क्लिनीकली-टेस्टेड घटक आणि 5 कस्टमाईझ्ड प्रॉडक्ट्स.'],
      img: 'https://storage.googleapis.com/heallocontent/kit.png',
    },
    {
      en: ['Free Call with Doctor', 'Assigned doctor calls you before shipping of regimen, to understand the patient history well and clarify your all doubts.'],
      hi: ['डॉक्टर के साथ फ्री कॉल', 'डॉक्टर आपके रेजिमेन की शिपिंग से पहले-पहले आपको कॉल करेंगे। ताकि आप अपने ट्रीटमेंट से संबंधित सभी सवाल पूछ सकें। '],
      te: ['డాక్టర్‌తో ఫ్రీ కాల్', 'పేషెంట్ హిస్టరీని బాగా అర్థం చేసుకోవడానికి ఇంకా మీ అన్ని సందేహాలను స్పష్టం చేయడానికి రెజిమెన్ ని షిప్పింగ్ చేయడానికి మీకు అసైన్ చేయబడిన డాక్టర్ మీకు ముందుగా కాల్ చేస్తారు.'],
      kn: ['ಡಾಕ್ಟರ್ ಜೊತೆ 15 ನಿಮಿಷಗಳ ವೀಡಿಯೊ ಕಾಲ್ ', 'ಅಸೈನ್ ಮಾಡಿದ ಡಾಕ್ಟರ್ ರೆಜಿಮೆನ್ ಅನ್ನು ಕಳುಹಿಸುವ ಮುಂಚೆ ನಿಮ್ಮ ಸಮಸ್ಯೆಯ ಬಗ್ಗೆ ಸರಿಯಾಗಿ ತಿಳಿದುಕೊಳ್ಳಲು ಮತ್ತು ನಿಮ್ಮ ಎಲ್ಲಾ ಅನುಮಾನಗಳನ್ನು ಬಗೆಹರಿಸಲು ನಿಮಗೆ ಕರೆ ಮಾಡುತ್ತಾರೆ.'],
      ta: ['டாக்டருடன் இலவச கால்', 'நோயாளியின் உடம்பு பற்றிய ஹிஸ்டரியை நன்கு புரிந்துகொள்வதற்கும் உங்கள் சந்தேகங்களைத் தெளிவுபடுத்துவதற்கும் உங்களுக்கென ஒதுக்கப்பட்ட டாக்டர், ரெஜிமனை அனுப்புவதற்கு முன் உங்களக்கு கால் செய்வார்'],
      ml: ['ഡോക്ടറുമായി സൗജന്യ കോൾ', 'ചികിത്സാക്രമം അയക്കുന്നതിന് മുൻപ് നിങ്ങളുടെ ഹിസ്റ്ററി നന്നായി അറിയാനും എല്ലാ സംശയങ്ങളും വ്യക്തമാക്കാനും നിശ്ചയിക്കപ്പെട്ട ഡോക്ടർ നിങ്ങളെ വിളിക്കും.'],
      bn: ['বিনামূল্যে ডাক্তারের সাথে কল', 'আপনার কাছে রেজিমেন পাঠানোর আগে রোগীর রোগের ব্যাপারে ভালোভাবে জানতে এবং আপনার সমস্ত প্রশ্নের উত্তর দিতে নির্দিষ্ট ডাক্তার আপনাকে কল করবেন।'],
      mr: ['डॉक्टरांसोबत फ्री कॉल', 'पेशंट हिस्ट्री चांगल्या प्रकारे समजून घेण्यासाठी आणि तुमच्या सर्व शंकांचे निरसन करण्यासाठी असाईन केलेले  डॉक्टर पथ्ये पाठवण्यापूर्वी तुम्हाला कॉल करतात.'],
      img: '/assets/images/dr-jisha.png',
    },
    {
      en: ['2 Diet Consultation Calls with Nutritionist', 'Complimentary consultations and diet tips given by a Cureskin nutritionist, to ensure your daily meal contains food that boosts skin health.'],
      hi: ['2 डाइट परामर्श कॉल नूट्रिशनिस्ट के साथ ', 'Cureskin नूट्रिशनिस्ट के साथ २ परामर्श कॉल्स और उनकी दी गयी सलाह। ताकि आपके दैनिक आहार में वो भोजन शामिल हो जो स्वस्थ त्वचा को बढ़ावा देता है। '],
      te: ['న్యూట్రిషనిస్ట్‌తో 2 డైట్ కన్సల్టేషన్ కాల్స్', 'మీ డెయిలీ మీల్ లో స్కిన్ హెల్త్ ని మెరుగుపరిచే ఆహారాన్ని కలిగి ఉండేలా చూసుకోవడానికి Cureskin న్యూట్రిషనిస్ట్‌ అందిస్తున్న కాంప్లిమెంటరీ కన్సల్టేషన్‌ ఇంకా డైట్ టిప్స్.'],
      kn: ['ನ್ಯೂಟ್ರಿಷನಿಸ್ಟ್ ನ ಜೊತೆ 2 ಡಯೆಟ್ ಕನ್ಸಲ್ಟೇಶನ್ ಕಾಲ್ಸ್', 'Cureskin ನ್ಯೂಟ್ರಿಷನಿಸ್ಟ್ ನೀಡಿದ ಕಾಂಪ್ಲಿಮೆಂಟರಿ ಕನ್ಸಲ್ಟೇಶನ್ ಮತ್ತು ಡಯೆಟ್ ಟಿಪ್ಸ್, ಇದರಿಂದ ನಿಮ್ಮ ಪ್ರತಿದಿನದ ಆಹಾರವು ಚರ್ಮದ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸುವ ಆಹಾರವನ್ನು  ಒಳಗೊಂಡಿರುತ್ತದೆ.  '],
      ta: ['ஊட்டச்சத்து நிபுணருடன் 2 டயட் கன்சல்டேஷன் கால்ஸ்.', 'உங்கள் தினசரி உணவில் சரும ஆரோக்கியத்தை மேம்படுத்தும் உணவுகள் உள்ளதா என்பதை உறுதிப்படுத்த, Cureskin ஊட்டச்சத்து நிபுணர் மூலம் ஒரு காம்ப்ளிமென்ட்ரி கன்சல்டேஷன்ஸ் மற்றும் உணவுத் திட்டம் தயாரித்து வழங்குவார் '],
      ml: ['ന്യൂട്രീഷനിസ്റ്റുമായി 2 ഡയറ്റ് കൺസൾട്ടേഷൻ കോളുകൾ', 'ചർമ്മത്തിന്റെ ആരോഗ്യം ഉത്തേജിപ്പിക്കാനുള്ള ഭക്ഷണം നിങ്ങളുടെ പ്രതിദിന ആഹാരത്തിൽ ഉണ്ടെന്ന് ഉറപ്പിക്കാൻ സൗജന്യ കൺസൾട്ടേഷനും Cureskin ന്യൂട്രീഷനിസ്റ്റ് നൽകുന്ന ഡയറ്റ് ടിപ്‌സും.'],
      bn: ['নিউট্রিশনিস্টের সাথে 2টো ডায়েট কন্সালটেশন কল', 'আপনার প্রতিদিনের খাবারে এমন খাদ্য রয়েছে কিনা যেটা ত্বককে আরো বেশি স্বাস্থ্যকর করে তোলে সেটা নিশ্চিত করার জন্য Cureskin-এর নিউট্রিশনিস্টের দেওয়া কমপ্লিমেন্টারি কন্সালটেশন এবং ডায়েট টিপস।'],
      mr: ['न्यूट्रिशनिस्ट सह  2 डाएट कंसल्टेशन कॉल', 'तुमच्या दैनंदिन आहारात त्वचेचे आरोग्य वाढवणारे डाएट असल्याचे सुनिश्चित करण्यासाठी Cureskin  न्यूट्रिशनिस्ट द्वारे कॉम्प्लिमेंटरी कंसल्टेशन आणि डाएट टिप्स दिल्या जातात.'],
      img: 'https://storage.googleapis.com/heallocontent/diet.png',
    },
    {
      en: ['Multiple Doctor Checkups + Anytime Support', 'Free routine checkups to monitor skin progress and anytime support to solve all patient queries and doubts.'],
      hi: ['कई डॉक्टर चेकअप + पूरा सपोर्ट', 'त्वचा के सुधार की निगरानी के लिए नि:शुल्क नियमित जांच। इसके अलावा आपके सभी प्रश्नों और शंकाओं को हल करने के लिए पूरी सहायता।'],
      te: ['అనేక డాక్టర్ చెక్ అప్స్ + ఎప్పుడైనా సపోర్ట్ ', 'స్కిన్ ప్రోగ్రెస్‌ని పర్యవేక్షించడానికి ఫ్రీ రొటీన్ చెకప్‌లు ఇంకా పేషెంట్ యొక్క ప్రతి ప్రశ్నలు మరియు సందేహాలను పరిష్కరించడానికి ఎల్లప్పుడూ సపోర్ట్.'],
      kn: ['ಅನೇಕ ಡಾಕ್ಟರ್ ಚೆಕಪ್ ಗಳು + ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಬೆಂಬಲ', 'ಸ್ಕಿನ್ ಪ್ರೋಗ್ರೆಸ್ ಅನ್ನು ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಲು ಉಚಿತ ರೊಟಿನ್ ಚೆಕಪ್ ಗಳು ಮತ್ತು ಎಲ್ಲಾ ರೋಗಿಗಳ ಪ್ರಶ್ನೆಗಳು ಮತ್ತು ಅನುಮಾನಗಳನ್ನು ಪರಿಹರಿಸಲು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಬೆಂಬಲ.'],
      ta: ['பல டாக்டர் செக்-அப்ஸ் + எந்நேரத்திலும் சப்போர்ட்', 'உங்கள் தோல் முன்னேற்றத்தை கண்காணிக்க இலவச ரொட்டீன் செக்-அப்ஸ் மற்றும் நோயாளியின் அனைத்து கேள்விகள் மற்றும் சந்தேகங்களை தீர்க்க எல்லா நேரத்திலும் சப்போர்ட்.'],
      ml: ['അനവധി ഡോക്ടർ ചെക്കപ്പുകൾ + ഏത് സമയത്തും പിന്തുണ', 'ചർമ്മ പുരോഗതി നിരീക്ഷിക്കാൻ പതിവ് സൗജന്യ ചെക്കപ്പുകളും രോഗിയുടെ എല്ലാ ചോദ്യങ്ങളും സംശയങ്ങളും പരിഹരിക്കാൻ ഏത് സമയ പിന്തുണയും.'],
      bn: ['একাধিক ডাক্তারের দ্বারা চেকআপ + যে কোনো সময় সহায়তা ', 'রোগীর ত্বকের উন্নতিকে পর্যবেক্ষণ করার জন্য বিনামূল্যে রুটিন চেকআপ করা হবে এবং রোগীর সমস্ত প্রশ্ন ও সন্দেহের সমাধান করার জন্য যে কোনো সময় সহায়তা করা হবে।'],
      mr: ['अनेक डॉक्टरांकडून तपासणी + कधीही सपोर्ट', 'त्वचेच्या प्रगतीवर लक्ष ठेवण्यासाठी नियमितपणे मोफत तपासणी आणि रुग्णाच्या सर्व प्रश्न आणि शंकांचे निराकरण करण्यासाठी कधीही सपोर्ट उपलब्ध.'],
      img: '/assets/images/dr-jisha.png',
    },
    {
      en: ['Free Blood Test for Special Cases', 'If prescribed by the doctor, a detailed blood test will be conductued to find the main reason behind your skin problem. Free home sample collection.'],
      hi: ['विशेष मामलों के लिए फ्री ब्लड टेस्ट', 'Cureskin डॉक्टर के निर्देशानुसार, त्वचा की चिंता के मूल कारण को समझने और उसके निवारण के लिए एक विस्तृत ब्लड टेस्ट। इसमें घर से ब्लड सैंपल लेने की निःशुल्क सेवा भी शामिल होगी।'],
      te: ['స్పెషల్ కేసుల కోసం ఫ్రీ బ్లడ్ టెస్ట్ రిపోర్ట్', 'డాక్టర్ సూచించినట్లయితే, మీ చర్మ సమస్య వెనుక ఉన్న ప్రధాన కారణాన్ని కనుగొనడానికి ఒక వివరణాత్మకమైన బ్లడ్ టెస్ట్ నిర్వహించబడుతుంది. దీనికి ఫ్రీ హోమ్ శాంపిల్ కలెక్షన్ కూడా ఉంది.'],
      kn: ['ಸ್ಪೆಷಲ್ ಕೇಸ್ ಗಳಿಗೆ ಉಚಿತ ಬ್ಲಡ್ ಟೆಸ್ಟ್ ರಿಪೋರ್ಟ್', 'ಡಾಕ್ಟರ್ ಸೂಚಿಸಿದರೆ, ನಿಮ್ಮ ಚರ್ಮದ ಸಮಸ್ಯೆಯ ಹಿಂದಿನ ಮುಖ್ಯ ಕಾರಣವನ್ನು ಕಂಡುಹಿಡಿಯಲು ವಿವರವಾದ ಬ್ಲಡ್ ಟೆಸ್ಟ್ ಅನ್ನು ಮಾಡಲಾಗುತ್ತದೆ. ಉಚಿತವಾಗಿ ಮನೆಯಲ್ಲಿ ಸಾಂಪಲ್ ಕಲೆಕ್ಟ್ ಮಾಡಲಾಗುತ್ತದೆ .'],
      ta: ['ஸ்பெஷல் கேஸ் - இலவச இரத்த டெஸ்ட் ரிப்போர்ட் ', 'டாக்டர் பரிந்துரைக்கும் பட்சத்தில், உங்கள் தோல் பிரச்சனைக்கான காரணத்தை கண்டறிய விரிவான இரத்தப் பரிசோதனை மேற்கொள்ளப்படும். இலவச வீட்டு சாம்பிள் கலெக்ஷன்'],
      ml: ['സൗജന്യ ബ്ലഡ് ടെസ്റ്റ് റിപ്പോർട്ട് ചില പ്രത്യേക കേസുകളിൽ', 'ഡോക്ടർ നിർദേശിച്ചതാണെങ്കിൽ, നിങ്ങളുടെ ചർമ്മത്തിന്റെ പ്രശ്നത്തിന് പിന്നിലെ പ്രധാന കാരണം കണ്ടെത്താൻ വിശദമായ ബ്ലഡ് ടെസ്റ്റ് നടത്തും. സൗജന്യമായി വീട്ടിൽ നിന്ന് സാമ്പിൾ കളക്ഷൻ.'],
      bn: ['বিশেষ ক্ষেত্রে বিনামূল্যে রক্ত পরীক্ষার রিপোর্ট', 'যদি ডাক্তার পরামর্শ দেন, তবে আপনার ত্বকের সমস্যার মূল কারণ খুঁজে পেতে বিশদভাবে রক্ত পরীক্ষা করা হবে। বাড়ি থেকে বিনামূল্যে নমুনা সংগ্রহ করা হবে। '],
      mr: ['स्पेशल केसेससाठी फ्री ब्लड टेस्ट रिपोर्ट', 'डॉक्टरांनी लिहून दिल्यास, तुमच्या त्वचेच्या समस्येमागील मुख्य कारण शोधण्यासाठी डीटेल्ड ब्लड टेस्ट केली जाईल. घरी येऊन मोफत सॅम्पल घेतले जाईल.'],
      img: '/assets/images/2499/blood-test.jpg',
    },
  ],
  introductory: [
    {
      en: ['100% Effectiveness', 'Clinically-tested ingredients and 3 customised products to ensure you get visible results.'],
      hi: ['100% प्रभावशाली', 'क्लिनिकल परीक्षण से गुजरे इंग्रेडिएंट्स और 3 कस्टमाइज्ड प्रोडक्ट्स ताकि आप बढ़िया नतीजे पाएं।'],
      te: ['100% ప్రభావతమైనది', 'మీరు అనుభూతి చెందగల ఫలితాలను పొందడానికి వైద్యపరంగా - పరీక్షించబడిన పదార్థాలు మరియు 3 అనుకూలీకరించిన ఉత్పత్తులు'],
      kn: ['100% ಪರಿಣಾಮಕಾರಿ', 'ನೀವು ಕಾಣುವಂತಹ ಫಲಿತಾಂಶ ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಕ್ಲಿನಿಕಲಿ ಟೆಸ್ಟ್ ಮಾಡಿದ ಪದಾರ್ಥಗಳು ಮತ್ತು 3 ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಪ್ರಾಡಕ್ಟ್ ಗಳು.'],
      ta: ['100% தீவிரமானது', 'நீங்கள் உணரக்கூடிய முடிவுகளைப் பெறுவதற்கென மருத்துவ ரீதியாக பரிசோதிக்கப்பட்ட உட்கொருட்களை கொண்டது மற்றும் இதில் 3 தனிப்பயனாக்கப்பட்ட தயாரிப்புகள் அடங்கும்.'],
      ml: ['100% ഫലപ്രദം', 'നിങ്ങൾക്ക് ദൃശ്യമാകുന്ന ഫലങ്ങൾ ലഭിക്കുന്നു എന്നുറപ്പാക്കാൻ വൈദ്യശാസ്ത്ര-പരീക്ഷിത സാമഗ്രികളും 3 വ്യക്തിഗത ഉത്പന്നങ്ങളും.'],
      bn: ['100% কার্যকারিতা', 'আপনি যাতে দুর্দান্ত ফলাফল পান তা নিশ্চিত করতে ক্লিনিক্যালি-টেস্ট করা উপাদান এবং 3 কাস্টমাইজড প্রোডাক্ট।'],
      mr: ['100% परिणामकारकता (इफेक्टिव्हनेस)', 'तुम्हाला दृश्यमान परिणाम मिळतील याची खात्री करण्यासाठी क्लिनीकली-टेस्टेड घटक आणि 3 कस्टमाईझ्ड प्रॉडक्ट्स.'],
      img: 'https://storage.googleapis.com/heallocontent/kit.png',
    },
    {
      en: ['Monthly Doctor Checkup', 'Free routine checkups with a Cureskin doctor to measure your skin progress in detail.'],
      hi: ['मासिक डॉक्टर चेकअप', 'आपकी त्वचा की प्रगति को ट्रैक करने के लिए Cureskin डॉक्टर के साथ नि:शुल्क रूटीन चेकअप।'],
      te: ['నెలవారీ డాక్టర్ చెక్అప్', 'మీ స్కిన్ ప్రోగ్రెస్ ని వివరంగా కొలవడానికి Cureskin వైద్యునితో ఫ్రీ రెగ్యులర్ చెక్అప్.'],
      kn: ['ಪ್ರತಿ ತಿಂಗಳು ವೈದ್ಯರ ತಪಾಸಣೆ', 'ನಿಮ್ಮ ಚರ್ಮದ ಪ್ರಗತಿಯನ್ನು ವಿವರವಾಗಿ ಅಳೆಯಲು Cureskin  ವೈದ್ಯರೊಂದಿಗೆ ಉಚಿತ ದೈನಂದಿನ ತಪಾಸಣೆ ಮಾಡಿ.'],
      ta: ['மாதாந்திர மருத்துவ பரிசோதனை ',
        'உங்கள் சரும முன்னேற்றத்தை விரிவாக அளவிட கியூர் ஸ்கின் மருத்துவருடன் வழக்கமான இலவச பரிசோதனைகள்.'],
      ml: ['പ്രതിമാസ ഡോക്ടർ ചെക്കപ്പ്', 'നിങ്ങളുടെ ചർമ്മ പുരോഗതി വിശദമായി അളക്കാൻ സൗജന്യ പതിവ് ചെക്കപ്പുകൾ.'],
      bn: ['প্রতি মাসে ডাক্তারের চেকআপ', 'আপনার ত্বক কতটা উন্নত হয়েছে সেটা বিশদে দেখতে Cureskin-এর একজন ডাক্তারের কাছ থেকে বিনামূল্যে রুটিন চেকআপ করান।'],
      mr: ['डॉक्टरांकडून मोफत मासिक तपासणी', 'तुमच्या त्वचेची प्रगती तपशीलवार मोजण्यासाठी Cureskin डॉक्टरांमार्फत मोफत नियमित तपासणी.'],
      img: '/assets/images/dr-jisha.png',
    },
    {
      en: ['Skin-friendly Diet Plan',
        'Complimentary meal plans to make sure your daily diet contains food that boosts skin health. '],
      hi: ['त्वचा अनुकूल डाइट प्लान',
        'कम्प्लीमेंटरी मील प्लान, ताकि आपके दैनिक आहार में वो भोजन शामिल हो जो स्वस्थ त्वचा को बढ़ावा देता है।'],
      te: ['స్కిన్ - ఫ్రెండ్లీ డైట్ ప్లాన్', 'మీ రోజువారీ ఆహారంలో చర్మ ఆరోగ్యాన్ని మెరుగుపరిచే ఆహారాన్ని కలిగి ఉండేలా కాంప్లిమెంటరీ మీల్ ప్లాన్‌లు.'],
      kn: ['ಚರ್ಮ ಸರಿಹೊಂದುವಂತ ಡಯೆಟ್ ಪ್ಲಾನ್', 'ನಿಮ್ಮ ಪ್ರತಿದಿನದ ಆಹಾರದಲ್ಲಿ ಚರ್ಮದ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸುವ ಆಹಾರವಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಉಚಿತ  ಮೀಲ್ ಪ್ಲಾನ್.'],
      ta: ['ஸ்கின்-ஃபிரெண்ட்லி டையட் பிளான்', 'உங்கள் தினசரி உணவில் சரும ஆரோக்கியத்தை அதிகரிக்கும் உணவு இருத்தலை உறுதி செய்யும் இலவச உணவுத் திட்டங்கள். '],
      ml: ['ചർമ്മ-സൗഹൃദ ഡയറ്റ് പ്ലാൻ',
        'ചർമ്മത്തിന്റെ ആരോഗ്യം ഉത്തേജിപ്പിക്കാനുള്ള ഭക്ഷണം നിങ്ങളുടെ പ്രതിദിന ഡയറ്റിൽ ഉണ്ടെന്ന് ഉറപ്പിക്കാൻ സൗജന്യ ഭക്ഷണ പ്ലാനുകൾ'],
      bn: ['ত্বকের সাথে সামঞ্জস্যপূর্ণ ডায়েট প্ল্যান', 'আপনার প্রতিদিনের ডায়েটে এমন খাবার রয়েছে কিনা যেটা ত্বককে আরো বেশি স্বাস্থ্যকর করে তোলে সেটা নিশ্চিত করার জন্য কমপ্লিমেন্টারি খাবারের পরিকল্পনা।'],
      mr: ['स्किन-फ्रेंडली डाएट प्लॅन',
        'तुमच्या दैनंदिन आहारात त्वचेचे आरोग्य वाढवणारे अन्न आहे याची खात्री करण्यासाठी मोफत जेवणाचे प्लॅन.'],
      img: 'https://storage.googleapis.com/heallocontent/diet.png',
    },
    {
      en: ['Free home delivery', 'No shipping charges or hidden costs. Only an effective treatment kit delivered right to your doorstep.'],
      hi: ['ऑनलाइन भुगतान पर फ्री होम डिलीवरी', 'कोई शिपिंग शुल्क या छिपे क़ीमत नहीं। सिर्फ आपके दरवाजे तक पहुँचाया गया एक प्रभावी ट्रीटमेंट किट।'],
      te: ['ఆన్‌లైన్ చెల్లింపుపై ఫ్రీ హోమ్ డెలివరీ', 'షిప్పింగ్ ఛార్జీలు లేవు లేదా దాగి ఉన్న ఛార్జీలు లేవు. సమర్థవంతమైన ట్రీట్మెంట్ కిట్ మాత్రమే మీ ఇంటికి డెలివేరి చేయబడుతుంది.'],
      kn: ['ಆನ್‌ಲೈನ್ ಪೇಮೆಂಟ್ ಗೆ ಉಚಿತ ಹೋಮ್ ಡೆಲಿವರಿ', 'ಯಾವುದೇ ಶಿಪ್ಪಿಂಗ್ ಚಾರ್ಜ್ ಗಳು ಅಥವಾ ಯಾವುದೇ ಹಿಡನ್ ಚಾರ್ಜ್ ಗಳಿಲ್ಲ. ಪರಿಣಾಮಕಾರಿ ಚಿಕಿತ್ಸಾ ಕಿಟ್ ಅನ್ನು ಮಾತ್ರ ನಿಮ್ಮ ಮನೆ ಬಾಗಿಲಿಗೆ ತಲುಪಿಸಲಾಗುತ್ತದೆ.'],
      ta: ['ஆன்லைன் பேமெண்ட்களில் இலவச ஹோம் டெலிவரி', 'ஷிப்பிங் கட்டணங்கள் இல்லை அல்லது மறைமுக கட்டணங்கள் இல்லை. ஒரு நற்பயன் தரும்  சிகிச்சை கிட் மட்டுமே உங்கள் வீட்டிற்கு டெலிவரி செய்யப்படுகிறது. '],
      ml: ['ഓൺലൈൻ പേയ്‌മെന്റിന് സൗജന്യ ഹോം ഡെലിവറി', 'ഷിപ്പിംഗ് നിരക്കുകൾ അല്ലെങ്കിൽ മറയ്ക്കപ്പെട്ട നിരക്കുകൾ ഒന്നുമില്ല. ഫലപ്രദമായ ചികിത്സ കിറ്റ് നിങ്ങളുടെ വീട്ടുപടിക്കൽ ഡെലിവർ ചെയ്യുക മാത്രം.'],
      bn: ['অনলাইনে পেমেন্ট করলে বিনামূল্যে হোম ডেলিভারি পাবেন', 'কোনো শিপিং চার্জ নেই বা কোনো লুকানো খরচ নেই। শুধুমাত্র আপনার দরজায় ডেলিভার করা একটি কার্যকরী ট্রিটমেন্ট কিট।'],
      mr: ['ऑनलाईन पेमेंट करा, फ्री होम डिलिव्हरी मिळवा', 'कोणतेही शिपिंग चार्जेस किंवा छुपे खर्च नाहीत. तुमच्या दारापर्यंत फक्त एक प्रभावी ट्रिटमेंट किट डिलिव्हर केली जाते.'],
      img: 'https://cdn.cureskin.com/app/img/regimen-service-4.png',
    },
    {
      en: ['Personal Skin Advisor', 'A team of skin advisors made available to you via the app, to solve your doubts and support your healthy skin journey.'],
      hi: ['व्यक्तिगत स्किन एडवाइजर', 'स्किन एडवाइजर की एक टीम जो कि आपकी शंकाओं को हल करने और स्वस्थ त्वचा के सफर को समर्थन देने के लिए ऐप पर उपलब्ध रहती है।'],
      te: ['పర్సనల్ స్కిన్ అడ్వైసర్', 'మీ సందేహాలను పరిష్కరించడానికి ఇంకా మీ ఆరోగ్యకరమైన స్కిన్ జర్నీకి మద్దతు ఇవ్వడానికి యాప్ ద్వారా మీకు స్కిన్ అడ్వైసర్ టీమ్ మీకు అందుబాటులో ఉంటారు.'],
      kn: ['ಪರ್ಸನಲ್ ಸ್ಕಿನ್ ಅಡವೈಸರ್', 'ಸ್ಕಿನ್ ಅಡವೈಸರ್ ನ ತಂಡವು ನಿಮ್ಮ ಸಂದೇಹಗಳನ್ನು ಬಗೆಹರಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಆರೋಗ್ಯಕರ ಚರ್ಮದ ಪ್ರಯಾಣವನ್ನು ಬೆಂಬಲಿಸಲು ಆಪ್ ಅಲ್ಲಿ ಲಭ್ಯವಿರುತ್ತಾರೆ.'],
      ta: ['பர்சனல் ஸ்கின் அட்வைசர்', 'உங்கள் சந்தேகங்களைத் தீர்த்து, ஆரோக்கியமான சருமத்திற்கான உங்கள் பயணத்தில் உங்களுக்கு ஆதரவளிக்க, எங்கள் ஆப் மூலம் ஸ்கின் அட்வைசர்கள் உங்களுடன் இணைந்திருப்பார்கள்'],
      ml: ['വ്യക്തിഗത ചർമ്മ ഉപദേഷ്ടാവ്', 'ചർമ്മ ഉപദേഷ്ടാക്കളുടെ ഒരു ടീം ആപ്പ് വഴി നിങ്ങളുടെ സംശയങ്ങൾ പരിഹരിക്കാനും ആരോഗ്യപൂർണമായ ചർമ്മത്തിലേക്കുള്ള യാത്രയിൽ പിന്തുണയ്ക്കാനും ലഭ്യമാക്കിയിരിക്കുന്നു.'],
      bn: ['ত্বকের ব্যক্তিগত পরামর্শদাতা', 'আপনার সন্দেহ দূর করতে এবং আপনার স্বাস্থ্যকর ত্বকের যাত্রাকে সাপোর্ট করতে ত্বকের পরামর্শদাতাদের একটা টিম আপনার জন্য অ্যাপের মাধ্যমে উপলব্ধ আছেন।'],
      mr: ['पर्सनल स्किन ॲडव्हाईझर.', 'तुमच्या शंका दूर करण्यासाठी आणि तुमच्या निरोगी त्वचेच्या प्रवासाला सपोर्ट करण्यासाठी स्किन ॲडव्हाईझर्सची टीम ॲपद्वारे उपलब्ध करून दिली जाते.'],
      img: 'https://storage.googleapis.com/heallocontent/support.png',
    },
  ],
};

export const HairVariantServices = {
  advance: [
    {
      en: ['100% Effectiveness', 'Clinically-tested ingredients and 4 customised products to ensure you get visible results.'],
      hi: ['100% प्रभावशाली', 'क्लिनिकल परीक्षण से गुजरे इंग्रेडिएंट्स और 4 कस्टमाइज्ड प्रोडक्ट्स ताकि आप बढ़िया नतीजे पाएं।'],
      te: ['100% ప్రభావతమైనది', 'మీరు అనుభూతి చెందగల ఫలితాలను పొందడానికి వైద్యపరంగా - పరీక్షించబడిన పదార్థాలు మరియు 4 అనుకూలీకరించిన ఉత్పత్తులు'],
      kn: ['100% ಪರಿಣಾಮಕಾರಿ', 'ನೀವು ಕಾಣುವಂತಹ ಫಲಿತಾಂಶ ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಕ್ಲಿನಿಕಲಿ ಟೆಸ್ಟ್ ಮಾಡಿದ ಪದಾರ್ಥಗಳು ಮತ್ತು 4 ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಪ್ರಾಡಕ್ಟ್ ಗಳು.'],
      ta: ['100% தீவிரமானது', 'நீங்கள் உணரக்கூடிய முடிவுகளைப் பெறுவதற்கென மருத்துவ ரீதியாக பரிசோதிக்கப்பட்ட உட்கொருட்களை கொண்டது மற்றும் இதில் 4 தனிப்பயனாக்கப்பட்ட தயாரிப்புகள் அடங்கும்.'],
      ml: ['100% ഫലപ്രദം', 'നിങ്ങൾക്ക് ദൃശ്യമാകുന്ന ഫലങ്ങൾ ലഭിക്കുന്നു എന്നുറപ്പാക്കാൻ വൈദ്യശാസ്ത്ര-പരീക്ഷിത സാമഗ്രികളും 4 വ്യക്തിഗത ഉത്പന്നങ്ങളും.'],
      bn: ['100% কার্যকারিতা', 'আপনি যাতে দুর্দান্ত ফলাফল পান তা নিশ্চিত করতে ক্লিনিক্যালি-টেস্ট করা উপাদান এবং 4 কাস্টমাইজড প্রোডাক্ট।'],
      mr: ['100% परिणामकारकता (इफेक्टिव्हनेस)', 'तुम्हाला दृश्यमान परिणाम मिळतील याची खात्री करण्यासाठी क्लिनीकली-टेस्टेड घटक आणि 4 कस्टमाईझ्ड प्रॉडक्ट्स.'],
      img: 'https://storage.googleapis.com/heallocontent/kit.png',
    },
    {
      en: ['Monthly Doctor Checkup', 'Free routine checkups with a Cureskin doctor to measure your hair progress in detail.'],
      hi: ['मासिक डॉक्टर चेकअप', 'आपकी बालों के सुधार को ट्रैक करने के लिए Cureskin डॉक्टर के साथ नि:शुल्क रूटीन चेकअप।'],
      te: ['నెలవారీ డాక్టర్ చెక్అప్', 'మీ హెయిర్ ప్రోగ్రెస్ ని వివరంగా కొలవడానికి Cureskin వైద్యునితో ఫ్రీ రెగ్యులర్ చెక్అప్.'],
      kn: ['ಪ್ರತಿ ತಿಂಗಳು ವೈದ್ಯರ ತಪಾಸಣೆ', 'ನಿಮ್ಮ ಹೇರ್ ಪ್ರೋಗ್ರೆಸ್ ಅನ್ನು ವಿವರವಾಗಿ ಅಳೆಯಲು Cureskin  ವೈದ್ಯರೊಂದಿಗೆ ಉಚಿತ ದೈನಂದಿನ ತಪಾಸಣೆ ಮಾಡಿ.'],
      ta: ['மாதாந்திர மருத்துவ பரிசோதனை ',
        'உங்கள் கூந்தலின் முன்னேற்றத்தை விரிவாக அளவிட Cureskin மருத்துவருடன் இலவச ரொட்டீன் செக்-அப்ஸ்.'],
      ml: ['പ്രതിമാസ ഡോക്ടർ ചെക്കപ്പ്', 'നിങ്ങളുടെ തലമുടിയുടെ പുരോഗതി വിശദമായി അളക്കാൻ ഒരു Cureskin ഡോക്ടറുമായി സൗജന്യ പതിവ് ചെക്കപ്പുകൾ.'],
      bn: ['প্রতি মাসে ডাক্তারের চেকআপ', 'আপনার চুল কতটা উন্নত হয়েছে সেটা বিশদে দেখতে Cureskin-এর একজন ডাক্তারের কাছ থেকে বিনামূল্যে রুটিন চেকআপ করান।'],
      mr: ['डॉक्टरांकडून मोफत मासिक तपासणी', 'तुमच्या केसांची प्रगती तपशीलवार मोजण्यासाठी Cureskin डॉक्टरांमार्फत मोफत नियमित तपासणी.'],
      img: '/assets/images/dr-jisha.png',
    },
    {
      en: ['Hair-friendly Diet Plan',
        'Complimentary meal plans to make sure your daily diet contains food that boosts hair health. '],
      hi: ['बालों के अनुकूल डाइट प्लान',
        'कम्प्लीमेंटरी मील प्लान, ताकि आपके दैनिक आहार में वो भोजन शामिल हो जो स्वस्थ बालों को बढ़ावा देता है।'],
      te: ['హెయిర్ - ఫ్రెండ్లీ డైట్ ప్లాన్', 'మీ రోజువారీ ఆహారంలో జుట్టు యొక్క ఆరోగ్యాన్ని మెరుగుపరిచే ఆహారాన్ని కలిగి ఉండేలా కాంప్లిమెంటరీ మీల్ ప్లాన్‌లు.'],
      kn: ['ಕೂದಲಿಗೆ ಸರಿಹೊಂದುವಂತ ಡಯೆಟ್ ಪ್ಲಾನ್', 'ನಿಮ್ಮ ಪ್ರತಿದಿನದ ಆಹಾರದಲ್ಲಿ ಕೂದಲಿನ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸುವ ಆಹಾರವಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಉಚಿತ ಮೀಲ್ ಪ್ಲಾನ್.'],
      ta: ['ஹேர்-ஃபிரெண்ட்லி டயட் பிளான்', 'தினசரி உணவில் உங்கள் முடியின் ஆரோக்கியத்தை அதிகரிக்கும் உணவு இருத்தலை உறுதி செய்யும் விரிவான உணவுத்திட்டங்கள். '],
      ml: ['തലമുടി-സൗഹൃദ ഡയറ്റ് പ്ലാൻ',
        'തലമുടിയുടെ ആരോഗ്യം ഉത്തേജിപ്പിക്കാനുള്ള ഭക്ഷണം നിങ്ങളുടെ പ്രതിദിന ഡയറ്റിൽ ഉണ്ടെന്ന് ഉറപ്പിക്കാൻ സൗജന്യ ഭക്ഷണ പ്ലാനുകൾ'],
      bn: ['চুলের সাথে সামঞ্জস্যপূর্ণ ডায়েট প্ল্যান', 'আপনার প্রতিদিনের ডায়েটে এমন খাবার রয়েছে কিনা যেটা চুলকে আরো বেশি স্বাস্থ্যকর করে তোলে সেটা নিশ্চিত করার জন্য কমপ্লিমেন্টারি খাবারের পরিকল্পনা।'],
      mr: ['केसांसाठी अनुकूल डाएट प्लॅन',
        'तुमच्या दैनंदिन आहारात केसांचे आरोग्य वाढवणारे अन्न आहे याची खात्री करण्यासाठी मोफत जेवणाचे प्लॅन.'],
      img: 'https://storage.googleapis.com/heallocontent/diet.png',
    },
    {
      en: ['Free home delivery', 'No shipping charges or hidden costs. Only an effective treatment kit delivered right to your doorstep.'],
      hi: ['ऑनलाइन भुगतान पर फ्री होम डिलीवरी', 'कोई शिपिंग शुल्क या छिपे क़ीमत नहीं। सिर्फ आपके दरवाजे तक पहुँचाया गया एक प्रभावी ट्रीटमेंट किट।'],
      te: ['ఆన్‌లైన్ చెల్లింపుపై ఫ్రీ హోమ్ డెలివరీ', 'షిప్పింగ్ ఛార్జీలు లేవు లేదా దాగి ఉన్న ఛార్జీలు లేవు. సమర్థవంతమైన ట్రీట్మెంట్ కిట్ మాత్రమే మీ ఇంటికి డెలివేరి చేయబడుతుంది.'],
      kn: ['ಆನ್‌ಲೈನ್ ಪೇಮೆಂಟ್ ಗೆ ಉಚಿತ ಹೋಮ್ ಡೆಲಿವರಿ', 'ಯಾವುದೇ ಶಿಪ್ಪಿಂಗ್ ಚಾರ್ಜ್ ಗಳು ಅಥವಾ ಯಾವುದೇ ಹಿಡನ್ ಚಾರ್ಜ್ ಗಳಿಲ್ಲ. ಪರಿಣಾಮಕಾರಿ ಚಿಕಿತ್ಸಾ ಕಿಟ್ ಅನ್ನು ಮಾತ್ರ ನಿಮ್ಮ ಮನೆ ಬಾಗಿಲಿಗೆ ತಲುಪಿಸಲಾಗುತ್ತದೆ.'],
      ta: ['ஆன்லைன் பேமெண்ட்களில் இலவச ஹோம் டெலிவரி', 'ஷிப்பிங் கட்டணங்கள் இல்லை அல்லது மறைமுக கட்டணங்கள் இல்லை. ஒரு நற்பயன் தரும்  சிகிச்சை கிட் மட்டுமே உங்கள் வீட்டிற்கு டெலிவரி செய்யப்படுகிறது. '],
      ml: ['ഓൺലൈൻ പേയ്‌മെന്റിന് സൗജന്യ ഹോം ഡെലിവറി', 'ഷിപ്പിംഗ് നിരക്കുകൾ അല്ലെങ്കിൽ മറയ്ക്കപ്പെട്ട നിരക്കുകൾ ഒന്നുമില്ല. ഫലപ്രദമായ ചികിത്സ കിറ്റ് നിങ്ങളുടെ വീട്ടുപടിക്കൽ ഡെലിവർ ചെയ്യുക മാത്രം.'],
      bn: ['অনলাইনে পেমেন্ট করলে বিনামূল্যে হোম ডেলিভারি পাবেন', 'কোনো শিপিং চার্জ নেই বা কোনো লুকানো খরচ নেই। শুধুমাত্র আপনার দরজায় ডেলিভার করা একটি কার্যকরী ট্রিটমেন্ট কিট।'],
      mr: ['ऑनलाईन पेमेंट करा, फ्री होम डिलिव्हरी मिळवा', 'कोणतेही शिपिंग चार्जेस किंवा छुपे खर्च नाहीत. तुमच्या दारापर्यंत फक्त एक प्रभावी ट्रिटमेंट किट डिलिव्हर केली जाते.'],
      img: 'https://cdn.cureskin.com/app/img/regimen-service-4.png',
    },
    {
      en: ['Personal Hair Advisor', 'A team of hair advisors made available to you via the app, to solve your doubts and support your healthy hair journey.'],
      hi: ['व्यक्तिगत हेयर एडवाइजर', 'हेयर एडवाइजर की एक टीम जो कि आपकी शंकाओं को हल करने और स्वस्थ बालों के सफर को समर्थन देने के लिए ऐप पर उपलब्ध रहती है।'],
      te: ['పర్సనల్ హెయిర్ అడ్వైసర్', 'మీ సందేహాలను పరిష్కరించడానికి ఇంకా మీ ఆరోగ్యకరమైన హెయిర్ జర్నీకి మద్దతు ఇవ్వడానికి యాప్ ద్వారా మీకు హెయిర్ అడ్వైసర్ టీమ్ మీకు అందుబాటులో ఉంటారు.'],
      kn: ['ಪರ್ಸನಲ್ ಹೇರ್ ಅಡವೈಸರ್', 'ಹೇರ್ ಅಡವೈಸರ್ ನ ತಂಡವು ನಿಮ್ಮ ಸಂದೇಹಗಳನ್ನು ಬಗೆಹರಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಆರೋಗ್ಯಕರ ಹೇರ್ ಜರ್ನಿಯನ್ನು ಬೆಂಬಲಿಸಲು ಆಪ್ ಅಲ್ಲಿ ಲಭ್ಯವಿರುತ್ತಾರೆ.'],
      ta: ['பர்சனல் ஹேர் அட்வைசர்', 'உங்கள் சந்தேகங்களைத் தீர்த்து, ஆரோக்கியமான கூந்தலுக்கான உங்கள் பயணத்தில் உங்களுக்கு ஆதரவளிக்க, எங்கள் ஆப்‌ மூலம் ஹேர் அட்வைசர்கள் உங்களுடன் இணைந்திருப்பார்கள்'],
      ml: ['വ്യക്തിഗത തലമുടി ഉപദേഷ്ടാവ്', 'തലമുടി ഉപദേഷ്ടാക്കളുടെ ഒരു ടീം ആപ്പ് വഴി നിങ്ങളുടെ സംശയങ്ങൾ പരിഹരിക്കാനും ആരോഗ്യപൂർണമായ തലമുടിയിലേക്കുള്ള യാത്രയിൽ പിന്തുണയ്ക്കാനും ലഭ്യമാക്കിയിരിക്കുന്നു.'],
      bn: ['চুলের ব্যক্তিগত পরামর্শদাতা', 'আপনার সন্দেহ দূর করতে এবং আপনার স্বাস্থ্যকর চুলের যাত্রাকে সাপোর্ট করতে চুলের পরামর্শদাতাদের একটা টিম আপনার জন্য অ্যাপের মাধ্যমে উপলব্ধ আছেন।'],
      mr: ['पर्सनल हेअर ॲडव्हाईझर', 'तुमच्या शंका दूर करण्यासाठी आणि तुमच्या निरोगी केसांच्या प्रवासाला सपोर्ट करण्यासाठी हेअर ॲडव्हाईझर्सची टीम ॲपद्वारे उपलब्ध करून दिली जाते.'],
      img: 'https://storage.googleapis.com/heallocontent/support.png',
    },
  ],
  pro: [
    {
      en: ['100% Effectiveness', 'Clinically-tested ingredients and 5 customised products to ensure you get visible results.'],
      hi: ['100% प्रभावशाली', 'क्लिनिकल परीक्षण से गुजरे इंग्रेडिएंट्स और 5 कस्टमाइज्ड प्रोडक्ट्स ताकि आप बढ़िया नतीजे पाएं।'],
      te: ['100% ప్రభావతమైనది', 'మీరు అనుభూతి చెందగల ఫలితాలను పొందడానికి వైద్యపరంగా - పరీక్షించబడిన పదార్థాలు మరియు 5 అనుకూలీకరించిన ఉత్పత్తులు'],
      kn: ['100% ಪರಿಣಾಮಕಾರಿ', 'ನೀವು ಕಾಣುವಂತಹ ಫಲಿತಾಂಶ ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಕ್ಲಿನಿಕಲಿ ಟೆಸ್ಟ್ ಮಾಡಿದ ಪದಾರ್ಥಗಳು ಮತ್ತು 5 ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಪ್ರಾಡಕ್ಟ್ ಗಳು.'],
      ta: ['100% தீவிரமானது', 'நீங்கள் உணரக்கூடிய முடிவுகளைப் பெறுவதற்கென மருத்துவ ரீதியாக பரிசோதிக்கப்பட்ட உட்கொருட்களை கொண்டது மற்றும் இதில் 5 தனிப்பயனாக்கப்பட்ட தயாரிப்புகள் அடங்கும்.'],
      ml: ['100% ഫലപ്രദം', 'നിങ്ങൾക്ക് ദൃശ്യമാകുന്ന ഫലങ്ങൾ ലഭിക്കുന്നു എന്നുറപ്പാക്കാൻ വൈദ്യശാസ്ത്ര-പരീക്ഷിത സാമഗ്രികളും 5 വ്യക്തിഗത ഉത്പന്നങ്ങളും.'],
      bn: ['100% কার্যকারিতা', 'আপনি যাতে দুর্দান্ত ফলাফল পান তা নিশ্চিত করতে ক্লিনিক্যালি-টেস্ট করা উপাদান এবং 5 কাস্টমাইজড প্রোডাক্ট।'],
      mr: ['100% परिणामकारकता (इफेक्टिव्हनेस)', 'तुम्हाला दृश्यमान परिणाम मिळतील याची खात्री करण्यासाठी क्लिनीकली-टेस्टेड घटक आणि 5 कस्टमाईझ्ड प्रॉडक्ट्स.'],
      img: 'https://storage.googleapis.com/heallocontent/kit.png',
    },
    {
      en: ['Diet Consultation Call with Nutritionist',
        'Complimentary consultation and diet tips given by a Cureskin nutritionist, to ensure your daily meal contains food that boosts hair health.'],
      hi: ['नूट्रिशनिस्ट के साथ डाइट परामर्श कॉल  ', 'Cureskin नूट्रिशनिस्ट के साथ परामर्श कॉल और उनकी दी गयी सलाह। ताकि आपके दैनिक आहार में वो भोजन शामिल हो जो स्वस्थ बालों को बढ़ावा देता है। '],
      te: ['న్యూట్రిషనిస్ట్‌తో డైట్ కన్సల్టేషన్ కాల్', 'మీ డెయిలీ మీల్ లో హెయిర్ హెల్త్ ని మెరుగుపరిచే ఆహారాన్ని కలిగి ఉండేలా చూసుకోవడానికి Cureskin న్యూట్రిషనిస్ట్‌ అందిస్తున్న కాంప్లిమెంటరీ కన్సల్టేషన్‌ ఇంకా డైట్ టిప్స్.'],
      kn: ['ನ್ಯೂಟ್ರಿಷನಿಸ್ಟ್ ನ ಜೊತೆ ಡಯೆಟ್ ಕನ್ಸಲ್ಟೇಶನ್ ಕಾಲ್ ', 'ಕ್ಯೂರೆಸ್ಕಿನ್ ನ್ಯೂಟ್ರಿಷನಿಸ್ಟ್ ನೀಡಿದ ಕಾಂಪ್ಲಿಮೆಂಟರಿ ಕನ್ಸಲ್ಟೇಶನ್ ಮತ್ತು ಡಯೆಟ್ ಟಿಪ್ಸ್, ಇದರಿಂದ ನಿಮ್ಮ ಪ್ರತಿದಿನದ ಆಹಾರವು ಆರೋಗ್ಯಕರ ಕೂದಲನ್ನು ಉತ್ತೇಜಿಸುವ ಆಹಾರವನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.'],
      ta: ['ஊட்டச்சத்து நிபுணருடன் டயட் கன்சல்டேஷன் கால்', 'உங்கள் தினசரி உணவில் கூந்தலின் ஆரோக்கியத்தை மேம்படுத்தும் உணவுகள் உள்ளதா என்பதை உறுதிப்படுத்த, Cureskin ஊட்டச்சத்து நிபுணர் மூலம் ஒரு காம்ப்ளிமென்ட்ரி கன்சல்டேசன் பெறலாம் மற்றும் அவரே உங்களுக்கான உணவுத் திட்டத்தையும் தயாரித்து வழங்குவார்.'],
      ml: ['ന്യൂട്രീഷനിസ്റ്റുമായി ഡയറ്റ് കൺസൾട്ടേഷൻ കോൾ', 'തലമുടിയുടെ ആരോഗ്യം ഉത്തേജിപ്പിക്കാനുള്ള ഭക്ഷണം നിങ്ങളുടെ പ്രതിദിന ആഹാരത്തിൽ ഉണ്ടെന്ന് ഉറപ്പിക്കാൻ സൗജന്യ കൺസൾട്ടേഷനും Cureskin ന്യൂട്രീഷനിസ്റ്റ് നൽകുന്ന ഡയറ്റ് ടിപ്‌സും.'],
      bn: ['নিউট্রিশনিস্টের সাথে ডায়েটের ব্যাপারে পরামর্শের জন্য কল', 'আপনার প্রতিদিনের খাবারে এমন খাদ্য রয়েছে কিনা যেটা চুলকে আরো বেশি স্বাস্থ্যকর করে তোলে সেটা নিশ্চিত করার জন্য Cureskin-এর নিউট্রিশনিস্টের দেওয়া কমপ্লিমেন্টারি কন্সালটেশন এবং ডায়েট টিপস।'],
      mr: ['न्यूट्रिशनिस्ट सह डाएट कंसल्टेशन कॉल', 'तुमच्या दैनंदिन आहारात केसांचे आरोग्य वाढवणारे डाएट असल्याचे सुनिश्चित करण्यासाठी Cureskin  न्यूट्रिशनिस्ट द्वारे कॉम्प्लिमेंटरी कंसल्टेशन आणि डाएट टिप्स दिल्या जातात.'],
      img: 'https://storage.googleapis.com/heallocontent/diet.png',
    },
    {
      en: ['Monthly Doctor Checkup',
        'Free routine checkups with a Cureskin doctor to measure your hair progress in detail.'],
      hi: ['मासिक डॉक्टर चेकअप', 'आपकी बालों के सुधार को ट्रैक करने के लिए Cureskin डॉक्टर के साथ नि:शुल्क रूटीन चेकअप।'],
      te: ['నెలవారీ డాక్టర్ చెక్అప్', 'మీ హెయిర్ ప్రోగ్రెస్ ని వివరంగా కొలవడానికి Cureskin వైద్యునితో ఫ్రీ రెగ్యులర్ చెక్అప్.'],
      kn: ['ಪ್ರತಿ ತಿಂಗಳು ವೈದ್ಯರ ತಪಾಸಣೆ', 'ನಿಮ್ಮ ಹೇರ್ ಪ್ರೋಗ್ರೆಸ್ ಅನ್ನು ವಿವರವಾಗಿ ಅಳೆಯಲು Cureskin  ವೈದ್ಯರೊಂದಿಗೆ ಉಚಿತ ದೈನಂದಿನ ತಪಾಸಣೆ ಮಾಡಿ.'],
      ta: ['மாதாந்திர மருத்துவ பரிசோதனை ',
        'உங்கள் கூந்தலின் முன்னேற்றத்தை விரிவாக அளவிட Cureskin மருத்துவருடன் இலவச ரொட்டீன் செக்-அப்ஸ்'],
      ml: ['പ്രതിമാസ ഡോക്ടർ ചെക്കപ്പ്', 'നിങ്ങളുടെ തലമുടിയുടെ പുരോഗതി വിശദമായി അളക്കാൻ ഒരു Cureskin ഡോക്ടറുമായി സൗജന്യ പതിവ് ചെക്കപ്പുകൾ.'],
      bn: ['প্রতি মাসে ডাক্তারের চেকআপ', 'আপনার চুল কতটা উন্নত হয়েছে সেটা বিশদে দেখতে Cureskin-এর একজন ডাক্তারের কাছ থেকে বিনামূল্যে রুটিন চেকআপ করান।'],
      mr: ['डॉक्टरांकडून मोफत मासिक तपासणी', 'तुमच्या केसांची प्रगती तपशीलवार मोजण्यासाठी Cureskin डॉक्टरांमार्फत मोफत नियमित तपासणी.'],
      img: '/assets/images/dr-jisha.png',
    },
    {
      en: ['Free home delivery', 'No shipping charges or hidden costs. Only an effective treatment kit delivered right to your doorstep.'],
      hi: ['ऑनलाइन भुगतान पर फ्री होम डिलीवरी', 'कोई शिपिंग शुल्क या छिपे क़ीमत नहीं। सिर्फ आपके दरवाजे तक पहुँचाया गया एक प्रभावी ट्रीटमेंट किट।'],
      te: ['ఆన్‌లైన్ చెల్లింపుపై ఫ్రీ హోమ్ డెలివరీ', 'షిప్పింగ్ ఛార్జీలు లేవు లేదా దాగి ఉన్న ఛార్జీలు లేవు. సమర్థవంతమైన ట్రీట్మెంట్ కిట్ మాత్రమే మీ ఇంటికి డెలివేరి చేయబడుతుంది.'],
      kn: ['ಆನ್‌ಲೈನ್ ಪೇಮೆಂಟ್ ಗೆ ಉಚಿತ ಹೋಮ್ ಡೆಲಿವರಿ', 'ಯಾವುದೇ ಶಿಪ್ಪಿಂಗ್ ಚಾರ್ಜ್ ಗಳು ಅಥವಾ ಯಾವುದೇ ಹಿಡನ್ ಚಾರ್ಜ್ ಗಳಿಲ್ಲ. ಪರಿಣಾಮಕಾರಿ ಚಿಕಿತ್ಸಾ ಕಿಟ್ ಅನ್ನು ಮಾತ್ರ ನಿಮ್ಮ ಮನೆ ಬಾಗಿಲಿಗೆ ತಲುಪಿಸಲಾಗುತ್ತದೆ.'],
      ta: ['ஆன்லைன் பேமெண்ட்களில் இலவச ஹோம் டெலிவரி', 'ஷிப்பிங் கட்டணங்கள் இல்லை அல்லது மறைமுக கட்டணங்கள் இல்லை. ஒரு நற்பயன் தரும்  சிகிச்சை கிட் மட்டுமே உங்கள் வீட்டிற்கு டெலிவரி செய்யப்படுகிறது. '],
      ml: ['ഓൺലൈൻ പേയ്‌മെന്റിന് സൗജന്യ ഹോം ഡെലിവറി', 'ഷിപ്പിംഗ് നിരക്കുകൾ അല്ലെങ്കിൽ മറയ്ക്കപ്പെട്ട നിരക്കുകൾ ഒന്നുമില്ല. ഫലപ്രദമായ ചികിത്സ കിറ്റ് നിങ്ങളുടെ വീട്ടുപടിക്കൽ ഡെലിവർ ചെയ്യുക മാത്രം.'],
      bn: ['অনলাইনে পেমেন্ট করলে বিনামূল্যে হোম ডেলিভারি পাবেন', 'কোনো শিপিং চার্জ নেই বা কোনো লুকানো খরচ নেই। শুধুমাত্র আপনার দরজায় ডেলিভার করা একটি কার্যকরী ট্রিটমেন্ট কিট।'],
      mr: ['ऑनलाईन पेमेंट करा, फ्री होम डिलिव्हरी मिळवा', 'कोणतेही शिपिंग चार्जेस किंवा छुपे खर्च नाहीत. तुमच्या दारापर्यंत फक्त एक प्रभावी ट्रिटमेंट किट डिलिव्हर केली जाते.'],
      img: 'https://cdn.cureskin.com/app/img/regimen-service-4.png',
    },
    {
      en: ['Personal Hair Advisor', 'A team of hair advisors made available to you via the app, to solve your doubts and support your healthy hair journey.'],
      hi: ['व्यक्तिगत हेयर एडवाइजर', 'हेयर एडवाइजर की एक टीम जो कि आपकी शंकाओं को हल करने और स्वस्थ बालों के सफर को समर्थन देने के लिए ऐप पर उपलब्ध रहती है।'],
      te: ['పర్సనల్ హెయిర్ అడ్వైసర్', 'మీ సందేహాలను పరిష్కరించడానికి ఇంకా మీ ఆరోగ్యకరమైన హెయిర్ జర్నీకి మద్దతు ఇవ్వడానికి యాప్ ద్వారా మీకు హెయిర్ అడ్వైసర్ టీమ్ మీకు అందుబాటులో ఉంటారు.'],
      kn: ['ಪರ್ಸನಲ್ ಹೇರ್ ಅಡವೈಸರ್', 'ಹೇರ್ ಅಡವೈಸರ್ ನ ತಂಡವು ನಿಮ್ಮ ಸಂದೇಹಗಳನ್ನು ಬಗೆಹರಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಆರೋಗ್ಯಕರ ಹೇರ್ ಜರ್ನಿಯನ್ನು ಬೆಂಬಲಿಸಲು ಆಪ್ ಅಲ್ಲಿ ಲಭ್ಯವಿರುತ್ತಾರೆ.'],
      ta: ['பர்சனல் ஹேர் அட்வைசர்', 'உங்கள் சந்தேகங்களைத் தீர்த்து, ஆரோக்கியமான கூந்தலுக்கான உங்கள் பயணத்தில் உங்களுக்கு ஆதரவளிக்க, எங்கள் ஆப்‌ மூலம் ஹேர் அட்வைசர்கள் உங்களுடன் இணைந்திருப்பார்கள்'],
      ml: ['വ്യക്തിഗത തലമുടി ഉപദേഷ്ടാവ്', 'തലമുടി ഉപദേഷ്ടാക്കളുടെ ഒരു ടീം ആപ്പ് വഴി നിങ്ങളുടെ സംശയങ്ങൾ പരിഹരിക്കാനും ആരോഗ്യപൂർണമായ തലമുടിയിലേക്കുള്ള യാത്രയിൽ പിന്തുണയ്ക്കാനും ലഭ്യമാക്കിയിരിക്കുന്നു.'],
      bn: ['চুলের ব্যক্তিগত পরামর্শদাতা', 'আপনার সন্দেহ দূর করতে এবং আপনার স্বাস্থ্যকর চুলের যাত্রাকে সাপোর্ট করতে চুলের পরামর্শদাতাদের একটা টিম আপনার জন্য অ্যাপের মাধ্যমে উপলব্ধ আছেন।'],
      mr: ['पर्सनल हेअर ॲडव्हाईझर', 'तुमच्या शंका दूर करण्यासाठी आणि तुमच्या निरोगी केसांच्या प्रवासाला सपोर्ट करण्यासाठी हेअर ॲडव्हाईझर्सची टीम ॲपद्वारे उपलब्ध करून दिली जाते.'],
      img: 'https://storage.googleapis.com/heallocontent/support.png',
    },
  ],
  assist: [
    {
      en: ['100% Effectiveness', 'Clinically-tested ingredients and 5 customised products to ensure you get visible results.'],
      hi: ['100% प्रभावशाली', 'क्लिनिकल परीक्षण से गुजरे इंग्रेडिएंट्स और 5 कस्टमाइज्ड प्रोडक्ट्स ताकि आप बढ़िया नतीजे पाएं।'],
      te: ['100% ప్రభావతమైనది', 'మీరు అనుభూతి చెందగల ఫలితాలను పొందడానికి వైద్యపరంగా - పరీక్షించబడిన పదార్థాలు మరియు 5 అనుకూలీకరించిన ఉత్పత్తులు'],
      kn: ['100% ಪರಿಣಾಮಕಾರಿ', 'ನೀವು ಕಾಣುವಂತಹ ಫಲಿತಾಂಶ ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಕ್ಲಿನಿಕಲಿ ಟೆಸ್ಟ್ ಮಾಡಿದ ಪದಾರ್ಥಗಳು ಮತ್ತು 5 ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಪ್ರಾಡಕ್ಟ್ ಗಳು.'],
      ta: ['100% தீவிரமானது', 'நீங்கள் உணரக்கூடிய முடிவுகளைப் பெறுவதற்கென மருத்துவ ரீதியாக பரிசோதிக்கப்பட்ட உட்கொருட்களை கொண்டது மற்றும் இதில் 5 தனிப்பயனாக்கப்பட்ட தயாரிப்புகள் அடங்கும்.'],
      ml: ['100% ഫലപ്രദം', 'നിങ്ങൾക്ക് ദൃശ്യമാകുന്ന ഫലങ്ങൾ ലഭിക്കുന്നു എന്നുറപ്പാക്കാൻ വൈദ്യശാസ്ത്ര-പരീക്ഷിത സാമഗ്രികളും 5 വ്യക്തിഗത ഉത്പന്നങ്ങളും.'],
      bn: ['100% কার্যকারিতা', 'আপনি যাতে দুর্দান্ত ফলাফল পান তা নিশ্চিত করতে ক্লিনিক্যালি-টেস্ট করা উপাদান এবং 5 কাস্টমাইজড প্রোডাক্ট।'],
      mr: ['100% परिणामकारकता (इफेक्टिव्हनेस)', 'तुम्हाला दृश्यमान परिणाम मिळतील याची खात्री करण्यासाठी क्लिनीकली-टेस्टेड घटक आणि 5 कस्टमाईझ्ड प्रॉडक्ट्स.'],
      img: 'https://storage.googleapis.com/heallocontent/kit.png',
    },
    {
      en: ['Free Call with Doctor', 'Assigned doctor calls you before shipping of regimen, to understand the patient history well and clarify your all doubts.'],
      hi: ['डॉक्टर के साथ फ्री कॉल', 'डॉक्टर आपके रेजिमेन की शिपिंग से पहले-पहले आपको कॉल करेंगे। ताकि आप अपने ट्रीटमेंट से संबंधित सभी सवाल पूछ सकें। '],
      te: ['డాక్టర్‌తో ఫ్రీ కాల్', 'పేషెంట్ హిస్టరీని బాగా అర్థం చేసుకోవడానికి ఇంకా మీ అన్ని సందేహాలను స్పష్టం చేయడానికి రెజిమెన్ ని షిప్పింగ్ చేయడానికి మీకు అసైన్ చేయబడిన డాక్టర్ మీకు ముందుగా కాల్ చేస్తారు.'],
      kn: ['ಡಾಕ್ಟರ್ ಜೊತೆ 15 ನಿಮಿಷಗಳ ವೀಡಿಯೊ ಕಾಲ್ ', 'ಅಸೈನ್ ಮಾಡಿದ ಡಾಕ್ಟರ್ ರೆಜಿಮೆನ್ ಅನ್ನು ಕಳುಹಿಸುವ ಮುಂಚೆ ನಿಮ್ಮ ಸಮಸ್ಯೆಯ ಬಗ್ಗೆ ಸರಿಯಾಗಿ ತಿಳಿದುಕೊಳ್ಳಲು ಮತ್ತು ನಿಮ್ಮ ಎಲ್ಲಾ ಅನುಮಾನಗಳನ್ನು ಬಗೆಹರಿಸಲು ನಿಮಗೆ ಕರೆ ಮಾಡುತ್ತಾರೆ.'],
      ta: ['டாக்டருடன் இலவச கால்', 'நோயாளியின் உடம்பு பற்றிய ஹிஸ்டரியை நன்கு புரிந்துகொள்வதற்கும் உங்கள் சந்தேகங்களைத் தெளிவுபடுத்துவதற்கும் உங்களுக்கென ஒதுக்கப்பட்ட டாக்டர், ரெஜிமனை அனுப்புவதற்கு முன் உங்களக்கு கால் செய்வார்'],
      ml: ['ഡോക്ടറുമായി സൗജന്യ കോൾ', 'ചികിത്സാക്രമം അയക്കുന്നതിന് മുൻപ് നിങ്ങളുടെ ഹിസ്റ്ററി നന്നായി അറിയാനും എല്ലാ സംശയങ്ങളും വ്യക്തമാക്കാനും നിശ്ചയിക്കപ്പെട്ട ഡോക്ടർ നിങ്ങളെ വിളിക്കും.'],
      bn: ['বিনামূল্যে ডাক্তারের সাথে কল', 'আপনার কাছে রেজিমেন পাঠানোর আগে রোগীর রোগের ব্যাপারে ভালোভাবে জানতে এবং আপনার সমস্ত প্রশ্নের উত্তর দিতে নির্দিষ্ট ডাক্তার আপনাকে কল করবেন।'],
      mr: ['डॉक्टरांसोबत फ्री कॉल', 'पेशंट हिस्ट्री चांगल्या प्रकारे समजून घेण्यासाठी आणि तुमच्या सर्व शंकांचे निरसन करण्यासाठी असाईन केलेले  डॉक्टर पथ्ये पाठवण्यापूर्वी तुम्हाला कॉल करतात.'],
      img: '/assets/images/dr-jisha.png',
    },
    {
      en: ['2 Diet Consultation Calls with Nutritionist', 'Complimentary consultations and diet tips given by a Cureskin nutritionist, to ensure your daily meal contains food that boosts hair health.'],
      hi: ['2 डाइट परामर्श कॉल नूट्रिशनिस्ट के साथ ', 'Cureskin नूट्रिशनिस्ट के साथ २ परामर्श कॉल्स और उनकी दी गयी सलाह। ताकि आपके दैनिक आहार में वो भोजन शामिल हो जो स्वस्थ बालों को बढ़ावा देता है। '],
      te: ['న్యూట్రిషనిస్ట్‌తో 2 డైట్ కన్సల్టేషన్ కాల్స్', 'మీ డెయిలీ మీల్ లో హెయిర్ హెల్త్ ని మెరుగుపరిచే ఆహారాన్ని కలిగి ఉండేలా చూసుకోవడానికి Cureskin న్యూట్రిషనిస్ట్‌ అందిస్తున్న కాంప్లిమెంటరీ కన్సల్టేషన్‌ ఇంకా డైట్ టిప్స్.'],
      kn: ['ನ್ಯೂಟ್ರಿಷನಿಸ್ಟ್ ನ ಜೊತೆ 2 ಡಯೆಟ್ ಕನ್ಸಲ್ಟೇಶನ್ ಕಾಲ್ಸ್', 'ಕ್ಯೂರೆಸ್ಕಿನ್ ನ್ಯೂಟ್ರಿಷನಿಸ್ಟ್ ನೀಡಿದ ಕಾಂಪ್ಲಿಮೆಂಟರಿ ಕನ್ಸಲ್ಟೇಶನ್ ಮತ್ತು ಡಯೆಟ್ ಟಿಪ್ಸ್, ಇದರಿಂದ ನಿಮ್ಮ ಪ್ರತಿದಿನದ ಆಹಾರವು ಆರೋಗ್ಯಕರ ಕೂದಲನ್ನು ಉತ್ತೇಜಿಸುವ ಆಹಾರವನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.'],
      ta: ['ஊட்டச்சத்து நிபுணருடன் 2 டயட் கன்சல்டேஷன் கால்ஸ்.', 'உங்கள் தினசரி உணவில் கூந்தலின் ஆரோக்கியத்தை மேம்படுத்தும் உணவுகள் உள்ளதா என்பதை உறுதிப்படுத்த, Cureskin ஊட்டச்சத்து நிபுணர் மூலம் ஒரு காம்ப்ளிமென்ட்ரி கன்சல்டேசன் பெறலாம் மற்றும் அவரே உங்களுக்கான உணவுத் திட்டத்தையும் தயாரித்து வழங்குவார்.'],
      ml: ['ന്യൂട്രീഷനിസ്റ്റുമായി 2 ഡയറ്റ് കൺസൾട്ടേഷൻ കോളുകൾ', 'തലമുടിയുടെ ആരോഗ്യം ഉത്തേജിപ്പിക്കാനുള്ള ഭക്ഷണം നിങ്ങളുടെ പ്രതിദിന ആഹാരത്തിൽ ഉണ്ടെന്ന് ഉറപ്പിക്കാൻ സൗജന്യ കൺസൾട്ടേഷനും Cureskin ന്യൂട്രീഷനിസ്റ്റ് നൽകുന്ന ഡയറ്റ് ടിപ്‌സും.'],
      bn: ['নিউট্রিশনিস্টের সাথে 2টো ডায়েট কন্সালটেশন কল', 'আপনার প্রতিদিনের খাবারে এমন খাদ্য রয়েছে কিনা যেটা চুলকে আরো বেশি স্বাস্থ্যকর করে তোলে সেটা নিশ্চিত করার জন্য Cureskin-এর নিউট্রিশনিস্টের দেওয়া কমপ্লিমেন্টারি কন্সালটেশন এবং ডায়েট টিপস।'],
      mr: ['न्यूट्रिशनिस्ट सह  2 डाएट कंसल्टेशन कॉल', 'तुमच्या दैनंदिन आहारात केसांचे आरोग्य वाढवणारे डाएट असल्याचे सुनिश्चित करण्यासाठी Cureskin  न्यूट्रिशनिस्ट द्वारे कॉम्प्लिमेंटरी कंसल्टेशन आणि डाएट टिप्स दिल्या जातात.'],
      img: 'https://storage.googleapis.com/heallocontent/diet.png',
    },
    {
      en: ['Multiple Doctor Checkups + Anytime Support', 'Free routine checkups to monitor hair progress and anytime support to solve all patient queries and doubts.'],
      hi: ['कई डॉक्टर चेकअप + पूरा सपोर्ट', 'त्बालों के सुधार की निगरानी के लिए नि:शुल्क नियमित जांच। इसके अलावा आपके सभी प्रश्नों और शंकाओं को हल करने के लिए पूरी सहायता।'],
      te: ['అనేక డాక్టర్ చెక్ అప్స్ + ఎప్పుడైనా సపోర్ట్ ', 'హెయిర్ ప్రోగ్రెస్‌ని పర్యవేక్షించడానికి ఫ్రీ రొటీన్ చెకప్‌లు ఇంకా పేషెంట్ యొక్క ప్రతి ప్రశ్నలు మరియు సందేహాలను పరిష్కరించడానికి ఎల్లప్పుడూ సపోర్ట్.'],
      kn: ['ಅನೇಕ ಡಾಕ್ಟರ್ ಚೆಕಪ್ ಗಳು + ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಬೆಂಬಲ', 'ಹೇರ್ ಪ್ರೋಗ್ರೆಸ್ ಅನ್ನು ಮೇಲ್ವಿಚಾರಣೆ ಮಾಡಲು ಉಚಿತ ರೊಟಿನ್ ಚೆಕಪ್ ಗಳು ಮತ್ತು ಎಲ್ಲಾ ರೋಗಿಗಳ ಪ್ರಶ್ನೆಗಳು ಮತ್ತು ಅನುಮಾನಗಳನ್ನು ಪರಿಹರಿಸಲು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಬೆಂಬಲ.'],
      ta: ['பல டாக்டர் செக்-அப்ஸ் + எந்நேரத்திலும் சப்போர்ட்', 'உங்கள் கூந்தல் முன்னேற்றத்தை கண்காணிக்க இலவச ரொட்டீன் செக்-அப்ஸ் மற்றும் நோயாளியின் அனைத்து கேள்விகள் மற்றும் சந்தேகங்களை தீர்க்க எல்லா நேரத்திலும் சப்போர்ட்.'],
      ml: ['അനവധി ഡോക്ടർ ചെക്കപ്പുകൾ + ഏത് സമയത്തും പിന്തുണ', 'തലമുടിയുടെ പുരോഗതി നിരീക്ഷിക്കാൻ പതിവ് സൗജന്യ ചെക്കപ്പുകളും രോഗിയുടെ എല്ലാ ചോദ്യങ്ങളും സംശയങ്ങളും പരിഹരിക്കാൻ ഏത് സമയ പിന്തുണയും.'],
      bn: ['একাধিক ডাক্তারের দ্বারা চেকআপ + যে কোনো সময় সহায়তা ', 'রোগীর চুলের উন্নতিকে পর্যবেক্ষণ করার জন্য বিনামূল্যে রুটিন চেকআপ করা হবে এবং রোগীর সমস্ত প্রশ্ন ও সন্দেহের সমাধান করার জন্য যে কোনো সময় সহায়তা করা হবে।'],
      mr: ['अनेक डॉक्टरांकडून तपासणी + कधीही सपोर्ट', 'केसांच्या प्रगतीवर लक्ष ठेवण्यासाठी नियमितपणे मोफत तपासणी आणि रुग्णाच्या सर्व प्रश्न आणि शंकांचे निराकरण करण्यासाठी कधीही सपोर्ट उपलब्ध.'],
      img: '/assets/images/dr-jisha.png',
    },
    {
      en: ['Free Blood Test for Special Cases', 'If prescribed by the doctor, a detailed blood test will be conductued to find the main reason behind your hair problem. Free home sample collection.'],
      hi: ['विशेष मामलों के लिए फ्री ब्लड टेस्ट', 'Cureskin डॉक्टर के निर्देशानुसार, बालों की चिंता के मूल कारण को समझने और उसके निवारण के लिए एक विस्तृत ब्लड टेस्ट। इसमें घर से ब्लड सैंपल लेने की निःशुल्क सेवा भी शामिल होगी।'],
      te: ['స్పెషల్ కేసుల కోసం ఫ్రీ బ్లడ్ టెస్ట్ రిపోర్ట్', 'డాక్టర్ సూచించినట్లయితే, మీ జుట్టు సమస్య వెనుక ఉన్న ప్రధాన కారణాన్ని కనుగొనడానికి ఒక వివరణాత్మకమైన బ్లడ్ టెస్ట్ నిర్వహించబడుతుంది. దీనికి ఫ్రీ హోమ్ శాంపిల్ కలెక్షన్ కూడా ఉంది.'],
      kn: ['ಸ್ಪೆಷಲ್ ಕೇಸ್ ಗಳಿಗೆ ಉಚಿತ ಬ್ಲಡ್ ಟೆಸ್ಟ್ ರಿಪೋರ್ಟ್', 'ಡಾಕ್ಟರ್ ಸೂಚಿಸಿದರೆ, ನಿಮ್ಮ ಕೂದಲಿನ ಸಮಸ್ಯೆಯ ಹಿಂದಿನ ಮುಖ್ಯ ಕಾರಣವನ್ನು ಕಂಡುಹಿಡಿಯಲು ವಿವರವಾದ ಬ್ಲಡ್ ಟೆಸ್ಟ್ ಅನ್ನು ಮಾಡಲಾಗುತ್ತದೆ. ಉಚಿತವಾಗಿ ಮನೆಯಲ್ಲಿ ಸಾಂಪಲ್ ಕಲೆಕ್ಟ್ ಮಾಡಲಾಗುತ್ತದೆ .'],
      ta: ['ஸ்பெஷல் கேஸ் - இலவச இரத்த டெஸ்ட் ரிப்போர்ட் ', 'டாக்டர் பரிந்துரைக்கும் பட்சத்தில், உங்கள் கூந்தல் பிரச்சனைக்கான காரணத்தை கண்டறிய விரிவான இரத்தப் பரிசோதனை மேற்கொள்ளப்படும். இலவச வீட்டு சாம்பிள் கலெக்ஷன்'],
      ml: ['സൗജന്യ ബ്ലഡ് ടെസ്റ്റ് റിപ്പോർട്ട് ചില പ്രത്യേക കേസുകളിൽ', 'ഡോക്ടർ നിർദേശിച്ചതാണെങ്കിൽ, നിങ്ങളുടെ തലമുടിയുടെ പ്രശ്നത്തിന് പിന്നിലെ പ്രധാന കാരണം കണ്ടെത്താൻ വിശദമായ ബ്ലഡ് ടെസ്റ്റ് നടത്തും. സൗജന്യമായി വീട്ടിൽ നിന്ന് സാമ്പിൾ കളക്ഷൻ.'],
      bn: ['বিশেষ ক্ষেত্রে বিনামূল্যে রক্ত পরীক্ষার রিপোর্ট', 'যদি ডাক্তার পরামর্শ দেন, তবে আপনার চুলের সমস্যার মূল কারণ খুঁজে পেতে বিশদভাবে রক্ত পরীক্ষা করা হবে। বাড়ি থেকে বিনামূল্যে নমুনা সংগ্রহ করা হবে। '],
      mr: ['स्पेशल केसेससाठी फ्री ब्लड टेस्ट रिपोर्ट', 'डॉक्टरांनी लिहून दिल्यास, तुमच्या केसांच्या समस्येमागील मुख्य कारण शोधण्यासाठी डीटेल्ड ब्लड टेस्ट केली जाईल. घरी येऊन मोफत सॅम्पल घेतले जाईल.'],
      img: '/assets/images/2499/blood-test.jpg',
    },
  ],
  introductory: [
    {
      en: ['100% Effectiveness', 'Clinically-tested ingredients and 3 customised products to ensure you get visible results.'],
      hi: ['100% प्रभावशाली', 'क्लिनिकल परीक्षण से गुजरे इंग्रेडिएंट्स और 3 कस्टमाइज्ड प्रोडक्ट्स ताकि आप बढ़िया नतीजे पाएं।'],
      te: ['100% ప్రభావతమైనది', 'మీరు అనుభూతి చెందగల ఫలితాలను పొందడానికి వైద్యపరంగా - పరీక్షించబడిన పదార్థాలు మరియు 3 అనుకూలీకరించిన ఉత్పత్తులు'],
      kn: ['100% ಪರಿಣಾಮಕಾರಿ', 'ನೀವು ಕಾಣುವಂತಹ ಫಲಿತಾಂಶ ಪಡೆಯುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಕ್ಲಿನಿಕಲಿ ಟೆಸ್ಟ್ ಮಾಡಿದ ಪದಾರ್ಥಗಳು ಮತ್ತು 3 ಕಸ್ಟಮೈಸ್ ಮಾಡಿದ ಪ್ರಾಡಕ್ಟ್ ಗಳು.'],
      ta: ['100% தீவிரமானது', 'நீங்கள் உணரக்கூடிய முடிவுகளைப் பெறுவதற்கென மருத்துவ ரீதியாக பரிசோதிக்கப்பட்ட உட்கொருட்களை கொண்டது மற்றும் இதில் 3 தனிப்பயனாக்கப்பட்ட தயாரிப்புகள் அடங்கும்.'],
      ml: ['100% ഫലപ്രദം', 'നിങ്ങൾക്ക് ദൃശ്യമാകുന്ന ഫലങ്ങൾ ലഭിക്കുന്നു എന്നുറപ്പാക്കാൻ വൈദ്യശാസ്ത്ര-പരീക്ഷിത സാമഗ്രികളും 3 വ്യക്തിഗത ഉത്പന്നങ്ങളും.'],
      bn: ['100% কার্যকারিতা', 'আপনি যাতে দুর্দান্ত ফলাফল পান তা নিশ্চিত করতে ক্লিনিক্যালি-টেস্ট করা উপাদান এবং 3 কাস্টমাইজড প্রোডাক্ট।'],
      mr: ['100% परिणामकारकता (इफेक्टिव्हनेस)', 'तुम्हाला दृश्यमान परिणाम मिळतील याची खात्री करण्यासाठी क्लिनीकली-टेस्टेड घटक आणि 3 कस्टमाईझ्ड प्रॉडक्ट्स.'],
      img: 'https://storage.googleapis.com/heallocontent/kit.png',
    },
    {
      en: ['Monthly Doctor Checkup', 'Free routine checkups with a Cureskin doctor to measure your hair progress in detail.'],
      hi: ['मासिक डॉक्टर चेकअप', 'आपकी बालों के सुधार को ट्रैक करने के लिए Cureskin डॉक्टर के साथ नि:शुल्क रूटीन चेकअप।'],
      te: ['నెలవారీ డాక్టర్ చెక్అప్', 'మీ హెయిర్ ప్రోగ్రెస్ ని వివరంగా కొలవడానికి Cureskin వైద్యునితో ఫ్రీ రెగ్యులర్ చెక్అప్.'],
      kn: ['ಪ್ರತಿ ತಿಂಗಳು ವೈದ್ಯರ ತಪಾಸಣೆ', 'ನಿಮ್ಮ ಹೇರ್ ಪ್ರೋಗ್ರೆಸ್ ಅನ್ನು ವಿವರವಾಗಿ ಅಳೆಯಲು Cureskin  ವೈದ್ಯರೊಂದಿಗೆ ಉಚಿತ ದೈನಂದಿನ ತಪಾಸಣೆ ಮಾಡಿ.'],
      ta: ['மாதாந்திர மருத்துவ பரிசோதனை ',
        'உங்கள் கூந்தலின் முன்னேற்றத்தை விரிவாக அளவிட Cureskin மருத்துவருடன் இலவச ரொட்டீன் செக்-அப்ஸ்.'],
      ml: ['പ്രതിമാസ ഡോക്ടർ ചെക്കപ്പ്', 'നിങ്ങളുടെ തലമുടിയുടെ പുരോഗതി വിശദമായി അളക്കാൻ ഒരു Cureskin ഡോക്ടറുമായി സൗജന്യ പതിവ് ചെക്കപ്പുകൾ.'],
      bn: ['প্রতি মাসে ডাক্তারের চেকআপ', 'আপনার চুল কতটা উন্নত হয়েছে সেটা বিশদে দেখতে Cureskin-এর একজন ডাক্তারের কাছ থেকে বিনামূল্যে রুটিন চেকআপ করান।'],
      mr: ['डॉक्टरांकडून मोफत मासिक तपासणी', 'तुमच्या केसांची प्रगती तपशीलवार मोजण्यासाठी Cureskin डॉक्टरांमार्फत मोफत नियमित तपासणी.'],
      img: '/assets/images/dr-jisha.png',
    },
    {
      en: ['Hair-friendly Diet Plan',
        'Complimentary meal plans to make sure your daily diet contains food that boosts hair health. '],
      hi: ['बालों के अनुकूल डाइट प्लान',
        'कम्प्लीमेंटरी मील प्लान, ताकि आपके दैनिक आहार में वो भोजन शामिल हो जो स्वस्थ बालों को बढ़ावा देता है।'],
      te: ['హెయిర్ - ఫ్రెండ్లీ డైట్ ప్లాన్', 'మీ రోజువారీ ఆహారంలో జుట్టు యొక్క ఆరోగ్యాన్ని మెరుగుపరిచే ఆహారాన్ని కలిగి ఉండేలా కాంప్లిమెంటరీ మీల్ ప్లాన్‌లు.'],
      kn: ['ಕೂದಲಿಗೆ ಸರಿಹೊಂದುವಂತ ಡಯೆಟ್ ಪ್ಲಾನ್', 'ನಿಮ್ಮ ಪ್ರತಿದಿನದ ಆಹಾರದಲ್ಲಿ ಕೂದಲಿನ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸುವ ಆಹಾರವಿದೆ ಎಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಉಚಿತ ಮೀಲ್ ಪ್ಲಾನ್.'],
      ta: ['ஹேர்-ஃபிரெண்ட்லி டயட் பிளான்', 'தினசரி உணவில் உங்கள் முடியின் ஆரோக்கியத்தை அதிகரிக்கும் உணவு இருத்தலை உறுதி செய்யும் விரிவான உணவுத்திட்டங்கள். '],
      ml: ['തലമുടി-സൗഹൃദ ഡയറ്റ് പ്ലാൻ',
        'തലമുടിയുടെ ആരോഗ്യം ഉത്തേജിപ്പിക്കാനുള്ള ഭക്ഷണം നിങ്ങളുടെ പ്രതിദിന ഡയറ്റിൽ ഉണ്ടെന്ന് ഉറപ്പിക്കാൻ സൗജന്യ ഭക്ഷണ പ്ലാനുകൾ'],
      bn: ['চুলের সাথে সামঞ্জস্যপূর্ণ ডায়েট প্ল্যান', 'আপনার প্রতিদিনের ডায়েটে এমন খাবার রয়েছে কিনা যেটা চুলকে আরো বেশি স্বাস্থ্যকর করে তোলে সেটা নিশ্চিত করার জন্য কমপ্লিমেন্টারি খাবারের পরিকল্পনা।'],
      mr: ['केसांसाठी अनुकूल डाएट प्लॅन',
        'तुमच्या दैनंदिन आहारात केसांचे आरोग्य वाढवणारे अन्न आहे याची खात्री करण्यासाठी मोफत जेवणाचे प्लॅन.'],
      img: 'https://storage.googleapis.com/heallocontent/diet.png',
    },
    {
      en: ['Free home delivery', 'No shipping charges or hidden costs. Only an effective treatment kit delivered right to your doorstep.'],
      hi: ['ऑनलाइन भुगतान पर फ्री होम डिलीवरी', 'कोई शिपिंग शुल्क या छिपे क़ीमत नहीं। सिर्फ आपके दरवाजे तक पहुँचाया गया एक प्रभावी ट्रीटमेंट किट।'],
      te: ['ఆన్‌లైన్ చెల్లింపుపై ఫ్రీ హోమ్ డెలివరీ', 'షిప్పింగ్ ఛార్జీలు లేవు లేదా దాగి ఉన్న ఛార్జీలు లేవు. సమర్థవంతమైన ట్రీట్మెంట్ కిట్ మాత్రమే మీ ఇంటికి డెలివేరి చేయబడుతుంది.'],
      kn: ['ಆನ್‌ಲೈನ್ ಪೇಮೆಂಟ್ ಗೆ ಉಚಿತ ಹೋಮ್ ಡೆಲಿವರಿ', 'ಯಾವುದೇ ಶಿಪ್ಪಿಂಗ್ ಚಾರ್ಜ್ ಗಳು ಅಥವಾ ಯಾವುದೇ ಹಿಡನ್ ಚಾರ್ಜ್ ಗಳಿಲ್ಲ. ಪರಿಣಾಮಕಾರಿ ಚಿಕಿತ್ಸಾ ಕಿಟ್ ಅನ್ನು ಮಾತ್ರ ನಿಮ್ಮ ಮನೆ ಬಾಗಿಲಿಗೆ ತಲುಪಿಸಲಾಗುತ್ತದೆ.'],
      ta: ['ஆன்லைன் பேமெண்ட்களில் இலவச ஹோம் டெலிவரி', 'ஷிப்பிங் கட்டணங்கள் இல்லை அல்லது மறைமுக கட்டணங்கள் இல்லை. ஒரு நற்பயன் தரும்  சிகிச்சை கிட் மட்டுமே உங்கள் வீட்டிற்கு டெலிவரி செய்யப்படுகிறது. '],
      ml: ['ഓൺലൈൻ പേയ്‌മെന്റിന് സൗജന്യ ഹോം ഡെലിവറി', 'ഷിപ്പിംഗ് നിരക്കുകൾ അല്ലെങ്കിൽ മറയ്ക്കപ്പെട്ട നിരക്കുകൾ ഒന്നുമില്ല. ഫലപ്രദമായ ചികിത്സ കിറ്റ് നിങ്ങളുടെ വീട്ടുപടിക്കൽ ഡെലിവർ ചെയ്യുക മാത്രം.'],
      bn: ['অনলাইনে পেমেন্ট করলে বিনামূল্যে হোম ডেলিভারি পাবেন', 'কোনো শিপিং চার্জ নেই বা কোনো লুকানো খরচ নেই। শুধুমাত্র আপনার দরজায় ডেলিভার করা একটি কার্যকরী ট্রিটমেন্ট কিট।'],
      mr: ['ऑनलाईन पेमेंट करा, फ्री होम डिलिव्हरी मिळवा', 'कोणतेही शिपिंग चार्जेस किंवा छुपे खर्च नाहीत. तुमच्या दारापर्यंत फक्त एक प्रभावी ट्रिटमेंट किट डिलिव्हर केली जाते.'],
      img: 'https://cdn.cureskin.com/app/img/regimen-service-4.png',
    },
    {
      en: ['Personal Hair Advisor', 'A team of hair advisors made available to you via the app, to solve your doubts and support your healthy hair journey.'],
      hi: ['व्यक्तिगत हेयर एडवाइजर', 'हेयर एडवाइजर की एक टीम जो कि आपकी शंकाओं को हल करने और स्वस्थ बालों के सफर को समर्थन देने के लिए ऐप पर उपलब्ध रहती है।'],
      te: ['పర్సనల్ హెయిర్ అడ్వైసర్', 'మీ సందేహాలను పరిష్కరించడానికి ఇంకా మీ ఆరోగ్యకరమైన హెయిర్ జర్నీకి మద్దతు ఇవ్వడానికి యాప్ ద్వారా మీకు హెయిర్ అడ్వైసర్ టీమ్ మీకు అందుబాటులో ఉంటారు.'],
      kn: ['ಪರ್ಸನಲ್ ಹೇರ್ ಅಡವೈಸರ್', 'ಹೇರ್ ಅಡವೈಸರ್ ನ ತಂಡವು ನಿಮ್ಮ ಸಂದೇಹಗಳನ್ನು ಬಗೆಹರಿಸಲು ಮತ್ತು ನಿಮ್ಮ ಆರೋಗ್ಯಕರ ಹೇರ್ ಜರ್ನಿಯನ್ನು ಬೆಂಬಲಿಸಲು ಆಪ್ ಅಲ್ಲಿ ಲಭ್ಯವಿರುತ್ತಾರೆ.'],
      ta: ['பர்சனல் ஹேர் அட்வைசர்', 'உங்கள் சந்தேகங்களைத் தீர்த்து, ஆரோக்கியமான கூந்தலுக்கான உங்கள் பயணத்தில் உங்களுக்கு ஆதரவளிக்க, எங்கள் ஆப்‌ மூலம் ஹேர் அட்வைசர்கள் உங்களுடன் இணைந்திருப்பார்கள்'],
      ml: ['വ്യക്തിഗത തലമുടി ഉപദേഷ്ടാവ്', 'തലമുടി ഉപദേഷ്ടാക്കളുടെ ഒരു ടീം ആപ്പ് വഴി നിങ്ങളുടെ സംശയങ്ങൾ പരിഹരിക്കാനും ആരോഗ്യപൂർണമായ തലമുടിയിലേക്കുള്ള യാത്രയിൽ പിന്തുണയ്ക്കാനും ലഭ്യമാക്കിയിരിക്കുന്നു.'],
      bn: ['চুলের ব্যক্তিগত পরামর্শদাতা', 'আপনার সন্দেহ দূর করতে এবং আপনার স্বাস্থ্যকর চুলের যাত্রাকে সাপোর্ট করতে চুলের পরামর্শদাতাদের একটা টিম আপনার জন্য অ্যাপের মাধ্যমে উপলব্ধ আছেন।'],
      mr: ['पर्सनल हेअर ॲडव्हाईझर', 'तुमच्या शंका दूर करण्यासाठी आणि तुमच्या निरोगी केसांच्या प्रवासाला सपोर्ट करण्यासाठी हेअर ॲडव्हाईझर्सची टीम ॲपद्वारे उपलब्ध करून दिली जाते.'],
      img: 'https://storage.googleapis.com/heallocontent/support.png',
    },
  ],
};
