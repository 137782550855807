<div class="tw-overflow-hidden tw-mb-3 tw-rounded-t-[18px]" (click)="gotoProduct.emit(product)">
    <img class="tw-w-full tw-h-full" #image="" [src]="
      product?.rebrandedImageWithBackground?.[0] ||
      product?.productUnboxedImage?.[0] ||
      product?.images?.[0]
    " />
</div>
<h2 class="tw-flex-1 tw-font-body tw-text-200 tw-text-center tw-mx-4" (click)="gotoProduct.emit(product)">
    {{ product.title.substr(0, 25) }}
</h2>
<div class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-mb-3 tw-mx-4" (click)="gotoProduct.emit(product)">
    <p class="tw-font-body tw-font-bold tw-text-300 tw-line-through">
        ₹{{ product.mrp }}
    </p>
    <p class="tw-font-body tw-font-bold tw-text-300 tw-text-orange-500">
        ₹{{ product.price }}
    </p>
</div>
<div class="tw-border tw-rounded-full tw-border-green-400 tw-flex tw-items-center tw-justify-around tw-h-8 tw-mx-4" *ngIf="productInCart?.quantity">
    <div class="tw-flex-1 tw-flex tw-justify-center" (click)="reomveFromCart.emit(product)">
        <cs-icon class="tw-h-4 tw-w-4" [iconName]="'remove'"></cs-icon>
    </div>
    <span class="font-body font-bold tw-text-green-400 tw-text-200">{{
    productInCart.quantity
  }}</span>
    <div class="tw-flex-1 tw-flex tw-justify-center" (click)="addToCart.emit({ product, image })">
        <cs-icon class="tw-h-4 tw-w-4" [iconName]="'add'"></cs-icon>
    </div>
</div>
<div class="tw-flex tw-items-center tw-justify-center tw-text-center tw-gap-1 tw-h-8 tw-rounded-full tw-bg-green-400 tw-mx-4" *ngIf="!productInCart?.quantity" (click)="addToCart.emit({ product, image })">
    <span class="tw-font-body tw-font-bold tw-text-200 tw-text-white" i18n="@@addToCart">Add to Cart</span><cs-icon class="tw-h-5 tw-w-5 tw-text-white" [iconName]="'chevron_right'"></cs-icon>
</div>