<div class="tw-absolute tw-bottom-20 tw-w-full tw-shadow-[0_0_10px_1px_rgba(0,0,0,0.25)]">
    <div class="tw-flex tw-flex-row tw-items-center tw-p-3 tw-gap-2" style="
      backdrop-filter: blur(12px);
      background-color: rgba(255, 255, 255, 0.8) !important;
    ">
        <img class="tw-flex-none tw-w-[30px] tw-h-[30px]" src="/assets/images/cart.gif"/>
        <div class="tw-flex-1">
            <p class="tw-font-body"
               *ngIf="numberOfProductsInCart === 1"
               i18n="@@oneProductInCart"
               [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn' , 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
                1 Product in your cart
            </p>
            <p class="tw-font-body"
               *ngIf="numberOfProductsInCart > 1"
               i18n="@@multipleProductsInCart"
               [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn', 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
                {{numberOfProductsInCart}} Products in your cart
            </p>
            <p class="mainTwo tw-font-body tw-text-gray-500" i18n="@@placeOrderNow" [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn', 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
                Place Order Now
            </p>
            <!-- commented for now, will remove after release
            <p class="tw-font-body tw-text-gray-500"
               [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn', 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
                ₹{{totalSp}}
            </p> -->
        </div>
        <cs-button class="tw-flex-none"
                   [class]="'primary btn-xxxs btn-xxxs-padding'"
                   (callback)="goToCartNav()"
                   [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn', 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
            <cs-button-text i18n="@@buyNow"
                            style="padding: 0 10px">Buy Now</cs-button-text>
        </cs-button>
        <div class="tw-h-8 tw-w-8 tw-border-gray-200 tw-border tw-rounded-full tw-flex tw-items-center tw-justify-center tw-box-border"
             (click)="closeStrip.emit()">
            <img class="tw-w-4 tw-h-4 tw-m-0"
                 src="/assets/images/cancel-cross.png" />
        </div>
    </div>
</div>