import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'cureskin-cash-sheet',
  templateUrl: './cureskin-cash-sheet.html',
  styleUrls: [],
})
export class CureskinCashSheetComponent implements OnInit {
  /**
     * The constructor injects the data passed to the bottom sheet using Angular's dependency injection.
     * The 'new-cap' linting rule is disabled for this line because it doesn't apply in this case,
     * as the uppercase letter is part of the Angular-specific token MAT_BOTTOM_SHEET_DATA.
   */
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private eventLogger: EventLoggerService,
    private bottomSheetRef: MatBottomSheetRef,
    private conn: ConnectionService) { }

  ngOnInit(): void {
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'shop-cash-sheet' }));
  }

  async handleClick(): Promise<void> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'cureskin-cash-banner' }));
    if (this.data.url) {
      await this.conn.navigateToURL(this.data.url);
    }
    this.closeBottomSheet();
  }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }
}
