import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'doctor-call',
  templateUrl: './doctor-call.html',
  styleUrls: [],
})
export class DoctorCallComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {
  }
}
