import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { AovSaleComponent } from './aov-sale.component';

export function playerFactory(): LottiePlayer {
  return player;
}
@NgModule({
  imports: [
    CommonModule,
    CsIconModule,
    [LottieModule.forRoot({ player: playerFactory })],
  ],
  declarations: [AovSaleComponent],
  exports: [AovSaleComponent],
})
export class AovSaleModule {}
