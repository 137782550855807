import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading';
import { CAudioComponent } from './c-audio.component';

@NgModule({
  imports: [FormsModule, CommonModule, LoadingModule],
  declarations: [CAudioComponent],
  exports: [CAudioComponent],
})
export class CAudioModule {}
