<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<loading class="pos-abs vh-center" *ngIf="loading"></loading>
<ng-container *ngIf="!loading && !newFollowUpUIExperiment">
  <ng-container *ngIf="reports?.length && !loading">
    <section class="tw-bg-teal-200 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-px-6 tw-pb-6 tw-pt-24">
      <h1 class="tw-font-body tw-font-bold tw-text-700 tw-mb-3" i18n="@@checkupsNew">Checkups</h1>
      <p class="tw-font-body tw-text-300" i18n="@@cureskinTreatmentInlcudes">
        Cureskin treatment kit includes monthly checkups, where we review your
        progress
      </p>
      <div class="followup-date tw-pt-4" *ngIf="followUp.date">
        <h2 class="tw-font-body tw-font-bold tw-text-500" i18n="@@yourNextCheckupIsDueOn">
          Your next monthly checkup is due on<span>
          {{followUp.date | date: 'd MMM yyyy'}}</span>
        </h2>
      </div>
    </section>
    <section class="tw-flex tw-col tw-px-6 tw-gap-4 tw-pt-5" *ngFor="let report of reports">
      <report-card class="tw-flex-1" [data]="report"></report-card>
    </section>
  </ng-container>
  <ng-container *ngIf="!loading && !reports?.length">
    <section class="tw-bg-teal-200 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-px-6 tw-pb-6 tw-pt-24">
      <h1 class="tw-font-body tw-font-bold tw-text-700 tw-mb-3" i18n="@@neverMissYourDoctorCheckup">
        NEVER MISS YOUR DOCTOR CHECKUP
      </h1>
    </section>
    <section class="tw-flex tw-flex-col tw-pl-6 tw-py-8">
      <h2 class="tw-font-body tw-font-bold tw-text-500 tw-mb-3 tw-mr-[35%]" i18n="@@whatWillHappenInCheckup">
        What will happen in doctor checkup?
      </h2>
      <div class="tw-flex tw-items-center tw-gap-3">
        <div class="tw-font-body tw-text-300" i18n="@@ourDoctorsWillMonitorYour">
          Our doctors will monitor your skin/hair progress and will share your
          latest skin report. Your doctor checkup report will include a voice note
          from the doctor, a comparison report of your skin/hair progress and next
          steps to the treatment. 
        </div>
        <img class="tw-max-h-48 tw-mr-[-10%]" src="https://storage.googleapis.com/heallocontent/app/img/hand_with_tab.png" alt="" />
      </div>
    </section>
    <section class="tw-px-6 tw-pt-8 tw-bg-green-100">
      <h2 class="tw-font-body tw-font-bold tw-text-700 tw-mb-8" i18n="@@HowItWorks">
        How it works?
      </h2>
      <div class="tw-flex tw-flex-col tw-items-start tw-gap-6 tw-mb-5">
        <div class="tw-flex tw-items-start tw-gap-5">
          <div class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-uppercase tw-bg-green-300 tw-rounded-full tw-px-2 tw-py-1">
            Step 1
          </div>
          <div class="tw-flex-1 tw-font-body tw-text-300" i18n="@@take23PhotoOfYour">
            Take 2-3 photos of your face/scalp, and answer a few questions.
          </div>
        </div>
        <div class="tw-flex tw-items-start tw-gap-5">
          <div class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-uppercase tw-bg-green-300 tw-rounded-full tw-px-2 tw-py-1">
            Step 2
          </div>
          <div class="tw-flex-1 tw-font-body tw-text-300" i18n="@@ourDoctorWillReviewYourImprovement">
            Our doctor will review your improvement (it will take 2 days).
          </div>
        </div>
        <div class="tw-flex tw-items-start tw-gap-5">
          <div class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-uppercase tw-bg-green-300 tw-rounded-full tw-px-2 tw-py-1">
            Step 3
          </div>
          <div class="tw-flex-1 tw-font-body tw-text-300" i18n="@@getACheckupReportAndAVoiceMessage">
            Get a checkup report and a voice message from the doctor on your
            progress.
          </div>
        </div>
        <div class="tw-flex tw-items-start tw-gap-5">
          <div class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-uppercase tw-bg-green-300 tw-rounded-full tw-px-2 tw-py-1">
            Step 4
          </div>
          <div class="tw-flex-1 tw-font-body tw-text-300" i18n="@@followTheChnagesAdvisedBy">
            Follow the changes advised by your doctor and get faster results.
          </div>
        </div>
      </div>
      <div class="tw-rounded-4 tw-px-5 tw-py-4 tw-bg-green-300 tw-relative tw-flex tw-flex-col tw-items-start tw-gap-3 tw-justify-center tw-mb-[-20%] tw-min-h-[120px]">
        <ng-container *ngIf="!followUp.date">
          <h2 class="tw-font-body tw-font-bold tw-text-500" i18n="@@youDontHaveAnyCheckUpsDue">
            You don't have any checkup scheduled right now
          </h2>
        </ng-container><ng-container *ngIf="followUp.date">
        <h2 class="tw-font-body tw-font-bold tw-text-500" i18n="@@yourNextCheckupIsDueOn">
          Your next monthly checkup is due on<span>
            {{followUp.date | date: 'd MMM yyyy'}}</span>
        </h2>
        <h2 class="tw-font-body tw-text-300" *ngIf="isFollowUpReady" i18n="@@toStartYourCheckup">
          to start your checkup
        </h2>
        <cs-button *ngIf="isFollowUpReady" [class]="'primary btn-xxxs btn-xxxs-padding'" (click)="startFollowUp()"><cs-button-text class="tw-px-4 tw-flex tw-items-center"><span i18n="@@clickHere">Click Here</span><cs-icon class="tw-h-4 tw-w-4" [iconName]='"chevron_right"'></cs-icon></cs-button-text></cs-button>
      </ng-container>
      </div>
    </section>
    <section class="tw-bg-gray-100 tw-px-8 tw-pb-10 tw-pt-24 tw-flex tw-gap-9">
      <div class="tw-flex-1">
        <h2 class="tw-font-body tw-font-bold tw-text-700 tw-mb-2">6 Lac+</h2>
        <p class="tw-font-body tw-text-300" i18n="@@peopleTookAdvantage">
          people took advantage of this service
        </p>
      </div>
      <div class="tw-flex-none tw-border-l tw-border-gray-300"></div>
      <div class="tw-flex-1">
        <h2 class="tw-font-body tw-font-bold tw-text-700 tw-mb-2">95%</h2>
        <p class="tw-font-body tw-text-300" i18n="@@sawBetterResults">
          saw better results with regular checkup
        </p>
      </div>
    </section>
    <section class="tw-px-6 tw-py-9">
      <div class="tw-flex-none tw-self-center">
        <img class="tw-max-w-56 tw-max-h-28 tw-mx-auto tw-mb-4 tw-rounded-[100px]" src="https://storage.googleapis.com/heallocontent/app/img/checkup_pill_2.png" alt="" />
        <p class="tw-font-body tw-text-600 tw-text-center" i18n="@@seeImprovementIn3Checkups">
          See improvement in <b>3 checkups</b>
        </p>
      </div>
    </section>
    <section class="tw-bg-gray-100 tw-pt-8 tw-pb-10">
      <h2 class="tw-font-body tw-font-bold tw-text-700 tw-text-center tw-mb-4" i18n="@@whatCustomersSay">What customers say</h2>
      <div class="tw-pl-4 tw-flex tw-gap-4 tw-overflow-x-auto">
        <div class="tw-bg-purple-100 tw-flex tw-flex-col tw-px-6 tw-pb-6 tw-pt-8 tw-rounded-4 tw-min-w-[70vw]">
          <img class="tw-flex-none tw-mb-4 tw-h-16 tw-w-16 tw-rounded-full" src="https://storage.googleapis.com/heallocontent/app/img/testimonial_suman.jpg" alt="" />
          <p class="tw-flex-none tw-font-body tw-text-300 tw-mb-3" i18n="@@testimonialTwoYearsAgoIStartedTreatment">
            2 years ago, I started using the Cureskin treatment kit and the
            results are WOW! The regular checkups with doctors helped me get my
            clear and smooth skin back.
          </p>
          <p class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@sumanBanglore">
            Suman, Bangalore
          </p>
          <div class="tw-flex tw-items-center">
            <cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon>
          </div>
        </div>
        <div class="tw-bg-blue-100 tw-flex tw-flex-col tw-px-6 tw-pb-6 tw-pt-8 tw-rounded-4 tw-min-w-[70vw]">
          <img class="tw-flex-none tw-mb-4 tw-h-16 tw-w-16 tw-rounded-full" src="https://storage.googleapis.com/heallocontent/app/img/testimonial_kartik.jpg" alt="" />
          <p class="tw-flex-none tw-font-body tw-text-300 tw-mb-3" i18n="@@testimonialFreeHairAnalysisIdentifiedTreatment">
            Free hair analysis identified the root cause of my hair fall. Whereas,
            the doctor-approved diet, monthly checkups, and hair Treatment kit
            helped me control it completely.
          </p>
          <p class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@kartikWarangal">
            Kartik, Warangal
          </p>
          <div class="tw-flex tw-items-center">
            <cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon>
          </div>
        </div>
        <div class="tw-bg-teal-100 tw-flex tw-flex-col tw-px-6 tw-pb-6 tw-pt-8 tw-rounded-4 tw-min-w-[70vw]">
          <img class="tw-flex-none tw-mb-4 tw-h-16 tw-w-16 tw-rounded-full" src="https://storage.googleapis.com/heallocontent/app/img/testimonial_aaditya.jpg" alt="" />
          <p class="tw-flex-none tw-font-body tw-text-300 tw-mb-3" i18n="@@testimonialMyPigmentationStartedTreatment">
            My pigmentation started reducing after a few weeks of using the
            Cureskin treatment kit. 5 stars to the doctor's support and their
            service of free checkups.
          </p>
          <p class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@adityaKanpur">
            Aditya, Kanpur
          </p>
          <div class="tw-flex tw-items-center">
            <cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gray-300" [iconName]='"star"'></cs-icon>
          </div>
        </div>
        <div class="tw-bg-green-100 tw-flex tw-flex-col tw-px-6 tw-pb-6 tw-pt-8 tw-rounded-4 tw-min-w-[70vw]">
          <img class="tw-flex-none tw-mb-4 tw-h-16 tw-w-16 tw-rounded-full" src="https://storage.googleapis.com/heallocontent/app/img/testimonial_lata.jpg" alt="" />
          <p class="tw-flex-none tw-font-body tw-text-300 tw-mb-3" i18n="@@testimonialSoGratefulThat">
            So grateful that I came across Cureskin during my pregnancy. Loved the
            support and guidance from the doctor's team throughout the pregnancy.
          </p>
          <p class="tw-flex-none tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@lataMurshidabad">
            Lata, Murshidabad
          </p>
          <div class="tw-flex tw-items-center">
            <cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gold-400" [iconName]='"star"'></cs-icon><cs-icon class="tw-h-5 tw-w-5 tw-text-gray-300" [iconName]='"star"'></cs-icon>
          </div>
        </div>
      </div>
    </section>
  </ng-container>
</ng-container>

<!--new checkup UI-->
<ng-container *ngIf="!loading && newFollowUpUIExperiment">

  <section class="tw-bg-teal-200 tw-pb-6 tw-pt-20">
    <h1 i18n="@@myCheckups" class="tw-font-body tw-font-bold tw-text-700 tw-px-6 tw-pt-2 tw-pb-1">
      My Check-ups
    </h1>
    <p class="tw-text-teal-700 tw-leading-4 tw-mb-4 tw-px-6 tw-font-body">
      <span *ngIf="isSkinSpecialist && isHairSpecialist" i18n="@@skinAndHair" class="tw-text-300 tw-font-medium">Skin and Hair</span>
      <span *ngIf="isSkinSpecialist && !isHairSpecialist" i18n="@@skin" class="tw-text-300 tw-font-medium">Skin</span>
      <span *ngIf="isHairSpecialist && !isSkinSpecialist" i18n="@@hair" class="tw-text-300 tw-font-medium">Hair</span>
      <span>&nbsp;</span>
      <span i18n="@@specialist">Specialist</span>
      <span>:</span>
      <span class="tw-font-bold"> {{doctorName}} </span>
    </p>
    <swiper id="followUp-swiper" class="tw-max-w-full tw-overflow-visible tw-px-4 tw-z-0 tw-font-body tw-text-300" [config]="followUpCarouselConfig">
      <ng-container *ngFor="let followUp of userAllFollowUps; let i = index">
        <!--  notify me card-->
        <ng-container *ngIf="showNotifyMeCard(followUp); else displayFollowUpActiveCard">
          <div *swiperSlide class="tw-w-32 tw-h-40 tw-bg-gray-50 tw-rounded-4 tw-shadow-z5">
            <div class=" tw-pt-6 tw-pb-4 tw-px-3 tw-flex tw-flex-col tw-gap-3 tw-justify-center tw-items-center">
              <h3 class="tw-text-gray-800 tw-font-bold">
                <span>{{i + 1}}</span>
                <span class="tw-align-super tw-text-100 tw-mr-0.5">{{ (i + 1).toString() | dateSuffix: true }}</span>
                <span>&nbsp;</span>
                <span i18n="@@checkup" class="tw-capitalize">Check-up</span>
              </h3>
              <h1 class="tw-text-center  tw-tracking-wider tw-leading-none tw-pt-1">
                <span class="tw-leading-none tw-uppercase tw-text-300 tw-block">
                  {{followUp.nextFollowUpDate['iso'] | date: 'MMM'}}
                </span>
                <span class="tw-text-900 tw-font-bold tw-leading-none tw-block">
                {{followUp.nextFollowUpDate['iso'] | date: 'dd'}}
              </span>
              </h1>
              <span i18n="@@notifyMe" class=" tw-text-teal-600 tw-font-bold tw-text-center" (click)="toggleNotifyMePopup()">Notify me</span>
            </div>
          </div>
        </ng-container>
        <!--follow up active card -->
        <ng-template #displayFollowUpActiveCard>
          <ng-container *ngIf="showFollowUpActiveCard(followUp); else displayReportInProgressCard">
            <div *swiperSlide class="tw-w-32 tw-h-40 tw-bg-gray-50 tw-rounded-4 tw-relative tw-shadow-z5">
              <div class=" tw-pt-6 tw-pb-4 tw-px-3 tw-flex tw-flex-col tw-gap-3 tw-justify-center tw-items-center">
                <h3 class="tw-text-gray-800 tw-font-bold">
                  <span>{{i + 1}}</span>
                  <span class="tw-align-super tw-text-100 tw-mr-0.5">{{ (i + 1).toString() | dateSuffix: true }}</span>
                  <span i18n="@@checkup" class="tw-capitalize">Check-up</span>
                </h3>
                <img alt="doctor profile" src="{{doctorImage}}" class="tw-aspect-square tw-w-14 tw-bg-gray-200 tw-rounded-full">
                <span i18n="@@takeNow" class=" tw-text-teal-600 tw-font-bold tw-text-center" (click)="startFollowUpOfId(followUp)">Take Now</span>
                <div class="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-flex tw-justify-center tw-text-100 tw-font-bold tw-text-gray-50">
                  <span i18n="@@active" class="tw-bg-orange-400 tw-rounded-b-1 tw-w-16 tw-h-5 tw-capitalize tw-flex tw-justify-center tw-items-center">active</span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <!--  report in process card-->
        <ng-template #displayReportInProgressCard>
          <ng-container *ngIf="showReportInProcessCard(followUp); else displayWaitingForImageCard">
            <div *swiperSlide class="tw-w-32 tw-h-40 tw-bg-gray-50 tw-rounded-4 tw-shadow-z5">
              <div class=" tw-pt-6 tw-pb-4 tw-px-3 tw-flex tw-flex-col tw-gap-3 tw-justify-center tw-items-center">
                <h3 class="tw-text-gray-800 tw-font-bold">
                  <span>{{i + 1}}</span>
                  <span class="tw-align-super tw-text-100 tw-mr-0.5">{{ (i + 1).toString() | dateSuffix: true }}</span>
                  <span i18n="@@checkup" class="tw-capitalize">Check-up</span>
                </h3>
                <img alt="sand-watch-loader" src="/assets/images/sand-watch-loader.gif" class="tw-aspect-square tw-w-14 tw-bg-gray-200 tw-rounded-full tw-object-cover">
                <span i18n="@@inProcess" class=" tw-text-teal-600 tw-font-bold tw-capitalize tw-text-center" (click)="toggleProcessPopup()">In process</span>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <!--Waiting for image card-->
        <ng-template #displayWaitingForImageCard>
          <ng-container *ngIf="showFollowUpWaitingForImageCard(followUp)" >
            <div *swiperSlide class="tw-w-32 tw-h-40 tw-bg-gray-50 tw-rounded-4 tw-relative tw-shadow-z5">
              <div class=" tw-pt-6 tw-pb-4 tw-px-3 tw-flex tw-flex-col tw-gap-3 tw-justify-center tw-items-center">
                <h3 class="tw-text-gray-800 tw-font-bold">
                  <span>{{i + 1}}</span>
                  <span class="tw-align-super tw-text-100 tw-mr-0.5">{{ (i + 1).toString() | dateSuffix: true }}</span>
                  <span i18n="@@checkup" class="tw-capitalize">Check-up</span>
                </h3>
                <img alt="doctor profile" src="{{doctorImage}}" class="tw-aspect-square tw-w-14 tw-bg-gray-200 tw-rounded-full tw-object-cover">
                <span i18n="@@upload" class=" tw-text-teal-600 tw-font-bold " (click)="startFollowUpOfId(followUp)">Upload</span>

                <div class="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-flex tw-justify-center tw-text-100 tw-font-bold tw-text-gray-50">
                  <span i18n="@@waitingForImage" class="tw-bg-orange-400 tw-rounded-b-1 tw-w-[103px] tw-h-5 tw-flex tw-justify-center tw-items-center tw-text-center">Waiting for image</span>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <!--  Report ready Card -->
        <ng-container *ngIf="showReportReadyCard(followUp)">
          <div *swiperSlide class="tw-w-32 tw-h-40 tw-bg-teal-100 tw-rounded-4">
            <div class=" tw-pt-6 tw-pb-4 tw-px-3 tw-flex tw-flex-col tw-gap-5 tw-justify-center tw-items-center">
              <h3 class="tw-font-bold">
                {{followUp.createdAt | date: 'dd MMM yy'}}
              </h3>
              <img alt="checkup-report-tick-icon" src="/assets/icons/checkup-report-tick-icon.svg" class="tw-w-10 tw-aspect-square">
              <span i18n="@@viewReport" class=" tw-text-teal-600 tw-font-bold tw-text-center" (click)="openReportOfId(followUp.followUpReportStatus?.FACE ?? followUp.followUpReportStatus?.HAIR)">View Report</span>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!--  Lock Card -->
      <ng-container *ngFor="let _ of (userAllFollowUps?.length === 1 ? [1, 2] : [1])">
        <div *swiperSlide class="tw-w-32 tw-h-40 tw-bg-teal-100 tw-rounded-4 tw-shadow-z5">
          <div class=" tw-pt-6 tw-pb-4 tw-px-3 tw-flex tw-flex-col tw-gap-3 tw-justify-center tw-items-center">
            <h3 i18n="@@nextCheckup" class="tw-text-gray-500 tw-text-center">Next Checkup</h3>
            <img alt="checkup-lock-icon" src="/assets/icons/checkup-lock-icon.svg" class="tw-w-9 tw-mt-1">
            <span class=" tw-text-teal-600 tw-font-bold tw-invisible"></span>
          </div>
        </div>
      </ng-container>
    </swiper>
  </section>
  <!-- how to take checkup section-->
  <section *ngIf="!userAllReportsWithCheckUpNumber?.length" class="tw-p-6 tw-pb-8 tw-font-body">
    <h2 i18n="@@completeYourCheckupsInThreeEasySteps" class="tw-text-400 tw-pb-4 tw-mb-4 tw-border-b tw-border-b-gray-100">How to take check-up ?</h2>
    <div class="tw-text-400 tw-flex tw-flex-col tw-gap-2">
      <div class="tw-flex tw-gap-4">
        <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-flex-shrink-0">
          <img alt="checkup-step-1-icon" src="/assets/icons/checkup-step-1-icon.svg" class="tw-w-14 tw-aspect-square"/>
          <div class="tw-bg-teal-400 tw-h-8 tw-w-0.5"></div>
        </div>
        <div>
          <span i18n="@@stepOne" class="tw-text-gray-800 tw-text-200 ">STEP 1</span>
          <p i18n="@@answerFewQuestions" class="tw-text-400 tw-font-bold tw-tracking-normal">Answer a few questions</p>
        </div>
      </div>
      <div class="tw-flex tw-gap-4">
        <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-flex-shrink-0">
          <img alt="checkup-step-2-icon" src="/assets/icons/checkup-step-2-icon.svg" class="tw-w-14 tw-aspect-square"/>
          <div class="tw-bg-teal-400 tw-h-8 tw-w-0.5"></div>
        </div>
        <div>
          <span i18n="@@stepTwo" class="tw-text-gray-800 tw-text-200 ">STEP 2</span>
          <p i18n="@@shareYourPhotosWithYourDoctor" class="tw-text-400 tw-font-bold tw-tracking-normal">Share your photos with <br/> your doctor</p>
        </div>
      </div>
      <div class="tw-flex tw-gap-4">
        <div class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-flex-shrink-0">
          <img alt="checkup-step-3-icon" src="/assets/icons/checkup-step-3-icon.svg" class="tw-w-14 tw-aspect-square"/>
        </div>
        <div>
          <span i18n="@@stepThree" class="tw-text-gray-800 tw-text-200 ">STEP 3</span>
          <p i18n="@@getYourTreatmentProgressReport" class="tw-text-400 tw-font-bold tw-tracking-normal">Get your treatment <br/> progress report</p>
        </div>
      </div>
    </div>
  </section>
  <!-- Better results -->
  <section *ngIf="!userAllReportsWithCheckUpNumber?.length" class="tw-bg-gray-100 tw-p-4 tw-font-body">
    <div class="tw-bg-gray-50 tw-rounded-2 tw-p-4 tw-py-5 tw-flex tw-justify-between">
      <div>
        <h1 i18n="@@ninetyPercentUsers" class="tw-text-teal-400 tw-text-700 tw-font-bold">90% users</h1>
        <p i18n="@@sawBetterResultsWithRegularCheckups" class="tw-text-300 tw-text-gray-400 tw-min-w-[50%]">Saw better results<br/>with regular checkups</p>
      </div>
      <div class="tw-flex-shrink-0" [ngClass]="(user.get('languagePreference') === 'te' || user.get('languagePreference') === 'ta') ? 'tw-min-w-[40%]' : ''">
        <img alt="checkup-user-result" src="/assets/images/checkup-user-results.svg">
      </div>
    </div>
  </section>
  <!-- Checkup report list -->
  <section *ngIf="userAllReportsWithCheckUpNumber?.length" class="tw-p-4 tw-pt-5 tw-font-body">
    <h2 i18n="@@checkupReports" class="tw-text-400 tw-font-bold tw-font-body tw-mb-5">Checkup Reports</h2>
    <div class="tw-overflow-y-clip">
      <div class="tw-text-300 tw-flex tw-flex-col" *ngFor="let followUpReport of userAllReportsWithCheckUpNumber; let i = index">
        <report-card class="tw-flex-1" [data]="followUpReport" [index]="i" [latestReportIds]="latestReportIds" [userAllReportsWithCheckUpNumber]="userAllReportsWithCheckUpNumber"></report-card>
      </div>
    </div>
  </section>
  <!-- In Process Pop up -->
  <ng-container *ngIf="isProcessPopUpOpen">
    <div class="tw-fixed tw-inset-0 tw-z-10 tw-animate-fade_in backdrop-blur-sm" (click)="toggleProcessPopup()"></div>
    <div class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-bg-white tw-z-20 tw-font-body tw-rounded-t-3xl tw-animate-slide_in_bottom">
      <div  class="tw-relative tw-p-5 tw-pt-14 tw-pb-10 tw-text-center">
        <h1 i18n="@@yourCheckupIsInProcessInProcess" class="tw-font-bold tw-text-700">Your checkup is <br/> in process.</h1>
        <p i18n="@@yourReportWillBeReadyWithinFortyEightHours" class="tw-text-[22px] tw-font-normal tw-mb-5 tw-text-gray-800 tw-font-body">your report will be ready <br/> within 48 hrs</p>
        <button i18n="@@okay" (click)="toggleProcessPopup()" class="tw-bg-teal-600 tw-text-white tw-text-300 tw-h-10 tw-mx-auto tw-text-center tw-w-full tw-font-bold tw-capitalize">okay</button>
        <div (click)="toggleProcessPopup()" class="tw-absolute tw-right-5 tw-top-5 tw-w-9">
          <img alt="isProcessPopUp CloseButton" src="/assets/icons/popupCloseButton.svg"/>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Notify me Pop up -->
  <ng-container *ngIf="isNotifyMePopUpOpen">
    <div class="tw-fixed tw-inset-0 tw-z-10 tw-animate-fade_in backdrop-blur-sm" (click)="toggleNotifyMePopup()"></div>
    <div class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 tw-bg-white tw-z-20 tw-font-body tw-rounded-t-3xl tw-animate-slide_in_bottom">
      <div  class="tw-relative tw-p-5 tw-pt-20 tw-pb-10 tw-text-center">
        <h1 i18n="@@notifyMePopupThankYou" class="tw-font-bold tw-text-700">Thank you.</h1>
        <p i18n="@@weWillNotifyYouOnceYourCheckupIsNear" class="tw-text-[22px] tw-font-normal tw-mb-5 tw-text-gray-800 tw-font-body">We will notify you once <br/> your checkup is near</p>
        <button i18n="@@okay" (click)="toggleNotifyMePopup()" class="tw-bg-teal-600 tw-text-white tw-text-300 tw-h-10 tw-mx-auto tw-text-center tw-w-full tw-font-bold tw-capitalize">okay</button>
        <div (click)="toggleNotifyMePopup()" class="tw-absolute tw-right-5 tw-top-5 tw-w-9">
          <img alt="isNotifyMePopUp CloseButton" src="/assets/icons/popupCloseButton.svg"/>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
