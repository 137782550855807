<div class="tw-relative" *ngIf="chat" (click)="openArticle()">
  <div class="tw-overflow-hidden tw-h-52" [ngClass]="{'tw-bg-[url(https://cdn.cureskin.com/app/img/regimen-chat-bg.jpg)]': regimenArticle }">
  <img class="tw-w-auto tw-block tw-h-52 tw-m-auto" #image="" [src]="imageSrc" (load)="loading = false" />
  </div>
  <div class="tw-mt-4 tw-px-4 tw-py-0">
  {{chat.get("Message").substring(0, 70) + (chat.get("Message").length > 70 ?
  ".." : "")}}
  </div>
  <div class="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-pt-[3px] tw-px-4 tw-text-200 tw-text-gray-400">
  {{((chat.get("Metadata").split(";")[2]).split("content:")[1])}}
  </div>
</div>
<div class="tw-w-full tw-float-right tw-pt-[10px]" *ngIf='chat && chat.get("Inputs").length'>
  <cs-button [class]="'primary btn-xs tw-float-right'" (callback)="openAction()"><cs-button-text>{{ chat.get('Inputs')[0].text }}</cs-button-text></cs-button>
</div>