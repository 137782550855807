/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConnectionService } from '@services/connection-service';
import * as Types from '@store/modals';
import { fromActions } from '../actions';
import { fromSelectors } from '../selectors';
import { AppConfig } from '../../app.config';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private conn: ConnectionService,
    private store: Store,
    private appConfig: AppConfig) {}

  getHomePageBlogsRequestBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.HomePageBlogsBegin),
      withLatestFrom(this.store.select(fromSelectors.selectHomePageState)),
      exhaustMap(([{ payload }, homePageData]: any[]): any => {
        if (homePageData.blogs.length) return of(homePageData.blogs);
        return this.conn.getUserBlogs(payload);
      }),
      map((blogs: any[]): any => fromActions.HomePageUpdate({ blogs: JSON.parse(JSON.stringify(blogs)) })),
      catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
        return caught;
      }),
    );
  });

  getHomePageAddonRequestBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.HomePageAddonBegin),
      withLatestFrom(this.store.select(fromSelectors.selectHomePageAddons)),
      exhaustMap(([{ payload }, addonProducts]: any[]): any => {
        this.store.dispatch(fromActions.HomePageUpdate({ isAddonProductsLoading: true }));
        return this.conn.findProductsForUser(payload);
      }),
      map((addon: any[]): any => {
        return fromActions.HomePageUpdate({ isAddonProductsLoading: false,
          addonProducts: JSON.parse(JSON.stringify(addon)) });
      }),
      catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
        return caught;
      }),
    );
  });

  getHomePageReorderProductsRequestBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.HomePageReorderBegin),
      withLatestFrom(this.store.select(fromSelectors.selectHomePageReorderProducts)),
      exhaustMap(([{ payload }, reorderProducts]: any[]): any => {
        this.store.dispatch(fromActions.HomePageUpdate({ isReorderProductsLoading: true }));
        return this.conn.findProductsForUser(payload);
      }),
      map((products: any[]): any => {
        return fromActions.HomePageUpdate({ isReorderProductsLoading: false,
          reorderProducts: JSON.parse(JSON.stringify(products)) });
      }),
      catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
        return caught;
      }),
    );
  });

  /**
   * Fetches the support category + question and create a map
   * questionsByCategory -> { categoryId: [question, question] };
   * map with key as categoryId and values as array of question belonging to that category.
   */
  getSupportCategoryFetchBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.SupportCategoryFetchBegin),
      withLatestFrom(this.store.select(fromSelectors.selectSupportCategory),
        this.store.select(fromSelectors.selectSupportCategory),
        this.store.select(fromSelectors.selectQueryParams)),
      exhaustMap(async ([_, savedCategories, savedQuestions, { userId }]: any): Promise<any> => {
        let questions: any[] = savedQuestions;
        this.store.dispatch(fromActions.SupportStateUpdate({ inProcess: true }));
        if (!savedCategories.length) {
          const category: any[] = await this.conn.fetchSupportCategory();
          this.store.dispatch(fromActions.SupportStateUpdate({ category: JSON.parse(JSON.stringify(category)) }));
          const where = { category, active: true };
          questions = await this.conn.fetchSupportQuestions({ where }, { userId });
        }
        const questionsByCategory: any = {};
        questions.forEach((each: any): void => {
          const questionArr: any[] = questionsByCategory[each.get('category').id] ?? [];
          questionArr.push(each);
          questionsByCategory[each.get('category').id] = questionArr;
        });
        return fromActions.SupportStateUpdate({ questions: JSON.parse(JSON.stringify(questions)),
          questionsByCategory: JSON.parse(JSON.stringify(questionsByCategory)),
          inProcess: false });
      }),
      catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
        this.store.dispatch(fromActions.SupportStateUpdate({ inProcess: false }));
        return caught;
      }),
    );
  });

  /** reFetch - is set to true by default & only if createTicket api is called. Else we reuse fetched tickets only throughout lifecycle. */
  getRecentQueriesFetchBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.SupportRecentTicketFetchBegin),
      withLatestFrom(this.store.select(fromSelectors.selectQueryParams), this.store.select(fromSelectors.selectRecentTicketRefetchFlag)),
      exhaustMap(async ([{ status }]: any): Promise<any> => {
        this.store.dispatch(fromActions.SupportRecentTicketUpdate({ inProcess: true }));

        const user = await this.conn.getActingUser();
        const data: Partial<Types.RecentSupportTicketI> = { inProcess: false, closedTickets: [], openTickets: [], reFetch: false };
        const payload = { where: { user, status } };
        if (!status) delete payload.where.status;
        const tickets: any[] = JSON.parse(JSON.stringify(await this.conn.fetchSupportTickets(payload)));
        tickets.forEach((ticket_: any): void => {
          const ticket = ticket_;
          if (ticket.cacheSupportChat
            && ticket.attended
            && new Date(ticket.lastVisited?.iso) < new Date(ticket?.latestResponseTimeFromInternalUser.iso)) ticket.hasUnreadMessage = true;
          if (ticket.status === this.appConfig.Shared.Ticket.status.Completed) data.closedTickets.push(ticket);
          else data.openTickets.push(ticket);
        });
        return fromActions.SupportRecentTicketUpdate(data);
      }),
      catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
        this.store.dispatch(fromActions.SupportRecentTicketUpdate({ inProcess: false }));
        return caught;
      }),
    );
  });
}
