import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { PopUpModalType } from '@components/pop-up-modal/pop-up-modal.type';
import { EventLoggerService } from '@services/event-logger-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { WindowRefService } from '@services/window-ref-service';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'user-order-view',
  templateUrl: './user-order-view.html',
  styleUrls: ['./user-order-view.scss'],
})
export class UserOrderViewComponent {
  REQUEST_CODE: any = { REGIMEN_ORDER_CANCEL: 1, ORDER_CANCEL: 2, ORDER_CANCEL_REASON: 3 };
  approvedByDoctor: string;
  allLocalStages: Array<any>;
  cancelOrderAllowed: boolean;
  changeAddressAllowed: boolean;
  order: any;
  orderStages: Array<any> = [];
  user: any;
  doctor: any;
  productInfo: Array<any> = [];
  expectedDeliveryDate: Date;
  popUpModal: PopUpModalType.ConfigParams = { open: false };
  regimenApproved: boolean = false;
  products: any = {};
  orderSp: any = 0;
  orderMRP: any = 0;

  orderPaymentPendingStages: any[] = [...this.appConfig.Shared.Order.Stage.orderPaymentPendingStages];
  orderPlacedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderPlacedStages];
  orderPackedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderPackedStages];
  orderDispatchedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderDispatchedStages];
  orderDeliveryFailedStages: any[] = [...this.appConfig.Shared.Order.Stage.orderDeliveryFailedStages];
  orderOutForDeliveryStages: any[] = [...this.appConfig.Shared.Order.Stage.orderOutForDeliveryStages];
  orderDeliveredStages: any[] = [...this.appConfig.Shared.Order.Stage.orderDeliveredStages];
  orderCanceledStages: any[] = [...this.appConfig.Shared.Order.Stage.orderCanceledStages];
  isiOSDevice: boolean = false;
  regimens: any;

  constructor(
    private broadcast: BroadcastService,
    private route: ActivatedRoute,
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private eventLogger: EventLoggerService,
    private router: Router,
    private appWebBridge: AppWebBridgeService,
    private windowRef: WindowRefService,
  ) {
  }

  async ngOnInit(): Promise<any> {
    this.user = await this.conn.getActingUser();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'user-order-view' }));
    if (this.user?.get('allocatedDoctor')) this.doctor = this.user?.get('allocatedDoctor');
    this.order = await this.conn.findOrderWithSignedURL(this.route.snapshot.params.id);
    if (this.order.get('paymentType') === this.appConfig.Shared.Order.PaymentType.COD) {
      this.order.get('serviceInfo').forEach((service: any): any => {
        if ((service.type === this.appConfig.Shared.Order.PaymentType.COD) && (
          service.SP === this.appConfig.Shared.Payment.Charges.CCOD_PROCESSING_AMOUNT)) {
          this.order.set('amount', this.order.get('amount') - service.SP);
        }
      });
    }
    this.orderSp = this.order.get('amount') - (this.order.get('paidAmount') || 0);
    if (!this.order.get('regimen')) {
      this.orderMRP = this.order.get('actualPrice');
    }
    if (this.order.get('regimen')) {
      const regimen = JSON.parse(JSON.stringify(this.order.get('regimen')));
      this.orderMRP = Math.round(regimen.fixedPriceMRP);
      regimen.totalMRP = Math.round(regimen.fixedPriceMRP);
      regimen.totalSP = regimen?.fixedPrice;
      regimen.discount = Math.floor(((regimen.totalMRP - regimen.totalSP) * 100) / regimen.totalMRP);
    }
    // If order is in initial stage, ie products are not copied from regimen to
    // products array. Then we need to pick products from `regimen.products`.
    // Also we need to hide `repair` products name to prevent users from finding those
    // products and leading to order cancellation.
    if (!this.order?.get('products').length && this.order?.get('regimen')) {
      await Promise.all(this.order?.get('regimen')?.get('products').map((product: any): Promise<any> => product.fetch()));
      this.products = this.order?.get('regimen')?.get('products')
        ?.reduce((acc: any, product: any): any => ({ ...acc, [product.id]: product }), {});

      if (this.order?.get('regimen')?.get('extraProducts')?.length) {
        await Promise.all(this.order?.get('regimen')?.get('extraProducts').map((product: any): Promise<any> => product.fetch()));
        const extraProducts = this.order?.get('regimen')?.get('extraProducts')
          ?.reduce((acc: any, product: any): any => ({ ...acc, [product.id]: product }), {});
        if (extraProducts) this.products = { ...this.products, ...extraProducts };
      }
      const experiments = await this.conn.findUserActiveExperiments();
      const isHideMedicine = experiments.find((experiment: any): any => experiment.key === 'hide_prescription_medicines');
      // Rename repair products name untill it's delivered
      if (isHideMedicine && this.order?.get('stage') !== this.appConfig.Shared.Order.Stage.DELIVERED) {
        Object.values(this.products).forEach((product: any): void => {
          if (product?.get('purpose') && product?.get('purpose').toLowerCase().includes('repair')) {
            product.set('title', `${product?.get('purpose')} product`);
          }
        });
      }
    } else {
      this.products = this.order?.get('products')?.reduce((acc: any, product: any): any => ({ ...acc, [product.id]: product }), {});
    }

    if (this.order?.get('expectedDeliveryDate')) {
      this.expectedDeliveryDate = new Date(this.order?.get('expectedDeliveryDate'));
      this.expectedDeliveryDate.setDate(this.expectedDeliveryDate.getDate() + 2);
    }

    if (this.doctor
      && this.doctor?.get('DoctorDisplayName')
      && this.order?.get('type') === this.appConfig.Shared.Order.Type.REGIMEN
      && [...this.appConfig.Shared.Order.Stage.AfterShipment, this.appConfig.Shared.Order.Stage.DELIVERED]
        .includes(this.order?.get('stage'))
    ) this.regimenApproved = true;

    await this.findOrderStages();
    this.isiOSDevice = this.appWebBridge.requestOSInformation() === 'iOS' || this.windowRef.isSafariBrowser();
  }

  async checkProductInRegimen(productId:any): Promise<any> {
    this.regimens = (await this.conn.fetchRegimens(null, true)).filter((each:any): any => !each.expired);
    let allProducts = [];
    this.regimens?.forEach((regimen:any): void => {
      // Concatenate morning and night products
      const morningProducts = regimen.morning || [];
      const nightProducts = regimen.night || [];
      allProducts = allProducts.concat(morningProducts, nightProducts);
    });
    const containsProduct = allProducts?.some((product:any): any => product?.product.objectId === productId);
    return containsProduct;
  }

  async cancelOrderDialog(): Promise<any> {
    if (this.order?.get('type') === this.appConfig.Shared.Order.Type.REGIMEN) {
      this.popUpModal = {
        open: true,
        title: this.appConfig.Shared.String.GOT_QUESTIONS,
        type: this.appConfig.Dialog.CONFIRMATION,
        requestCode: this.REQUEST_CODE.REGIMEN_ORDER_CANCEL,
        cancelText: this.appConfig.Shared.String.SEE_REVIEWS,
        okText: this.appConfig.Shared.String.CANCEL_ORDER,
        message: { type: this.appConfig.Shared.String.CANCEL_ORDER_OFFER_CONFIRM },
      };
      return;
    }
    this.popUpModal = {
      requestCode: this.REQUEST_CODE.ORDER_CANCEL,
      type: this.appConfig.Dialog.CONFIRMATION,
      cancelText: this.appConfig.Shared.String.SEND_PRODUCTS,
      title: this.appConfig.Shared.String.CANCEL_ORDER,
      okText: this.appConfig.Shared.String.CANCEL_ORDER,
      message: { type: this.appConfig.Shared.String.CANCEL_ORDER_OFFER_CONFIRM },
      open: true,
    };
  }

  cancelOrder(cancelReason?: string): void {
    this.broadcast.broadcast('LOADING', { status: true });
    this.conn.cancelOrder(this.order.id, cancelReason)
      .then((): void => this.order.fetch())
      .then((order: any): void => {
        this.order = order;
        this.broadcast.broadcast('LOADING', { status: false });
        this.findOrderStages();
        if (!order?.get('cancelReason')) {
          this.popUpModal = {
            open: true,
            title: this.appConfig.Shared.String.CANCEL_ORDER,
            requestCode: this.REQUEST_CODE.ORDER_CANCEL_REASON,
            type: this.appConfig.Dialog.RADIO_BUTTON,
            okText: this.appConfig.Shared.String.SUBMIT,
            inputs: [
              { text: this.appConfig.Shared.String.WILL_ORDER_LATER },
              { text: this.appConfig.Shared.String.REGIMEN_NOT_HAPPY },
              { text: this.appConfig.Shared.String.ORDER_MISTAKE },
              { text: this.appConfig.Shared.String.COSTLY },
              { text: this.appConfig.Shared.String.SOMETHING_ELSE },
            ],
            message: { type: this.appConfig.Shared.String.ORDER_CANCEL_REASON },
          };
          return;
        }
        this.broadcast.broadcast('NOTIFY', { message: 'Order cancelled successfully' });
      })
      .catch((err: any): void => {
        this.broadcast.broadcast('LOADING', { status: false });
        this.popUpModal = {
          title: this.appConfig.Shared.String.CANCEL_ORDER,
          open: true,
          cancelText: this.appConfig.Shared.String.OK,
          type: this.appConfig.Dialog.ALERT,
          message: { text: err.message },
        };
      });
  }

  onPopUpClose(result: PopUpModalType.Result): void {
    this.popUpModal.open = false;
    switch (result.requestCode) {
      case this.REQUEST_CODE.ORDER_CANCEL: {
        if (result.clickOnYes) this.cancelOrder();
        break;
      }
      case this.REQUEST_CODE.ORDER_CANCEL_REASON: {
        this.cancelOrder(result?.input?.text);
        break;
      }
      case this.REQUEST_CODE.REGIMEN_ORDER_CANCEL: {
        if (result.clickOnYes) {
          this.cancelOrder();
          return;
        }
        if (result.clickOnNo) {
          setTimeout((): Promise<any> => this.router.navigate(['/user/testimonialsNew']), 200);
        }
        break;
      }
      default:
    }
  }

  makePaymentForOrder(): void {
    this.router.navigate(['./payment'], { relativeTo: this.route });
  }

  private updateOrderFlags(): void {
    this.cancelOrderAllowed = false;
    this.changeAddressAllowed = false;
    if ([
      this.appConfig.Shared.Order.Stage.CANCELED,
      this.appConfig.Shared.Order.Stage.REFUND_REQUESTED,
    ].includes(this.order?.get('stage'))) return;

    if (this.appConfig.Shared.Order.Stage.BeforeShipment.includes(this.order?.get('stage'))) this.changeAddressAllowed = true;
    const currentOrderStage: any = this.allLocalStages.find((stage: any): boolean => stage.name === this.order?.get('stage'));
    if (currentOrderStage) {
      this.cancelOrderAllowed = currentOrderStage
        .possibleStageChange.some(({ name }: { name: string }): boolean => (this.appConfig.Shared.Order.Stage.CANCELED === name));
    }
  }

  private async findOrderStages(): Promise<void> {
    this.orderStages = [];
    this.allLocalStages = await this.conn.fetchOrderStages();
    this.updateOrderFlags();
    const orderStages: any[] = (await this.conn.findOrderStages({ order: this.order }, ['createdBy'])).reverse();
    orderStages.forEach((orderStage: any): void => {
      const orderStageJson = this.allLocalStages.find((stage: any): boolean => stage.name === orderStage?.get('stage'));
      if (orderStage?.get('stage') === this.appConfig.Shared.Order.Stage.DR_VERIFIED) {
        this.approvedByDoctor = orderStage?.get('createdBy')?.get('DoctorDisplayName');
      }
      orderStage.set('orderStage', orderStageJson);
      if (orderStageJson && orderStageJson.visibleToUser !== false) this.orderStages.push(orderStage);
    });
  }

  reorder(product?: any): void {
    let productIds = [];
    if (product) {
      productIds = [product.id];
    } else {
      productIds = this.order?.get('productInfo').map((product: any): string => product.id);
    }
    const ids = Array.from(new Set([...productIds]));
    const url = this.order?.get('type') === 'REGIMEN'
      ? `/cart?products=${ids.join(',')}&type=REGIMEN`
      : `/cart?products=${ids.join(',')}&type=PRODUCT`;
    this.conn.navigateToURL(url);
  }

  trackOrder(url: string): void {
    if (this.isiOSDevice) this.conn.navigateToURL(url);
    else this.broadcast.broadcast('OPEN_IN_NEW_TAB', { url });
  }

  openInvoiceUrl(url: string): void {
    if (this.isiOSDevice) this.conn.navigateToURL(url);
    else this.broadcast.broadcast('OPEN_IN_NEW_TAB', { url });
  }

  openURL(url: string): void {
    this.conn.navigateToURL(url);
  }

  viewProductDetail(product: any): void {
    this.conn.navigateToURL(`/product/${product.id}`);
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  seeInstructions(): void {
    this.router.navigate(['/user'], {
      queryParams: { tab: 'regimen', class: this.order.get('regimen').get('class') || 'FACE', init: 'true' },
      replaceUrl: true,
    });
  }

  async handleNavigation(product:any): Promise<void> {
    if (await this.checkProductInRegimen(product?.id)) {
      this.router.navigate(['/user'], {
        queryParams: { tab: 'regimen' },
        replaceUrl: true,
      });
    } else {
      this.conn.navigateToURL(`/product/${product.id}`);
    }
  }
}
