<div class="hbox bg-dark">
  <div class="flex-box">
  <div class="flex-content pos-rel">
    <div *ngIf="!ui.photoTaken && !ui.waitForUiGesture">
    <div class="pos-abs w85 clr-white font18 h-center text-center" *ngIf="!ui.photoTaken" style="z-index: 2; top: 30px">
      Turn your Phone. Photo will be taken after 7 seconds.
    </div>
    <video id="video" #video="" style="visibility: hidden" autoplay="autoplay" playsinline="playsinline"></video><canvas #canvas=""></canvas><audio #audio="" src="/assets/sounds/beep-07.mp3" autostart="false"></audio>
    <div class="counter" [hidden]="counter === 0 || counter > 7 || ui.photoTaken">
      {{counter}}
    </div>
    </div>
    <div class="pos-abs vh-center w100 text-center p20" *ngIf="ui.waitForUiGesture">
    <div class="clr-white w80 mx-auto font17">
      Photo is taken from back camera for better quality. Click Allow to
      continue
    </div>
    <cs-button class="mT15" (callback)="startVideo()" [class]="'w50 primary mT20'"><cs-button-text i18n="@@allow">Allow</cs-button-text></cs-button>
    </div>
    <div class="closeBtn w100 p15 pos-abs">
    <cs-button *ngIf="!ui.photoTaken && !ui.waitForUiGesture" [disabled]="ui.photoTaken" (callback)="closeCamera(true)" [class]="'w100 primary'"><cs-button-text i18n="@@cancel">Cancel</cs-button-text></cs-button>
    </div>
  </div>
  </div>
</div>