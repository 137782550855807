import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading/index';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { ComparisonSliderComponent } from './comparison-slider.component';

@NgModule({
  imports: [CommonModule, LoadingModule, CustomPipeModule],
  declarations: [ComparisonSliderComponent],
  exports: [ComparisonSliderComponent],
})
export class ComparisonSliderModule {}
