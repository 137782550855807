import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { FollowUpReportBannerComponent } from './followup-report.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ButtonModule,
  ],
  declarations: [FollowUpReportBannerComponent],
  exports: [FollowUpReportBannerComponent],
})
export class FollowUpReportBannerModule {}
