import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { DoctorPanelComponent } from './doctor-panel.component';

@NgModule({
  declarations: [DoctorPanelComponent],
  imports: [
    CommonModule,
    CsIconModule,
  ],
  exports: [DoctorPanelComponent],
})

export class DoctorPanelModule { }
