import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';

@Component({ selector: 'login-doctor', templateUrl: './login-doctor.html' })
export class LoginDoctorComponent {
  sessionToken: string = '';
  static parameters: any = [ConnectionService, ActivatedRoute, BroadcastService];
  constructor(private conn: ConnectionService, private route: ActivatedRoute, private broadcaster: BroadcastService) {
  }

  ngOnInit(): any {
    const { token }: any = this.route.snapshot.queryParams;
    if (token) this.addSessionTokenToCookie(token);
  }

  addSessionTokenToCookie(token?: any): void {
    if (token) this.sessionToken = token;
    if (this.sessionToken) {
      this.conn.loginWithTokenAndUpdateUserInfo(this.sessionToken)
        .then((): Promise<any> => this.conn.redirectToLastKnowLocation())
        .catch((err: any): void => alert(err.message || err));
    } else {
      this.broadcaster.broadcast('NOTIFY', { message: 'Session token missing' });
    }
  }
}
