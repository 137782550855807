import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';

@Directive({ selector: '[dblClick]' })
export class DoubleClickDirective {
  prevClick: number;
  MAX_WAIT_TIME_IN_MILLI_SEC: number = 400;
  timeoutInterval: any;
  isMoved: boolean;

  @Output('onClick') onClick: EventEmitter<void> = new EventEmitter();
  @Output('onDoubleClick') onDoubleClick: EventEmitter<void> = new EventEmitter();
  constructor(private window: WindowRefService) {}

  @HostListener('touchmove', ['$event.touches[0]?.clientX', '$event.touches[0]?.clientY'])
  handleTouchMove(): void {
    this.isMoved = true;
  }

  @HostListener('touchend', ['$event.touches[0]?.clientX', '$event.touches[0]?.clientY'])
  handleTouchEnd(): void {
    if (this.isMoved) {
      this.isMoved = false;
      return;
    }
    if (!this.window.isMobileBrowser()) return;
    this.handleDoubleClick();
  }

  @HostListener('click', ['$event'])
  handleClick(): void {
    if (this.window.isMobileBrowser()) return;
    this.handleDoubleClick();
  }

  handleDoubleClick(): void {
    clearTimeout(this.timeoutInterval);
    this.timeoutInterval = setTimeout((): void => {
      this.onClick.emit();
      delete this.prevClick;
    }, this.MAX_WAIT_TIME_IN_MILLI_SEC);
    if (!this.prevClick) {
      this.prevClick = new Date().getTime();
      return;
    }
    const timeDiff: number = (new Date().getTime()) - this.prevClick;
    if (timeDiff <= this.MAX_WAIT_TIME_IN_MILLI_SEC) {
      clearTimeout(this.timeoutInterval);
      this.onDoubleClick.emit();
    }
    delete this.prevClick;
    this.isMoved = false;
  }
}
