import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { SkinParameterComponent } from './skin-parameter.component';

@NgModule({
  imports: [
    CommonModule,
    CsIconModule,
  ],
  declarations: [SkinParameterComponent],
  exports: [SkinParameterComponent],
})
export class SkinParameterModule {}
