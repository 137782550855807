<div class="rebranded-audio-container" *ngIf="src" (click)="$event.stopPropagation();" [ngClass]='{ "bg-white": imageUrl}'>
  <div class="audio-img-container" *ngIf="imageUrl">
  <img class="audio-img mL5 mR5" src="{{imageUrl}}" /><img class="audio-mic" src="/assets/images/mic.svg" />
  </div>
  <div class="slider-box" [ngClass]='{ "mL20": imageUrl}'>
  <div class="text-center play-container" *ngIf="!isPlaying" (click)="playAudio();$event.stopPropagation();">
    <em class="cs-icon-play playButton"></em>
  </div>
  <div class="text-center play-container" *ngIf="isPlaying" (click)="pauseAudio();$event.stopPropagation();">
    <em class="cs-icon-pause pauseButton"></em>
  </div>
  <div class="slider-container">
    <input class="slider" #slider="" type="range" min="0" max="100" value="0" step="1" />
    <div class="slider-time">
    <loading *ngIf="loading" [scale]="0.4" margin="-11px"></loading>
    <div class="font12" *ngIf="!loading">{{audioDuration}}</div>
    </div>
  </div>
  </div>
</div>