<ng-container>
<section *ngIf="!npsBannerExperiment" class="reminder-action n-p-s-section" (click)="openFeedback()">
  <div class="n-p-s-box card text-center">
  <div class="npsBoxText">
    <span class="w40">
    <div class="fw-700" [ngClass]="{ 'tw-text-400': checkIfLanguageWithUIIssues, 'tw-text-600': !checkIfLanguageWithUIIssues}" i18n="@@tellUsYourFeedback">
      Tell Us Your Feedback
    </div>
    </span><span class="icons"><img class="icon" src="/assets/images/nps-1.png" /><img class="icon" src="/assets/images/nps-2.png" /><img class="icon" src="/assets/images/nps-3.png" /><img class="icon" src="/assets/images/nps-4.png" /><span class="multiple-icon-container"><img class="icon-header icon" src="/assets/images/nps-6.png" /><img class="icon" src="/assets/images/nps-5.png" /><img class="icon-footer icon" src="/assets/images/nps-hand.png" /></span></span>
  </div>
  <div>
    <div class="small-btn share" i18n="@@shareHere">Share Here</div>
  </div>
  </div>
</section>

<!--  new nps banner oct 2024  -->
  <section *ngIf="npsBannerExperiment" class="tw-px-4 tw-py-6 tw-bg-gray-50 tw-font-display" (click)="openFeedback()">
    <div class="tw-bg-yellow-100 tw-flex tw-flex-col tw-items-center tw-pt-6 tw-rounded-3 tw-pb-2 tw-border-[1px] tw-border-gray-50 tw-shadow-z4 tw-overflow-x-clip">
      <h1 class="tw-text-500 tw-font-bold tw-mb-1 tw-text-center" i18n="@@shareYourFeedback">Share your feedback</h1>
      <p class="tw-text-gray-500 tw-text-200 tw-text-center tw-w-72" i18n="@@yourInputIsValuable">Your input is valuable in helping us better understand your needs.</p>
      <img src="/assets/images/nps-banner-oct-2024.svg" alt="banner" class="tw-mt-3 tw-scale-105"/>
    </div>
  </section>
</ng-container>
