import { Component, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { CurrentComponentService } from '@services/current-component';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { AppConfig } from '../app.config';

SwiperCore.use([Autoplay, Scrollbar]);

@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.html',
  styleUrls: ['./product-description.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductDescriptionComponent implements OnDestroy {
  product: any;
  window: any;
  user: any;
  paid: boolean = false;
  keyDetail: any = [];
  discount: any = 0;
  totalSP: any = 0;
  totalMRP: any = 0;
  experiments: any = [];
  cart: any;
  cartItems: any = [];
  toolbarIcons: Array<any> = [
    {
      clickId: 'cart_page',
      name: this.appConfig.Toolbar.ICONS.CART,
      callback: (): void => this.goToCart(),
    },
  ];

  images: { type: string, url: string }[] = [];
  isOldProductImage: boolean = false;

  carouselConfig: SwiperOptions = {
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    observer: false,
    pagination: {
      el: '#swiper-pagination',
      clickable: true,
      bulletActiveClass: 'active-pagination-class',
      renderBullet: (index: number, className: string): string => `<div
        class="${className} tw-m-0 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-bg-purple-100 tw-rounded-none"
        style="opacity: 1">
        <img class="tw-max-h-full tw-max-w-full" src="${this.images[index]?.type === 'video'
    ? '/assets/images/play_circle.svg'
    : this.images[index]?.url}">
      </div>`,
    },
  };
  @ViewChild('swiperWrapperOne', { static: false }) swiper?: SwiperComponent;
  slideNext(): void {
    this.swiper.swiperRef.slideNext(100);
  }
  slidePrev(): void {
    this.swiper.swiperRef.slidePrev(100);
  }

  loading: boolean = true;
  constructor(
    private conn: ConnectionService,
    private route: ActivatedRoute,
    private windowRef: WindowRefService,
    public appConfig: AppConfig,
    private currentComponent: CurrentComponentService,
    private router: Router,
    private eventLogger: EventLoggerService,
    private broadcast: BroadcastService,
  ) { }

  async ngOnInit(): Promise<any> {
    this.currentComponent.set(this);
    this.window = this.windowRef.nativeWindow;
    this.user = this.conn.getActingUser();
    if (!this.user) return;
    if (this.user.isPaid()) this.paid = true;
    await this.subscribeToUrlQueryParams();
    this.toolbarIcons[0].showCartUI = true;
    this.getCart();
    this.experiments = await this.conn.findUserActiveExperiments(this.user.get('username'));
    const productId = this.route.snapshot.params.id;
    const prodDetail = await this.conn.findCatalogWithKey([productId]);
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'product-description', productId }));
    this.eventLogger.trackEvent('product_opened', { productId, type: 'PRODUCT_OPEN' });
    this.eventLogger.trackInParse('PAGE_OPEN', productId);
    [this.product] = JSON.parse(JSON.stringify(prodDetail));
    if (this.product?.productUnboxedImage?.length) {
      if (this.product?.productUnboxedImage[0].split('.').pop() === 'mp4') {
        this.images.push({ type: 'video', url: this.product?.productUnboxedImage[0] });
      } else {
        this.images.push({ type: 'image', url: this.product?.productUnboxedImage[0] });
      }
      this.isOldProductImage = true;
    } else if (this.product?.rebrandedImageWithBackground?.length) {
      if (this.product?.rebrandedImageWithBackground[0].split('.').pop() === 'mp4') {
        this.images.push({ type: 'video', url: this.product?.rebrandedImageWithBackground[0] });
      } else {
        this.images.push({ type: 'image', url: this.product?.rebrandedImageWithBackground[0] });
      }
    }
    if (this.product.extraImages?.length) {
      this.images = [];
      this.product.extraImages.forEach((imageArr: string[]): void => {
        if (imageArr[0]?.split('.')?.pop() === 'mp4') {
          this.images.push({ type: 'video', url: imageArr[0] });
        } else if (['jpg', 'png'].includes(imageArr[0]?.split('.')?.pop())) {
          this.images.push({ type: 'image', url: imageArr[0] });
        }
      });
    }
    this.keyDetail = this.product.key_features;
    this.findDiscount();
    this.loading = false;
  }

  goToCart(): void {
    this.router.navigate(['cart']);
  }

  async getCart(): Promise<void> {
    this.cart = await this.conn.getCart();
    this.cartItems = this.cart.get('lineItems');
    if (this.cartItems.length) {
      this.toolbarIcons[0].cart = true;
      let totalItems: number = 0;
      this.cartItems.forEach((element: any): void => {
        totalItems += element.quantity;
      });
      this.toolbarIcons[0].cartValue = totalItems || null;
    } else {
      this.toolbarIcons[0].cartValue = null;
    }
  }
  findDiscount(): void {
    this.totalMRP = this.product.mrp;
    this.totalSP = this.product.price;
    if (this.totalMRP > this.totalSP) {
      this.discount = Math.floor(((this.totalMRP - this.totalSP) * 100) / this.totalMRP);
    } else this.discount = 0;
  }

  async subscribeToUrlQueryParams(): Promise<any> {
    const productId = this.route.snapshot.params.id;
    const prodDetail = await this.conn.findProductsById([productId]);
    [this.product] = JSON.parse(JSON.stringify(prodDetail));
    if (!this.product) this.back();
  }

  async buttonAction(): Promise<void> {
    const productId = this.product.objectId;
    if (this.cartItems.length) {
      this.cartItems.forEach(async (element: any): Promise<any> => {
        if (element.productId === productId) {
          const [productsFromParams]: any = await this.conn.findProductsById(element.productId);
          if (productsFromParams) {
            // eslint-disable-next-line no-param-reassign
            element.prescriptionRequired = productsFromParams.get('prescriptionRequired');
          }
          if (!element.prescriptionRequired && element.quantity < 5) {
            const params = {
              productId: element.productId,
              quantity: 1,
            };
            await this.conn.addProductToCart(params);
            this.router.navigate(['cart']);
            return;
          }
          if (element.prescriptionRequired && element.quantity < 3) {
            const params = {
              productId: element.productId,
              quantity: 1,
            };
            await this.conn.addProductToCart(params);
            this.router.navigate(['cart']);
            return;
          }
          if (!element.prescriptionRequired && element.quantity >= 5) {
            this.router.navigate(['cart']);
            this.broadcast.broadcast('NOTIFY', {
              message: 'Maximum quantity is limited to 5 per product',
            });
            return;
          }
          if (element.prescriptionRequired && element.quantity >= 3) {
            this.router.navigate(['cart']);
            this.broadcast.broadcast('NOTIFY', {
              message: 'Maximum quantity is limited to 3 per product',
            });
            return;
          }
        }
        const prod: any = this.cartItems.find((each: any): any => each.productId === productId);
        if (!prod) {
          const params = {
            productId,
            quantity: 1,
          };
          await this.conn.addProductToCart(params);
          this.router.navigate(['cart']);
        }
      });
    } else {
      const params = {
        productId: this.product.objectId,
        quantity: 0,
      };
      await this.conn.addProductToCart(params);
      const paramsQuery = {
        type: this.appConfig.Shared.Order.Type.PRODUCT,
        products: this.product.objectId,
      };
      this.router.navigate(['cart']);
    }
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'product-description-checkout' }));
  }

  open(url: any, params?: any): void {
    this.router.navigate([url], params);
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  ngOnDestroy(): void { }
}
