import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StringHelperService {
  constructor() { }

  removeSubstringFromEnd(originalStr: string, substring: string): string {
    if (originalStr === substring) {
      return originalStr;
    }
    const lastIndex = originalStr.lastIndexOf(substring);
    if (lastIndex !== -1 && lastIndex + substring.length === originalStr.length
    ) {
      return originalStr.substring(0, lastIndex).trim();
    }
    return originalStr;
  }
}
