import { Component } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { Router } from '@angular/router';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'testimonials-new',
  templateUrl: './testimonials-new.html',
  styleUrls: [],
})

export class TestimonialsNewComponent {
  user: any;
  concernClass: string = 'FACE';
  constructor(
    private broadcast: BroadcastService,
    private conn: ConnectionService,
    private eventLogger: EventLoggerService,
    public appConfig: AppConfig,
    private router: Router) { }

  ngOnInit(): void {
    this.user = this.conn.getActingUser();
    this.concernClass = this.user?.get('PrivateMainConcernClass') || 'FACE';
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'testimonials-new' }));
  }

  back(): void {
    this.router.navigate(['user?tab=home']);
  }
}
