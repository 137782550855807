import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { RegimenConcernSelectionModule } from '@shared/regimen-concern-selection/regimen-concern-selection.module';
import { ProductCarouselModule } from '@shared/product-carousel/product-carousel.module';
import { LoadingModule } from '@components/loading';
import { ShopByCategoryComponent } from './shop-by-category.component';

@NgModule({
  imports: [CommonModule,
    ButtonModule,
    LoadingModule,
    RegimenConcernSelectionModule,
    ProductCarouselModule,
  ],
  declarations: [ShopByCategoryComponent],
  exports: [ShopByCategoryComponent],
})
export class ShopByCategoryModule {}
