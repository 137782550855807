<div class="tw-flex tw-flex-col tw-h-full tw-bg-green-100">
    <div class="tw-fixed tw-left-6 tw-top-3 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
        <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
    </div>
    <div class="tw-flex-none tw-bg-green-100 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-px-6">
        <div class="tw-flex tw-items-start tw-gap-2 tw-w-full tw-mb-3 tw-pt-16 tw-z-[100]">
            <div class="tw-font-body tw-font-bold tw-text-300 tw-px-5 tw-py-3 tw-rounded-4" i18n="@@invite" (click)="type = 'INVITE'" [ngClass]="type === 'INVITE' ? 'tw-bg-purple-400 tw-text-white' : 'tw-bg-white tw-text-gray-500'">Invite</div>
            <div class="tw-font-body tw-font-bold tw-text-300 tw-px-5 tw-py-3 tw-rounded-4" i18n="@@faqs" (click)="type = 'FAQ'" [ngClass]="type === 'FAQ' ? 'tw-bg-purple-400 tw-text-white' : 'tw-bg-white tw-text-gray-500'">FAQs</div>
        </div>
        <div class="tw-font-body tw-font-bold tw-text-black tw-text-700 tw-mb-6" i18n="@@earnCashOnEveryReferral" *ngIf="type === 'INVITE'">
            Earn ₹400 Cureskin cash<br />on your every referral
        </div>
        <div class="tw-font-body tw-text-300 tw-text-normal tw-mb-2" i18n="@@yourReferralCode">
            Your referral code
        </div>
        <div class="tw-bg-white tw-rounded-4 tw-px-4 tw-flex tw-items-center tw-justify-between tw-mb-6 tw-h-[52px]" (click)="copyText()">
            <input class="tw-font-body tw-text-300 tw-text-gray-400 tw-border-0" value="{{user.id}}" id="referralCode" readonly="readonly" />
            <div class="tw-h-8 tw-bg-green-400 tw-text-white tw-text-200 tw-rounded-6 tw-px-4 tw-flex tw-items-center tw-gap-2">
                <span i18n="@@copy">Copy</span><cs-icon class="tw-h-3 tw-w-3" [iconName]='"chevron_right"'></cs-icon>
            </div>
        </div>
    </div>
    <div class="tw-flex-auto tw-overflow-auto tw-rounded-tr-5 tw-rounded-tl-5 tw-p-6 tw-bg-white" *ngIf="type === 'INVITE'">
        <div class="tw-font-body tw-font-bold tw-text-400 tw-mb-6" i18n="@@userReferShareCureSkinTodayParaWallet">
            Get someone started on the journey of glowing skin and strong hair with
            Cureskin, and receive ₹400 in your Cureskin wallet.
        </div>
        <div class="tw-flex tw-flex-row tw-gap-2 tw-mb-5">
            <cs-icon class="tw-flex-none tw-h-6 tw-w-6 tw-text-green-400" [iconName]="'point'"></cs-icon>
            <div class="tw-flex-auto tw-flex tw-flex-col tw-gap-2">
                <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@inviteYourFriend">
                    Invite your friends
                </p>
                <p class="tw-font-body tw-text-300 tw-text-gray-500" i18n="@@clickTheReferNowButton">
                    Click the 'Refer now' button given below to share this app with your
                    friend.
                </p>
            </div>
        </div>
        <div class="tw-flex tw-flex-row tw-gap-2 tw-mb-5">
            <cs-icon class="tw-flex-none tw-h-6 tw-w-6 tw-text-green-400" [iconName]="'point'"></cs-icon>
            <div class="tw-flex-auto tw-flex tw-flex-col tw-gap-2">
                <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@friendWillDownloadApp">
                    Friend will download app
                </p>
                <p class="tw-font-body tw-text-300 tw-text-gray-500" i18n="@@makeSureHeSheDownloads">
                    Make sure he/she downloads the app from your shared link.
                </p>
            </div>
        </div>
        <div class="tw-flex tw-flex-row tw-gap-2 tw-mb-5">
            <cs-icon class="tw-flex-none tw-h-6 tw-w-6 tw-text-green-400" [iconName]="'point'"></cs-icon>
            <div class="tw-flex-auto tw-flex tw-flex-col tw-gap-2">
                <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@youBothEarnCash">
                    You both earn cash
                </p>
                <p class="tw-font-body tw-text-300 tw-text-gray-500" i18n="@@friendPurchasesAndReceivesTreatment">Friend purchases and receives the treatment kit, and you both get ₹400 Cureskin Cash in your wallets.</p>
            </div>
        </div>
    </div>
    <div class="tw-flex-auto tw-overflow-auto tw-rounded-tr-5 tw-rounded-tl-5 tw-p-6 tw-bg-white" *ngIf="type === 'FAQ'">
        <p class="tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@whomCanIRefer">
            Whom can I refer?
        </p>
        <p class="tw-font-body tw-text-300 tw-mb-4" i18n="@@userReferYouCanReferAnyPara">
            You can refer any person above the age of 18, residing in India with an
            Indian phone number, who has not purchased products from Cureskin. Any
            person who has already purchased products from CureSkin will not qualify.
        </p>
        <p class="tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@whatIsTheReferralProcess">
            What is the referral process?
        </p>
        <p class="tw-font-body tw-text-300" i18n="@@userReferUseTheShareButtonInParaNew">
            1) Use the 'Refer now' button in the Referral screen to share the app with
            a friend. Make sure your friend downloads the app from the link you share
            OR ask your friend to use your referral code during checkout.
        </p>
        <p class="tw-font-body tw-text-300 tw-mb-4" i18n="@@userReferWhenYourFriendPurchasesParaWalletTreatment">
            2) When your friend purchases and receives a treatment kit, both you and
            your friend will receive ₹ {{cashValue}} Cureskin cash in your wallet.
        </p>
        <p class="tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@whenWillIEarnTheReward">
            When will I earn the reward?
        </p>
        <p class="tw-font-body tw-text-300 tw-mb-4" i18n="@@userReferYouWillEarnRewardParaTreatment">
            You will earn your reward, when the person you refer orders a treatment
            kit from Cureskin, either after downloading the Cureskin application
            through the link you share or by using your referral code during checkout.
            The person referred, must pay for the treatment kit and not return the
            product.
        </p>
        <p class="tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@howWillIReceiveAnyProductsThatIEarn">
            How will I receive any reward that I earn?
        </p>
        <p class="tw-font-body tw-text-300 tw-mb-4" i18n="@@userReferYouCanGoToThePara">
            You can go to the "My Wallet" screen and claim your reward.
        </p>
        <p class="tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@byWhenDoIHaveToRedeemTheOffer">
            By when do I have to redeem the offer?
        </p>
        <p class="tw-font-body tw-text-300 tw-mb-4" i18n="@@userReferProductsAndCashBackArePara">
            Rewards are available only for 60 days from when they are made available
            to you.
        </p>
        <p class="tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@userReferMyReferralForgotToPara">
            My referral forgot to download the app through my link or use the referral
            code.
        </p>
        <p class="tw-font-body tw-text-300 tw-mb-4" i18n="@@userReferUnfortunatelyYouWillPara">
            Unfortunately, you will be unable to earn any rewards in such a situation
            as you cannot retroactively apply referral codes.
        </p>
        <p class="tw-font-body tw-font-bold tw-text-300 tw-mb-1" i18n="@@userReferIHaveReferredMyFriendPara">
            I have referred my friend/family member but not received my reward?
        </p>
        <p class="tw-font-body tw-text-300 tw-mb-4" i18n="@@userReferWeApologiseForThePara">
            We apologise for the inconvenience. Please write to us at
            <a class="tw-text-black tw-italic" href="mailto:info@cureskin.com">info&#64;cureskin.com</a>
        </p>
    </div>
    <div class="tw-flex-none shadow tw-px-6 tw-py-4 tw-bg-white">
        <cs-button [class]="'primary w100 shine'" (callback)="share()"><cs-button-text i18n="@@referNow">Refer Now</cs-button-text></cs-button>
    </div>
</div>
<pop-up [show]="showSuccessPopup" (close)="closePopup()">
    <div class="w100 tw-bg-white tw-p-4 tw-flex tw-flex-col tw-items-center tw-rounded-[24px]">
        <div class="tw-bg-white tw-px-4 tw-pt-5 w100 tw-text-center tw-pb-2 tw-rounded-xl">
            <div class="tw-font-bold tw-text-700 tw-mb-2 tw-text-black">
                <span>You have successfully referred</span>
            </div>
            <div class="tw-mb-3 tw-text-200 tw-text-gray-500">
                <span>When you refered contact purchases and receives the treatment kit,
                    both you and your friend will receive Rs 400 in your CureSkin wallet
                    to use on your next purchase.</span>
            </div>
            <cs-button [class]="'w100 primary'" (click)="closePopup()"><cs-button-text>refer more/close</cs-button-text></cs-button>
        </div>
    </div>
</pop-up>