// Onboarding page url config
export const onboardingPageMap = {
  genderPage: '/onboarding/gender',
  locationPage: '/onboarding/location',
  concernPage: '/onboarding/concern',
} as const;

declare global {
  export type OnboardingPage = keyof typeof onboardingPageMap;
  export type OnboardingPageUrl = typeof onboardingPageMap[OnboardingPage];
}

// Onboarding helper class to check onboarding status and state of the user
// !important: Don't import it directly in components or modules.
// TODO(rajesh@heallo) Limit import at compile time
export class Onboarding {
  constructor(private user: Record<string, any>, private isUserWithoutMainConcern: boolean) { }

  hasGenderData(): boolean {
    return this.user.Gender && this.user.Age;
  }

  hasLocationData(): boolean {
    return this.user.lastLocation;
  }

  hasConcernData(): boolean {
    return !this.isUserWithoutMainConcern;
  }

  validateOnboarding(): OnboardingPageUrl | null {
    if (!this.hasGenderData()) return onboardingPageMap.genderPage;
    if (!this.hasLocationData()) return onboardingPageMap.locationPage;
    if (!this.hasConcernData()) return onboardingPageMap.concernPage;
    return null;
  }
}
