import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { RegimenConcernSelectionComponent } from './regimen-concern-selection.component';

@NgModule({
  imports: [CommonModule, CsIconModule, ButtonModule],
  declarations: [RegimenConcernSelectionComponent],
  exports: [RegimenConcernSelectionComponent],
})
export class RegimenConcernSelectionModule {}
