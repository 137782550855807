import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading/index';
import { ToolbarModule } from '@components/toolbar/index';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { ButtonModule } from '@components/button';
import { ProgressBarModule } from '@components/progress-bar/progress-bar.module';
import { ComparisonSliderModule } from '@shared/comparison-slider/comparison-slider.module';
import { CsIconModule } from '@components/icon';
import { CAudioModule } from '@shared/c-audio/c-audio.module';
import { RouterModule } from '@angular/router';
import { UserFollowupReportComponent } from './user-followup-report.component';

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild([
      { path: 'report/:id', component: UserFollowupReportComponent },
    ]),
    CommonModule,
    LoadingModule,
    ToolbarModule,
    CustomPipeModule,
    ButtonModule,
    ProgressBarModule,
    ComparisonSliderModule,
    CAudioModule,
    CsIconModule,
  ],
  declarations: [UserFollowupReportComponent],
  exports: [],
})
export class UserFollowupReportModule { }
