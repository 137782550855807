/* eslint-disable arrow-body-style */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppStateI } from '../reducers';
import * as Types from '../modals';
import * as fromRouteSelectors from './router.selectors';

export const selectApp = createFeatureSelector<AppStateI>('app');

export const selectConfigState = createSelector(
  selectApp,
  (appState: AppStateI): Types.ConfigI => appState.config,
);

export const selectReferralCashValue = createSelector(
  selectConfigState,
  (configState: Types.ConfigI): number => configState.referralCashValue,
);

export const selectExperiments = createSelector(
  selectApp,
  (appState: AppStateI): any[] => appState.experiments,
);

// ******** HOME PAGE *********** //

export const selectHomePageState = createSelector(
  selectApp,
  (appState: AppStateI): Types.HomePageDataI => appState.homePage,
);

export const selectHomePageBlogs = createSelector(
  selectHomePageState,
  (homePage: Types.HomePageDataI): object[] => homePage.blogs,
);

export const selectHomePageAddons = createSelector(
  selectHomePageState,
  (homePage: Types.HomePageDataI): object[] => homePage.addonProducts,
);

export const selectHomePageReorderProducts = createSelector(
  selectHomePageState,
  (homePage: Types.HomePageDataI): object[] => homePage.reorderProducts,
);

export const selectReorderProductsLoading = createSelector(
  selectHomePageState,
  (homePage: Types.HomePageDataI): boolean => homePage.isReorderProductsLoading,
);

export const selectAddonProductsLoading = createSelector(
  selectHomePageState,
  (homePage: Types.HomePageDataI): boolean => homePage.isAddonProductsLoading,
);

// ******** CART PAGE *********** //

export const selectCartState = createSelector(
  selectApp,
  (appState: AppStateI): Types.CartI => appState.cart,
);
export const selectCartProducts = createSelector(
  selectCartState,
  (cartState: Types.CartI): { [key: string]: number } => cartState.products,
);
