import { Component, EventEmitter, Output } from '@angular/core';

@Component({ selector: 'self-reorder-warning',
  templateUrl: './self-reorder-warning.html' })
export class SelfReorderWarningComponent {
  @Output('close') close: EventEmitter<any> = new EventEmitter();
  static parameters: any = [];
  constructor() {
  }

  closeDialog(): void {
    this.close.emit();
  }

  stopPropagation(event: any): void {
    event.stopPropagation();
  }
}
