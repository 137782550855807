import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from '../app.config';

@Component({
  selector: 'help',
  templateUrl: './help.html',
})
export class HelpComponent {
  showRestartButton: boolean = false;
  user: any;
  mailLink: any;
  appVersion: any;
  static parameters: any = [
    ConnectionService,
    BroadcastService,
    Router,
    AppConfig,
  ];
  constructor(
    private conn: ConnectionService,
    private broadcast: BroadcastService,
    private router: Router,
    public appConfig: AppConfig,
  ) {}
  async ngOnInit(): Promise<any> {
    const numberOfOrders = await this.conn.fetchOrdersCount();
    this.user = this.conn.getActingUser();
    this.mailLink = `mailto:hello@cureskin.com?Subject=${this.user.get('MobileNumber')}, ${this.user.get('PatientName')}`;
    this.showRestartButton = !numberOfOrders;
    this.appVersion = `V ${this.appConfig.Shared.AppVersion}`;
  }

  menuAction(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  async startAgain(): Promise<any> {
    await this.conn.disablePreviousConsultationSessions();
    return this.conn.navigateToURL('/mainConcern?force=true');
  }

  open(url: any): void {
    this.router.navigate([url]);
  }
}
