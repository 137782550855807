import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderShopComponent } from './loader-shop.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LoaderShopComponent],
  exports: [LoaderShopComponent],
})
export class LoaderShopModule {}
