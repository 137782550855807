<div class="weekStrip tw-bg-white tw-text-center tw-m-auto tw-mb-6 tw-font-body tw-text-200 tw-pt-2">
  <span i18n="@@yourTreatment1">Your Treatment :</span><span><span class="tw-font-bold tw-mr-1">&nbsp;WEEK 2</span><img class="tw-inline-flex tw-mb-[2px]" src="/assets/icons/teal-tick-circle.svg" /></span>
</div>
<div class="title tw-text-center tw-font-body  tw-mb-2 tw-m-auto">
  <p class="tw-mx-4 tw-text-600 tw-mb-1 tw-font-bold" i18n="@@week2Journey">
    You are in WEEK 2 of your journey
  </p>
  <p class="tw-mb-1 tw-mx-4" i18n="@@useKitEveryday">
    You're eligible for free monthly checkups!
  </p>
</div>
<div class="tw-text-center tw-mb-4 tw-mx-4">
  <cs-button class="tw-flex-none" 
  [class]="'primary btn-xxxs btn-xxxs-padding'" 
  (callback)="handleCTA()" 
  [ngClass]="{'tw-text-100': user?.get('languagePreference') === 'ta' || user?.get('languagePreference') === 'kn', 'tw-text-200': user?.get('languagePreference') !== 'ta' && user?.get('languagePreference') !== 'kn'}">
  <cs-button-text i18n="@@knowMore" style="padding: 0 10px">Know More</cs-button-text>
</cs-button>
</div>
<!-- <button class="tw-m-0 tw-min-w-unset tw-h-8 tw-px-4 tw-items-center tw-bg-green-400 tw-m-auto tw-mb-6" (click)="handleArticle1('post-purchase-improvement-card-4')">
  <span class="tw-normal-case tw-font-body tw-text-300 tw-tracking-normal tw-text-white tw-whitespace-break-spaces" i18n="@@knowMore">Know More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'chevron_right'"></cs-icon></button> -->
<swiper class="tw-pl-6 tw-pb-14 tw-pt-16" [config]="carouselConfigWeeklyJourney"><ng-container>
    <div class="bgClass firstCard tw-mr-3 tw-p-1 tw-h-full tw-box-border" *swiperSlide>
      <!-- <section class="cust-wid">
        <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="/assets/images/question_mark.png" />
        <div class="header tw-mt-11">
          <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@itchingOrBurning">
            Experiencing Itching/Burning/Redness or seeing more acne breakout?
          </div>
        </div>
        <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-absolute tw-bottom-0 tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleArticle2('post-purchase-issue-card-4')">
          <span class="tw-lowercase" i18n="@@readMore">Read More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
        </div>
      </section> -->
      <!-- 1 -->
      <section class="cust-wid tw-flex tw-flex-col tw-items-start">
        <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" [src]="userDrImage ? userDrImage : defaultDoctorImage" />
        <div class="header tw-mt-11 tw-mb-4">
          <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@upcomingCheckup">
            You have FREE upcoming checkup on
            {{
        followUp?.get("effectiveFollowUpDate") ||
          followUp?.get("nextFollowUpDate") | date: "dd MMM"
        }}
          </div>
        </div>
        <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-7 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleCheckups('post-purchase-checkup-card-4')">
          <span class="tw-lowercase" i18n="@@knowMore">Know More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
        </div>
      </section>
    </div>
    <!-- 2 -->
    <section class="cust-wid tw-flex tw-flex-col tw-items-start tw-mr-2" *swiperSlide>
      <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="/assets/images/question_mark.png" />
      <div class="header tw-mt-11 tw-mb-4">
        <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@whatAreMonthlyCheckup">
          What Are Free Monthly Checkups?
        </div>
      </div>
      <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleCheckups('post-purchase-checkup-card-4')">
        <span class="tw-lowercase" i18n="@@knowMore">Know More</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
      </div>
    </section>
    <!-- 3 -->
    <section class="cust-wid tw-flex tw-flex-col tw-items-start tw-mr-2" *swiperSlide>
      <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]"  src="/assets/images/before-after.png" />
      <div class="header tw-mt-11 tw-mb-4">
        <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@weekTwoCardThree">
          96% of people who had regular doctor checkups saw significant improvement in their skin        
        </div>
      </div>
      <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleCheckups('post-purchase-checkup-card-4')">
        <span class="tw-lowercase" i18n="@@checkHere">Check here</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
      </div>
    </section>
    <!-- 4 -->
    <section class="cust-wid tw-flex tw-flex-col tw-items-start tw-mr-2" *swiperSlide>
      <img class="cardImg tw-w-24 tw-h-24 tw-rounded-full tw-z-50 tw-absolute tw-object-cover tw-top-[-49px]" src="https://storage.googleapis.com/heallocontent/app/img/home_checkup.png" />
      <div class="header tw-mt-11 tw-mb-4">
        <div class="tw-font-body tw-text-300 tw-font-bold tw-mb-2" i18n="@@weekTwoCardFour">
          How to take free monthly doctor checkups?        
        </div>
      </div>
      <div class="tw-flex-auto tw-flex tw-w-fit tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-max-h-10 tw-mt-auto tw-mb-8 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-rounded-full" (click)="handleCheckups('post-purchase-checkup-card-4')">
        <span class="tw-lowercase" i18n="@@checkHere">Check here</span><cs-icon class="tw-h-4 tw-w-4 tw-text-green-400" [iconName]="'chevron_right'"></cs-icon>
      </div>
    </section>

  </ng-container></swiper>