import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserOrderModule } from '../user-order/user-order.module';
import { UserViewModule } from './user-view/user-view.module';
import { UserPrescriptionViewModule } from '../user-prescription/user-prescription-view.module';
import { UserProfileModule } from '../user-profile/user-profile.module';
import { UserReferModule } from '../user-refer/user-refer.module';
import { UserWalletModule } from '../user-wallet/user-wallet.module';
import { UserRegimenModule } from '../user-regimen/user-regimen.module';

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    UserOrderModule,
    UserViewModule,
    UserPrescriptionViewModule,
    UserProfileModule,
    UserReferModule,
    UserWalletModule,
    UserRegimenModule,
  ],
})
export class UserModule { }
