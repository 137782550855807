import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { WindowRefService } from '@services/window-ref-service';
import { ConnectionService } from '@services/connection-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'buy-now-footer', templateUrl: './buy-now-footer.html', styleUrls: ['./buy-now-footer.scss'] })
export class BuyNowFooterComponent {
  user: any;
  isInternalUser: boolean;
  offerTimer: any = {};
  @Output() buyNow: EventEmitter<any> = new EventEmitter();
  @Input('regimen') regimen: any;
  experiments: any[] = [];
  @Input() plan: string = '';

  constructor(private dom: DomSanitizer,
    private window: WindowRefService,
    private conn: ConnectionService,
    private router: Router,
    private appBridge: AppWebBridgeService,
    private appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<void> {
    this.isInternalUser = this.conn.isInternalUser();
    this.user = this.conn.getActingUser();
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach(async (experiment: any): Promise<any> => {
      if (experiment.key === 'offer_timer_throughout_checkout') {
        const timeLeft: any = (new Date(Number(experiment.variant.time)).getTime() - new Date().getTime());
        if (timeLeft > 0) {
          this.offerTimer = { endTime: timeLeft,
            title: experiment.variant.title[this.user.get('languagePreference') || this.appConfig.Shared.Languages.EN] };
        }
      }
    });
  }

  get discountSpinnerFillColor(): string {
    switch (this.plan) {
      case 'regular': return '#76539A';
      case 'advanced': return '#D1903E';
      case 'pro': return '#4973B4';
      default: return '#D66736';
    }
  }
}
