<div class="tw-block tw-border-spacing-0 tw-w-full tw-h-full tw-overflow-hidden tw-font-body">
    <div class="tw-flex tw-flex-col tw-flex-nowrap tw-h-full">
        <div class="tw-w-full tw-flex-initial">
            <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
                <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
                     (click)="menuAction()">
                    <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50"
                             [iconName]="'arrow_back'"></cs-icon>
                </div>
                <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white"
                      i18n="@@instantSkinAnalysis">Analysis reports</span>
            </div>
        </div>
        <div class="tw-w-full tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto tw-relative">
            <div class="tw-w-[75%] tw-pt-18 tw-px-5 tw-pb-0 tw-text-center tw-text-[rgba(0,0,0,0.8)] tw-text-400 tw-font-normal tw-bg-[url('/assets/images/not-found.png')] tw-bg-no-repeat tw-bg-[50%_0px] tw-bg-[length:60px] tw-absolute tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2"
                 *ngIf="!loading && !instantCheckups.length"
                 i18n="@@noInstantSkinAnalysisTaken"> No Skin Analysis Taken </div>
            <div *ngIf="!loading && instantCheckups.length">
                <div class="tw-px-5 tw-py-4 tw-relative tw-overflow-hidden tw-z-0"
                     rippleEffect="rippleEffect"
                     *ngFor="let item of instantCheckups; let i = index"
                     (callback)="routeTo(item.objectId)"
                     [ngClass]="{ 'bdr-btm tw-border-solid tw-border-b tw-border-gray-200 ': i !== instantCheckups.length - 1 }">
                    <span class="tw-text-left tw-capitalize"
                          *ngIf="item.type">
                        <translate-string [key]="item.type"></translate-string>
                    </span>
                    <span class="tw-absolute tw-left-auto tw-right-0 tw-bottom-auto tw-top-1/2 -tw-translate-y-1/2 tw-text-gray-400 tw-text-100"
                          style="width: 90px">{{item.createdAt | date: 'd MMM hh:mm a'}}</span>
                </div>
            </div>
            <loading class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
                     *ngIf="loading"></loading>
        </div>
    </div>
</div>