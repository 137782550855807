import { Directive, ElementRef, HostListener } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';

@Directive({
  selector: '[appPinchZoom]',
})
export class PinchZoomDirective {
  scaling: boolean = false;

  constructor(private elementRef: ElementRef<HTMLImageElement>,
    private eventLogger: EventLoggerService) {}

  @HostListener('touchstart', ['$event'])
  touchStart(event: TouchEvent): void {
    if (event.touches.length !== 2) return;
    this.scaling = true;
  }

  @HostListener('touchmove', ['$event'])
  touchMove(event: TouchEvent): void {
    if (!this.scaling) return;
    this.elementRef.nativeElement.style.transform = 'scale(1.3)';
    this.elementRef.nativeElement.style.transition = 'transform 1s';
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'image-pinch-zoom' }));
  }

  @HostListener('touchend', ['$event'])
  touchEnd(event: TouchEvent): void {
    this.elementRef.nativeElement.style.transform = 'scale(1)';
    this.elementRef.nativeElement.style.transition = 'unset';
    this.scaling = false;
  }
}
