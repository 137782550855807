import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { AppConfig } from '../../app/app.config';

@Component({ selector: 'video-player', templateUrl: './video-player.html', styleUrls: ['./video-player.scss'] })
export class VideoPlayerComponent {
  @Input('src') src: string;
  @Input('muteVideo') muteVideo: boolean = false;
  @Input('style') style: { width?: number, height?: number } = {};
  @Input('poster') poster: string;
  @Input('autoplay') autoplay: boolean = false;
  @Input('cssClass') cssClass: string;
  @Input('loop') loop: boolean = true;
  @Input('hideAudioControl') hideAudioControl: boolean;
  @Output('onPlay') onPlay: EventEmitter<any> = new EventEmitter<any>();
  @Output('onVideoEnd') onVideoEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output('onLoad') onLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output('onDoubleClick') onDoubleClick: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('videoElement', { static: true }) videoElement: ElementRef;
  loading: boolean = true;
  isPaused: boolean = true;
  isPlaying: boolean = false;
  constructor(public appConfig: AppConfig) {}

  ngOnInit(): void {
    if (this.autoplay) this.isPaused = false;
    if (this.muteVideo) this.muteVideoEvent();
  }

  @HostListener('window:blur')
  handleWindowBlur(): void {
    this.videoElement.nativeElement.pause();
    this.isPaused = true;
  }

  videoTogglePlayPause(): void {
    const videoElement = this.videoElement.nativeElement;
    if (this.isPlaying) {
      videoElement.pause();
      this.isPlaying = false;
    } else {
      const videoPlay = videoElement.play();
      if (videoPlay !== undefined) {
        videoPlay.then(():void => {
          this.isPlaying = true;
        });
      }
    }
  }

  onVideoPlay(): void {
    this.onPlay.emit();
    this.isPaused = false;
  }

  onVideoPause(): void {
    this.isPaused = true;
  }

  videoEnd(): void {
    this.onVideoEnd.emit();
  }

  doubleClick(): void {
    this.onDoubleClick.emit();
  }

  muteVideoEvent(): void {
    this.videoElement.nativeElement.muted = true;
  }

  muteToggle(event: any): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
    this.videoElement.nativeElement.muted = !this.videoElement.nativeElement.muted;
  }
}
