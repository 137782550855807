import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { InputModule } from '@components/inputs';
import { PopUpModalComponent } from './pop-up-modal.component';
import { ButtonModule } from '../button';
import { TranslateStringsModule } from '../translate-string';

@NgModule({
  imports: [CommonModule, RouterModule, ButtonModule, TranslateStringsModule, InputModule],
  declarations: [PopUpModalComponent],
  exports: [PopUpModalComponent],
})
export class PopUpModalModule {}
