import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading';
import { ToolbarModule } from '@components/toolbar';
import { VideoComponent } from './video.component';

@NgModule({
  imports: [CommonModule, LoadingModule, ToolbarModule],
  declarations: [VideoComponent],
  exports: [],
})
export class VideoModule {}
