import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CsIconModule } from '@components/icon';
import { ProductCardComponent } from './components/product-card/product-card.component';

@NgModule({
  imports: [
    CommonModule,
    CsIconModule,
  ],
  declarations: [
    ProductCardComponent,
  ],
  exports: [
    ProductCardComponent,
  ],
})
export class ShopModule { }
