import { Shared } from './app.constants';

class AppConfig {
  static Shared: Shared = new Shared();

  Shared: Shared;
  static InstantCheckupResult: any = {
    acneOrWhiteheads_None: 'Your acne was not detected in the photo. If your acne is on the cheeks or on the side of your '
    + 'face, please take photo of that side of your face. You can also try again in sunlight to improve detection. Or chat '
    + 'below to get recommendations for treating your acne.',
    acneOrWhiteheads_Comedones: 'Comedones: There are oily regions on skin causing comedones. The excess oil production by your skin\'s '
    + 'sebaceous glands is causing clogged pores. And they could be making your skin feel uneven, bumpy or rough.',
    acneOrWhiteheads_NotComedones_PIMPLE: 'Pimples: Your pimples (inflammatory-acne) could be caused by many reasons like hormonal'
    + ' issues, excessive oil production in your skin, bad diet, bad skin hygiene or even pollution.',
    acneOrWhiteheads_DarkSpots: 'Dark spots: There are also some dark spots left behind after pimples. This usually happens due to sun '
    + 'exposure. After you get your acne under control, I can help you clear the dark spots. To stop your pimples from causing new '
    + 'dark spots, it is better to use a non-comedogenic sunscreen.',
    acneOrWhiteheads_AcneScars: 'Scars: You are also having scars due to pimples. After your pimples are under control, '
    + 'I will help you cure the scars.',
    acneOrWhiteheads_All: 'Don\'t worry, I will help you cure your acne and protect yourself from future breakouts. It is good '
    + 'to manage acne quickly after they start, so that they don\'t lead to marks or scars. When you chat, I will ask some questions'
    + ' to understand more and make recommendations to cure it.',
    darkSpots_None: 'Your dark spots were not detected in the photo. If your dark spots are on the cheeks or on the side of your '
    + 'face, please take photo of that side of your face. You can also try again in sunlight to improve detection.  Or chat to below '
    + 'to find out cream for your dark spot removal.',
    darkSpots_DarkSpots: 'Dark spots: Your dark spots are caused when pimples go away and leave behind marks due to excessive '
    + 'pigmentation in sun exposure. I will help you clear the dark spots with a repair cream. Also to stop any new pimples from '
    + 'causing new dark spots, it is better to use a non-comedogenic sunscreen.',
    darkSpots_Pimple_OR_Comodones: 'Acne: You are also getting new pimples or have oily patches on your face. You have to use a '
    + 'face-wash that help you to reduce breakouts, so that new dark spots don\'t happen.',
    scars_None: 'Your scars were not detected in the photo. If your scars are on the cheeks or on the side of your face, please '
    + 'take photo of that side of your face. You can also try again in sunlight to improve detection. Or just chat to find what creams '
    + 'work for your scar removal.',
    scars_AcneScars: 'Acne Scars: Your acne scars happen if you had large or deep acne in the past. Note that acne scar removal usually '
    + 'takes a long time. Chat below and I will suggest a scar removal cream. If you are interested in knowing more about any procedures '
    + 'for quick scar reduction or removal, let me know.',
    scras_NotAcneScars_OtherScars: 'Other scars: Scars like chicken pox scars or injury scars, are caused due to external factors '
    + 'damaging the skin. I can recommend a scar removal cream, but usually healing scars takes a long time.',
    scras_Pimple_OR_Comedones: 'You are also getting new pimples or have oily patches on your face. You have to use a face-wash '
    + 'that help you to reduce breakouts, so that new marks and acne scars don\'t happen.',
    skintone_DarkSpots: 'Dark spots: Due to dark-spots caused in sun exposure, your skin-tone is getting affected.',
    skintone_Comedones_Or_Pimple: 'Acne: You are also getting new pimples or have oily patches on your face. This will make your'
    + ' skin look bumpy or uneven. You have to use a face-wash that helps you improve your skin.',
    skintone_All: 'Thank you for taking instant skin check. I will recommend the skin-care routine for you to improve your skin '
    + 'and fix the skin-dullness, tanning, pigmentation or any uneven skin-tone.',
    nothing_Detected: 'We have not detected any issues in the photo. If you have issues on the cheeks or on the side of your face, '
    + 'please take photo of that side of your face. You can also try again in sunlight to improve detection.',
    nothing_Comedones: 'Comedones: You have oily regions on your skin causing comedones. The excess oil production by your skin\'s '
    + 'sebaceous glands is causing clogged pores. And they may make your skin feel uneven, bumpy or rough.',
    nothing_Pimple: 'Pimples: Your pimples (inflammatory-acne) could be caused by many reasons like hormonal issues, excessive oil '
    + 'production in your skin, bad diet, bad skin hygiene or even pollution.',
    nothing_DarkSpots: 'Dark spots: Your dark spots are caused when pimples go away and leave behind marks due to excessive pigmentation '
    + 'in those places, usually due to sun exposure.',
    nothing_AcneScars: 'Acne Scars: Your acne scars happen if you had large or deep acne in the past. Note that acne scar removal '
    + 'usually takes a long time.',
    nothing_OtherScars: 'Other scars: Scars like chicken pox scars or injury scars, are caused due to external factors damaging the skin.',
    chatForSolution: 'To check your skin again in bright light, click \'Check again\' button. Or click on \'Chat for solution\' '
    + 'button to proceed.',
    nothing_Summary: 'Detected the following issues:',
  };
  static Dialog: any = {
    CONFIRMATION: 'confirmation',
    MULTI_BUTTON: 'multi_button',
    RADIO_BUTTON: 'radio_button',
    MULTI_OPTION_SINGLE_SELECT: 'multi_option_single_select',
    ALERT: 'alert',
  };
  static Toolbar: any = {
    ICONS: {
      DELETE: 'delete',
      LOCK: 'lock',
      EDIT: 'edit',
      SAVE: 'save',
      CALL: 'CALL',
      INFO: 'INFO',
      REFRESH: 'refresh',
      NOTIFICATION: 'NOTIFICATION',
      CART: 'CART',
    },
  };

  static acneConcernType: any = {
    ACNE: 'ACNE',
    ACNE_OR_PIMPLES: 'ACNE_OR_PIMPLES',
    INFLAMMATORY_ACNE: 'INFLAMMATORY_ACNE',
    COMEDONES: 'COMEDONES',
  };

  static TagCacheTableMapping: {
    Recommended: string;
    ALL_SHOP: string;
    MT_HAIR_FALL: string;
    MT_DANDRUFF: string;
    MT_SMOKER: string;
    ReOrder: string;
    MT_BODY: string;
    SHOP_SECTION: string
  } = {
      Recommended: 'CureSkin/recommendedProducts',
      ReOrder: 'CureSkin/reorderProducts',
      ALL_SHOP: 'CureSkin/allShopProducts',
      SHOP_SECTION: 'CureSkin/shopSection',
      MT_BODY: 'CureSkin/bodyProducts',
      MT_HAIR_FALL: 'CureSkin/hairFallProducts',
      MT_DANDRUFF: 'CureSkin/dandruffProducts',
      MT_SMOKER: 'CureSkin/lipProducts',
    };
  Toolbar: any;
  Dialog: any;
  InstantCheckupResult: any;
  acneConcernType: any;
}

AppConfig.Shared.initialize();

export { AppConfig };
