import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'waiting-for-photo',
  templateUrl: './waiting-for-photo.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class WaitingForPhotoComponent implements OnInit {
  @Input() followUp: any;
  @Output() startFollowUp: EventEmitter<any> = new EventEmitter();
  constructor(
    public appConfig: AppConfig,
  ) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.startFollowUp.emit('TAKE_FOLLOWUP_BANNER_CLICKED');
  }
}
