<div class="hbox">
  <loading class="pos-fix vh-center" *ngIf="!addOnProduct"></loading>
  <div class="flex-box" *ngIf="addOnProduct">
    <div class="flex-heading pT30">
      <div class="fontL4 bold text-center" i18n="@@recommendedForYou">
        Recommended for you
      </div>
      <div class="separator"></div>
    </div>
    <div class="flex-content">
      <div class="fontL2 text-cap clr-secondary text-center" i18n="@@thankYouForPlacingRegimenOrder">
        Thank you for placing your treatment kit order.
      </div>
      <div class="product" (click)="openArticleOfAddon()">
        <div class="img">
          <img [src]='addOnProduct.get("productUnboxedImage")[0]' />
        </div>
        <div class="content">
          <div class="fontL2 bold">{{addOnProduct.get('title')}}</div>
          <div class="mT10 bold">
            <del class="fontS1">₹<span>{{addOnProduct.get('mrp')}}</span></del><span class="clr-green fontL1 pL5">₹{{addOnProduct.get('price')}}</span>
          </div>
          <div class="clr-secondary mT10 fontS1">
            {{note || addOnProduct.get('description') ||
      addOnProduct.get('purposeDescription')}}
          </div>
        </div>
      </div>
    </div>
    <div class="flex-footer">
      <cs-button [class]='"primary w100"' (callback)="buyProduct(true)"><cs-button-text i18n="@@buyNow">Buy Now</cs-button-text></cs-button><cs-button [class]='"primary mT10 only-text w100 clr-primary"' (callback)="buy(false)"><cs-button-text i18n="@@skip">Skip</cs-button-text></cs-button>
    </div>
  </div>
</div>