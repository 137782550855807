<div style="display: flex; position: relative; width: 100%; height: 100%">
    <canvas style="height: 100%;width: 100%;margin: 0 auto;position: relative;z-index: 1;"
            [appHairAiCanvas]="data"
            (topPositionForCaption)="topPositionForCaption($event)"
            (angleOfHairfall)="angleOfHairfall($event)"
            (hairAiLoaded)="hairAiLoaded.emit(true); processAiResult()"></canvas>
    <div class="tw-absolute tw-left-0 tw-flex tw-flex-col tw-items-start tw-gap-3 tw-px-6 tw-z-10"
         *ngIf="severityLevel"
         [ngStyle]="{ top: '66%' }">
        <div class="tw-flex tw-items-center tw-rounded-full tw-bg-white tw-px-5 tw-py-2">
            <p class="tw-font-body tw-text-300">{{ severityLevel }}</p>
        </div>
    </div>
</div>