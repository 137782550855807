<div class="videoPlayer" [ngClass]="cssClass" [style.height]='style.height+"px"'>
  <loading class="pos-abs vh-center" *ngIf="!isPaused && loading" [scale]="1" [stroke]='"#000"'></loading>
  <div dblClick="dblClick" (onClick)="videoTogglePlayPause()" (onDoubleClick)="doubleClick()">
  <video #videoElement="" [src]="src" (canplaythrough)="loading = false" (play)="onVideoPlay()" (pause)="onVideoPause()" (ended)="videoEnd()" (loadeddata)="onLoaded?.emit()" [poster]="poster" [autoplay]="autoplay" [loop]="loop" disablepictureinpicture="disablepictureinpicture" controlslist="nodownload nofullscreen noremoteplayback" playsinline="playsinline" [style.height]='style.height+"px"' [preload]='"none"'></video>
  <div class="play-button anim-fadeIn" *ngIf="isPaused || (autoplay && loading)">
    <img src="/assets/images/play.png" />
  </div>
  </div>
  <div class="mute anim-fadeIn" *ngIf="!hideAudioControl" (click)="muteToggle($event)">
  <em class="cs-icon-volume-off anim-fadeIn" *ngIf="videoElement.muted"></em><em class="cs-icon-volume-on anim-fadeIn" *ngIf="!videoElement.muted"></em>
  </div>
</div>
