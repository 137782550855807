<div class="font-noto tw-flex tw-items-center tw-relative tw-px-2 tw-py-4" style="height: 300px">
  <div class="tw-rounded-full tw-absolute tw-mb-20 tw-right-0 tw-left-0 tw-m-auto tw-bg-yellow-100 tw-w-[120px] tw-z-[100] tw-top-[-60px] tw-h-[120px]">
    <img class="tw-rounded-full tw-absolute tw-right-0 tw-left-0 tw-m-auto tw-top-0 tw-bottom-0 tw-w-[90px] tw-h-[90px]" src="/assets/images/cureskin-cash-icon.png" (click)="handleClick()" /><img class="tw-absolute tw-right-0 tw-left-0 tw-m-auto tw-top-0 tw-bottom-0 tw-w-[90px] tw-h-[90px]" src="/assets/images/star-animation.gif" />
  </div>
  <div class="tw-absolute tw-p-1 tw-rounded-full tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-[15px] tw-right-[10px] close-sheet" style="width: 35px; height: 35px" (click)="closeBottomSheet()">
    <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
  </div>
  <div class="tw-w-full tw-bg-white rounded-lg tw-p-2 tw-flex tw-flex-col tw-pt-10 tw-text-center" (click)="handleClick()">
    <div *ngIf="data?.data">
      <h2 class="tw-text-lg tw-font-semibold tw-text-700" i18n="@@haveCureskinCash">
        You have Cureskin cash worth ₹{{data?.data}}
      </h2>
      <p class="tw-my-2 tw-text-600 tw-text-gray-500" i18n="@@appliedOnPayment">
        This will be applied on payment
      </p>
    </div>
    <cs-button class="tw-my-2" [class]="'w100 primary btn btn-padding tw-px-6 btn-float'" (click)="handleClick()"><cs-button-text><span class="tw-font-body tw-tracking-normal" i18n="@@continueShopping">Continue Shopping</span><span>&nbsp; ></span></cs-button-text></cs-button>
  </div>
</div>