<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="tw-flex tw-flex-col tw-h-full tw-bg-gray-100" [ngClass]="{ 'loading': inProcess } ">
  <div class="tw-flex-1 tw-overflow-y-auto tw-flex tw-flex-col tw-pt-20 tw-pb-20">
  <div class="operator" *ngIf="viewType === ViewTypes.FirstPage || viewType === ViewTypes.NextPage">
    <img src="/assets/images/bot.gif" />
    <div class="desc" *ngIf="viewType === ViewTypes.FirstPage">
    <div class="fontS1" i18n="@@hiImYourCureskinAdvisor">
      Hi {{user?.get('PatientName')?.split(' ')[0]}}, I'm your CureSkin
      Advisor
    </div>
    <div class="fw-600 fontL1" i18n="@@howMayIHelpYou">
      How may I help you?
    </div>
    </div>
    <div class="desc" *ngIf="viewType === ViewTypes.NextPage">
    <div class="fontS1" i18n="@@HiHereAreTheDetails">
      Hi {{user?.get('PatientName')?.split(' ')[0]}}, Here are the details
    </div>
    <div class="title fontL1">
      {{question?.responseTitle || question?.question}}
    </div>
    </div>
  </div>
  <section class="queries" *ngIf="viewType === ViewTypes.FirstPage && (recentSupportTicketList$ | async)?.length" [scale]="0.8">
    <div class="header fontL1 fw-500" i18n="@@recentSupportTickets">
    Recent Queries
    </div>
    <div class="of-x-auto">
    <div class="question-holder default">
      <div class="question recent-queries" *ngFor="let ticket of (recentSupportTicketList$ | async)" (click)="viewTicket(ticket)" [ngClass]="{ 'highlight green': ticket.hasUnreadMessage }">
      <div class="timestamp">
        {{ ticket.createdAt | date: 'dd MMM YYY, HH:MM a' }}
      </div>
      <div class="mT10 fontR bdr-btm pB10">
        {{ truncateString(ticket.title ||
    ticket.supportQuestion?.questionLanguageString[ticket.languagePreference],
    20 )}}
      </div>
      <div class="mT10">
        <div class="status tw-uppercase" i18n="@@open">Open</div>
        <i class="cs-icon-arrow"></i>
      </div>
      </div>
    </div>
    </div>
  </section>
  <div class="content" [ngClass]="{ 'bg-white': inProcess }">
    <support-component-selection [question]="question" [questions]="questions" [loading]="inProcess"></support-component-selection>
  </div>
  <div class="others" *ngIf="viewType === ViewTypes.FirstPage && queriesCount && !user?.isPaid()">
    <div>
    <span class="underline" i18n="@@allQueries" (click)="viewAllQuery()">All Queries</span>
    </div>
  </div>
  <div class="others" *ngIf="user?.isPaid()">
    <div>
    <span class="underline" routerLink="/faq" i18n="@@faqs">FAQs</span>
    </div>
  </div>
  <div class="feedback-section" *ngIf="question.outputComponent !== appConfig.Shared.Support.component.SELECT_SUB_QUERY">
    <div class="feedback">
    <div class="flex-grow" i18n="@@wasThisHelpfull">Was this helpful?</div>
    <div class="icons">
      <em (click)="updateFeedback(true)" [ngClass]="{ 'cs-icon-like-filled anim-like': isHelpfull, 'cs-icon-like': !isHelpfull }"></em><em (click)="updateFeedback(false)" [ngClass]="{ 'cs-icon-dislike-filled anim-like': isHelpfull === false, 'cs-icon-dislike': isHelpfull !== false }"></em>
    </div>
    </div>
    <a class="feedback" *ngIf="user?.get('orderState') !== appConfig.Shared.User.OrderState.DELIVERED" (click)="showCallPopup = true">
    <div class="flex-grow" i18n="@@stillNotResolved">
      Still not resolved?
    </div>
    <div class="icons">
      <em class="cs-icon-phone-in-talk font18"></em><span class="pL10 fontS1" i18n="@@callUs">Call Us</span>
    </div>
    </a>
  </div>
  <div class="cam-icon" (click)="cameraClick()">
    <div class="cs-icon-camera-filled"></div>
  </div>
  </div>
  <div class="floating-nav tw-flex-none tw tw-h-20 tw-flex tw-flex-row tw-border-none">
  <div class="festive-alert" *ngIf="festivalDelayAlertEnabled">
    <div class="fontS3 fw-700" i18n="@@expectDelayInResponse">
    Please expect delay in response due to ongoing festivities.
    </div>
    <div class="fontS4" i18n="@@weWillGetBackToEnquiries">
    We will get back to your enquiries as soon as we can.
    </div>
  </div>
  <div class="navHolder tw-h-20">
    <div class="tw-h-full tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" rippleEffect="rippleEffect" (callback)="onTabChange(0)">
    <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-black tw-rounded-br tw-rounded-bl tw-bg-white"></div>
    <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
      <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" [iconName]='"home"'></cs-icon>
      <div class="tw-font-body tw-text-200 tw-text-gray-500" i18n="@@home">
      Home
      </div>
    </div>
    </div>
    <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" rippleEffect="rippleEffect">
    <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-black tw-rounded-br tw-rounded-bl tw-bg-purple-400"></div>
    <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
      <cs-icon class="tw-h-8 tw-w-8 tw-text-purple-400" [iconName]='"help_solid"'></cs-icon>
      <div class="tw-font-body tw-text-200 tw-text-purple-400" i18n="@@help">Help</div>
    </div>
    </div>
    <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" *ngIf="userStatus.PAID" rippleEffect="rippleEffect" (callback)="onTabChange(4);shopClick()">
    <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-white tw-bg-white"></div>
    <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
      <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" [iconName]='"storefront"'></cs-icon>
      <div class="tw-font-body tw-text-200 tw-text-gray-500" i18n="@@shop">
      Shop
      </div>
    </div>
    </div>
    <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" *ngIf="!userStatus.PAID" rippleEffect="rippleEffect" (callback)="cameraClickHome()">
    <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-white"></div>
    <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
      <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" [iconName]='"photo_camera"'></cs-icon>
      <div class="tw-font-body tw-text-200 tw-text-gray-500" i18n="@@photo">Photo</div>
    </div>
    </div>
    <div class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" rippleEffect="rippleEffect" (callback)="onTabChange(2)">
    <div class="tw-flex-none tw-w-11 tw-h-1 tw-bg-black tw-rounded-br tw-rounded-bl tw-bg-white"></div>
    <div class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
      <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" [iconName]='"sanitizer"'></cs-icon>
      <div class="tw-font-body tw-text-200 tw-text-gray-500" i18n="@@treatment">
      Treatment
      </div>
    </div>
    </div>
    <div *ngIf="!exploreSectionExperiment" class="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-3" rippleEffect="rippleEffect" (callback)="onTabChange(3);exploreClick()">
    <div *ngIf="!exploreSectionExperiment" class="tw-flex-none tw-w-11 tw-h-1 tw-bg-black tw-rounded-br tw-rounded-bl tw-bg-white"></div>
    <div *ngIf="!exploreSectionExperiment" class="tw-flex-none tw-flex tw-flex-col tw-items-center tw-gap-0.5">
      <cs-icon class="tw-h-8 tw-w-8 tw-text-gray-500" [iconName]='"manage_search"'></cs-icon>
      <div class="tw-font-body tw-text-200 tw-text-gray-500" i18n="@@explore">Explore</div>
    </div>
    </div>
  </div>
  </div>
</div>
<call-us [show]="showCallPopup" (close)="showCallPopup = false" (onCallNeeded)="onCallNeeded()"></call-us><pop-up *ngIf="showChatSupportPrompt" [show]="true" (close)="showChatSupportPrompt = false" [class]="'bdr-rad-5'">
  <div class="p20">
  <div class="fontL2 mB5 fw-600" i18n="@@sorryToHearThat">
    Sorry to hear that 😞
  </div>
  <div class="fontR" i18n="@@wouldYouLikeToOpenTicket">
    Would you like to chat with your skin advisor?
  </div>
  <cs-button [class]="'secondary btn-xs w100 mT25'" (callback)="showChatSupportPrompt = false"><cs-button-text i18n="@@noThanksNew">No, Thanks</cs-button-text></cs-button><cs-button [class]="'primary btn-xs w100 mT15'" (callback)="createTicket()" [loading]="ticketInProcess"><cs-button-text i18n="@@yes">Yes</cs-button-text></cs-button>
  </div>
</pop-up>