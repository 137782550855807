<loading class="tw-flex tw-justify-center tw-h-full" *ngIf="loading"></loading><!-- Fixed back button-->
<div class="tw-fixed tw-z-10 tw-top-3 tw-left-6 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="tw-flex tw-flex-col tw-h-full tw-bg-gray-100" *ngIf="!loading">
  <!-- Regimen Hero-->
  <section class="tw-flex-1 tw-flex tw-flex-col tw-gap-4 tw-overflow-y-auto tw-pt-18 tw-mb-4">
    <product-item class="tw-border-b tw-border-gray-200" *ngFor="let product of regimen.products; let i = index;" (openCarousal)="openCarousalPopUp($event)" [user]="user" [userLanguage]="userLanguage" [product]="product" [regimen]="regimen" [activeExperiments]="activeExperiments" [itemIndex]="i" (playVideo)="playVideo($event)"></product-item>
  </section>
  <!--  Image carousal pop up -->
  <div *ngIf="showProductCarousalPopUp" class="tw-fixed tw-w-full -tw-translate-y-1/2 tw-top-1/2 tw-z-30 tw-flex tw-justify-center tw-items-center tw-rounded-xl">
    <swiper #swiperCardsBanner="" class="" [config]="carouselConfig">
      <ng-container *ngFor="let product of regimen?.products; let i = index">
          <div *swiperSlide class="tw-w-full tw-bg-white tw-rounded-xl tw-py-2 tw-h-[65vh] tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3">
            <div class="tw-w-11/12 tw-mx-auto tw-flex tw-justify-center tw-items-center">
              <img class="tw-w-40 tw-min-h-[50vh] tw-h-[50vh] tw-object-contain" [src]="product.product.rebrandedImageWithoutBackground[0]" />

            </div>
            <h1 class="tw-font-body tw-font-bold tw-text-500 tw-text-center tw-px-2 tw-h-2">{{ product.product.title }}</h1>
          </div>
      </ng-container>
    </swiper>
  </div>
  <div *ngIf="showProductCarousalPopUp" class="tw-fixed tw-inset-0 tw-z-20 tw-opacity-30" (click)="closeCarousalPopUp()" style="background-color: black;"></div>
  <div class="tw-flex-none" *ngIf="!loading && !regimen.active && !regimen.orderPlaced">
    <buy-now-footer [regimen]="regimen" (buyNow)="buyRegimen()"></buy-now-footer>
  </div>
</div>
<div class="video-container tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-hidden tw-z-50">
  <div class="tw-bg-black tw-absolute tw-inset-0"></div>
  <video class="tw-h-full tw-w-full tw-object-contain tw-max-w-full tw-max-h-full tw-relative tw-z-10" #videoPlayer controls="controls"></video><button class="tw-absolute tw-top-2 tw-right-1 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-end tw-text-white tw-font-bold tw-text-xl tw-z-10 close-video-button" (click)="closeVideo()">
    <svg class="tw-h-6 tw-w-6" fill="currentColor" viewBox="0 0 24 24">
      <path d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
    </svg>
  </button>
</div>
