<section id="rebrandedTestimonialsSection" class="tw-bg-white font-noto tw-my-2 tw-py-4">
  <div class="tw-flex tw-items-center tw-justify-center tw-mb-4">
    <div class="tw-font-semibold tw-text-600 tw-text-gray-800 tw-text-center" i18n="@@realDoctorProvenResults">
      Real doctors, proven results.
    </div>
  </div>
  <swiper class="tw-bg-white tw-w-[79%] tw-mx-auto tw-overflow-visible" #swiper="" [config]="swiperConfig">
    <ng-container *ngFor="let testimonial of testimonials; let i = index">
      <div class="tw-bg-white tw-mx-auto tw-shadow-[0px_8px_30px_0px_#0000001A] tw-rounded-4" *swiperSlide>
        <div id="testimonial-header" class="tw-relative tw-w-full">
          <img class="tw-rounded-tl-4 tw-rounded-tr-4" [src]="testimonial['testimonialImg']" alt="testimonial_image"/>
          <p class="tw-absolute tw-top-2 tw-left-3.5 tw-text-white tw-text-200 tw-font-bold" i18n="@@before">Before</p>
          <p class="tw-absolute tw-top-2 tw-right-3.5 tw-text-white tw-text-200 tw-font-bold" i18n="@@after">After</p>
        </div>
        <div id="testimonial-content" class="tw-pt-4 tw-pr-5 tw-pb-4 tw-pl-5">
          <div class="tw-flex tw-items-center tw-gap-2">
            <img [src]="testimonial['userImg']" class="tw-w-8 tw-h-8 tw-rounded-full" alt="user_profile"/>
            <span class="tw-flex tw-flex-wrap tw-items-center">
              <p class="tw-text-200 tw-text-gray-800 tw-font-bold"> {{testimonial['name']}} </p>&#44;&nbsp;<p class="tw-text-200 tw-italic">{{testimonial['location']}} {{testimonial['occupation']}}</p>
            </span>
          </div>
          <p class="tw-text-200 tw-text-gray-500 tw-mt-1 tw-py-2.5 tw-break-words">{{testimonial['testimonial']}}</p>
          <p class="tw-text-200 tw-font-500 tw-text-gray-800 tw-mt-2.5" i18n="@@effectivelyReduced">Effectively Reduced:</p>
          <div *ngIf="testimonial.tags.length !== 3" >
            <div class="tw-flex tw-flex-wrap tw-gap-y-2 tw-gap-x-1.5 tw-mt-2 tw-text-gray-800">
              <ng-container *ngFor="let tag of testimonial.tags; let i = index">
                <p class="tw-bg-teal-100 tw-text-200 tw-w-max tw-px-3 tw-py-1 tw-rounded-5 tw-text-gray-800">{{tag}}</p>
              </ng-container>
            </div>
          </div>
          <div *ngIf="testimonial.tags.length === 3">
            <div class="tw-flex tw-flex-wrap tw-gap-y-2 tw-gap-x-1.5 tw-mt-2 tw-text-gray-800">
              <p class="tw-bg-teal-100 tw-text-200 tw-w-max tw-px-3 tw-py-1 tw-rounded-5 tw-text-gray-800">{{testimonial.tags[0]}}</p>
              <p class="tw-bg-teal-100 tw-text-200 tw-w-max tw-px-3 tw-py-1 tw-rounded-5 tw-text-gray-800">{{testimonial.tags[1]}}</p>
            </div>
            <p class="tw-bg-teal-100 tw-text-200 tw-w-max tw-px-3 tw-py-1 tw-rounded-5 tw-text-gray-800 tw-mt-2">{{testimonial.tags[2]}}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </swiper>
</section>
