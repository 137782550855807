import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { PrepaidDiscountSheetComponent } from './prepaid-discount-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
  ],
  declarations: [PrepaidDiscountSheetComponent],
  exports: [PrepaidDiscountSheetComponent],
})
export class PrepaidDiscountSheetModule { }
