import { Injectable } from '@angular/core';
import * as EXIF from 'exif-js';
import confetti from 'canvas-confetti';
import { WindowRefService } from '../window-ref-service';
import { AppConfig } from '../../app/app.config';

@Injectable()
export class CommonUtilityHelper {
  constructor(private window: WindowRefService, private appConfig: AppConfig) {}

  convertImageToBase64(image: any): Promise<any> {
    const canvas = this.window.nativeWindow.document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    return new Promise((resolve: Function, reject: Function): any => {
      EXIF.getData(image, (): void => {
        const orientation = EXIF.getTag(image, 'Orientation');
        if (orientation === 6 || orientation === 5) {
          canvas.width = image.height;
          canvas.height = image.width;
          ctx.translate(canvas.width, 0);
          ctx.rotate(90 * (Math.PI / 180));
          ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.height, canvas.width);
        } else if (orientation === 8 || orientation === 7) {
          canvas.width = image.height;
          canvas.height = image.width;
          ctx.rotate(-0.5 * Math.PI);
          ctx.translate(-canvas.height, 0);
          ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.height, canvas.width);
        } else {
          canvas.height = image.height;
          canvas.width = image.width;
          ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
        }
        return resolve(canvas.toDataURL());
      });
    });
  }

  rotateCanvasBasedOnOrientation(orientation: any, ctx: any, canvasElem: any): void {
    const canvas = canvasElem.nativeElement || canvasElem;
    switch (orientation) {
      case 6: {
        ctx.translate(canvas.width, 0);
        ctx.rotate(90 * (Math.PI / 180));
        break;
      }
      case 8: {
        ctx.rotate(-0.5 * Math.PI);
        ctx.translate(-canvas.height, 0);
        break;
      }
      case 3: {
        ctx.rotate(Math.PI);
        ctx.translate(-canvas.width, -canvas.height);
        break;
      }
      case 5: {
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      }
      case 7: {
        ctx.transform(0, -1, -1, 0, canvas.width, canvas.height);
        break;
      }
      case 4: {
        ctx.transform(1, 0, 0, -1, 0, canvas.height);
        break;
      }
      case 2: {
        ctx.transform(-1, 0, 0, 1, canvas.width, 0);
        break;
      }
      default: break;
    }
  }

  chromeVersion(): any {
    let version;
    const split = this.window.nativeWindow.navigator.userAgent.split(' ');
    split.forEach((e: any): void => {
      if (e.includes('Chrome')) {
        version = (e.split('/')[1]).split('.')[0];
      }
    });
    return Number(version);
  }

  safariVersion(): any {
    let version;
    const split = this.window.nativeWindow.navigator.userAgent.split(' ');
    split.forEach((e: any): void => {
      if (e.includes('Safari') || e.includes('AppleWebKit')) {
        version = (e.split('/')[1]).split('.')[0];
      }
    });
    return Number(version);
  }

  rotationNeeded(): boolean {
    const chromeVersion = this.chromeVersion();
    const safariVersion = this.safariVersion();
    if (chromeVersion && chromeVersion >= 81) return false;
    if (safariVersion && safariVersion >= 605) return false;
    return true;
  }

  /**
   * Checks the activeFrom date in regimen and decides whether the regimen has reached the active time or not.
   * @param regimen
   * @returns {boolean} - denotes whether the regimen is ready to be visible to user.
   */
  isRegimenBeingPrepared(regimen: any): boolean {
    if (!regimen.objectId || regimen.expired || !regimen.activeFrom || regimen.active || regimen.orderPlaced) return false;
    const acceptableDifferenceInSec = 5;
    const currTime: any = new Date();
    const regimenShowTime: any = new Date(regimen.activeFrom.iso);
    return !(((regimenShowTime - currTime) / 1000) <= acceptableDifferenceInSec);
  }

  isFileSizeLesserThanMaxSize(sizeInBytes: number, maxSizeInMB: number): boolean {
    const fileSizeInMB = (sizeInBytes / 1024) / 1024;
    return fileSizeInMB <= maxSizeInMB;
  }

  findFileType(fileName: string): string {
    let type: string;
    switch (fileName.split('.').pop()) {
      case 'pdf': {
        type = this.appConfig.Shared.Assistant.Attachment.Type.PDF;
        break;
      }
      default: type = this.appConfig.Shared.Assistant.Attachment.Type.IMAGE;
    }
    return type;
  }

  limitInputByMaxDigit(event_: any, maxDigit: number): void {
    const event = event_;
    const keyCode = event.keyCode || event.which;
    if (event.target.value.toString().length === maxDigit && ![8, 46].includes(keyCode)) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  showPaperBombAnimation(delay?: number): void {
    setTimeout((): void => {
      confetti({
        particleCount: 150,
        spread: 100,
      });
    }, delay);
  }
}
