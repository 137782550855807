import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'aov-sale',
  templateUrl: './aov-sale.html',
  styleUrls: ['./aov-sale.scss'],
})
export class AovSaleComponent {
  @Input('maxPrice') maxPrice: any;
  @Input('numberOfProductsInCart') numberOfProductsInCart: any;
  @Input('showConfettiAnimation') showConfettiAnimation: any;
  @Input('barStyle') barStyle: any;
  @Input('fromListingPage') fromListingPage: boolean;
  @Input('bottomBar') isBottomBarVisible: boolean;
  @Input('cart') cart: any;
  @Input('user') user: any;
  @Input('prodImage') prodImage: any;
  @Input('userLanguageKnOrTa') userLanguageKnOrTa: boolean;
  @Input('amountRemaining') amountRemaining: any;
  @Output() onCheckout: EventEmitter<any> = new EventEmitter();
  options: AnimationOptions = {
    path: '/assets/animation.json',
  };
  styles: Partial<CSSStyleDeclaration> = {
    marginTop: '-200px',
    position: 'sticky',
    bottom: '0',
  };
}
