<div class="hbox">
  <div class="flex-box pos-rel">
  <loading class="pos-abs vh-center" *ngIf="inProcess$ | async"></loading>
  <div class="flex-heading">
    <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
    <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
      <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
    </div>
    <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@recentSupportQueries">Support Queries</span>
    </div>
  </div>
  <div class="flex-content" *ngIf="!(inProcess$ | async)">
    <div class="recent-queries" *ngFor="let ticket of (recentSupportTickets$ | async)" (click)="viewQuery(ticket)" [ngClass]="{ 'highlight green': ticket.hasUnreadMessage }">
    <div class="tw-flex tw-justify-between">
      <div class="timestamp tw-font-body tw-text-100">
      {{ ticket.createdAt | date: 'dd MMM YYY, HH:MM a' }}
      </div>
      <p class="tw-font-body tw-text-200 tw-text-black">
      {{ ticket.ticketNumber }}
      </p>
    </div>
    <div class="tw-border-b tw-border-gray-300 tw-pb-3 tw-mt-3 tw-font-body tw-text-300">
      {{ticket.title ||
    ticket?.supportQuestion?.questionLanguageString[ticket.languagePreference]}}
    </div>
    <div class="mT10">
      <div class="status tw-font-body tw-text-200 red" *ngIf="ticket.status === appConfig.Shared.Ticket.status.Completed" i18n="@@resolved">
      RESOLVED
      </div>
      <div class="status tw-font-body tw-text-200 tw-uppercase" *ngIf="ticket.status === appConfig.Shared.Ticket.status.Pending" i18n="@@open">Open</div>
      <i class="cs-icon-arrow"></i>
    </div>
    </div>
  </div>
  <div class="flex-footer"></div>
  </div>
</div>