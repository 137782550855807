import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SingleSelectorComponent } from '@shared/single-selector/single-selector.component';
import { DirectivesModule } from '@directives/directives.module';
import { SingleSelectorService } from '@shared/single-selector/single-selector.service';
import { TextFieldModule } from '@angular/cdk/text-field';

@NgModule({
  providers: [SingleSelectorService],
  imports: [CommonModule, DirectivesModule, FormsModule, TextFieldModule],
  declarations: [SingleSelectorComponent],
  exports: [SingleSelectorComponent],
})
export class SingleSelectorModule {}
