import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { DiscontinuedPopupComponent } from './discontinued-popup.component';

@NgModule({
  imports: [CommonModule, CsIconModule],
  declarations: [DiscontinuedPopupComponent],
  exports: [DiscontinuedPopupComponent],
})
export class DiscontinuedPopupModule {}
