<!-- fixed tagline strip -->
<div
    class="tw-absolute tw-left-5 tw-bg-gradient-yellow tw-bg-[length:400%_400%] tw-rounded-b-1 tw-animate-background_gradient tw-flex tw-items-center tw-justify-center tw-h-6 tw-px-2 tw-font-body tw-text-100">
    <ng-content select="app-regimen-offer-tag"></ng-content>
</div>

<!--  -->
<div class="tw-flex-[3_0_60%] tw-flex tw-flex-col tw-pt-8 tw-pb-5 tw-pl-5">
    <ng-content select="regimen-offer-product-title"></ng-content>
    <ng-content select="regimen-offer-product-caption"></ng-content>
</div>

<!-- image -->
<div class="tw-flex-[2_0_40%] tw-flex tw-items-end">
    <img class="tw-w-full tw-h-auto" src="https://cdn.cureskin.com/app/img/offer-pouch.png">
</div>