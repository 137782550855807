import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EmptyComponent } from './empty.component';

@NgModule({
  imports: [FormsModule],
  declarations: [EmptyComponent],
  exports: [],
})
export class EmptyModule {}
