import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({ selector: 'loading', templateUrl: './loading.html' })
export class LoadingComponent implements AfterViewInit {
  @ViewChild('svg', { static: false }) svgElement: ElementRef;
  color: string;
  @Input('scale') scaleValue: number = 0.9;
  @Input('margin') margin: string = '0';
  @Input()
  set stroke(color: string) {
    this.color = color;
  }

  ngAfterViewInit(): void {
    this.svgElement.nativeElement.style.stroke = this.color;
  }
}
