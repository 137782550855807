import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateSuffix' })
export class DateSuffixService implements PipeTransform {
  transform(value: string | number, onlySuffix: boolean = false): string {
    const numValue = Number(value);

    // Handle negative numbers or zero
    if (numValue <= 0) {
      return onlySuffix ? '' : `${value}`;
    }

    const lastDigit: number = numValue % 10;
    const lastTwoDigits: number = numValue % 100;

    const firstSuffixTrigger = 1;
    const secondSuffixTrigger = 2;
    const thirdSuffixTrigger = 3;

    const specialCaseStart = 11;
    const specialCaseEnd = 13;

    let suffix = 'th';

    if (lastDigit === firstSuffixTrigger && (lastTwoDigits < specialCaseStart || lastTwoDigits > specialCaseEnd)) {
      suffix = 'st';
    } else if (lastDigit === secondSuffixTrigger && (lastTwoDigits < specialCaseStart || lastTwoDigits > specialCaseEnd)) {
      suffix = 'nd';
    } else if (lastDigit === thirdSuffixTrigger && (lastTwoDigits < specialCaseStart || lastTwoDigits > specialCaseEnd)) {
      suffix = 'rd';
    }

    return onlySuffix ? suffix : `${value}${suffix}`;
  }
}
