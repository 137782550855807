<div class="rebranded-notification-card" (click)="onClickReadMore()" [ngClass]='{ "bg-lightBlue": !notification.viewed, "bg-white": notification.viewed}'>
  <img class="notifications-img" src="{{notificationImage}}" />
  <div class="timestamp" *ngIf="timeStamp"><span>{{timeStamp}}</span></div>
  <div class="notification-container">
  <div class="text-container grid" *ngIf="notification.subject">
    <div class="notifications-title rebrandedTitle ellipsis">
    {{notification.subject}}
    </div>
  </div>
  <div class="text-container">
    <multiline-ellipsis class="font12 font-noto" [text]="text" readMoreClass='font12 font-noto mR25 {{!notification.viewed ? "bg-unreaded" : "bg-white"}}' [maxLine]="notification.subject ? 2 : 3" (readMore)="onClickReadMore()"></multiline-ellipsis>
  </div>
  <img class="img-container" *ngIf="notification.imageURLSigned" src="{{notification.imageURLSigned}}" />
  <div class="mT10" *ngIf="notification.audioURLSigned">
    <c-audio src="{{notification.audioURLSigned}}" [trackEvent]="notification.track" eventPrefix="Notification" (emitTrackEvent)="trackEvent($event)"></c-audio>
  </div>
  <img *ngIf='order?.get("type") === appConfig.Shared.Order.Type.REGIMEN && order?.get("products")?.length' [src]='order?.get("regimen")?.get("regimenCoverImage") || "https://storage.googleapis.com/heallocontent/app/img/Skin-Box.png"' style="width: 100px; display: block; margin: 15px auto 15px auto" /><product-list *ngIf='order?.get("type") === appConfig.Shared.Order.Type.PRODUCT' [products]='order?.get("products")' [hideFooter]="true"></product-list>
  <div class="content" *ngIf='order?.get("type") === appConfig.Shared.Order.Type.REGIMEN && order?.get("products")?.length'>
    <div class="bold mB10">
    <translate-string [key]="order?.get('regimen')?.get('class')"></translate-string><span class="pL5" i18n="@@treatmentKit">Treatment kit</span>
    </div>
    <div *ngFor="let product of productInfo">
    <li class="mB5">{{product.get('title')}}</li>
    </div>
  </div>
  <div class="w100" *ngIf="showPayButton">
    <cs-button [class]="'m2 primary btn-xxs'" style="margin: 0 auto; width: fit-content"><cs-button-text i18n="@@placeOrder">Place Order</cs-button-text></cs-button>
  </div>
  </div>
</div>
<pop-up *ngIf="ui.dialog.open" [show]="true" (close)="closeReadMore()"><notification-dialog [notification]="notification" (close)="closeReadMore()"></notification-dialog></pop-up>