import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { FollowUpBannerComponent } from './follow-up-banner.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ButtonModule,
  ],
  declarations: [FollowUpBannerComponent],
  exports: [FollowUpBannerComponent],
})
export class FollowUpBannerModule {}
