import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { HairThinningComponentComponent } from './hair-thinning.component';

@NgModule({
  declarations: [HairThinningComponentComponent],
  imports: [
    CommonModule,
    CsIconModule,
  ],
  exports: [HairThinningComponentComponent],
})

export class HairThinningBannerModule { }
