import { Component, EventEmitter, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';
import { BroadcastService } from '@services/broadcast-service';
import { CurrentComponentService } from '@services/current-component';
import { AppConfig } from '../../app/app.config';

@Component({ selector: 'call-us',
  templateUrl: './call-us.html',
  styleUrls: ['./call-us.scss'] })

export class CallUsComponent implements OnDestroy {
  @Input('show') set setShow(status: boolean) {
    this.show = status;
    if (this.show) {
      this.showPopup();
      this.currentComponentService.set(this);
    } else this.currentComponentService.remove(this);
  }
  @Output('onCallNeeded') onCallNeeded: EventEmitter<void> = new EventEmitter();
  @Output('close') close: EventEmitter<void> = new EventEmitter();
  show: boolean = false;
  constructor(private window: WindowRefService,
    public appConfig: AppConfig,
    public broadcast: BroadcastService,
    private currentComponentService: CurrentComponentService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
  }

  showPopup(): void {
    setTimeout((): void => {
      this.window.replaceHash('./#popup', (): void => { this.show = false; this.close.emit(); });
    }, 0);
    if (this.viewContainerRef.element) {
      this.window.nativeWindow.document.body.appendChild(this.viewContainerRef.element.nativeElement);
    }
  }

  async closePopUp(): Promise<any> {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    if (this.viewContainerRef.element) this.viewContainerRef.element.nativeElement.remove();
  }
}
