<div class="font-noto tw-relative tw-px-7 tw-pt-[72px] tw-pb-[24px]">

  <div class="tw-absolute tw-p-1 tw-z-[100] tw-top-[16px] tw-right-[16px] tw-flex tw-justify-center close-sheet" style="width: 24px; height: 24px" (click)="closeBottomSheet()">
    <div class="tw-text-black tw-text-600 tw-font-bold">&#10005;</div>
  </div>
  <p class="tw-text-black tw-font-bold tw-text-center tw-text-700"> Extra ₹100 discount </p>

  <div class="tw-h-[107px] offer-bg tw-my-3 tw-flex tw-items-center">
    <div class="tw-w-[80px] tw-h-full tw-flex tw-items-center tw-justify-center tw-px-1">
      <img class="tw-w-full" src="/assets/images/upi.png" alt="">
    </div>
    <div class="tw-h-full tw-w-full offer-info tw-flex tw-items-center tw-relative">
      <div class="tw-w-full tw-pl-4">
        <p class="tw-text-400 tw-font-bold">₹100 discount</p>
        <p class="tw-text-400">on online payment</p>
        <div class="tw-text-100 tw-font-bold tw-absolute tw-right-2 tw-top-2 tw-text-green-400">OFFER</div>
      </div>
    </div>
  </div>
  <cs-button class="tw-mt-7" id="continueButton" (click)="handlePrepaidOptionClick()" [class]="'primary w100'" [loading]="loading">
    <cs-button-text class="tw-leading-[0]">
      <div>
        <span [ngClass]="data.userLanguage === appConfig.Shared.Languages.TA || data.userLanguage === appConfig.Shared.Languages.ML ? 'tw-text-200' : 'tw-text-400'" i18n="@@prepaidPayOnline">Pay ₹{{ data?.orderSP - 100 }} Online</span>
        <br>
        <span class="tw-text-200 tw-font-normal" i18n="@@prepaidDiscountApplied">(₹100  discount applied)</span>
      </div>
    </cs-button-text>
  </cs-button>
  <cs-button class="tw-mt-3" id="continueButton" (click)="handleCODOptionClick()" [class]="'secondary w100'" [loading]="loading">
    <cs-button-text class="tw-font-body tw-leading-[0]">
      <div>
        <span class="tw-font-normal" [ngClass]="data.userLanguage === appConfig.Shared.Languages.TA || data.userLanguage === appConfig.Shared.Languages.ML ? 'tw-text-200' : 'tw-text-400'" i18n="@@prepaidCashOnDelivery">Cash on delivery</span>
        <span [ngClass]="data.userLanguage === appConfig.Shared.Languages.TA || data.userLanguage === appConfig.Shared.Languages.ML ? 'tw-text-200' : 'tw-text-400'" i18n="@@prepaidPay"> - Pay ₹{{ data?.orderSP }}</span>
      </div>
    </cs-button-text>
  </cs-button>
</div>
