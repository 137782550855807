import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class CacheService {
  cacheValidityInMinutes: number = 5;
  cacheValidityInMilliseconds: number = this.cacheValidityInMinutes * 60 * 1000;
  private cache: Record<string, { data: any, expiresOn: number }> = {};

  constructor() {
  }

  store(key: string, data: any, expireTimeInMin: number = this.cacheValidityInMinutes): void {
    const expiredTimeStamp = Date.now() + expireTimeInMin * 60 * 1000;
    this.cache[key] = { data, expiresOn: expiredTimeStamp };
  }

  retrieve(key: string): any {
    const result = this.cache[key];
    return result?.expiresOn > Date.now() ? result.data : null;
  }
}
