import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CsIconModule } from '@components/icon';
import { ImprovementJourneyComponent } from './improvement-journey.component';

@NgModule({
  imports: [
    CommonModule,
    CsIconModule,
  ],
  declarations: [ImprovementJourneyComponent],
  exports: [ImprovementJourneyComponent],
})
export class ImprovementJourneyModule { }
