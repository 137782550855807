<div class="tw-w-full tw-flex tw-content-center tw-flex-wrap tw-pt-0 tw-px-4 tw-pb-5 tw-animate-anim_scale" *ngIf='chat && chat.get("Inputs").length' [ngClass]="{'img-options tw-w-64': chat.get('Inputs')[0].type === 'IMAGE' }">
  <div id="chatContainer" class="tw-max-w-full" *ngFor="let each of chat.get('Inputs'); let i = index" [ngClass]="{'tw-w-full': each.type === 'TEXT', 'singleInput': chat.get('Inputs').length === 1 }">
    <div id="btnInp" class="tw-min-w-[150px] tw-text-[13px] tw-px-5 tw-py-0 tw-capitalize tw-rounded-6 tw-h-[42px] tw-flex tw-items-center tw-justify-center tw-text-center tw-text-ellipsis tw-whitespace-nowrap tw-bg-white tw-mt-[10px] tw-mr-3 tw-mb-0 tw-ml-0 tw-border tw-border-green-400 tw-text-green-400" rippleEffect="rippleEffect" rippleClass="ripple-blue" *ngIf='each.type === "BUTTON"' (click)="selectedIndex = i" (callback)="sendMessage()">
      {{each.text}}
    </div>
    <div id="imgInp" class="tw-w-[100px] tw-h-[100px] tw-my-[5px]" *ngIf="each.type === 'IMAGE'" rippleEffect="rippleEffect" rippleClass="ripple-blue" (click)="selectedIndex = i" (callback)="sendMessage()">
      <img [src]="each.params.imageUrl" />
    </div>
    <div id="textInp" class="tw-w-full tw-mt-[10px] tw-flex" *ngIf='each.type === "TEXT"' [ngClass]="{ 'mT10': chat.get('Inputs').length === 1, 'tw-items-center': each.isNumber, 'tw-items-end': !each.isNumber }">
      <ng-container *ngIf="each.isNumber; then numberInput else textAreaInput"></ng-container>
      <ng-template #numberInput>
        <input
        class="tw-w-full tw-flex-grow tw-rounded-[22.5px] tw-px-5 tw-py-[15px] tw-m-auto tw-mr-3 tw-shadow-[0_1px_4px_-1px_#d5d5d5] tw-border tw-border-solid tw-border-gray-300"
        id="chatMessageInput" *ngIf="each.text" type='number' [(ngModel)]="inputVal"
        placeholder="{{each.text}}" autocomplete="off" (keydown)="selectedIndex = i" (focus)="scrollToBottom()" />
        <input 
        class="tw-w-full tw-flex-grow tw-rounded-[22.5px] tw-px-5 tw-py-[15px] tw-m-auto tw-mr-3 tw-shadow-[0_1px_4px_-1px_#d5d5d5] tw-border tw-border-solid tw-border-gray-300"
        id="chatMessageInput" *ngIf="!each.text" type='number' [(ngModel)]="inputVal"
        placeholder="Type your message here" i18n-placeholder="@@chatPlaceholder" autocomplete="off" (keydown)="selectedIndex = i" (focus)="scrollToBottom()" />
      </ng-template>
      <ng-template #textAreaInput>
        <div *ngIf="each.text"
          class="tw-w-full tw-flex-grow tw-rounded-[22.5px] tw-px-4 tw-py-[11.5px] tw-m-auto tw-mr-3 tw-shadow-[0_1px_4px_-1px_#d5d5d5] tw-border tw-border-solid tw-bg-gray-50 tw-border-gray-300">
          <textarea id="chatMessageInput" class="tw-w-full tw-p-0 tw-rounded-none tw-text-300"
            style="border: none; min-height: 0px;" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="0" cdkAutosizeMaxRows="0.78" [(ngModel)]="inputVal" placeholder="{{each.text}}"
            autocomplete="off" (keydown)="selectedIndex = i" (focus)="scrollToBottom()"></textarea>
        </div>
        <div *ngIf="!each.text"
          class="tw-w-full tw-flex-grow tw-rounded-[22.5px] tw-px-4 tw-py-[11.5px] tw-m-auto tw-mr-3 tw-shadow-[0_1px_4px_-1px_#d5d5d5] tw-border tw-border-solid tw-bg-gray-50 tw-border-gray-300">
          <textarea id="chatMessageInput" class="tw-w-full tw-p-0 tw-rounded-none tw-text-300"
            style="border: none; min-height: 0px;" matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="0" cdkAutosizeMaxRows="0.78" [(ngModel)]="inputVal" placeholder="Type your message here"
            i18n-placeholder="@@chatPlaceholder" autocomplete="off" (keydown)="selectedIndex = i"
            (focus)="scrollToBottom()"></textarea>
        </div>
      </ng-template>
      <div class="tw-inline-block tw-w-[15%]" *ngIf='textInputAvailable && i === chat.get("Inputs").length - 1'>
        <div class="tw-inline-block tw-w-[15%]" *ngIf='textInputAvailable && i === chat.get("Inputs").length - 1'>
          <div class="tw-flex tw-items-center tw-justify-center tw-w-[45px] tw-h-[45px] tw-rounded-full tw-text-center tw-animate-fade_in" id="sendMessageButton" (click)="sendMessage()" [ngStyle]='{ "background" : inputVal ? "#000" : "#fff" } '>
            <em class="tw-ml-1 tw-text-[22px] cs-icon-send" [ngStyle]='{ "color" : inputVal ? "#fff" : "#c3c3c3" } ' ></em>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>