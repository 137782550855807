import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { HomePageSheetComponent } from './home-page-bottom-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
  ],
  declarations: [HomePageSheetComponent],
  exports: [HomePageSheetComponent],
})
export class HomePageSheetModule { }
