<section class="tw-bg-blue-100 tw-px-5 tw-py-6" style="background: #e2ebf5 !important" *ngIf="isProgressiveProductBanner && progressiveProduct && !doctorProductsInCart?.length" (click)="viewProduct.emit(progressiveProduct)">
    <div class="tw-flex tw-items-center tw-gap-2 tw-mb-4">
        <img class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-full" src="/assets/images/boost.gif" alt="boost" />
        <div class="tw-flex-1">
            <h2 class="tw-font-body tw-font-bold tw-text-600 tw-text-black tw-tracking-normal">
                Boost your treatment kit
            </h2>
            <p class="tw-font-body tw-text-300 tw-text-gray-500 tw-tracking-normal">
                Get 2X faster result in your treatment
            </p>
        </div>
    </div>
    <div class="tw-flex tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg">
        <div class="tw-flex-none tw-flex tw-justify-center tw-items-center tw-border-r tw-border-gray-200 tw-p-5 tw-pl-5 tw-w-24 tw-bg-green-200 tw-box-content">
            <img class="tw-max-w-full tw-max-h-40 tw-object-contain" [src]='progressiveProduct?.get("rebrandedImageWithBackground")?.[0] || progressiveProduct?.get("productUnboxedImage")?.[0] || progressiveProduct?.get("images")?.[0]' />
        </div>
        <div class="tw-flex-1 tw-px-4 tw-py-5 tw-flex tw-flex-col tw-items-start tw-gap-2">
            <div>
                <h3 class="tw-font-body tw-font-bold tw-text-black tw-tracking-normal tw-mb-4">
                    {{progressiveProduct?.get("title")?.substr(0, 25)}}
                </h3>
            </div>
            <div class="tw-flex-1"></div>
            <p class="tw-font-body tw-text-300 tw-tracking-normal tw-text-black">
                <span class="tw-font-bold">Total</span>
                <span class="tw-line-through">&nbsp;&nbsp;₹{{progressiveProduct?.get("price")}}&nbsp;</span>
                <span class="tw-font-bold tw-text-orange-400">₹{{progressiveProduct?.get("mrp")}}</span>
            </p>
            <button class="tw-m-0 tw-min-w-unset tw-h-8 tw-px-4 tw-flex tw-items-center tw-gap-2 tw-bg-green-400" (click)="proceedToBuy.emit(progressiveProduct?.id)">
                <span class="tw-normal-case tw-font-body tw-text-200 tw-tracking-normal tw-text-white" i18n="@@proceedToBuy">Proceed to Buy</span>
            </button>
        </div>
    </div>
</section>
<div class="tw-bg-blue-100 tw-px-5 tw-py-6 tw-mb-5" style="background: #e2ebf5 !important" *ngIf="isBoostProductBanner && !doctorProductsInCart?.length">
    <div class="tw-flex tw-items-center tw-gap-2 tw-mb-6">
        <img class="tw-flex-none tw-h-10 tw-w-10 tw-rounded-full" src="/assets/images/stethoscope.gif" alt="boost" />
        <div class="tw-flex-1" *ngIf="!isBoostProductLoading && !boostProductList?.length">
            <h2 class="tw-font-body tw-font-bold tw-text-600 tw-text-black tw-tracking-normal">
                What are you looking for?
            </h2>
            <p class="tw-font-body tw-text-300 tw-text-gray-500 tw-tracking-normal">
                Get personalised recommendations
            </p>
        </div>
        <div class="tw-flex-1" *ngIf="isBoostProductLoading || (!isBoostProductLoading && boostProductList?.length)">
            <p class="tw-font-body tw-text-300 tw-text-gray-500 tw-tracking-normal">
                Your personalised recommendations
            </p>
            <h2 class="tw-font-body tw-font-bold tw-text-600 tw-text-black tw-tracking-normal" *ngIf="boostProductTagName === 'UNDER_EYE'">
                For Under eye
            </h2>
            <h2 class="tw-font-body tw-font-bold tw-text-600 tw-text-black tw-tracking-normal" *ngIf="boostProductTagName === 'GLOW'">
                For Glow
            </h2>
            <h2 class="tw-font-body tw-font-bold tw-text-600 tw-text-black tw-tracking-normal" *ngIf="boostProductTagName === 'DARK_SPOT'">
                For Dark spot
            </h2>
            <h2 class="tw-font-body tw-font-bold tw-text-600 tw-text-black tw-tracking-normal" *ngIf="boostProductTagName === 'ACNE'">
                For Pimple
            </h2>
        </div>
    </div>
    <loading class="tw-flex tw-justify-center" *ngIf="isBoostProductLoading"></loading>
    <div class="tw-grid tw-grid-cols-2 tw-gap-2" *ngIf="!isBoostProductLoading && !boostProductList?.length">
        <div class="tw-flex-1 tw-py-5 tw-bg-white tw-shadow-z1 tw-rounded-xl">
            <p class="tw-font-body tw-text-400 tw-font-bold tw-text-black tw-text-center tw-tracking-normal" (click)="fetchBoostProductByType.emit('UNDER_EYE')">
                Under eye
            </p>
        </div>
        <div class="tw-flex-1 tw-py-5 tw-bg-white tw-shadow-z1 tw-rounded-xl">
            <p class="tw-font-body tw-text-400 tw-font-bold tw-text-black tw-text-center tw-tracking-normal" (click)="fetchBoostProductByType.emit('GLOW')">
                Glow
            </p>
        </div>
        <div class="tw-flex-1 tw-py-5 tw-bg-white tw-shadow-z1 tw-rounded-xl">
            <p class="tw-font-body tw-text-400 tw-font-bold tw-text-black tw-text-center tw-tracking-normal" (click)="fetchBoostProductByType.emit('DARK_SPOT')">
                Dark spot
            </p>
        </div>
        <div class="tw-flex-1 tw-py-5 tw-bg-white tw-shadow-z1 tw-rounded-xl">
            <p class="tw-font-body tw-text-400 tw-font-bold tw-text-black tw-text-center tw-tracking-normal" (click)="fetchBoostProductByType.emit('ACNE')">
                Pimple
            </p>
        </div>
    </div>
    <div *ngIf="!isBoostProductLoading && boostProductList?.length">
        <product-carousel [products]="boostProductList" (valueChange)="getCart.emit()" (showPopup)="showPopup.emit()"></product-carousel>
    </div>
</div>