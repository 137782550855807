import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { DirectivesModule } from '@directives/directives.module';
import { CsIconModule } from '@components/icon';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { ButtonModule } from '@components/button';
import { RouterModule } from '@angular/router';
import { UserPrescriptionViewComponent } from './user-prescription-view.component';
import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'prescription',
        canActivate: [authenticationGuard, onboardingGuard],
        component: UserPrescriptionViewComponent,
      },
    ]),
    ToolbarModule,
    DirectivesModule,
    LoadingModule,
    CsIconModule,
    ButtonModule,
    ToolbarTabsModule,
  ],
  declarations: [UserPrescriptionViewComponent],
})
export class UserPrescriptionViewModule { }
