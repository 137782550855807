<div class="font-noto tw-flex tw-items-center tw-relative tw-px-2 tw-py-4">
    <div class="tw-absolute tw-p-1 tw-rounded-full tw-bg-gray-100 tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-[15px] tw-right-[10px] close-sheet" style="width: 35px; height: 35px" (click)="closedPopup()">
      <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
    </div>
    <div class="tw-w-full tw-bg-white rounded-lg tw-p-2 tw-flex tw-flex-col tw-pt-10">
        <div  class="tw-flex tw-justify-center">
            <div class=" tw-p-4 tw-justify-center tw-rounded-full tw-bg-green-300 " >
            <cs-icon class="tw-h-8 tw-w-8 tw-font-bold tw-text-600 tw-text-green-500 " [iconName]="'notifications'"></cs-icon>
        </div>
        </div>
        <!--  -->
        <div class="bold">
            <div class="tw-p-2 tw-text-center">
                <div class="text-4xl font-noto tw-font-bold tw-text-900" i18n="@@tunOnNotification">
                    Turn on <br/> notifications
                </div>
            </div>
        </div>
        <!--  -->
        <div>
            <div class="tw-p-2 tw-text-center">
                <div class="tw-px-4 text-base font-noto tw-text-600" *ngIf="content === 'order-placed'" i18n="@@getTimelyUpdates">
                    Get timely updates about your order delivery status
                </div>
                <div class="tw-px-4 text-base font-noto tw-text-600" *ngIf="content === 'support-ticket'" i18n="@@getQueryUpdates">
                    Get timely update about your query / concerns raised
                </div>
                <div class="tw-px-4 text-base font-noto tw-text-600" *ngIf="content === 'followup-completed'" i18n="@@getReportUpdates">
                    Get notified as soon as doctor has updated your skin report
                </div>
            </div>
        </div>
        <div class="tw-mt-4 tw-flex tw-flex-col tw-justify-between  tw-px-[10px]">
            <div>
                <cs-button class="tw-my-2" [class]="'w100 primary btn btn-padding tw-px-6 btn-float'" (click)="openNativePopup()"><cs-button-text><span class="tw-font-body tw-tracking-normal" i18n="@@continuePermission">Continue</span><span>&nbsp; ></span></cs-button-text></cs-button>
            </div>
            <div class="tw-flex tw-items-center tw-justify-center tw-pl-4 tw-pr-3 tw-h-10 tw-font-body tw-font-bold tw-text-300 tw-text-black-400 tw-rounded-full"
            (click)="mayBeLater()">
                <span class="tw-lowercase tw-lg"
                i18n="@@mayBeLaterPermission">Maybe later</span>
            </div>
        </div>
  </div>
  