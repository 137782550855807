import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { InstaLiveBannerComponent } from './insta-live-banner.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
  ],
  declarations: [InstaLiveBannerComponent],
  exports: [InstaLiveBannerComponent],
})
export class InstaLiveBannerModule {}
