<loading class="pos-fix vh-center" *ngIf="!order"></loading>
<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="tw-flex tw-flex-col tw-max-h-full tw-h-full tw-bg-gray-100">
  <div class="tw-flex-1 tw-overflow-y-scroll" *ngIf="order">
    <div class="tw-flex tw-items-end tw-justify-between tw-px-4 tw-pt-24 tw-pb-4 tw-bg-white tw-mb-2">
      <div class="tw-flex-1 tw-flex tw-flex-col">
        <div class="tw-font-body tw-font-bold tw-text-200 tw-text-black">
          <span i18n="@@orderNumber">Order number</span><span>: {{"#"+ (order ? order.get('orderNumber') : '')}}</span>
        </div>
        <div class="tw-font-body tw-text-200 tw-text-gray-400">
          <span i18n="@@orderDate">Order date</span><span>: {{order.get('createdAt') | date: 'd MMM, yyyy'}}</span>
          <div *ngIf="expectedDeliveryDate && appConfig.Shared.Order.Stage.AfterShipment.includes(order.get('stage'))">
            <div class="bold" i18n="@@expectedDeliveryDate">
              Expected Delivery Date
            </div>
            <div class="mT5 fontS1">
              {{expectedDeliveryDate | date: 'd MMM, yyyy' }}
            </div>
          </div>
        </div>
      </div>
      <div class="tw-flex-none">
        <p class="tw-font-body tw-text-300">
          <span class="tw-text-black" i18n="@@total">Total</span><span class="tw-font-bold tw-text-green-500">: ₹{{order.get('amount')}}</span>
        </p>
      </div>
    </div>
    <ng-container [ngSwitch]="true">
      <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-yellow-100 tw-mb-2" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))" (click)="makePaymentForOrder()">
        <p class="tw-font-body tw-text-300" i18n="@@yourPaymentIsPending">
          Your payment is pending
        </p>
        <div class="tw-h-8 tw-px-5 tw-bg-green-400 tw-rounded-full tw-flex tw-items-center">
          <span class="tw-font-body tw-text-200 tw-text-white" i18n="@@makePayment">Make payment</span>
        </div>
      </div>
      <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-green-100 tw-mb-2" *ngSwitchCase="orderPlacedStages.includes(order.get('stage')) && order.get('trackingId')">
        <p class="tw-font-body tw-text-300" i18n="@@yourOrderIsPlaced">
          Your order is Placed
        </p>
        <div class="tw-h-8 tw-px-5 tw-rounded-full tw-flex tw-items-center tw-border tw-border-black" *ngIf="order.get('trackingId')" (click)='trackOrder(order.get("trackingURL"))'>
          <span class="tw-font-body tw-text-200 tw-text-green-500" i18n="@@trackOrder">Track Order</span>
        </div>
      </div>
      <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-green-100 tw-mb-2" *ngSwitchCase="orderPackedStages.includes(order.get('stage'))">
        <p class="tw-font-body tw-text-300" i18n="@@yourOrderIsPacked">
          Your order is Packed
        </p>
        <div class="tw-h-8 tw-px-5 tw-rounded-full tw-flex tw-items-center tw-border tw-border-black" *ngIf="order.get('trackingId')" (click)='trackOrder(order.get("trackingURL"))'>
          <span class="tw-font-body tw-text-200 tw-text-green-500" i18n="@@trackOrder">Track Order</span>
        </div>
      </div>
      <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-green-100 tw-mb-2" *ngSwitchCase="orderDispatchedStages.includes(order.get('stage'))">
        <p class="tw-font-body tw-text-300" i18n="@@yourOrderIsDispatched">
          Your order is Dispatched
        </p>
        <div class="tw-h-8 tw-px-5 tw-rounded-full tw-flex tw-items-center tw-border tw-border-black" *ngIf="order.get('trackingId')" (click)='trackOrder(order.get("trackingURL"))'>
          <span class="tw-font-body tw-text-200 tw-text-green-500" i18n="@@trackOrder">Track Order</span>
        </div>
      </div>
      <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-yellow-100 tw-mb-2" *ngSwitchCase="orderDeliveryFailedStages.includes(order.get('stage'))">
        <p class="tw-font-body tw-text-300" i18n="@@deliveryAttemptFailed">
          Delivery attempt failed
        </p>
        <div class="tw-h-8 tw-px-5 tw-rounded-full tw-flex tw-items-center tw-border tw-border-black" *ngIf="order.get('trackingId')" (click)='trackOrder(order.get("trackingURL"))'>
          <span class="tw-font-body tw-text-200 tw-text-green-500" i18n="@@trackOrder">Track Order</span>
        </div>
      </div>
      <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-green-100 tw-mb-2" *ngSwitchCase="orderOutForDeliveryStages.includes(order.get('stage'))">
        <p class="tw-font-body tw-text-300" i18n="@@orderOutForDelivery">
          Your order is out for delivery
        </p>
        <div class="tw-h-8 tw-px-5 tw-bg-green-400 tw-rounded-full tw-flex tw-items-center" *ngIf="order.get('trackingId')" (click)='trackOrder(order.get("trackingURL"))'>
          <span class="tw-font-body tw-text-200 tw-text-white" i18n="@@trackOrder">Track Order</span>
        </div>
      </div>
      <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-blue-100 tw-mb-2" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))">
        <p class="tw-font-body tw-text-300" i18n="@@yourOrderIsDelivered">
          Your order is Delivered
        </p>
        <div class="tw-h-8 tw-px-5 tw-bg-green-400 tw-rounded-full tw-flex tw-items-center" *ngIf="order.get('type') === appConfig.Shared.Order.Type.REGIMEN" (click)="seeInstructions()" [queryParams]='{tab: "regimen"}'>
          <span class="tw-font-body tw-text-200 tw-text-white" i18n="@@seeInstructions">See instructions</span>
        </div>
      </div>
      <div class="tw-h-12 tw-flex tw-items-center tw-justify-between tw-pl-4 tw-pr-7 tw-bg-orange-100 tw-mb-2" *ngSwitchCase="orderCanceledStages.includes(order.get('stage'))">
        <div class="tw-font-body tw-text-300 tw-flex tw-items-center tw-gap-1">
          <span i18n="@@yourOrderIsCancelled">Your order is Cancelled</span>
          <div class="tw-rounded-full tw-bg-red-400 tw-h-4">
            <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'close'"></cs-icon>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="tw-flex tw-flex-col tw-gap-2 tw-bg-white tw-p-4 tw-mb-2">
      <h2 class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@deliveryAddress">
        Delivery Address
      </h2>
      <div>
        <div class="tw-font-body tw-text-300 tw-text-gray-500">
          {{order.get("contactDetail").contactName }}
        </div>
        <div class="tw-font-body tw-text-300 tw-text-gray-500">
          {{order.get("contactDetail").buildingDetails }},
          {{order.get("contactDetail").localityDetails }},
          {{order.get("contactDetail").landmark }}
        </div>
        <div>
          <span class="tw-font-body tw-text-300 tw-text-gray-500">{{order.get("contactDetail").city }},
            {{order.get("contactDetail").state }}&nbsp;</span><span class="tw-font-body tw-text-300 tw-text-gray-500">- {{order.get("contactDetail").zipCode }}</span>
        </div>
        <div class="tw-font-body tw-text-300 tw-text-gray-500">
          {{order.get("contactDetail").contactNumber }}
        </div>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-bg-white tw-py-4 tw-mb-5">
      <h2 class="tw-font-body tw-text-300 tw-text-black tw-px-5">
        <span>{{order.get('productInfo').length}}&nbsp;</span><span i18n="@@productsInYourOrder">Products in your order</span>
      </h2>
      <div class="tw-flex tw-flex-col tw-gap-4 tw-mb-gray-300 tw-pt-6 tw-pb-5 tw-border-b-2 tw-border-gray-100 tw-pl-5 tw-pr-7 last:tw-border-none" *ngFor="let product of order.get('productInfo')">
        <div class="tw-flex tw-items-end tw-pr-3">
          <img class="tw-h-20 tw-w-auto tw-rounded-xl tw-mr-5" [src]="products[product.id]?.get('rebrandedImageWithBackground')?.[0]" />
          <div class="tw-flex-1 tw-flex tw-flex-col tw-self-stretch tw-justify-between tw-py-2">
            <h3 class="tw-font-body tw-font-bold tw-text-400 tw-text-black">
              {{product?.name}}{{!product?.SP && order?.get('type') === appConfig.Shared.Order.Type.REGIMEN ? ': Free' : ''}}
            </h3>
            <div class="tw-font-body tw-text-300 tw-text-gray-500">
              Qty {{product?.quantity}}
            </div>
          </div>
          <div class="tw-font-body tw-text-300 tw-pb-2" *ngIf="order?.get('type') !== appConfig.Shared.Order.Type.REGIMEN">
            <span class="tw-line-through tw-gray-500">₹{{product?.MRP}}</span><span class="tw-font-bold tw-text-black">&nbsp;₹{{product?.SP}}</span>
          </div>
        </div>
        <div class="tw-flex tw-gap-3">
          <div class="tw-flex-1 tw-h-8 tw-flex tw-gap-2 tw-items-center tw-justify-center tw-border tw-border-black tw-rounded-full" 
            *ngIf="!orderCanceledStages.includes(order.get('stage')) && product?.SP" (click)="handleNavigation(product)">
            <span class="tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-lowercase" i18n="@@seeInstructions">See instructions</span><cs-icon class="tw-h-4 tw-w-4 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
          </div>
          <div class="tw-flex-1 tw-h-8 tw-flex tw-gap-2 tw-items-center tw-justify-center tw-border tw-border-black tw-rounded-full" 
            *ngIf="!orderCanceledStages.includes(order.get('stage'))&& !product?.SP && order?.get('type') === appConfig.Shared.Order.Type.REGIMEN" (click)="handleNavigation(product)">
            <span class="tw-font-body tw-font-bold tw-text-200 tw-text-green-500 tw-lowercase" i18n="@@view">View</span><cs-icon class="tw-h-4 tw-w-4 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-mx-4 tw-mb-5 tw-flex tw-flex-col tw-rounded-3xl tw-overflow-hidden tw-bg-white tw-shadow-z1 tw-py-3 tw-px-4">
      <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf='order.get("signedUrlForPdfInvoice")' (click)='openInvoiceUrl(order.get("signedUrlForPdfInvoice"))'>
        <p class="tw-font-body tw-text-200" i18n="@@downloadInvoice">
          Download Invoice
        </p>
        <cs-icon class="tw-h-6 tw-w-6" [iconName]="'chevron_right'"></cs-icon>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf="regimenApproved" routerLink="/user/prescription">
        <p class="tw-font-body tw-text-200" i18n="@@seePrescription">
          See Prescription
        </p>
        <cs-icon class="tw-h-6 tw-w-6" [iconName]="'chevron_right'"></cs-icon>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" routerLink="/support">
        <p class="tw-font-body tw-text-200 tw-text-orange-500">
          <span i18n="@@needHelp">Need help</span><span>?</span>
        </p>
        <cs-icon class="tw-h-6 tw-w-6 tw-text-orange-500" [iconName]="'chevron_right'"></cs-icon>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-py-3 tw-border-b tw-border-gray-200 last:tw-border-none" *ngIf="!showPayButton && cancelOrderAllowed" (click)="cancelOrderDialog()">
        <p class="tw-font-body tw-text-200 tw-text-orange-500" i18n="@@cancelOrder">Cancel Order</p>
        <cs-icon class="tw-h-6 tw-w-6 tw-text-orange-500" [iconName]="'chevron_right'"></cs-icon>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-bg-white tw-pt-4 tw-gap-2">
      <h2 class="tw-font-body tw-text-400 tw-mb-3 tw-px-4" i18n="@@paymentDetails">
        Payment details
      </h2>
      <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
        <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@paymentMethod">Payment method</span><span class="tw-font-body tw-text-200">{{order.get('paymentType').replace('_', ' ') | titlecase}}</span>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
        <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@subtotalInclusiveTax">Subtotal (inclusive tax)</span><span class="tw-font-body tw-text-200">₹{{orderMRP}}</span>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
        <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@discount">Discount</span><span class="tw-font-body tw-text-200 tw-text-orange-400">(-) ₹{{+orderMRP - +orderSp}}</span>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
        <span class="tw-font-body tw-text-200 tw-text-gray-800" i18n="@@shippingCharges">Shipping charges</span><span class="tw-font-body tw-text-200">₹0</span>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-px-4">
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@total">Total</span><span class="tw-font-body tw-font-bold tw-text-300">₹{{orderSp}}</span>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-p-4 tw-bg-blue-100">
        <span class="tw-font-body tw-text-300 tw-text-green-500" i18n="@@totalSavings">Total Savings:</span><span class="tw-font-body tw-text-300 tw-text-green-500">₹{{+orderMRP - +orderSp}}</span>
      </div>
    </div>
  </div>
</div>
<pop-up-modal *ngIf="popUpModal.open" [config]="popUpModal" (close)="onPopUpClose($event)"></pop-up-modal>