import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { SwiperModule } from 'swiper/angular';
import { RouterModule } from '@angular/router';
import { TestimonialsDescComponent } from './testimonials-description/testimonials-description.component';
import { TestimonialsNewComponent } from './testimonials-new/testimonials-new.component';
import { TestimonialCaseStudiesComponent } from './testimonial-case-studies/testimonial-case-studies.component';
import { TestimonialCaseStudiesInfoComponent } from './testimonial-case-studies-info/testimonial-case-studies-info.component';
import { TestimonialWallComponent } from './testimonial-wall/testimonial-wall.component';
import { TestimonialsWidgetComponent } from './testimonials-widget/testimonials-widget.component';
import { VideoFeedComponent } from './video-feed/video-feed.component';

@NgModule({
  declarations: [
    TestimonialsDescComponent,
    TestimonialsNewComponent,
    TestimonialCaseStudiesComponent,
    TestimonialCaseStudiesInfoComponent,
    TestimonialWallComponent,
    TestimonialsWidgetComponent,
    VideoFeedComponent,
  ],
  exports: [
    TestimonialsDescComponent,
    TestimonialsNewComponent,
    TestimonialCaseStudiesComponent,
    TestimonialCaseStudiesInfoComponent,
    TestimonialWallComponent,
    TestimonialsWidgetComponent,
    VideoFeedComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'testimonial',
        children: [
          { path: '', component: TestimonialsNewComponent },
          { path: 'case-study/:testimonialId', component: TestimonialCaseStudiesInfoComponent },
          { path: ':type/:testimonialId', component: TestimonialsDescComponent },
        ],
      },
    ]),
    ButtonModule,
    CsIconModule,
    CustomPipeModule,
    SwiperModule,
  ],
})
export class TestimonialModule { }
