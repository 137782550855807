import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { HairThinningBannerModule } from '@shared/home-banners/hair-thinning/hair-thinning.module';
import { HairFallBannerModule } from '@shared/home-banners/hair-fall/hair-fall-banner.module';
import { HairFallMaleBannerModule } from '@shared/home-banners/control-hair-fall-male/hair-fall-male.module';
import { HairDandruffBannerRegimenModule } from '@shared/home-banners/hair-dandruff-regimen/hair-dandruff-regimen.module';
import { CrossRegimenBannerComponent } from './cross-regimen-banner.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ButtonModule,
    CustomPipeModule,
    HairThinningBannerModule,
    HairFallBannerModule,
    HairFallMaleBannerModule,
    HairDandruffBannerRegimenModule,
  ],
  declarations: [CrossRegimenBannerComponent],
  exports: [CrossRegimenBannerComponent],
})
export class CrossRegimenBannerModule {}
