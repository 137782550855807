<div class="img-holder" id="imgHolder" #imgHolder="" *ngIf='chat && !(chat.get("imageDeleted") || chat.get("unableToFetch"))' (click)="openImage()" [ngClass]=" { 'bdr-0': inMultiView } ">
  <loading *ngIf="loading"></loading><canvas id="chatImageCanvas" #canvas="" [ngStyle]=" { 'visibility': loading ? 'hidden' : 'visible' }"></canvas>
</div>
<div class="p15 pB0" *ngIf="chat && chat.get('imageDeleted') && !inMultiView" i18n="@@thisImageDeleted">
  This image has been deleted.
</div>
<div class="p15 pB0" *ngIf="chat && chat.get('unableToFetch') && !inMultiView" i18n="@@unableToFetchImage">
  Unable to load images due to slow internet.
</div>
<div class="image-count" *ngIf="images.length && chat && !(chat.get('imageDeleted') || chat.get('unableToFetch'))" (click)="expandImages.emit()">
  <div class="dib">
  <em class="cs-icon-camera"></em><span>+{{images.length}}</span>
  </div>
</div>