import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from '@components/loading/index';
import { CustomPipeModule } from '@components/custom-pipe/custom-pipe.module';
import { DirectivesModule } from '@directives/directives.module';
import { ProductCarouselComponent } from './product-carousel.component';

@NgModule({
  imports: [CommonModule, LoadingModule, DirectivesModule, CustomPipeModule],
  declarations: [ProductCarouselComponent],
  exports: [ProductCarouselComponent],
})
export class ProductCarouselModule {}
