import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'product-list',
  templateUrl: './product-list.html',
  styleUrls: ['./product-list.scss'],
})
export class ProductListComponent {
  user: any;
  products: any[] = [];
  regimen: any;
  @Input('banner') banner: boolean;
  @Input('homePage') homePage: boolean;
  @Input('ordersBanner') ordersBanner: boolean;
  @Input('userOrder') userOrder: boolean;
  @Input('order') order: any;
  @Input('hideFooter') hideFooter: boolean;
  @Input('hideButtons') hideButtons: boolean = false;
  @Input('regimen') set setRegimen(regimen: any) {
    if (!regimen) {
      return;
    }
    this.regimen = regimen;
    this.processProducts(this.regimen.objectId ? [...regimen.morning, ...regimen.night] : []);
  }
  @Input('products') set setProducts(products: any[]) {
    if (!products) {
      return;
    }
    this.processProducts(products);
  }
  @Output('onClick') onClick: EventEmitter<void> = new EventEmitter<void>();
  @Output('onReadMoreClick') onReadMoreClick: EventEmitter<void> = new EventEmitter<void>();
  @Output('onInstructionClick') onInstructionClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(private conn: ConnectionService, private router: Router) {}
  async ngOnInit(): Promise<void> {
    this.user = this.conn.getActingUser();
  }

  processProducts(products_: any[]): void {
    let products: any[] = JSON.parse(JSON.stringify(products_));
    products = products.map((each: Record<string, any>): Record<string, any> => {
      if (each.product) return each.product;
      return each;
    });
    products.forEach((each_: any): void => {
      const each: Record<string, any> = each_;
      if (each.productUnboxedImage) each.image = each.productUnboxedImage[0];
      if (each.images) each.image = each.productUnboxedImage[0];
      each.title = this.appendMargCountToTitle(each.title, each.margUnit);
    });
    this.products = products;
  }

  appendMargCountToTitle(title: string, unit: number): string {
    if (unit === 1) return title;
    if (title?.includes('Pack of')) {
      const titleWihtoutPack: string = title.substring(0, title.indexOf('('));
      return `${titleWihtoutPack} (Pack of ${unit})`;
    }
    return `${title} (Pack of ${unit})`;
  }

  clickOnProductImage(): void {
    if (this.regimen) {
      this.router.navigate(['/user/regimen/products'],
        { queryParams: { class: this.regimen.class, username: this.user.get('username'), regimenId: this.regimen.regimenId } });
    } else if (this.ordersBanner) {
      this.router.navigate([`/user/order/${this.order.id}`]);
    } else this.onClick.emit();
  }

  readMoreAboutProducts(): void {
    if (this.regimen) {
      this.router.navigate(['/user/regimen/products'],
        { queryParams: { class: this.regimen.class, username: this.user.get('username'), regimenId: this.regimen.regimenId } });
    } else this.onReadMoreClick.emit();
  }

  seeInstructions(): void {
    if (this.regimen) {
      this.router.navigate(['/user'],
        { queryParams: { class: this.regimen.class,
          username: this.user.get('username'),
          tab: 'regimen',
          instructions: true } });
    } else this.onInstructionClick.emit();
  }

  getProductImage(product: any): string {
    let image = 'false';
    if (product?.rebrandedImageWithoutBackground?.length) {
      image = product.rebrandedImageWithoutBackground[product.rebrandedImageWithoutBackground.length - 1];
    } else if (product?.image) {
      // eslint-disable-next-line prefer-destructuring
      image = product.image;
    } else if (product?.rebrandedImageWithBackground?.length) {
      image = product.rebrandedImageWithBackground[product.rebrandedImageWithBackground.length - 1];
    }
    return image;
  }
}
