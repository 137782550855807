/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { BroadcastService } from '@services/broadcast-service';
import { WindowRefService } from '@services/window-ref-service';
import { Params } from '@angular/router';
import { ApiClientConstant, ParseKeys, Table } from 'api-client';
import * as Types from '../modals';
import { fromActions } from '../actions';
import { fromSelectors } from '../selectors';
import { AppConfig } from '../../app.config';

@Injectable()
export class CheckoutEffects {
  constructor(
    private actions$: Actions,
    private conn: ConnectionService,
    private store: Store,
    private eventLogger: EventLoggerService,
    private broadcastService: BroadcastService,
    private windowRef: WindowRefService,
    private appConfig: AppConfig) {
  }

  getDefalutAddressBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.AddressBookDefaultFetchBegin),
      withLatestFrom(this.store.select(fromSelectors.selectDefaultAddress)),
      exhaustMap(([_, deliverHereAddress]: any[]): any => {
        if (deliverHereAddress) return of(deliverHereAddress);
        return this.conn.fetchDefaultAddress(this.conn.getActingUser());
      }),
      map((address: Types.AddressBookI): any => {
        if (address) {
          return this.store.dispatch(fromActions.AddressBookUpdate({ payload: { defaultAddress: JSON.parse(JSON.stringify(address)) } }));
        }
        return this.conn.navigateToURL('/user/address/new?fromCheckout=true');
      }),
      catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
        return caught;
      }),
    );
  }, { dispatch: false });

  getAddressBookListBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.AddressBookListFetchBegin),
      withLatestFrom(this.store.select(fromSelectors.selectAddressBookList)),
      exhaustMap(([{ fromCache }, addressBookList]: any[]): any => {
        this.store.dispatch(fromActions.AddressBookUpdate({ payload: { isFetchInProgress: true } }));
        if (addressBookList.length && fromCache) return of(addressBookList);
        return this.conn.fetchAllAddress(this.conn.getActingUser());
      }),
      tap((addressList: any[] = []): void => {
        this.store.dispatch(fromActions.AddressBookUpdate({ payload: { addressList: JSON.parse(JSON.stringify(addressList)),
          isFetchInProgress: false } }));
        if (!addressList.length) this.conn.navigateToURL('/user/address/new');
      }),
      catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
        this.store.dispatch(fromActions.AddressBookUpdate({ payload: { isFetchInProgress: false } }));
        return caught;
      }),
    );
  }, { dispatch: false });

  deleteAddressBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.AddressBookDeleteBegin),
      exhaustMap(({ address }: { address: Types.AddressBookI }): any => {
        this.store.dispatch(fromActions.AddressBookUpdate({ payload: { isFetchInProgress: true } }));
        const addressBook = new Table.AddressBook();
        addressBook.id = address.objectId;
        return addressBook.destroy();
      }),
      map((): void => {
        this.broadcastService.broadcast('NOTIFY', { message: 'Address deleted successfully.' });
        this.store.dispatch(fromActions.AddressBookListFetchBegin({}));
      }),
      catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
        this.broadcastService.broadcast('NOTIFY', { message: error.toString() });
        this.store.dispatch(fromActions.AddressBookUpdate({ payload: { isFetchInProgress: false } }));
        return caught;
      }),
    );
  }, { dispatch: false });

  setDeliveryAddress$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.AddressBookSetDeliveryAddress),
      withLatestFrom(this.store.select(fromSelectors.selectQueryParams)),
      exhaustMap(([{ deliveryAddress }, queryParams]: [{ deliveryAddress: Types.AddressBookI }, Params]): any => {
        if (queryParams.orderId) {
          return this.store.dispatch(fromActions.AddressBookUpdateOrderAddress({ deliveryAddress, orderId: queryParams.orderId }));
        }
        this.broadcastService.broadcast('NAVIGATION_BACK');
        return this.store.dispatch(fromActions.AddressBookUpdate({ payload: { defaultAddress: deliveryAddress } }));
      }),
    );
  });

  saveAddressBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.AddressBookSaveBegin),
      exhaustMap(({ address, addressId }: { address: Types.AddressBookI, addressId: string }): any => {
        this.store.dispatch(fromActions.AddressBookUpdate({ payload: { saveInProcess: true } }));

        const { buildingDetails, buildingDetails2 }: Types.AddressBookI = address;
        const combinedBuildingDetails = `${buildingDetails} ${buildingDetails2 || ''}`;

        const newAddressObject = new Table.AddressBook();
        newAddressObject.id = addressId;
        Object.keys(address).forEach((each: ParseKeys<Table.AddressBook>): void => {
          newAddressObject.set(each, address[each]);
        });
        newAddressObject.set('buildingDetails', combinedBuildingDetails);
        newAddressObject.set('user', this.conn.getActingUser());
        return newAddressObject.save();
      }),
      map((): void => {
        this.store.dispatch(fromActions.AddressBookUpdate({ payload: { saveInProcess: false } }));
        this.broadcastService.broadcast('NAVIGATION_BACK');
      }),
      catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
        this.store.dispatch(fromActions.AddressBookUpdate({ payload: { saveInProcess: false } }));
        this.broadcastService.broadcast('NOTIFY', { message: error.toString() });
        return caught;
      }),
    );
  }, { dispatch: false });

  updateOrderAddress$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.AddressBookUpdateOrderAddress),
      exhaustMap(({ orderId, deliveryAddress }: { deliveryAddress: Types.AddressBookI, orderId: string }): any => {
        return this.conn.updateOrderBeforeShipment({ id: orderId, addressBookId: deliveryAddress.objectId });
      }),
      map((): void => {
        this.broadcastService.broadcast('NOTIFY', { message: 'Delivery address changes successfully.' });
        this.broadcastService.broadcast('NAVIGATION_BACK');
      }),
      catchError((error: string, caught: Observable<unknown>): Observable<unknown> => {
        this.broadcastService.broadcast('NOTIFY', { message: error.toString() });
        this.broadcastService.broadcast('NAVIGATION_BACK');
        return caught;
      }),
    );
  }, { dispatch: false });

  checkoutOrderBegin$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(fromActions.CheckoutOrderBegin),
      withLatestFrom(this.store.select(fromSelectors.selectDefaultAddress)),
      exhaustMap(async ([props, defaultAddress]: [any, Types.AddressBookI]): Promise<any> => {
        return this.checkoutOrder(props, defaultAddress);
      }),
      map(({ order, replaceUrl }: { order: any, replaceUrl: boolean }): any => {
        this.store.dispatch(fromActions.CheckoutOrderUpdate({ checkout: { checkoutInProgress: false } }));
        return this.conn.navigateToURL(`/user/order/${order.id}/payment`, false, replaceUrl);
      }),
      catchError((error: any, caught: Observable<unknown>): Observable<unknown> => {
        this.store.dispatch(fromActions.CheckoutOrderUpdate({ checkout: { checkoutInProgress: false } }));
        if (error.message && error.code === 409) this.showError('We have the same order in process', true);
        else this.showError(error.message, true);
        this.eventLogger.trackEvent('order_creation_failed', { Exception: error.toString() });
        return caught;
      }),
    );
  }, { dispatch: false });

  async checkoutOrder(props: { payload: any, replaceUrl: boolean }, defaultAddress: Types.AddressBookI): Promise<any> {
    const user = this.conn.getActingUser();
    this.eventLogger.trackInParse('ADDRESS_SELECTED', ApiClientConstant.Event.Type.ORDER);
    this.eventLogger.trackEvent('checkout_validation_success', { regimenId: props.payload.regimenId });
    this.eventLogger.trackInElasticSearch({
      username: user.get('username'),
      added: new Date(),
      type: 'webApp',
      message: 'User checkout validation success',
      event: 'CHECKOUT_VALIDATION_SUCCESS',
      params: { regimenId: props.payload.regimenId },
    });
    this.store.dispatch(fromActions.CheckoutOrderUpdate({ checkout: { checkoutInProgress: true } }));
    if (!defaultAddress && props.payload.type !== this.appConfig.Shared.Order.Type.CONSULTATION) {
      return this.conn.navigateToURL('/user/address/new');
    }
    // Address used for order is marked as default address
    const address = await this.conn.fetchAddressById(defaultAddress?.objectId);
    if (address) {
      address.set('default', true);
      await address.save();
    }

    const order = await this.conn.checkoutAndCreateOrder({ ...props.payload, addressBookId: defaultAddress?.objectId });
    return { order, replaceUrl: props.replaceUrl };
  }

  showError(message: string, goBack?: boolean): void {
    this.broadcastService.broadcast('NOTIFY', { message });
    if (!goBack) return;
    this.broadcastService.broadcast('NAVIGATION_BACK');
  }
}
