import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { VideoPlayerModule } from '@components/video-player/video-player.module';
import { LoadingModule } from '@components/loading';
import { DirectivesModule } from '@directives/directives.module';
import { SwiperModule } from 'swiper/angular';
import { CsIconModule } from '@components/icon';
import { UserExploreComponent } from './user-explore.component';
import { VideoComponent } from './components/video/video.component';
import { ImageComponent } from './components/image/image.component';
import { FeedComponent } from './components/feed/feed.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    SwiperModule,
    DirectivesModule,
    ToolbarModule,
    ButtonModule,
    VideoPlayerModule,
    LoadingModule,
    CsIconModule,
  ],
  declarations: [UserExploreComponent, VideoComponent, ImageComponent, FeedComponent],
  exports: [UserExploreComponent],
})
export class UserExploreModule { }
