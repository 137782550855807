import { Component, OnInit, Output, Input, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-doubts-section',
  templateUrl: './doubts-section.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class DoubtsSectionComponent implements OnChanges {
  @Output('openPopUp') openPopUp: EventEmitter<string> = new EventEmitter();
  @Input('faceRegimenOrderPlaced') faceRegimenOrderPlaced: any;
  @Input('bannersTop') bannersTop: any;
  @Output('knowAboutDoctors') knowAboutDoctors: EventEmitter<void> = new EventEmitter();
  @Output('openBanner') openBanner: EventEmitter<any> = new EventEmitter();
  @Input('ImgSrc') ImgSrc: string;
  user: any;
  isPlaying: boolean = false;
  activeVideo: string;
  doubtsData: { id: number, title: string, image: string, video: string }[];
  duration: number = 0;
  currentTime: number = 0;
  videoPlayer: HTMLVideoElement;
  progress: number;
  activeThumbnail: string;
  userLang: string;
  @ViewChild('progressBar') progressBar: ElementRef;
  isRebrandingV1: boolean = false;

  constructor(private broadcast: BroadcastService,
    private windowRef: WindowRefService,
    private appConfig: AppConfig,
    private conn: ConnectionService) {
  }

  async ngOnInit(): Promise<any> {
    const experiments = await this.conn.findUserActiveExperiments();
    experiments?.forEach((exp: any): void => {
      if (exp.key === 'rebranding_v1_phase_2') {
        this.isRebrandingV1 = true;
      }
    });
    this.user = this.conn.getActingUser();
    this.loadDoubtsData();
  }

  loadDoubtsData(): void {
    this.userLang = this.user?.get('languagePreference');
    if (this.userLang === 'hi') {
      this.doubtsData = [
        {
          id: 0,
          title: 'मेरी ट्रीटमेंट किट कैसे बनती है?',
          image: this.isRebrandingV1 ? 'assets/images/rebranding/v1/sample_1.png' : 'assets/images/sample2.png',
          video: 'https://cdn.cureskin.com/app/video/cureskin_kit_kaise_banti_hai_hindi.mp4',
        },
        {
          id: 1,
          title: 'Cureskin के डॉक्टरों के बारे में जानें',
          image: this.isRebrandingV1 ? 'assets/images/rebranding/v1/sample_2.png' : 'assets/images/sample1.png',
          video: 'https://cdn.cureskin.com/app/video/app_intro_charu_hindi.mp4',
        },
      ];
    } else {
      this.doubtsData = [
        {
          id: 0,
          title: 'How is my treatment kit prepared?',
          image: this.isRebrandingV1 ? 'assets/images/rebranding/v1/sample_1.png' : 'assets/images/sample2.png',
          video: 'https://cdn.cureskin.com/app/video/cureskin_kit_kaise_banti_hai_eng.mp4',
        },
        {
          id: 1,
          title: 'Know about cureskin doctors',
          image: this.isRebrandingV1 ? 'assets/images/rebranding/v1/sample_2.png' : 'assets/images/sample1.png',
          video: 'https://cdn.cureskin.com/app/video/app_intro_charu_eng.mp4',
        },
      ];
    }
  }

  closeVideo(): void {
    this.broadcast.broadcast('DOUBTS_FULLSCREEN_VIDEO_OFF');
    const targetElement = this.windowRef.nativeWindow.document.getElementById('videoPlayer');
    this.videoPlayer = targetElement.querySelector('video');
    this.videoPlayer?.pause();
    if (this.videoPlayer) this.videoPlayer.currentTime = 0;
    this.isPlaying = false;
  }

  playVideo(i: number): void {
    this.activeVideo = this.doubtsData[i]?.video;
    this.activeThumbnail = this.doubtsData[i]?.image;
    this.broadcast.broadcast('DOUBTS_FULLSCREEN_VIDEO_ON', { show: true });
    const targetElement = this.windowRef.nativeWindow.document.getElementById('videoPlayer');
    this.videoPlayer = targetElement?.querySelector('video');
    if (this.videoPlayer) {
      if (this.isPlaying === false) {
        this.isPlaying = true;
      }
    }
  }

  onVideoEnd(): void {
    const targetElement = this.windowRef.nativeWindow.document.getElementById('videoPlayer');
    this.videoPlayer = targetElement?.querySelector('video');
    this.videoPlayer?.pause();
    this.videoPlayer.currentTime = 0;
    this.isPlaying = false;
  }

  ngOnChanges(): void {
  }

  handleClick(doubt: string): void {
    this.openPopUp.emit(doubt);
  }

  docInfo(): void {
    this.knowAboutDoctors.emit();
  }

  handleBanner(banner: any): void {
    this.openBanner.emit(banner);
  }

  protected readonly innerWidth: number = innerWidth;
}
