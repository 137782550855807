import { Component, Input, OnDestroy } from '@angular/core';

@Component({ selector: 'discount-timer', templateUrl: './discount-timer.html', styleUrls: ['./discount-timer.scss'] })
export class DiscountTimerComponent implements OnDestroy {
  timer: { days?: number, hours?: number, minutes?: number, seconds?: number } = {};
  interval: any;
  hideTimer: boolean = true;

  static parameters: Array<any> = [];
  @Input('text') text: string = 'Offer ends in';
  @Input('endTime')
  set data(milliseconds: any) {
    this.startTimer(milliseconds);
  }

  constructor() {}

  ngOnInit(): void {
  }

  startTimer(milliseconds: any): any {
    if (milliseconds <= 0) return;
    this.hideTimer = false;
    let millisecondsLeft = milliseconds;

    const days = Math.floor(milliseconds / 1000 / 60 / 60 / 24);

    this.timer.days = days;
    millisecondsLeft -= days * 24 * 60 * 60 * 1000;

    this.timer.hours = Math.floor(millisecondsLeft / 1000 / 60 / 60);
    millisecondsLeft -= this.timer.hours * 60 * 60 * 1000;

    this.timer.minutes = Math.floor(millisecondsLeft / 1000 / 60);
    millisecondsLeft -= this.timer.minutes * 60 * 1000;

    this.timer.seconds = Math.floor(millisecondsLeft / 1000);

    this.interval = setInterval((): void => {
      this.timer.seconds -= 1;
      if (this.timer.seconds < 0) {
        this.timer.minutes -= 1;
        this.timer.seconds = 59;
      }
      if (this.timer.minutes < 0) {
        this.timer.hours -= 1;
        this.timer.minutes = 59;
      }
      if (this.timer.hours < 0) {
        this.timer.days -= 1;
        this.timer.hours = 24;
      }
      if (this.timer.days < 0) {
        this.timer = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        clearInterval(this.interval);
        this.hideTimer = true;
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
