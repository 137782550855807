<loading class="pos-abs vh-center" *ngIf="loading"></loading>
<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" *ngIf="!loading" (click)="back()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<ng-container *ngIf="!loading">
  <div class="tw-flex tw-flex-col tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-h-full tw-bg-green-300" *ngIf="!alreadyAnswered && !noMoreQuestions && !showConfettiAnimation">
  <section class="tw-bg-green-300 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-px-6 tw-pb-6 tw-pt-24">
    <div class="tw-text-center tw-font-body tw-font-bold tw-text-700 tw-text-black tw-mb-3">
    {{currentQuestion?.get('questionLanguageString')?.get(user?.get('languagePreference'))
  ?
  currentQuestion?.get('questionLanguageString')?.get(user.get('languagePreference'))
  :
  currentQuestion?.get('questionLanguageString')?.get(appConfig.Shared.Languages.EN)
  }}
    </div>
  </section>
  <div class="tw-flex-auto tw-flex tw-flex-col tw-justify-center tw-gap-4 tw-overflow-auto tw-bg-green-100 tw-rounded-tr-5 tw-rounded-tl-5 tw-p-6 tw-pb-[132px]">
    <ng-container *ngFor='let option of currentQuestion?.get("optionsArray")'>
    <div class="tw-flex-none tw-flex tw-items-center tw-justify-center tw-rounded-full tw-h-12 tw-border tw-border-green-300" (click)="submit(option)" [ngClass]='{"tw-bg-green-300": currentQuestion?.get("answer")?.id === option?.id && selectedOption, "tw-animate-scale_out": currentQuestion?.get("answer")?.id !== option?.id && selectedOption, "tw-animate-tada": currentQuestion?.get("answer")?.id === option?.id && isCorrectAnswer, "tw-bg-orange-200 tw-animate-headshake": (selectedOption?.id === option?.id && isWrongAnswer)}'>
      <div class="tw-font-body tw-text-400 tw-text-black">
      {{option.get(user.get('languagePreference')) ?
    option.get(user.get('languagePreference')) :
    option.get(appConfig.Shared.Languages.EN)}}
      </div>
    </div>
    <div class="tw-font-body tw-text-200 tw-text-black" *ngIf="isWrongAnswer && currentQuestion?.get('answer')?.id === option?.id" [ngClass]='{"tw-animate-slide_in_top": isWrongAnswer && currentQuestion?.get("answer")?.id === option?.id }'>
      {{
    currentQuestion?.get('wrongAnswer')?.get(user?.get('languagePreference'))
    ?
    currentQuestion?.get('wrongAnswer')?.get(user.get('languagePreference'))
    :
    currentQuestion?.get('wrongAnswer')?.get(appConfig.Shared.Languages.EN)
    }}
    </div>
    </ng-container>
  </div>
  <footer class="tw-absolute tw-bottom-0 tw-right-0 tw-left-0 tw-flex-none tw-flex tw-flex-col tw-gap-3 tw-p-6 tw-bg-green-100" *ngIf="isWrongAnswer">
    <div [ngClass]='{"tw-animate-slide_in_bottom": isWrongAnswer}'>
    <div class="tw-flex tw-flex-col tw-items-center">
      <div class="tw-font-body tw-font-bold tw-text-200" i18n="@@comeTomorrow">
      Come back tomorrow
      </div>
      <div class="tw-font-body tw-text-200" i18n="@@nextQuiz">
      for the next quiz
      </div>
    </div>
    <cs-button [class]="'primary w100'" (callback)="back()"><cs-button-text i18n="@@close">close</cs-button-text></cs-button>
    </div>
  </footer>
  </div>
  <div class="tw-flex tw-flex-col tw-bg-green-300 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-h-full" *ngIf="showConfettiAnimation">
  <div class="tw-flex-auto tw-flex tw-overflow-auto tw-rounded-tr-5 tw-rounded-tl-5 tw-p-6 tw-items-center tw-justify-center">
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-py-10 tw-bg-green-100 tw-rounded-2xl">
    <div class="tw-text-center tw-font-body tw-font-bold tw-text-900" i18n="@@yay">
      Yayyy
    </div>
    <div class="tw-text-center tw-font-body tw-text-600" i18n="@@correctAnswer">
      🎉 Right answer
    </div>
    <div class="tw-text-center tw-font-body tw-font-bold tw-text-600" i18n="@@wonCash">
      You won ₹{{rewardMoney}} Cureskin Cash
    </div>
    </div>
  </div>
  <ng-lottie width="100%" [styles]="styles" [options]="options"></ng-lottie>
  <footer class="tw-flex-none tw-flex tw-flex-col tw-gap-3 tw-p-6 footer-btn">
    <div class="tw-flex tw-flex-col tw-items-center">
    <div class="tw-font-body tw-font-bold tw-text-200" i18n="@@comeTomorrow">
      Come back tomorrow
    </div>
    <div class="tw-font-body tw-text-200" i18n="@@anotherWin">
      for another win
    </div>
    </div>
  </footer>
  </div>
  <div class="tw-flex tw-flex-col tw-bg-green-300 tw-bg-grid-layout tw-bg-repeat-x tw-bg-top tw-h-full" *ngIf="alreadyAnswered || noMoreQuestions">
  <div class="tw-flex-auto tw-flex tw-overflow-auto tw-rounded-tr-5 tw-rounded-tl-5 tw-p-6 tw-items-center tw-justify-center">
    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-px-6 tw-py-10 tw-bg-green-100 tw-rounded-2xl">
    <div class="tw-text-center tw-font-body tw-text-700" *ngIf="alreadyAnswered" i18n="@@alreadyAnswered">
      You have already taken today's quiz.
    </div>
    <div class="tw-text-center tw-font-body tw-text-700" *ngIf="noMoreQuestions" i18n="@@noNewQuestion">
      No new question for now.
    </div>
    </div>
  </div>
  <footer class="tw-flex-none tw-flex tw-flex-col tw-gap-3 tw-p-6 footer-btn">
    <div class="tw-flex tw-flex-col tw-items-center">
    <div class="tw-font-body tw-font-bold tw-text-200" i18n="@@comeTomorrow">
      Come back tomorrow
    </div>
    <div class="tw-font-body tw-text-200" i18n="@@nextQuiz">
      for the next quiz
    </div>
    </div>
    <cs-button [class]="'primary w100'" (callback)="back()"><cs-button-text i18n="@@close">close</cs-button-text></cs-button>
  </footer>
  </div>
</ng-container>