import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoginDoctorComponent } from './login-doctor.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [LoginDoctorComponent],
  exports: [LoginDoctorComponent],
})
export class LoginDoctorModule {
}
