import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { WindowRefService } from '@services/window-ref-service';
import { LocalStorageService } from '@services/local-storage-service';
import { BroadcastService } from '@services/broadcast-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { AppConfig } from '../app.config';

@Component({
  selector: 'onboarding-language',
  templateUrl: './onboarding-language.html',
})

export class OnboardingLanguageComponent {
  language: any = { code: '' };
  languages: Array<any> = [];
  changeLanguage: boolean = false;
  languageStrings: { CONTINUE?: any, CHOOSE_LANGUAGE?: any, CHANGE_LANGUAGE?: any } = {};
  loading: boolean;

  constructor(private conn: ConnectionService,
    private windowRef: WindowRefService,
    private router: Router,
    private storage: LocalStorageService,
    private appConfig: AppConfig,
    private route: ActivatedRoute,
    private broadcast: BroadcastService,
    public zone: NgZone,
    private eventLogger: EventLoggerService,
    private appWebBridge: AppWebBridgeService) {
    this.languages = [
      { code: this.appConfig.Shared.Languages.HI, displayName: 'हिन्दी' },
      { code: this.appConfig.Shared.Languages.EN, displayName: 'English' },
      { code: this.appConfig.Shared.Languages.KN, displayName: 'ಕನ್ನಡ' },
      { code: this.appConfig.Shared.Languages.TE, displayName: 'తెలుగు' },
      { code: this.appConfig.Shared.Languages.TA, displayName: 'தமிழ்' },
      // { code: this.appConfig.Shared.Languages.BN, displayName: 'বাংলা' },
      // { code: this.appConfig.Shared.Languages.ML, displayName: 'മലയാളം' },
      { code: this.appConfig.Shared.Languages.MR, displayName: 'मराठी' },
    ];
  }

  /**
   * 'force' queryParams - denotes that it came from 'change language' feature.
   * Once language is selected, then if the module is revisited, route them forward to '/number' page.
   */
  async ngOnInit(): Promise<any> {
    const user = this.conn.getActingUser();
    this.getLanguageStrings();
    if (user) {
      if (this.route.snapshot.queryParams.force) { // language selection from profile
        this.changeLanguage = true;
      } else { // regular app open event
        await this.router.navigate(['/user'], { queryParams: { tab: 'home', init: 'true' }, replaceUrl: true });
      }
      this.language = { code: user.get('languagePreference') || this.appConfig.Shared.Languages.EN };
      return;
    }
    // User not logged In
    if (this.storage.getValue('CureSkin/language')) {
      await this.router.navigate(['/introduction']);
      return;
    }
    this.language = { code: this.appConfig.Shared.Languages.EN };
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'onboarding-language-selection' }));
  }

  /**
   * Update user selected language in user table if user is found.
   * If user selected language code === current website code, then ignore redirect nad move to '/onboarding/number' page.
   * Else redirect the user to preferred language website.
   */
  async updateLanguage(): Promise<any> {
    const user = this.conn.getActingUser();
    if (this.language.code === this.conn.currentWebSiteLanguage.toLowerCase()) {
      await this.redirectFromLanguagePage();
      return;
    }
    this.appWebBridge.notifyUserLanguageChange(this.language.code);
    this.storage.setValue('CureSkin/language', this.language.code);
    if (user) await this.conn.updateUserData({ languagePreference: this.language.code });

    if (this.language.code.toLowerCase() !== this.conn.currentWebSiteLanguage.toLowerCase()) {
      if (this.changeLanguage) this.storage.setValue('CureSkin/redirectUrl', '');
      if (this.language.code === this.appConfig.Shared.Languages.EN) {
        this.windowRef.nativeWindow.location.href = `${this.conn.getBaseUrl()}/`;
      } else if (this.language.code !== this.appConfig.Shared.Languages.EN && !user) {
        this.windowRef.nativeWindow.location.href = `${this.conn.getBaseUrl()}/${this.language.code}/introduction`;
      } else this.windowRef.nativeWindow.location.href = `${this.conn.getBaseUrl()}/${this.language.code}/`;
      return;
    }
    this.redirectFromLanguagePage();
  }

  async redirectFromLanguagePage(): Promise<void> {
    if (this.changeLanguage) this.back();
    else await this.router.navigate(['/introduction']);
  }

  selectLanguage(item: any): void {
    this.zone.run((): void => {
      this.language = item;
    });
    this.updateLanguage();
  }

  getLanguageStrings(): void {
    this.languageStrings.CONTINUE = {
      [this.appConfig.Shared.Languages.EN]: 'Continue',
      [this.appConfig.Shared.Languages.HI]: 'जारी रखें',
      [this.appConfig.Shared.Languages.TE]: 'కొనసాగించండి',
      [this.appConfig.Shared.Languages.KN]: 'ಮುಂದುವರಿಸಿ',
      [this.appConfig.Shared.Languages.TA]: 'தொடரவும்',
      [this.appConfig.Shared.Languages.MR]: 'सुरू',
      [this.appConfig.Shared.Languages.BN]: 'চালিয়ে যান',
      [this.appConfig.Shared.Languages.ML]: 'തുടരുക',
    };
    this.languageStrings.CHANGE_LANGUAGE = {
      [this.appConfig.Shared.Languages.EN]: 'Change language',
      [this.appConfig.Shared.Languages.HI]: 'भाषा बदलें',
      [this.appConfig.Shared.Languages.TE]: 'భాషను మార్చుకోండి',
      [this.appConfig.Shared.Languages.KN]: 'ಭಾಷೆಯನ್ನು ಬದಲಾಯಿಸಿ',
      [this.appConfig.Shared.Languages.TA]: 'மொழியை மாற்றுங்கள்',
      [this.appConfig.Shared.Languages.MR]: 'भाषा बदला',
      [this.appConfig.Shared.Languages.BN]: 'ভাষা পরিবর্তন করুন',
      [this.appConfig.Shared.Languages.ML]: 'ഭാഷ മാറ്റുക',
    };
    this.languageStrings.CHOOSE_LANGUAGE = {
      [this.appConfig.Shared.Languages.EN]: 'Choose your language',
      [this.appConfig.Shared.Languages.HI]: 'अपनी भाषा चुनें',
      [this.appConfig.Shared.Languages.TE]: 'మీ భాషను ఎంచుకోండి',
      [this.appConfig.Shared.Languages.KN]: 'ನಿಮ್ಮ ಭಾಷೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ',
      [this.appConfig.Shared.Languages.TA]: 'உங்கள் மொழியைத் தேர்வுசெய்க',
      [this.appConfig.Shared.Languages.MR]: 'आपली भाषा निवडा',
      [this.appConfig.Shared.Languages.BN]: 'আপনার ভাষা নির্বাচন করুন',
      [this.appConfig.Shared.Languages.ML]: 'നിങ്ങളുടെ ഭാഷ തിരഞ്ഞെടുക്കുക',
    };
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
