import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { DietBannerComponent } from './diet-banner.component';

@NgModule({
  imports: [
    CommonModule,
    DirectivesModule,
    ButtonModule,
  ],
  declarations: [DietBannerComponent],
  exports: [DietBannerComponent],
})
export class DietBannerModule {}
