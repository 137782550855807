import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { FollowUpChatSheetComponent } from './followup-chat-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
  ],
  declarations: [FollowUpChatSheetComponent],
  exports: [FollowUpChatSheetComponent],
})
export class FollowUpChatSheetModule { }
