import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { WindowRefService } from '@services/window-ref-service';

@Component({
  selector: 'shop-carousels',
  templateUrl: './shop-carousels.html',
})
export class ShopCarouselComponent {
  @Input('sectionName') sectionName: any;
  @Input('translateId') translateId: string;
  @Input('viewAllSection') viewAllSection: any;
  @Input('showTags') showTags: any;

  constructor(
    private eventLogger: EventLoggerService,
    private conn: ConnectionService,
  ) {}

  viewAll(pageName: string, tags?: boolean): void {
    this.eventLogger.cleverTapEvent(
      'click',
      JSON.stringify({ name: `view-all-${pageName}` }),
    );
    if (this.showTags) {
      this.conn.navigateToURL(
        `/user/order/reorder?from=${pageName}&tags=${tags}`,
      );
    } else {
      this.conn.navigateToURL(`/user/order/reorder?from=${pageName}`);
    }
  }
}
