import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { AppConfig } from '../../app.config';

@Component({ selector: 'user-wallet-transactions',
  templateUrl: './user-wallet-transactions.html',
  styleUrls: ['./user-wallet-transactions.scss'] })
export class UserWalletTransactionsComponent {
  user: any;
  transactions: Array<any> = [];
  loading: boolean = true;
  queryUsername: string;

  constructor(private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    private broadcast: BroadcastService,
    private appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    this.queryUsername = this.route.snapshot.queryParams.username;
    this.transactions = JSON.parse(JSON.stringify(await this.conn.getCureSkinCashTransactions(this.queryUsername)));
    this.processTransactions();
    this.loading = false;
  }

  processTransactions(): void {
    this.transactions.forEach(async (each_: any): Promise<void> => {
      const each = each_;
      if (each?.referenceType === 'reward'
        && each[each.referenceType]
        && each[each.referenceType].isReferrer) {
        each.reason = 'REFERRER';
        const referrerName = await this.conn.findUserPropertyByObjectId(each?.reward?.fromUser?.objectId,
          ['PatientName'],
        );
        each.referrerName = referrerName?.get('PatientName');
      } else if (each?.referenceType === 'reward'
        && each[each.referenceType]
        && !each[each.referenceType].isReferrer) {
        if (each?.reward?.type === 'USER_INIT' && each.reward?.source !== 'Game Reward') {
          each.reason = 'SURPRISE';
        } else if (each?.reward?.type === 'GAME_REWARD' && each.reward?.source === 'Game Reward') {
          each.reason = 'GAME';
        } else {
          each.reason = 'REFEREE';
          const refereeName = await this.conn.findUserPropertyByObjectId(each?.reward?.fromUser?.objectId,
            ['PatientName'],
          );
          each.refereeName = refereeName?.get('PatientName');
        }
      } else if (each?.referenceType === 'order'
        && each[each.referenceType]
        && each[each.referenceType].type === this.appConfig.Shared.Order.Type.REGIMEN) {
        each.reason = 'REGIMEN_ORDER';
      } else if (each?.referenceType === 'order'
        && each[each.referenceType]
        && each[each.referenceType].type === this.appConfig.Shared.Order.Type.PRODUCT) {
        each.reason = 'PRODUCT_ORDER';
      } else each.reason = each.referenceType ? each.referenceType.toLowerCase().trim() : 'Reward';
    });
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}
