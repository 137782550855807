import { Component, OnInit, Input } from '@angular/core';
import { CsIconModule } from '@components/icon';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from 'client/app/app.config';

const VARIANT_1499_ID = 'variant_1499';

const REGIMEN_IMG_BACKGROUND_CLASS = {
  variant_999: 'tw-bg-teal-100',
  variant_1499: 'tw-bg-purple-100',
  variant_1999: 'tw-bg-orange-100',
  variant_4999: 'tw-bg-blue-100',
};

@Component({
  selector: 'regimen-kit-ready',
  templateUrl: './regimen-kit-ready.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class RegimenKitReadyComponent implements OnInit {
  @Input('userName') userName: string;
  @Input('class') class: string;
  @Input('regimen') regimen: any;
  @Input('coverImage') coverImage: string;
  @Input('doctorImage') doctorImage: string;
  imgSrcstr: string = '';
  isRebrandingV1: boolean = false;
  regimenImageBackgroundColor: string = '';

  constructor(
    public appConfig: AppConfig,
    public conn: ConnectionService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.userName = this.findUserDisplayName(this.userName);

    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((exp: any): void => {
      if (exp.key === 'rebranding_v1_phase_2') {
        this.isRebrandingV1 = true;
      }
    });

    this.setRegimenImageBackgroundColor();
  }

  findUserDisplayName(name: string): string {
    return name
      .split(' ')
      .slice(0, 1)
      .join('');
  }

  setRegimenImageBackgroundColor(): void {
    const variantId = this?.regimen?.selectedVariantId;
    if (!variantId || variantId === VARIANT_1499_ID || !REGIMEN_IMG_BACKGROUND_CLASS[variantId]) {
      this.regimenImageBackgroundColor = this.isRebrandingV1
        ? REGIMEN_IMG_BACKGROUND_CLASS.variant_999
        : REGIMEN_IMG_BACKGROUND_CLASS.variant_1499;
    } else {
      this.regimenImageBackgroundColor = REGIMEN_IMG_BACKGROUND_CLASS[variantId];
    }
  }
}
