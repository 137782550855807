import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref-service';
import { TimeService } from '../time-service';

@Injectable()
class CookieService {
  static parameters: any = [WindowRefService, TimeService];
  constructor(private windowRef: WindowRefService, private timeService: TimeService) {}

  stringToCookieJSON(cookieString: string): object {
    const cookie = {};
    if (!cookieString) return cookie;
    cookieString.split(';')
      .forEach((keyValue: string): void => {
        const [key, value]: Array<string> = keyValue.split('=');
        cookie[key.trim()] = value;
      });
    return cookie;
  }

  check(name: string): boolean { return !!this.get(name); }

  /**
   * @param name Cookie name
   * @returns {any}
   */
  get(name: string): string {
    return this.getAll()[name];
  }

  /**
   * @returns {}
   */
  getAll(): object {
    const allCookies = {};
    if (!this.windowRef.nativeWindow.document.cookie) return allCookies;
    return this.stringToCookieJSON(this.windowRef.nativeWindow.document.cookie);
  }

  /**
   * @param name    Cookie name
   * @param value   Cookie value
   * @param expires Number of days until the cookies expires or an actual `Date`
   * @param path    Cookie path
   * @param domain  Cookie domain
   * @param secure  Secure flag
   */
  set(name: string, value: string, expires?: Date, path?: string, domain?: string, secure?: boolean): void {
    this.windowRef.nativeWindow.document.cookie = `${name}=${value};domain=${this.windowRef.nativeWindow.location.hostname
    };path=/;expires=${(expires || this.timeService.addYears(new Date(), 1)).toUTCString()}`;
  }

  /**
   * @param name   Cookie name
   * @param path   Cookie path
   * @param domain Cookie domain
   */
  delete(name: string, path?: string, domain?: string): void {
    this.set(name, '', this.timeService.addDays(new Date(), -1));
  }

  /**
   * @param path   Cookie path
   * @param domain Cookie domain
   */
  deleteAll(path?: string, domain?: string): void {
    const cookies = this.windowRef.nativeWindow.document.cookie.split(';');
    cookies.forEach((each: any): void => {
      const cookie = each;
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      this.windowRef.nativeWindow.document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    });
  }

  setUser(user: any): void {
    this.set('Parse/myAppId/session', user.getSessionToken());
    this.set('Parse/myAppId/user', JSON.stringify({
      username: user.get('username'),
      Gender: user.get('Gender'),
      objectId: user.id,
    }));
  }
}

export { CookieService };
