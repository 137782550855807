export interface ProgressConfigType {
  [key: string]: {
    questionType: string;
    questionId: string;
    progressValue: number | null;
  };
}

export const ProgressConfig: ProgressConfigType = {
  introSkin: {
    questionType: 'Anchor',
    questionId: 'IntroSkinIssue',
    progressValue: 15,
  },
  introHair: {
    questionType: 'Anchor',
    questionId: 'IntroHairIssue',
    progressValue: 15,
  },
  hairfallPhoto: {
    questionType: 'Anchor',
    questionId: 'HairfallPhoto',
    progressValue: 30,
  },
  hairfallPhotoTop: {
    questionType: 'Anchor',
    questionId: 'HairfallPhotoTop',
    progressValue: 40,
  },
  takeInstantCheckup: {
    questionType: 'Anchor',
    questionId: 'TakeInstantCheckup',
    progressValue: 40,
  },
  takeInstantCheckSideFace: {
    questionType: 'Anchor',
    questionId: 'TakeInstantCheckSideFace',
    progressValue: 45,
  },
  productsUsing: {
    questionType: 'Anchor',
    questionId: 'ProductsUsing',
    progressValue: 50,
  },
  anythingElseGeneric: {
    questionType: 'Anchor',
    questionId: 'anythingElseGeneric',
    progressValue: 90,
  },
  pcodNotice: {
    questionType: 'Info',
    questionId: 'PCODNotice',
    progressValue: null,
  },
  explainInstantCheckup: {
    questionType: 'Info',
    questionId: 'ExplainInstantCheckup',
    progressValue: null,
  },
};
