import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'request-data',
  templateUrl: './request-data.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class RequestDataComponent implements OnInit {
  @Input('userDoctor') userDoctor: any;
  @Output('viewTicket') viewTicket: EventEmitter<any> = new EventEmitter();
  constructor(
  ) { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.viewTicket.emit();
  }
}
