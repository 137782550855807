import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'suggested-for-you',
  templateUrl: './suggested-for-you.html',
})
export class SuggestedForYouComponent implements OnInit {
    @Input('productsInCart') productsInCart:any;
    @Input('cartDiscount') cartDiscount:number;
    @Input('totalSp') totalSp:number;
    @Input('recommendedItems') recommendedItems: any;
    @Input('levelFourBanners') levelFourBanners: boolean;
    @Output('goToCart') goToCart:EventEmitter<void> = new EventEmitter();
    ngOnInit(): void { }
    handleClick():void {
      this.goToCart.emit(this.recommendedItems[0]);
    }
}
