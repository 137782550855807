import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { DirectivesModule } from '@directives/directives.module';
import { CsIconModule } from '@components/icon';
import { HelpComponent } from './help.component';

@NgModule({
  imports: [
    CommonModule,
    ToolbarModule,
    DirectivesModule,
    CsIconModule,
  ],
  declarations: [HelpComponent],
  exports: [],
})
export class HelpModule {}
