<div class="tw-block tw-border-spacing-0 tw-w-full tw-flex-[1] tw-overflow-x-hidden tw-h-screen tw-relative tw-font-body">
    <div class="tw-flex tw-flex-col tw-flex-[1] tw-h-full">
        <div class="tw-fixed tw-left-0 tw-top-0 tw-py-8 tw-px-6 tw-z-10 tw-bg-gray-200 tw-w-full">
          <div class="tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
            <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
          </div>
        </div>
        <div class="tw-w-full tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto tw-pt-24">
            <div class="tw-w-full tw-relative tw-bg-gray-200 tw-py-5 tw-pt-8">
                <div class="tw-pl-6">
                    <div class="tw-text-700 tw-w-4/5 tw-font-bold tw-mb-5" i18n="@@skinDifferent">
                        "Every skin is different, so is our care"
                    </div>
                    <div class="tw-mb-10 tw-pr-3 tw-w-8/12">
                        <div class="tw-text-400 tw-mb-14" i18n="@@dermoTeamDescNew">
                            My team of experienced dermatologists and advisors guide you
                            through every step of your skin and hair journey, and support your
                            progress.
                        </div>
                        <img class="tw-mb-4 tw-w-40" src="https://cdn.cureskin.com/app/img/dr-charu-sign.png" />
                        <div class="tw-text-400 tw-font-body tw-font-semibold" i18n="@@drCharu">
                          Dr. Charu
                        </div>
                        <div class="tw-text-300 tw-w-4/5 tw-font-bold" i18n="@@headOfDermatology">
                          Head of Dermatology - Cureskin
                        </div>
                    </div>
                </div>
                <img class="tw-absolute tw-w-64 tw-bottom-0 -tw-right-20 tw-left-auto" src="/assets/images/dr-charu.png" alt="Dr Charu" />
            </div>
          <!--marquee text animation-->
          <div class="tw-overflow-hidden tw-whitespace-nowrap tw-py-1.5 tw-px-2 tw-bg-black">
            <div class="tw-animate-marquee tw-flex tw-gap-4">
              <div class="tw-flex tw-justify-center tw-animate-fade_marquee tw-gap-2 tw-mx-4 tw-relative tw-items-center tw-text-500 tw-text-white">
                <img src="/assets/icons/stethoscope_white.svg" class="tw-w-7">
                <span class="tw-font-bold">70 +</span>
                <span i18n="@@doctors">Doctors</span>
              </div>
              <div class="tw-flex tw-justify-center tw-animate-fade_marquee tw-gap-2 tw-mx-4 tw-relative tw-items-center tw-text-500 tw-text-white">
                <img src="/assets/icons/search_white.svg" class="tw-w-7">
                <span class="tw-font-bold">200 +</span>
                <span i18n="@@cureskinAdvisors">Cureskin Advisors</span>
              </div>
              <div class="tw-flex tw-justify-center tw-animate-fade_marquee tw-gap-2 tw-mx-4 tw-relative tw-items-center tw-text-500 tw-text-white">
                <img src="/assets/icons/search_white.svg" class="tw-w-7">
                <span class="tw-font-bold">17<span i18n="@@lakhsPlus">&nbsp;Lakh +</span></span>
                <span i18n="@@happyCustomers">Happy customers</span>
              </div>
              <!-- Repeat the same set of content to ensure seamless scrolling -->
              <div class="tw-flex tw-justify-center tw-animate-fade_marquee tw-gap-2 tw-mx-4 tw-relative tw-items-center tw-text-500 tw-text-white">
                <img src="/assets/icons/stethoscope_white.svg" class="tw-w-7">
                <span class="tw-font-bold">40 +</span>
                <span i18n="@@doctors">Doctors</span>
              </div>
              <div class="tw-flex tw-justify-center tw-animate-fade_marquee tw-gap-2 tw-mx-4 tw-relative tw-items-center tw-text-500 tw-text-white">
                <img src="/assets/icons/search_white.svg" class="tw-w-7">
                <span class="tw-font-bold">200 +</span>
                <span i18n="@@cureskinAdvisors">Cureskin Advisors</span>
              </div>
              <div class="tw-flex tw-justify-center tw-animate-fade_marquee tw-gap-2 tw-mx-4 tw-relative tw-items-center tw-text-500 tw-text-white">
                <img src="/assets/icons/search_white.svg" class="tw-w-7">
                <span class="tw-font-bold">17<span i18n="@@lakhsPlus">&nbsp;Lakh +</span></span>
                <span i18n="@@happyCustomers">Happy customers</span>
              </div>
            </div>
          </div>
          <div class="tw-flex tw-flex-wrap tw-m-auto tw-relative tw-bg-gray-100">
                <div class="tw-text-center tw-w-full tw-px-5 tw-pt-1 tw-pb-3 tw-text-700 tw-font-bold tw-mt-3" i18n="@@ourDermatologists">
                    Our dermatologists
                </div>
                <div class="tw-pt- tw-pb-10 tw-px-2.5 tw-flex tw-flex-col tw-w-full tw-m-auto tw-min-h-52" >
                    <loading class="tw-absolute tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2" *ngIf="!doctors"></loading>
                    <div id="doctor-detail" class="tw-flex tw-gap-1 tw-bg-white tw-min-h-36 tw-w-11/12 tw-mx-auto tw-my-2.5 tw-text-center tw-rounded-4 tw-p-5" *ngFor="let doctor of doctors;let i = index" (click)="handleViewDoctor(doctor)">
                        <div class="tw-flex-1 tw-text-left tw-w-full tw-h-fit">
                            <div class="tw-text-500 tw-my-1 tw-mx-0 tw-font-bold">
                                {{doctor.get('DoctorDisplayName').substring(0,25)}}
                            </div>
                            <div class="tw-text-gray-400 tw-mb-2.5">
                                {{ conn.getQualifyText(doctor) }}
                            </div>
                            <p class="tw-font-body tw-font-bold tw-text-200 tw-text-gray-900" i18n="@@noOfCureskinPatientTreatedSoFar1">
                                Has treated {{ doctor?.get('patientsTreated') }} <br/> Cureskin
                                patients
                            </p>
                        </div>
                        <div class="tw-flex tw-w-32 tw-justify-center tw-items-center tw-text-center">
                            <img [src]='doctor.get("doctorDisplayImage")' class=" tw-aspect-square tw-inline-block tw-mt-0  tw-mx-auto tw-object-cover tw-rounded-full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
