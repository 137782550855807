import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from '@components/toolbar/index';
import { ButtonModule } from '@components/button';
import { PopUpModalModule } from '@components/pop-up-modal/index';
import { LoadingModule } from '@components/loading';
import { DirectivesModule } from '@directives/directives.module';
import { AddressFormModule } from '@shared/address-form/address-form.module';
import { CsIconModule } from '@components/icon';
import { RouterModule } from '@angular/router';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileEditComponent } from './user-profile-edit/user-profile-edit.component';
import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild([{
      path: 'profile',
      canActivate: [authenticationGuard, onboardingGuard],
      children: [
        { path: '', component: UserProfileComponent },
        { path: 'edit', component: UserProfileEditComponent },
      ],
    }]),
    CommonModule,
    ToolbarModule,
    ButtonModule,
    DirectivesModule,
    PopUpModalModule,
    AddressFormModule,
    LoadingModule,
    CsIconModule,
  ],
  declarations: [UserProfileComponent, UserProfileEditComponent],
  exports: [UserProfileComponent],
})

export class UserProfileModule { }
