<div class="hbox">
  <div class="flex-box">
  <div class="flex-content">
    <div class="heading">
    <div class="title" i18n="@@takeASecuredPhoto">
      <div>Take Your</div>
      <div>Skin Analysis</div>
    </div>
    </div>
    <div class="content justify-start">
    <div class="take-photo-holder">
      <div class="photo-placeholder shadow" (click)="selectType(appConfig.Shared.InstantCheckup.Type.FULL_FACE)" [ngClass]="{ 'zoom-in': selectedPhotoType === appConfig.Shared.InstantCheckup.Type.FULL_FACE, 'disabled': (selectedPhotoType !== appConfig.Shared.InstantCheckup.Type.FULL_FACE && !imageURLMap[appConfig.Shared.InstantCheckup.Type.FULL_FACE]) }">
      <div class="frame" [ngClass]='{ "disabled": imageURLMap.FULL_FACE.imagePath }'>
        <div class="empty" *ngIf="!imageURLMap.FULL_FACE.imagePath">
        <img [src]="meshImage.FRONT" />
        <div i18n="@@frontFace">Front Face</div>
        </div>
        <div class="user-image" *ngIf="imageURLMap.FULL_FACE.imagePath">
        <img [src]="imageURLMap.FULL_FACE.imagePath" />
        </div>
        <div class="lock"><em class="cs-icon-lock"></em></div>
      </div>
      <div class="tick" *ngIf="imageURLMap.FULL_FACE.imagePath">
        <em class="cs-icon-circle-tick-filled"></em>
      </div>
      </div>
      <div class="photo-placeholder shadow" (click)="selectType(appConfig.Shared.InstantCheckup.Type.SIDE_FACE)" [ngClass]="{ 'zoom-in': selectedPhotoType === appConfig.Shared.InstantCheckup.Type.SIDE_FACE, 'disabled': (selectedPhotoType !== appConfig.Shared.InstantCheckup.Type.SIDE_FACE && !imageURLMap[appConfig.Shared.InstantCheckup.Type.SIDE_FACE]) }">
      <div class="frame" [ngClass]='{ "disabled": imageURLMap.SIDE_FACE.imagePath }'>
        <div class="empty" *ngIf="!imageURLMap.SIDE_FACE.imagePath">
        <img [src]="meshImage.SIDE" />
        <div i18n="@@sideFace">Side Face</div>
        </div>
        <div class="user-image" *ngIf="imageURLMap.SIDE_FACE.imagePath">
        <img [src]="imageURLMap.SIDE_FACE.imagePath" />
        </div>
        <div class="lock"><em class="cs-icon-lock"></em></div>
      </div>
      <div class="tick" *ngIf="imageURLMap.SIDE_FACE.imagePath">
        <em class="cs-icon-circle-tick-filled"></em>
      </div>
      </div>
    </div>
    <div class="mT30">
      <div class="flex">
      <em class="cs-icon-lock clr-secondary fontL1"></em><span class="bold pL10" i18n="@@cureskinPrivacyProtect">CureSkin Privacy Protect Guard</span>
      </div>
      <div class="mT10 fontS2" i18n="@@yourPhotosAre100%Secure">
      • Your photos are 100% secured.
      </div>
      <div class="mT5 fontS2" i18n="@@ourDoctorsWillUseThePhoto">
      • Our doctors will use the photo to make your treatment kit.
      </div>
    </div>
    </div>
  </div>
  <div class="flex-footer">
    <cs-button *ngIf="photosUploaded" [class]="'w100 primary'" (callback)="showDetections()" [loading]="ui.loading"><cs-button-text i18n="@@continue">Continue</cs-button-text></cs-button><cs-button *ngIf="!photosUploaded && selectedPhotoType" [class]="'w100 primary'" (callback)="takePhoto()" [loading]="ui.loading"><cs-button-text *ngIf="selectedPhotoType === appConfig.Shared.InstantCheckup.Type.FULL_FACE" i18n="@@takeFrontPhoto">Take Front Face Photo</cs-button-text><cs-button-text *ngIf="selectedPhotoType === appConfig.Shared.InstantCheckup.Type.SIDE_FACE" i18n="@@takeSideFacePhoto">Take Side Face Photo</cs-button-text></cs-button>
  </div>
  </div>
</div>