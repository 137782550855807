<div class="font-noto tw-flex tw-items-center tw-relative tw-px-5 tw-py-4">
  <img class="tw-rounded-full tw-absolute tw-mb-20 tw-w-[120px] tw-z-[100] tw-top-[-60px]" style="border: 5px solid white" src="https://cdn.cureskin.com/app/img/doctor_ecg_1_min.gif" />
  <div class="tw-w-full tw-bg-white rounded-lg tw-p-2 tw-flex tw-flex-col tw-pt-10">
    <div>
      <h2 class="tw-text-lg tw-font-semibold tw-text-300 tw-mt-2 tw-text-gray-500">
        <span i18n="@@welcomeBackFullName">Welcome back, {{user?.get('PatientName')}}</span><span>!</span>
      </h2>
      <div [ngSwitch]="true">
        <p class="tw-my-2 tw-text-700 tw-font-bold" *ngSwitchCase="pastRegimensClass.includes('FACE') && pastRegimensClass.includes('HAIR')" i18n="@@continueSkinHairJourney">
          Continue with your skin and hair journey
        </p>
        <p class="tw-my-2 tw-text-700 tw-font-bold" *ngSwitchCase="pastRegimensClass.includes('FACE') && !pastRegimensClass.includes('HAIR')" i18n="@@continueSkinJourney">
          Continue with your skin journey
        </p>
        <p class="tw-my-2 tw-text-700 tw-font-bold" *ngSwitchCase="pastRegimensClass.includes('HAIR') && !pastRegimensClass.includes('FACE')" i18n="@@continueHairJourney">
          Continue with your hair journey
        </p>
      </div>
    </div>
    <cs-button class="tw-my-2" [class]="'w100 primary btn btn-padding tw-px-6 btn-float'" (click)="handleClick()" [disabled]="!isChecked"><cs-button-text><span class="tw-font-body tw-tracking-normal" i18n="@@okay">okay</span><span>&nbsp; ></span></cs-button-text></cs-button>
    <div class="tw-flex">
      <label class="tw-flex tw-flex-start tw-mr-2"><input class="toggle" id="whatsapp" type="checkbox" [checked]="isChecked" [(ngModel)]="isChecked" style="appearance: checkbox !important" /></label>
      <div class="tw-text-200 tw-text-gray-500" i18n="@@serviceUpdate">
        By continuing you agree to the updated&nbsp;<span class="tw-font-bold tw-underline" (click)='navigateTo("/iframe?src=https://cdn.cureskin.com/privacypolicy_new.html")'>Privacy Policy</span><span>&nbsp;and&nbsp;</span><span class="tw-font-bold tw-underline" (click)='navigateTo("/iframe?src=https://cdn.cureskin.com/termsofservice_new.html")'>Terms of Service</span>
      </div>
    </div>
  </div>
</div>