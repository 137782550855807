<div class="hbox pos-rel font-noto">
  <div class="flex-box">
    <div class="flex-heading">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="backHome()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" i18n="@@wallet">Cureskin wallet</span>
      </div>
    </div>
    <div class="flex-content bg-app">
      <header class="tw-bg-grid-layout tw-bg-repeat-x tw-bg-top">
        <div class="title" i18n="@@walletBalance">Current balance</div>
        <div class="balance bdr-btm">
          <div class="num tw-ml-0">₹</div>
          <div class="num tw-ml-0">{{walletBalance}}</div>
        </div>
        <div class="total">
          <span i18n="@@earnedSoFar">Total Cureskin Cash earned :</span><span>{{totalEarned}}</span>
        </div>
        <div class="links">
          <div class="w50" i18n="@@cureskinCashHistory" (click)='navigateTo("/user/wallet/transactions")'>
            Cureskin Cash History
          </div>
          <div class="v-separator w50" i18n="@@faqs" (click)='navigateTo("/user/refer?tab=faq")'>FAQs</div>
        </div>
      </header>
      <section>
        <loading class="pos-abs vh-center" *ngIf="loading"></loading>
        <div class="toolbar">
          <toolbar-tabs [data]="tabData" (tabSelected)="tabChange($event)" [floatOnScroll]="false">
            <tab1 i18n="@@rewards">Rewards</tab1>
            <tab2 i18n="@@earn">Earn more</tab2>
            <tab3 i18n="@@redeem">Redeem</tab3>
          </toolbar-tabs>
        </div>
        <div class="flex-grow pos-rel" *ngIf="!loading">
          <div class="rewardTab" *ngIf="selectedTabIndex === 0">
            <div class="centerA empty-hint empty-icon" *ngIf="!rewards.length" i18n="@@noRewards">
              No Rewards Available
            </div>
            <div class="card" #cardItem="" *ngFor="let item of rewards; let i = index" (click)="viewReward(item, i, cardItem)">
              <div class="content" [ngClass]='{ "hideCard": scratchCard && scratchCard.objectId === item.objectId }'>
                <div class="scratched" *ngIf="item.isClaimed">
                  <div class="fontS2" i18n="@@youHaveEarned">You have earned</div>
                  <div class="flex mT15">
                    <div class="bold fontL4">₹</div>
                    <div class="bold fontL4">{{item.amount}}</div>
                  </div>
                  <div class="source" *ngIf="item.source">
                    Source : {{item.source}}
                  </div>
                  <img class="paper" src="https://cdn.cureskin.com/app/img/paper-purple.png" />
                </div>
                <div class="unscratched" *ngIf="!item.isClaimed" [ngClass]="{ 'purple': i%2 === 0, 'yellow': i%2 === 1 }">
                  <img src="https://cdn.cureskin.com/app/img/heart-trans.svg" />
                  <div class="text" i18n="@@scratchToWin">
                    Scratch to win between 5 - 500
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="earnTab" *ngIf="selectedTabIndex === 1">
            <div class="earnInfo">
              <div class="dt" (click)='navigateTo("/user/refer")'>
                <div class="dtc">
                  <img class="refer" src="https://cdn.cureskin.com/app/img/earn-couple.png" />
                </div>
                <div class="dtc v-mid">
                  <div class="refer" i18n="@@referAndEarn">Refer & Earn</div>
                  <div class="invite tw-normal-case" i18n="@@inviteFriends">
                    Invite your friend to earn more Cureskin Cash
                  </div>
                  <div class="know clr-green tw-text-green-400" i18n="@@knowMore">Know More</div>
                </div>
              </div>
            </div>
            <div class="share">
              <em></em>
              <div i18n="@@earnOnSuccessRef">
                <div class="cash">
                  <span>Earn</span><span>&nbsp;₹</span><span> {{referralCashValue}}</span>
                </div>
                <div class="fontL1 tw-normal-case">
                  on every successful referral
                </div>
              </div>
              <div class="code" (click)="copyText()">
                <input id="referralCode" value="REFERRAL CODE - {{user.id}}" readonly="readonly" />
              </div>
              <div class="btn tw-bg-green-400 tw-capitalize tw-tracking-normal" (click)="shareApp()" i18n="@@share">Share</div>
            </div>
            <div class="banners" *ngFor="let item of banners" (click)="navigateTo(item.link)">
              <img [src]="item.image" />
            </div>
          </div>
          <div class="redeemTab" *ngIf="selectedTabIndex === 2">
            <div class="centerA empty-hint empty-icon" *ngIf="!regimen && !products.length" i18n="@@noRewardsToRedeem">
              No Rewards To Redeem
            </div>
            <div class="regimen" *ngIf="regimen">
              <div class="card">
                <div class="image" *ngIf="regimen.regimenCoverImage">
                  <img [src]="regimen.regimenCoverImage" />
                </div>
                <div class="buy">
                  <div class="fontL3 bold">
                    <span>{{regimen.class.toLowerCase()}}</span><span class="pL5" i18n="@@treatmentKit">Treatment kit</span>
                  </div>
                  <div class="price">
                    <del class="clr-secondary">₹{{regimen.fixedPriceMRP}}</del>
                    <div class="bold pL10 fontL3">₹{{regimen.fixedPrice}}</div>
                  </div>
                </div>
              </div>
              <div class="p20">
                <cs-button [class]="'primary w100'" (callback)="buyRegimen()"><cs-button-text><span i18n="@@buyNow">Buy Now</span><span class="pL5">&#64;</span><span class="regimenPrice">₹{{regimen.buyNowAt}}</span></cs-button-text></cs-button>
                <div class="cashUsed" i18n="@@cashUsedReg">
                  ( {{regimen.cashUsed}} wallet balance applied )
                </div>
              </div>
            </div>
            <div class="products" *ngIf="products.length">
              <div class="title" i18n="@@productsToOrder">
                Products You May Like To Order
              </div>
              <div class="cardHolder">
                <div class="dt anim-group">
                  <ng-container *ngFor="let item of products">
                    <div class="card anim-fadeIn" *ngIf='item.get("price") !== 0' (click)="buyProduct(item)">
                      <div class="img">
                        <img [src]="getImageSrc(item)" align="middle" />
                      </div>
                      <div class="detail">
                        <div class="name">{{item.get('title')}}</div>
                        <div class="price">₹{{item.get('price')}}</div>
                      </div>
                      <div class="buy">
                        <div class="price">
                          <span class="tw-capitalize tw-tracking-normal" i18n="@@buyNow">Buy Now</span><span class="font-noto">&#64;</span><span class="pL5">₹{{item.get('buyNowAt')}}</span>
                        </div>
                        <div class="cashUsed" i18n="@@cashUsedProd">
                          ( {{item.get('cashUsed')}} wallet balance applied )
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
<pop-up id="scratchCardPopup" *ngIf="scratchCard" [show]="true" (close)="closeScratch()" bg="rgba(255, 255, 255, 0)">
  <div class="scratch-zoom" id="holder" (click)="stopPropagation($event)">
    <div class="earned">
      <div class="fontS1" i18n="@@youHaveEarned">You have earned</div>
      <div class="df mT15">
        <img src="https://cdn.cureskin.com/app/img/heart-purple.svg" />
        <div class="fw-600 fontL4 mL10">{{scratchCard.amount}}</div>
      </div>
      <div class="source" *ngIf="scratchCard.source">
        Source : {{scratchCard.source}}
      </div>
      <img class="paper" [style.visibility]='scratchCard.scratched ? "visible" : "hidden"' src="https://cdn.cureskin.com/app/img/paper-purple.png" />
    </div>
    <canvas #canvasCard=""></canvas>
  </div>
  <div class="note" i18n="@@scratchNote">
    Swipe back & forth to see what you’ve won!
  </div>
</pop-up><pop-up *ngIf="claimCashPopup?.show" [show]="true" (close)="claimCashPopup.show = false" [class]="'bdr-rad-5'">
  <div class="claim-cash" *ngIf="!claimCashPopup.isClaimed">
    <div class="fw-500 fontL2 mB15" i18n="@@enterTheCouponCode">
      Enter the coupon code
    </div>
    <input class="primary mB30 text-center fontL2 fw-600" #cashCode="" autofocus="autofocus" /><cs-button [class]="'primary btn-xs w100'" (callback)="applyCouponAndClaimCash(cashCode.value)" [disabled]="!cashCode.value" [loading]="claimCashPopup.inProgress"><cs-button-text i18n="@@apply">Apply</cs-button-text></cs-button>
  </div>
  <div class="claim-cash bg-paper" *ngIf="claimCashPopup.isClaimed">
    <div class="fw-600 fontL3 clr-primary ls-1" i18n="@@congratulations">Congratulations!</div>
    <div class="fontL2 fw-500 mTB30" i18n="@@youHaveGotCashAdded">
      You have got {{claimCashPopup.amount}} in your CureSkin wallet.
    </div>
    <cs-button [class]="'primary btn-xs'" (callback)="shopCureskinCash()"><cs-button-text i18n="@@shopNow">Shop Now</cs-button-text></cs-button>
  </div>
</pop-up>