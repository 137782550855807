import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'app-analysis-banner',
  templateUrl: './analysis-banner.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class AnalysisBannerComponent implements OnInit {
  @Output('analysisOfTheDay') analysisOfTheDay: EventEmitter<void> = new EventEmitter();
  isRebrandingV1: boolean = false;

  constructor(public conn: ConnectionService) { }

  async ngOnInit(): Promise<void> {
    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach((exp: any): void => {
      if (exp.key === 'rebranding_v1_phase_2') {
        this.isRebrandingV1 = true;
      }
    });
  }

  handleClick(): void {
    this.analysisOfTheDay.emit();
  }
}
