import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { CCODBottomSheetComponent } from './ccod-bottom-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
  ],
  declarations: [CCODBottomSheetComponent],
  exports: [CCODBottomSheetComponent],
})
export class CCODBottomSheetModule { }
