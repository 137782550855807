import { Component, Input } from '@angular/core';

interface JourneyStep {
  name: string;
  duration: string;
}

@Component({
  selector: 'improvement-journey',
  templateUrl: './improvement-journey.html',
  styleUrls: ['./improvement-journey.scss'],
})
export class ImprovementJourneyComponent {
  @Input('showFirstJourney') showFirstJourney!: boolean;
  @Input('doctorDisplayImage') doctorDisplayImage!: string;

  journeySteps: { firstJourney: JourneyStep[]; notFirstJourney: JourneyStep[] } = {
    firstJourney: [
      { name: 'Depigmentation', duration: '0-1 month' },
      { name: 'Skin Regeneration', duration: '1-3 months' },
      { name: 'Skin Rejuvenation', duration: '3-6 months' },
      { name: 'Skin Maintenance', duration: '6 month onwards' },
    ],
    notFirstJourney: [
      { name: 'Sebum Control', duration: '0-1 month' },
      { name: 'Depigmentation', duration: '1-3 months' },
      { name: 'Skin Regeneration', duration: '3-6 months' },
      { name: 'Skin Maintenance', duration: '6 month onwards' },
    ],
  };
}
