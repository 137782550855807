import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { InputModule } from '@components/inputs';
import { GetFeedbackSheetComponent } from './get-feedback-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
    InputModule,
  ],
  declarations: [GetFeedbackSheetComponent],
  exports: [GetFeedbackSheetComponent],
})
export class GetFeedbackSheetModule { }
