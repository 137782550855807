<div class="comparison-slider" [ngClass]='{ "mB0": sliderLength - 1 === index }'>
  <div class="improvement" #container="">
    <div class="img-container" id="imgContainer" [ngStyle]='{ visibility: (!imageLoaded[0] && !imageLoaded[1]) ? "hidden" : "visible" }'>
      <div class="img-holder-1" #imgH1="">
        <canvas class="pos-rel" #imageOne=""></canvas>
      </div>
      <div class="img-holder-2" #imgH2="">
        <canvas class="pos-rel" #imageTwo=""></canvas>
      </div>
      <div class="rebranding-slider" #slider="" [ngStyle]='{ visibility: (!imageLoaded[0] || !imageLoaded[1]) ? "hidden" : "visible" }'>
        <img class="img" src="/assets/images/compress.svg" />
      </div>
    </div>
    <loading class="pos-abs vh-center z-ind-1" *ngIf="!imageLoaded[0] || !imageLoaded[1]"></loading>
  </div>
  <div class="rebranding-date" [ngClass]='{ "odd": index % 2 !== 0, "even": index % 2 === 0 }' [ngStyle]='{ visibility: (!imageLoaded[0] && !imageLoaded[1]) ? "hidden" : "visible" }'>
    <div class="day">
      {{report?.beforeInstantCheckup?.createdAt | date: 'd MMM yyyy'}}
    </div>
    <div class="day">
      {{report?.afterInstantCheckup?.createdAt | date: 'd MMM yyyy'}}
    </div>
  </div>
</div>