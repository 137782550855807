import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { DirectivesModule } from '@directives/directives.module';
import { ArticleComponent } from './article.component';

@NgModule({
  imports: [CommonModule, RouterModule, LoadingModule, DirectivesModule, ButtonModule],
  declarations: [ArticleComponent],
  exports: [ArticleComponent],
})
export class ArticleModule {}
