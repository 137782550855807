<loading class="pos-fix vh-center"
         *ngIf="isLoading"></loading>
<div class="empty-hint centerA empty-icon tw-font-bold"
     *ngIf="noProductsInCart && !isLoading">
    Your Cart is Empty
    <p class="tw-font-body tw-text-green-400 tw-text-300 tw-mt-4"
       (click)="goToShop()">
        Click here to check recommended products for you
    </p>
</div>
<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
     (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
             [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="tw-flex tw-flex-col tw-h-full tw-bg-gray-100"
     *ngIf="!noProductsInCart">
    <div class="tw-flex-1 tw-overflow-y-auto tw-flex tw-flex-col tw-pt-20">
        <div class="tw-flex tw-flex-col w70 tw-pl-6 tw-pb-4">
            <div class="font20 fw-700 float-left mainText tw-font-body"
                 i18n="@@shoppingCart">
                Shopping cart
            </div>
            <div class="font14 fw-500 float-left subText tw-font-body"
                 i18n="@@checkoutAllItems">
                Checkout {{totalProducts}} items
            </div>
        </div>
        <div *ngIf="!isLoading" class="tw-mt-0" [ngClass]="{ 'tw-pb-[206px]' : aovExp && amountRemaining && amountRemaining > 0 && !isLoading,
            'tw-pb-[136px]' : (!aovExp && !isLoading) || (aovExp && amountRemaining === 0 && !isLoading) }">
            <ng-container *ngIf="!aovExp">
                <div class="tw-relative tw-flex tw-mx-[23px] tw-mb-[18px] tw-bg-[#ffffff] tw-bg-[position:0_0] tw-bg-no-repeat tw-bg-clip-padding tw-opacity-100 tw-overflow-hidden tw-pb-0 tw-border tw-border-solid tw-border-[#E0E0E0] tw-rounded-5"
                     *ngFor="let item of cartItems; let i = index"
                     style="width: -webkit-fill-available; box-shadow: unset;">
                    <img class="tw-w-2/5"
                         (click)="viewProductDetail(item)"
                         [src]="item.rebrandedImageWithBackground || item.rebrandedImage || item.rebrandedImageWithoutBackground || item.imageURL" />
                    <span class="tw-ml-3 tw-m-4 tw-mr-5 tw-flex tw-flex-col tw-flex-nowrap tw-justify-evenly"
                          [ngClass]="{ '!tw-mt-[30px]' : item?.isDoctorAdded }"
                          *ngIf="item?.price !== 0"
                          style="width: -webkit-fill-available;">
                        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-p-2 tw-bg-gray-100 tw-h-6 tw-rounded-t-[25px] tw-rounded-r-[0px] tw-rounded-b-[0px] tw-rounded-l-[25px] tw-absolute tw-right-0 tw-top-2"
                             *ngIf="item?.isDoctorAdded">
                            <cs-icon class="tw-h-3 tw-w-3 tw-text-white tw-bg-green-400 tw-rounded-full"
                                     [iconName]='"done"'></cs-icon>
                            <p class="tw-font-body tw-text-100 tw-text-blue-500 tw-h-6 tw-tracking-[0] tw-font-bold tw-text-[10px] tw-pt-1"
                               i18n="@@drSuggested">
                                Dr. Suggested
                            </p>
                        </div>
                        <p class="p1-new font12 tw-font-body">{{item?.title}}</p>
                        <p class="font12 tw-font-body">{{item?.quantityData}}</p>
                        <span>
                            <del class="pR5 fw-700 font14 tw-font-body">₹{{item?.mrp}}</del>
                            <span class="fw-700 clr-orange font14 tw-font-body">₹{{item?.price}}</span>
                        </span>
                        <span class="tw-mt-[13px] tw-h-8 flex">
                            <div
                                 class="tw-flex tw-w-[93px] tw-h-[25px] tw-opacity-100 tw-justify-around tw-bg-[#FEFEFE] tw-border tw-border-solid tw-border-[#5FA653] tw-rounded-6 tw-text-[#5FA653] tw-mr-2">
                                <em class="cs-icon-minus green"
                                    (click)='removeProduct(item, "REMOVE", $event)'></em>
                                <b class="tw-h-[18px] tw-text-left tw-text-[14px] tw-tracking-[0px] green"
                                   *ngIf="!item.isRx">{{item?.quantity > nonRxLimit ? nonRxLimit :item?.quantity}}</b>
                                <b class="tw-h-[18px] tw-text-left tw-text-[14px] tw-tracking-[0px] green"
                                   *ngIf="item.isRx">{{item?.quantity > rxLimit ? rxLimit : item?.quantity}}</b>
                                <em class="cs-icon-plus green"
                                    (click)='addOrRemove(item, "ADD", $event)'></em>
                            </div>
                            <p class="font12 tw-font-body"
                               i18n="@@remove"
                               (click)='removeProduct(item, "DELETE", $event)'
                               style="margin: unset; margin-right: 17px;">
                                Remove
                            </p>
                        </span>
                    </span>
                </div>
            </ng-container><ng-container *ngIf="aovExp">
                <div class="align-center tw-relative tw-flex tw-mx-[23px] tw-mb-[18px] tw-bg-[#ffffff] tw-bg-[position:0_0] tw-bg-no-repeat tw-bg-clip-padding tw-opacity-100 tw-overflow-hidden tw-pb-0 tw-border tw-border-solid tw-border-[#E0E0E0] tw-rounded-5"
                     *ngFor='let item of ( (this.cart.get("totalPrice") >= this.maxPrice.totalValue &amp;&amp; showFreeProd &amp;&amp; !noFreeProduct) ? (cartItems | slice: 0: cartItems.length - 1) : (cartItems) ); let i = index'
                     style="width: -webkit-fill-available; box-shadow: unset;">
                    <img class="tw-mr-[5px] tw-object-contain tw-rounded-tl-5 tw-rounded-br-5 tw-w-2/5"
                         (click)="viewProductDetail(item)"
                         [src]="item.rebrandedImageWithBackground || item.rebrandedImage || item.rebrandedImageWithoutBackground || item.imageURL" /><span
                          class="tw-ml-3 tw-mr-5 tw-flex tw-flex-col tw-flex-nowrap tw-justify-evenly tw-m-4"
                          [ngClass]="{ '!tw-mt-[30px]' : item?.isDoctorAdded }"
                          *ngIf="item?.price !== 0">
                        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-p-2 tw-bg-green-100 tw-absolute tw-right-0 tw-top-2"
                             *ngIf="item?.isDoctorAdded">
                            <img src="/assets/images/correct.png" />
                            <p class="tw-font-body tw-text-black">Dr. Recommended</p>
                        </div>
                        <p class="p1-new font12 tw-font-body">{{item?.title}}</p>
                        <p class="font12 tw-font-body">{{item?.quantityData}}</p>
                        <span><del class="pR5 fw-700 font14 tw-font-body">₹{{item?.mrp}}</del><span
                                  class="fw-700 clr-orange font14 tw-font-body">₹{{item?.price}}</span></span>
                        <span class="tw-mt-[13px] tw-h-8 flex tw-gap-3">
                            <div
                                 class="tw-flex tw-w-[93px] tw-h-[25px] tw-opacity-100 tw-justify-around tw-bg-[#FEFEFE] tw-border tw-border-solid tw-border-[#5FA653] tw-rounded-6 tw-text-[#5FA653] tw-mr-2">
                                <em class="cs-icon-minus green"
                                    (click)='removeProduct(item, "REMOVE", $event)'></em>
                                <b class="tw-h-[18px] tw-text-left tw-text-[14px] tw-tracking-[0px] green"
                                   *ngIf="!item.isRx">{{item?.quantity > nonRxLimit ? nonRxLimit :item?.quantity}}</b>
                                <b class="tw-h-[18px] tw-text-left tw-text-[14px] tw-tracking-[0px] green"
                                   *ngIf="item.isRx">{{item?.quantity > rxLimit ? rxLimit : item?.quantity}}</b><em
                                    class="cs-icon-plus green"
                                    (click)='addOrRemove(item, "ADD", $event)'></em>
                            </div>
                            <p class="font12"
                               i18n="@@remove"
                               (click)='removeProduct(item, "DELETE", $event)'
                               style="margin: unset">
                                Remove
                            </p>
                        </span>
                    </span>
                </div>
                <div class="tw-flex tw-mx-[23px] tw-mb-[18px] tw-overflow-hidden tw-bg-[#FFF0F0] tw-bg-[position:0_0] tw-bg-no-repeat tw-bg-clip-padding tw-shadow-z2 tw-opacity-100 tw-pb-0 tw-border tw-border-solid tw-border-[#E0E0E0] tw-rounded-5"
                     *ngIf="aovExp &amp;&amp; this.cart.get('totalPrice') >= this.maxPrice.totalValue"
                     style="width: -webkit-fill-available; box-shadow: unset;">
                    <img class="tw-mr-[5px] tw-object-contain tw-rounded-tl-5 tw-rounded-br-5 tw-w-2/5"
                         [src]="freeProduct.rebrandedImageWithBackground || freeProduct.rebrandedImage || freeProduct.rebrandedImageWithoutBackground || freeProduct.imageURL" />
                    <span class="tw-ml-3 tw-mr-5 tw-flex tw-flex-col tw-flex-nowrap tw-justify-evenly m16">
                        <p class="p1-new font12 tw-font-body">{{freeProduct?.title}}</p>
                        <p class="font12 tw-font-body">
                            {{freeProduct?.quantity}} {{freeProduct?.quantityUnit}}
                        </p>
                        <span class="tw-justify-between tw-mt-[13px] tw-h-8 flex">
                            <p class="tw-text-red-400 font14 fw-700 clr-orange tw-font-body tw-uppercase"
                               i18n="@@free">free</p>
                        </span>
                    </span>
                </div>
            </ng-container>
        </div>

        <!-- <div class="tw-flex-1"></div> -->
    </div>
    <div class="tw-px-4 tw-py-2 tw-mb-[86px] tw-fixed tw-right-0 tw-left-0 tw-bottom-0"
    style="backdrop-filter: blur(5px); background-color: rgba(255, 255, 255, 0.8) !important;"
         *ngIf="cartItems?.length &amp;&amp; !isLoading">
        <div class="tw-bg-[#FAFAFA] tw-bg-[position:0_0] tw-p-2 tw-bg-no-repeat tw-opacity-100 tw-flex tw-flex-wrap tw-justify-between tw-items-center"
         *ngIf="aovExp && amountRemaining && amountRemaining > 0 && !isLoading">
            <div class="tw-pt-[10px]">
                <div class="font14 tw-font-body"
                    i18n="@@shopMore">
                    Shop for ₹{{ amountRemaining%1 != 0 ? amountRemaining.toFixed(2) :
                    amountRemaining }} more to
                </div>
                <span class="tw-inline-flex font14 tw-font-body"
                    i18n="@@getAFreeGift">
                    <p class="tw-mr-[0.2em] tw-font-bold"
                    style="margin-top: unset; margin-bottom: unset;">Get a</p>
                    <p class="tw-text-red-400 tw-font-semibold tw-mr-[0.2em]"
                    style="margin-top: unset; margin-bottom: unset;">free</p>
                    <p class="tw-mr-[0.2em] tw-font-bold"
                    style="margin-top: unset; margin-bottom: unset;">product</p>
                </span>
            </div>
            <div class="rightBlock">
                <p class="tw-text-center tw-not-italic tw-font-bold tw-text-[13px] tw-leading-[33px] tw-tracking-[0px] tw-text-[#5EAC73] tw-opacity-100 before:tw-mr-[2px] green tw-font-body tw-normal-case tw-text-green-400 tw-text-200"
                (click)="goToList()"
                i18n="@@addMoreProducts">
                    Add more products
                </p>
            </div>
        </div>

         <div>
            <span class="tw-w-3/5 tw-font-body tw-text-200 tw-text-gray-500"
                  i18n="@@subtotal">Subtotal</span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-200 tw-tabular-nums">₹{{totalMRP}}</span>
        </div>
        <div>
            <span class="tw-w-3/5 tw-font-body tw-text-200 tw-text-gray-500"
                  i18n="@@discount">Discount</span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-200 tw-tabular-nums"
                  *ngIf="!showCashBalance">- ₹{{totalMRP - totalSP}}</span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-200 tw-tabular-nums"
                  *ngIf="showCashBalance">- ₹{{totalMRP - (totalSP + cashBalance)}}</span>
        </div>
        <div class="tw-flex"
             *ngIf="showCashBalance">
            <span class="tw-w-3/5 tw-flex"><span class="tw-font-body tw-text-200 tw-text-gray-500"
                      i18n="@@cureskinCash">Cureskin Cash</span><img class="tw-w-4 tw-h-4 tw-ml-1"
                     src="/assets/images/cureskin-cash-icon.png" /></span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-200 tw-tabular-nums">-
                ₹{{cashBalance}}</span>
        </div>
        <div class="tw-mb-3">
            <span class="tw-w-3/5 tw-font-body tw-text-200 tw-text-gray-500"
                  i18n="@@delivery">Delivery</span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-200"><span
                      class="tw-text-orange-400 tw-tracking-normal tw-font-bold"
                      i18n="@@free">free</span><del class="tw-tabular-nums"> ₹{{100}}</del></span>
        </div>
        <div class="tw-border-b tw-border-gray-300 tw-mb-3"></div>
        <div>
            <span class="tw-w-3/5 tw-font-body tw-font-bold tw-text-300"
                  i18n="@@grandTotal">Grand Total</span><span
                  class="tw-w-2/5 tw-tracking-[1px] tw-text-right tw-float-right tw-font-body tw-text-300 tw-font-bold">₹{{totalSP}}</span>
        </div>
    </div>
    <div class="tw-flex-none tw-w-full tw-flex tw-flex-row tw-flex-nowrap tw-text-center tw-font-medium tw-bg-[position:0_0] tw-bg-no-repeat tw-bg-clip-padding tw-opacity-100 tw-bg-[#EBF1CE] tw-justify-between tw-py-4"
         *ngIf="!isLoading">
        <button class="tw-border-none tw-w-full tw-h-[54px] tw-relative tw-text-[#ffffff] tw-text-[18px] tw-capitalize tw-overflow-visible w100 p15 shine loop tw-bg-green-400"
                *ngIf="discount > 0"
                (click)="checkout()">
            <div class="tw-absolute tw-flex tw-items-center tw-justify-center tw-top-[-15px] tw-left-[10px] tw-text-orange-400"
                 *ngIf="discount > 5">
                <svg class="tw-w-[85px] tw-h-[85px] tw-relative tw-anima orange-spin"
                     xmlns="http://www.w3.org/2000/svg"
                     width="64.443"
                     height="67.447"
                     viewBox="0 0 64.443 67.447"
                     style="animation: spin 4s linear infinite;">
                    <path id="Path_23051"
                          data-name="Path 23051"
                          d="M87.025,208.059,79.5,203.146l-2.347-8.676a3.039,3.039,0,0,0-3.088-2.243l-8.976.449-7-5.639a3.042,3.042,0,0,0-3.817,0l-7,5.639-8.975-.449a3.031,3.031,0,0,0-3.088,2.243l-2.347,8.676-7.525,4.912a3.042,3.042,0,0,0-1.179,3.63l3.2,8.4-3.2,8.4a3.042,3.042,0,0,0,1.179,3.63l7.525,4.913,2.347,8.675a3.034,3.034,0,0,0,3.088,2.243l8.975-.449,7,5.639a3.041,3.041,0,0,0,3.817,0l7-5.639,8.976.449a3.036,3.036,0,0,0,3.088-2.243l2.347-8.675,7.525-4.913a3.042,3.042,0,0,0,1.179-3.63l-3.2-8.4,3.2-8.4A3.042,3.042,0,0,0,87.025,208.059Z"
                          transform="translate(-23.961 -186.363)"
                          fill="#D66736"></path>
                </svg>
              <span class="tw-absolute tw-text-[22px] tw-text-white tw-uppercase tw-z-20 tw-leading-none tw-font-bold tw-font-body">{{discount}}%<br />off</span>
            </div>
            <span class="tw-font-bold btn-txt tw-font-body tw-normal-case tw-ml-2"
                  i18n="@@proceedToBuy" [ngStyle]="{ 'font-size' : user.get('languagePreference') === 'mr' || user.get('languagePreference') === 'kn' ?  '13px' : '18px'}">Proceed to Buy</span>
        </button>
        <button class="tw-border-none tw-w-full tw-h-[54px] tw-relative tw-text-[#ffffff] tw-text-[18px] tw-capitalize tw-overflow-visible w100 p15 shine loop tw-bg-green-400" *ngIf="discount <= 0" (click)="checkout()">
            <span class="tw-font-bold btn-txt tw-font-body tw-normal-case tw-text-500 tw-ml-2" i18n="@@proceedToBuy">Proceed to Buy</span><span>&nbsp;&#64;₹{{totalSP}}</span>
        </button>
    </div>
</div>
<pop-up *ngIf="isDiscontinued"
        [class]="'bdr-rad-10 p15 popup-ht-unset'"
        [show]="isDiscontinued"
        [triggerDismiss]="true"
        (close)="closePopup()">
    <discontinued-popup [oldProductData]="oldProduct"
                        [newProductData]="newProduct"
                        (changeProduct)="changeProduct($event)"
                        (articleOpen)="openArticle($event)"></discontinued-popup>
</pop-up>
