import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';

@Component({ selector: 'file-picker', templateUrl: './file-picker.html' })
export class FilePickerComponent {
  @ViewChild('filePicker', { static: true }) filePicker: ElementRef;
  @Output('onFileChange') onFileChange: EventEmitter<any> = new EventEmitter<any>();
  @Input('capture') capture: string;
  @Input('accept') accept: string = 'image/*.jpeg';

  constructor(private window: WindowRefService) {
  }

  ngOnInit(): void {
    if (this.capture) this.filePicker.nativeElement.setAttribute('capture', this.capture);
  }

  openFileIntent(): void {
    if (this.filePicker.nativeElement) this.filePicker.nativeElement.click();
    else this.window.nativeWindow.document.getElementById('cameraInput').click();
  }

  uploadImageFromNativeCamera(event: any): void {
    this.onFileChange.emit(event);
  }
}
