<section *ngIf="!levelFourBanners" class="tw-bg-blue-100 tw-p-5 tw-mx-4 tw-border tw-border-gray-200 tw-rounded-xl tw-overflow-hidden tw-mb-6" style="background: white !important">
  <div class="tw-flex tw-items-center tw-gap-2 tw-mb-2">
    <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-800 tw-tracking-normal" i18n="@@completeOrder">
      Complete your order
    </h2>
  </div>
  <div class="tw-flex tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg" id="productSection" (click)="handleClick()">
    <div class="tw-flex-none tw-flex tw-justify-center tw-items-center tw-border-r tw-border-gray-200 tw-box-content" [ngClass]='{ "tw-w-2/5": productsInCart[0]?.rebrandedImageWithBackground , "tw-p-5 tw-pl-5 tw-w-24 tw-bg-orange-100": !productsInCart[0]?.rebrandedImageWithBackground}'>
      <img alt="product" class="tw-max-w-full" [ngClass]='{ "tw-max-h-full tw-min-w-full tw-min-h-full": productsInCart[0]?.rebrandedImageWithBackground , "tw-max-h-40 tw-object-contain": !productsInCart[0]?.rebrandedImageWithBackground }' [src]="productsInCart[0]?.rebrandedImageWithBackground || productsInCart[0]?.productUnboxedImage || productsInCart[0]?.rebrandedImageWithoutBackground" />
    </div>
    <div class="tw-flex-1 tw-px-4 tw-flex tw-flex-col tw-items-start tw-gap-2 tw-relative tw-pb-5" (click)="handleClick()" [ngClass]='{"tw-pt-5": !productsInCart[0]?.isDoctorAdded, "tw-pt-8": productsInCart[0]?.isDoctorAdded}'>
      <div class="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-h-6 tw-absolute tw-top-2 tw-right-0 tw-rounded-l-full tw-bg-gray-100 tw-px-1" *ngIf="productsInCart[0]?.isDoctorAdded">
        <cs-icon class="tw-h-3 tw-w-3 tw-text-white tw-bg-green-400 tw-rounded-full" [iconName]='"done"'></cs-icon>
        <p class="tw-font-body tw-text-gray-800 tw-text-100 tw-text-blue-500" i18n="@@drSuggested">
          Dr. Suggested
        </p>
      </div>
      <div>
        <h3 class="tw-font-body tw-font-bold tw-text-gray-800 tw-tracking-normal">
          {{productsInCart[0]?.title}}
        </h3>
      </div>
      <div class="tw-flex-1"></div>
      <p class="tw-font-body tw-text-300 tw-tracking-normal tw-text-gray-800">
        <span class="tw-font-bold">Total</span><span class="tw-line-through">&ngsp;&ngsp; ₹{{productsInCart[0]?.mrp}}&ngsp;</span><span class="tw-font-bold tw-text-orange-400">₹{{productsInCart[0]?.price}}</span>
      </p>
      <button class="tw-m-0 tw-min-w-unset tw-h-8 tw-px-4 tw-flex tw-items-center tw-gap-2 tw-bg-green-400" (click)="handleClick()">
        <span class="tw-normal-case tw-font-body tw-text-200 tw-tracking-normal tw-text-white tw-whitespace-break-spaces" i18n="@@buyNow">Buy Now</span><cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]='"chevron_right"'></cs-icon>
      </button>
    </div>
  </div>
</section>
<section *ngIf="levelFourBanners" class="tw-pb-2 tw-p-4 tw-m-4 tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-rounded-xl tw-overflow-hidden tw-mb-2" style="background: white !important">
  <div class="tw-flex tw-gap-6 tw-mb-4" *ngIf="!productsInCart[0]?.isDoctorAdded">
      <span>
          <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-800 tw-tracking-normal" i18n="@@completeOrder">
              Complete your order
          </h2>
          <p class="tw-font-body tw-text-300 tw-text-gray-500" i18n="@@oneProductInCart">
              1 Product in your cart
          </p>
      </span>
  </div>
  <div class="tw-flex tw-gap-4 tw-mb-4" id="doctorAddedSection" *ngIf="productsInCart[0]?.isDoctorAdded">
    <span class="tw-relative tw-inline-block">
        <img alt="dr" class="tw-flex-none tw-h-12 tw-w-12" [src]="userDrImage" />
        <img alt="tick" src="/assets/icons/orange_tick_new.svg" class="tw-absolute tw-top-[2px] tw-right-[-1px] tw-h-4 tw-w-4" id="tick">
    </span>
    <span>
        <h2 class="tw-font-body tw-font-bold tw-text-500 tw-text-gray-800 tw-tracking-normal" i18n="@@drAddedProducts">
          Doctor-added products
        </h2>
        <p class="tw-font-body tw-text-300 tw-text-gray-500" i18n="@@basisCheckupReport">
          Based on your checkup report
        </p>
    </span>
</div>
  <div class="tw-flex tw-bg-white tw-rounded-2 tw-border-2 tw-border-gray-100 tw-mb-4 tw-relative tw-overflow-hidden" (click)="handleClick()">
        <div class="tw-flex-none tw-flex tw-justify-center tw-items-center tw-box-content tw-w-2/5">
          <img alt="product" class="tw-h-[144px] tw-max-w-full tw-max-h-full tw-min-w-full tw-min-h-full" [src]="productsInCart[0]?.rebrandedImageWithBackground || productsInCart[0]?.productUnboxedImage || productsInCart[0]?.rebrandedImageWithoutBackground" />
        </div>
        <div class="tw-pl-4 tw-pr-4 tw-flex tw-flex-col tw-items-start tw-relative tw-pb-4 tw-pt-4" (click)="handleClick()">
          <p class="tw-font-body tw-text-300 tw-text-gray-800 tw-tracking-normal tw-mb-2">
              {{productsInCart[0]?.title}}
          </p>
          <p class="tw-font-body tw-text-300 tw-tracking-normal">
              <span class="tw-relative tw-inline-block">
              <span class="tw-relative z-10 tw-text-gray-400">&ngsp;&ngsp; ₹{{productsInCart[0]?.mrp}}&ngsp;</span>
                <span class="tw-absolute tw-top-1/2 tw-left-0 tw-right-0 tw-h-[1px] tw-bg-gray-400 tw-w-[90%] tw-mx-auto"></span>
              </span>
              <span class="tw-font-bold tw-text-orange-400">&nbsp;₹{{productsInCart[0]?.price}}</span>
          </p>
      </div>
      <div *ngIf="productsInCart[0]?.isDoctorAdded" class="tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-text-center tw-gap-2 tw-bg-orange-100 tw-flex tw-items-center tw-justify-center">
        <cs-icon class="tw-h-3 tw-w-3 tw-text-bold tw-text-orange-400" [iconName]='"done"'></cs-icon>
            <p class="tw-font-body tw-text-orange-400 tw-text-100 tw-whitespace-nowrap" i18n="@@drAdded">
               Dr. Added
            </p>
      </div>
  </div>
  <div class="tw-w-full tw-mb-2 tw-shadow-[0_2px_4px_0_rgba(0,0,0,0.10)] tw-rounded-[12px]" (click)="handleClick()">
    <div class="tw-relative tw-flex tw-h-[40px] tw-px-[24px] tw-rounded-[12px] tw-bg-teal-600 tw-py-[8px] tw-justify-center tw-items-center tw-gap-[8px] tw-text-white tw-group">
      <div class="shine loop tw-absolute tw-inset-0 tw-bg-gradient-to-r tw-from-transparent tw-via-white/50 tw-to-transparent tw-pointer-events-none"></div>
      <div *ngIf="cartDiscount > 5" class="tw-absolute tw-flex tw-items-center" style="left: 16px;">
        <svg class="anim-image orange-spin tw-invert-[0.35] tw-sepia-[0.69] tw-saturate-[1,30,300] tw-hue-rotate-[349deg] tw-brightness-[0.86] tw-contrast-[0.88] tw-h-[56px] tw-w-[56px]" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64.443 67.447">
          <path id="Path_23051" data-name="Path 23051" d="M87.025,208.059,79.5,203.146l-2.347-8.676a3.039,3.039,0,0,0-3.088-2.243l-8.976.449-7-5.639a3.042,3.042,0,0,0-3.817,0l-7,5.639-8.975-.449a3.031,3.031,0,0,0-3.088,2.243l-2.347,8.676-7.525,4.912a3.042,3.042,0,0,0-1.179,3.63l3.2,8.4-3.2,8.4a3.042,3.042,0,0,0,1.179,3.63l7.525,4.913,2.347,8.675a3.034,3.034,0,0,0,3.088,2.243l8.975-.449,7,5.639a3.041,3.041,0,0,0,3.817,0l7-5.639,8.976.449a3.036,3.036,0,0,0,3.088-2.243l2.347-8.675,7.525-4.913a3.042,3.042,0,0,0,1.179-3.63l-3.2-8.4,3.2-8.4A3.042,3.042,0,0,0,87.025,208.059Z" transform="translate(-23.961 -186.363)" fill="#D66736"></path>
        </svg>
        <span class="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-leading-4 tw-text-300 tw-font-body tw-z-0 tw-uppercase tw-font-bold">
          {{ cartDiscount }}% <br /> off
        </span>
      </div>
      <span class="tw-font-body tw-font-bold tw-lowercase tw-text-300 tw-tracking-normal tw-normal-case">
        <span i18n="@@buyNow">Buy Now</span><span>&nbsp;&#64; ₹{{ totalSp }}</span>
      </span>
    </div>
  </div>
</section>
