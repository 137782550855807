/**
 * Generate a hash with phonenumber+time+salt
 * Backend will generate the has with same mechanism and will ensure that request
 * is coming from right origin.
 * @param phone Phone number
 * @param time curren time string
 * @returns hash
 */
export async function generateDigest(phone: string, time: string): Promise<string> {
  const s = 'rN5SAoSYt(n|Iz@j!1$o6o)\\D,AoGn#fCJ7&-F^@?<<I;{hCE3';
  const ms = `${phone}|${time}|${s}`;
  const msgUint8 = new TextEncoder().encode(ms);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b: any): string => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
}
