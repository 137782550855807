import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions, Pagination, Swiper } from 'swiper';
import { ConnectionService } from '@services/connection-service';

SwiperCore.use([Autoplay, Scrollbar, Pagination]);

@Component({
  selector: 'app-week1-part-one',
  templateUrl: './week1-part-one.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class Week1PartOneComponent implements OnChanges {
  carouselConfigWeeklyJourney: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
  };
@Input('user')user:any;
@Input('pendingCheckInCall')pendingCheckInCall:any;
@Input('faceRegimenOrderDelivered')faceRegimenOrderDelivered:any;
@Input('latestFaceRegimenDelivered')latestFaceRegimenDelivered:any;
@Output('openInstructions')openInstructions:EventEmitter<string> = new EventEmitter();
@Output('openStaticArticle')openStaticArticle:EventEmitter<string> = new EventEmitter();
@Output('openHelp')openHelp:EventEmitter<string> = new EventEmitter();
@Output('openCheckup')openCheckup:EventEmitter<string> = new EventEmitter();

constructor(private conn: ConnectionService) {
}

ngOnChanges(): void {}
handleInstructions(card:string):void {
  this.openInstructions.emit(card);
}
handleArticle(card:string):void {
  this.openStaticArticle.emit(card);
}
handleHelp(card:string):void {
  this.openHelp.emit(card);
}
handleCheckups(card:string): void {
  this.openCheckup.emit(card);
}
handleCTA(): void {
  this.conn.navigateToURL('https://app.cureskin.com/user?tab=explore&id=iv6pdwK1x9');
}
}
