import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/index';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'user-assistant', templateUrl: './user-assistant.html', styleUrls: ['./user-assistant.scss'] })
export class UserAssistantComponent {
  user: any;
  selectedIndex: number;
  tabData: any = { count: 2 };
  toolbarIcons: Array<any> = [];
  showRegimenChat: boolean;
  subscriptions: Subscription[] = [];
  @Input('tabUpdates') tabUpdates: any;
  constructor(private conn: ConnectionService,
    private router: Router,
    private eventLogger: EventLoggerService,
    private route: ActivatedRoute, private appConfig: AppConfig) {
  }

  async ngOnInit(): Promise<any> {
    await this.router.navigate(['/home']);
    this.subscriptions.push(this.route.queryParams.subscribe(async (params: any): Promise<void> => {
      const index = params.tab === 'followups' ? 1 : 0;
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ selectedTab: params.tab, pageName: 'chat' }));
      if (this.selectedIndex === index) return;
      this.selectedIndex = index;
      this.user = await this.conn.getActingUser();
      const regimens = await this.conn.fetchRegimens();
      this.showRegimenChat = !this.conn.isInternalUser()
        && !this.user.isPaid()
        && !regimens.length;
      this.tabData.selectedTab = this.selectedIndex;
    }));
  }

  home(): void {
    const queryParams: any = { tab: 'home' };
    this.router.navigate(['/user'], { queryParams });
  }

  tabChange(index: any): void {
    const queryParams: any = {};
    if (this.selectedIndex === index) return;
    if (index === 0) {
      queryParams.tab = 'chat';
      this.router.navigate([], { queryParams, replaceUrl: true, relativeTo: this.route });
    }
    if (index === 1) {
      queryParams.tab = 'followups';
      this.router.navigate([], { queryParams, replaceUrl: true, relativeTo: this.route });
    }
  }

  tabSwitch(event: any): void {
    let curIndex = this.selectedIndex;
    if (event === 'LEFT' && curIndex < 1) {
      curIndex += 1;
    } else if (event === 'RIGHT' && curIndex > 0) {
      curIndex -= 1;
    }
    this.tabChange(curIndex);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((each: Subscription): void => each.unsubscribe());
  }
}
