import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '@components/loading';
import { ToolbarModule } from '@components/toolbar';
import { DirectivesModule } from '@directives/directives.module';
import { ImageCardModule } from '../image-card/image-card.module';
import { MultiImagesComponent } from './multiImages.component';

@NgModule({
  imports: [CommonModule, RouterModule, LoadingModule, DirectivesModule, ImageCardModule, ToolbarModule],
  declarations: [MultiImagesComponent],
  exports: [MultiImagesComponent],
})
export class MultiImagesModule {}
