<ng-container>
  <!-- <cross-regimen-banner *ngIf="regimen"></cross-regimen-banner> -->
  <div class="w100 h100 font-noto tw-bg-yellow-100 pT60" *ngIf="regimen">
  <img class="diB m-auto" [src]="iconSrc" [class]="regimen.class" style="width: 80px; height: 80px" />
  <div class="text" *ngIf="regimen.expired && regimen.class !== appConfig.Shared.Regimen.Class.HAIR" i18n="@@skinTreatmentExpired">
    Your treatment kit has expired. Your skin condition might have changed.
    Please click below to get a new treatment.
  </div>
  <div class="text" *ngIf="regimen.expired && regimen.class === appConfig.Shared.Regimen.Class.HAIR" i18n="@@hairTreatmentExpired">
    Your treatment kit has expired. Your hair condition might have changed.
    Please click below to get a new treatment kit.
  </div>
  <div class="text tw-text-center" *ngIf="!regimen.expired && regimen.class !== appConfig.Shared.Regimen.Class.HAIR">
    <p class="tw-text-400 tw-font-body tw-font-bold" i18n="@@skinGetYourNewTreatment">
    Get started now with doctor given kit for your skin. It only takes 2
    minutes!
    </p>
  </div>
  <div class="text tw-text-center" *ngIf="!regimen.expired && regimen.class === appConfig.Shared.Regimen.Class.HAIR">
    <p class="tw-text-300 tw-font-body tw-mb-4" i18n="@@hairGetYourNewTreatment">
    Get started now with doctor given kit for your hair. It only takes 2
    minutes!
    </p>
  </div>
  </div>
</ng-container>