import { Component, ElementRef, Input, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { WindowRefService } from '@services/window-ref-service';
import { EventLoggerService } from '@services/event-logger-service';
import { CurrentComponentService } from '@services/current-component';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'user-explore',
  templateUrl: './user-explore.html',
  styleUrls: ['./user-explore.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserExploreComponent implements OnDestroy {
  isTabActive: boolean;
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  onScroll(): void {
    const scrollTop = this.scrollContainer?.nativeElement?.scrollTop;
    if (scrollTop >= this.feedsJSON.length * 200) this.loadMoreFeedContent();
  }
  @Input('refresh')
  set refresh(isActive: boolean) {
    this.isTabActive = isActive;
    if (this.isTabActive) {
      if (this.scrollContainer) this.scrollContainer.nativeElement.scrollTop = 0;
    }
  }
  @Input('animation') animation: any;
  user: any;
  activeVideoElementIndex: number;
  userStatus: any = { PAID: false, GOT_REGIMEN: false, NO_PHOTO: false };
  username: string;
  feedById: any;
  feeds: any[] = [];
  throttledFeeds: any[] = [];
  feedsJSON: any[] = [];
  loading: boolean = true;
  unBoughtRegimen: any;
  latestIntersectedIndex: number;
  firstExecution: boolean = true;
  isExecuting: boolean = false;

  constructor(private router: Router,
    private conn: ConnectionService,
    private broadcast: BroadcastService,
    private window: WindowRefService,
    private route: ActivatedRoute,
    private eventLogger: EventLoggerService,
    private appConfig: AppConfig,
    private currentComponentService: CurrentComponentService,
    private changeDetector: ChangeDetectorRef) {
    this.currentComponentService.set(this);
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.conn.getActingUser();
    if (this.user.isPaid() && this.user.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED) this.userStatus.PAID = true;
    if (this.isExecuting) return;
    this.loading = true;
    this.isExecuting = true;
    await this.fetchFeed();
    this.loading = false;
    this.isExecuting = false;

    if (this.firstExecution) {
      const feedId = this.route.snapshot.queryParams.id;
      if (feedId) await this.prependFeed(feedId);
      this.firstExecution = false;
      this.loading = false;
      this.isExecuting = false;
    }
    this.changeDetector.detectChanges();
  }

  /**
   * Prepend the feed to the top of the feeds if `/explore/:id` is present
   */
  async prependFeed(feedId: string): Promise<void> {
    if (!feedId) return;
    this.feedById = await this.conn.fetchExplorePostById(feedId);
    if (!this.feedById) return;
    if (this.user && this.user.get('marketingTags')) {
      const isTagMatch = (<string[]> this.user.get('marketingTags'))
        .some((tag: string): boolean => (this.feedById.get('categoryTag') === tag || this.feedById.get('classTag') === tag));
      if (!isTagMatch) this.feedById = null;
      this.feeds = [this.feedById, ...this.feeds];
      this.feedsJSON = JSON.parse(JSON.stringify(this.feeds));
    }
  }

  async fetchFeed(): Promise<any> {
    this.feeds = await this.conn.fetchFeeds();
    if (this.feedById) {
      this.feeds = this.feeds.filter((feed: any): any => {
        if (feed.id === this.feedById?.id) return false;
        return true;
      });
    }
    this.throttledFeeds = [...this.throttledFeeds, ...this.feeds.slice(0, 5)];
    const response = JSON.parse(JSON.stringify(this.throttledFeeds));
    this.feedsJSON = [...this.feedsJSON, ...response];
    this.setElementIntersectionObservers();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'explore' }));

    const regimens = await this.conn.fetchRegimens();
    this.unBoughtRegimen = regimens.find((each: any): boolean => !each.active && !each.expired && !each.orderPlaced);
  }

  loadMoreFeedContent(): void {
    if (this.throttledFeeds.length >= this.feeds.length) return;
    const startIndex = this.throttledFeeds.length;
    const endIndex = startIndex + 5;
    this.throttledFeeds = [...this.throttledFeeds, ...this.feeds.slice(startIndex, endIndex)];
    const response = JSON.parse(JSON.stringify(this.throttledFeeds));
    this.feedsJSON = [...response];
  }

  async onCTAClick(feed: any): Promise<void> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: `explore-${this.getEventFormattedString(feed.ctaText)}` }));
    this.eventLogger.trackEvent('feed_cta_click', { url: feed.ctaUrl, feed_id: feed.objectId });
    await this.conn.navigateToURL(feed.ctaUrl);
  }

  // Input -> Check my regimen
  // Output -> check-my-regimen
  getEventFormattedString(eventName: string): string {
    const formattedString = eventName.split(' ').join('-').toLowerCase();
    return formattedString;
  }
  async updateIsLikedFromFeedComponent(liked: boolean, index: number): Promise<void> {
    const feed = this.feeds[index];
    const prevLikedValue = feed?.isLiked;
    feed.isLiked = liked;
    try {
      if (feed.isLiked !== prevLikedValue) {
        await this.conn.likeFeed(feed.objectId);
      }
      // this.feedsJSON[index] = JSON.parse(JSON.stringify(feed));
      this.eventLogger.trackEvent('feed_like', { liked: this.feedsJSON[index].liked, feed_id: feed.objectId });
    } catch (error) {
      feed.isLiked = prevLikedValue;
      this.feedsJSON[index] = JSON.parse(JSON.stringify(feed));
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  buyRegimen(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'explore-buy-regimen' }));
    this.eventLogger.trackEvent('BUY_NOW_CLICKED');
    this.eventLogger.trackInFirebaseAndBranch('BUY_NOW_CLICKED');
    if (this.unBoughtRegimen.class === this.appConfig.Shared.Regimen.Class.HAIR) {
      this.eventLogger.trackEvent('BUY_NOW_CLICKED_HAIR');
      this.eventLogger.trackInFirebaseAndBranch('BUY_NOW_CLICKED_HAIR');
    }
    this.conn.navigateToURL(`/user?tab=regimen&class=${this.unBoughtRegimen.class}`);
  }

  /**
   * Creates IntersectionObserver to the scroll container and makes all the children of container to observe for the callback.
   * Callback is called when any child's visibility reaches 0.3(30%) or 0.75(75%) within the scroll container viewport.
   */
  setElementIntersectionObservers(limit: number = 15): void {
    if (!this.scrollContainer?.nativeElement && limit > 0) {
      // scrollContainer not available yet, wait and try again
      setTimeout((): void => this.setElementIntersectionObservers(limit - 1), 100);
      return;
    }
    const options = {
      root: this.scrollContainer?.nativeElement,
      rootMargin: '0px',
      threshold: [0.3, 0.75],
    };
    const observer = new IntersectionObserver(this.elementIntersectionCallback, options);
    this.feeds.forEach((each: any, index: number): void => {
      const element = this.window.nativeWindow.document.getElementById(`${index}-feed`);
      if (element) observer.observe(element);
    });
  }

  /**
   * When any elements intersection reaches options.threshold ([0.3, 0.75]) this callback get executed.
   * if element has 0.75 intersectionRatio, then we mark that feed isVisible to true.
   * if element has 0.3 intersectionRatio, then we mark that feed isVisible to false.
   * if any new feed becomes visible, then current active playing video feed is forced to become isVisible false
   * in order to pause the video.
   */
  elementIntersectionCallback = (intersectionArray: any): void => {
    const MIN_THRESHOLD = 0.5;
    if (!intersectionArray.length || !this.feedsJSON.length) return;

    const intersectionObj = intersectionArray[0];
    const { id }: { id: string } = intersectionObj.target;
    const index = Number(id.split('-feed')[0]);
    if (this.latestIntersectedIndex === index) return;
    this.latestIntersectedIndex = index;
    if (!this.feedsJSON[index]) return;
    this.feedsJSON[index].isVisible = intersectionObj.intersectionRatio > MIN_THRESHOLD;

    if (this.activeVideoElementIndex >= 0
      && this.activeVideoElementIndex !== index
      && this.feedsJSON[index].isVisible) {
      this.makeVisibilityOfActiveVideoToFalse();
    }
  };

  /**
   * We are forcing the isVisible of active video to false, even if its false before. in order to pause the video.
   */
  makeVisibilityOfActiveVideoToFalse(): void {
    if (!this.feedsJSON[this.activeVideoElementIndex].isVisible) this.feedsJSON[this.activeVideoElementIndex].isVisible = true;
    setTimeout((feed_: any): void => {
      const feed = feed_;
      feed.isVisible = false;
      delete this.activeVideoElementIndex;
    }, 0, this.feedsJSON[this.activeVideoElementIndex]);
  }

  /**
   * Makes the current 'activeVideoElementIndex' feeds visibility to false.
   * And Stores the index of active video in 'activeVideoElementIndex' variable.
   * @param {number} index - index of active video which is playing.
   */
  onVideoPlay(index: number): void {
    this.eventLogger.trackEvent('feed_video_played', {
      liked: this.feedsJSON[index].liked,
      feed_id: this.feedsJSON[index].objectId,
    });
    if (this.activeVideoElementIndex >= 0 && this.activeVideoElementIndex !== index) {
      this.makeVisibilityOfActiveVideoToFalse();
    } else this.activeVideoElementIndex = index;
  }

  trackById(index: number): number {
    return index;
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  stopPropagation(event: any): void {
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.conn.updateLastActiveTime('EXPLORE_TAB');
    this.currentComponentService.remove(this);
  }
}
