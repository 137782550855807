<div class="shop">
  <section>
    <div class="title" i18n="@@getTreatmentKitFor">Get a treatment kit for</div>
    <regimen-concern-selection (onSelect)="onSelectConcern($event)" [gender]="user?.get('Gender')"></regimen-concern-selection>
  </section>
  <section *ngIf="user?.isPaid()">
    <div class="header">
      <div class="title" i18n="@@recommProducts">Recommended products</div>
      <div class="title float-right" i18n="@@viewAll" (click)="viewAll('recommendedProducts')">View all</div>
    </div>
    <loading class="pos-abs vh-center" *ngIf="isAddOnProductLoading$ | async"></loading><ng-container *ngIf="!(isAddOnProductLoading$ | async) && !(addOnProducts$ | async)?.length">
      <div *ngTemplateOutlet="notFound"></div>
    </ng-container><product-carousel *ngIf="(addOnProducts$ | async)?.length" [products]="addOnProducts$ | async"></product-carousel>
  </section>
  <section *ngIf="user?.isPaid() && ((isReorderProductLoading$ | async) || (reorderProducts$ | async)?.length)">
    <div class="header">
      <div class="title" i18n="@@treatmentProducts">Treatment Kit Products</div>
      <div class="title float-right" i18n="@@viewAll" (click)="viewAll('reorderProducts')">View all</div>
    </div>
    <loading class="pos-abs vh-center" *ngIf="isReorderProductLoading$ | async"></loading><ng-container *ngIf="!(isReorderProductLoading$ | async) && !(reorderProducts$ | async)?.length">
      <div *ngTemplateOutlet="notFound"></div>
    </ng-container><product-carousel *ngIf="(reorderProducts$ | async)?.length" [products]="reorderProducts$ | async"></product-carousel>
  </section>
  <ng-template #notFound="">
    <div class="bold text-center pos-abs vh-center clr-secondary mT20" i18n="@@noProductsFound">
      No products found.
    </div>
  </ng-template>
</div>