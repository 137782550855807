import { Directive, HostListener, Input } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service/index';

@Directive({ selector: '[loadingEffect]' })
export class LoadingEffectDirective {
  throttle: boolean;
  @Input('color') color: string;
  @Input('bgClass') bgClass: string;
  @Input('timeout') timeout: any;
  static parameters: Array<any> = [WindowRefService];
  constructor(private window: WindowRefService) {}

  @HostListener('click', ['$event'])
  handleClick(event: any): void {
    if (this.throttle) return;
    this.throttle = true;
    const node = event.target.closest('[loadingEffect]');
    const nodeStyles = this.window.nativeWindow.getComputedStyle(node);
    const div = this.window.nativeWindow.document.createElement('div');
    if (!this.bgClass) {
      div.style.background = nodeStyles.getPropertyValue('background')
        || nodeStyles.getPropertyValue('background-color') || '#fff';
    } else div.classList.add(this.bgClass);
    div.style.width = '100%';
    div.style.height = '100%';
    div.classList.add('pos-abs');
    div.classList.add('flex');
    div.classList.add('justify-center');
    node.classList.add('of-hdn');
    div.innerHTML = `<svg class="circle-loader" style="stroke:${this.color}; stroke-width:2" width="30"
      height="30" version="1.1" xmlns="http://www.w3.org/2000/svg"><circle cx="15" cy="15" r="12"></circle></svg>`;
    node.appendChild(div);
    setTimeout((): void => {
      node.removeChild(div);
      node.classList.remove('of-hdn');
      this.throttle = false;
    }, this.timeout || 5000);
  }
}
