import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { VideoPlayerComponent } from '@components/video-player/video-player.component';
import { WindowRefService } from '@services/window-ref-service';

@Component({ selector: 'feed-video', templateUrl: './video.html', styleUrls: ['./video.scss', '../../user-explore.scss'] })
export class VideoComponent {
  MAX_WIDTH: number = 500;
  @Input('index') index: number;
  @Input('isLiked') isLiked: boolean;
  @Input('feed') set setFeed(feed: any) {
    this.feed = JSON.parse(JSON.stringify(feed));
  }
  feed: any;
  @Input('visible') set setVisibility(isVisible: boolean) {
    if (isVisible === false) this.pauseVideo();
  }
  @ViewChild(VideoPlayerComponent, { static: false }) videoPlayerComponent: VideoPlayerComponent;
  @Output('likeToggle') likeToggle:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('onVideoPlay') onVideoPlay:EventEmitter<number> = new EventEmitter<number>();
  @Output('onCTAClick') onCTAClick:EventEmitter<any> = new EventEmitter<any>();
  style: { width?: number, height?: number } = {};
  playLikeAnimation: boolean = false;
  timeoutInterval: any;
  clickCount: number = 0;
  constructor(private window: WindowRefService) {
  }

  /**
   * We calculate width & height for the element from aspectRation ('w:h') string set in feed.
   * Example: 1:2
   * width:  windowWidth * 1
   * height: windowWidth * 2
   * If aspect ration is not set in feed, then width takes full screen width.
   * i.e width: windowWidth.
   */
  ngOnInit(): void {
    let windowWidth = this.window.nativeWindow.innerWidth;
    if (this.window.nativeWindow.innerWidth > this.MAX_WIDTH) windowWidth = this.MAX_WIDTH;
    if (this.feed.aspectRatio) {
      this.style.width = Number(this.feed.aspectRatio.split(':')[0]) * windowWidth;
      this.style.height = Number(this.feed.aspectRatio.split(':')[1]) * windowWidth;
    } else {
      this.style.width = windowWidth;
    }
  }

  onPlay(): void {
    this.feed.visible = true;
    this.onVideoPlay.emit(this.index);
  }

  onLikeClick(): void {
    this.clickCount += 1;
    if (this.clickCount === 1) {
      setTimeout(():void => {
        if (this.clickCount > 1) {
          this.isLiked = !this.isLiked;
          if (this.isLiked) this.playHeartAnimation();
          this.likeToggle.emit(this.isLiked);
        }
        this.clickCount = 0;
      }, 500);
    }
  }

  playHeartAnimation(): void {
    clearTimeout(this.timeoutInterval);
    this.playLikeAnimation = true;
    this.timeoutInterval = setTimeout((): boolean => this.playLikeAnimation = false, 500);
  }

  pauseVideo(): any {
    if (this.videoPlayerComponent && !this.videoPlayerComponent.isPaused) {
      this.videoPlayerComponent.videoTogglePlayPause();
    }
  }
}
