import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@directives/directives.module';
import { LoadingModule } from '../loading';
import { VideoPlayerComponent } from './video-player.component';

@NgModule({
  imports: [CommonModule, DirectivesModule, LoadingModule],
  declarations: [VideoPlayerComponent],
  exports: [VideoPlayerComponent],
})
export class VideoPlayerModule {}
