import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SwitchComponent } from './switch/switch.component';
import { RadioBtnComponent } from './radio-btn/radio-btn.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [CheckboxComponent, SwitchComponent, RadioBtnComponent],
  exports: [CheckboxComponent, SwitchComponent, RadioBtnComponent],
})
export class InputModule {}
