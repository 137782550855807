import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from '@components/loading';
import { ToolbarModule } from '@components/toolbar';
import { ButtonModule } from '@components/button';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { DirectivesModule } from '@directives/directives.module';
import { CsIconModule } from '@components/icon';
import { MainConcernComponent } from './main-concern.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoadingModule,
    ToolbarModule,
    ToolbarTabsModule,
    ButtonModule,
    DirectivesModule,
    CsIconModule,
  ],
  declarations: [MainConcernComponent],
  exports: [],
})
export class MainConcernModule {}
