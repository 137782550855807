import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { SelectSubQueryComponent } from './select-sub-query.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
  ],
  declarations: [SelectSubQueryComponent],
  exports: [SelectSubQueryComponent],
})
export class SelectSubQueryModule {}
