<ng-container *ngIf="isRebrandingV1; then rebrandedTemplateV1 else originalTemplate"></ng-container>

<ng-template #originalTemplate>
  <!-- Original template -->
  <section class="card takePhoto getSkinAnalysis pos-rel flex-col m16 bdr-rad-20 pB20 tw-overflow-hidden tw-rounded-tr-4 tw-rounded-tl-4" id="takePhoto" (click)="handleClick()">
    <video-player class="tw-block tw-overflow-hidden tw-mb-4" [src]="
      'https://storage.googleapis.com/heallocontent/app/img/take-photo.mp4'
    " autoplay="true" [poster]="
      'https://storage.googleapis.com/heallocontent/app/img/take-photo-thumbnail.jpg'
    " [hideAudioControl]="true"></video-player>
    <div class="nutritionist pos-abs pos-top-left mL15 mT15" i18n="@@takePhoto">Take Photo</div>
    <div class="fw-700 font20 mL16" i18n="@@takePhoto">Take Photo</div>
    <div class="mL16 w80" i18n="@@getDetailedAnalysis"></div>
    <div class="tw-flex tw-items-end">
      <div class="tw-flex-1 tw-flex tw-flex-col tw-self-center">
        <div class="mL16" i18n="@@getDetailedSkinAndHairAnalysis">
          Get detailed skin & hair analysis
        </div>
      </div>
      <div class="tw-mr-4">
        <cs-button [class]="'primary btn-xxxs tw-px-4'"><cs-button-text i18n="@@takePhoto">Take Photo</cs-button-text><cs-icon class="tw-h-4 tw-w-4" [iconName]="'chevron_right'"></cs-icon></cs-button>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #rebrandedTemplateV1>
  <!-- Rebranded template v1 -->
  <div class="tw-relative tw-flex tw-flex-col tw-mx-4 tw-my-6 tw-rounded-5 tw-overflow-hidden tw-bg-gray-50"
    id="takePhoto" (click)="handleClick()">
    <video-player class="tw-block tw-overflow-hidden"
      [src]="'https://storage.googleapis.com/heallocontent/app/img/take-photo.mp4'" autoplay="true"
      [poster]="'https://storage.googleapis.com/heallocontent/app/img/take-photo-thumbnail.jpg'"
      [hideAudioControl]="true"></video-player>
    <div class="tw-flex tw-p-4 tw-items-end tw-gap-2">
      <div class="tw-flex tw-flex-col tw-grow">
        <div class="tw-font-bold tw-text-600 tw-text-gray-800" i18n="@@takePhoto">Take Photo</div>
        <div class="tw-w-[80%] tw-text-gray-800" i18n="@@getDetailedAnalysis"></div>
        <div class="tw-mt-2 tw-text-300 tw-text-gray-800" i18n="@@getDetailedSkinAndHairAnalysis">
          Get detailed skin & hair analysis
        </div>
      </div>
      <cs-icon class="tw-w-8 tw-h-8 tw-shrink-0" [iconName]="'click-icon'"></cs-icon>
    </div>
  </div>
</ng-template>