import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions, Pagination, Swiper } from 'swiper';

SwiperCore.use([Autoplay, Scrollbar, Pagination]);
@Component({
  selector: 'app-week-zero-journey',
  templateUrl: './week-zero-journey.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class WeekZeroJourneyComponent implements OnChanges {
  carouselConfigWeeklyJourney: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
  };
@Input('user')user:any;
@Input('expectedDeliveryDate')expectedDeliveryDate:any;
@Input('latestPlacedRegimenOrder')latestPlacedRegimenOrder:any;
@Output('trackOrder')trackOrder:EventEmitter<any> = new EventEmitter();
@Input('allocatedDoctor')allocatedDoctor:any;
@Input('userDrImage')userDrImage:string;
@Input('defaultDoctorImage')defaultDoctorImage:string;
@Input('primaryConcern')primaryConcern:string;
@Input('otherConcerns')otherConcerns:string;
@Output('openInstructions')openInstructions:EventEmitter<string> = new EventEmitter();
@Output('openDiet')openDiet:EventEmitter<string> = new EventEmitter();
@Output('openHelp')openHelp:EventEmitter<string> = new EventEmitter();
constructor() { }
kitImg:string = '';
ngOnChanges(): void {
  this.kitImg = (this.latestPlacedRegimenOrder?.get('regimen')?.get('regimenCoverImage'));
}
handleOrder(url:string):void {
  this.trackOrder.emit(url);
}
handleInstructions(card:string):void {
  this.openInstructions.emit(card);
}
handleDiet(dietCard:string):void {
  this.openDiet.emit(dietCard);
}
handleHelp(helpCard:string):void {
  this.openHelp.emit(helpCard);
}
}
