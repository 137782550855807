<ng-container *ngIf="isRebrandingV1; then rebrandedTemplateV1 else originalTemplate"></ng-container>

<ng-template #originalTemplate>
  <!-- Original template -->
  <section class="tw-flex tw-flex-col tw-bg-white tw-px-4 tw-pt-5 tw-pb-5">
    <div class="tw-font-body tw-font-bold tw-text-black tw-text-600 tw-mb-1" i18n="@@clearYourDoubts">
      Clear your doubts
    </div>
    <div class="tw-font-body tw-text-gray-500 tw-text-200 tw-mb-3" i18n="@@anythingToHelp">
      Is there anything you want to know ?
    </div>
    <div class="tw-w-full tw-flex tw-items-center tw-gap-2">
      <div *ngFor="let data of doubtsData; let i = index" id="doubts"
           class="tw-w-full tw-bg-teal-200 tw-relative tw-rounded-4 tw-min-h-50 flex tw-justify-center tw-items-center"
           (click)="playVideo(i)">
        <div
          class="tw-absolute tw-top-5 tw-px-3 tw-left-0 tw-w-full tw-flex tw-gap-1 tw-justify-between tw-items-start tw-z-[5]">
          <h1 class="tw-flex-1 tw-font-body tw-text-500 tw-font-bold">{{ data.title }}</h1>
          <span id="play-button" *ngIf="!isPlaying"
                class="tw-w-fit tw-mt-2 tw-bg-gray-50 tw-p-2.5 tw-py-[10.5px] tw-rounded-full tw-flex tw-justify-center tw-items-center tw-cursor-pointer">
            <svg viewBox="-3 0 28 28" version="1.1" style="width: 16px; margin-left: 4px;"
                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000" stroke="#000000"><g
              id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                             stroke-linejoin="round"></g><g
              id="SVGRepo_iconCarrier"> <title>play</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g
              id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g
              id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-419.000000, -571.000000)"
              fill="#000000"> <path
              d="M440.415,583.554 L421.418,571.311 C420.291,570.704 419,570.767 419,572.946 L419,597.054 C419,599.046 420.385,599.36 421.418,598.689 L440.415,586.446 C441.197,585.647 441.197,584.353 440.415,583.554"
              id="play" sketch:type="MSShapeGroup"> </path> </g> </g> </g></svg>
          </span>
        </div>
        <img src={{data.image}} class="tw-w-full tw-h-56 tw-rounded-4 tw-object-cover tw-relative tw-z-0" alt="">
      </div>
    </div>
    <!-- Video container -->
    <div id="video-container" [ngClass]="{'tw-hidden' : !isPlaying}"
         class="video-container tw-flex tw-flex-col tw-justify-center tw-items-center tw-fixed tw-h-full tw-w-full tw-inset-0 bottom-0 tw-bg-black tw-z-50 tw-min-h-screen">
      <video-player id="videoPlayer" [src]="activeVideo" [autoplay]="true" [poster]="activeThumbnail" [loop]="false" (onVideoEnd)="onVideoEnd()" class="video-container tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-inset-0 bottom-0 tw-z-10 tw-min-h-screen"></video-player>
      <span
        id="close-video"
        class="tw-absolute tw-top-4 tw-right-3 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-text-white tw-font-bold tw-text-xl tw-z-10 close-video-button"
        (click)="closeVideo()">
        <svg class="tw-h-6 tw-w-6" fill="currentColor" viewBox="0 0 24 24">
          <path
            d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path>
        </svg>
      </span>
    </div>
  </section>
  <section class="banners" *ngFor="let banner of bannersTop" (click)="handleBanner(banner)">
    <img [src]="imageService.getURL(banner.image)"/>
  </section>
</ng-template>

<ng-template #rebrandedTemplateV1>
  <!-- Rebranded template v1 -->
  <section class="tw-flex tw-flex-col tw-bg-white tw-px-4 tw-py-6 tw-my-6">
    <div class="tw-font-body tw-font-bold tw-text-gray-800 tw-text-600 tw-mb-4" i18n="@@clearYourDoubts">
      Clear your doubts
    </div>
    <div class="tw-w-full tw-flex tw-items-stretch tw-gap-2">
      <div *ngFor="let data of doubtsData; let i = index" id="doubts"
        class="tw-flex tw-flex-col tw-w-full tw-bg-teal-100 tw-rounded-[15px] tw-min-h-50" (click)="playVideo(i)">
        <div class="tw-px-4 tw-py-4 tw-flex tw-gap-2 tw-justify-between tw-items-start">
          <h1 class="tw-flex-1 tw-font-body tw-text-400 tw-text-gray-800">{{ data.title }}</h1>
          <cs-icon *ngIf="!isPlaying" id="play-button" class="tw-h-8 tw-w-8"
            [iconName]="'/rebranding/v1/play_icon'"></cs-icon>
        </div>
        <img src={{data.image}} class="tw-w-full tw-object-cover tw-rounded-b-[15px] tw-mt-auto" alt="">
      </div>
    </div>
    <!-- Video container -->
    <div id="video-container" [ngClass]="{'tw-hidden' : !isPlaying}"
      class="video-container tw-flex tw-flex-col tw-justify-center tw-items-center tw-fixed tw-h-full tw-w-full tw-inset-0 bottom-0 tw-bg-black tw-z-50 tw-min-h-screen">
      <video-player id="videoPlayer" [src]="activeVideo" [autoplay]="true" [poster]="activeThumbnail" [loop]="false" (onVideoEnd)="onVideoEnd()"
        class="video-container tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full tw-inset-0 bottom-0 tw-z-10 tw-min-h-screen"></video-player>
      <span id="close-video"
        class="tw-absolute tw-top-4 tw-right-3 tw-rounded-full tw-h-8 tw-w-8 tw-flex tw-items-center tw-justify-center tw-text-white tw-font-bold tw-text-xl tw-z-10 close-video-button"
        (click)="closeVideo()">
        <svg class="tw-h-6 tw-w-6" fill="currentColor" viewBox="0 0 24 24">
          <path
            d="M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7A.996.996 0 105.7 7.11L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z">
          </path>
        </svg>
      </span>
    </div>
  </section>
  <section class="banners" *ngFor="let banner of bannersTop" (click)="handleBanner(banner)">
    <img [src]="imageService.getURL(banner.image)" />
  </section>
</ng-template>
