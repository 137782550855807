<div class="tw-px-4 tw-pt-6 tw-pb-2" [ngClass]="backgroundColor">
  <span class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal"
    i18n="@@yourTreatmentPlanIncludes">Your&nbsp;<span class="tw-font-bold">₹{{totalSellingPrice}}</span>&nbsp;treatment
    plan includes</span>
  <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-mt-4 tw-mb-2" style="grid-template-areas: 'a d''b d''b d''c c';">
    <div
      class="tw-flex tw-flex-col tw-gap-1 tw-p-4 tw-bg-gray-75 tw-rounded-3 tw-shadow-[0_2px_4px_0px_rgba(0,0,0,0.10)]"
      style="grid-area: a;" (click)="openPopup(0)">
      <cs-icon class="tw-h-4 tw-w-4" [iconName]="'/rebranding/v1/green_check'"></cs-icon>
      <span *ngIf="productsCount === 0" class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal"
        i18n="@@treatmentKitWithSkincareProducts"><span class="tw-font-bold">Treatment kit</span>&nbsp;with skin-care
        products</span>
      <span *ngIf="productsCount !== 0" class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal"
        i18n="@@treatmentKitWithNProducts"><span class="tw-font-bold">Treatment kit</span>&nbsp;with {{productsCount}}
        products</span>
    </div>
    <div class="tw-flex tw-flex-col tw-pb-2 tw-bg-gray-75 tw-rounded-3 tw-shadow-[0_2px_4px_0px_rgba(0,0,0,0.10)]"
      style="grid-area: b;" (click)="openPopup(1)">
      <div class="tw-flex tw-flex-col tw-gap-1 tw-p-4">
        <cs-icon class="tw-h-4 tw-w-4" [iconName]="'/rebranding/v1/green_check'"></cs-icon>
        <span class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal" i18n="@@monthlyDoctorCheckups">Monthly
          <span class="tw-font-bold">Doctor
            Checkups</span></span>
      </div>
      <div class="tw-relative tw-w-full tw-mt-auto">
        <img class="tw-w-full tw-h-full tw-object-contain" src="https://cdn.cureskin.com/app/img/calendar.gif"
          alt="Calendar" />
      </div>
    </div>
    <div *ngIf="variantId !== variantIdConstants.variant_999"
      class="tw-flex tw-gap-1 tw-bg-gray-75 tw-rounded-3 tw-shadow-[0_2px_4px_0px_rgba(0,0,0,0.10)]"
      style="grid-area: c;" (click)="openPopup(2)">
      <div class="tw-flex tw-flex-col tw-gap-1 tw-pl-4 tw-py-4">
        <cs-icon class="tw-h-4 tw-w-4" [iconName]="'/rebranding/v1/green_check'"></cs-icon>
        <span class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal" i18n="@@skinFriendlyDietPlan">Skin
          Friendly&nbsp;<span class="tw-font-bold">Diet
            Plan</span>&nbsp;& lifestyle guidance</span>
        <div *ngIf="variantId === variantIdConstants.variant_1999 || variantId === variantIdConstants.variant_4999"
          class="tw-flex tw-justify-centre tw-items-center tw-mt-1 tw-px-2 tw-py-1 tw-w-fit tw-bg-orange-100 tw-border tw-border-solid tw-border-orange-400 tw-rounded-[30px]">
          <span *ngIf="variantId === variantIdConstants.variant_1999" class="tw-text-200 tw-text-gray-800 tw-font-bold"
            i18n="@@+1SessionWithNutritionist">+1 session with nutritionist</span>
          <span *ngIf="variantId === variantIdConstants.variant_4999" class="tw-text-200 tw-text-gray-800 tw-font-bold"
            i18n="@@+2SessionsWithNutritionist">+2 sessions with nutritionist</span>
        </div>
      </div>
      <div class="tw-relative tw-w-[180px] tw-ml-auto tw-rounded-r-3 tw-overflow-hidden">
        <img class="tw-w-full tw-h-full tw-object-cover" src="https://cdn.cureskin.com/app/img/diet_plan.png"
          alt="Diet plan" />
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-bg-gray-75 tw-rounded-3 tw-shadow-[0_2px_4px_0px_rgba(0,0,0,0.10)]"
      style="grid-area: d;" (click)="openPopup(3)">
      <div class="tw-p-4 tw-flex tw-flex-col tw-gap-1 tw-p-4">
        <cs-icon class="tw-h-4 tw-w-4" [iconName]="'/rebranding/v1/green_check'"></cs-icon>
        <span class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal"
          i18n="@@dedicatedDoctorForAnytimeSupport"><span class="tw-font-bold">Dedicated doctor</span>&nbsp;for anytime
          support</span>
      </div>
      <div class="tw-relative tw-w-full tw-max-h-[182px] fading-grid-pattern-1 tw-mt-auto">
        <img class="tw-w-full tw-h-full tw-object-contain"
          [src]="doctorImageUrl ? doctorImageUrl: '/assets/images/dr-charu.png'" alt="Doctor Image" />
        <div *ngIf="variantId === variantIdConstants.variant_4999"
          class="tw-absolute tw-bottom-0 tw-rounded-b-3 tw-flex tw-justify-center tw-gap-[6px] tw-w-full tw-px-5 tw-py-3 tw-border-t tw-border-solid tw-border-gray-200 tw-backdrop-blur-md"
          style="background-color: rgba(255, 255, 255, 0.70);">
          <cs-icon class="tw-w-3 tw-h-3 tw-mt-1 tw-shrink-0" [iconName]="'/rebranding/v1/telephone_icon'"></cs-icon>
          <div class="tw-flex tw-flex-col">
            <span class="tw-font-body tw-text-200 tw-text-orange-400 tw-font-bold" i18n="@@callWithDoctor">Call with
              Doctor</span>
            <span class="tw-font-body tw-text-100 tw-text-gray-800 tw-font-normal" i18n="@@beforeKitIsShipped">before
              kit is shipped</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tw-flex tw-items-center tw-justify-evenly tw-gap-2 tw-p-2">
    <span class="tw-font-body tw-text-200 tw-text-gray-800 tw-font-normal tw-text-center" i18n="@@100%Effective">100%
      Effective</span>
    <span class="tw-shrink-0 tw-w-[1px] tw-h-[18px] tw-bg-[#00000033]"></span>
    <span class="tw-font-body tw-text-200 tw-text-gray-800 tw-font-normal tw-text-center"
      i18n="@@clinicallyTested">Clinically
      Tested</span>
    <span class="tw-shrink-0 tw-w-[1px] tw-h-[18px] tw-bg-[#00000033]"></span>
    <span class="tw-font-body tw-text-200 tw-text-gray-800 tw-font-normal tw-text-center" i18n="@@freeDelivery">Free
      Delivery</span>
  </div>
</div>

<!-- Popup backdrop -->
<div *ngIf="showPopup" class="tw-fixed tw-inset-0 tw-z-20" (click)="closePopup()"
  style="background-color: rgba(0, 0, 0, 0.8);"></div>

<!-- Popup -->
<div *ngIf="showPopup"
  class="tw-fixed tw-w-full -tw-translate-y-1/2 tw-top-1/2 tw-z-30 tw-flex tw-justify-center tw-items-center tw-rounded-3">
  <swiper #swiperCardsBanner="" class="" [config]="carouselConfig">
    <div *swiperSlide class="tw-relative tw-w-full tw-bg-gray-80 tw-rounded-3 tw-h-[70vh] tw-grid tw-grid-cols-1"
      style="grid-template-rows: repeat(3, minmax(0, auto))">
      <cs-icon class="tw-absolute tw-top-2 tw-right-2 tw-w-6 tw-h-6" [iconName]="'/rebranding/v1/close_button_icon'"
        (click)="closePopup()"></cs-icon>
      <span *ngIf="variantId !== variantIdConstants.variant_4999 && productsCount === 0"
        class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal tw-p-8 tw-text-center"
        i18n="@@treatmentKitWithSkincareProductsLongMessage"><span class="tw-font-bold">Treatment kit with skin-care
          products</span>&nbsp;to ensure you get visible results.</span>
      <span *ngIf="variantId !== variantIdConstants.variant_4999 && productsCount !== 0"
        class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal tw-p-8 tw-text-center"
        i18n="@@kitWithNProductsLongMessage"><span class="tw-font-bold">Kit with {{productsCount}}
          products</span>&nbsp;to ensure you get visible results.</span>
      <span *ngIf="variantId === variantIdConstants.variant_4999 && productsCount === 0"
        class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-bold tw-p-8 tw-text-center"
        i18n="@@treatmentKitWithSkincareProductsVariant4999LongMessage">Treatment kit with skin-care products + doctor
        consultation session.</span>
      <span *ngIf="variantId === variantIdConstants.variant_4999 && productsCount !== 0"
        class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-bold tw-p-8 tw-text-center"
        i18n="@@kitWithNProductsVariant4999LongMessage">Kit with {{productsCount}} products + doctor consultation
        session.</span>
      <img class="tw-w-full tw-h-full tw-object-contain"
        src="https://cdn.cureskin.com/app/img/regimen_proposition_slide_1.png" alt="regimen proposition slide 1" />
      <div class="tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-3 tw-bg-green-400 tw-rounded-b-3"
        (click)="handleGetTreatmentKit()">
        <span class="tw-font-body tw-text-300 tw-text-gray-50 tw-font-bold" i18n="@@getTreatmentKit">Get Treatment
          Kit</span>
      </div>
    </div>
    <div *swiperSlide class="tw-relative tw-w-full tw-bg-gray-80 tw-rounded-3 tw-h-[70vh] tw-grid tw-grid-cols-1"
      style="grid-template-rows: repeat(3, minmax(0, auto))">
      <cs-icon class="tw-absolute tw-top-2 tw-right-2 tw-w-6 tw-h-6" [iconName]="'/rebranding/v1/close_button_icon'"
        (click)="closePopup()"></cs-icon>
      <span class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal tw-p-8 tw-text-center"
        i18n="@@monthlyDoctorCheckupsLongMessage"><span class="tw-font-bold">Monthly doctor
          checkups</span>&nbsp;to measure your skin progress.</span>
      <img class="tw-w-full tw-h-full tw-object-contain"
        src="https://cdn.cureskin.com/app/img/regimen_proposition_slide_2.png" alt="regimen proposition slide 2" />
      <div class="tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-3 tw-bg-green-400 tw-rounded-b-3"
        (click)="handleGetTreatmentKit()">
        <span class="tw-font-body tw-text-300 tw-text-gray-50 tw-font-bold" i18n="@@getTreatmentKit">Get Treatment
          Kit</span>
      </div>
    </div>
    <ng-container *ngIf="variantId !== variantIdConstants.variant_999">
      <div *swiperSlide class="tw-relative tw-w-full tw-bg-gray-80 tw-rounded-3 tw-h-[70vh] tw-grid tw-grid-cols-1"
        style="grid-template-rows: repeat(3, minmax(0, auto))">
        <cs-icon class="tw-absolute tw-top-2 tw-right-2 tw-w-6 tw-h-6" [iconName]="'/rebranding/v1/close_button_icon'"
          (click)="closePopup()"></cs-icon>
        <span *ngIf="variantId !== variantIdConstants.variant_1999 && variantId !== variantIdConstants.variant_4999"
          class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal tw-p-8 tw-text-center"
          i18n="@@dietPlanLongMessage"><span class="tw-font-bold">Diet plan & lifestyle
            guidance</span>&nbsp;by nutritionist to boost skin health.</span>
        <span *ngIf="variantId === variantIdConstants.variant_1999"
          class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal tw-p-8 tw-text-center"
          i18n="@@dietPlanVariant1999LongMessage"><span class="tw-font-bold">1 session with nutritionist + diet
            plan</span>&nbsp;to boost skin health.</span>
        <span *ngIf="variantId === variantIdConstants.variant_4999"
          class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal tw-p-8 tw-text-center"
          i18n="@@dietPlanVariant4999LongMessage"><span class="tw-font-bold">2 sessions with nutritionist + diet
            plan</span>&nbsp;to boost skin health.</span>
        <img class="tw-w-full tw-h-full tw-object-contain" [src]="variantId === variantIdConstants.variant_1999 || variantId === variantIdConstants.variant_4999 
            ? 'https://cdn.cureskin.com/app/img/regimen_proposition_slide_5.png' 
            : 'https://cdn.cureskin.com/app/img/regimen_proposition_slide_3.png'" alt="regimen proposition slide" />
        <div class="tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-3 tw-bg-green-400 tw-rounded-b-3"
          (click)="handleGetTreatmentKit()">
          <span class="tw-font-body tw-text-300 tw-text-gray-50 tw-font-bold" i18n="@@getTreatmentKit">Get Treatment
            Kit</span>
        </div>
      </div>
    </ng-container>
    <div *swiperSlide class="tw-relative tw-w-full tw-bg-gray-80 tw-rounded-3 tw-h-[70vh] tw-grid tw-grid-cols-1"
      style="grid-template-rows: repeat(3, minmax(0, auto))">
      <cs-icon class="tw-absolute tw-top-2 tw-right-2 tw-w-6 tw-h-6" [iconName]="'/rebranding/v1/close_button_icon'"
        (click)="closePopup()"></cs-icon>
      <span class="tw-font-body tw-text-500 tw-text-gray-800 tw-font-normal tw-p-8 tw-text-center"
        i18n="@@dedicatedDoctorLongMessage"><span class="tw-font-bold">Dedicated doctor</span>&nbsp;to help & support
        your healthy skin journey.</span>
      <img class="tw-w-full tw-h-full tw-object-contain" [src]="variantId !== variantIdConstants.variant_4999
        ? 'https://cdn.cureskin.com/app/img/regimen_proposition_slide_4.png'
        : 'https://cdn.cureskin.com/app/img/regimen_proposition_slide_6.png'" alt="regimen proposition slide 4" />
      <div class="tw-flex tw-justify-center tw-items-center tw-px-4 tw-py-3 tw-bg-green-400 tw-rounded-b-3"
        (click)="handleGetTreatmentKit()">
        <span class="tw-font-body tw-text-300 tw-text-gray-50 tw-font-bold" i18n="@@getTreatmentKit">Get Treatment
          Kit</span>
      </div>
    </div>
  </swiper>
</div>